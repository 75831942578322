import { OrderLineDisplayModel } from "./orderLineDisplayModel";
//Sprint-24 TTD-116
export class TCModel{
   public tcSection!: string;
   public isSelectedAll!: boolean;
   public isCollapse!: boolean;
   public isOpen!: boolean;
   public isSortAtoZ!: boolean;
   public isSortZtoA!: boolean;
   public sortType!: string;
   public selectedSortOption!: string;
}

export class OrderListModel{
   public ID!: string;
   public tranCertificate!: string;
   public tcType!: string;
   public tcStatusValue!: string;
   public tranCertificateRefID!: string;
}

export class UploadOrderModel{
   public orderId!: string;
   public certificate1or2!: string;
   public clientId!: string;
   public tranCertificate!: string;
   public level!: string;
   public orderNumber!: string;
   public styleNumber!: string;
   public colour!: string;
   public client!: string;
   public isRequestSelected!: boolean;
   public tranCertificateRefId!: string;
}

export class TCMailModel{
   public supplierCompanyName!: string;
   public tcDocType!: string;
   public tcType!: string;
   public orderNumbers!: string;
   public clientID!: string;
}

export class TCDocumentModel{
   public success!: boolean;
   public tcData!: TCDocDataModel;
   public docUrl!: string;
}

export class TCDocDataModel {
   public ID!: string;
   public archieveDate!: string;
   public archieveDate1!: string;
   public clientID!: string;
   public clientName!: string;
   public cpSupplierID!: string;
   public cpSupplierName!: string;
   public createdAt!: string;
   public createdBy!: string;
   public createdByName!: string;
   public rejectionReason!: string;
   public tcComments!: string;
   public tcReferenceNumber!: string;
   public tcStatus!: string;
   public tcType!: string;
   public tranCertificate!: string;
   public tranCertificateRefID!: string;
   public tranCertificateUrls!: TCCertificateUrlsModel;
   public updatedAt!: string;
   public updatedBy!: string;
}


export class TCCertificateUrlsModel{
   public ID!: string;
   public createdAt!: string;
   public createdBy!: string;
   public docUrl!: string;
   public fileHash!: string;
   public fileName!: string;
   public fileType!: string;
   public shown!: boolean;
}

export class UploadTCResponsepModel {
   public success!: boolean;
   public order!: OrderLineDisplayModel[];
}