import { Vue, Component, Emit } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Emit()
  private apply(): void {
    // Emit
  }

  @Emit()
  private close(): void {
    // Emit
  }
}
