import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';

// Components
import ViewDocumentsSigned from '@/views/compliance/components/viewDocumentsSigned.vue';
import ViewOriginalDocuments from '@/views/compliance/components/viewOriginalDocuments.vue';

// Models
import { ComplianceDocResponseModel } from '@/models/complianceDocResponseModel';
import { ComplianceClientDocModel } from '@/models/complianceClientDocModel';
import { mapState } from 'vuex';
import { CompanyNameModel } from '@/models/companyModel';

@Component({
  components: { ViewDocumentsSigned, ViewOriginalDocuments },
  computed: mapState(['companyNameList']),
})
export default class DocumentsSigned extends Vue {
  @Prop()
  private documents!: ComplianceDocResponseModel[];

  @Prop()
  private selectedTab!: number;

  @Prop()
  private loadingDoc!: boolean;

  @Prop()
  private searchFilter!: string;

  @Prop()
  private selectedClient!: string;

  private showPDFModal: boolean = false;

  private showOriginalPDFModal: boolean = false;

  private documentDetail: ComplianceDocResponseModel =
    new ComplianceDocResponseModel();

  private companyNameList!: CompanyNameModel[];

  public constructor() {
    super();
  }

  private get getDocs(): ComplianceDocResponseModel[] {
    let clientDocs: ComplianceDocResponseModel[] = [];
    clientDocs = this.documents;
    if (this.searchFilter!=='') {
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      clientDocs = clientDocs.filter((s)=>s.documentName.toLowerCase().includes(lowerCaseFilter) || s.fromCompanyName.toLowerCase().includes(lowerCaseFilter));
    }
    return clientDocs;
  }

  private formattedDate(date: string): string {
    return moment(date).format('DD MMM YYYY');
  }

  private openPDFModal(doc: ComplianceDocResponseModel): void {
    this.documentDetail = doc;
    const container = document.getElementById('right-container');
    container!.style.height = '97vh';
    if (doc.scpSignedStatus || doc.scpReadStatus === "true") {
      this.showPDFModal = true;
    } else {
      this.showOriginalPDFModal = true;
    }
  }

  private closePDFModal(success: boolean): void {
    const container = document.getElementById('right-container');
    container!.style.height = '100vh';
    if (success) {
      this.reloadDocuments();
    }
    this.showPDFModal = false;
    this.showOriginalPDFModal = false;
  }

  private partnerName(partnerId: string, currentName: string): string {
    return this.companyNameList.filter((c) => c.ID === partnerId).length > 0 ? this.companyNameList.filter((c) => c.ID === partnerId)[0].name : currentName;
  }

  @Emit()
  private reloadDocuments(): void {}
}
