import { BaseClient } from './baseClient';
import { SupplyChainApiAuthenticator } from '../authenticators/supplyChainApiAuthenticator';
import { ApplicationConfig } from '../config';
import { CompanyModel, companyResponseModel } from '@/models/companyModel';
import { InvitationModel } from '@/models/invitationModel';
import { AddSecondUserResponeModel, InvitedSupplierModel, InvitedSupplierResponseModel } from '@/models/invitedSupplierModel';

export class InvitationClient extends BaseClient {
  private readonly endPoint: string = 'api/invitation';

  public constructor() {
    super(
      ApplicationConfig.endpoints.dashboardApi,
      new SupplyChainApiAuthenticator()
    );
  }

  public async getSupplierByInvitationIdAsync(
    invitationId: string
  ): Promise<CompanyModel> {
    return await this.get<CompanyModel>(
      `${this.endPoint}/supplier?invitationId=${invitationId}`
    );
  }

  public async updateSupplierAsync(
    invitationId: string,
    supplier: CompanyModel
  ): Promise<companyResponseModel> {
    return await this.putWithResponse(
      `${this.endPoint}/${invitationId}/supplier/${supplier.id}`,
      supplier
    );
  }

  public async sendInvitation(
    invitationModel: InvitationModel
  ): Promise<InvitedSupplierResponseModel> {
    return await this.postWithResponse(`${this.endPoint}`, invitationModel);
  }

  public async addSecondUserAsync(invitedSupplierModel: InvitationModel, userId: string): Promise<AddSecondUserResponeModel> {
    return await this.postWithResponse<AddSecondUserResponeModel>(`${this.endPoint}/requestAddSecondUser/${userId}`, invitedSupplierModel);
  }
}
