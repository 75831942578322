import { BaseClient } from './baseClient';
import { SupplyChainApiAuthenticator } from '../authenticators/supplyChainApiAuthenticator';
import { ApplicationConfig } from '../config';
import { CompanyModel, companyResponseModel } from '@/models/companyModel';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { AddressModel } from '@/models/addressModel';
import { InvitedSupplierModel, LinkSupplierResponeModel } from '@/models/invitedSupplierModel';
import { SignedComplianceDocResponse } from '@/models/signedComplianceDocResponse';
import { CompanyPictureModel } from '@/models/companyPictureModel';
import { TCMailModel } from '@/models/transcationCertificateModal';

export class SupplierClient extends BaseClient {
  private readonly endPoint: string = 'api/supplier';
  private readonly endPointInvite: string = 'api/invitation';

  public constructor() {
    super(
      ApplicationConfig.endpoints.dashboardApi,
      new SupplyChainApiAuthenticator()
    );
  }

  public async getSupplierAsync(): Promise<CompanyModel> {
    return await this.get<CompanyModel>(`${this.endPoint}`);
  }

  public async getSupplierNameByIdAsync(supplierId: string): Promise<string> {
    return await this.getText(`${this.endPoint}/${supplierId}/name`);
  }

  public async getSupplyChainPartnersAsync(): Promise<OrderCompanyModel[]> {
    return await this.get<OrderCompanyModel[]>(`${this.endPoint}/suppliers`);
  }

  public async updateSupplierAsync(
    supplier: CompanyModel
  ): Promise<companyResponseModel> {
    return await this.putWithResponse(
      `${this.endPoint}/${supplier.id}`,
      supplier
    );
  }

  public async updateSupplierAddressAsync(
    supplierId: string,
    address: AddressModel
  ): Promise<string> {
    return await this.putWithResponse<string>(
      `${this.endPoint}/${supplierId}/address`,
      address
    );
  }

  public async linkSupplierAsync(
    invitedSupplierModel: InvitedSupplierModel
  ): Promise<LinkSupplierResponeModel> {
    return await this.postWithResponse<LinkSupplierResponeModel>(`${this.endPoint}/link`, invitedSupplierModel);
  }

  public async downloadSignedPdf(
    data: any
  ): Promise<SignedComplianceDocResponse> {
    return await this.postWithResponse(
      `${this.endPoint}/downloadSignedPdf`,
      data
    );
  }

  public uploadCompanyPicturesAsync(
    companyId: string,
    pictures: CompanyPictureModel[]
  ): Promise<Response> {
    return this.post(`${this.endPoint}/${companyId}/profilepictures`, pictures);
  }

  // Sprint-24 TTD-4486
  public async sendTCUploadMail( tcMailData: TCMailModel): Promise<Response> {
    return await this.post(`${this.endPoint}/SendTCDocUploadedNotificationEmailToClient`, tcMailData);
  }
}
