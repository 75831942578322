import { Vue, Component, Emit, Watch, Prop } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { ActionTypes } from '@/store/actions/actions';
import i18n from '@/i18n';
import { mapGetters } from 'vuex';
import store from '@/store';
import lodash, { add, cloneDeep } from 'lodash';
import { debounce } from 'ts-debounce';
import { v4 as uuidv4 } from 'uuid';

// Components
import MapModal from '@/views/profile/components/mapModal.vue';
import Multiselect from '@/components/input/multiselect.vue';
import CompanyPictures from '@/views/profile/components/companyPictures.vue';
import DropDownInput from '@/components/general/drop-down-input/dropDownInput.vue';
import DropDownEditable from '@/components/general/drop-down-editable/dropDownEditable.vue';
import SmallScreenPopUpMsg from '@/components/general/smallScreenPopUpMsg/smallScreenPopUpMsg.vue';

// Helpers
import { CompanyFormDropdownHelper } from '@/helpers/companyFormDropdownHelper';
import { CryptionHelper } from '@/helpers/cryptionHelper';
import { CountryHelper } from '@/helpers/countryHelper';
import { NotificationHelper } from '@/helpers/notificationHelper';
import { NonRomanCharCheckHelper }from '@/helpers/nonRomanCharCheckHelper'

// Services
import { GeolocationService } from '@/services/geolocationService';
import { InvitationService } from '@/services/invitationService';
import { UserService } from '@/services/userService';
import { SupplierService } from '@/services/supplierService';
import { CountryStateCityService } from '@/services/countryStateCityService';
import { MiddlewareService } from '@/services/middlewareService';
import { UserClient } from '@/clients/userClient';

// Models
import { AddressErrorModel, AddressModel } from '@/models/addressModel';
import { CompanyErrorModel, CompanyModel, companyResponseModel } from '@/models/companyModel';
import { ContactModel } from '@/models/contactModel';
import { DropdownModel } from '@/models/dropdownModel';
import { UserPermissionModel } from '@/models/permissionModel';
import { AddressStatus } from '@/models/addressState';
import { CompanyAdminRequestModel } from '@/models/createCompanyUsersModel';
import { SigninSupplierModel } from '@/models/signinSupplierModel';
import { UserModel } from '@/models/userModel';
import { Guid } from '@/models/guid';

@Component({
  components: {
    MapModal,
    Multiselect,
    DropDownInput,
    DropDownEditable,
    CompanyPictures,
    SmallScreenPopUpMsg,
  },
  computed: mapGetters(['userRole', 'userPermissions', 'encryptPwd']),
})
export default class CompanyForm extends Vue {
  private changedSettingsCounter: number = 0;

  private supplierService: SupplierService;

  private invitationService: InvitationService;

  private userService: UserService;

  private geolocationService: GeolocationService;

  private middlewareService: MiddlewareService;

  private client: UserClient = new UserClient();

  private cryptionHelper: CryptionHelper;

  private mapboxAccessToken: string = '';

  private admin: ContactModel = new ContactModel();

  private isUploadError: any = { validtoDate: false, validfromDate: false };

  private contact: ContactModel = new ContactModel();

  private company: CompanyModel = new CompanyModel();

  private companyClone: CompanyModel = new CompanyModel();

  private allCategories: DropdownModel[] = [];

  private statesDropdown: any = {};

  private citiesDropdown: any = {};

  private selectedCountryValue: string = '';

  private selectedStateValue: string = '';

  private cscService: CountryStateCityService;

  private allProductGroups: DropdownModel[] = [];

  private companyTypes: DropdownModel[] = [];

  private companySubtypes: DropdownModel[] = [];

  private countries: DropdownModel[] = [];

  private factoryAddresses: AddressModel[] = [new AddressModel()];

  private officeAddress: AddressModel[] = [new AddressModel()];

  private companyAddresses: AddressModel[] = [new AddressModel()];

  private curAddressId: string = '';
  private curAddressTitle: string = '';
  private curAddressDetails: string = '';

  private showDefaultProfileTextModal: boolean = false;

  private isLoading: boolean = true;

  private isSaving: boolean = false;

  private isCompanyTypeDisabled: boolean = false;

  private isMainContactEmailDisabled: boolean = false;

  private isValid: boolean = true;

  private countryError: boolean = false;

  private cityError: boolean = false;

  private stateError: boolean = false;

  private countryErrorList: any = {};

  private cityErrorList: any = {};

  private stateErrorList: any = {};

  private showOfficeAddressMapModal: number = -1;

  private indexShowFactoryAddressMapModal: number = -1;

  private totalCertificates: number = 0;

  // private indexOffice: number = -1;

  // private indexFactory: number = -1;

  private companyName: string = '';

  private isCurrentAddress: boolean = false;

  private isMobileAddressError: boolean = false;

  private isMobile: boolean = false;

  private officeAddrDisabled: boolean[] = [];

  private isTablet: boolean = false;

  private disableSave: boolean = false;

  private showOfficeAddressMobile: boolean = false;

  private companyPicturesState: string = '';

  private showQrCodeModal: boolean = false;

  private addressAddedRemoved: boolean = false;

  private checkFieldsDebounced: any;

  private checkNameDebounced: any;

  private showConfirmAddressModal: boolean = false;

  private showEditWarningModal: boolean = false;

  private isFactoryAddress: boolean = false;

  private confirmedOfficeAddCount: number = 0;

  private confirmedFactoryAddCount: number = 0;

  private addressType: string = '';

  private addIndex: number = -1;

  private allAddressCount: string = '';

  private mobileCurrentAddress: number = 1;

  private recentCertificates!: number;

  private allAddressConfirmed: boolean = false;

  private isBrandInvalid: boolean = false;

  private isInvalidAddress: boolean = false;

  private showDisableSingleAddressWarning: boolean = false;

  private showDisableMultipleAddressWarning: boolean = false;

  private disableAddressIndex: number = -1;

  private disableAddressType: string = '';

  private locationError: boolean = false;

  private companyPicturesAvailable: boolean = false;

  private isNewlyInvited: boolean = false;

  private invitationId: string | null = '';

  private userRole!: string;

  private userPermissions!: UserPermissionModel | null;

  private profileSave: string = 'FIRST';

  private encryptPwd!: string;

  private isAccountSave: boolean = false;

  private showSave: boolean = true;

  private isPhoneNonRoman: boolean = false;

  private isEmailNonRoman: boolean = false;

  private isValidType: boolean = true;
  private firstNameError: boolean = false;
  private fNameSpecCharErr: boolean = false;
  private allAddressess: AddressModel[] = [];
  private allAddressessClone: AddressModel[] = [];
  private isCompanyDetailsInValid: boolean = false;
  private addressIndex: number = -1;
  private changedkeys: string[] = [];
  private counter: number = 0;

  public constructor() {
    super();
    this.supplierService = new SupplierService();
    this.invitationService = new InvitationService();
    this.cscService = new CountryStateCityService();
    this.geolocationService = new GeolocationService();
    this.userService = new UserService();
    this.middlewareService = new MiddlewareService();
    this.cryptionHelper = new CryptionHelper();
  }

  private mounted(): void {
    if (screen.width < 636) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    if (screen.width < 1081) {
      this.isTablet = true;
    } else {
      this.isTablet = false;
    }
    window.onresize = () => {
      if (window.innerWidth < 636) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      if (window.innerWidth < 1081) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
      localStorage.setItem('isMobile', this.isMobile.toString());
    };
    localStorage.setItem('isMobile', this.isMobile.toString());
  }

  private get types(): DropdownModel[] {
    return this.companyTypes;
  }

  private get subtypes(): DropdownModel[] {
    return this.companySubtypes.sort(function(type1, type2){
      if(type1.text < type2.text) { return -1; }
      if(type1.text > type2.text) { return 1; }
      return 0;
    });
  }

  private get productGroups(): DropdownModel[] {
    return this.allProductGroups.sort(function(type1, type2){
      if(type1.text < type2.text) { return -1; }
      if(type1.text > type2.text) { return 1; }
      return 0;
    });
  }

  private get categories(): DropdownModel[] {
    return this.allCategories.sort(function(type1, type2){
      if(type1.text < type2.text) { return -1; }
      if(type1.text > type2.text) { return 1; }
      return 0;
    });
  }

  private get officeAddressValidation10length(): string {
    if (this.company.factoryAddressDiffers) {
      return 'max:10|roman';
    }
    return 'required|max:10|roman';
  }

  private get officeAddressValidation100length(): string {
    if (this.company.factoryAddressDiffers) {
      return 'max:100|roman';
    }
    return 'required|max:100|roman';
  }

  private get isConfirming(): boolean {
    if (
      (this.company.officeAddress &&
        this.company.officeAddress.some((o) => o.isConfirming)) ||
      this.company.factoryAddresses.some((u) => u.isConfirming)
    ) {
      return true;
    }
    return false;
  }

  private get isCompanyPictureUploaded(): boolean {
    let isUploaded = true;
    const latestCompany: CompanyModel = store.getters.company;
    this.company.officeAddress.forEach((address, index) => {
      if (index === 0) {
        const count = latestCompany.profilePictures.filter(
          (p) =>
            p.addressID === address.id ||
            p.addressID === '' ||
            p.addressID === null
        ).length;
        if (count < 3) {
          isUploaded = false;
        }
      } else {
        if (isUploaded) {
          const count = latestCompany.profilePictures.filter(
            (p) => p.addressID === address.id
          ).length;
          if (count < 3) {
            isUploaded = false;
            return;
          }
        }
      }
    });
    if (isUploaded) {
      this.company.factoryAddresses.forEach((address) => {
        const count = latestCompany.profilePictures.filter(
          (p) => p.addressID === address.id
        ).length;
        if (count < 3) {
          isUploaded = false;
          return;
        }
      });
    }
    return isUploaded;
  }

  private get showReactiveBar(): boolean {
    this.showSave = true;
    let showBar = false;
    if (
      this.showOfficeAddressMobile ||
      this.showQrCodeModal ||
      this.showDisableSingleAddressWarning ||
      this.showDisableMultipleAddressWarning ||
      this.isMobileAddressError
    ) {
      showBar = false;
    } else {
      if (this.counter > 0 || this.invitationId) {
        showBar = true;
      }
    }
    return showBar;
  }

  private get currencyList(): DropdownModel[] {
    return [
      { value: 'AED', text: 'AED' },
      { value: 'ARS', text: 'ARS' },
      { value: 'AUD', text: 'AUD' },
      { value: 'BHD', text: 'BHD' },
      { value: 'BRL', text: 'BRL' },
      { value: 'CAD', text: 'CAD' },
      { value: 'CHF', text: 'CHF' },
      { value: 'CHF', text: 'CHF' },
      { value: 'CNY', text: 'CNY' },
      { value: 'COP', text: 'COP' },
      { value: 'CZK', text: 'CZK' },
      { value: 'DKK', text: 'DKK' },
      { value: 'EGP', text: 'EGP' },
      { value: 'EUR', text: 'EUR' },
      { value: 'GBP', text: 'GBP' },
      { value: 'HKD', text: 'HKD' },
      { value: 'HUF', text: 'HUF' },
      { value: 'IDR', text: 'IDR' },
      { value: 'ILS', text: 'ILS' },
      { value: 'INR', text: 'INR' },
      { value: 'JPY', text: 'JPY' },
      { value: 'JOD', text: 'JOD' },
      { value: 'KRW', text: 'KRW' },
      { value: 'KWD', text: 'KWD' },
      { value: 'MXN', text: 'MXN' },
      { value: 'MYR', text: 'MYR' },
      { value: 'NGN', text: 'NGN' },
      { value: 'NOK', text: 'NOK' },
      { value: 'NZD', text: 'NZD' },
      { value: 'OMR', text: 'OMR' },
      { value: 'PKR', text: 'PKR' },
      { value: 'PLN', text: 'PLN' },
      { value: 'PHP', text: 'PHP' },
      { value: 'QAR', text: 'QAR' },
      { value: 'RUB', text: 'RUB' },
      { value: 'SAR', text: 'SAR' },
      { value: 'SEK', text: 'SEK' },
      { value: 'SGD', text: 'SGD' },
      { value: 'THB', text: 'THB' },
      { value: 'TRY', text: 'TRY' },
      { value: 'USD', text: 'USD' },
      { value: 'VND', text: 'VND' },
      { value: 'ZAR', text: 'ZAR' },
    ];
  }

  private get addressessType(): DropdownModel[] {
    return [{ value: 'OFFICE', text: 'Office' }, { value: 'FACTORY', text: 'Factory' }];
  }

  private get monthlyCapacityUnit(): DropdownModel[] {
    return [{ value: 'PIECES', text: 'Pieces' }, { value: 'M', text: 'M' }, { value: 'KM', text: 'Km' }, { value: 'KG', text: 'Kg' }];
  }

  private get guidEmpty(): string {
    return Guid.Empty;
  }

  private get officeFacilityAdd(): AddressModel[] {
    const add =  this.allAddressess.filter(add => add.facilityType === 'OFFICE');
    return add;
  }

  private get factoryFacilityAdd(): AddressModel[] {
    const add = this.allAddressess.filter(add => add.facilityType === 'FACTORY');
    return add;
  }

  private async created(): Promise<void> {
    try {
      this.isLoading = true;
      this.checkNameDebounced = debounce(this.validateName, 250, {
        maxWait: 250,
        isImmediate: true,
      });
      const mapboxTokenModel = await this.geolocationService.getMapboxToken();
      this.mapboxAccessToken = mapboxTokenModel.token;
      this.companyTypes = CompanyFormDropdownHelper.getCompanyTypes();
      this.companySubtypes = CompanyFormDropdownHelper.getCompanySubtypes();
      this.allCategories = CompanyFormDropdownHelper.getCategories();
      this.allProductGroups = CompanyFormDropdownHelper.getProductGroups();
      this.countries = CountryHelper.getCountryList();
      this.invitationId = localStorage.getItem('invitationId');
      if (this.invitationId) {
        this.isMainContactEmailDisabled = true;
        if (store.getters.company != null) {
          this.company = store.getters.company;
        } else {
          this.company =
            await this.invitationService.getSupplierByInvitationIdAsync(
              this.invitationId
            );
          store.commit('setMyAccount', this.company);
        }
        this.isNewlyInvited = true;
      } else {
        this.isCompanyTypeDisabled = true;
        if (this.$store.getters.company != null) {
          this.company = this.$store.getters.company;
        } else {
          await this.$store.dispatch(ActionTypes.SET_MYACCOUNT);
          this.company = this.$store.getters.company;
        }
        const total = this.$store.getters.certificatesCount;
        if (total) {
          this.recentCertificates = total;
        } else {
          this.recentCertificates = 0;
        }
      }
      this.company.turnover = this.company.turnover === -1 ? "" : this.company.turnover;
      this.admin = this.company.admin;
      this.contact = this.company.contact;
      this.isCompanyPicturesAvailable();
      this.updateProfilePictures();
      // this.countryStateCityValidation();
      this.allOfficeAddressConfirmed();
      this.allFactoryAddressConfirmed();
      this.syncFacilityAddress();
      this.allConfirmedAddress();
      this.companyName = this.company.companyName;
      // this.sortForDisabledAddresses('OFFICE');
      // this.sortForDisabledAddresses('FACTORY');
      // this.company.officeAddress = this.officeAddress;
      // this.company.factoryAddresses = this.factoryAddresses;
      // if (this.invitationId) {
      //   this.checkAddressLookupAvailable(this.company.officeAddress[0]);
      // }
      this.company.inValid = new CompanyErrorModel();
      store.commit('setMyAccount', this.company);
      const companyState = this.$store.getters.company as CompanyModel;
      if (!this.invitationId) {
        let sustainableCount = 0;
        let socialCount = 0;
        for (
          let index = 0;
          index < companyState.sustainability.length;
          index++
        ) {
          if (
            companyState.sustainability[index].fileType !== 'image/jpeg' &&
            companyState.sustainability[index].fileType !== null
          ) {
            sustainableCount++;
          }
        }
        for (let index = 0; index < companyState.social.length; index++) {
          if (
            companyState.social[index].fileType !== 'image/jpeg' &&
            companyState.social[index].fileType !== null
          ) {
            socialCount++;
          }
        }
        this.totalCertificates =
          socialCount + sustainableCount + this.recentCertificates;
      } else {
        this.totalCertificates = 0;
      }

      this.checkFieldsDebounced = debounce(this.validateField, 250, {
        maxWait: 250,
        isImmediate: true,
      });
    } catch (e: any) {
      if (e.statuscodeBackingField == 500) {
        localStorage.setItem('invitationId', '');
        this.$router.push({
          name: 'Signin',
          params: { invitationError: '1' },
        });
      }
    } finally {
      this.companyClone = lodash.cloneDeep(this.company);
      this.isLoading = false;
    }
  }

  private syncFacilityAddress(): void {
    this.allAddressess = [];
    this.allAddressessClone = [];
    if(this.invitationId){
      this.officeAddress = this.company.officeAddress;
      this.checkAddressLookupAvailable(this.officeAddress[0]);
      this.officeAddress.forEach((o, index) => {
        o.isExpanded = false;
        o.employees = o.employees !== -1 ? o.employees : '';
        o.maleEmployees = o.maleEmployees !== -1 ? o.maleEmployees : '';
        o.femaleEmployees = o.femaleEmployees !== -1 ? o.femaleEmployees : '';
        o.monthlyCapacity = o.monthlyCapacity !== -1 ? o.monthlyCapacity : '';
        o.sequence = index;
        o.inValid = new AddressErrorModel();
        this.checkAddressDisabled(o);
        this.allAddressess.push(o);
        this.allAddressess[0].isExpanded = true;
      });
    } else {
      if(this.company.newFacilityData !== ''){
        this.company.officeAddress.forEach(add => {
          if(add.facilityType === ''){
            add.facilityType = 'OFFICE';
          }
          this.allAddressess.push(add);
        })
        if(this.company.factoryAddressDiffers){
          this.company.factoryAddresses.forEach(add => {
            if(add.facilityType === ''){
              add.facilityType = 'FACTORY';
            }
            this.allAddressess.push(add);
          })
        }
        this.allAddressess.sort((a, b) => a.position - b.position);
      } else {
        this.company.officeAddress.forEach((o, index) => {
          o.facilityType = 'OFFICE';
          o.sequence = index;
        });
        this.company.factoryAddresses.forEach((f, index) => {
          f.facilityType = 'FACTORY';
          f.sequence = this.company.officeAddress.length + index;
        });
        const officeAddress = this.company.officeAddress.filter(o => o.addressStatus !== "DISABLED");
        officeAddress.sort((a, b) => a.sequence - b.sequence);
        this.allAddressess.push(...officeAddress);
        if(this.company.factoryAddressDiffers){
          const factoryAddress = this.company.factoryAddresses.filter(f => f.addressStatus !== "DISABLED");
          factoryAddress.sort((a, b) => a.sequence - b.sequence);
          this.allAddressess.push(...factoryAddress);
        }
        this.allAddressess.push(...this.company.officeAddress.filter(o => o.addressStatus === "DISABLED"));
        if(this.company.factoryAddressDiffers){
          this.allAddressess.push(...this.company.factoryAddresses.filter(f => f.addressStatus === "DISABLED"));
        }
      }
      this.allAddressess.forEach((o, index) => {
        o.isExpanded = false;
        o.employees = o.employees !== -1 ? o.employees : (index === 0 && this.company.employees !== -1 ? this.company.employees : '');
        o.maleEmployees = o.maleEmployees !== -1 ? o.maleEmployees : (index === 0 && this.company.maleEmployees !== -1 ? this.company.maleEmployees : '');
        o.femaleEmployees = o.femaleEmployees !== -1 ? o.femaleEmployees : (index === 0 && this.company.femaleEmployees !== -1 ? this.company.femaleEmployees : '');
        o.monthlyCapacity = o.monthlyCapacity !== -1 ? o.monthlyCapacity : (index === 0 && this.company.monthlyCapacity !== -1 ? this.company.monthlyCapacity : '');
        o.inValid = new AddressErrorModel();
        this.checkAddressDisabled(o);
      });
    }
    this.allAddressessClone = cloneDeep(this.allAddressess);
  }

  private toggleAddress(index: number): void {
    if(this.allAddressess[index].isConfirmed) {
      this.allAddressess[index].isExpanded = !this.allAddressess[index].isExpanded;
      const address = this.allAddressess[index];
      this.allAddressess.splice(index, 1, address);
    }
  }

  private getAddressSeq(index: number): number {
    return index + 1;
  }

  private isCompanyPicturesAvailable(): void {
    const company = this.$store.getters.company;
    if (company.profilePictures.length > 2) {
      this.companyPicturesAvailable = true;
    }
  }

  private updateProfilePictures(): void {
    if (this.company && this.company.profilePictures.length > 0) {
      for (let i = 0; i < this.company.profilePictures.length; i++) {
        if (
          this.company.profilePictures[i].addressID === '' ||
          this.company.profilePictures[i].addressID === null
        ) {
          this.company.profilePictures[i].addressID =
            this.company.officeAddress[0].id;
        }
      }
    }
  }

  private allConfirmedAddress(): void {
    const officeAddress = this.allAddressess.filter(add => add.facilityType === 'OFFICE' && add.addressStatus !== 'DISABLED');
    const factoryAddress = this.allAddressess.filter(add => add.facilityType === 'FACTORY' && add.addressStatus !== 'DISABLED');
    let confirmedOfficeAdd = 0;
    let confirmedFactoryAdd = 0;
    this.allAddressCount = '';
    const totalAddressCount = this.allAddressess.length;

    if (officeAddress.length > 0) {
      confirmedOfficeAdd = officeAddress.filter(
        (o) => o.isConfirmed === true
      ).length;
      this.allAddressCount = `${confirmedOfficeAdd}/${
        officeAddress.length
      }${i18n.t('pages.profile.office_add_confirmed')}`;
    }

    if (factoryAddress.length > 0) {
      confirmedFactoryAdd = factoryAddress.filter(
        (f) => f.isConfirmed === true
      ).length;
      if (officeAddress.length > 0) {
        this.allAddressCount =
          this.allAddressCount +
          `${i18n.t('pages.profile.and')} ${confirmedFactoryAdd}/${
            factoryAddress.length
          }${i18n.t('pages.profile.factory_add_confirmed')}`;
      } else {
        this.allAddressCount = `${confirmedFactoryAdd}/${
          factoryAddress.length
        }${i18n.t('pages.profile.factory_add_confirmed')}`;
      }

      if (officeAddress.length > 0 && factoryAddress.length > 0) {
        this.allAddressCount =
          `${confirmedOfficeAdd}/${officeAddress.length} ${i18n.t(
            'pages.profile.office'
          )} ` +
          `${i18n.t('pages.profile.and')} ${confirmedFactoryAdd}/${
            factoryAddress.length
          }${i18n.t('pages.profile.factory_add_confirmed')}`;
      }
    }

    this.allAddressConfirmed =
      totalAddressCount === confirmedOfficeAdd + confirmedFactoryAdd
        ? true
        : false;
  }

  // TTD-4477, for adding validations on firstname
  private validateName(event: any, type: string): void {
    var specials=/[@()[\];:<>, ]/;
    switch (type) {
      case "firstName":
        if (event.length < 2) {
            this.firstNameError = true;
        } else {
            this.firstNameError = false;
        }
        if (specials.test(event) && !(event.length < 2)) {
            this.fNameSpecCharErr = true;
        } else {
            this.fNameSpecCharErr = false;
        }
      break;

      default:
        break;
    }
  }

  // TTD-4477, for adding validations on firstname
  private get relatedValidations(): boolean {
    return this.firstNameError || this.fNameSpecCharErr;
  }

  private async validateField(
    event: any,
    fieldtype: string,
    addresstype: string,
    index: number
  ): Promise<void> {
    const cityDropdownArray = this.$refs[`city_${index}`] as any;
    const stateDropdownArray = this.$refs[
      `state_${index}`
    ] as any;

    if (cityDropdownArray.length == 0 || stateDropdownArray.length == 0) {
      return;
    }
    const stateDropdown = stateDropdownArray[0];
    const cityDropdown = cityDropdownArray[0];

    switch (fieldtype) {
      case 'country':
        // switch (addresstype) {
        //   case 'office':
        //     this.officeAddress[index].country = '';
        //     this.officeAddress[index].countryInFull = '';
        //     this.officeAddress[index].state = '';
        //     this.officeAddress[index].city = '';
        //     break;

        //   case 'factory':
        //     this.factoryAddresses[index].country = '';
        //     this.factoryAddresses[index].countryInFull = '';
        //     this.factoryAddresses[index].state = '';
        //     this.factoryAddresses[index].city = '';
        //     break;
        // }
        this.allAddressess[index].country = '';
        this.allAddressess[index].countryInFull = '';
        this.allAddressess[index].state = '';
        this.allAddressess[index].city = '';

        this.countryErrorList[`country_${index}`] =
          event.fieldKey.length < 2 ? true : false;

        this.statesDropdown[`state_${index}`] = [
          { value: '', text: i18n.t('pages.invitation.loading').toString() },
        ];
        this.citiesDropdown[`city_${index}`] = [];
        stateDropdown.clearValues();
        cityDropdown.clearValues();

        if (
          this.countryErrorList[`country_${index}`] === false
        ) {
          const countriesList = await this.cscService.getAllCountries();
          const country = countriesList.find(
            (x: { iso2: any }) => x.iso2 == event.fieldKey
          );

          let states = [];
          if (country !== undefined) {
            // switch (addresstype) {
            //   case 'office':
            //     this.officeAddress[index].country = event.fieldKey;
            //     states = await this.cscService.getStatesOfCountry(
            //       this.officeAddress[index].country
            //     );
            //     break;

            //   case 'factory':
            //     this.factoryAddresses[index].country = event.fieldKey;
            //     states = await this.cscService.getStatesOfCountry(
            //       this.factoryAddresses[index].country
            //     );
            //     break;
            // }
            this.allAddressess[index].country = event.fieldKey;
            states = await this.cscService.getStatesOfCountry(
              this.allAddressess[index].country
            );

            this.statesDropdown[`state_${index}`] = [];
            if (states !== undefined) {
              const stateList: DropdownModel[] = [];
              states.forEach((state: { iso2: string; name: string }) => {
                stateList.push({
                  value: state.iso2,
                  text: state.name,
                });
              });
              this.statesDropdown[`state_${index}`] = stateList;
            }

            if (!states || states.length == 0) {
              this.statesDropdown[`state_${index}`] = [
                {
                  value: '',
                  text: i18n.t('errors.no_records_found').toString(),
                },
              ];
            }
          } else {
            this.statesDropdown[`state_${index}`] = [];
          }
        } else {
          this.statesDropdown[`state_${index}`] = [];
        }
        this.statesDropdown = Object.assign({}, this.statesDropdown, {});
        this.compareAddressClone('country', index);
        break;

      case 'state':
        if (
          (this.statesDropdown[`state_${index}`] === undefined &&
            !event.fieldKey) ||
          event.fieldKey.length == 0
        ) {
          let country = this.allAddressess[index].country !== '' ? this.allAddressess[index].country : '';
          // country =
          //   addresstype == 'factory'
          //     ? this.factoryAddresses[index].country
          //     : country;

          let countryInFull = this.allAddressess[index].countryInFull !== '' ? this.allAddressess[index].countryInFull : '';
          // countryInFull =
          //   addresstype == 'factory'
          //     ? this.factoryAddresses[index].countryInFull
          //     : countryInFull;

          this.validateField(
            { fieldKey: country, fieldValue: countryInFull },
            'country',
            addresstype,
            index
          );
          return;
        }

        if (
          (!event.fieldKey || event.fieldKey.length == 0) &&
          this.statesDropdown[`state_${index}`].length > 0
        ) {
          this.stateErrorList[`state_${index}`] =
            event.fieldValue == i18n.t('errors.no_records_found').toString() ||
            event.fieldValue == i18n.t('pages.invitation.loading').toString()
              ? true
              : false;
          return;
        }

        // switch (addresstype) {
        //   case 'office':
        //     this.officeAddress[index].city = '';
        //     break;

        //   case 'factory':
        //     this.factoryAddresses[index].city = '';
        //     break;
        // }
        this.allAddressess[index].city = '';

        this.stateErrorList[`state_${index}`] =
          event.fieldValue.length < 2 ? true : false;

        this.citiesDropdown[`city_${index}`] = [
          { value: '', text: i18n.t('pages.invitation.loading').toString() },
        ];
        cityDropdown.clearValues();

        if (this.stateErrorList[`state_${index}`] === false) {
          let country = this.allAddressess[index].country !== '' ? this.allAddressess[index].country : '';
          // country =
          //   addresstype == 'factory'
          //     ? this.factoryAddresses[index].country
          //     : country;

          // switch (addresstype) {
          //   case 'office':
          //     this.officeAddress[index].state = event.fieldValue;
          //     break;

          //   case 'factory':
          //     this.factoryAddresses[index].state = event.fieldValue;
          //     break;
          // }
          this.allAddressess[index].state = event.fieldValue;

          if (event.fieldKey && event.fieldKey.length > 0) {
            const cities = await this.cscService.getCitiesOfState(
              country,
              event.fieldKey
            );
            if (cities !== undefined) {
              const cityList: DropdownModel[] = [];
              cities.forEach((city: { id: any; name: any }) => {
                cityList.push({ value: city.id, text: city.name });
              });
              this.citiesDropdown[`city_${index}`] = cityList;
            }
          } else {
            this.citiesDropdown[`city_${index}`] = [];
          }
        } else {
          this.citiesDropdown[`city_${index}`] = [];
        }
        this.citiesDropdown = Object.assign({}, this.citiesDropdown, {});
        this.stateErrorList = Object.assign({}, this.stateErrorList, {});
        this.compareAddressClone('state', index);
        break;

      case 'city':
        // if (addresstype == 'office') {
        //   if (event.fieldValue == '') {
        //     this.officeAddress[index].city = '';
        //   }
        // } else {
        //   if (event.fieldValue == '') {
        //     this.factoryAddresses[index].city = '';
        //   }
        // }
        this.allAddressess[index].city = '';
        if (this.citiesDropdown[`city_${index}`] === undefined) {
          let country = this.allAddressess[index].country !== '' ? this.allAddressess[index].country : '';
          // country =
          //   addresstype == 'factory'
          //     ? this.factoryAddresses[index].country
          //     : country;

          let state = this.allAddressess[index].state !== '' ? this.allAddressess[index].state : '';
          // state =
          //   addresstype == 'factory'
          //     ? this.factoryAddresses[index].state
          //     : state;

          if (
            this.statesDropdown[`state_${index}`] === undefined
          ) {
            const states = await this.cscService.getStatesOfCountry(country);
            this.statesDropdown[`state_${index}`] = [];
            if (states !== undefined) {
              const stateList: DropdownModel[] = [];
              states.forEach((state: { iso2: string; name: string }) => {
                stateList.push({
                  value: state.iso2,
                  text: state.name,
                });
              });
              this.statesDropdown[`state_${index}`] = stateList;
            }
          }

          if (
            this.statesDropdown[`state_${index}`] === undefined
          ) {
            return;
          }

          const stateKeyValue = this.statesDropdown[
            `state_${index}`
          ].filter(
            (item: { value: string; text: string }) => item.text == state
          );
          let cities = undefined;
          if (stateKeyValue.length > 0) {
            cities = await this.cscService.getCitiesOfState(
              country,
              stateKeyValue.pop().value
            );
          }

          if (cities !== undefined) {
            const cityList: DropdownModel[] = [];
            cities.forEach((city: { id: any; name: any }) => {
              cityList.push({ value: city.id, text: city.name });
            });
            this.citiesDropdown[`city_${index}`] = cityList;
          }
          this.citiesDropdown = Object.assign({}, this.citiesDropdown, {});
        }

        this.cityErrorList[`city_${index}`] =
          event.fieldValue.length < 2 ||
          event.fieldValue == i18n.t('errors.no_records_found').toString()
            ? true
            : false;
        if (this.cityErrorList[`city_${index}`] === false) {
          // switch (addresstype) {
          //   case 'office':
          //     this.officeAddress[index].city = event.fieldValue;
          //     break;

          //   case 'factory':
          //     this.factoryAddresses[index].city = event.fieldValue;
          //     break;
          // }
          this.allAddressess[index].city = event.fieldValue;
        }
        this.cityErrorList = Object.assign({}, this.cityErrorList, {});
        this.compareAddressClone('city', index);
        break;
      default:
        break;
    }
    this.checkLocationError();
    // if (addresstype == 'factory') {
    //   this.checkAddressDisabled(this.factoryAddresses[index]);
    //   this.checkAddressLookupAvailable(this.factoryAddresses[index], true);
    // } else {
      this.checkAddressDisabled(this.allAddressess[index]);
      this.checkAddressLookupAvailable(this.allAddressess[index], true);
    // }
    // this.countryStateCityValidation();
  }

  private factoryAddrDiffersCheck(): void {
    if (this.company.factoryAddressDiffers) {
      if (this.company.factoryAddresses.length === 0) {
        this.factoryAddresses.push(new AddressModel());
      }
    }
    this.addressAddedRemoved = true;
  }

  private openQrCodeModal(): void {
    if (!this.showQrCodeModal) {
      this.showQrCodeModal = true;
    }
  }

  private closeQrCodeModal(): void {
    if (this.showQrCodeModal) {
      this.showQrCodeModal = false;
    }
  }

  private openFirstOfficeAddressAndTakePictures(): void {
    if (this.officeAddress.length > 0 || this.factoryAddresses.length > 0) {
      const address =
        this.officeAddress.length > 0
          ? this.officeAddress[0]
          : this.factoryAddresses[0];
      const address_type =
        this.officeAddress.length > 0 ? 'office-address' : 'factory-address';
      this.updateAddressMobile(address.id, address_type, 1);
      setTimeout(
        (app: any) => {
          app.companyPicturesState = 'upload';
        },
        200,
        this
      );
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('pages.profile.add_factory_address').toString()
      );
    }
  }

  private updateAddressMobile(
    addressId: string,
    addressType: string,
    position: number
  ): void {
    if (!this.isAdmin) {
      return;
    }
    if (position <= 0) {
      return;
    }
    if (addressType === 'office-address') {
      if (this.officeFacilityAdd[position - 1].addressStatus === 'DISABLED') {
        return;
      }
    } else {
      if (this.factoryFacilityAdd[position - 1].addressStatus === 'DISABLED') {
        return;
      }
    }
    let addressDetails: AddressModel;
    if (!this.showOfficeAddressMobile) {
      this.curAddressId = addressId;
      this.curAddressTitle =
        addressType == 'factory-address'
          ? i18n
              .t('pages.profile.factory_address', [
                position,
              ])
              .toString()
          : i18n
              .t('pages.profile.office_address', [
                position,
              ])
              .toString();
      if (addressType == 'office-address') {
        addressDetails = this.officeFacilityAdd[position - 1];
      } else {
        if (position <= 0) {
          return;
        }
        addressDetails = this.factoryFacilityAdd[position - 1];
      }

      this.curAddressDetails = `${addressDetails.line1}`;
      if (addressDetails.line2.trim().length > 0) {
        this.curAddressDetails += `, ${addressDetails.line2}`;
      }
      if (addressDetails.postal.trim().length > 0) {
        this.curAddressDetails += `, ${addressDetails.postal} `;
      } else {
        this.curAddressDetails += `, `;
      }
      if (addressDetails.city.trim().length > 0) {
        this.curAddressDetails += `${addressDetails.city}`;
      }
      if (addressDetails.state.trim().length > 0) {
        this.curAddressDetails += `, ${addressDetails.state}`;
      }
      this.curAddressDetails += `, ${i18n
        .t('countries.' + addressDetails.country)
        .toString()}`;
      this.showOfficeAddressMobile = true;
    }
  }

  private closeOfficeAddressMobile(): void {
    if (this.showOfficeAddressMobile) {
      this.showOfficeAddressMobile = false;
      this.companyPicturesState = '';
      this.curAddressId = '';
      this.curAddressTitle = '';
      this.curAddressDetails = '';
    }
  }

  private checkAddressDisabled(address: AddressModel): void {
    const invitationId = localStorage.getItem('invitationId');
    if (address.country !== null || address.country !== '') {
      const index = this.countries.findIndex((c) => c.value == address.country);
      if(index !== -1) {
        address.countryInFull = this.countries[index].text;
      }
    } else {
      address.countryInFull = '';
    }
    if (invitationId) {
      address.disabled = false;
      address.lookupAvailable = true;
    } else {
      if (
        ((address.geoLong == null || address.geoLong === 0) &&
          (address.geoLat == null || address.geoLat === 0)) ||
        address.forceEnabled
      ) {
        address.disabled = false;
      } else {
        address.disabled = true;
      }
      if (
        address.city == null ||
        address.country == null ||
        address.state == null ||
        address.city == '' ||
        address.country == '' ||
        address.state == '' ||
        address.line1 == null ||
        address.officeName == null ||
        address.postal == null ||
        address.companySubtypes.length == 0
      ) {
        address.lookupAvailable = false;
      } else {
        address.lookupAvailable = true;
      }
      if (address.isConfirmed || address.disabled) {
        address.lookupAvailable = false;
      }
    }
    address.confirmError = false;
  }

  // remove call this function on save and update store
  private updateStore(): void {
    this.company.officeAddress = this.officeAddress;
    this.company.factoryAddresses = this.factoryAddresses;
    store.commit('setMyAccount', this.company);
  }

  private checkAddressLookupAvailable(
    address: AddressModel,
    toCheckConfirmed?: boolean
  ): void {
    if (toCheckConfirmed) {
      if (
        address.line1 == '' ||
        address.postal === '' ||
        address.city === '' ||
        address.country === '' ||
        address.state === ''
      ) {
        address.lookupAvailable = false;
        address.isEdited = false;
      } else {
        address.lookupAvailable = true;
        address.isEdited = true;
      }
    } else if (
      address.line1 === '' ||
      address.postal === '' ||
      address.city === '' ||
      address.country === '' ||
      address.state === '' ||
      (address.geoLong != null && address.geoLong !== 0) ||
      (address.geoLat != null && address.geoLat !== 0)
    ) {
      address.lookupAvailable = false;
    } else {
      address.lookupAvailable = true;
    }
  }

  private setUserInformation(): void {
    this.contact = this.company.contact;
  }

  private removeFactoryAddress(index: number): void {
    this.addressAddedRemoved = true;
    this.factoryAddresses.splice(index, 1);
  }

  // remove
  private addOfficeAddress(): void {
    this.scrollToBottom();
    this.addressAddedRemoved = true;
    this.officeAddress.push(new AddressModel());
    this.officeAddress[this.officeAddress.length - 1].sequence =
      this.officeAddress.length - 1;
  }

  // remove
  private addFactoryAddress(): void {
    this.scrollToBottom();
    this.addressAddedRemoved = true;
    this.factoryAddresses.push(new AddressModel());
    this.factoryAddresses[this.factoryAddresses.length - 1].sequence =
      this.factoryAddresses.length - 1;
  }

  private addAddress(): void {
    this.scrollToBottom();
    this.allAddressess.push(new AddressModel());
    this.allAddressessClone.push(new AddressModel());
    this.allAddressess[this.allAddressess.length - 1].sequence = this.allAddressess.length - 1;
    this.counter++;
    this.isCompanyDetailsInValid = false;
  }

  private removeOfficeAddress(index: number): void {
    this.addressAddedRemoved = true;
    this.officeAddress.splice(index, 1);
  }

  private openDefaultProfileTextModal(): void {
    this.showDefaultProfileTextModal = true;
  }

  private closeDefaultProfileTextModal(): void {
    this.showDefaultProfileTextModal = false;
  }

  private selectTemplate(template: number): void {
    const profileText = document.getElementById(
      `profile-template-${template}`
    ) as HTMLElement;
    this.company.profile = profileText.innerHTML.trim();
    this.closeDefaultProfileTextModal();
    this.compareCompanyClone('profile');
  }

  // private confirmOfficeAddress(index: number): void {
  //   this.companyAddresses = [];
  //   this.officeAddress[index].isOfficeAddress = true;
  //   this.companyAddresses.push(this.officeAddress[index]);
  //   this.showConfirmAddressModal = true;
  // }

  private confirmAddress(index: number, addressType: string): void {
    if (this.confirmGeolocationPermission === 1 || this.invitationId !== null) {
      this.companyAddresses = [];
      let address: AddressModel = new AddressModel();
      let allConfirmed: boolean = true;
      switch (addressType) {
        case 'ALL':
          this.allAddressess.forEach((o) => {
            if (!o.isConfirmed) {
              allConfirmed = false;
              // o.isOfficeAddress = true;
              this.companyAddresses.push(o);
            }
          });
          // this.factoryAddresses.forEach((f) => {
          //   if (!f.isConfirmed) {
          //     allConfirmed = false;
          //     f.isOfficeAddress = false;
          //     this.companyAddresses.push(f);
          //   }
          // });
          break;
        case 'FACILITY':
          address = this.allAddressess[index];
          allConfirmed = false;
          address.isOfficeAddress = address.facilityType === "OFFICE" ? true : false;
          this.companyAddresses.push(address);
          break;

        // case 'FACTORY':
        //   address = this.factoryAddresses[index];
        //   allConfirmed = false;
        //   address.isOfficeAddress = false;
        //   this.companyAddresses.push(address);
        //   break;
      }
      if (allConfirmed) {
        return;
      }
      this.addressType = addressType;
      this.addIndex = index;
      this.showConfirmAddressModal = true;
    }
  }

  // private confirmAllLocation(): void {
  //   this.companyAddresses = [];
  //   this.officeAddress.forEach((o) => {
  //     if (o.geoLat === 0 && o.geoLong === 0) {
  //       o.isOfficeAddress = true;
  //       this.companyAddresses.push(o);
  //     }
  //   });
  //   this.factoryAddresses.forEach((f) => {
  //     if (f.geoLat === 0 && f.geoLong === 0) {
  //       f.isOfficeAddress = false;
  //       this.companyAddresses.push(f);
  //     }
  //   });
  //   this.showConfirmAddressModal = true;
  // }

  private closeMapModal(): void {
    this.showConfirmAddressModal = false;
  }

  private companySubtypeSelected(types: number[]): void {
    this.company.companySubtypes = types;
    this.compareCompanyClone('companySubtypes');
  }

  private addressSubtypeSelected(
    types: number[],
    addressType: string | null,
    addIndex: number
  ): void {

    this.allAddressess[addIndex].companySubtypes = lodash.uniq(types);

    if (types.length == 0) {
      this.allAddressess[addIndex].companySubtypes = lodash.uniq(types);
      this.allAddressess[addIndex].lookupAvailable = false;
    }
    // if (addressType == 'factory') {
    //   this.factoryAddresses[officeIndex].companySubtypes = lodash.uniq(types);

    //   if (types.length == 0) {
    //     this.factoryAddresses[officeIndex].lookupAvailable = false;
    //   }
    // } else {
    //   this.officeAddress[officeIndex].companySubtypes = lodash.uniq(types);

    //   if (types.length == 0) {
    //     this.officeAddress[officeIndex].lookupAvailable = false;
    //   }
    // }
    this.compareAddressClone('companySubtypes', addIndex);
  }

  private categorySelected(categories: number[]): void {
    this.company.categories = categories;
    this.compareCompanyClone('categories');
  }

  private productGroupSelected(productGroups: number[]): void {
    this.company.productGroups = productGroups;
    this.compareCompanyClone('productGroups');
  }

  private allOfficeAddressConfirmed(): void {
    for (let index = 0; index < this.officeAddress.length; index++) {
      const addr = this.officeAddress[index];
      if (
        addr.geoLong != null &&
        addr.geoLong !== 0 &&
        addr.geoLat != null &&
        addr.geoLat !== 0 &&
        addr.postal !== ''
      ) {
        this.officeAddress[index].isConfirmed = true;
        this.officeAddress[index].disabled = true;
        this.officeAddress[index].forceEnabled = false;
        this.officeAddrDisabled.push(true);
      } else {
        this.officeAddress[index].isConfirmed = false;
        this.officeAddress[index].disabled = false;
        this.officeAddress[index].forceEnabled = true;
        this.officeAddrDisabled.push(false);
      }
      if (this.officeAddress.findIndex((o) => o.sequence !== 0) === -1) {
        this.officeAddress[index].sequence = index;
      }
    }
  }

  private allFactoryAddressConfirmed(): void {
    for (let index = 0; index < this.factoryAddresses.length; index++) {
      const addr = this.factoryAddresses[index];
      if (
        addr.geoLong != null &&
        addr.geoLong !== 0 &&
        addr.geoLat != null &&
        addr.geoLat !== 0 &&
        addr.postal !== ''
      ) {
        this.factoryAddresses[index].isConfirmed = true;
        this.factoryAddresses[index].disabled = true;
        this.factoryAddresses[index].forceEnabled = false;
      } else {
        this.factoryAddresses[index].isConfirmed = false;
        this.factoryAddresses[index].disabled = false;
        this.factoryAddresses[index].forceEnabled = true;
      }
      if (this.factoryAddresses.findIndex((o) => o.sequence !== 0) === -1) {
        this.factoryAddresses[index].sequence = index;
      }
    }
  }

  private enableAddress(index: number): void {
    this.addressIndex = index;
    this.showEditWarningModal = true;
    this.isCompanyDetailsInValid = false;
  }

  private enableAddressYes(): void {
    this.showEditWarningModal = false;
    const index = this.addressIndex;
    const address = this.allAddressess[index];
    address.disabled = false;
    address.forceEnabled = true;
    this.allAddressess.splice(index, 1, address);
    // this.officeAddress[index] = officeAddress;
    // const add_index = index;
    // this.officeAddress.splice(index, 1);
    // this.officeAddress.splice(add_index, 0, officeAddress);
    this.addressIndex = -1;
    this.allConfirmedAddress();
  }

  private enableAddressNo(): void {
    this.showEditWarningModal = false;
    this.addressIndex = -1;
  }

  // remove
  // private enableOfficeAddress(index: number): void {
  //   this.indexOffice = index;
  //   this.showEditWarningModal = true;
  // }

  // remove
  // private enableOfficeAddressYes(): void {
  //   this.showEditWarningModal = false;
  //   const index = this.indexOffice;
  //   const officeAddress = this.officeAddress[index];
  //   officeAddress.disabled = false;
  //   officeAddress.forceEnabled = true;
  //   this.officeAddress[index] = officeAddress;
  //   const add_index = index;
  //   this.officeAddress.splice(index, 1);
  //   this.officeAddress.splice(add_index, 0, officeAddress);
  //   this.indexOffice = -1;
  //   this.allConfirmedAddress();
  // }

  // remove
  // private enableOfficeAddressNo(): void {
  //   this.showEditWarningModal = false;
  //   this.indexOffice = -1;
  // }

  // remove
  // private enableFactoryAddress(index: number): void {
  //   this.isFactoryAddress = true;
  //   this.indexFactory = index;
  //   this.showEditWarningModal = true;
  // }

  // remove
  // private enableFactoryAddressYes(): void {
  //   this.showEditWarningModal = false;
  //   const index = this.indexFactory;
  //   const factoryAddress = this.factoryAddresses[index];
  //   factoryAddress.disabled = false;
  //   factoryAddress.forceEnabled = true;
  //   this.factoryAddresses[index] = factoryAddress;
  //   const add_index = index;
  //   this.factoryAddresses.splice(index, 1);
  //   this.factoryAddresses.splice(add_index, 0, factoryAddress);
  //   this.indexFactory = -1;
  //   this.isFactoryAddress = false;
  //   this.allConfirmedAddress();
  // }

  // remove
  // private enableFactoryAddressNo(): void {
  //   this.showEditWarningModal = false;
  //   this.isFactoryAddress = false;
  //   this.indexFactory = -1;
  // }

  private isAddressConfirmed(address: AddressModel): boolean {
    return (
      address.geoLong != null &&
      address.geoLong !== 0 &&
      address.geoLat != null &&
      address.geoLat !== 0 &&
      address.isConfirmed === true
    );
  }

  private async addressConfirmed(addresses: AddressModel[]): Promise<void> {
    try {
      switch (this.addressType) {
        case 'ALL':
          addresses.forEach((a) => {
            // if (a.isOfficeAddress) {
              const index = this.allAddressess.findIndex(
                (o) =>
                  o.officeName === a.officeName &&
                  o.line1 === a.line1 &&
                  o.line2 === a.line2
              );
              a.forceEnabled = false;
              a.isEdited = false;
              this.allAddressess[index].confirmError = false;
              this.allAddressess.splice(index, 1);
              this.allAddressess.splice(index, 0, a);
            // } else {
            //   const index = this.factoryAddresses.findIndex(
            //     (o) =>
            //       o.officeName === a.officeName &&
            //       o.line1 === a.line1 &&
            //       o.line2 === a.line2
            //   );
            //   a.forceEnabled = false;
            //   a.isEdited = false;
            //   this.factoryAddresses[index].confirmError = false;
            //   this.factoryAddresses.splice(index, 1);
            //   this.factoryAddresses.splice(index, 0, a);
            // }
          });
          // this.allConfirmedAddress();
          break;
        case 'FACILITY':
          addresses.forEach((a) => {
            const index = this.allAddressess.findIndex(
              (o) =>
                o.officeName === a.officeName &&
                o.line1 === a.line1 &&
                o.line2 === a.line2
            );
            a.forceEnabled = false;
            a.isEdited = false;
            this.allAddressess[index].confirmError = false;
            this.allAddressess.splice(index, 1);
            this.allAddressess.splice(index, 0, a);
          });
          break;
        // case 'FACTORY':
        //   addresses.forEach((a) => {
        //     const index = this.factoryAddresses.findIndex(
        //       (o) =>
        //         o.officeName === a.officeName &&
        //         o.line1 === a.line1 &&
        //         o.line2 === a.line2
        //     );
        //     a.forceEnabled = false;
        //     a.isEdited = false;
        //     this.factoryAddresses[index].confirmError = false;
        //     this.factoryAddresses.splice(index, 1);
        //     this.factoryAddresses.splice(index, 0, a);
        //   });
        //   break;
      }
      this.closeMapModal();
      this.allConfirmedAddress();
    } catch {
      //
    } finally {
      this.isCompanyDetailsInValid = false;
    }
  }

  private checkLocationError(): void {
    let isError = false;
    this.allAddressess.forEach((add) => {
      if (add.country == '' || add.country == null) {
        add.countryError = true;
        isError = true;
      }
      if (add.state == '' || add.state == null) {
        add.stateError = true;
        isError = true;
      }
      if (add.city == '' || add.city == null) {
        add.cityError = true;
        isError = true;
      }
    });
    // if (this.company.factoryAddressDiffers) {
    //   this.factoryAddresses.forEach((f) => {
    //     if (f.country == '' || f.country == null) {
    //       f.countryError = true;
    //       isError = true;
    //     }
    //     if (f.state == '' || f.state == null) {
    //       f.stateError = true;
    //       isError = true;
    //     }
    //     if (f.city == '' || f.city == null) {
    //       f.cityError = true;
    //       isError = true;
    //     }
    //   });
    // }
    if (isError && !this.isMobile) {
      this.locationError = true;
    } else {
      this.locationError = false;
    }
  }

  private addressConfirmError(): boolean {
    let addressNotConfirmed = false;
    this.allAddressess.forEach((add) => {
      if (add.lookupAvailable) {
        add.confirmError = true;
        addressNotConfirmed = true;
      }
    });
    // this.factoryAddresses.forEach((f) => {
    //   if (f.lookupAvailable) {
    //     f.confirmError = true;
    //     addressNotConfirmed = true;
    //   }
    // });
    return addressNotConfirmed;
  }

  private checkRequiredValidation(): boolean {
    let invalidCount = 0;
    if (this.company.companyName === '') {
      this.company.inValid.isCompanyNameEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isCompanyNameEmpty = false;
    }
    if (this.company.companyType === 0) {
      this.company.inValid.isCompanyTypeEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isCompanyTypeEmpty = false;
    }
    if (this.company.companySubtypes.length === 0) {
      this.company.inValid.isCompanySubtypeEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isCompanySubtypeEmpty = false;
    }
    if (this.company.categories.length === 0) {
      this.company.inValid.isCategoryEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isCategoryEmpty = false;
    }
    if (this.company.productGroups.length === 0) {
      this.company.inValid.isProductGroupEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isProductGroupEmpty = false;
    }
    if (this.company.profile === '') {
      this.company.inValid.isProfileEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isProfileEmpty = false;
    }
    if (this.company.profile === '') {
      this.company.inValid.isProfileEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isProfileEmpty = false;
    }
    if (this.contact.firstName === '') {
      this.company.inValid.isFirstNameEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isFirstNameEmpty = false;
    }
    if (this.contact.lastName === '') {
      this.company.inValid.isLastNameEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isLastNameEmpty = false;
    }
    if (this.contact.email === '') {
      this.company.inValid.isEmailEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isEmailEmpty = false;
    }
    if (this.contact.phone === '' || this.contact.phone.trim().length < 10) {
      this.company.inValid.isPhoneEmpty = true;
      invalidCount++;
    } else {
      this.company.inValid.isPhoneEmpty = false;
    }
    this.allAddressess.filter(add => add.addressStatus !== 'DISABLED').forEach((add, index) => {
      add.inValid.isAddTypeEmpty = add.facilityType !== '' ? false : true;
      add.inValid.isAddNameEmpty = add.officeName !== '' ? false : true;
      add.inValid.isAddLine1Empty = add.line1 !== '' ? false : true;
      add.inValid.isAddPostalEmpty = add.postal !== '' ? false : true;
      add.inValid.isAddCompanySubtypeEmpty = add.companySubtypes.length > 0 ? false : true;
      add.inValid.isAddCountryEmpty = add.country !== '' ? false : true;
      add.countryError = add.country !== '' ? false : true;
      add.inValid.isAddStateEmpty = add.state !== '' ? false : true;
      add.stateError = add.state !== '' ? false : true;
      add.inValid.isAddCityEmpty = add.city !== '' ? false : true;
      add.cityError = add.city !== '' ? false : true;
      add.inValid.isAddEmployeesEmpty = add.employees !== '' ? false : true;
      add.inValid.isAddMaleEmployeesEmpty = add.maleEmployees !== '' ? false : true;
      add.inValid.isAddFemaleEmployeesEmpty = add.femaleEmployees !== '' ? false : true;
      add.inValid.isAddMonthlyCapacityEmpty = add.monthlyCapacity !== '' ? false : true;
      add.inValid.isAddMonthlyCapacityUnitEmpty = add.monthlyCapacityUnit !== '' ? false : true;
      if (add.lookupAvailable) {
        add.confirmError = true;
      }
      if(add.inValid.isAddTypeEmpty
        || add.inValid.isAddNameEmpty
        || add.inValid.isAddLine1Empty
        || add.inValid.isAddPostalEmpty
        || add.inValid.isAddCompanySubtypeEmpty
        || add.inValid.isAddCountryEmpty || add.countryError
        || add.inValid.isAddStateEmpty || add.stateError
        || add.inValid.isAddCityEmpty || add.cityError
        || add.inValid.isAddEmployeesEmpty
        || add.inValid.isAddMaleEmployeesEmpty
        || add.inValid.isAddFemaleEmployeesEmpty
        || add.inValid.isAddMonthlyCapacityEmpty
        || add.inValid.isAddMonthlyCapacityUnitEmpty || add.confirmError) {
        invalidCount++;
        add.isExpanded = true;
        this.allAddressess.splice(index, 1, add);
      }
    });
    return invalidCount > 0 ? true : false;
  }

  private async save(): Promise<void> {
    try {
      // const observer = this.$refs.observer as InstanceType<
      //   typeof ValidationObserver
      // >;
      // let valid = await observer.validate();
      // if (this.company.companyType === 0) {
      //   valid = false;
      //   this.isValidType = false;
      // }
      // if (!valid) {
      //   this.isValid = false;
      //   return;
      // }
      if(this.checkRequiredValidation()) {
        this.isCompanyDetailsInValid = true;
        return;
      }
      // if (this.addressConfirmError()) {
      //   return;
      // }

      // this.checkLocationError();

      // if (this.locationError && !this.isMobile) {
      //   return;
      // }
      this.contact.phone = this.contact.phone.trim();
      if (this.contact.phone.length < 10) {
        return;
      }

      this.admin.firstName = this.admin.firstName.trim();
      this.admin.lastName = this.admin.lastName.trim();
      this.admin.email = this.admin.email.trim();
      this.admin.phone = this.admin.phone.trim();

      this.contact.firstName = this.contact.firstName.trim();
      this.contact.lastName = this.contact.lastName.trim();
      this.contact.email = this.contact.email.trim();

      this.isSaving = true;
      this.company.admin = this.admin;
      this.company.contact = this.contact;

      // remove
      // if (this.company.factoryAddressDiffers) {
      //   this.company.factoryAddresses = this.factoryAddresses;
      // } else {
      //   this.company.factoryAddresses = store.getters.savedFactoryAddresses;
      // }

      // TTD-3065
      // let isAddressInComplete: Boolean = false;
      // this.allAddressess.forEach((address) => {
      //   isAddressInComplete =
      //     address.facilityType == '' || address.facilityType == null
      //       ? true
      //       : isAddressInComplete;
      //   isAddressInComplete =
      //     address.officeName == '' || address.officeName == null
      //       ? true
      //       : isAddressInComplete;
      //   isAddressInComplete =
      //     address.line1 == '' || address.line1 == null
      //       ? true
      //       : isAddressInComplete;
      //   isAddressInComplete =
      //     address.postal == '' || address.postal == null
      //       ? true
      //       : isAddressInComplete;
      //   isAddressInComplete =
      //     address.country == '' || address.country == null
      //       ? true
      //       : isAddressInComplete;
      //   isAddressInComplete =
      //     address.state == '' || address.state == null
      //       ? true
      //       : isAddressInComplete;
      //   isAddressInComplete =
      //     address.city == '' || address.city == null
      //       ? true
      //       : isAddressInComplete;
      //   address.officeName = address.officeName.trim();
      //   address.line1 = address.line1.trim();
      //   address.line2 = address.line2.trim();
      //   address.postal = address.postal.trim();
      //   address.employees = address.employees === "" ? -1 : Number(address.employees);
      //   address.maleEmployees = address.maleEmployees === "" ? -1 : Number(address.maleEmployees);
      //   address.femaleEmployees = address.femaleEmployees === "" ? -1 : Number(address.femaleEmployees);
      //   address.monthlyCapacity = address.monthlyCapacity === "" ? -1 : Number(address.monthlyCapacity);
      // });

      const invitationId = localStorage.getItem('invitationId');

      if(invitationId){
        // TTD-3065
        this.allAddressess.forEach((address, index) => {
          address.id = address.id === this.guidEmpty || address.id === '' ? uuidv4() : address.id;
          address.facilityType = address.facilityType.trim();
          address.officeName = address.officeName.trim();
          address.line1 = address.line1.trim();
          address.line2 = address.line2.trim();
          address.postal = address.postal.trim();
          address.employees = address.employees === "" ? -1 : Number(address.employees);
          address.maleEmployees = address.maleEmployees === "" ? -1 : Number(address.maleEmployees);
          address.femaleEmployees = address.femaleEmployees === "" ? -1 : Number(address.femaleEmployees);
          address.monthlyCapacity = address.monthlyCapacity === "" ? -1 : Number(address.monthlyCapacity);
          address.monthlyCapacityUnit = address.monthlyCapacityUnit.trim();
          address.position = index;
          address.sequence = index;
        });
      } else {
        // TTD-3065
        this.allAddressess.forEach((address, index) => {
          address.id = (address.id === this.guidEmpty || address.id === '') ? uuidv4() : address.id;
          address.facilityType = address.facilityType.trim();
          address.officeName = address.officeName.trim();
          address.line1 = address.line1.trim();
          address.line2 = address.line2.trim();
          address.postal = address.postal.trim();
          address.employees = address.employees === "" ? -1 : Number(address.employees);
          address.maleEmployees = address.maleEmployees === "" ? -1 : Number(address.maleEmployees);
          address.femaleEmployees = address.femaleEmployees === "" ? -1 : Number(address.femaleEmployees);
          address.monthlyCapacity = address.monthlyCapacity === "" ? -1 : Number(address.monthlyCapacity);
          address.monthlyCapacityUnit = address.monthlyCapacityUnit.trim();
          address.position = index;
        });
      }
      this.company.officeAddress = [];
      this.company.factoryAddresses = [];
      if(this.allAddressess.length === 1){
        this.company.officeAddress.push(...this.allAddressess);
      } else {
        this.company.officeAddress = this.allAddressess.filter((address) => address.facilityType === 'OFFICE');
        this.company.factoryAddresses = this.allAddressess.filter((address) => address.facilityType === 'FACTORY');
        if(this.company.officeAddress.length === 0){
          this.company.factoryAddresses = [];
          this.company.officeAddress.push(this.allAddressess[0]);
          for(let i=1; i<this.allAddressess.length; i++){
            this.company.factoryAddresses.push(this.allAddressess[i]);
          }
        }
        this.company.factoryAddressDiffers = this.company.factoryAddresses.length > 0 ? true : false;
      }

      // if (
      //   invitationId &&
      //   this.company.factoryAddressDiffers &&
      //   // isAddressInComplete &&
      //   this.company.factoryAddresses.length == 1
      // ) {
      //   const officeAddress = this.company.officeAddress[0];
      //   this.company.factoryAddresses[0].line1 = officeAddress.line1;
      //   this.company.factoryAddresses[0].line2 = officeAddress.line2;
      //   this.company.factoryAddresses[0].postal = officeAddress.postal;
      //   this.company.factoryAddresses[0].city = officeAddress.city;
      //   this.company.factoryAddresses[0].state = officeAddress.state;
      //   this.company.factoryAddresses[0].country = officeAddress.country;
      // }

      // check if company name has been changed
      if (this.company.companyName !== this.companyName) {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        user.companyName = this.company.companyName;
        localStorage.setItem('user', JSON.stringify(user));
        this.company.companyNameUpdated = true;
      } else {
        this.company.companyNameUpdated = false;
      }

      this.company.companyName = this.company.companyName.trim();
      this.company.brands = this.company.brands.trim();
      this.company.profile = this.company.profile.trim();
      this.company.contact.firstName = this.company.contact.firstName.trim();
      this.company.contact.lastName = this.company.contact.lastName.trim();
      this.company.contact.email = this.company.contact.email.trim();
      this.company.contact.phone = this.company.contact.phone.trim();
      this.company.admin.firstName = this.company.admin.firstName.trim();
      this.company.admin.lastName = this.company.admin.lastName.trim();
      this.company.admin.email = this.company.admin.email.trim();
      this.company.admin.phone = this.company.admin.phone.trim();
      this.company.newFacilityData = "YES";
      if (this.profileSave === 'FAIL') {
        const user = new CompanyAdminRequestModel();
        user.Firstname = this.company.admin.firstName.trim();
        user.Lastname = this.company.admin.lastName.trim();
        user.Email = this.company.admin.email;
        user.Password = await this.cryptionHelper.decryptText(this.encryptPwd);

        const result = await this.middlewareService.createCompanyAdmin(user);
        if (result.result === 'success') {
          const supplier = new SigninSupplierModel();
          supplier.Email = user.Email.trim();
          supplier.Password = user.Password.trim();

          const response = await this.middlewareService.signinSupplier(
            supplier
          );
          if (response.response_type === 'success') {
            sessionStorage.setItem('access_token', response.access_token);
          }
        }
      }
      let response = new companyResponseModel();
      const cloneCompany = cloneDeep(this.company);
      cloneCompany.turnover = this.company.turnover === '' ? -1 : this.company.turnover;
      if (invitationId) {
        response = await this.invitationService.updateSupplierAsync(
          invitationId,
          cloneCompany
        );
      } else {
        response = await this.supplierService.updateSupplierAsync(cloneCompany);
      }

      if (
        response.result === 'success' &&
        response.responseObject.companyName !== ''
      ) {
        this.counter = 0;
        this.changedkeys = [];
        this.companyClone = lodash.cloneDeep(this.company);
        this.syncFacilityAddress();
        store.commit('setCanNavigate', true);
        store.commit('setMyAccount', response.responseObject);
        store.commit('setEncryptedPwd', '');
        this.changedSettings(this.counter);
        this.profileSave = 'SUCCESS';
        this.isSaving = false;
        this.showSave = false;
        if (this.invitationId !== '' && this.invitationId !== null) {
          this.invitationId = '';
          this.isAccountSave = true;
          this.isMainContactEmailDisabled = false;
        }
        await this.userService.loadUserInfoAsync(false);
        const user = this.$store.getters.user as UserModel;
        if (user.companyId === null || user.companyId === undefined) {
          const result = await this.client.getUserInfoAsync();
          this.$store.commit('setUser', result);
        }
        localStorage.setItem('invitationId', '');
        this.invitationId = '';
      } else if (
        response.result === 'fail' &&
        response.message ==
          'Error while saving account. Please try again with invitaion link'
      ) {
        NotificationHelper.createErrorNotification(
          'Your account update was not saved due to technical issues. Please click ‘Save’ again to successfully save your account details.'
        );
        this.profileSave = 'FAIL';
      }
    } finally {
      this.isSaving = false;
    }
  }

  private validateCompanyType(): void {
    this.updateStore();
    if (this.company.companyType === 0) {
      this.isValid = false;
      this.isValidType = false;
    } else {
      this.isValid = true;
      this.isValidType = true;
    }
    this.compareCompanyClone('companyType');
  }

  private companyPictureStateChanged(state: string): void {
    this.companyPicturesState = state;
  }

  private get getMobileAddreses(): AddressModel[] {
    const allAddresses: AddressModel[] = [];
    this.officeAddress.forEach((address) => {
      allAddresses.push(address);
    });
    this.factoryAddresses.forEach((address) => {
      allAddresses.push(address);
    });
    return allAddresses;
  }

  // remove
  // private countryStateCityValidation(): void {
  //   const officeAddresses = this.officeAddress;
  //   const factoryAddresses = this.factoryAddresses;
  //   let isInvalid = false;
  //   officeAddresses.forEach((o) => {
  //     if (
  //       (o.country == '' ||
  //         o.country == null ||
  //         o.state == '' ||
  //         o.state == null ||
  //         o.city == '' ||
  //         o.city == null) &&
  //       !this.isMobile &&
  //       o.id !== '00000000-0000-0000-0000-000000000000'
  //     ) {
  //       isInvalid = true;
  //     }
  //   });
  //   const invitationId = localStorage.getItem('invitationId');
  //   if (factoryAddresses.length > 0) {
  //     factoryAddresses.forEach((f) => {
  //       if (
  //         (f.country == '' ||
  //           f.country == null ||
  //           f.state == '' ||
  //           f.state == null ||
  //           f.city == '' ||
  //           f.city == null) &&
  //         !invitationId &&
  //         !this.isMobile &&
  //         f.id !== '00000000-0000-0000-0000-000000000000'
  //       ) {
  //         isInvalid = true;
  //       }
  //     });
  //   }
  //   this.isInvalidAddress = isInvalid;
  // }

  private scrollToBottom(): void {
    if (!this.isMobile) {
      setTimeout(
        (app: any) => {
          const pageScroller = document.getElementById(
            'right-container'
          ) as HTMLElement;
          if (pageScroller) {
            pageScroller.scrollTop = pageScroller.scrollHeight;
          }
        },
        0,
        this
      );
    }
  }
  private checkValidation(brands: string): void {
    const chars = brands.split(',');
    if (chars.length > 5) {
      this.isBrandInvalid = true;
    } else {
      this.isBrandInvalid = false;
    }
    this.compareCompanyClone('brands');
  }

  private toggleAddressStateToDisabled(
    index: number
  ): void {
    if (
      this.allAddressess.length <= 1 ||
      this.allAddressess.filter(
        (o) => o.addressStatus === 'ENABLE' || o.addressStatus === ''
      ).length <= 1
    ) {
      this.showDisableSingleAddressWarning = true;
    } else {
      this.showDisableMultipleAddressWarning = true;
    }
    this.allAddressess[index].confirmError = false;
    this.allAddressess[index].lookupAvailable = false;
    // if (addressType === 'OFFICE') {

    // }

    // if (addressType === 'FACTORY') {
    //   if (
    //     this.factoryAddresses.length <= 1 ||
    //     this.factoryAddresses.filter(
    //       (o) => o.addressStatus === 'ENABLE' || o.addressStatus === ''
    //     ).length <= 1
    //   ) {
    //     this.showDisableSingleAddressWarning = true;
    //   } else {
    //     this.showDisableMultipleAddressWarning = true;
    //   }
    //   this.factoryAddresses[index].confirmError = false;
    //   this.factoryAddresses[index].lookupAvailable = false;
    // }
    this.disableAddressIndex = index;
  }

  private hideDisableAddressWarning(): void {
    this.disableAddressType = '';
    this.disableAddressIndex = -1;
    this.showDisableSingleAddressWarning = false;
    this.showDisableMultipleAddressWarning = false;
  }

  private disableAddressAndReOrder(): void {
    // if (this.disableAddressType === 'OFFICE') {
      this.allAddressess[this.disableAddressIndex].addressStatus =
        AddressStatus.DISABLED;
        this.allAddressessClone[this.disableAddressIndex].addressStatus = AddressStatus.DISABLED;
    // }
    // if (this.disableAddressType === 'FACTORY') {
    //   this.factoryAddresses[this.disableAddressIndex].addressStatus =
    //     AddressStatus.DISABLED;
    // }
    this.sortForDisabledAddresses(this.disableAddressType);
    this.hideDisableAddressWarning();
    // this.compareAddressStatus();
    this.isCompanyDetailsInValid = false;
    this.counter++;
  }

  private toggleAddressStateToEnabled(
    index: number
  ): void {
    this.allAddressess[index].addressStatus = AddressStatus.ENABLE;
    const address = cloneDeep(this.allAddressess[index]);
    this.allAddressess.splice(index, 1);
    this.allAddressess.splice(address.sequence, 0, address);
    // update address clone
    this.allAddressessClone[index].addressStatus = AddressStatus.ENABLE;
    const addressClone = cloneDeep(this.allAddressess[index]);
    this.allAddressessClone.splice(index, 1);
    this.allAddressessClone.splice(addressClone.sequence, 0, addressClone);
    this.isCompanyDetailsInValid = false;
    this.counter++;
  }

  private sortForDisabledAddresses(addressType: string): void {
    // if (addressType === 'OFFICE') {
      const filterEnabledAddress = this.allAddressess.filter(add => add.addressStatus !== AddressStatus.DISABLED);
      const filterDisabledAddress = this.allAddressess.filter(add => add.addressStatus === AddressStatus.DISABLED);
      this.allAddressess = [];
      this.allAddressessClone = [];
      this.allAddressess.push(...filterEnabledAddress, ...filterDisabledAddress);
      this.allAddressessClone.push(...filterEnabledAddress, ...filterDisabledAddress);
      // this.allAddressess = this.officeAddress.sort(
      //   (a: AddressModel, b: AddressModel) => {
      //     if (a.addressStatus === AddressStatus.DISABLED) {
      //       return 1;
      //     }
      //     if (b.addressStatus === AddressStatus.DISABLED) {
      //       return -1;
      //     }
      //     return 0;
      //   }
      // );
    // }

  //   if (addressType === 'FACTORY') {
  //     this.factoryAddresses = this.factoryAddresses.sort(
  //       (a: AddressModel, b: AddressModel) => {
  //         if (a.addressStatus === AddressStatus.DISABLED) {
  //           return 1;
  //         }
  //         if (b.addressStatus === AddressStatus.DISABLED) {
  //           return -1;
  //         }
  //         return 0;
  //       }
  //     );
  //   }
  }

  private removeAddress(addressIndex: number): void {
    if(this.allAddressess[addressIndex].facilityType.trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].officeName.trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].line1.trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].line2.trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].postal.trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].companySubtypes.length !== 0){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].country.trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].state.trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].city.trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].employees.toString().trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].maleEmployees.toString().trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].femaleEmployees.toString().trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].monthlyCapacity.toString().trim() !== ""){
      this.counter--;
    }
    if(this.allAddressess[addressIndex].monthlyCapacityUnit.trim() !== ""){
      this.counter--;
    }
    this.counter--;
    this.allAddressess.splice(addressIndex, 1);
    this.allAddressessClone.splice(addressIndex, 1);
    this.isCompanyDetailsInValid = false;
  }

  private cancelChanges(): void {
    this.cityErrorList = {}
    this.countryErrorList = {}
    this.stateErrorList = {}
    this.isPhoneNonRoman = false;
    this.isLoading = true;
    this.company = cloneDeep(this.companyClone);
    this.companyClone = lodash.cloneDeep(this.company);
    this.admin = this.company.admin;
    this.contact = this.company.contact;
    // this.officeAddress = this.company.officeAddress;
    // this.factoryAddresses = this.company.factoryAddresses;
    // this.compareCompanyClone();
    setTimeout(
      (app: any) => {
        app.isLoading = false;
      },
      100,
      this
    );
    // this.updateStore();
    this.allOfficeAddressConfirmed();
    this.allFactoryAddressConfirmed();
    this.allConfirmedAddress();
    this.syncFacilityAddress();
    this.counter = 0;
    this.changedkeys = [];
    store.commit('setMyAccount', this.companyClone);
  }

  private compareCompanyClone(key: string): void {
    switch (key) {
      case 'companyName':
        if(this.company.companyName.trim() !== '' && this.company.inValid.isCompanyNameEmpty) {
          this.company.inValid.isCompanyNameEmpty = false;
        }
        if (
          this.company.companyName !==
            this.companyClone.companyName &&
          !this.changedkeys.includes('companyName')
        ) {
          this.changedkeys.push('companyName');
          this.counter++;
        } else if (
          this.company.companyName ===
            this.companyClone.companyName &&
          this.changedkeys.includes('companyName')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('companyName'), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'companyType':
          if(this.company.companyType !== 0 && this.company.inValid.isCompanyTypeEmpty) {
            this.company.inValid.isCompanyTypeEmpty = false;
          }
          if (
            this.company.companyType !==
              this.companyClone.companyType &&
            !this.changedkeys.includes('companyType')
          ) {
            this.changedkeys.push('companyType');
            this.counter++;
          } else if (
            this.company.companyType ===
              this.companyClone.companyType &&
            this.changedkeys.includes('companyType')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('companyType'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

        case 'companySubtypes':
          if(this.company.companySubtypes.length !== 0 && this.company.inValid.isCompanySubtypeEmpty) {
            this.company.inValid.isCompanySubtypeEmpty = false;
          }
          if (
            this.company.companySubtypes.join(",") !==
              this.companyClone.companySubtypes.join(",") &&
            !this.changedkeys.includes('companySubtypes')
          ) {
            this.changedkeys.push('companySubtypes');
            this.counter++;
          } else if (
            this.company.companySubtypes.join(",") ===
              this.companyClone.companySubtypes.join(",") &&
            this.changedkeys.includes('companySubtypes')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('companySubtypes'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

          case 'categories':
          if(this.company.categories.length !== 0 && this.company.inValid.isCategoryEmpty) {
            this.company.inValid.isCategoryEmpty = false;
          }
          if (
            this.company.categories.join(",") !==
              this.companyClone.categories.join(",") &&
            !this.changedkeys.includes('categories')
          ) {
            this.changedkeys.push('categories');
            this.counter++;
          } else if (
            this.company.categories.join(",") ===
              this.companyClone.categories.join(",") &&
            this.changedkeys.includes('categories')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('categories'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

          case 'productGroups':
          if(this.company.productGroups.length !== 0 && this.company.inValid.isProductGroupEmpty) {
            this.company.inValid.isProductGroupEmpty = false;
          }
          if (
            this.company.productGroups.join(",") !==
              this.companyClone.productGroups.join(",") &&
            !this.changedkeys.includes('productGroups')
          ) {
            this.changedkeys.push('productGroups');
            this.counter++;
          } else if (
            this.company.productGroups.join(",") ===
              this.companyClone.productGroups.join(",") &&
            this.changedkeys.includes('productGroups')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('productGroups'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

          case 'turnover':
            this.company.turnover = Number(this.allowOnlyInteger(this.company.turnover.toString()));
          if (
            this.company.turnover !==
              this.companyClone.turnover &&
            !this.changedkeys.includes('turnover')
          ) {
            this.changedkeys.push('turnover');
            this.counter++;
          } else if (
            this.company.turnover ===
              this.companyClone.turnover &&
            this.changedkeys.includes('turnover')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('turnover'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

          case 'turnoverCurrency':
          if (
            this.company.turnoverCurrency !==
              this.companyClone.turnoverCurrency &&
            !this.changedkeys.includes('turnoverCurrency')
          ) {
            this.changedkeys.push('turnoverCurrency');
            this.counter++;
          } else if (
            this.company.turnoverCurrency ===
              this.companyClone.turnoverCurrency &&
            this.changedkeys.includes('turnoverCurrency')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('turnoverCurrency'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

          case 'brands':
          if (
            this.company.brands !==
              this.companyClone.brands &&
            !this.changedkeys.includes('brands')
          ) {
            this.changedkeys.push('brands');
            this.counter++;
          } else if (
            this.company.brands ===
              this.companyClone.brands &&
            this.changedkeys.includes('brands')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('brands'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

          case 'profile':
          if(this.company.profile.trim() !== '' && this.company.inValid.isProfileEmpty) {
            this.company.inValid.isProfileEmpty = false;
          }
          if (
            this.company.profile !==
              this.companyClone.profile &&
            !this.changedkeys.includes('profile')
          ) {
            this.changedkeys.push('profile');
            this.counter++;
          } else if (
            this.company.profile ===
              this.companyClone.profile &&
            this.changedkeys.includes('profile')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('profile'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

          case 'firstName':
          if(this.company.contact.firstName.trim() !== '' && this.company.inValid.isFirstNameEmpty) {
            this.company.inValid.isFirstNameEmpty = false;
          }
          if (
            this.company.contact.firstName !==
              this.companyClone.contact.firstName &&
            !this.changedkeys.includes('firstName')
          ) {
            this.changedkeys.push('firstName');
            this.counter++;
          } else if (
            this.company.contact.firstName ===
              this.companyClone.contact.firstName &&
            this.changedkeys.includes('firstName')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('firstName'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

          case 'lastName':
          if(this.company.contact.lastName.trim() !== '' && this.company.inValid.isLastNameEmpty) {
            this.company.inValid.isLastNameEmpty = false;
          }
          if (
            this.company.contact.lastName !==
              this.companyClone.contact.lastName &&
            !this.changedkeys.includes('lastName')
          ) {
            this.changedkeys.push('lastName');
            this.counter++;
          } else if (
            this.company.contact.lastName ===
              this.companyClone.contact.lastName &&
            this.changedkeys.includes('lastName')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('lastName'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

          case 'email':
          if(this.company.contact.email.trim() !== '' && this.company.inValid.isEmailEmpty) {
            this.company.inValid.isEmailEmpty = false;
          }
          if (
            this.company.contact.email !==
              this.companyClone.contact.email &&
            !this.changedkeys.includes('email')
          ) {
            this.changedkeys.push('email');
            this.counter++;
          } else if (
            this.company.contact.email ===
              this.companyClone.contact.email &&
            this.changedkeys.includes('email')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('email'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

          case 'phone':
          if(this.company.contact.phone.trim() !== '' && this.company.inValid.isPhoneEmpty) {
            this.company.inValid.isPhoneEmpty = false;
          }
          if (
            this.company.contact.phone !==
              this.companyClone.contact.phone &&
            !this.changedkeys.includes('phone')
          ) {
            this.changedkeys.push('phone');
            this.counter++;
          } else if (
            this.company.contact.phone ===
              this.companyClone.contact.phone &&
            this.changedkeys.includes('phone')
          ) {
            this.changedkeys.splice(this.changedkeys.indexOf('phone'), 1);
            this.counter--;
          } else {
            // no action
          }
          break;

    }
    this.isCompanyDetailsInValid = false;
    // if (
    //   this.invitationId === '' ||
    //   this.invitationId === null ||
    //   this.invitationId === undefined
    // ) {
    //   let changes: number = 0;
    //   const company: [string, any][] = Object.entries(this.company).sort();
    //   const companyClone: [string, any][] = Object.entries(
    //     this.companyClone
    //   ).sort();

    //   // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    //   const getObjectDiff = (obj1: any, obj2: any) => {
    //     const obj1Props = Object.keys(obj1);
    //     const obj2Props = Object.keys(obj2);
    //     const keysWithDiffValue = obj1Props.reduce(
    //       (keysWithDiffValueAccumulator, key) => {
    //         // eslint-disable-next-line no-prototype-builtins
    //         const propExistsOnObj2 = obj2.hasOwnProperty(key);
    //         const hasNestedValue =
    //           obj1[key] instanceof Object && obj2[key] instanceof Object;
    //         const keyValuePairBetweenBothObjectsIsEqual =
    //           obj1[key] === obj2[key];

    //         if (!propExistsOnObj2) {
    //           if (
    //             obj1[key] !== null &&
    //             obj1[key] !== undefined &&
    //             obj1[key] !== '' &&
    //             obj1[key] instanceof Object
    //           ) {
    //             if (
    //               obj1[key].city !== undefined &&
    //               obj1[key].city !== null &&
    //               obj1[key].city !== ''
    //             ) {
    //               keysWithDiffValueAccumulator.push(`${key}.1`);
    //             }
    //             if (
    //               obj1[key].country !== undefined &&
    //               obj1[key].country !== null &&
    //               obj1[key].country !== ''
    //             ) {
    //               keysWithDiffValueAccumulator.push(`${key}.2`);
    //             }
    //             if (
    //               obj1[key].state !== undefined &&
    //               obj1[key].state !== null &&
    //               obj1[key].state !== ''
    //             ) {
    //               keysWithDiffValueAccumulator.push(`${key}.3`);
    //             }

    //             if (
    //               obj1[key].officeName !== undefined &&
    //               obj1[key].officeName !== null &&
    //               obj1[key].officeName !== ''
    //             ) {
    //               keysWithDiffValueAccumulator.push(`${key}.4`);
    //             }
    //             if (
    //               obj1[key].line1 !== undefined &&
    //               obj1[key].line1 !== null &&
    //               obj1[key].line1 !== ''
    //             ) {
    //               keysWithDiffValueAccumulator.push(`${key}.5`);
    //             }
    //             if (
    //               obj1[key].line2 !== undefined &&
    //               obj1[key].line2 !== null &&
    //               obj1[key].line2 !== ''
    //             ) {
    //               keysWithDiffValueAccumulator.push(`${key}.6`);
    //             }
    //             if (
    //               obj1[key].postal !== undefined &&
    //               obj1[key].postal !== null &&
    //               obj1[key].postal !== ''
    //             ) {
    //               keysWithDiffValueAccumulator.push(`${key}.7`);
    //             }

    //             if (
    //               obj1[key].companySubtypes !== undefined &&
    //               obj1[key].companySubtypes !== null &&
    //               obj1[key].companySubtypes.length > 0
    //             ) {
    //               keysWithDiffValueAccumulator.push(`${key}.8`);
    //             }
    //           } else {
    //             keysWithDiffValueAccumulator.push(key);
    //           }
    //         } else if (hasNestedValue) {
    //           const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
    //           if (keyIndex >= 0) {
    //             keysWithDiffValueAccumulator.splice(keyIndex, 1);
    //           }
    //           const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
    //           for (const diff of nestedDiffs) {
    //             if (
    //               obj1[key][0] &&
    //               obj1[key][0] !== undefined &&
    //               (obj1[key][0] == 'factoryAddressDiffers' ||
    //                 diff.endsWith('.disabled') ||
    //                 diff.endsWith('.forceEnabled') ||
    //                 diff.endsWith('.isOfficeAddress') ||
    //                 diff.endsWith('.isEdited') ||
    //                 diff.endsWith('.geoLat') ||
    //                 diff.endsWith('.geoLong'))
    //             ) {
    //               continue;
    //             }
    //             keysWithDiffValueAccumulator.push(`${key}.${diff}`);
    //             if (
    //               obj1[key][0] &&
    //               obj1[key][0] !== undefined &&
    //               (obj1[key][0] == 'categories' ||
    //                 obj1[key][0] == 'productGroups' ||
    //                 obj1[key][0] == 'companySubtypes')
    //             ) {
    //               break;
    //             }
    //           }
    //         } else if (keyValuePairBetweenBothObjectsIsEqual) {
    //           const equalValueKeyIndex =
    //             keysWithDiffValueAccumulator.indexOf(key);
    //           keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
    //         }
    //         return keysWithDiffValueAccumulator;
    //       },
    //       obj2Props
    //     );

    //     return keysWithDiffValue;
    //   };
    //   const diffFound = getObjectDiff(company, companyClone);

    //   let newDiff: string[] = [];
    //   diffFound.filter((item: any) => {
    //     if (
    //       item.includes('isEdited') ||
    //       item.includes('lookupAvailable') ||
    //       item.includes('countryInFull') ||
    //       item.includes('stateError') ||
    //       item.includes('cityError')
    //     ) {
    //       return false;
    //     }
    //     if (item.includes('.companySubtypes.')) {
    //       item = item.slice(0, -2);
    //     }
    //     newDiff.push(item);
    //   });

    //   newDiff = newDiff.filter(
    //     (item, index) => newDiff.indexOf(item) === index
    //   );
    //   changes = newDiff.length;
    //   this.changedSettingsCounter = changes;
    //   this.changedSettings(changes);
    //   if (this.changedSettingsCounter > 0) {
    //     this.showSave = true;
    //   } else {
    //     this.showSave = false;
    //   }
    // }
  }

  private compareAddressClone(key: string, index: number): void {
    let address: AddressModel = new AddressModel();
    switch(key) {
      case 'facilityType':
        if(this.allAddressess[index].facilityType.trim() !== '' && this.allAddressess[index].inValid.isAddTypeEmpty) {
          this.allAddressess[index].inValid.isAddTypeEmpty = false;
        }
        if (
          this.allAddressess[index].facilityType !==
            this.allAddressessClone[index].facilityType &&
          !this.changedkeys.includes(`facilityType${index}`)
        ) {
          this.changedkeys.push(`facilityType${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].facilityType ===
            this.allAddressessClone[index].facilityType &&
          this.changedkeys.includes(`facilityType${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`facilityType${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'officeName':
        if(this.allAddressess[index].officeName.trim() !== '' && this.allAddressess[index].inValid.isAddNameEmpty) {
          this.allAddressess[index].inValid.isAddNameEmpty = false;
        }
        if (
          this.allAddressess[index].officeName !==
            this.allAddressessClone[index].officeName &&
          !this.changedkeys.includes(`officeName${index}`)
        ) {
          this.changedkeys.push(`officeName${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].officeName ===
            this.allAddressessClone[index].officeName &&
          this.changedkeys.includes(`officeName${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`officeName${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'line1':
        if(this.allAddressess[index].line1.trim() !== '' && this.allAddressess[index].inValid.isAddLine1Empty) {
          this.allAddressess[index].inValid.isAddLine1Empty = false;
        }
        if (
          this.allAddressess[index].line1 !==
            this.allAddressessClone[index].line1 &&
          !this.changedkeys.includes(`line1${index}`)
        ) {
          this.changedkeys.push(`line1${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].line1 ===
            this.allAddressessClone[index].line1 &&
          this.changedkeys.includes(`line1${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`line1${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'line2':
        if (
          this.allAddressess[index].line2 !==
            this.allAddressessClone[index].line2 &&
          !this.changedkeys.includes(`line2${index}`)
        ) {
          this.changedkeys.push(`line2${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].line2 ===
            this.allAddressessClone[index].line2 &&
          this.changedkeys.includes(`line2${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`line2${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'postal':
        if(this.allAddressess[index].postal.trim() !== '' && this.allAddressess[index].inValid.isAddPostalEmpty) {
          this.allAddressess[index].inValid.isAddPostalEmpty = false;
        }
        if (
          this.allAddressess[index].postal !==
            this.allAddressessClone[index].postal &&
          !this.changedkeys.includes(`postal${index}`)
        ) {
          this.changedkeys.push(`postal${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].postal ===
            this.allAddressessClone[index].postal &&
          this.changedkeys.includes(`postal${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`postal${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'companySubtypes':
        if(this.allAddressess[index].companySubtypes.length !== 0 && this.allAddressess[index].inValid.isAddCompanySubtypeEmpty) {
          this.allAddressess[index].inValid.isAddCompanySubtypeEmpty = false;
        }
        if (
          this.allAddressess[index].companySubtypes.join(",") !==
            this.allAddressessClone[index].companySubtypes.join(",") &&
          !this.changedkeys.includes(`companySubtypes${index}`)
        ) {
          this.changedkeys.push(`companySubtypes${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].companySubtypes.join(",") ===
            this.allAddressessClone[index].companySubtypes.join(",") &&
          this.changedkeys.includes(`companySubtypes${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`companySubtypes${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'country':
        if(this.allAddressess[index].country.trim() !== '' && this.allAddressess[index].inValid.isAddCountryEmpty) {
          this.allAddressess[index].inValid.isAddCountryEmpty = false;
        }
        if (
          this.allAddressess[index].country !==
            this.allAddressessClone[index].country &&
          !this.changedkeys.includes(`country${index}`)
        ) {
          this.changedkeys.push(`country${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].country ===
            this.allAddressessClone[index].country &&
          this.changedkeys.includes(`country${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`country${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'state':
        if(this.allAddressess[index].state.trim() !== '' && this.allAddressess[index].inValid.isAddStateEmpty) {
          this.allAddressess[index].inValid.isAddStateEmpty = false;
        }
        if (
          this.allAddressess[index].state !==
            this.allAddressessClone[index].state &&
          !this.changedkeys.includes(`state${index}`)
        ) {
          this.changedkeys.push(`state${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].state ===
            this.allAddressessClone[index].state &&
          this.changedkeys.includes(`state${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`state${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'city':
        if(this.allAddressess[index].city.trim() !== '' && this.allAddressess[index].inValid.isAddCityEmpty) {
          this.allAddressess[index].inValid.isAddCityEmpty = false;
        }
        if (
          this.allAddressess[index].city !==
            this.allAddressessClone[index].city &&
          !this.changedkeys.includes(`city${index}`)
        ) {
          this.changedkeys.push(`city${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].city ===
            this.allAddressessClone[index].city &&
          this.changedkeys.includes(`city${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`city${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'employees':
        if(this.allAddressess[index].employees !== '' && this.allAddressess[index].inValid.isAddEmployeesEmpty) {
          this.allAddressess[index].inValid.isAddEmployeesEmpty = false;
        }
        this.allAddressess[index].employees = Number(this.allowOnlyInteger(this.allAddressess[index].employees.toString()));
        if (
          this.allAddressess[index].employees !==
            this.allAddressessClone[index].employees &&
          !this.changedkeys.includes(`employees${index}`)
        ) {
          this.changedkeys.push(`employees${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].employees ===
            this.allAddressessClone[index].employees &&
          this.changedkeys.includes(`employees${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`employees${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        address = cloneDeep(this.allAddressess[index]);
        this.allAddressess.splice(index, 1);
        this.allAddressess.splice(index, 0, address);
        break;

        case 'femaleEmployees':
        if(this.allAddressess[index].femaleEmployees !== '' && this.allAddressess[index].inValid.isAddFemaleEmployeesEmpty) {
          this.allAddressess[index].inValid.isAddFemaleEmployeesEmpty = false;
        }
        this.allAddressess[index].femaleEmployees = Number(this.allowOnlyInteger(this.allAddressess[index].femaleEmployees.toString()));
        if (
          this.allAddressess[index].femaleEmployees !==
            this.allAddressessClone[index].femaleEmployees &&
          !this.changedkeys.includes(`femaleEmployees${index}`)
        ) {
          this.changedkeys.push(`femaleEmployees${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].femaleEmployees ===
            this.allAddressessClone[index].femaleEmployees &&
          this.changedkeys.includes(`femaleEmployees${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`femaleEmployees${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'maleEmployees':
        if(this.allAddressess[index].maleEmployees !== '' && this.allAddressess[index].inValid.isAddMaleEmployeesEmpty) {
          this.allAddressess[index].inValid.isAddMaleEmployeesEmpty = false;
        }
        this.allAddressess[index].maleEmployees = Number(this.allowOnlyInteger(this.allAddressess[index].maleEmployees.toString()));
        if (
          this.allAddressess[index].maleEmployees !==
            this.allAddressessClone[index].maleEmployees &&
          !this.changedkeys.includes(`maleEmployees${index}`)
        ) {
          this.changedkeys.push(`maleEmployees${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].maleEmployees ===
            this.allAddressessClone[index].maleEmployees &&
          this.changedkeys.includes(`maleEmployees${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`maleEmployees${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'monthlyCapacity':
        if(this.allAddressess[index].monthlyCapacity !== '' && this.allAddressess[index].inValid.isAddMonthlyCapacityEmpty) {
          this.allAddressess[index].inValid.isAddMonthlyCapacityEmpty = false;
        }
        this.allAddressess[index].monthlyCapacity = Number(this.allowOnlyInteger(this.allAddressess[index].monthlyCapacity.toString()));
        if (
          this.allAddressess[index].monthlyCapacity !==
            this.allAddressessClone[index].monthlyCapacity &&
          !this.changedkeys.includes(`monthlyCapacity${index}`)
        ) {
          this.changedkeys.push(`monthlyCapacity${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].monthlyCapacity ===
            this.allAddressessClone[index].monthlyCapacity &&
          this.changedkeys.includes(`monthlyCapacity${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`monthlyCapacity${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

        case 'monthlyCapacityUnit':
        if(this.allAddressess[index].monthlyCapacityUnit.trim() !== '' && this.allAddressess[index].inValid.isAddMonthlyCapacityUnitEmpty) {
          this.allAddressess[index].inValid.isAddMonthlyCapacityUnitEmpty = false;
        }
        if (
          this.allAddressess[index].monthlyCapacityUnit !==
            this.allAddressessClone[index].monthlyCapacityUnit &&
          !this.changedkeys.includes(`monthlyCapacityUnit${index}`)
        ) {
          this.changedkeys.push(`monthlyCapacityUnit${index}`);
          this.counter++;
        } else if (
          this.allAddressess[index].monthlyCapacityUnit ===
            this.allAddressessClone[index].monthlyCapacityUnit &&
          this.changedkeys.includes(`monthlyCapacityUnit${index}`)
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf(`monthlyCapacityUnit${index}`), 1);
          this.counter--;
        } else {
          // no action
        }
        break;
    }
    this.isCompanyDetailsInValid = false;
  }

  // private compareAddressStatus(): void {
  //   let count = 0;
  //   this.companyClone.officeAddress.forEach((add) => {
  //     const address = this.officeAddress.filter((o) => o.id === add.id)[0];
  //     if (address.addressStatus !== add.addressStatus) {
  //       count += 1;
  //     }
  //   });
  //   this.companyClone.factoryAddresses.forEach((add) => {
  //     const address = this.factoryAddresses.filter((f) => f.id === add.id)[0];
  //     if (address.addressStatus !== add.addressStatus) {
  //       count += 1;
  //     }
  //   });
  //   this.changedSettingsCounter = this.changedSettingsCounter + count;
  //   this.changedSettings(this.changedSettingsCounter);
  // }

  private async checkNonRomanChar(variableName:string,val:string){
    (this as any)[variableName] = await NonRomanCharCheckHelper.checkNonRomanChar(val);
  }

  private get confirmGeolocationPermission(): number {
    return this.invitationId !== null
      ? 1
      : this.userPermissions !== null
      ? this.userPermissions.confirmGeolocationCompany
      : 0;
  }

  private get uploadCertificatePermission(): number {
    return this.userPermissions !== null
      ? this.userPermissions.uploadCertificates
      : 0;
  }

  private get uploadCompanyPicturePermission(): number {
    return this.invitationId !== null
      ? 1
      : this.userPermissions !== null
      ? this.userPermissions.createPhotos
      : 0;
  }

  private get isAdmin(): boolean {
    if (this.userRole === 'Editor' || this.userRole === 'Reader') {
      return false;
    } else {
      return true;
    }
  }

  private allowOnlyInteger(input: string): string {
    const regex = /^\d*$/;
    let returnValue = '';
    if (!regex.test(input)) {
      returnValue = input.replace(/[^\d]/g, '');
    } else {
      returnValue = input;
    }
    return returnValue;
  }

  @Emit()
  private changedSettings(value: number): void {}
}
