import { ProductPictureDisplayModel } from "./productPictureDisplayModel";
import { ProductQualityReportModel } from "./productQualityReportModel";
import { DelegateCompanyUserModel } from './delegateCompanyUserModel';
import moment from 'moment';
export class OrderLineModel {
  public id!: string;
  public orderId!: string;
  public orderLineId?: string;
  public lineID!: string;
  public brandName!: string;
  public productGroup!: number;
  public styleNumber!: string;
  public styleName!: string;
  public colourway?: string;
  public quantity?: number;
  public compositionMainFabric?: string;
  public compositionSecondFabric?: string;
  public expectedTimeOfDelivery!: string;
  public deliveryTerms!: string;
  public certification?: string;
  public orderNumber?: string;
  public companyName?: string;
  public toCompanyId!: string;
  public toCompanyName!: string;
  public CompanyId!: string;
  public supplierCompanyId!: string;
  public orderLineStatus!: number;
  public orderLinePhase!: number;
  public certificate?: string;
  public totalQuantityPerCw!: number;
  public etd!: string;
  public qrCodeRef!: string;
  public productPictureUrls?: ProductPictureDisplayModel[];
  public showNewsLetter!: boolean;
  public season!: string;
  public repeat!: string;
  public productQualityRef!: ProductQualityReportModel[];
  // TTD-2630 Sprint 18
  public delegateCompanyUser!: DelegateCompanyUserModel;
  public delegateCompanyID!: string;
  public delegatedAt!: moment.Moment;
}
