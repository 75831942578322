import i18n from '@/i18n';
import moment from 'moment';
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { NotificationHelper } from '@/helpers/notificationHelper';
import { Drag, Drop, DropList } from 'vue-easy-dnd';
import ClickOutside from 'vue-click-outside';
import Compressor from 'compressorjs';

// Components
import SmallScreenPopUpMsg from '@/components/general/smallScreenPopUpMsg/smallScreenPopUpMsg.vue';

// Models
import { UserModel } from '@/models/userModel';
import { EvidencePhotoListModel, SelectPictureModel, UploadedPictureResponseModel } from '@/models/selectPictureModel';
import { GroupStepEvidenceModel } from '@/models/groupStepEvidenceModel';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import { AddressModel } from '@/models/addressModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';

// Services
import { OrderService } from '@/services/orderService';
import { SupplierService } from '@/services/supplierService';
import { EvidenceService } from '@/services/evidenceService';

import store from '@/store';
import { OrderLineStepEvidenceModel } from '@/models/orderLineStepEvidenceModel';
import { BlockchainAccessModal } from '@/models/blockchainAccessModal';
import { UserClient } from '@/clients/userClient';
import { orderLineStepID, stepsList, SubmitStepEvidenceModel } from '@/models/orderStepEvidenceModel';

@Component({
  directives: { ClickOutside },
  components: { Drag, Drop, DropList, SmallScreenPopUpMsg },
})
export default class SelectPictures extends Vue {
  private orderService: OrderService;
  private supplierService: SupplierService;
  private evidenceService: EvidenceService;
  private client: UserClient;

  private reference: string = '';
  private isRefError: boolean = false;

  private isCameraOn: boolean = false;
  private isCameraEnabled: boolean = false;
  private isUploadSuccess: boolean = false;
  private isPicturesExists: boolean = false;
  private isLocationEnabled: boolean = false;

  private isTakePicture: boolean = false;
  private isSavePicture: boolean = false;
  private isShowPopVisibile: boolean = false;

  private isReachedMaxPictures: boolean = false;
  private showPicturesIsEditable: boolean = false;
  private askGeolocationPermission: boolean = true;

  private curImageIndexToShow: number = 0;
  private curImageIndexToDelete: number = 0;
  private maxPhotoCount: number = 20;
  private minPhotoCount: number = 1;
  private curPhotoIndex: number = 0;
  private maxViewable: number = 3;
  private companyId: string = '';
  private longitude: number = 0;
  private latitude: number = 0;
  private maxMeters: Number = 100;

  private cameraWidth: number = 0;
  private cameraHeight: number = 0;

  private video: any = null;
  private canvas: any = null;

  private isSaving: boolean = false;

  private isError: boolean = false;

  private user: UserModel = new UserModel();

  private fileType: string = 'image/jpeg';
  private picturesAdded: SelectPictureModel[] = [];

  @Prop()
  private groupedSteps!: GroupStepEvidenceModel;

  @Prop({ default: false })
  private isResubmit!: boolean;

  private orderId?: string = '';
  private orderLineId?: string = '';
  private orderLineStepId?: string = '';

  private isIos: boolean = false;

  public constructor() {
    super();
    this.client = new UserClient();
    this.orderService = new OrderService();
    this.supplierService = new SupplierService();
    this.evidenceService = new EvidenceService();
  }

  private async mounted(): Promise<void> {
    setTimeout(
      (app: any) => {
        const head = document.getElementById('image-scroller') as HTMLElement;
        if (head) {
          head.scrollLeft = head.scrollWidth;
        }
      },
      100,
      this
    );
  }

  private async created(): Promise<void> {
    this.isCameraOn = true;
    this.getGeolocationPermission();
    const ua = navigator.userAgent;
    if (/iPad|iPhone|iPod/.test(ua)) {
      this.isIos = true;
    } else {
      this.isIos = false;
    }
    this.user = this.$store.getters.user as UserModel;
  }

  private async beforeDestroy(): Promise<void> {
  }

  private getGeolocationPermission(): void {
    navigator.geolocation.getCurrentPosition(
      this.getLocationSuccess,
      this.getLocationError,
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }

  private setBase64Image(url: string, newPicture: SelectPictureModel): void {
    let base64Image: string = '';
    const imgObject = new Image();
    imgObject.src = url;
    imgObject.setAttribute('crossorigin', 'anonymous');
    imgObject.onload = (): void => {
      var canvas = document.createElement('canvas');
      canvas.width = imgObject.width;
      canvas.height = imgObject.height;
      var ctx = canvas.getContext('2d');
      ctx?.drawImage(imgObject, 0, 0);
      base64Image = canvas.toDataURL('image/jpg');

      newPicture.content = base64Image;
      this.picturesAdded.push(newPicture);
      this.scrollToEnd(-1);
    };
  }

  private getLocationError(err: any): void {
    NotificationHelper.createErrorNotification(
      this.$t('global.notifications.enable_geolocation').toString()
    );
    NotificationHelper.createErrorNotification(err.message);
  }

  private getLocationSuccess(position: any): void {
    this.longitude = position.coords.longitude;
    this.latitude = position.coords.latitude;
    this.isLocationEnabled = true;
  }

  private takePicture(): void {
    if (this.picturesAdded.length + 1 > this.maxPhotoCount) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.profile_pictures_quantity', [
          this.minPhotoCount,
          this.maxPhotoCount,
        ]).toString()
      );
      this.closePopup();
      return;
    }
    const inputFile = <HTMLDivElement>document.querySelector('.camera-file');
    inputFile.click();
  }

  private async uploadImageFromGallery(event: any): Promise<void> {
    if (this.latitude === 0 && this.longitude === 0) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.enable_geolocation').toString()
      );
      return;
    }
    this.isCameraOn = false;
    const app = this;
    const quality: number = 1; // Ranges from 0-1, where 0.1-low, and 1 is full quality
    const maxHeight: number = 480;
    const imgObject = new Image();

    if (
      this.picturesAdded.length + event.target.files.length >
      this.maxPhotoCount
    ) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.profile_pictures_quantity', [
          this.minPhotoCount,
          this.maxPhotoCount,
        ]).toString()
      );
      this.closePopup();
      return;
    }
    for (let index = 0; index < event.target.files.length; index++) {
      // TTD-4294: Added compression to reduce the image size
      new Compressor(event.target.files[index], {
        quality: 0.6,
        success(result) {
          const selectedFile = result;
          const reader: FileReader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onload = (function (file: any, app: any) {
            return (): void => {
              // TTD-4294: Removed limit of 5MB
              const newPicture = new SelectPictureModel();
              newPicture.latitude = app.latitude;
              newPicture.longitude = app.longitude;
              newPicture.createdBy = `${app.user.firstName} ${app.user.lastName}`;
              newPicture.fileName = 'photo' + moment.now();
              newPicture.documentType = file.type;
              newPicture.content = reader.result as string;
              newPicture.size = file.size;
              newPicture.progress = 1;
              app.picturesAdded.push(newPicture);
              app.uploadPictureInChunks(app.picturesAdded.length - 1);
              app.closePopup();
            };
          })(selectedFile, app);
          reader.onerror = (): void => {
            NotificationHelper.createErrorNotification(
              i18n.t('errors.image_invalid').toString()
            );
            app.closePopup();
          };
        }
      })
    }
    this.isCameraOn = false;
  }

  private async uploadPictureInChunks(pictureindex: number): Promise<void> {
    const token = store.getters.accessToken as BlockchainAccessModal;
    const chunkSize = 3 * 100 * 1024; // 300kb (adjust based on your requirements)
    const totalChunks = Math.ceil(this.picturesAdded[pictureindex].size / chunkSize);
    const chunkProgress = 100 / totalChunks;
    let accesstoken: BlockchainAccessModal = new BlockchainAccessModal();
    let chunkNumber = 0;
    let start = 0;
    let end = chunkSize;
    if (token !== null) {
      accesstoken = token;
    } else {
      accesstoken = await this.client.getBlockchainToken();
      store.commit('setAccessToken', accesstoken);
    }

    const uploadNextChunk = async () => {
      if (start <= this.picturesAdded[pictureindex].size) {
        const chunk = this.dataURItoFile(this.picturesAdded[pictureindex].fileName, this.picturesAdded[pictureindex].content).slice(start, end);
        const formData = new FormData();
        formData.append("upload", chunk);
        formData.append("chunkNumber", chunkNumber.toString());
        formData.append("totalChunks", totalChunks.toString());
        formData.append("originalname", this.picturesAdded[pictureindex].fileName);
        formData.append("mimetype", this.picturesAdded[pictureindex].documentType);
        formData.append("geoLat", this.picturesAdded[pictureindex].latitude.toString());
        formData.append("geoLong", this.picturesAdded[pictureindex].longitude.toString());
        formData.append("status", OrderLineStepStatus.WaitingForApproval.toString());

        fetch(`${process.env.VUE_APP_Blockchain_Api_Endpoint}/v2/api/orderlineStep/supplier/${this.user.companyId}/uploadEvidence`, {
          headers: {
            Authorization: 'Bearer ' + accesstoken.token,
          },
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data: UploadedPictureResponseModel) => {
            this.picturesAdded[pictureindex].progress = (Number((chunkNumber + 1) * chunkProgress));
            const picture = this.picturesAdded[pictureindex];
            this.picturesAdded.splice(pictureindex, 1, picture);
            chunkNumber++;
            start = end;
            end = start + chunkSize;
            if(chunkNumber === totalChunks) {
              this.picturesAdded[pictureindex].uploadedPicture = data.evidencePhotoList;
              const picture = this.picturesAdded[pictureindex];
              this.picturesAdded.splice(pictureindex, 1, picture);
            }
            uploadNextChunk();
          })
          .catch((error) => {
            console.error("Error uploading chunk:", error);
          });
      } else {
        setTimeout(
          (app: any) => {
            this.picturesAdded[pictureindex].progress = 101;
            const picture = this.picturesAdded[pictureindex];
            this.picturesAdded.splice(pictureindex, 1, picture);
          },
          1000,
          this
        );
      }
    };
    uploadNextChunk();
  }

  private get isSubmitDisabled(): boolean {
    return this.picturesAdded.some(p => p.progress > 0 && p.progress < 101) || this.isSaving || this.picturesAdded.length === 0;
  }

  private deletePicture(index: number): void {
    this.closePopup();
    this.picturesAdded.splice(
      this.picturesAdded.indexOf(this.picturesAdded[index]),
      1
    );
  }

  private showTakePicture(): void {
    this.isTakePicture = true;
    this.isSavePicture = false;
    this.showPopup();
  }

  private showSaveConfirm(): void {
    this.isSavePicture = true;
    this.isTakePicture = false;
    this.showPopup();
  }

  private showPopup(): void {
    this.isShowPopVisibile = true;
    const rightContainer = document.getElementById(
      'right-container'
    ) as HTMLElement;
    rightContainer.style.zIndex = '999999';
  }

  private closePopup(): void {
    if (this.isSaving) {
      return;
    }
    this.isShowPopVisibile = false;
    this.isTakePicture = false;
    this.isSavePicture = false;
    this.isSaving = false;
    const rightContainer = document.getElementById(
      'right-container'
    ) as HTMLElement;
    rightContainer.style.zIndex = 'unset';
    this.scrollToEnd(-1);
  }

  private dataURItoFile(filename: string, dataURI: string): File {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const fileBlob = new Blob([ab], { type: mimeString });
    return new File([fileBlob], filename, { type: mimeString });
  }

  private async saveSelectPictures(): Promise<void> {
    if (this.latitude === 0 && this.longitude === 0) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.enable_geolocation').toString()
      );
      this.closePopup();
      return;
    }

    if (
      this.picturesAdded.length < this.minPhotoCount ||
      this.picturesAdded.length > this.maxPhotoCount
    ) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.profile_pictures_quantity', [
          this.minPhotoCount,
          this.maxPhotoCount,
        ]).toString()
      );
      this.closePopup();
      return;
    }

    const lMatches: Boolean = await this.checkLocationMatches();

    const pictureAddedFiles: File[] = [];
    this.picturesAdded.forEach((picture: SelectPictureModel) => {
      pictureAddedFiles.push(
        this.dataURItoFile(picture.fileName, picture.content)
      );
    });

    // This method need to call after images get captured from cameraor uploaded from device
    try {
      this.isSaving = true;
      let stepIds: stepsList[] = []
      this.groupedSteps.orderStyle.forEach((s) => {
        if (s.isSelected) {
          stepIds.push({ ID: s.ID });
        }
      });
      const lineStepID: orderLineStepID = {
        stepsList: stepIds
      }
      const uploadedPictures: EvidencePhotoListModel[] = [];
      this.picturesAdded.forEach((p) => {
        uploadedPictures.push(p.uploadedPicture);
      });
      const submitStepEvidence: SubmitStepEvidenceModel = {
        orderLineStepID: lineStepID,
        referenceNo: this.reference.trim(),
        evidencePhotoList: uploadedPictures
      }
      const uploadResult = await this.evidenceService.submitStepEvidence(
        submitStepEvidence,
        this.user.companyId
      );
      this.reference = '';
      this.isSaving = false;
      this.isUploadSuccess = true;
      this.lastPage();
      this.closePopup();
    } catch (error) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.error').toString()
      );
      this.isSaving = false;
      this.closePopup();
    } finally {
      const lastStepData: object[] = [];
      this.groupedSteps.orderStyle.forEach((o) => {
        lastStepData.push({
          orderID: o.orderId,
          orderLineID: o.orderLineId,
          orderLineStepID: o.ID,
        });
      });
    }
  }

  /** Upload element */
  private get upload(): HTMLInputElement {
    return this.$refs.uploadImage as HTMLInputElement;
  }

  private delay(delayInms: number) {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  }

  private degToRad(deg: number): number {
    return deg * (Math.PI / 180);
  }

  private async getDistanceFromLatLonInMeters(
    coords1: { lat: number; lon: number },
    coords2: { lat: number; lon: number }
  ): Promise<Number> {
    const { lat: lat1, lon: lon1 } = coords1;
    const { lat: lat2, lon: lon2 } = coords2;

    const R = 6371;
    const halfDLat = this.degToRad(lat2 - lat1) / 2;
    const halfDLon = this.degToRad(lon2 - lon1) / 2;
    const a =
      Math.sin(halfDLat) * Math.sin(halfDLat) +
      Math.cos(this.degToRad(lat1)) *
        Math.cos(this.degToRad(lat2)) *
        Math.sin(halfDLon) *
        Math.sin(halfDLon);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (R * c) / 100;
  }

  private async checkLocationMatches(): Promise<Boolean> {
    let lMatches: Boolean = false;
    let company: any = this.$store.getters.profile;
    if (this.$store.getters.profile != null) {
      company = this.$store.getters.profile;
    } else {
      company = await this.supplierService.getSupplierAsync();
    }

    if (company.officeAddress !== null || company.officeAddress !== undefined) {
      await company.officeAddress.forEach((address: AddressModel) => {
        if (address != null && address !== undefined) {
          if (address && address.geoLat != null && address.geoLong != null) {
            this.getDistanceFromLatLonInMeters(
              { lat: address.geoLat, lon: address.geoLong },
              { lat: this.latitude, lon: this.longitude }
            ).then((distance) => {
              lMatches = distance < this.maxMeters ? true : lMatches;
            });
          }
        }
      });
    }

    if (
      company.factoryAddresses !== null ||
      company.factoryAddresses !== undefined
    ) {
      await company.factoryAddresses.forEach((address: AddressModel) => {
        if (address != null && address !== undefined) {
          if (address && address.geoLat != null && address.geoLong != null) {
            this.getDistanceFromLatLonInMeters(
              { lat: address.geoLat, lon: address.geoLong },
              { lat: this.latitude, lon: this.longitude }
            ).then((distance) => {
              lMatches = distance < this.maxMeters ? true : lMatches;
            });
          }
        }
      });
    }

    return lMatches;
  }

  private redirectToFirstPage(): void {
    this.isCameraOn = true;
  }

  private stopCamera(): void {
    this.scrollToEnd(-1);
  }

  private scrollToEnd(endValue: number): void {
    setTimeout(
      (app: any) => {
        const imageScroller = document.getElementById(
          'image-scroller'
        ) as HTMLElement;
        if (imageScroller) {
          if (endValue >= 0) {
            imageScroller.scrollLeft = endValue;
          } else {
            imageScroller.scrollLeft = imageScroller.scrollWidth;
          }
        }
      },
      100,
      this
    );
  }

  @Watch('picturesAdded')
  private onPicturesAddedChanged(picturesAdded: SelectPictureModel[]): void {
    this.curPhotoIndex = picturesAdded.length - 1;
  }

  @Watch('curPhotoIndex')
  private onCurPhotoIndexChanged(curPhotoIndex: number): void {
    if (curPhotoIndex > 0) {
      const widthFound = document.getElementById(
        'image-picture-show-' + (curPhotoIndex - 1)
      ) as HTMLElement;
      if (curPhotoIndex + 1 < this.picturesAdded.length) {
        this.scrollToEnd(curPhotoIndex * widthFound.offsetWidth);
      } else {
        this.scrollToEnd(-1);
      }
    } else {
      this.scrollToEnd(0);
    }
  }

  @Emit()
  private stateChanged(state: string): void {}

  @Emit()
  private cancel(): void {

  }

  @Emit()
  private closeUpload(): void {}

  @Emit()
  private reloadEvidence(): void {
    this.closeUpload();
  }

  @Emit()
  private lastPage(): void {}
}
