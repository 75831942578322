var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"select-order tile medium-large"},[_c('div',{staticClass:"content"},[_c('div',[_c('label',{staticClass:"order-label"},[_c('span',{staticClass:"label-style"},[_vm._v("Delivery to: ")]),_vm._v(_vm._s(_vm.groupedStepOrders.nextStepCompany))])]),_c('div',{staticClass:"order-text"},[_c('span',{staticClass:"label-style"},[_vm._v("Reference:")]),_vm._v(" "),_c('span',{staticClass:"value-style"},[_vm._v(_vm._s(_vm.stepReferenceNo!=='' ? _vm.stepReferenceNo : '-'))])]),_c('div',{staticClass:"order-text"},[_c('span',{staticClass:"label-style"},[_vm._v("Process:")]),_vm._v(" "),_c('spnan',{staticClass:"value-style"},[_vm._v(_vm._s(_vm.process)+" ")])],1),(_vm.isApproveReject)?_c('div',{staticClass:"order-text"},[_c('span',{staticClass:"label-style"},[_vm._v("Note from previous partner in the chain: ")]),_vm._v(" "),(_vm.groupedStepOrders.reference=='')?_c('span',[_vm._v("-")]):_vm._e(),(_vm.groupedStepOrders.reference!='')?_c('p',{staticClass:"mb-0 mt-0 next-ref"},[_vm._v(_vm._s(_vm.groupedStepOrders.reference!=='' ? _vm.groupedStepOrders.reference : '-'))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"row mx-0 my-3"},[_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"row"},[(!_vm.noCheckboxes)?_c('div',{class:[
              'option-list mr-2',
              { 'un-select': !_vm.allSelected, selected: _vm.allSelected },
            ],attrs:{"role":"button"},on:{"click":function($event){return _vm.selectAll()}}}):_vm._e(),_c('h3',[_vm._v("Order number")])])]),_vm._m(0)]),_vm._l((_vm.orderList),function(order,index){return _c('div',{key:'order' + index,staticClass:"row mx-0 my-3"},[_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"row"},[(!_vm.noCheckboxes)?_c('div',{class:[
              'option-list mr-2',
              { 'un-select': !order.isSelected, selected: order.isSelected },
            ],attrs:{"role":"button"},on:{"click":function($event){return _vm.select(index, order.isSelected)}}}):_vm._e(),_c('p',{staticClass:"order-no"},[_vm._v(_vm._s(order.orderNo))])])]),_c('div',{staticClass:"col-5"},[_c('p',{staticClass:"style-no-content"},[_vm._v(_vm._s(order.styleNo))])])])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-5"},[_c('h3',[_vm._v("Style number")])])
}]

export { render, staticRenderFns }