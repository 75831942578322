import i18n from '@/i18n';
import { extend } from 'vee-validate';
import {
  email,
  required,
  max,
  max_value,
  min_value,
} from 'vee-validate/dist/rules';
import { WordCount } from './helpers/wordCount';

export class VeeValidateInit {
  public static setupRules(): void {
    extend('email', {
      ...email,
      message: i18n.t('validation.email').toString(),
    });

    extend('required', {
      ...required,
      message: i18n.t('validation.required').toString(),
    });

    extend('max', {
      ...max,
      message: (_, { length }) =>
        i18n.t('validation.max_length', [length]).toString(),
    });

    extend('max_value', {
      ...max_value,
      message: (_, { max }) => i18n.t('validation.max_value', [max]).toString(),
    });

    extend('min_value', {
      ...min_value,
      message: (_, { min }) => i18n.t('validation.min_value', [min]).toString(),
    });

    extend('max_words', {
      validate: (value, args: any[] | Record<string, any>) =>
        WordCount.getWordCount(value) <=
        VeeValidateInit.getSingleParam(args, 'max'),
      message: (_, { max }) => i18n.t('validation.max_words', [max]).toString(),
      params: ['max'],
    });

    extend('phone_number', {
      validate: (value) => new RegExp('^(\\+)?([ 0-9]){10,16}$').test(value),
      message: i18n.t('validation.phone_number').toString(),
    });

    extend('roman', {
      validate: (value) =>
        !/[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/g.test(value),
      message: i18n.t('validation.roman_characters').toString(),
    });
  }

  private static getSingleParam(
    params: any[] | Record<string, any> | undefined,
    paramName: string
  ): any {
    if (!params) {
      return undefined;
    }
    return Array.isArray(params) ? params[0] : params[paramName];
  }
}
