import { Vue, Component, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';

// Components
import esgData from '@/views/esg/components/esgData.vue';

// Models
import { UserModel } from '@/models/userModel';
import { ContactModel } from '@/models/contactModel';
import { mapState } from 'vuex';

@Component({
  components: { esgData },
  computed: mapState(['isEsgDataChanged', 'isShowEsgConfirmation'])
})
export default class Profile extends Vue {
  private tabIndex: number = 0;
  private showConfirmSave: boolean = false;
  private isEsgDataChanged!: boolean;

  public constructor() {
    super();
  }

  public async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  private get title(): string[] {
    return [
      i18n.t('pages.esg.soc_data').toString(),
      i18n.t('pages.esg.env_data').toString()
    ];
  }

  private selectDetailTab(newTabIndex: number, prevTabIndex: number, bvEvent: any): void {
    if(this.isEsgDataChanged){
      bvEvent.preventDefault();
      this.showConfirmSave = true;
    }
  }

  private saveEsgData(): void {
    if(this.tabIndex === 0){
      const socialEsg = (this.$refs.esg as any).$refs.social;
      socialEsg.save();
      this.showConfirmSave = false;
    }else{
      const envEsg = (this.$refs.esg as any).$refs.env;
      envEsg.save();
      this.showConfirmSave = false;
    }
    this.$store.commit('setShowEsgConfirmation', false);
  }

  private cancelChanges(): void{
    if(this.tabIndex === 0){
      const socialEsg = (this.$refs.esg as any).$refs.social;
      socialEsg.cancelChanges();
      this.showConfirmSave = false;
    }else{
      const envEsg = (this.$refs.esg as any).$refs.env;
      envEsg.cancelChanges();
      this.showConfirmSave = false;
    }
    this.$store.commit('setShowEsgConfirmation', false);
  }
}
