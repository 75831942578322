import Vue from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import * as msal from '@azure/msal-browser';
import Notifications from 'vue-notification';
import { NotificationHelper } from './helpers/notificationHelper';

import { UserAuthenticator } from './authenticators/userAuthenticator';
import MsalFactory from './factories/msalFactory';
import { ActionTypes } from './store/actions/actions';
import 'isomorphic-fetch';

// Bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

// Components
import Modal from './components/general/modal.vue';
import OrderInfo from './views/home/components/orderInfo.vue';
import Datepicker from 'vuejs-datepicker';

// Styles
import './styles/main.scss';

// VeeValidate
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { VeeValidateInit } from './validation/veeValidateInit';

import { VueSimpleAlert } from 'vue-simple-alert';
import { AppInsightsHelper } from './helpers/appInsightsHelper';
import { VueFilters } from './filters/filters';

VeeValidateInit.setupRules();

// MsalFactory.initialize();

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Notifications);

Vue.config.errorHandler = function (error: Error): void {
  if (error != null && error.message != null && error.message !== '') {
    NotificationHelper.createErrorNotification(
      i18n.t(error.message).toString()
    );
  }
};

Vue.component('modal', Modal);
Vue.component('order-info', OrderInfo);
Vue.component('date-picker', Datepicker);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueSimpleAlert, {
  reverseButtons: true,
  cancelButtonText: i18n.t('global.modal.no'),
  confirmButtonText: i18n.t('global.modal.yes'),
});

AppInsightsHelper.initialize();
VueFilters.register();

new Vue({
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render: (h) => h(App),
  router,
  store,
  i18n,
}).$mount('#app');
