import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import i18n from '@/i18n';
import lodash from 'lodash';

// Components
import LoadingOrderInfo from '@/views/home/components/loadingOrderInfo.vue';
import PendingOrderLine from '@/views/home/components/pendingOrderLine.vue';
import enLangData from '@/locales/en.json';

// Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';
import { GroupStepEvidenceHelper } from '@/helpers/groupStepEvidenceHelper';

// Store
import { ActionTypes } from '@/store/actions/actions';
import store from '@/store';
import { mapState } from 'vuex';

// Services
import { OrderService } from '@/services/orderService';

// Models
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { OrderIdListModel, OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { Guid } from '@/models/guid';
import { DelegateCompanyModel, OrderDelegateCompanyModel, OrderDelegatePartnerReqModel } from '@/models/delegateCompanyModel';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { StepState } from '@/models/stepState';
import { ConfirmStepBlockchainModel } from '@/models/confirmOrderLineStepModal';
import { StepHistoryBlockchainModel } from '@/models/stepHistoryModal';
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { ParallelStepBlockchainModel } from '@/models/orderLineStepParallelModel';
import { UserModel } from '@/models/userModel';
import { SupplierService } from '@/services/supplierService';

@Component({
  components: { PendingOrderLine, LoadingOrderInfo },
  computed: mapState(['isOrderLoading', 'pendingOrders', 'orders', 'orderLineEvidenceStepsLoading', 'orderLineEvidenceSteps', 'suppliers', 'user'])
})
export default class PendingOrderLinesGroup extends Vue {

  @Prop()
  private orderData!: OrderLineDisplayModel[];

  private orderService: OrderService;

  private supplierService: SupplierService;

  private orders!: OrderLineDisplayModel[];

  private pendingOrders!: OrderLineDisplayModel[];

  private suppliers!: OrderCompanyModel[];

  private user!: UserModel;

  private orderLineSteps: OrderLineStepDisplayModel[] = [];

  private imgSrc: NodeRequire = require('../../../assets/no-pending-order-lines.png');

  private isLoading: boolean = false;

  private isOrderLoading!: boolean;

  private orderLineCreated: boolean = false;

  private combinedOrders: CombinedOrderLineModel[] = [];

  private delegatedToCombinedOrders: CombinedOrderLineModel[] = [];

  private delegatedByCombinedOrders: CombinedOrderLineModel[] = [];

  private stepNames: string[] = [];

  private orderLineEvidenceStepsLoading!: boolean;

  private orderLineEvidenceSteps!: OrderLineEvidenceStep[];

  private delegatedToCount: number = 0;

  private delegatedByCount: number = 0;

  private pendingOrdersCount: number = 0;
  //commenting these changes not required now sprint-18
  // private isPendingStepsUpdated: boolean = false;

  // private isOrdersUpdated: boolean = false;

  public constructor() {
    super();
    this.orderService = new OrderService();
    this.supplierService = new SupplierService();
    this.stepNames = Object.values(enLangData.enums.steps);
  }

  private async created(): Promise<void> {
    // await this.getOrders();
  }

  private async getOrders(): Promise<void> {
    // if (this.pendingOrders.length === 0 && this.orders.length === 0) {
    //   await this.$store.dispatch(ActionTypes.SET_ORDERLINE);
    //   if(this.orderLineEvidenceSteps.length === 0 && !this.orderLineEvidenceStepsLoading){
    //     this.$store.dispatch(ActionTypes.SET_ORDER_LINE_EVIDENCE_STEPS);
    //   }
    // }
    //  this.setCombinedOrders();
    //  this.setDelegatedToCombinedOrders();
    //  this.setDelegatedByCombinedOrders();
  }

  private async createOrderLine(
    created: boolean,
    orderLineSteps: ConfirmStepBlockchainModel[],
    isDelegated: boolean,
    selectedDelegatePartner : DelegateCompanyModel,
    orderLine: OrderLineDisplayModel[],
    isDelegatedPartnerRemoved: boolean
  ): Promise<void> {
    try {
      this.orderLineCreated = true;
      if (created) {
        const steps = await GroupStepEvidenceHelper.updateApproveStepEvidece(orderLineSteps, 'CREATE', isDelegatedPartnerRemoved);
        //checking is steps customised or not TTD-2422
        if(this.checkStepsCustomised(orderLineSteps)){
            await this.orderService.updateOrderStepsBlockchainAsync(
            steps,
            'CREATE'
          );
        }
        const orderIds: OrderIdListModel = {
          orderIdList: lodash.uniqBy(steps, 'orderID').map(s => s.orderID)
        }
        if(!isDelegated && !isDelegatedPartnerRemoved){
          store.commit(ActionTypes.SET_ORDER_LOADING, true);
          const updatedResponse = await this.orderService.updateOrderPhaseStatusAsync(orderIds);
          //commenting these changes not required now sprint-18
          // if(updatedResponse.success){
          //   //updating confirmed orders at store
          //   await this.updatedOrderState(updatedResponse.orders);
          //   this.isOrdersUpdated = true;
          // }
        }else if(!isDelegated && isDelegatedPartnerRemoved){
          store.commit(ActionTypes.SET_ORDER_LOADING, true);
          await this.delegateToCp(orderLine,selectedDelegatePartner);
          const updatedResponse = await this.orderService.updateOrderPhaseStatusAsync(orderIds);
          //commenting these changes not required now sprint-18
          // if(updatedResponse.success){
          //   //updating confirmed orders at store
          //   await this.updatedOrderState(updatedResponse.orders);
          // }
        }else{
          await this.delegateToCp(orderLine,selectedDelegatePartner);
        }
        this.$store.dispatch(ActionTypes.SET_ORDER_LINE_EVIDENCE_COUNT);
        store.commit('setConfirmOrderLine', true);
        await this.$store.dispatch(ActionTypes.SET_ORDERLINE);
      }
    } finally {
      store.commit(ActionTypes.SET_ORDER_LOADING, false);
    }
  }
  //Commented this logic for removing lag on pending orderline page
  // private setCombinedOrders(): void {
    // const combinedOrders: CombinedOrderLineModel[] = [];
    // const data = lodash.cloneDeep(this.orderData);
    // const pendingOrders = data.filter(p => p.delegateCompanyID === Guid.Empty);
    // this.pendingOrdersCount = pendingOrders.length;
    // if (pendingOrders !== null && pendingOrders.length > 0) {
    //   store.commit(ActionTypes.SET_ORDER_LOADING, true);
    //   pendingOrders.forEach((order) => {
    //     const existingOrder = this.getCombinedOrders(combinedOrders, order);
    //     if (existingOrder == null) {
    //       const combinedOrder = this.createNewCombinedOrder(order);
    //       combinedOrders.push(combinedOrder);
    //     } else {
    //       this.addOrderToCombinedOrders(existingOrder, order);
    //     }
    //   });
    // }
    // this.combinedOrders = combinedOrders;
    // store.commit(ActionTypes.SET_ORDER_LOADING, false);
  // }

  //Commented this logic for removing lag on pending orderline page
  // private setDelegatedToCombinedOrders(): void {
    // const combinedOrders: CombinedOrderLineModel[] = [];
    // const user = this.$store.getters.user as UserModel;
    // const data = lodash.cloneDeep(this.orderData);
    // const pendingOrders = data.filter(p => p.delegateCompanyID !== Guid.Empty && p.delegateCompanyID === user.companyId);
    // this.delegatedToCount = pendingOrders.length;
    // if (pendingOrders !== null && pendingOrders.length > 0) {
    //   store.commit(ActionTypes.SET_ORDER_LOADING, true);
    //   pendingOrders.forEach((order) => {
    //     const existingOrder = this.getCombinedOrders(combinedOrders, order);
    //     if (existingOrder == null) {
    //       const combinedOrder = this.createNewCombinedOrder(order);
    //       combinedOrders.push(combinedOrder);
    //     } else {
    //       this.addOrderToCombinedOrders(existingOrder, order);
    //     }
    //   });
    // }
    // this.delegatedToCombinedOrders = combinedOrders;
    // store.commit(ActionTypes.SET_ORDER_LOADING, false);
  // }

  //Commented this logic for removing lag on pending orderline page
  // private setDelegatedByCombinedOrders(): void {
    // const combinedOrders: CombinedOrderLineModel[] = [];
    // const user = this.$store.getters.user as UserModel;
    // const data = lodash.cloneDeep(this.orderData);
    // const pendingOrders = data.filter(p => p.delegateCompanyID !== Guid.Empty && p.delegateCompanyID !== user.companyId);
    // this.delegatedByCount = pendingOrders.length;
    // if (pendingOrders !== null && pendingOrders.length > 0) {
    //   store.commit(ActionTypes.SET_ORDER_LOADING, true);
    //   pendingOrders.forEach((order) => {
    //     const existingOrder = this.getCombinedOrders(combinedOrders, order);
    //     if (existingOrder == null) {
    //       const combinedOrder = this.createNewCombinedOrder(order);
    //       combinedOrders.push(combinedOrder);
    //     } else {
    //       this.addOrderToCombinedOrders(existingOrder, order);
    //     }
    //   });
    // }
    // this.delegatedByCombinedOrders = combinedOrders;
    // store.commit(ActionTypes.SET_ORDER_LOADING, false);
  // }

  private getCombinedOrders(
    combinedOrders: CombinedOrderLineModel[],
    order: OrderLineDisplayModel
  ): CombinedOrderLineModel | null {
    let combine: CombinedOrderLineModel[];
    let combineOrder = null;
    if (order.orderRefId !== '') {
      combine = combinedOrders.filter(
        (o) =>
          o.orderRefId === order.orderRefId &&
          o.productGroup === order.productGroup
      );
      //Commented this logic for removing lag on pending orderline page

      // if (combine !== null && combine !== undefined && combine.length > 0) {
      //   const orderLine = combine[combine.length - 1];
      //   const steps: OrderLineStepDisplayModel[] =
      //     this.$store.getters.pendingOrderLineSteps;
      //   const orderLineSteps =
      //     steps === null
      //       ? []
      //       : steps.filter((s) => s.orderId === order.orderId);
      //   const combineOrderSteps =
      //     steps === null
      //       ? []
      //       : steps.filter((s) => s.orderId === orderLine.orderIds[0]);
      //   if (orderLineSteps.length === 0 && combineOrderSteps.length === 0) {
      //     combineOrder = orderLine;
      //   } else {
      //     for (const o of combineOrderSteps) {
      //       if (
      //         orderLineSteps.findIndex(
      //           (s) =>
      //             s.stepId === o.stepId &&
      //             s.sequence === o.sequence &&
      //             s.toCompanyId === o.toCompanyId
      //         ) === -1
      //       ) {
      //         combineOrder = null;
      //         break;
      //       } else {
      //         combineOrder = orderLine;
      //       }
      //     }
      //   }
      // }

      //Added this logic for removing lag on pending orderline page
      if (combine !== null && combine !== undefined && combine.length > 0) {
        combineOrder = combine[combine.length - 1];
      } else {
        combineOrder = null;
      }
    } else {
      combineOrder = null;
    }
    return combineOrder;
  }

  private createNewCombinedOrder(
    order: OrderLineDisplayModel
  ): CombinedOrderLineModel {
    return {
      id: uuidv4(),
      orderCount: 1,
      orderIds: [order.orderId],
      clientName: order.fromCompanyUser.name,
      orderRefId: order.orderRefId,
      productGroup: order.productGroup,
      clientId: order.fromCompanyUser.ID,
      delegatedByPartnerName: order.delegateCompanyUser.delegatedBy,
      delegatedDate: this.formattedDate(order.delegatedAt.toString())
    };
  }

  private formattedDate(date: string): string {
		if (date==="9999-01-01T00:00:00Z") {
			return "No deadline"
		}
		return moment(date).format("D MMM YYYY");
	}

  private addOrderToCombinedOrders(
    combinedOrder: CombinedOrderLineModel,
    order: OrderLineDisplayModel
  ): void {
    // Order lines must be combined per order
    if (combinedOrder.orderIds.indexOf(order.orderId) === -1) {
      combinedOrder.orderIds.push(order.orderId);
      combinedOrder.orderCount++;
    }
  }

  private get orderLines(): CombinedOrderLineModel[] {
    const combinedOrders: CombinedOrderLineModel[] = [];
    const data = lodash.cloneDeep(this.orderData);
    const pendingOrders = data.filter(p => p.delegateCompanyID === Guid.Empty);
    this.pendingOrdersCount = pendingOrders.length;
    if (pendingOrders !== null && pendingOrders.length > 0) {
      pendingOrders.forEach((order) => {
        const existingOrder = this.getCombinedOrders(combinedOrders, order);
        if (existingOrder == null) {
          const combinedOrder = this.createNewCombinedOrder(order);
          combinedOrders.push(combinedOrder);
        } else {
          this.addOrderToCombinedOrders(existingOrder, order);
        }
      });
    }
    this.combinedOrders = combinedOrders;
    return this.combinedOrders;
  }

  private get delegatedToOrderLines(): CombinedOrderLineModel[] {
    const combinedOrders: CombinedOrderLineModel[] = [];
    const user = this.$store.getters.user as UserModel;
    const data = lodash.cloneDeep(this.orderData);
    const pendingOrders = data.filter(p => p.delegateCompanyID !== Guid.Empty && p.delegateCompanyID === user.companyId);
    this.delegatedToCount = pendingOrders.length;
    if (pendingOrders !== null && pendingOrders.length > 0) {
      pendingOrders.forEach((order) => {
        const existingOrder = this.getCombinedOrders(combinedOrders, order);
        if (existingOrder == null) {
          const combinedOrder = this.createNewCombinedOrder(order);
          combinedOrders.push(combinedOrder);
        } else {
          this.addOrderToCombinedOrders(existingOrder, order);
        }
      });
    }
    this.delegatedToCombinedOrders = combinedOrders;
    return this.delegatedToCombinedOrders;
  }

  private get delegatedByOrderLines(): CombinedOrderLineModel[] {
    const combinedOrders: CombinedOrderLineModel[] = [];
    const user = this.$store.getters.user as UserModel;
    const data = lodash.cloneDeep(this.orderData);
    const pendingOrders = data.filter(p => p.delegateCompanyID !== Guid.Empty && p.delegateCompanyID !== user.companyId);
    this.delegatedByCount = pendingOrders.length;
    if (pendingOrders !== null && pendingOrders.length > 0) {
      pendingOrders.forEach((order) => {
        const existingOrder = this.getCombinedOrders(combinedOrders, order);
        if (existingOrder == null) {
          const combinedOrder = this.createNewCombinedOrder(order);
          combinedOrders.push(combinedOrder);
        } else {
          this.addOrderToCombinedOrders(existingOrder, order);
        }
      });
    }
    this.delegatedByCombinedOrders = combinedOrders;
    return this.delegatedByCombinedOrders;
  }

  private get pendingCount(): number {
    return this.pendingOrders !== null ? this.pendingOrders.length : 0;
  }

  private async confirmOrderLine(
    orderLine: OrderLineDisplayModel[],
    orderLineSteps: OrderLineStepModel[],
    isDelegated: boolean,
    selectedDelegatePartner: DelegateCompanyModel,
    isDelegatedPartnerRemoved: boolean
  ): Promise<void> {
    // store.commit(ActionTypes.SET_ORDER_LOADING, true);
    let confirmOrderLineStep: ConfirmStepBlockchainModel[] = [];
    const user = this.$store.getters.user as UserModel;
    const line: OrderLineStepModel[] =
      this.$store.getters.pendingOrderLineSteps;
    orderLine.forEach((order) => {
      const steps: OrderLineStepModel[] = line.filter(
        (l) => l.orderId === order.orderId
      );
      orderLineSteps.forEach((step, index) => {
        const line = new ConfirmStepBlockchainModel();
        if (step.id !== Guid.Empty) {
          line.ID = steps.filter((s) => s.stepId === step.stepId)[0].id;
        } else {
          line.ID = uuidv4();
        }
        if (step.supplierStep === true) {
          line.stepState = StepState.CREATED_BY_SUPPLIER;
        } else {
          line.stepState = step.stepState;
        }
        const stepHistory: StepHistoryBlockchainModel = {
          displayName: this.stepNames[step.stepId],
          stepID: step.stepId,
          sequence: step.sequence,
          description: '',
          createdByCompanyID:
            line.stepState === StepState.CREATED_BY_SUPPLIER
              ? user.companyId
              : order.fromCompanyUser.ID,
          createdByCompanyName:
            line.stepState === StepState.CREATED_BY_SUPPLIER
              ? user.companyName
              : order.fromCompanyUser.name,
          editedByCompanyID: user.companyId,
          editedByCompanyName: user.companyName,
          updatedAt: new Date().toISOString(),
        };
        line.orderID = order.orderId;
        line.orderLineID = order.id;
        line.sequence = index + 1;
        line.orderNumber = order.orderNumber;
        line.productGroup = order.productGroup;
        line.stepID = step.stepId;
        line.displayName = this.stepNames[step.stepId];
        line.fromCompanyID = step.fromCompanyId;
        line.toCompanyID = step.toCompanyId;
        line.toCompanyName = step.toCompanyName;
        line.evidencePhotos = step.evidencePhotos;
        line.status = step.status;
        line.clientID = step.clientID,
        line.cpSupplierID = step.cpSupplierID,
        line.approvalRequestedTo = step.approvalRequestedTo,
        line.delegatedCompanyID = selectedDelegatePartner.id,
        line.isLastStep = line.sequence === orderLineSteps.length ? 'true' : 'false';
        line.stepHistory.push(stepHistory);
        // TTD-3619 ref fields are added
        line.footprintRefNo = step.footprintRefNo;
        line.footprintRefID = step.footprintRefID;
        line.footprintRefNoUpdatedBy = step.footprintRefNoUpdatedBy;
        if (step.parallelSupplyChain0.length > 0) {
          const parallelChain0: ParallelStepBlockchainModel[] = [];
          step.parallelSupplyChain0.forEach((p, indexP) => {
            const parallelChainStep:ParallelStepBlockchainModel = new ParallelStepBlockchainModel(p, step.stepId.toString(), order);
            if (p.supplierStep) {
              parallelChainStep.stepState = StepState.CREATED_BY_SUPPLIER;
              parallelChainStep.fromCompanyID = user.companyId;
              parallelChainStep.orderNumber = order.orderNumber;
              parallelChainStep.displayName = this.stepNames[p.stepId];
              // TTD-3619 ref fields are added
              parallelChainStep.footprintRefNo = p.footprintRefNo;
              parallelChainStep.footprintRefID = p.footprintRefID;
              parallelChainStep.footprintRefNoUpdatedBy = p.footprintRefNoUpdatedBy;
            }
            parallelChain0.push(parallelChainStep);
          });
          line.parallelSupplyChain0 = parallelChain0;
        }
        if (step.parallelSupplyChain1.length > 0) {
          const parallelChain1: ParallelStepBlockchainModel[] = [];
          step.parallelSupplyChain1.forEach((p, indexP) => {
            const parallelChainStep:ParallelStepBlockchainModel = new ParallelStepBlockchainModel(p, step.stepId.toString(), order);
            if (p.supplierStep) {
              parallelChainStep.stepState = StepState.CREATED_BY_SUPPLIER;
              parallelChainStep.fromCompanyID = user.companyId;
              parallelChainStep.orderNumber = order.orderNumber;
              parallelChainStep.displayName = this.stepNames[p.stepId];
              // TTD-3619 ref fields are added
              parallelChainStep.footprintRefNo = p.footprintRefNo;
              parallelChainStep.footprintRefID = p.footprintRefID;
              parallelChainStep.footprintRefNoUpdatedBy = p.footprintRefNoUpdatedBy;
            }
            parallelChain1.push(parallelChainStep);
          });
          line.parallelSupplyChain1 = parallelChain1;
        }
        if (step.parallelSupplyChain2.length > 0) {
          const parallelChain2: ParallelStepBlockchainModel[] = [];
          step.parallelSupplyChain2.forEach((p, indexP) => {
            const parallelChainStep:ParallelStepBlockchainModel = new ParallelStepBlockchainModel(p, step.stepId.toString(), order);
            if (p.supplierStep) {
              parallelChainStep.stepState = StepState.CREATED_BY_SUPPLIER;
              parallelChainStep.fromCompanyID = user.companyId;
              parallelChainStep.orderNumber = order.orderNumber;
              parallelChainStep.displayName = this.stepNames[p.stepId];
              // TTD-3619 ref fields are added
              parallelChainStep.footprintRefNo = p.footprintRefNo;
              parallelChainStep.footprintRefID = p.footprintRefID;
              parallelChainStep.footprintRefNoUpdatedBy = p.footprintRefNoUpdatedBy;
            }
            parallelChain2.push(parallelChainStep);
          });
          line.parallelSupplyChain2 = parallelChain2;
        }
        if (step.parallelSupplyChain3.length > 0) {
          const parallelChain3: ParallelStepBlockchainModel[] = [];
          step.parallelSupplyChain3.forEach((p, indexP) => {
            const parallelChainStep:ParallelStepBlockchainModel = new ParallelStepBlockchainModel(p, step.stepId.toString(), order);
            if (p.supplierStep) {
              parallelChainStep.stepState = StepState.CREATED_BY_SUPPLIER;
              parallelChainStep.fromCompanyID = user.companyId;
              parallelChainStep.orderNumber = order.orderNumber;
              parallelChainStep.displayName = this.stepNames[p.stepId];
              // TTD-3619 ref fields are added
              parallelChainStep.footprintRefNo = p.footprintRefNo;
              parallelChainStep.footprintRefID = p.footprintRefID;
              parallelChainStep.footprintRefNoUpdatedBy = p.footprintRefNoUpdatedBy;
            }
            parallelChain3.push(parallelChainStep);
          });
          line.parallelSupplyChain3 = parallelChain3;
        }
        if (step.parallelSupplyChain4.length > 0) {
          const parallelChain4: ParallelStepBlockchainModel[] = [];
          step.parallelSupplyChain4.forEach((p, indexP) => {
            const parallelChainStep:ParallelStepBlockchainModel = new ParallelStepBlockchainModel(p, step.stepId.toString(), order);
            if (p.supplierStep) {
              parallelChainStep.stepState = StepState.CREATED_BY_SUPPLIER;
              parallelChainStep.fromCompanyID = user.companyId;
              parallelChainStep.orderNumber = order.orderNumber;
              parallelChainStep.displayName = this.stepNames[p.stepId];
              // TTD-3619 ref fields are added
              parallelChainStep.footprintRefNo = p.footprintRefNo;
              parallelChainStep.footprintRefID = p.footprintRefID;
              parallelChainStep.footprintRefNoUpdatedBy = p.footprintRefNoUpdatedBy;
            }
            parallelChain4.push(parallelChainStep);
          });
          line.parallelSupplyChain4 = parallelChain4;
        }
        confirmOrderLineStep.push(line);
      });
      // confirmOrderLineStep = this.setLastStep(confirmOrderLineStep);
      steps.forEach((s) => {
        const index = confirmOrderLineStep.findIndex(
          (item) => item.ID === s.id
        );
        if (index === -1) {
          const step = new ConfirmStepBlockchainModel();
          const stepHistory: StepHistoryBlockchainModel = {
            displayName: this.stepNames[step.stepID],
            stepID: s.stepId,
            sequence: s.sequence,
            description: '',
            createdByCompanyID: order.fromCompanyId,
            createdByCompanyName: order.fromCompanyUser.name,
            editedByCompanyID: user.companyId,
            editedByCompanyName: user.companyName,
            updatedAt: new Date().toISOString(),
          };
          step.ID = s.id;
          step.orderID = s.orderId;
          step.orderLineID = s.id;
          step.sequence = s.sequence;
          step.orderNumber = s.orderNumber;
          step.productGroup = s.productGroup;
          step.stepID = s.stepId;
          step.displayName = this.stepNames[s.stepId];
          step.fromCompanyID = s.fromCompanyId;
          step.toCompanyID = s.toCompanyId;
          step.toCompanyName = s.toCompanyName;
          step.evidencePhotos = s.evidencePhotos;
          step.status = s.status;
          step.stepState = StepState.DELETED_BY_SUPPLIER;
          step.isLastStep = s.isLastStep;
          step.clientID = s.clientID,
          step.cpSupplierID = s.cpSupplierID,
          step.approvalRequestedTo = s.approvalRequestedTo,
          step.stepHistory.push(stepHistory);
          confirmOrderLineStep.push(step);

          if (s.parallelSupplyChain0.length > 0) {
            const parallelChain0: ParallelStepBlockchainModel[] = [];
            s.parallelSupplyChain0.forEach((p, indexP) => {
              const parallelChainStep:ParallelStepBlockchainModel = new ParallelStepBlockchainModel(p, s.stepId.toString(), order);
              if (p.supplierStep) {
                parallelChainStep.stepState = StepState.DELETED_BY_SUPPLIER;
                parallelChainStep.fromCompanyID = user.companyId;
                parallelChainStep.orderNumber = order.orderNumber;
                parallelChainStep.displayName = this.stepNames[p.stepId];
              }
              parallelChain0.push(parallelChainStep);
            });
            step.parallelSupplyChain0 = parallelChain0;
          }
          if (s.parallelSupplyChain1.length > 0) {
            const parallelChain1: ParallelStepBlockchainModel[] = [];
            s.parallelSupplyChain1.forEach((p, indexP) => {
              const parallelChainStep:ParallelStepBlockchainModel = new ParallelStepBlockchainModel(p, s.stepId.toString(), order);
              if (p.supplierStep) {
                parallelChainStep.stepState = StepState.DELETED_BY_SUPPLIER;
                parallelChainStep.fromCompanyID = user.companyId;
                parallelChainStep.orderNumber = order.orderNumber;
                parallelChainStep.displayName = this.stepNames[p.stepId];
              }
              parallelChain1.push(parallelChainStep);
            });
            step.parallelSupplyChain1 = parallelChain1;
          }
          if (s.parallelSupplyChain2.length > 0) {
            const parallelChain2: ParallelStepBlockchainModel[] = [];
            s.parallelSupplyChain2.forEach((p, indexP) => {
              const parallelChainStep:ParallelStepBlockchainModel = new ParallelStepBlockchainModel(p, s.stepId.toString(), order);
              if (p.supplierStep) {
                parallelChainStep.stepState = StepState.DELETED_BY_SUPPLIER;
                parallelChainStep.fromCompanyID = user.companyId;
                parallelChainStep.orderNumber = order.orderNumber;
                parallelChainStep.displayName = this.stepNames[p.stepId];
              }
              parallelChain2.push(parallelChainStep);
            });
            step.parallelSupplyChain2 = parallelChain2;
          }
          if (s.parallelSupplyChain3.length > 0) {
            const parallelChain3: ParallelStepBlockchainModel[] = [];
            s.parallelSupplyChain3.forEach((p, indexP) => {
              const parallelChainStep:ParallelStepBlockchainModel = new ParallelStepBlockchainModel(p, s.stepId.toString(), order);
              if (p.supplierStep) {
                parallelChainStep.stepState = StepState.DELETED_BY_SUPPLIER;
                parallelChainStep.fromCompanyID = user.companyId;
                parallelChainStep.orderNumber = order.orderNumber;
                parallelChainStep.displayName = this.stepNames[p.stepId];
              }
              parallelChain3.push(parallelChainStep);
            });
            step.parallelSupplyChain3 = parallelChain3;
          }
          if (s.parallelSupplyChain4.length > 0) {
            const parallelChain4: ParallelStepBlockchainModel[] = [];
            s.parallelSupplyChain4.forEach((p, indexP) => {
              const parallelChainStep:ParallelStepBlockchainModel = new ParallelStepBlockchainModel(p, s.stepId.toString(), order);
              if (p.supplierStep) {
                parallelChainStep.stepState = StepState.DELETED_BY_SUPPLIER;
                parallelChainStep.fromCompanyID = user.companyId;
                parallelChainStep.orderNumber = order.orderNumber;
                parallelChainStep.displayName = this.stepNames[p.stepId];
              }
              parallelChain4.push(parallelChainStep);
            });
            step.parallelSupplyChain4 = parallelChain4;
          }
        }
      });
    });
    this.createOrderLine(true, confirmOrderLineStep, isDelegated, selectedDelegatePartner, orderLine, isDelegatedPartnerRemoved);
  }

  private async delegateToCp(orderLine:OrderLineDisplayModel[], selectedDelegatePartner: DelegateCompanyModel){
    const suppFiltered = this.suppliers.filter(s => s.id === selectedDelegatePartner.id);
    let updateDelegate: boolean = true;
    if (suppFiltered.length> 0 &&  suppFiltered[0].isDelegatePartner === false) {
      const user = this.$store.getters.user as UserModel;
      updateDelegate = await this.orderService.updatedIsDelegated(user.companyId, selectedDelegatePartner.id);
    }
    if (updateDelegate === false) {
      NotificationHelper.createErrorNotification("Something went wrong while updating partner status, please try again.");
      return;
    }
    // if(selectedDelegatePartner.id !== Guid.Empty && selectedDelegatePartner.id !== ''){
      const delegateCompany: OrderDelegateCompanyModel = {
        orderIdList: orderLine.map(o => o.orderId),
        delegateUserDetails: {
          delegateCompanyID: selectedDelegatePartner.id,
          companyName: selectedDelegatePartner.companyName,
          delegatedBy: selectedDelegatePartner.id !=='' && selectedDelegatePartner.id!==Guid.Empty ? this.user.companyName : '',
          delegatedByCompanyID: selectedDelegatePartner.id !=='' && selectedDelegatePartner.id!==Guid.Empty ? this.user.companyId : '',
          email: selectedDelegatePartner.companyEmail,
          firstName: selectedDelegatePartner.firstName,
          lastName: selectedDelegatePartner.lastName
        }
      }
      const response = await this.supplierService.updateDelegatePartnerAsync(delegateCompany, this.user.companyId);
      //TTD-3710 child issue-2 of TTD-2422 adden notification after for delegation
      if(response.success){
        const delegatePartnerMail: OrderDelegatePartnerReqModel = {
          delegatedPartnerEmail: delegateCompany.delegateUserDetails.email,
          delegatedPartnerName: delegateCompany.delegateUserDetails.companyName,
          CPName: delegateCompany.delegateUserDetails.delegatedBy
        }
        //commenting these changes not required now sprint-18
        // this.updatedOrderState(response.orders);
        //while removed delegate partner no need to trigger mail and show notification.
        if(response.orders[0].delegateCompanyID!==Guid.Empty && response.orders[0].delegateCompanyID!==''){
          this.orderService.triggerMailDelegatePartner(delegatePartnerMail);
          NotificationHelper.createSucceededNotification(i18n.t('pages.order_lines.sent_delegated_partner').toString());
        }
        //commenting these changes not required now sprint-18
        // this.isOrdersUpdated = true;
      } else{
        NotificationHelper.createErrorNotification("Something went wrong while sending orders to delegate partner, please try again!");
        //commenting these changes not required now sprint-18
        // this.isOrdersUpdated = true;
      }
    // }
  }

  // private updateOrderLineStepsAtStore(resSteps:OrderLineStepDisplayModel[], isDelegated:boolean): void{
  //   //updating pending order steps at store TTD-2422
  //   const storedPendingSteps:OrderLineStepDisplayModel[] = this.$store.getters.pendingOrderLineSteps;
  //   resSteps.forEach((step) => {
  //       const index = storedPendingSteps.findIndex((st) => st.orderId === step.orderId);
  //       const resStep: OrderLineStepDisplayModel = new OrderLineStepDisplayModel();
  //       resStep.id = step.id;
  //       resStep.orderId = step.orderId;
  //       resStep.orderLineId = step.orderId;
  //       resStep.orderNumber = step.orderNumber;
  //       resStep.stepId = step.stepId;
  //       resStep.productGroup = step.productGroup;
  //       resStep.sequence = step.sequence;
  //       resStep.status = step.status;
  //       resStep.toCompanyId = step.toCompanyId;
  //       resStep.toCompanyName = step.toCompanyName;
  //       resStep.fromCompanyId = step.fromCompanyId;
  //       resStep.clientID = step.clientID;
  //       resStep.cpSupplierID = step.cpSupplierID;
  //       resStep.approvalRequestedTo = step.approvalRequestedTo;
  //       resStep.evidencePhotos = step.evidencePhotos;
  //       resStep.parallelSupplyChain0 = step.parallelSupplyChain0;
  //       resStep.parallelSupplyChain1 = step.parallelSupplyChain1;
  //       resStep.parallelSupplyChain2 = step.parallelSupplyChain2;
  //       resStep.parallelSupplyChain3 = step.parallelSupplyChain3;
  //       resStep.parallelSupplyChain4 = step.parallelSupplyChain4;
  //       if(index!==-1){
  //         storedPendingSteps.splice(index, 1, resStep);
  //       }else{
  //         storedPendingSteps.push(resStep);
  //       }
  //   })
  //   store.commit(ActionTypes.SET_PENDING_ORDER_LINE_STEPS, []);
  //   store.commit(ActionTypes.SET_PENDING_ORDER_LINE_STEPS, storedPendingSteps);
  //   this.isPendingStepsUpdated = true;
  //   if(!isDelegated && resSteps.length > 0){
  //     NotificationHelper.createSucceededNotification(i18n.t('global.notifications.supply_chain_creation_succeeded').toString());
  //     this.setCombinedOrders();
  //   }
  // }
  //check steps or customised or not TTD-2422
  private checkStepsCustomised(orderLineSteps:ConfirmStepBlockchainModel[]){
    if(orderLineSteps.findIndex(s => (s.toCompanyID !==Guid.Empty && s.toCompanyID !=='') ||
        s.parallelSupplyChain0.findIndex(pc0=> pc0.toCompanyID !== Guid.Empty && pc0.toCompanyID !== '') !== -1 ||
        s.parallelSupplyChain1.findIndex(pc1=> pc1.toCompanyID !== Guid.Empty && pc1.toCompanyID !== '') !== -1 ||
        s.parallelSupplyChain2.findIndex(pc2=> pc2.toCompanyID !== Guid.Empty && pc2.toCompanyID !== '') !== -1 ||
        s.parallelSupplyChain3.findIndex(pc3=> pc3.toCompanyID !== Guid.Empty && pc3.toCompanyID !== '') !== -1 ||
        s.parallelSupplyChain4.findIndex(pc4=> pc4.toCompanyID !== Guid.Empty && pc4.toCompanyID !== '') !== -1) !== -1){
        return true;
    }else{
        return false;
    }
  }
  //commenting these changes not required now sprint-18
  // private resetUpdateVar(): void{
  //   this.isOrdersUpdated = false;
  //   this.isPendingStepsUpdated = false;
  // }


  private updatedOrderState(orders: OrderLineDisplayModel[]): void {
    const allOrders = this.orders;
    const allPendingOrders = this.pendingOrders;
    orders.forEach(order => {
      const index = this.orders.findIndex(o => o.orderId === order.orderId);
      if(order.orderLines[0].orderLinePhase === 1 && order.orderLines[0].orderLineStatus === 0){
        if(index !== -1){
          allPendingOrders.splice(index, 0, order);
        }
      } else {
        if(index !== -1){
          allOrders.splice(index, 0, order);
        }
      }
    })
    store.commit(ActionTypes.SET_ORDER, allOrders);
    store.commit(ActionTypes.SET_PENDING_ORDERS, allPendingOrders);
    //Commented this logic for removing lag on pending orderline page
    // this.runCombineOrder();
  }
//Commented this logic for removing lag on pending orderline page
  // @Watch('orderData', {deep: true})
  // private runCombineOrder(): void {
  //   this.getOrders();
  // }
}
