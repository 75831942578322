import i18n from '@/i18n';
import { OrderClient } from '@/clients/orderClient';
import { NotificationHelper } from '@/helpers/notificationHelper';
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { OrderModel } from '@/models/orderModel';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import moment from 'moment';
import store from '@/store';
import { OrderWithEvidenceSteps } from '@/models/orderWithEvidenceStepsModel';
import {
  OrdersWithStepsModal,
  OrdersWithCountModel,
} from '@/models/ordersWithStepsModel';
import { UserModel } from '@/models/userModel';
import { ComplianceCertificatesClient } from '@/clients/complianceCertificatesClient';
import { EvidenceStepCountModel } from '@/models/orderLineEvidenceStep';
import { OrderIdListModel } from '@/models/orderLineDisplayModel';
import { SupplierEvidence } from '@/clients/supplierEvidence';
import { ConfirmStepBlockchainModel } from '@/models/confirmOrderLineStepModal';
import { ConfirmOrderModel } from "@/models/confirmOrderModel";
import { StepsRespModel } from '@/models/stepsResponseModel';
import { OrderCompanyRespModel, OrderDelegatePartnerReqModel } from '@/models/delegateCompanyModel';
import { CompanyType } from '@/models/company';

export class OrderService {
  private readonly client: OrderClient = new OrderClient();
  private readonly supplierClient: ComplianceCertificatesClient =
    new ComplianceCertificatesClient();
  private readonly supplierEvidence: SupplierEvidence = new SupplierEvidence();

  public async getOrdersAsync(
    bookmark: string,
    pageSize: number
  ): Promise<OrdersWithCountModel> {
    const user = store.getters.user as UserModel;
    // TTD-2630 Sprint 18
    let resultDelegated: OrdersWithCountModel = new OrdersWithCountModel();
    if (user.isDelegatePartner) {
      resultDelegated = await this.supplierClient.getOrdersForDelegatedAsync(
        bookmark,
        pageSize,
        user.companyId
      );
    }
    let result: OrdersWithCountModel = new OrdersWithCountModel();
    if (user.isT1Scp) {
      result = await this.supplierClient.getOrdersAsync(
        bookmark,
        pageSize,
        user.companyId
      );
    }
    // TTD-2630 Sprint 18
    if (result.orders === undefined) {
      result = resultDelegated;
    } else if(resultDelegated.orders !== undefined) {
      result.count += resultDelegated.count;
      result.totalStyles += resultDelegated.totalStyles;
      result.orders.push(...resultDelegated.orders);
      result.orderLineSteps.push(...resultDelegated.orderLineSteps);
    }
    if (result.success === true && result.orders.length > 0) {
      result.orders.forEach((order) => {
        // Initializing the properties so Vue picks up changes
        order.scpCompanyName = '';
        (order.evidenceCreatedBy = ''), (order.evidenceCreatedAt = moment());
        order.stepStatus = OrderLineStepStatus.None;
        order.show = true;
        order.expectedTimeOfDelivery = moment(order.expectedTimeOfDelivery);
      });
    }
    return result;
  }

  public async getOrderLineStepsAsync(
    orderId: string,
    orderLineId: string
  ): Promise<OrderLineStepModel[]> {
    const result = await this.client.getOrderLineStepsAsync(
      orderId,
      orderLineId
    );
    return result;
  }

  public async updatedIsDelegated(companyId: string, invitedSupplierID: string): Promise<boolean> {
    const result = await this.supplierClient.updatedIsDelegated(companyId, invitedSupplierID);
    return result.success;
  }

  // public async getPendingOrdersAsync(
  //   bookmark: string
  // ): Promise<OrdersWithStepsModal> {
  //   const result = await this.client.getPendingOrdersAsync(bookmark);
  //   return result;
  // }

  public async createSupplyChainStepsAsync(
    steps: OrderLineStepModel[]
  ): Promise<void> {
    await this.client.createSupplyChainStepsAsync(steps);
    NotificationHelper.createSucceededNotification(
      i18n.t('global.notifications.supply_chain_creation_succeeded').toString()
    );
  }

  // public async editSupplyChainStepsAsync(
  //   steps: OrderLineStepModel[],
  //   type: string
  // ): Promise<void> {
  //   await this.client.editSupplyChainStepsAsync(steps);
  //   switch (type) {
  //     case 'create':
  //       NotificationHelper.createSucceededNotification(
  //         i18n
  //           .t('global.notifications.supply_chain_creation_succeeded')
  //           .toString()
  //       );
  //       store.commit('setConfirmOrderLine', true);
  //       break;

  //     case 'edit':
  //       NotificationHelper.createSucceededNotification(
  //         i18n
  //           .t('global.notifications.supply_chain_update_succeeded')
  //           .toString()
  //       );
  //       break;

  //     default:
  //       break;
  //   }
  // }

  public async updateOrderStepsBlockchainAsync(
    steps: ConfirmStepBlockchainModel[],
    type: string
  ): Promise<void> {
    const response = await this.supplierEvidence.updateOrderStepsBlockchainAsync(steps);
    switch (type) {
      case 'CREATE':
        if(response.success){
          NotificationHelper.createSucceededNotification(
            i18n
              .t('global.notifications.supply_chain_creation_succeeded')
              .toString()
          );
          store.commit('setConfirmOrderLine', true);
        }
        else{
          NotificationHelper.createErrorNotification(
            'Failed to confirm order line steps. Please try again.'
          );
        }
        break;

      case 'EDIT':
        if(response.success){
          NotificationHelper.createSucceededNotification(
            i18n
              .t('global.notifications.supply_chain_update_succeeded')
              .toString()
          );
        }
        else{
          NotificationHelper.createErrorNotification(
            'Failed to updated order line steps. Please try again.'
          );
        }

        break;

      default:
        break;
    }
  }

  public async getOrderLineEvidenceStepsByStatusAsync(companyId: string): Promise<OrderWithEvidenceSteps> {
    return await this.supplierEvidence.getOrderLineEvidenceStepsByStatusAsync(companyId);
  }

  public async updateLastStep(data: object): Promise<void> {
    this.client.updateLastStep(data);
  }

  public getEvidenceStepsCountAsync(companyId: string): Promise<EvidenceStepCountModel> {
    const user = store.getters.user as UserModel;
    let partnerType = '';
    if(user.isT1Scp && !user.isDelegatePartner){
      partnerType = CompanyType.CP
    } else if(!user.isT1Scp && user.isDelegatePartner){
      partnerType = CompanyType.DP
    } else if(user.isT1Scp && user.isDelegatePartner){
      partnerType = CompanyType.CPDP
    } else {
      partnerType = CompanyType.SCP
    }
    return this.supplierClient.getEvidenceStepsCountAsync(companyId, partnerType);
  }

  public async updateOrderPhaseStatusAsync(orderIdList: OrderIdListModel): Promise<OrderCompanyRespModel> {
    const response = await this.supplierClient.updateOrderPhaseStatusAsync(orderIdList);
    if(!response){
      NotificationHelper.createErrorNotification('Failed to update order status');
    }
    return response;
  }

  public async confirmOrderAsync(orders: ConfirmOrderModel[]): Promise<OrdersWithStepsModal> {
    const response = await this.client.confirmOrderAsync(orders);
    return response;
  }

  //get orderLinesteps response to update the steps at store TTD-2422
  public async updateOrderStepsAsync(
    steps: ConfirmStepBlockchainModel[],
    type: string
  ): Promise<StepsRespModel> {
    const response = await this.supplierEvidence.updateOrderStepsAsync(steps);
    return response;
  }

  // TTD-2422 api to trigger mail for order which are assigned to delegated partner
  public async triggerMailDelegatePartner(delegateMailData: OrderDelegatePartnerReqModel): Promise<Response> {
    const response = await this.client.triggerMailDelegatePartner(delegateMailData);
    return response;
  }

  //Sprint-20 TTD-3931 get steps for regular scp user
  public getScpOrderLineSteps(supplierId:string,orderId: string,lineId:string): Promise<StepsRespModel> {
    return this.supplierClient.getScpOrderLineSteps(supplierId,orderId,lineId);
  }

}
