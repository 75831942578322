import { Vue, Component, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import store from '@/store';

// Components
import CompanyForm from '@/views/profile/components/companyForm.vue';
import MyDetails from '@/views/profile/components/myDetails.vue';
import AdminSettings from '@/views/profile/components/adminSettings.vue';
import InviteModal from '@/views/profile/components/inviteModal.vue';

// Models
import { UserModel } from '@/models/userModel';
import { ContactModel } from '@/models/contactModel';
import { CompanyModel } from '@/models/companyModel';

//helper
import { NonRomanCharCheckHelper } from '@/helpers/nonRomanCharCheckHelper';

@Component({
  components: { CompanyForm, MyDetails, AdminSettings, InviteModal },
})
export default class Profile extends Vue {
  private tabIndex: number = 0;

  private changedSettingsCounter: number = 0;
  private admin: ContactModel = new ContactModel();
  private showInviteModal: boolean = false;
  private userListFilter: string = '';
  private reloadList: boolean = false;
  private isAdmin: boolean = false;
  private user: UserModel = new UserModel();
  private showEdit: boolean = false;
  private userType: string = '';

  private valid: boolean = true;
  private isNonRomanChar: boolean = false;

  public constructor() {
    super();
  }

  public async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if (this.$route.params.selectTab == 'companydetails') {
      this.selectedTab(1);
    }
  }

  private get isAdminUser(): boolean {
    const company = this.$store.getters.company as CompanyModel;
    const user = this.$store.getters.user as UserModel;
    if (company && user && screen.availWidth >= 1140) {
      if (user.isAdmin === 'true') {
        setTimeout((func: any) => {
          func.redirectAdminSettingHandler();
        }, 150, this);
        return true;
      } else {
        const userRole = this.$store.getters.userRole;
        this.userType = user.userType;
        this.isAdmin = userRole == 'Admin' ? true : false;
        if (
          this.isAdmin &&
          this.userType == 'COMPANY_ADMIN' &&
          company.contact.email.toLowerCase() === user.email.toLowerCase() &&
          screen.availWidth >= 1140
        ) {
          setTimeout((func: any) => {
            func.redirectAdminSettingHandler();
          }, 150, this);
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  private get title(): string[] {
    return [
      i18n.t('pages.profile.my_details').toString(),
      i18n.t('pages.profile.company_details').toString(),
      i18n.t('pages.profile.admin_details').toString(),
    ];
  }

  private get isMobile(): boolean {
    return screen.availWidth <= 636 ? true : false;
  }

  private selectedTab(index: number): void {
    this.tabIndex = index;
  }

  private setChangedSettingsCounter(value: number): void {
    this.changedSettingsCounter = value;
    if (value > 0) {
      store.commit('setCanNavigate', false);
    } else {
      store.commit('setCanNavigate', true);
    }
  }

  private enableTab(valid: boolean): void {
    this.valid = valid;
  }

  private openInviteModal(): void {
    this.showInviteModal = true;
  }

  private closeInviteModal(): void {
    this.showInviteModal = false;
    this.user = new UserModel();
    this.showEdit = false;
  }

  private reload(): void {
    this.reloadList = true;
  }

  private reloadSuccess(): void {
    this.reloadList = false;
  }

  private showEditModal(user: UserModel): void {
    this.user = user;
    this.showEdit = true;
    this.showInviteModal = true;
  }

  private redirectAdminSettingHandler(): void {
    if (this.$route.params.admin == '1') {
      this.selectedTab(2);
    }
  }

  private async checkNonRomanChar(val:string): Promise<void> {
    this.isNonRomanChar = await NonRomanCharCheckHelper.checkNonRomanChar(val)
  }

  @Watch('userListFilter')
  private async onUserListFilterChanged(
    userListFilter: string
  ): Promise<void> {}
}
