import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import i18n from '@/i18n';
import lodash from 'lodash';
import store from '@/store';
import { debounce } from 'ts-debounce';
import ClickOutside from 'vue-click-outside';

// Component
import DropDownEditable from '@/components/general/drop-down-editable/dropDownEditable.vue';

// Services
import { InvitationService } from '@/services/invitationService';
import { SupplierService } from '@/services/supplierService';
import { GeolocationService } from '@/services/geolocationService';
import { CountryStateCityService } from '@/services/countryStateCityService';

// Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';
import { CountryHelper } from '@/helpers/countryHelper';
import { NonRomanCharCheckHelper }from '@/helpers/nonRomanCharCheckHelper'

// Models
import { DropdownModel } from '@/models/dropdownModel';
import { InvitationModel } from '@/models/invitationModel';
import { InvitedSupplierModel, InvitedSupplierResponseModel } from '@/models/invitedSupplierModel';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { UserModel } from '@/models/userModel';
import { InviteDropdownModel } from '@/models/inviteDropdownModel';
import { InvitedCompaniesModel, CompaniesModel } from '@/models/invitedCompaniesModel';
import { AddressModel } from '@/models/addressModel';

@Component({
  components: { Multiselect, DropDownEditable },
  directives: { ClickOutside }
})
export default class SearchSupplier extends Vue {
  @Prop()
  private items!: InviteDropdownModel[];

  @Prop()
  private isLoading!: DropdownModel[];

  @Prop()
  private suppliers!: OrderCompanyModel[];

  @Prop()
  private isDelegate!: boolean;
  //TTD-3619
  @Prop()
  private toCompanyId!: string;

  @Prop()
  private refNum!: string;

  private countriesDropdown: DropdownModel[] = [];
  private statesDropdown: DropdownModel[] = [];
  private citiesDropdown: DropdownModel[] = [];

  private searchText: string = '';

  private inviteSendToContactEmail: string = '';

  private invitationService: InvitationService;

  private supplierService: SupplierService;

  private countries: DropdownModel[] = [];

  private invitationModel: InvitationModel = new InvitationModel();

  private showInviteSupplierModal: boolean = false;

  private isInviting: boolean = false;

  private isLinking: boolean = false;

  private cscService: CountryStateCityService;
  private geolocationService: GeolocationService;

  private mapboxAccessToken: string = '';

  private mapboxGeolocationCoordinates: any;

  //  error handling and validation
  private contactEmailError: boolean = false;

  private firstNameError: boolean = false;

  private lastNameError: boolean = false;

  private companyNameError: boolean = false;

  private companyRegistrationNumberError: boolean = false;

  private cityError: boolean = false;

  private stateError: boolean = false;

  private countryError: boolean = false;

  private partnerTypeError: boolean = false;

  private isCountryNonRoman: boolean = false;

  private isCityNonRoman: boolean = false;

  private checkFieldsDebounced: any;
  private heading: string = "";
  private centerText: string = "";
  private bottomText: string = "";
  private result: InvitedSupplierResponseModel = new InvitedSupplierResponseModel();
  private showConfirmModal: boolean = false;
  private existingRegNoError: boolean = false;
  private existingEmailError: boolean = false;
  private isRegNumberNonRoman: boolean = false;
  private isCompanyNameNonRoman: boolean = false;
  private isFirstNameNonRoman: boolean = false;
  private isLastnameNonRoman: boolean = false;
  private isEmailNonRoman: boolean = false;
  private allItems: InviteDropdownModel[] = [];
  private referenceNumber: string = '';
  private selectedCountry: string = "";
  private selectedState: string = "";
  private selectedCity: string = "";
  private invitedCompanies: CompaniesModel[] = [];
  private filteredData: CompaniesModel[] = [];
  private isRowselectedName: boolean = false;
  private isRowselectedReg: boolean = false;
  private showCompanyPallete: boolean = false;
  private showRegPallete: boolean = false;
  private showCpWarning: boolean = false;
  private debounceTimeout: number = -1;
  private fNameSpecialCharCheck: boolean = false;

  public constructor() {
    super();
    this.invitationService = new InvitationService();
    this.supplierService = new SupplierService();
    this.cscService = new CountryStateCityService();
    this.geolocationService = new GeolocationService();
  }

  private async created(): Promise<void> {
    this.countriesDropdown = [];
    this.countriesDropdown = CountryHelper.getCountryList();
    this.existingRegNoError = false;
    this.existingEmailError = false;
    this.checkFieldsDebounced = debounce(this.validateField, 250, {
      maxWait: 250,
      isImmediate: true,
    });
    this.allItems = lodash.cloneDeep(this.items);
    //TTD-3619
    this.setPartnerAndRef();
  }

  // Sprint 23 - TTD-3647, updated
  private async searchBy(field: string): Promise<void> {
    this.filteredData = [];
    let response: InvitedCompaniesModel = new InvitedCompaniesModel();
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(async()=>{
      if (field === 'name' && this.invitationModel.companyName.length > 1) {
          response = await this.invitationService.getCompanyFromNameAsync(this.invitationModel.companyName.toLowerCase());
      }
      if (field === 'registration' && this.invitationModel.companyRegistrationNumber.length > 1) {
          response = await this.invitationService.getCompanyFromRegAsync(this.invitationModel.companyRegistrationNumber.toLowerCase());
      }
      this.filteredData.splice(0, this.filteredData.length);
      const user = store.getters.user as UserModel;
      if (((field === 'name' && this.invitationModel.companyName.length > 1) || (field === 'registration' && this.invitationModel.companyRegistrationNumber.length > 1)) && response.companies.length > 0) {
        if(user.companyId !== null && user.companyId !== undefined && !user.companyName.startsWith("TT -") && !user.companyName.startsWith("TT-") && !user.companyName.startsWith("tt-") && !user.companyName.startsWith("tt -")){
          response.companies = response.companies.filter(c => !c.companyID.startsWith("TT -") && !c.companyID.startsWith("TT-") && !c.companyID.startsWith("tt-") && !c.companyID.startsWith("tt -") && !c.name.startsWith("TT -") && !c.name.startsWith("TT-") && !c.name.startsWith("tt-") && !c.name.startsWith("tt -"));
        }
        response.companies.forEach((company) => {
            if (field === 'name' && company.isBlocked === false) {
                this.filteredData.push(company);
            }
            if (field === 'registration' && company.isBlocked === false) {
                this.filteredData.push(company);
            }
        });
      }
      this.filteredData = this.filteredData.sort((user1,user2) => {
        return user2.name.toLowerCase() > user1.name.toLowerCase()
          ? -1
          : user2.name.toLowerCase() < user1.name.toLowerCase()
          ? 1
          : 0;
      });
      if (field === 'name' && this.filteredData.length > 0) {
          this.showCompanyPallete = true;
      } else {
          this.showCompanyPallete = false;
      }
      if (field === 'registration' && this.filteredData.length > 0) {
          this.showRegPallete = true;
      } else {
          this.showRegPallete = false;
      }
    }, 250, this);
  }

  // Sprint 23 - TTD-3647, updated
  private selectedRow(data: CompaniesModel): void {
    if(data.companyType === 5) {
      this.showCpWarning = true;
      return;
    }
    let countryInFull = "";
    if (data.officeAddresses.length > 0 && data.officeAddresses[0].country !== '') {
      countryInFull = this.countriesDropdown.filter(cd => cd.value === data.officeAddresses[0].country)[0].text
    }
    this.invitationModel.companyName = data.name;
    this.invitationModel.companyRegistrationNumber = data.companyID; // TTD-4736
    if (data.officeAddresses.length > 0) {
      this.invitationModel.country = data.officeAddresses[0].country ? data.officeAddresses[0].country : '';
      this.invitationModel.state = data.officeAddresses[0].state ? data.officeAddresses[0].state : '';
      this.invitationModel.city = data.officeAddresses[0].city ? data.officeAddresses[0].city : '';
    } else {
        this.invitationModel.country = '';
        this.invitationModel.state = '';
        this.invitationModel.city = '';
    }
    this.selectedCountry = countryInFull;
    this.selectedState = this.invitationModel.state;
    this.selectedCity = this.invitationModel.city;
    if (this.showCompanyPallete) {
        this.isRowselectedName = true;
    } else {
        this.isRowselectedReg = true;
    }
    this.showRegPallete = false;
    this.showCompanyPallete = false;
  }

  private emptyOnFocus(): void {
    if (this.isRowselectedName || this.isRowselectedReg) {
        this.invitationModel.companyName = "";
        this.invitationModel.companyRegistrationNumber = "";
        this.invitationModel.country = "";
        this.invitationModel.state = "";
        this.invitationModel.city = "";
        this.selectedCountry = "";
        this.selectedState = "";
        this.selectedCity = "";
        this.isRowselectedName = false;
        this.isRowselectedReg = false;
    }
  }

  // Sprint 23 - TTD-3647, updated
  private closeRegPallete(): void {
      this.showRegPallete = false;
      // this.invitationModel.companyRegistrationNumber = '';
  }

  // Sprint 23 - TTD-3647, updated
  private closeCompanyPallete(): void {
      this.showCompanyPallete = false;
      // this.invitationModel.companyName = '';
  }

  private closeWarningModal(): void {
    this.showCpWarning = false;
  }

  private get filteredItems(): InviteDropdownModel[] {
    let items :InviteDropdownModel[] = this.allItems.filter((f) =>
      f.text.toLowerCase().match(this.searchText.toLowerCase())
    )
    items = items.sort((user1,user2) => {
      return user2.text.toLowerCase() > user1.text.toLowerCase()
        ? -1
        : user2.text.toLowerCase() < user1.text.toLowerCase()
        ? 1
        : 0;
    });
    return items;
  }

  private closeInviteSupplierModal(): void {
    this.countryError = false;
    this.companyRegistrationNumberError = false;
    this.companyNameError = false;
    this.firstNameError = false;
    this.lastNameError = false;
    this.stateError = false;
    this.cityError = false;
    this.contactEmailError = false;
    this.showInviteSupplierModal = false;
    this.selectedCountry = '';
    this.selectedState = '';
    this.selectedCity = '';
    this.isRowselectedReg = false;
    this.isRegNumberNonRoman = false;
  }

  private openInviteSupplierModal(): void {
    this.invitationModel = new InvitationModel();
    this.inviteSendToContactEmail = '';
    this.showInviteSupplierModal = true;
    this.getMapboxTokenAsync();
  }

  private get sendInvitationDisabled(): boolean {
    let isError = this.inviteSendToContactEmail === this.invitationModel.toContactEmail;
    if (!isError) {
      isError =
        this.contactEmailError ||
        this.invitationModel.toContactEmail === '' ||
        this.invitationModel.companyRegistrationNumber === '' ||
        this.firstNameError || this.fNameSpecialCharCheck ||
        this.invitationModel.MainContactFirstName === '' ||
        this.lastNameError ||
        this.invitationModel.MainContactLastName === '' ||
        this.companyRegistrationNumberError ||
        this.companyNameError ||
        this.invitationModel.companyName === '' ||
        this.countryError ||
        this.invitationModel.country === '' ||
        this.stateError ||
        this.invitationModel.state === '' ||
        this.cityError ||
        this.invitationModel.city === ''
          ? true
          : false;
    }

    return isError;
  }

  private get confirmDisabled(): boolean {
    return this.allItems.filter(a => a.selected === true).length > 0 ? false : true;
  }

  private changeSelected(item: InviteDropdownModel, state: boolean): void {
    this.allItems.forEach(e => {
      e.selected = false;
    });
    this.allItems.filter(f => f.value === item.value)[0].selected = state;
  }

  private sendResponse(): void {
    const id = this.allItems.filter(a => a.selected === true)[0].value;
    this.valueSelected(id.toString(), this.referenceNumber);
  }
  //TTD-3619 validation for ref input field
  private filterInput(event: Event) {
      const input = event.target as HTMLInputElement;
      const filteredValue = input.value.replace(/[^a-zA-Z0-9.,+/&-]/g, '');
      input.value = filteredValue;
      this.referenceNumber = filteredValue;
  }

  @Emit()
  private valueSelected(value: string, refNum: string): void {
    this.searchText = '';
  }

  @Emit()
  private closeSearch(): void {}

  private async sendInvitationClick(): Promise<void> {
    if (this.sendInvitationDisabled) {
      return;
    }
    // store value to disable multiple invites
    this.inviteSendToContactEmail = this.invitationModel.toContactEmail;
        this.sendInvitationAsync();

    // reset to enable invite again.
    this.inviteSendToContactEmail = '';
  }

  private async sendInvitationAsync(): Promise<void> {
    try {
      this.invitationModel.companyName =
        this.invitationModel.companyName.trim();
      this.invitationModel.companyRegistrationNumber =
        this.invitationModel.companyRegistrationNumber.trim();
      this.invitationModel.toContactEmail =
        this.invitationModel.toContactEmail.trim();
        this.invitationModel.toContactName =
          this.invitationModel.MainContactFirstName.trim()+' '+this.invitationModel.MainContactLastName.trim();
      this.invitationModel.MainContactFirstName =
        this.invitationModel.MainContactFirstName.trim();
      this.invitationModel.MainContactLastName =
        this.invitationModel.MainContactLastName.trim();

      // store value to disable multiple invites
      this.inviteSendToContactEmail = this.invitationModel.toContactEmail.trim();
      // TTD-3618: is-delegate partner key added
      this.invitationModel.isDelegatePartner = this.isDelegate;
      this.invitationModel.invitedPersonEmailID = this.invitationModel.toContactEmail.trim();
      this.isInviting = true;

      const invitationResult = await this.invitationService.sendInvitationAsync(
        this.invitationModel
      );
      this.handleInvitationResult(invitationResult);

      // reset to enable invite again.
      this.inviteSendToContactEmail = '';
    } finally {
      this.isInviting = false;
    }
  }

  private handleInvitationResult(invitationResult: InvitedSupplierResponseModel): void {
    this.result = invitationResult;
    const supplierIsAlreadyInList =
    invitationResult.invitedSupplierResponse!== null ? this.suppliers.findIndex(
              (x) => x.id.toLowerCase() === invitationResult.invitedSupplierResponse.invitedCompanyId
            ) >= 0 : false;
    if (invitationResult.invitedSupplierResponse!== null && supplierIsAlreadyInList) {
      const alertExistingInListText = this.$t(
              'pages.invitation.existingSupplierInCurrentList',
              [
                this.invitationModel.companyRegistrationNumber,
                invitationResult.invitedSupplierResponse.companyName,
              ]
            ).toString();

            this.$alert(alertExistingInListText, '', undefined, {
              confirmButtonText: this.$t('global.modal.ok') as string,
              html: alertExistingInListText,
            });
    } else {
      switch (invitationResult.message) {
          case "BRN_And_Email_Already_Known_With_Same_Company": {
            this.heading = "The user email and company registration number are already known for below company.";
            this.centerText = "Company registration number ‘"+this.invitationModel.companyRegistrationNumber+"’ Company name ‘"+invitationResult.invitedSupplierResponse.companyName+"’ Contact name ‘"+invitationResult.invitedSupplierResponse.mainContact+"’.";
            this.bottomText = "Do you want to invite the user of this company?";
            this.showConfirmModal = true;
            break;
          }
          case "Do_You_Want_To_Add_Second_User": {
              this.heading = "The company registration number is already known.";
              this.centerText = "Company registration number ‘"+this.invitationModel.companyRegistrationNumber+"’ Company name ‘"+invitationResult.invitedSupplierResponse.companyName+"’.";
              this.bottomText = "Do you want to invite ‘"+this.invitationModel.toContactEmail+"’ as new user for ‘"+invitationResult.invitedSupplierResponse.companyName+"’?";
              this.showConfirmModal = true;
              break;
          }
          case "Email_Already_Known_With_Diffrent_BRN": {
              this.heading = "The user email is already known.";
              this.centerText = "This email address is known in the system for a company with a different company registration number.";
              this.bottomText = "Please check the company registration number or contact ‘"+this.invitationModel.toContactEmail+"’.";
              this.showConfirmModal = true;
              break;
          }
          case "BRN_And_Email_Already_Known_With_Diffent_Company": {
              this.heading = "The user email is already known.";
              this.centerText = "This email address is known in the system for a company with a different company registration number.";
              this.bottomText = "Please check the company registration number or contact ‘"+this.invitationModel.toContactEmail+"’.";
              this.showConfirmModal = true;
              break;
          }
          case "BRN_And_Email_Already_Known_With_Same_Company_For_Second_User": {
              this.heading = "The user email and company registration number are already known for the below company.";
              this.centerText = "Company registration number ‘"+this.invitationModel.companyRegistrationNumber+"’ Company name ‘"+invitationResult.invitedSupplierResponse.companyName+"’ Contact name ‘"+invitationResult.invitedSupplierResponse.mainContact+"’";
              this.bottomText = "Do you want to invite the user of this company?";
              this.showConfirmModal = true;
              break;
          }
          case "New_Supplier_Added_Successfully": {
            NotificationHelper.createSucceededNotification(i18n.t('pages.invitation.invite_notification', [this.invitationModel.toContactEmail]).toString());
            this.addNewSupplier();
            this.closeInviteSupplierModal();
            break;
          }
          case "No_Scenario_Match": {
            NotificationHelper.createErrorNotification('We cannot process the invite with provided Email and Business Registration Number. Please contact tex.tracer via the icon in the bottom right corner or send an e-mail to support@tex-tracer.com.');
            break;
          }
          default:
              break;
      }
    }
  }

  private addNewSupplier(): void {
    let suppliersVar = this.$store.getters.suppliers as OrderCompanyModel[];
    let newSupplier: OrderCompanyModel = {
      id: this.result.invitedSupplierResponse.invitedCompanyId,
      companyName: this.result.invitedSupplierResponse.companyName,
      country: this.result.invitedSupplierResponse.country,
      isCompliant: this.result.invitedSupplierResponse.isCompliant,
      onBoarded: this.result.invitedSupplierResponse.onBoarded,
      isTier1: this.result.invitedSupplierResponse.isTier1,
      isAgent: this.result.invitedSupplierResponse.isAgent,
      invitedPersonEmailID: this.result.invitedSupplierResponse.invitedPersonEmailID,
      companyEmail: this.invitationModel.toContactEmail,
      firstName: this.invitationModel.MainContactFirstName,
      lastName: this.invitationModel.MainContactLastName,
      isDelegatePartner: this.result.invitedSupplierResponse.isDelegatePartner
    };
    this.allItems.push({'value': newSupplier.id, 'text': newSupplier.companyName+' (Pending invite)', 'selected': false});
    suppliersVar.push(newSupplier);
    this.$store.commit('setSuppliers', suppliersVar);
  }

  private closeModal(): void{
    this.showConfirmModal = false;
    if (this.result.message==='Email_Already_Known_With_Diffrent_BRN') {
        this.existingEmailError = true;
    } else if(this.result.message==='Do_You_Want_To_Add_Second_User') {
        this.existingRegNoError = true;
    } else {
        this.existingRegNoError = true;
        this.existingEmailError = true;
    }

}

  private async linkSupplierAsync(
  ): Promise<void> {
    if (this.result.message==="Do_You_Want_To_Add_Second_User") {
      this.addSecondUser();
      return;
    }
    this.isLinking = true;

    try {
      if (this.result.message==="BRN_And_Email_Already_Known_With_Same_Company_For_Second_User") {
        this.result.invitedSupplierResponse.invitedPersonEmailID = this.invitationModel.toContactEmail;
      }
      const response = await this.supplierService.linkSupplierAsync(this.result.invitedSupplierResponse);
      if(response.result === "Duplicate_Record_In_InvitedSupplierLedger"){
        const user = store.getters.user as UserModel;
        await this.supplierService.getSuppliersListAsync(user.companyId);
        const alertText = "Another user of your company has already linked the invited supplier.";
        this.$alert(alertText, "", undefined, { confirmButtonText: this.$t('global.modal.cancel') as string, html: alertText });
      }
      else if(response.result === "success"){
        this.addNewSupplier();
        NotificationHelper.createSucceededNotification(
          i18n
            .t('pages.invitation.link_notification', [
              this.result.invitedSupplierResponse.companyName,
            ])
            .toString()
        );
      }
      else{
        NotificationHelper.createErrorNotification(`Failed to add ${this.result.invitedSupplierResponse.companyName} partner to your supply chain partners list.`);
      }
      this.invitationSend();
      this.showConfirmModal = false;
      this.closeInviteSupplierModal();
    } finally {
      this.isLinking = false;
    }
  }

  private getLocation(location: AddressModel): string {
    let loc = location.city+', '+location.country;
    return loc;
  }

  private async addSecondUser(): Promise<void> {
    this.isLinking = true;
    try {
        const user: UserModel = this.$store.getters.user;
        const inviteUser: InvitationModel = {
          toContactEmail: this.invitationModel.toContactEmail,
          toContactName: this.invitationModel.toContactName,
          companyName: this.result.invitedSupplierResponse.companyName,
          companyRegistrationNumber: this.invitationModel.companyRegistrationNumber,
          isClient: this.invitationModel.isClient,
          isValid: this.invitationModel.isValid,
          language: this.invitationModel.language,
          country: this.result.invitedSupplierResponse.country,
          state: this.invitationModel.state,
          city: this.invitationModel.city,
          latitude: this.invitationModel.latitude,
          longitude: this.invitationModel.longitude,
          MainContactFirstName: this.invitationModel.MainContactFirstName,
          MainContactLastName: this.invitationModel.MainContactLastName,
          isDelegatePartner: this.isDelegate,
          invitedPersonEmailID: this.invitationModel.toContactEmail
        }
        const response = await this.invitationService.addSecondUserAsync(inviteUser, user.companyId);
        if(response.message === "RequestAddSecondUser_Success"){
            this.showConfirmModal = false;
            this.addNewSupplier();
            this.closeInviteSupplierModal();
            NotificationHelper.createSucceededNotification('Second user added successfully.');
        } else if(response.message === "Company_Already_Exists_In_InvitedSupplier") {
          const alertExistingInListText = this.$t(
            'pages.invitation.existingSupplierInCurrentList',
            [
              this.invitationModel.companyRegistrationNumber,
              this.result.invitedSupplierResponse.companyName,
            ]
          ).toString();

          this.$alert(alertExistingInListText, '', undefined, {
            confirmButtonText: this.$t('global.modal.ok') as string,
            html: alertExistingInListText,
          });
          this.showConfirmModal = false;
          this.existingRegNoError = true;
        } else {
            NotificationHelper.createErrorNotification('Failed to add second user, please try again.');
        }
    }
    finally {
        this.isLinking = false;
    }
}

  private async getMapboxTokenAsync(): Promise<void> {
    this.mapboxAccessToken = (
      await this.geolocationService.getMapboxToken()
    ).token;
    return;
  }

  private async checkNonRomanChar(variableName:string,val:string){
    if (variableName==="isCompanyNameNonRoman") {
      this.searchBy('name');
    }
    if (variableName==="isRegNumberNonRoman") {
      this.searchBy('registration');
    }
    (this as any)[variableName] = await NonRomanCharCheckHelper.checkNonRomanChar(val);
  }

  private get isCharNonRoman():boolean {
    if(this.isRegNumberNonRoman ||this.isCompanyNameNonRoman ||this.isFirstNameNonRoman ||this.isLastnameNonRoman ||
      this.isEmailNonRoman){
        return true
      }
      return false
  }

  private getCountryNonRomanChar(val: boolean){
    this.isCountryNonRoman = val;
  }

  private getCityNonRomanChar(val: boolean){
    this.isCityNonRoman = val;
  }
  //TTD-3619 set partnername and refNumber
  private setPartnerAndRef(){
    if(this.toCompanyId!=='' && this.refNum!==''){
      const index = this.filteredItems.findIndex((scp) => scp.value === this.toCompanyId);
      if(index!==-1){
        this.filteredItems[index].selected = true;
        this.referenceNumber = this.refNum;
      }
    }
  }

  @Emit()
  private invitationSend(): void {}

  private async validateField(event: any, fieldname: string): Promise<void> {
    const cityDropdown = this.$refs.cityDropdown as any;
    const stateDropdown = this.$refs.stateDropdown as any;
    var specials=/[@()[\];:<>, ]/;

    switch (fieldname) {
      case 'contactEmail': {
        const emailValid = event.target.value
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        this.contactEmailError = emailValid == null ? true : false;
        this.existingEmailError === true ? this.existingEmailError = this.contactEmailError : '';
        break;
      }
      case 'firstName':
        if (event.target.value.length < 2) {
            this.firstNameError = true;
        } else {
            this.firstNameError = false;
        }
        // TTD-4477, for adding validations on firstname
        if (specials.test(event.target.value) && !(event.target.value.length < 2)) {
          this.fNameSpecialCharCheck = true;
        } else {
            this.fNameSpecialCharCheck = false;
        }
        break;
      case 'lastName':
        if (event.target.value.length < 2) {
            this.lastNameError = true;
        } else {
            this.lastNameError = false;
        }
        break;
      case 'companyRegistrationNumber':
        this.companyRegistrationNumberError =
          event.target.value.length < 2 ? true : false;
        this.existingRegNoError === true ? this.existingRegNoError = this.companyRegistrationNumberError : '';
        break;
      case 'companyName':
        this.companyNameError = event.target.value.length < 2 ? true : false;
        break;

      case 'country':
        this.invitationModel.country = '';
        this.countryError =  (event.fieldValue == i18n.t('errors.no_records_found').toString() || event.fieldValue == i18n.t('pages.invitation.loading').toString()) ? true : false;

        this.statesDropdown = [
          { value: '', text: i18n.t('pages.invitation.loading').toString() },
        ];
        stateDropdown.clearValues();
        this.invitationModel.state = '';

        this.citiesDropdown = [];
        cityDropdown.clearValues();
        this.invitationModel.city = '';

        if (!this.countryError) {
          const countriesList = await this.cscService.getAllCountries();
          const country = countriesList.find(
            (x: { iso2: any }) => x.iso2 == event.fieldKey
          );

          if (country !== undefined) {
            this.invitationModel.country = event.fieldKey;
            const states = await this.cscService.getStatesOfCountry(
              this.invitationModel.country
            );
            this.statesDropdown = [];
            if (states !== undefined) {
              states.forEach((state: { iso2: string; name: string }) => {
                this.statesDropdown.push({
                  value: state.iso2,
                  text: state.name,
                });
              });
            }
            if (!states || states.length == 0) {
              this.statesDropdown = [
                {
                  value: '',
                  text: i18n.t('errors.no_records_found').toString(),
                },
              ];
            }
          } else {
            this.statesDropdown = [];
          }
        } else {
          this.statesDropdown = [];
        }
        break;

      case 'state':
        if (!event.fieldKey || event.fieldKey.length == 0) {
          this.stateError =
            event.fieldValue == i18n.t('errors.no_records_found').toString() ||
            event.fieldValue == i18n.t('pages.invitation.loading').toString()
              ? true
              : false;
          return;
        }

        this.citiesDropdown = [
          { value: '', text: i18n.t('pages.invitation.loading').toString() },
        ];
        cityDropdown.clearValues();
        this.invitationModel.city = '';

        this.invitationModel.state = '';
        this.stateError = event.fieldValue.length < 2 ? true : false;

        if (!this.stateError) {
          this.invitationModel.state = event.fieldValue;
          if (event.fieldKey && event.fieldKey.length > 0) {
            const cities = await this.cscService.getCitiesOfState(
              this.invitationModel.country,
              event.fieldKey
            );
            this.citiesDropdown = [];
            if (cities !== undefined) {
              cities.forEach((city: { id: any; name: any }) => {
                this.citiesDropdown.push({ value: city.id, text: city.name });
              });
            }
            const gotCoordinates = await this.geolocationService.getCoordinates(
              this.mapboxAccessToken,
              this.invitationModel.state + ', ' + this.invitationModel.country
            );
            if (gotCoordinates.features.length > 0) {
              this.invitationModel.longitude = parseFloat(
                gotCoordinates.features[0].center[0]
              );
              this.invitationModel.latitude = parseFloat(
                gotCoordinates.features[0].center[1]
              );
            }
          } else {
            this.citiesDropdown = [];
          }
        } else {
          this.citiesDropdown = [];
        }
        break;

      case 'city':
        this.invitationModel.city = '';
        this.cityError =
          event.fieldValue.length < 2 ||
          event.fieldValue == i18n.t('errors.no_records_found').toString()
            ? true
            : false;
        if (!this.cityError) {
          this.invitationModel.city = event.fieldValue;
          const gotCoordinates = await this.geolocationService.getCoordinates(
            this.mapboxAccessToken,
            this.invitationModel.city.split(' ')[0] +
              ', ' +
              this.invitationModel.state +
              ', ' +
              this.invitationModel.country
          );
          if (gotCoordinates.features.length > 0) {
            this.invitationModel.longitude = parseFloat(
              gotCoordinates.features[0].center[0]
            );
            this.invitationModel.latitude = parseFloat(
              gotCoordinates.features[0].center[1]
            );
          }
        }

        break;
      case 'partnerType':
        this.partnerTypeError = event.target.value.length < 2 ? true : false;
        break;
      default:
        break;
    }
  }
}
