import { SupplyChainApiAuthenticator } from '@/authenticators/supplyChainApiAuthenticator';
import { ApplicationConfig } from '@/config';
import { BaseClient } from './baseClient';

export class UploadEvidence extends BaseClient {
  private readonly endPoint: string = 'api';

  public constructor() {
    super(
      ApplicationConfig.endpoints.dashboardApi,
      new SupplyChainApiAuthenticator()
    );
  }

  public async postEvidencePhotos(
    orderId: string,
    orderLineId: string,
    orderLineStepId: string,
    data: object
  ): Promise<Response> {
    return await this.postWithResponse(
      `${this.endPoint}/order/${orderId}/line/${orderLineId}/step/${orderLineStepId}/evidence`,
      data
    );
  }
}
