import { CertificateUploadModel } from '@/models/certificateUploadModel';
import i18n from '@/i18n';
import { ComplianceCertificatesClient } from '@/clients/complianceCertificatesClient';
import { CertificateModel } from '@/models/certificateModel';
import { NotificationHelper } from '@/helpers/notificationHelper';
// import { OrderCompanyRespModel } from "@/models/delegateCompanyModel"
import { UploadTCResponsepModel } from "@/models/transcationCertificateModal"
import { ArchiveResponse } from "@/models/certificateModel";

export class ComplianceCertificatesService {
  private readonly client: ComplianceCertificatesClient =
    new ComplianceCertificatesClient();

  public async getSocialCertificatesAsync(
    companyId: string
  ): Promise<CertificateModel[]> {
    const result = await this.client.getSocialCertificatesAsync(companyId);
    if (result.success) {
      return result.socialPdf;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('global.notifications.fetch_cert_social_failed').toString()
      );
      return [];
    }
  }

  public async getSustainableCertificatesAsync(
    companyId: string
  ): Promise<CertificateModel[]> {
    const result = await this.client.getSustainableCertificatesAsync(companyId);
    if (result.success) {
      return result.sustainablePdf;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('global.notifications.fetch_cert_sustainable_failed').toString()
      );
      return [];
    }
  }

  public async uploadSocialCertificatesAsync(
    companyId: string,
    uploadData: FormData
  ): Promise<Response> {
    return await this.client.uploadSocialCertificatesAsync(
      companyId,
      uploadData
    );
  }

  public async uploadSustainableCertificatesAsync(
    companyId: string,
    uploadData: FormData
  ): Promise<Response> {
    return await this.client.uploadSustainableCertificatesAsync(
      companyId,
      uploadData
    );
  }

  public async uploadTC(
    uploadData: FormData
  ): Promise<UploadTCResponsepModel> {
    return await this.client.uploadTCAsync(
      uploadData
    );
  }
  
  public async archiveCertificate(
    companyId: string,
    updateData: any
  ): Promise<ArchiveResponse> {
    return await this.client.archiveCertificate(companyId,
      updateData
    );
  }
}

