import moment from 'moment';

export class FileModel {
  public constructor(
    public id: string,
    public fileName: string,
    public documentType: string,
    public content: string,
    public createdAt: moment.Moment,
    public geoLat: number,
    public geoLong: number,
    public isMainPicture: boolean = false
  ) {}
}
