import { calendarFormat } from "moment";
import { Guid } from "./guid";
import { TransportFootprintModel } from "./transportFootprintModel";
import { DropdownModel } from "./dropdownModel";

export class WetProcessFootprintRequestModel {
    public footprintDeadline!: string;
    public footprintFilledByCompanyID!: string;
    public wetprocess!:WetprocessModel;
}

export class WetProcessDataModel {
    public footprintStatus!: String;
    public wetprocessFootprint!: WetProcessFootprintModel[];
    public transportStatus!: string;
    public transport!: TransportFootprintModel[];
}

export class WetProcessFootprintModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.toCompanyUser = {
            toCompanyID: Guid.Empty,
            toCompanyName: '',
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.treatmentType = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty
        };
        this.yarnWetprocess = [];
        this.fabricWetprocess = [];

        // this.dyiengTreatment = [];
        // this.printTreatment = [];
        // this.preTreatment = [];
        // this.coatingFinishing = [];
        this.showWetProcess = false;
        this.isPrefilledPartner = false;
        this.isWetProcessFieldEmpty = false;
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
        this.stepID = "";
        this.footprintStatus = "";
        this.supplierName = "";
        this.referenceNo = "";
        this.footprintRefNoPrefix = "";
        this.status = "";
        this.showWrapper = false;
    }
    public ID!: string;
    public toCompanyUser!: ToCompanyUserModel;
    public treatmentType!: TreatmentTypeModel;
    public yarnWetprocess!: YarnWetProcessModel[];
    public fabricWetprocess!: FabricWetprocessModel[];
    public footprintStatus!: String;
    public isWetProcessFieldEmpty!: boolean;
    //TTD-2761 updating modal as per new requirement
    // public dyiengTreatment!: DyingTreatmentModel[];
    // public printTreatment!: PrintTreatmentModel[];
    // public preTreatment!: PreTreatmentModel[];
    // public coatingFinishing!: CoatingOrFinishingModel[];
    public showWetProcess!: boolean;
    public isPrefilledPartner!: boolean;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public stepID!: string;
    public supplierName!: string;
    public referenceNo!: string;
    public footprintRefNoPrefix?: string;
    public status = "";
    public showWrapper!: boolean;
}

export class DyingTreatmentModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.toCompanyUser = {
            toCompanyID: Guid.Empty,
            toCompanyName: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true,
            isUnique: true
        };
        this.dyeType = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.shade = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.process = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.waterUsedInFabric = {
            amount: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.totalElectricEnergy = {
            value: 0,
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.windEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.solarEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergySource = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.energyUsed = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergyNew = [new ThermalEnergyModel()];
        this.thermalEnergySourceNew = [new ThermalEnergyModel()];
        this.provideEnergyFlag = true;
        this.energySupportingDocument = [];
        this.msdsDoc = [];
        this.showWetDyeing = false;
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
    }
    public ID!: string;
    public toCompanyUser!: ToCompanyUserModel;
    public reference!: ReferenceModel;
    public dyeType!: DyeTypeModel;
    public shade!: ShadeModel;
    public process!: ProcessModel;
    public waterUsedInFabric!: WaterUsedInFabricModel;
    public msdsDoc!: DocumentModel[];
    public provideEnergyFlag!: boolean;
    public totalElectricEnergy!: TotalElectricEnergyModel;
    public windEnergy!: windEnergyModel;
    public solarEnergy!: SolarEnergyModel;
    public thermalEnergy!: ThermalEnergyModel;
    public energyUsed!: ThermalEnergyModel;
    public thermalEnergySource!: ThermalEnergyModel;
    public energySupportingDocument!: DocumentModel[];
    public showWetDyeing!: boolean;
    public thermalEnergyNew!: ThermalEnergyModel[];
    public thermalEnergySourceNew!: ThermalEnergyModel[];
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

export class PrintTreatmentModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.toCompanyUser = {
            toCompanyID: Guid.Empty,
            toCompanyName: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true,
            isUnique: true
        };
        this.printType = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.shade = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.process = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.waterUsedInFabric = {
            amount: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.totalElectricEnergy = {
            value: 0,
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.windEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.solarEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergySource = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergyNew = [new ThermalEnergyModel()];
        this.thermalEnergySourceNew = [new ThermalEnergyModel()];
        this.energyUsed = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.provideEnergyFlag = true;
        this.energySupportingDocument = [];
        this.msdsDoc = [];
        this.showWetPrint = false;
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
    }
    public ID!: string;
    public toCompanyUser!: ToCompanyUserModel;
    public reference!: ReferenceModel;
    public printType!: DyeTypeModel;
    public shade!: ShadeModel;
    public process!: ProcessModel;
    public waterUsedInFabric!: WaterUsedInFabricModel;
    public msdsDoc!: DocumentModel[];
    public provideEnergyFlag!: boolean;
    public totalElectricEnergy!: TotalElectricEnergyModel;
    public windEnergy!: windEnergyModel;
    public solarEnergy!: SolarEnergyModel;
    public thermalEnergy!: ThermalEnergyModel;
    public energyUsed!: ThermalEnergyModel;
    public thermalEnergySource!: ThermalEnergyModel;
    public thermalEnergyNew!: ThermalEnergyModel[];
    public thermalEnergySourceNew!: ThermalEnergyModel[];
    public energySupportingDocument!: DocumentModel[];
    public showWetPrint!: boolean;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

export class PreTreatmentModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.toCompanyUser = {
            toCompanyID: Guid.Empty,
            toCompanyName: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true,
            isUnique: true
        };
        this.process = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.waterUsedInFabric = {
            amount: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.totalElectricEnergy = {
            value: 0,
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.windEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.solarEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergySource = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergyNew = [new ThermalEnergyModel()];
        this.thermalEnergySourceNew = [new ThermalEnergyModel()];
        this.energyUsed = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.provideEnergyFlag = true;
        this.energySupportingDocument = [];
        this.msdsDoc = [];
        this.showWetPre = false;
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
    }
    public ID!: string;
    public toCompanyUser!: ToCompanyUserModel;
    public reference!: ReferenceModel;
    public process!: ProcessModel;
    public waterUsedInFabric!: WaterUsedInFabricModel;
    public msdsDoc!: DocumentModel[];
    public provideEnergyFlag!: boolean;
    public totalElectricEnergy!: TotalElectricEnergyModel;
    public windEnergy!: windEnergyModel;
    public solarEnergy!: SolarEnergyModel;
    public thermalEnergy!: ThermalEnergyModel;
    public energyUsed!: ThermalEnergyModel;
    public thermalEnergySource!: ThermalEnergyModel;
    public thermalEnergyNew!: ThermalEnergyModel[];
    public thermalEnergySourceNew!: ThermalEnergyModel[];
    public energySupportingDocument!: DocumentModel[];
    public showWetPre!: boolean;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

export class CoatingOrFinishingModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.toCompanyUser = {
            toCompanyID: Guid.Empty,
            toCompanyName: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true,
            isUnique: true
        };
        this.coatingOrFinishing = {
            type: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.waterUsedInFabric = {
            amount: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.totalElectricEnergy = {
            value: 0,
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.windEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.solarEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergySource = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergy = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.thermalEnergyNew = [new ThermalEnergyModel()];
        this.thermalEnergySourceNew = [new ThermalEnergyModel()];
        this.energyUsed = {
            value: "",
            filledByPrimarySource: false,
            filledByCompanyID: Guid.Empty,
            isValid: true
        };
        this.provideEnergyFlag = true;
        this.energySupportingDocument = [];
        this.msdsDoc = [];
        this.showWetCoating = false;
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
    }
    public ID!: string;
    public toCompanyUser!: ToCompanyUserModel;
    public reference!: ReferenceModel;
    public coatingOrFinishing!: DyeTypeModel;
    public waterUsedInFabric!: WaterUsedInFabricModel;
    public msdsDoc!: DocumentModel[];
    public provideEnergyFlag!: boolean;
    public totalElectricEnergy!: TotalElectricEnergyModel;
    public windEnergy!: windEnergyModel;
    public solarEnergy!: SolarEnergyModel;
    public thermalEnergy!: ThermalEnergyModel;
    public energyUsed!: ThermalEnergyModel;
    public thermalEnergySource!: ThermalEnergyModel;
    public thermalEnergyNew!: ThermalEnergyModel[];
    public thermalEnergySourceNew!: ThermalEnergyModel[];
    public energySupportingDocument!: DocumentModel[];
    public showWetCoating!: boolean;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

export class ToCompanyUserModel{
    public constructor(){
        this.toCompanyID = Guid.Empty;
        this.toCompanyName = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = false;
    }
    public toCompanyID!: string;
    public toCompanyName!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class ReferenceModel{
    public constructor(){
        this.referenceNo = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = false;
        this.isUnique = true;
    }
    public referenceNo!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
    public isUnique!: boolean;
}

export class DyeTypeModel{
    public constructor(){
        this.type = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = false;
    }
    public type!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class ShadeModel{
    public constructor(){
        this.type = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = false;
    }
    public type!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class ProcessModel{
    public constructor(){
        this.type = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = false;
    }
    public type!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class WaterUsedInFabricModel{
    public constructor(){
        this.amount = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = false;
    }
    public amount!: string|number;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class TotalElectricEnergyModel{
    public constructor(){
        this.value = 0;
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = false;
    }
    public value!: number;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class windEnergyModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = false;
    }
    public value!: string|number;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class SolarEnergyModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = false;
    }
    public value!: string|number;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class ThermalEnergyModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
        this.isValid = true;
    }
    public value!: string|number;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class DocumentModel{
    public ID!: string;
    public createdAt!: string;
    public createdBy!: string;
    public docUrl!: string;
    public fileHash!: string;
    public fileName!: string;
    public fileType!: string;
    public geoLat!: number;
    public geoLong!: number;
    public referenceNo!: string;
    public rejectionReason!: string;
    public status!: number;
    public isValid!: boolean;
}
//TTD-2761 updating modal as per new requirement
export class TreatmentTypeModel{
    public constructor(){
        this.value = "";
        this.filledByPrimarySource = false;
        this.filledByCompanyID = Guid.Empty;
    }
    public value!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
}

export class YarnWetProcessModel{
    public constructor(){
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true,
            isUnique: true
        };
        this.ID = Guid.Empty;
        this.dyiengTreatment = [];
        this.preTreatment = [];
        this.coatingFinishing = [];
        this.filledByCompanyID = Guid.Empty,
        this.filledByPrimarySource = false,
        this.yarnFootprintID = Guid.Empty,
        this.showYarn = false;
        // this.stepID = "";
        this.status = "";
    }
    public ID!: string;
    public reference!: ReferenceModel;
    public dyiengTreatment!: DyingTreatmentModel[];
    public preTreatment!: PreTreatmentModel[];
    public coatingFinishing!: CoatingOrFinishingModel[];
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public yarnFootprintID!: string;
    public showYarn!: boolean;
    // public stepID!: string;
    public status!: string;
}

export class FabricWetprocessModel{
    public constructor(){
        this.reference = {
            referenceNo: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true,
            isUnique: true
        };
        this.ID = Guid.Empty;
        this.dyiengTreatment = [];
        this.preTreatment = [];
        this.printTreatment = [];
        this.coatingFinishing = [];
        this.showFabric =  false;
        this.filledByCompanyID = Guid.Empty,
        this.filledByPrimarySource = false,
        this.fabricFootprintID = Guid.Empty
        // this.stepID = "";
    }
    public ID!: string;
    public reference!: ReferenceModel;
    public dyiengTreatment!: DyingTreatmentModel[];
    public preTreatment!: PreTreatmentModel[];
    public printTreatment!: PrintTreatmentModel[];
    public coatingFinishing!: CoatingOrFinishingModel[];
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public fabricFootprintID!: string;
    public showFabric!: boolean;
    // public stepID!: string;
}

export class WetprocessModel {
    public footprintStatus!: String;
    public transportStatus!: String;
    public dyiengTreatment!: DyingTreatmentModel[];
    public printTreatment!: PrintTreatmentModel[];
    public preTreatment!: PreTreatmentModel[];
    public coatingFinishing!: CoatingOrFinishingModel[];
    public transport!: TransportFootprintModel[];
    public wetprocessFootprint!: WetProcessFootprintModel[];
}

export class WetProcessStepsDataModel {
    public stepID!: string;
    public prefixReferenceNo!: string;
    public toCompanyID!: string;
    public toCompanyName!: string;
    public showWetProcess!: boolean;
    public yarnRefList!: DropdownModel[];
    public fabricRefList!: DropdownModel[];
}

