import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { OrderLineStepModel } from './orderLineStepModel';

export class OrdersWithStepsModal {
  public bookmark!: string;
  public count!: number;
  public orders!: OrderLineDisplayModel[];
  public orderLineSteps!: OrderLineStepModel[];
  public totalStyles!: number;
}

export class OrdersWithCountModel {
  public success!: boolean;
  public bookmark!: string;
  public count!: number;
  public orders!: OrderLineDisplayModel[];
  public orderLineSteps!: OrderLineStepModel[];
  public totalStyles!: number;
}
