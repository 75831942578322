export enum StepState {
  NONE = 0,
  CREATED_BY_CLIENT = 1,
  UPDATED_BY_CLIENT = 2,
  DELETED_BY_CLIENT = 3,
  CREATED_BY_SUPPLIER = 4,
  UPDATED_BY_SUPPLIER = 5,
  DELETED_BY_SUPPLIER = 6,
  CREATED_BY_AGENT = 7,
  UPDATED_BY_AGENT = 8,
  DELETED_BY_AGENT = 9,
  CREATED_BY_DELEGATEPARTNER = 10,
  UPDATED_BY_DELEGATEPARTNER = 11,
  DELETED_BY_DELEGATEPARTNER = 12,
}
