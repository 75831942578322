import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
// import samplePdf from '@/views/compliance/components/empty-sample-pdf.pdf'

import VuePdfApp from 'vue-pdf-app';

import moment from 'moment';
import { componentsPlugin } from 'bootstrap-vue';

// Helper
import { NotificationHelper } from '@/helpers/notificationHelper';
import { DropdownModel } from '@/models/dropdownModel';
import { CertificateBodyList } from "@/helpers/certificationBodyHelper";

// Services
import { ComplianceCertificatesService } from '@/services/complianceCertificatesService';
import { NonRomanCharCheckHelper }from '@/helpers/nonRomanCharCheckHelper';

// Models
import { CertificateUploadModel } from '@/models/certificateUploadModel';
import { UserModel } from '@/models/userModel';
import { ThrottlingUtils } from '@azure/msal-common';
import { CertificateTypes, CertificateOnTypeModel } from '@/models/certificateModel'

import DropDownInput from '@/components/general/drop-down-input/dropDownInput.vue';
import { isObject } from 'lodash';

@Component({
  components: { VuePdfApp, DropDownInput },
})
export default class UploadCertificates extends Vue {
  private tabIndex: number = 0;
  private otherCertName: string = '';
  private currentView: string = '';
  private isUploading: boolean = false;
  private certificateType: string = '';
  private certificatesCount: number = 1;
  private isNonRomanChar: boolean = false;
  private isCompanyNonRoman: boolean = false;
  private isReferenceNonRoman: boolean = false;
  private isRspNonRoman: boolean = false;
  private isAuditNonRoman: boolean = false;
  private isAmforNonRoman: boolean = false;
  private isAmforSiteIdNonRoman: boolean = false;
  private isOekoNonRoman: boolean = false;
  private searchText: string = '';
  private auditCertificates: CertificateOnTypeModel[] = [];

  private filteredData: CertificateOnTypeModel[] = [];

  private pdfDocument: any = {};

  private resetField: boolean = false;

  private certificateTypename: string = '';

  private cerificateType: string = '';

  private ledgerType: string = ''

  private uploadCertificate: any = {
    files: [],
    description: '',
    geoLat: 0,
    geoLong: 0,
    issuer: '',
    referenceissuer: '',
    validtoDate: '',
    validfromDate: '',
    type: 0,
    rspholder: '',
    auditresult: '',
    amforiid: '',
    amforisiteid: '',
    oekoclass: '',
    certificationBody: '',
    membershipID: '',
    certificationNumber: '',
    licenseID: '',
    femScore: '',
    fslmScore: '',
    updatedOn: '',
    issuanceDate: ''
  };
  private isUploadError: any = {
    files: -1,
    description: -1,
    geoLat: -1,
    geoLong: -1,
    issuer: -1,
    referenceissuer: -1,
    validtoDate: -1,
    validfromDate: -1,
    rspholder: -1,
    auditresult: -1,
    amforiid: -1,
    amforisiteid: -1,
    oekoclass: -1,
    certificationBody: -1,
    certificationNumber: -1,
    licenseID: -1,
    femScore: -1,
    fslmScore: -1,
    membershipID: -1,
    updatedOn: -1,
    issuanceDate: -1
  };

  private certificateUploadModel: CertificateUploadModel =
    new CertificateUploadModel();

  private certificateService: ComplianceCertificatesService;

  public constructor() {
    super();
    this.certificateService = new ComplianceCertificatesService();
  }

  private created(): void{
    const socialAudit = i18n.t(`enums.social_certificates.audit`) as any;
    for (const key of Object.keys(socialAudit)) {
        const certifcateName = socialAudit[key];
        this.auditCertificates.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SOCIAL));
    }
    this.auditCertificates = this.auditCertificates.sort((a: any, b: any) => {
      return a.Name.localeCompare(b.Name);
    });
  }

  private get disableUpload(): Boolean {
    let disable: boolean = false;
    disable = this.isUploadError.files !== 0 ? true : disable;
    disable = this.isUploadError.description !== 0 ? true : disable;
    disable = this.isUploadError.geoLat !== 0 ? true : disable;
    disable = this.isUploadError.geoLong !== 0 ? true : disable;
    disable = this.isUploadError.certificationBody !== 0 ? true : disable;
    disable = this.isUploadError.certificationNumber !== 0 ? true : disable;
    disable = this.isUploadError.validtoDate !== 0 ? true : disable;
    disable = this.isUploadError.validfromDate !== 0 ? true : disable;
    if(this.uploadCertificate.type === 2){
      disable = this.isUploadError.rspholder !== 0 ? true : disable;
      disable = this.isUploadError.auditresult !== 0 ? true : disable;
      disable = this.isUploadError.amforisiteid !== 0 ? true : disable;
    }
    if(this.uploadCertificate.type !== 2){
      disable = this.isUploadError.amforiid !== 0 ? true : disable;
    }
    disable = this.isUploadError.oekoclass !== 0 ? true : disable;
    disable = this.isUploadError.licenseID !== 0 ? true : disable;
    if(this.uploadCertificate.type === 12){
      disable = this.isUploadError.fslmScore !== 0 ? true : disable;
    }
    return disable;
  }

  private async checkAllFieldsForErrors(): Promise<void> {
    await this.checkForUploadErrors('files');
    this.checkForUploadErrors('validfromDate');
    this.checkForUploadErrors('validtoDate');
    this.checkForUploadErrors('certificationbody');
    this.checkForUploadErrors('certificationNumber');
    this.checkForUploadErrors('licenseID');
    this.checkForUploadErrors('fslmScore');
    this.checkForUploadErrors('rspholder');
    this.checkForUploadErrors('auditresult');
    this.checkForUploadErrors('amforiid');
    this.checkForUploadErrors('amforisiteid');
    this.checkForUploadErrors('audit');
  }

  private gotoTop(): void {
    var element = document.getElementById('scrolldiv') as HTMLElement;
    element.scrollTop = 0;
  }

  private gotoBottom(): void {
    var element = document.getElementById('scrolldiv') as HTMLElement;
    element.scrollTop = element.scrollHeight * 0.9;
  }

  private async checkForUploadErrors(field: string): Promise<void> {
    const maxSize = 1024 * 1024 * 5;
    if (this.currentView == '') {
      return;
    }
    switch (field) {
      case 'files':
        if(this.tabIndex!==3){
          if(this.currentView === 'upload'){
            this.isUploadError.files = -1;
          }else{
            this.isUploadError.files = 1;
          }

          if (maxSize < this.uploadCertificate.files.size) {
            NotificationHelper.createErrorNotification(
              i18n.t('errors.certificate_upload_failed').toString()
            );
            return;
          }

          if (this.uploadCertificate.files.type.includes('pdf')) {
            this.pdfDocument = this.uploadCertificate.files.file;
            this.isUploadError.files = 0;
            if (navigator.geolocation) {
              await navigator.geolocation.getCurrentPosition(async (position) => {
                this.uploadCertificate.geoLat = position.coords.latitude;
                this.uploadCertificate.geoLong = position.coords.longitude;
                this.isUploadError.geoLat = 0;
                this.isUploadError.geoLong = 0;
              });
              if (this.currentView == 'viewpdf') {
                this.pdfDocument =
                  await this.uploadCertificate.files.arrayBuffer();
              }
            }
          }
        }else{
          this.isUploadError.files = 0;
        }

        break;
      case 'description':
        this.isUploadError.description = 1;
        if (this.uploadCertificate.description.length.trim() !== '') {
          this.isUploadError.description = 0;
        }
        break;
      case 'geoLat':
        this.isUploadError.geoLat = 1;
        if (this.uploadCertificate.geoLat !== 0) {
          this.isUploadError.geoLat = 0;
        }
        break;
      case 'geoLong':
        this.isUploadError.geoLong = 1;
        if (this.uploadCertificate.geoLong !== 0) {
          this.isUploadError.geoLong = 0;
        }
        break;
      case 'referenceissuer':
        this.isUploadError.referenceissuer = 1;
        if (this.uploadCertificate.referenceissuer.trim() !== '') {
          this.isUploadError.certificationNumber = 0;
        }
        break;
      case 'rspholder':
        if (this.certificateType.toLowerCase().includes('amfori')) {
          this.isUploadError.rspholder = 1;
          if (this.uploadCertificate.rspholder.trim() !== '') {
            this.isUploadError.rspholder = 0;
          }
        }
        break;
      case 'auditresult':
        if (this.certificateType.toLowerCase().includes('amfori') || this.uploadCertificate.type === 13) {
          this.isUploadError.auditresult = 1;
          if (this.uploadCertificate.auditresult.trim() !== '') {
            this.isUploadError.auditresult = 0;
          }
        }
        break;
      case 'amforiid':
        if (this.certificateType.toLowerCase().includes('amfori')) {
          this.isUploadError.amforiid = 1;
          if (this.uploadCertificate.amforiid.trim() !== '') {
            this.isUploadError.amforiid = 0;
          }
        }
        break;
      case 'amforisiteid':
        if (this.certificateType.toLowerCase().includes('amfori')) {
          this.isUploadError.amforisiteid = 1;
          if (this.uploadCertificate.amforisiteid.trim() !== '') {
            this.isUploadError.amforisiteid = 0;
          }
        }
        break;
      case 'oekoclass':
        if (this.certificateType.toLowerCase().includes('amfori')) {
          this.isUploadError.oekoclass = 1;
          if (this.uploadCertificate.oekoclass.trim() !== '') {
            this.isUploadError.oekoclass = 0;
          }
        }
        break;
      case 'certificationBody':
        this.isUploadError.certificationBody = 1;
        if (this.uploadCertificate.certificationBody.trim() !== '') {
          this.isUploadError.certificationBody = 0;
        }
        break;
      case 'certificationNumber':
      this.isUploadError.certificationNumber = 1;
      if (this.uploadCertificate.certificationNumber.trim() !== '') {
        this.isUploadError.certificationNumber = 0;
      }
      break;
      case 'licenseID':
        this.isUploadError.licenseID = 1;
        if (this.uploadCertificate.licenseID.trim() !== '') {
           this.isUploadError.licenseID = 0;
        }
        break;
      case 'fslmScore':
      this.isUploadError.fslmScore = 1;
      if (this.uploadCertificate.fslmScore.trim() !== '') {
          this.isUploadError.fslmScore = 0;
        }
        break;
      case 'audit':
      this.isUploadError.auditresult = 1;
      if (this.uploadCertificate.auditresult.trim() !== '') {
          this.isUploadError.auditresult = 0;
        }
        break;
      case 'membershipID':
        this.isUploadError.membershipID = 1;
        if (this.uploadCertificate.membershipID.trim() !== '') {
            this.isUploadError.membershipID = 0;
          }
          break;
      case 'validfromDate':
        this.isUploadError.validfromDate = 1;
        if (
          this.uploadCertificate.validfromDate !== null &&
          this.uploadCertificate.validfromDate !== ''
        ) {
          this.uploadCertificate.validfromDate = new Date(
            this.uploadCertificate.validfromDate
          ).toJSON();
          this.isUploadError.validfromDate = 0;
          if (this.uploadCertificate.validtoDate !== null) {
            const validTo = new Date(
              this.uploadCertificate.validtoDate
            ).getTime();
            const validFrom = new Date(
              this.uploadCertificate.validfromDate
            ).getTime();
            if (validFrom > validTo) {
              this.isUploadError.validfromDate = 1;
            }
            if(validFrom <= validTo){
              this.isUploadError.validtoDate = 0;
            }
          }
        }
        break;
      case 'validtoDate':
        this.isUploadError.validtoDate = 1;
        if (
          this.uploadCertificate.validtoDate !== null &&
          this.uploadCertificate.validtoDate !== ''
        ) {
          this.uploadCertificate.validtoDate = new Date(
            this.uploadCertificate.validtoDate
          ).toJSON();
          this.isUploadError.validtoDate = 0;
          if (this.uploadCertificate.validfromDate !== null) {
            const validTo = new Date(
              this.uploadCertificate.validtoDate
            ).getTime();
            const validFrom = new Date(
              this.uploadCertificate.validfromDate
            ).getTime();
            if (validFrom > validTo) {
              this.isUploadError.validtoDate = 1;
            }
          }
        }
        break;
      case 'updatedOn':
        this.isUploadError.updatedOn = 1;
        if (
          this.uploadCertificate.updatedOn !== null &&
          this.uploadCertificate.updatedOn !== ''
        ) {
          this.uploadCertificate.updatedOn = new Date(
            this.uploadCertificate.updatedOn
          ).toJSON();
          this.isUploadError.updatedOn = 0;
        }
        break;
        case 'issuanceDate':
          this.isUploadError.issuanceDate = 1;
          if (
            this.uploadCertificate.issuanceDate !== null &&
            this.uploadCertificate.issuanceDate !== ''
          ) {
            this.uploadCertificate.issuanceDate = new Date(
              this.uploadCertificate.issuanceDate
            ).toJSON();
            this.isUploadError.issuanceDate = 0;
          }
          break;
    }
  }

  private async setIssuer(
    index: number,
    issuer: string,
    lCheck: boolean = true,
    isClick: boolean,
    certificateName: string,
    type: string,
    relatedType: string
  ): Promise<void> {
    this.resetField = false;
    this.resetUploadFields();
    index = certificateName == '' ? 0 : index;
    if (lCheck && this.uploadCertificate.type == index) {
      this.uploadCertificate.type = 0;
      this.certificateType = '';
    } else {
      this.uploadCertificate.type = index;
      this.certificateType = certificateName;
    }
    if (issuer !== this.otherCertName) {
      this.otherCertName = '';
    }
    if(this.otherCertName === '' && isClick){
      this.uploadCertificate.type = parseInt(issuer);
      this.certificateTypename = certificateName;
      this.cerificateType = type;
      this.ledgerType = relatedType;
      this.switchView('upload')
    }
    this.searchText = '';
    this.filteredData = [];
  }

  private resetUploadFields(): void {
    this.uploadCertificate = {
      files: [],
      description: '',
      geoLat: 0,
      geoLong: 0,
      issuer: '',
      referenceissuer: '',
      validtoDate: '',
      validfromDate: '',
      type: 0,
      rspholder: '',
      auditresult: '',
      amforiid: '',
      amforisiteid: '',
      oekoclass: '',
      femScore: '',
      fslmScore: '',
      certificationBody: '',
      certificationNumber: '',
      licenseID: '',
      audit: '',
      membershipID: '',
      updatedOn: '',
      issuanceDate: ''
    };
    this.resetIsUploadError();
  }

  private resetIsUploadError(): void {
    this.isUploadError = {
      files: -1,
      description: -1,
      geoLat: -1,
      geoLong: -1,
      issuer: -1,
      referenceissuer: -1,
      validtoDate: -1,
      validfromDate: -1,
      rspholder: -1,
      auditresult: -1,
      amforiid: -1,
      amforisiteid: -1,
      oekoclass: -1,
      certificationBody: -1,
      certificationNumber: -1,
      licenseID: -1,
      femScore: -1,
      fslmScore: -1,
      membershipID: -1,
      updatedOn: -1,
      issuanceDate: -1
    };
    this.isUploadError.geoLat = 0;
    this.isUploadError.geoLong = 0;
    this.isUploadError.description = 0;
    this.isUploadError.oekoclass = this.certificateType
      .toLowerCase()
      .includes('oeko')
      ? -1
      : 0;
    this.isUploadError.amforiid = this.certificateType
      .toLowerCase()
      .includes('amfori')
      ? -1
      : 0;
    this.isUploadError.rspholder = this.certificateType
      .toLowerCase()
      .includes('amfori')
      ? -1
      : 0;
    this.isUploadError.auditresult = this.certificateType
      .toLowerCase()
      .includes('amfori')
      ? -1
      : 0;
    this.isUploadError.amforisiteid = this.certificateType
      .toLowerCase()
      .includes('amfori')
      ? -1
      : 0;
  }

  private get auditOptions(): DropdownModel[] {
    const certificates = i18n.t(`enums.audit_result`) as any;
    const result: DropdownModel[] = [];
    for (const key of Object.keys(certificates)) {
        const certifcateName = certificates[key];
        result.push({ value: key, text: certifcateName });
    }
    return result;
  }

  private async goBack(): Promise<void> {
    switch (this.currentView) {
      case 'viewpdf':
        this.switchView('upload');
        this.checkAllFieldsForErrors();
        break;

      case 'upload':
        this.switchView('');
        break;

      default:
        this.closeModal();
        break;
    }
  }

  private async switchView(view: string): Promise<void> {
      this.isNonRomanChar = false;
      this.isCompanyNonRoman = false;
      this.isReferenceNonRoman = false;
      this.isRspNonRoman = false;
      this.isAuditNonRoman = false;
      this.isAmforNonRoman = false;
      this.isAmforSiteIdNonRoman = false;
      this.isOekoNonRoman = false;
    if(view === ''){
      this.resetUploadFields();
    }
    if (view == 'viewpdf' && this.disableUpload) {
      return;
    }

    if (view == 'viewpdf' && this.uploadCertificate.files) {
      this.gotoTop();
      await this.checkAllFieldsForErrors();
      if (this.disableUpload) {
        return;
      }
      setTimeout(
        async (app: any) => {
          app.pdfDocument = await app.uploadCertificate.files.arrayBuffer();
        },
        250,
        this
      );
    }
    if (view == 'upload' && !this.disableUpload) {
      //
    }
    this.currentView = view;
  }

  private async pdfReady(pdfApp: any): Promise<void> {
    const pdfZoom = 'page-fit';
    await pdfApp.pdfViewer.pagesPromise;
    pdfApp.pdfViewer.currentScaleValue = pdfZoom;
  }

  private async uploadCertificateService(): Promise<void> {
    await this.checkAllFieldsForErrors();
    if (this.disableUpload) {
      return;
    }

    this.isUploading = true;

    const user = this.$store.getters.user as UserModel;

    const certificatFormData = new FormData();
    certificatFormData.append('upload', this.uploadCertificate.files);

    certificatFormData.append(
      'description',
      this.uploadCertificate.description
    );
    certificatFormData.append(
      'geoLat',
      this.uploadCertificate.geoLat.toString()
    );
    certificatFormData.append(
      'geoLong',
      this.uploadCertificate.geoLong.toString()
    );
    certificatFormData.append('issuer', this.uploadCertificate.certificationBody);
    certificatFormData.append(
      'referenceIssuer',
      this.uploadCertificate.certificationNumber
    );

    if (this.certificateType.toLowerCase().includes('amfori') || this.uploadCertificate.type === 13) {
      certificatFormData.append('rspHolder', this.uploadCertificate.rspholder);
      certificatFormData.append(
        'auditResult',
        this.uploadCertificate.auditresult
      );

      certificatFormData.append('amforiID', this.uploadCertificate.amforiid);
      certificatFormData.append(
        'siteAmforiID',
        this.uploadCertificate.amforisiteid
      );
    }

    if (this.certificateType.toLowerCase().includes('oeko')) {
      certificatFormData.append('oekoClass', this.uploadCertificate.oekoclass);
    }

    certificatFormData.append(
      'validFromDate',
      this.uploadCertificate.validfromDate
    );
    certificatFormData.append(
      'validToDate',
      this.uploadCertificate.validtoDate
    );
    certificatFormData.append('type', this.uploadCertificate.type.toString());

    certificatFormData.append('licenseID', this.uploadCertificate.licenseID.toString());
    certificatFormData.append('femScore', this.uploadCertificate.femScore.toString());
    certificatFormData.append('membershipID', this.uploadCertificate.membershipID.toString());
    certificatFormData.append('issuanceDate', this.uploadCertificate.updatedOn.toString());
    certificatFormData.append('certificateType', CertificateTypes.AUDIT);
    certificatFormData.append('fslmScore', this.uploadCertificate.fslmScore.toString());
    certificatFormData.append('status', CertificateTypes.UPLOADED);
    certificatFormData.append('otherCertName', this.otherCertName);
    try {
      let response: any;
      response = await this.certificateService.uploadSocialCertificatesAsync(
        user.companyId,
        certificatFormData
      );

      if (response.status == 500) {
        if(this.tabIndex!==3){
          NotificationHelper.createErrorNotification(
            i18n.t('errors.certificate_upload_failed').toString()
          );
        }else{
          NotificationHelper.createErrorNotification(
            'Updating membership failed.'
          );
        }
      }

      if (response.type && response.ID) {
        const certTotal = this.$store.getters.certificatesCount;
        if (certTotal > 0) {
          this.$store.commit('setCertificatesCount', certTotal + 1);
        } else {
          this.$store.commit('setCertificatesCount', 1);
        }
        NotificationHelper.createSucceededNotification(
          i18n.t('pages.esg.new_social_audit_upload_success', [this.auditCertificates.filter(ac => ac.ID === this.uploadCertificate.type.toString())[0].Name]).toString()
        );
        this.certUploaded(response.ID, this.uploadCertificate.type.toString());
      }
    } finally {
      // this.isUploading = false;
    }
  }

  private async checkNonRomanChar(val:string) {
    this.isNonRomanChar = await NonRomanCharCheckHelper.checkNonRomanChar(val);
  }

  private async checkNonRomanCharForUpload(variableName:string,val:string){
    (this as any)[variableName] = await NonRomanCharCheckHelper.checkNonRomanChar(val)
  }

  private get isNonRomnError():boolean{
    if(this.isCompanyNonRoman||this.isReferenceNonRoman||this.isRspNonRoman||this.isAuditNonRoman||
      this.isAmforNonRoman||this.isAmforSiteIdNonRoman||this.isOekoNonRoman){
        return true;
      }
      return false
  }

  private get certifcationBodyList(): DropdownModel[] {
		let options: DropdownModel[] = [];
		options = CertificateBodyList.getCertificatesBodyList();
		return options;
	}

  private setCertificateBody(val:any): void{
    this.uploadCertificate.certificationBody = val.fieldValue;
    this.checkForUploadErrors('certificationBody');
  }

  private setAuditOption(val:any): void{
    this.uploadCertificate.auditresult = val.fieldValue;
    this.checkForUploadErrors('audit');
  }

  private searchBy(): void{
    if(this.searchText.trim()!==''){
      this.filteredData = this.auditCertificates.filter(su => su.Name.toLowerCase().includes(this.searchText.toLowerCase()) && su.Name!=='Other');
    }else{
      this.filteredData = [];
    }
  }

  private setOtherCertificate(): void{
    this.resetField = false;
    if(this.tabIndex === 0){
      this.uploadCertificate.type = 12;
      this.cerificateType = CertificateTypes.SUSTAINABILITY;
      this.ledgerType = CertificateTypes.SUSTAINABILITY;
    }else if(this.tabIndex === 1){
      this.uploadCertificate.type = 10;
      this.cerificateType = CertificateTypes.SOCIAL;
      this.ledgerType = CertificateTypes.SOCIAL;
    }else if(this.tabIndex === 2){
      this.uploadCertificate.type = 15;
      this.cerificateType = CertificateTypes.AUDIT;
      this.ledgerType = CertificateTypes.SOCIAL;
    }else {
      this.uploadCertificate.type = 16;
      this.cerificateType = CertificateTypes.MEMBERSHIP;
      this.ledgerType = CertificateTypes.SOCIAL;
      this.isUploadError.files = 0;
      this.isUploadError.licenseID = 0;
    }
    this.certificateTypename = this.otherCertName;
    this.switchView('upload')
  }

  // private async fetchPdfFile(): Promise<File> {
  //   const response = await fetch(samplePdf);
  //   const blob = await response.blob();
  //   return new File([blob], 'sample.pdf', { type: 'application/pdf' });
  // }

  private getFutureDate(days: number): Date {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + days);
    return currentDate;
  }

  @Watch('uploadCertificate.files')
  private checkValidFiles(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('files');
    }
  }

  @Watch('uploadCertificate.validfromDate')
  private checkValidFromDate(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('validfromDate');
    }
  }

  @Watch('uploadCertificate.validtoDate')
  private checkValidToDate(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('validtoDate');
    }
  }

  @Watch('uploadCertificate.updatedOn')
  private checkUpdatedOn(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('updatedOn');
    }
  }

  @Watch('uploadCertificate.issuanceDate')
  private checkissuanceDate(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('issuanceDate');
    }
  }

  private closeSwitchModal(): void {
    if(this.currentView === '' || this.currentView === 'viewpdf'){
      this.closeModal();
    } else if( this.currentView === 'upload'){
      this.switchView('');
    }
  }

  @Emit()
  private certUploaded(ID: string, type: string): void {
  }

  @Emit()
  private closeModal(): void {
  }
}
