import { BaseClient } from './baseClient';
import { SupplyChainApiAuthenticator } from '../authenticators/supplyChainApiAuthenticator';
import { ApplicationConfig } from '../config';
import { SupplyChainStepModel } from '@/models/supplyChainStepModel';

export class SupplyChainStepClient extends BaseClient {
  private readonly endPoint: string = 'api/supplychainstep';

  public constructor() {
    super(
      ApplicationConfig.endpoints.dashboardApi,
      new SupplyChainApiAuthenticator()
    );
  }

  public async getSupplyChainStepsAsync(): Promise<SupplyChainStepModel[]> {
    return await this.get<SupplyChainStepModel[]>(`${this.endPoint}`);
  }
}
