import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { uniq } from 'underscore';

import { GroupStepEvidenceModel } from '@/models/groupStepEvidenceModel';
import { OrderStepEvidenceModel } from '@/models/orderStepEvidenceModel';

@Component({
    components: { },
    computed: mapGetters([]),
  })

export default class UploadTable extends Vue {
    @Prop()
    private groupedStepOrders!: GroupStepEvidenceModel;

    @Prop({ default: false})
    private noCheckboxes!: boolean;

    private orderList!: OrderStepEvidenceModel[];

    private allSelected: boolean = true;

    private selectedOrders: OrderStepEvidenceModel[] = [];

    private async created(): Promise<void> {
        this.orderList = uniq(this.groupedStepOrders.orderStyle, 'orderId');
        if(this.noCheckboxes){
          this.orderList = this.orderList.filter(o => o.isSelected === true);
        }
        this.allSelected =
        this.groupedStepOrders.orderStyle.findIndex(
            (o) => o.isSelected === false
        ) === -1
            ? true
            : false;
        this.selectedOrderList(this.groupedStepOrders.orderStyle);
    }

    private selectAll(): void{
        this.allSelected = !this.allSelected;
        this.orderList.forEach((o) => {
        o.isSelected = this.allSelected;
        });
        const orderList = this.orderList.filter((o) => o.isSelected === true);
        const selectedOrderList: OrderStepEvidenceModel[] = [];
        orderList.forEach((order) => {
        selectedOrderList.push(
            ...this.groupedStepOrders.orderStyle.filter(
            (o) => o.orderId === order.orderId
            )
        );
        });
        this.selectedOrderList(selectedOrderList);
    }

    // private deSelectAll(): void{
    //     this.isSelectAll = false;
    //     this.orders = this.orders.map(item => ({
    //     ...item,
    //     isSelected: false
    //   }));
    //   this.selectedOrders = cloneDeep(this.orders);
    // }

    private select(index: number, state: boolean): void {
        this.orderList[index].isSelected = !state;
        if (
          this.orderList.filter((o) => o.isSelected === true).length ===
          this.orderList.length
        ) {
          this.allSelected = true;
        } else {
          this.allSelected = false;
        }
        const orderList = this.orderList.filter((o) => o.isSelected === true);
        const selectedOrderList: OrderStepEvidenceModel[] = [];
        orderList.forEach((order) => {
          selectedOrderList.push(
            ...this.groupedStepOrders.orderStyle.filter(
              (o) => o.orderId === order.orderId
            )
          );
        });
        this.selectedOrderList(selectedOrderList);
    }

    @Emit()
    private selectedOrderList(selectedOrders: OrderStepEvidenceModel[]): void {}
}
