import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import moment from 'moment';
import lodash from 'lodash';
import i18n from '@/i18n';

// Components
import ViewCertificatesUploaded from '@/views/compliance/components/viewCertificatesUploaded.vue';

// Models
import { CertificateModel, CertificateOnTypeModel, CertificateTypes } from '@/models/certificateModel';
import { UserModel } from '@/models/userModel';

// Service
import { ComplianceCertificatesService } from '@/services/complianceCertificatesService';
import { NotificationHelper } from '@/helpers/notificationHelper';

@Component({
  components: { ViewCertificatesUploaded },
})
export default class DocumentsSigned extends Vue {
  @Prop()
  private documents!: {
    social: CertificateModel[];
    sustainable: CertificateModel[];
  };

  @Prop()
  private selectedTab!: number;

  @Prop()
  private searchFilter!: string;

  @Prop()
  private loadingDoc!: boolean;

  private showPDFModal: boolean = false;
  private sortGroupType: string = '';
  private sortGroupTypename: string = '';
  private sortValidToLatest: boolean = true;
  private sortValidToLatestArchive: boolean = true;

  private susFocusCertificateGroup: string = '';
  private socFocusCertificateGroup: string = '';
  private auditFocusCertificateGroup: string = '';
  private memFocusCertificateGroup: string = '';

  private documentDetail: CertificateModel = new CertificateModel();

  private isShowArchive: boolean = false;

  private sustainableCerts: CertificateOnTypeModel[] = [];
  private auditCerts: CertificateOnTypeModel[] = [];
  private sustainableAuditCerts: CertificateOnTypeModel[] = [];
  private sustainableMemCerts: CertificateOnTypeModel[] = [];
  private socialCerts: CertificateOnTypeModel[] = [];
  private socailAuditCerts: CertificateOnTypeModel[] = [];
  private socailMemCerts:  CertificateOnTypeModel[] = [];
  private membershipCerts: CertificateOnTypeModel[] = [];
  private showSocialArchive: boolean = false;
  private showSustainableArchive: boolean = false;
  private showAuditArchive: boolean = false;
  private showMembershipArchive: boolean = false;

  private certificateService: ComplianceCertificatesService;

  public constructor() {
    super();
    this.certificateService = new ComplianceCertificatesService();
  }

  private created(): void{
    const sustainable = i18n.t(`enums.sustainability_certificates.sustainability`) as any;
    for (const key of Object.keys(sustainable)) {
        const certifcateName = sustainable[key];
        this.sustainableCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.SUSTAINABILITY, CertificateTypes.SUSTAINABILITY))
    }
    const susMembership = i18n.t(`enums.sustainability_certificates.membership`) as any;
    for (const key of Object.keys(susMembership)) {
        const certifcateName = susMembership[key];
        this.sustainableMemCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SUSTAINABILITY));
        this.membershipCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SUSTAINABILITY));
    }
    const susAudit = i18n.t(`enums.sustainability_certificates.audit`) as any;
    for (const key of Object.keys(susAudit)) {
        const certifcateName = susAudit[key];
        this.sustainableAuditCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
        this.auditCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
    }
    const social = i18n.t(`enums.social_certificates.social`) as any;
    for (const key of Object.keys(social)) {
        const certifcateName = social[key];
        this.socialCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.SOCIAL, CertificateTypes.SOCIAL));
    }
    const socialAudit = i18n.t(`enums.social_certificates.audit`) as any;
    for (const key of Object.keys(socialAudit)) {
        const certifcateName = socialAudit[key];
        this.socailAuditCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
        this.auditCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SOCIAL));
    }
    const socialMembership = i18n.t(`enums.social_certificates.membership`) as any;
    for (const key of Object.keys(socialMembership)) {
        const certifcateName = socialMembership[key];
        this.socailMemCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SOCIAL));
        this.membershipCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SOCIAL));
    }
  }

  private async updateFocusCertificateGroup(group: string, type:string): Promise<void> {
    if(type === 'SUSTAINABILITY'){
      if (this.susFocusCertificateGroup !== group) {
        this.susFocusCertificateGroup = group;
      } else {
        this.susFocusCertificateGroup = '';
      }
    }else if(type === 'SOCIAL'){
      if (this.socFocusCertificateGroup !== group) {
        this.socFocusCertificateGroup = group;
      } else {
        this.socFocusCertificateGroup = '';
      }
    }else if(type === 'AUDIT'){
      if (this.auditFocusCertificateGroup !== group) {
        this.auditFocusCertificateGroup = group;
      } else {
        this.auditFocusCertificateGroup = '';
      }
    }else{
      if(this.memFocusCertificateGroup !== group) {
        this.memFocusCertificateGroup = group;
      } else {
        this.memFocusCertificateGroup = '';
      }
    }
  }

  private get socialCertificates(): {} {
    return this.filterDocuments('social');
  }

  private get sustainableCertificates(): {} {
    return this.filterDocuments('sustainable');
  }

  private get auditCertificates(): {} {
    return this.filterDocuments('audit');
  }

  private get memberShipCertificates(): {} {
    return this.filterDocuments('membership')
  }


  private sortByValidTo(type: number, typename: string): void {
    this.sortValidToLatest = !this.sortValidToLatest;
    this.sortGroupType = type.toString();
    this.sortGroupTypename = typename;
    this.uploadedCertificates(type, typename);
  }

  private sortByValidToArchive(type: number, typename: string): void {
    this.sortValidToLatestArchive = !this.sortValidToLatestArchive;
    this.sortGroupType = type.toString();
    this.sortGroupTypename = typename;
    this.showArchivedCertificates(type, typename);
  }

  private filterDocuments(name: string): {} {
    let documents:CertificateModel[] = []
    if(name === 'social'){
      documents = this.documents.social.filter(s => s.certificateType !==CertificateTypes.AUDIT && s.certificateType !==CertificateTypes.MEMBERSHIP && s.type!==0 && this.socailAuditCerts.findIndex(au => au.ID === s.type.toString())===-1 && this.socailMemCerts.findIndex(mem => mem.ID === s.type.toString())===-1);
    }else if(name === 'audit'){
      documents = [...this.documents.social.filter(s => s.certificateType === CertificateTypes.AUDIT || (s.certificateType === '' && this.socailAuditCerts.findIndex(au => au.ID === s.type.toString())!==-1)), ...this.documents.sustainable.filter(s => s.certificateType === CertificateTypes.AUDIT || (s.certificateType === '' && this.sustainableAuditCerts.findIndex(au => au.ID === s.type.toString())!==-1))];
    }else if(name === 'membership'){
      documents = [...this.documents.social.filter(s => s.certificateType === CertificateTypes.MEMBERSHIP && s.membershipID!=='' && s.status!==''), ...this.documents.sustainable.filter(s => s.certificateType === CertificateTypes.MEMBERSHIP && s.membershipID!=='' && s.status!=='')];
    }else{
      documents = this.documents.sustainable.filter(s => s.certificateType !==CertificateTypes.AUDIT && s.certificateType !==CertificateTypes.MEMBERSHIP && s.type!==0 && this.sustainableAuditCerts.findIndex(au => au.ID === s.type.toString())===-1 && this.sustainableMemCerts.findIndex(mem => mem.ID === s.type.toString())===-1);
    }
    const app = this;
    const groupedCertificateModel = documents.reduce(
      (acc: any, certificate: any) => {
        const filteredCertificate = lodash.clone(certificate);
        if(name === 'social'){
          const cert = this.socialCerts.filter(soc => soc.ID === certificate.type.toString())[0];
          if(cert){
            filteredCertificate.typeName = cert.Name;
          }
        }else if(name === 'audit'){
          const cert = this.auditCerts.filter(aud => aud.ID === certificate.type.toString())[0];
          if(cert){
            filteredCertificate.typeName = cert.Name;
          }
        }else if(name === 'membership'){
          const cert = this.membershipCerts.filter(mem => mem.ID === certificate.type.toString())[0];
          if(cert){
            filteredCertificate.typeName = cert.Name;
          }
        }else{
          const cert = this.sustainableCerts.filter(sus => sus.ID === certificate.type.toString())[0];
          if(cert){
            filteredCertificate.typeName = cert.Name;
          }
        }
        filteredCertificate.expiryInfo = '';
        filteredCertificate.expiryStatus = '';
        const daysDifference = moment(certificate.validtoDate).diff(
          moment(),
          'days'
        );
        if (daysDifference <= 30) {
          if(daysDifference > 0){
            filteredCertificate.expiryInfo = `${app.$t(
                  'pages.compliance.uploaded_certs_expiring'
                )} ${daysDifference} ${app.$t(
                  'pages.compliance.uploaded_certs_expiring_days'
                )}`
          }
          else if (daysDifference == 0 ){
            filteredCertificate.expiryInfo = 'Expiring'
          }
          else{
            filteredCertificate.expiryInfo = app.$t('pages.compliance.uploaded_certs_expired');
          }
          filteredCertificate.expiryStatus =
            daysDifference >= 0 ? `expiring` : `expired`;
        }
        (
          acc[filteredCertificate.typeName] ||
          (acc[filteredCertificate.typeName] = [])
        ).push(filteredCertificate);
        return acc;
      },
      {}
    );

    const orderedGroupedCertificateModel = Object.keys(groupedCertificateModel)
      .sort()
      .reduce((obj: any, key) => {
        obj[key] = groupedCertificateModel[key];
        return obj;
      }, {});
    return orderedGroupedCertificateModel;
  }

  private showArchivedCertificates(typeValue:number, type: string): CertificateModel[] {
    if(type === CertificateTypes.SUSTAINABILITY){
      let documents = this.documents.sustainable.filter(s => s.type === typeValue && s.status === CertificateTypes.ARCHIVED && s.certificateType!== CertificateTypes.AUDIT && s.certificateType!== CertificateTypes.MEMBERSHIP);
      documents = this.certificateStatus(documents);
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, 'ID');
    }else if(type === CertificateTypes.SOCIAL){
      let documents = this.documents.social.filter(s => s.type === typeValue &&  s.status === CertificateTypes.ARCHIVED && s.certificateType!== CertificateTypes.AUDIT && s.certificateType!== CertificateTypes.MEMBERSHIP);
      documents = this.certificateStatus(documents);
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, 'ID');
    }else if(type === CertificateTypes.AUDIT){
      const suAudit = this.documents.sustainable.filter(s => s.type === typeValue && (s.status === CertificateTypes.ARCHIVED || s.status === '') && (s.certificateType === CertificateTypes.AUDIT || s.certificateType === ''));
      const socialAudit = this.documents.social.filter(s => s.type === typeValue && (s.status === CertificateTypes.ARCHIVED || s.status === '') && (s.certificateType === CertificateTypes.AUDIT || s.certificateType === ''));
      let documents = [...suAudit, ...socialAudit];
      documents = this.certificateStatus(documents);
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, 'ID');
    }else{
      const suAudit = this.documents.sustainable.filter(s => s.type === typeValue && s.status === CertificateTypes.ARCHIVED && s.certificateType === CertificateTypes.MEMBERSHIP && s.membershipID!=='');
      const socialAudit = this.documents.social.filter(s => s.type === typeValue && s.status === CertificateTypes.ARCHIVED && s.certificateType === CertificateTypes.MEMBERSHIP && s.membershipID!=='');
      let documents = [...suAudit, ...socialAudit];
      documents = this.certificateStatus(documents);
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, 'ID');
    }
  }

  private formattedDate(date: string): string {
    return moment(date).format('D MMM YYYY');
  }

  private openPDFModal(doc: any, certName:string): void {
    this.documentDetail = doc;
    this.documentDetail.typeName = certName;
    this.documentDetail.amforiID = doc.licenseID;
    this.showPDFModal = true;
  }

  private async archiveCertificate(doc: any, type:string): Promise<void> {
    doc.isArchiving = true;
    let relatableType = doc.relatableType;
    if(doc.relatableType === CertificateTypes.SOCIAL){
      const index = this.documents.social.findIndex(s => s.ID === doc.ID);
      if(index!==-1){
        this.documents.social.splice(index, 0, doc);
      }
    }else{
      const index = this.documents.sustainable.findIndex(s => s.ID === doc.ID);
      if(index!==-1){
        this.documents.sustainable.splice(index, 0, doc);
      }
    }
    const payload = {
      "ID": doc.ID,
      "certificateType": type,
      "relatableType": relatableType
    }
    const user = this.$store.getters.user as UserModel;
    const result = await this.certificateService.archiveCertificate(user.companyId,payload);
    const storeSocial:CertificateModel[] = this.$store.getters.socialCertificate;
    const storeSutainable:CertificateModel[] = this.$store.getters.sustainableCertificate;
    if(result.success){
      doc.isArchiving = false;
      NotificationHelper.createSucceededNotification("Certificate is archived successfully.");
      if(storeSocial.length === 0 || storeSutainable.length === 0){
        this.susFocusCertificateGroup = '';
        this.socFocusCertificateGroup = '';
        this.auditFocusCertificateGroup = '';
        this.susFocusCertificateGroup = '';
        this.reloadDocuments();
      }else{
        if(doc.relatableType === CertificateTypes.SOCIAL){
          const socialIndex = storeSocial.findIndex(s => s.ID === doc.ID);
          if(socialIndex!==-1){
            storeSocial[socialIndex].status = CertificateTypes.ARCHIVED;
            this.$store.commit('setSocialCertificates', storeSocial);
          }
        }else{
          const susIndex = storeSutainable.findIndex(s => s.ID === doc.ID);
          if(susIndex!==-1){
            storeSutainable[susIndex].status = CertificateTypes.ARCHIVED;
            this.$store.commit('setSustainableCertificate', storeSutainable);
          }
        }
      }

    }else{
      NotificationHelper.createErrorNotification('Something went wrong!')
    }
  }

  private isStatusUploadedExist(certs: CertificateModel[]): boolean{
    if(certs.findIndex(c => c.status === CertificateTypes.UPLOADED || c.status === '' || c.status === CertificateTypes.EXPIRED)===-1){
      return false;
    }
    return true;
  }

  private uploadedCertificates(typeValue: number, type: string): CertificateModel[] {
    if(type === CertificateTypes.SUSTAINABILITY){
      let documents = this.documents.sustainable.filter(s => s.type === typeValue && (s.status === CertificateTypes.UPLOADED || s.status === '' || s.status === CertificateTypes.EXPIRED) && s.certificateType!== CertificateTypes.AUDIT && s.certificateType!== CertificateTypes.MEMBERSHIP);
      documents = this.certificateStatus(documents);
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, 'ID');
    }else if(type === CertificateTypes.SOCIAL){
      let documents = this.documents.social.filter(s => s.type === typeValue && (s.status === CertificateTypes.UPLOADED || s.status === '' || s.status === CertificateTypes.EXPIRED) && s.certificateType!== CertificateTypes.AUDIT && s.certificateType!== CertificateTypes.MEMBERSHIP);
      documents = this.certificateStatus(documents);
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, 'ID');
    }else if(type === CertificateTypes.AUDIT){
      const suAudit = this.documents.sustainable.filter(s => s.type === typeValue && (s.status === CertificateTypes.UPLOADED || s.status === '' || s.status === CertificateTypes.EXPIRED) && s.certificateType === CertificateTypes.AUDIT);
      const socialAudit = this.documents.social.filter(s => s.type === typeValue && (s.status === CertificateTypes.UPLOADED || s.status === '' || s.status === CertificateTypes.EXPIRED) && (s.certificateType === CertificateTypes.AUDIT || (s.certificateType === '' && this.auditCerts.findIndex(au => au.ID === typeValue.toString())!==-1)));
      let documents = [...suAudit, ...socialAudit];
      documents = this.certificateStatus(documents);
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, 'ID');
    }else{
      const susMem = this.documents.sustainable.filter(s => s.type === typeValue && (s.status === CertificateTypes.UPLOADED) && (s.certificateType === CertificateTypes.MEMBERSHIP) && s.membershipID!=='');
      const socialMem = this.documents.social.filter(s => s.type === typeValue && (s.status === CertificateTypes.UPLOADED) && (s.certificateType === CertificateTypes.MEMBERSHIP) && s.membershipID!=='');
      let documents = [...susMem, ...socialMem];
      documents = this.certificateStatus(documents);
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, 'ID');
    }
  }

  private sortByValidToDate(documents:CertificateModel[]): CertificateModel[]{
    const app = this;
    documents.sort(function (a: CertificateModel, b: CertificateModel) {
      const dateA = new Date(a.validtoDate),
        dateB = new Date(b.validtoDate);
      if (
        (a.type.toString() == app.sortGroupType ||
          b.type.toString() == app.sortGroupType)
      ) {
        return app.sortValidToLatest
          ? moment(dateB).unix() - moment(dateA).unix()
          : moment(dateA).unix() - moment(dateB).unix();
      }
      return moment(dateB).unix() - moment(dateA).unix();
    });
    return documents;
  }

  private sortByArchiveLatest(documents:CertificateModel[]): CertificateModel[]{
    const app = this;
    documents.sort(function (a: CertificateModel, b: CertificateModel) {
      const dateA = new Date(a.validtoDate),
        dateB = new Date(b.validtoDate);
      if (
        (a.type.toString() == app.sortGroupType ||
          b.type.toString() == app.sortGroupType)
      ) {
        return app.sortValidToLatestArchive
          ? moment(dateB).unix() - moment(dateA).unix()
          : moment(dateA).unix() - moment(dateB).unix();
      }
      return moment(dateB).unix() - moment(dateA).unix();
    });
    return documents;
  }

  private closePDFModal(success: boolean): void {
    if (success) {
      this.reloadDocuments();
    }
    this.showPDFModal = false;
  }

  private toggleArchive(typeValue: number, relatableTtype:string, certificateType:string, id: string): void{
    const susIndex = this.documents.sustainable.findIndex(f => f.type === typeValue && f.certificateType === certificateType && relatableTtype === CertificateTypes.SUSTAINABILITY && f.ID === id);
    const socIndex = this.documents.social.findIndex(f => f.type === typeValue && f.certificateType === certificateType && relatableTtype === CertificateTypes.SOCIAL && f.ID === id);
    if(susIndex!==-1 && relatableTtype === CertificateTypes.SUSTAINABILITY){
      const cert = this.documents.sustainable.filter(s => s.type === typeValue && s.certificateType === certificateType && s.ID === id)[0];
      cert.isShowArchive = !cert.isShowArchive;
      this.documents.sustainable.splice(susIndex, 0, cert);
    }
    if(socIndex!==-1 && relatableTtype === CertificateTypes.SOCIAL){
      const cert = this.documents.social.filter(s => s.type === typeValue && s.certificateType === certificateType && s.ID === id)[0];
      cert.isShowArchive = !cert.isShowArchive;
      this.documents.social.splice(socIndex, 0, cert);
    }
  }

  private getNearestDate(cert: CertificateModel[]): string {
    let dates: Date[] = cert.filter(c => c.validtoDate && c.status !== CertificateTypes.ARCHIVED).map(c => new Date(c.validtoDate));
    if(dates.length === 0){
      dates = cert.filter(c => c.validtoDate && c.status === CertificateTypes.ARCHIVED).map(c => new Date(c.validtoDate));
    }
    const today = new Date();
    if(dates.filter(d => d > today).length > 1){
      const nearestDate = dates.filter(d => d > today).sort((a, b) => a.getDate() - b.getDate())[0];
      return this.formattedDate(nearestDate.toString());
    } else if(dates.filter(d => d > today).length === 1){
      const nearestDate = dates.filter(d => d > today)[0];
      return this.formattedDate(nearestDate.toString());
    } else {
      let nearestDate = dates[0];
      let minDifference = Math.abs(nearestDate.getTime() - today.getTime());
      if(dates.length > 1){
        for (let i = 1; i < dates.length; i++) {
          const difference = Math.abs(dates[i].getTime() - today.getTime());
          if (difference < minDifference) {
            nearestDate = dates[i];
            minDifference = difference;
          }
        }
      }
      return this.formattedDate(nearestDate.toString());
    }
  }

  private certificateStatus(certificates:CertificateModel[]): CertificateModel[]{
    const app = this;
    certificates.forEach(certificate => {
      const daysDifference = this.getDateDifference(certificate.validtoDate);
      if (daysDifference <= 30) {
        if(daysDifference > 0){
          certificate.expiryInfo = `${app.$t(
                'pages.compliance.uploaded_certs_expiring'
              )} ${daysDifference} ${app.$t(
                'pages.compliance.uploaded_certs_expiring_days'
              )}`
          if(daysDifference === 1){
            certificate.expiryInfo = `Expiring in ${daysDifference} day`
          }
        }
        else if (daysDifference == 0 ){
          certificate.expiryInfo = 'Expiring'
        }
        else{
          certificate.expiryInfo = 'Expired';
        }

        certificate.expiryStatus =
          daysDifference >= 0 ? `expiring` : `expired`;
      }
    });
    return certificates;
  }

  private getDateDifference(date: string): number {
    const start = moment(Date.now());
    const end = moment(date);
    const duration = moment.duration(end.diff(start));
    return Math.ceil(duration.asDays());
  }

  @Emit()
  private reloadDocuments(): void {}
}
