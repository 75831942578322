import { UploadEvidence } from '@/clients/uploadEvidence';
import { SupplierEvidence } from '@/clients/supplierEvidence';

// Models
import { OrderLineStepEvidenceResponseModel } from '@/models/orderLineStepEvidenceModel';
import { EvidenceFileRespModel, SubmitStepEvidenceModel } from '@/models/orderStepEvidenceModel';
import { NotificationHelper } from '@/helpers/notificationHelper';

export class EvidenceService {
  private readonly client: SupplierEvidence = new SupplierEvidence();
  private readonly uploadClient: UploadEvidence = new UploadEvidence();

  public async submitUploadedOrders(
    uploadOrders: FormData,
    supplierId: string
  ): Promise<boolean> {
    const result = await this.client.submitUploadedOrders(
      uploadOrders,
      supplierId
    );
    if (!result) {
      return true;
    } else {
      return false;
    }
  }

  public async reSubmitOrders(
    uploadOrders: FormData,
    supplierId: string
  ): Promise<boolean> {
    const result = await this.client.reSubmitOrders(uploadOrders, supplierId);
    if (!result) {
      return true;
    } else {
      return false;
    }
  }

  public async submitStepEvidence(
    stepsEvidence: SubmitStepEvidenceModel,
    supplierId: string
  ): Promise<boolean> {
    const result = await this.client.submitStepEvidence(stepsEvidence, supplierId);
    if (!result) {
      return true;
    } else {
      return false;
    }
  }

  public async getEvidencePhotos(
    orderId: string,
    orderLineId?: string,
    stepId?: string
  ): Promise<OrderLineStepEvidenceResponseModel> {
    const result = await this.client.getEvidencePhotos(
      orderId,
      orderLineId,
      stepId
    );
    return result;
  }

  public async postEvidenceStatus(
    companyId: string,
    data: object
  ): Promise<any> {
    return await this.client.postEvidenceStatus(companyId, data);
  }

  public async uploadEvidencePhotos(
    orderId: string,
    orderLineId: string,
    orderLineStepId: string,
    data: object
  ): Promise<Response> {
    return await this.uploadClient.postEvidencePhotos(
      orderId,
      orderLineId,
      orderLineStepId,
      data
    );
  }

  // TTD-4618 pdf evidence upload service
  public async uploadPdfEvidenceAsync(
    companyID: string,
    data: FormData
  ): Promise<EvidenceFileRespModel> {
    const response = await this.client.uploadPdfEvidenceAsync(companyID, data);
    if(!response.success){
      NotificationHelper.createErrorNotification(
        'Failed to upload document. Please try again.'
      );
    }
    return response;
  }
}
