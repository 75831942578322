import { ApplicationConfig } from '@/config';
import { BaseClientBlockchain } from './baseClientBlockchain';
import { SuppliersListModel } from '@/models/supplierModel';

// Models
import { OrderLineStepEvidenceResponseModel } from '@/models/orderLineStepEvidenceModel';
import { CompanyPictureModel, CompanyPictureReqModel } from '@/models/companyPictureModel';
import { ConfirmStepBlockchainModel, ConfirmStepBlockchainRespModel } from '@/models/confirmOrderLineStepModal';
import { StepsRespModel } from '@/models/stepsResponseModel';
import { OrderDelegateCompanyModel, OrderCompanyRespModel } from '@/models/delegateCompanyModel';
import { OrderWithEvidenceSteps } from '@/models/orderWithEvidenceStepsModel';
import { EvidenceFileRespModel, SubmitStepEvidenceModel } from '@/models/orderStepEvidenceModel';

export class SupplierEvidence extends BaseClientBlockchain {
  private readonly endPoint: string = 'v1/api';
  private readonly endPointV2: string = 'v2/api';

  public constructor() {
    super(ApplicationConfig.endpoints.blockchainApi);
  }

  public async submitUploadedOrders(
    uploadOrders: FormData,
    supplierId: string
  ): Promise<Response> {
    return await this.postWithResponse(
      `${this.endPoint}/orderlineStep/supplier/${supplierId}/uploadEvidence`,
      uploadOrders,
      true
    );
  }

  public async reSubmitOrders(
    uploadOrders: FormData,
    supplierId: string
  ): Promise<Response> {
    return await this.putWithResponse(
      `${this.endPoint}/orderlineStep/supplier/${supplierId}/reSubmitEvidence`,
      uploadOrders,
      true
    );
  }

  public async submitStepEvidence(stepsEvidence: SubmitStepEvidenceModel, supplierId: string): Promise<Response>{
    return await this.patchWithResponse(`${this.endPoint}/orderlineStep/supplier/${supplierId}/submitEvidence`, stepsEvidence, false);
  }

  public async getEvidencePhotos(
    orderId: string,
    orderLineId?: string,
    stepId?: string
  ): Promise<OrderLineStepEvidenceResponseModel> {
    return await this.get(
      `${this.endPoint}/order/${orderId}/line/${orderLineId}/steps/${stepId}/evidence`
    );
  }

  public async postEvidenceStatus(
    companyId: string,
    data: object
  ): Promise<Response> {
    return await this.patch(
      `${this.endPoint}/orderlineStep/supplier/${companyId}/changeStatus`,
      data
    );
  }

  public async postEvidencePhotos(
    orderId: string,
    orderLineId: string,
    orderLineStepId: string,
    data: object
  ): Promise<Response> {
    return await this.post(
      `${this.endPoint}/order/${orderId}/line/${orderLineId}/step/${orderLineStepId}/evidence`,
      data
    );
  }

  // Company pictures upload to blockchain
  public uploadCompanyPicturesAsync(
    companyId: string,
    pictures: CompanyPictureModel[]
  ): Promise<Response> {
    return this.post(
      `${this.endPoint}/supplier/${companyId}/profilepictures`,
      pictures
    );
  }

  public uploadProfilePictureAsync(
    userId: string,
    data: FormData
  ): Promise<Response> {
    return this.postWithResponse(
      `${this.endPoint}/user/${userId}/profilePics`,
      data,
      true
    );
  }

  public updateProfilePictureAsync(
    userId: string,
    data: FormData
  ): Promise<Response> {
    return this.putWithResponse(
      `${this.endPoint}/user/${userId}/updateProfilePics`,
      data,
      true
    );
  }

  public deleteProfilePictureAsync(
    userId: string,
    profileId: string
  ): Promise<Response> {
    return this.delete(
      `${this.endPoint}/user/${userId}/deleteProfilePics/${profileId}`
    );
  }

  // TTD-3406 pictures upload to blockchain improvement
  public deleteCompanyPictureAsync(
    companyId: string,
    pictureId: string
  ): Promise<Response> {
    return this.delete(
      `${this.endPoint}/supplier/${companyId}/profilepictures/${pictureId}`
    );
  }

  public updateOrderStepsBlockchainAsync(steps: ConfirmStepBlockchainModel[]): Promise<ConfirmStepBlockchainRespModel> {
    return this.putWithResponse<ConfirmStepBlockchainRespModel>(`${this.endPoint}/steps`, steps, false);
  }

  // api to get suppliers list and supplier certificate list
  public async getSuppliersListAsync(supplierId: string): Promise<SuppliersListModel>{
    return this.get<SuppliersListModel>(`${this.endPoint}/supplier/${supplierId}/dashboard/getSupplierList`);
  }

   //get orderLinesteps response to update the steps at store TTD-2422
   public updateOrderStepsAsync(steps: ConfirmStepBlockchainModel[]): Promise<StepsRespModel> {
    return this.putWithResponse<StepsRespModel>(`${this.endPoint}/steps`, steps, false);
  }

  // TTD-2422 delegate partner object save into ledger
  public async updateDelegatePartnerAsync(delegateCompany: OrderDelegateCompanyModel, supplierId: string): Promise<OrderCompanyRespModel> {
    return this.putWithResponse<OrderCompanyRespModel>(`${this.endPoint}/supplier/${supplierId}/delegateOrder`, delegateCompany, false);
  }

  // TTD-4398 get orderLineSteps for evidence - sprint 25
  public async getOrderLineEvidenceStepsByStatusAsync(companyID: string): Promise<OrderWithEvidenceSteps> {
    return this.get<OrderWithEvidenceSteps>(
      `${this.endPointV2}/supplier/${companyID}/orderLineSteps`
    );
  }

  // TTD-3406 pictures upload to blockchain imporvment
  public async submitCompanyPicturesAsync(pictures: CompanyPictureReqModel, supplierId: string): Promise<Response> {
    return this.patchWithResponse<Response>(`${this.endPoint}/supplier/${supplierId}/submit/profilepictures`, pictures, false);
  }

  // TTD-4618 sprint 27 upload pdf format evidence
  public uploadPdfEvidenceAsync(
    companyID: string,
    data: FormData
  ): Promise<EvidenceFileRespModel> {
    return this.postWithResponse(
      `${this.endPoint}/supplier/${companyID}/uploadPDF`,
      data,
      true
    );
  }
}
