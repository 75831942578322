import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { mapState } from "vuex";
import { v4 as uuidv4 } from 'uuid';

// Components
import uploadCertificates from "@/views/esg/components/uploadCertificates.vue";

// Services
import { SupplierService } from "@/services/supplierService";

// Models
import { AddressModel } from "@/models/addressModel";
import { CompanyModel } from "@/models/companyModel";
import { EsgAddressStatus, EsgAuditCertificate, EsgFacilityAddressInfo, EsgSocialAuditCert, EsgSocialModel } from "@/models/esgModel";
import { UserModel } from "@/models/userModel";
import { CertificateModel } from "@/models/certificateModel";

@Component({
    components: {uploadCertificates},
    computed: mapState(["company", "user", "esgSocial", "socialCertificate", "sustainableCertificate"]),
})
export default class EsgFacilityModal extends Vue {
    @Prop()
    private auditCerts!: CertificateModel[];

    @Prop()
    private type!: string;

    @Prop()
    private activeAddress!: AddressModel;

    private supplierService: SupplierService;

    private esgSocial!: EsgSocialModel[];
    private user!: UserModel;
    private company!: CompanyModel;
    private companyAddresses: AddressModel[] = [];
    private addresses: EsgAddressStatus[] = [];
    private isUploading: boolean = false;
    private showUploadEsgDocModal: boolean = false;
    private showUploadNewAuditCertModal: boolean = false;
    private auditDocs: EsgAuditCertificate[] = [];

    public constructor() {
        super();
        this.supplierService = new SupplierService();
    }

    private created(): void {
        this.companyAddresses.push(...this.company.officeAddress.filter(add => add.addressStatus !== 'DISABLED'));
        if (this.company.factoryAddressDiffers) {
            this.companyAddresses.push(...this.company.factoryAddresses.filter(add => add.addressStatus !== 'DISABLED'));
        }
        if(this.company.newFacilityData === 'YES'){
            this.companyAddresses.sort((a, b) => {
                return (a.position < b.position) ? -1 : ((a.position > b.position) ? 1 : 0);
            });
        }
        this.addresses.push(...this.companyAddresses.map(add => new EsgAddressStatus(add.id, '')));
        const index = this.addresses.findIndex(add => add.addressID === this.activeAddress.id);
        if (index !== -1) {
            this.addresses[index].isSelected = true;
            this.addresses.splice(index, 1, this.addresses[index]);
        }
        if(this.type === 'AUDIT') {
            this.auditCerts.filter(cert => cert.isSelected === true).forEach(cert => {
                const doc: EsgAuditCertificate = new EsgAuditCertificate();
                doc.complianceDocID = cert.ID;
                doc.type = cert.type.toString();
                this.auditDocs.push(doc);
            });
        }
    }

    private getFacilityName(addressID: string): string {
        const address = this.companyAddresses.find(add => add.id === addressID);
        return address ? address.officeName !== '' ? address.officeName : address.line1 : '-';
    }

    private selectUnselectFacility(index: number, isSelected: boolean): void {
        if(this.addresses[index].addressID !== this.activeAddress.id) {
            this.addresses[index].isSelected = isSelected;
            this.addresses.splice(index, 1, this.addresses[index]);
        }
    }

    private save(): void {
        if (this.type === 'AUDIT') {
            this.saveAuditCert();
        } else if (this.type === 'NEW-AUDIT') {
            this.uploadNewAuditCert();
        } else {
            const selectedFacilities = this.addresses.filter(add => add.isSelected);
            this.showEsgUploadDoc(true, selectedFacilities);
        }
    }

    private async saveAuditCert(): Promise<void> {
        this.isUploading = true;
        const socialCerts: EsgSocialAuditCert = new EsgSocialAuditCert();
        const selectedFacilities = this.addresses.filter(add => add.isSelected);
        const facilityWithID: EsgFacilityAddressInfo[] = [];
        selectedFacilities.forEach(add => {
            const facility: EsgFacilityAddressInfo = new EsgFacilityAddressInfo();
            facility.addressID = add.addressID;
            const socialIdIndex = this.esgSocial.findIndex(social => social.addressID === add.addressID);
            if(socialIdIndex !== -1) {
                facility.ID = this.esgSocial[socialIdIndex].ID;
            } else {
                facility.ID = uuidv4();
            }
            facilityWithID.push(facility);
        });
        socialCerts.certificates = this.auditDocs;
        socialCerts.createdAt = new Date().toISOString();
        socialCerts.createdBy = this.user.userId;
        socialCerts.addressInfoForFacility = facilityWithID;
        const result = await this.supplierService.uploadEsgSocialAuditCertAsync(socialCerts);
        this.isUploading = false;
        if (result.success) {
            this.showUploadNewAuditCertModal = false;
            this.socialAuditUploaded(socialCerts);
        }
    }

    private uploadNewAuditCert(): void {
        this.showUploadNewAuditCertModal = true;
    }

    private async certUploaded(ID: string, type: string): Promise<void> {
        this.auditDocs = [];
        const doc: EsgAuditCertificate = new EsgAuditCertificate();
        doc.complianceDocID = ID;
        doc.type = type;
        this.auditDocs.push(doc);
        await this.saveAuditCert();
    }

    private closeModal(): void {
        this.showUploadNewAuditCertModal = false;
    }

    @Emit()
    private showFacilityModal(status: boolean, type: string): void {}

    @Emit()
    private socialAuditUploaded(socialCerts: EsgSocialAuditCert): void {}

    @Emit()
    private showEsgUploadDoc(status: boolean, selectedFacility: EsgAddressStatus[]): void {}
}
