export class StepHistory {
  public constructor(stepHistory: StepHistory) {
    this.sequence = stepHistory.sequence;
    this.updateAt = stepHistory.updateAt;
    this.editedByCompanyId = stepHistory.editedByCompanyId;
    this.editedByCompanyName = stepHistory.editedByCompanyName;
    this.createdByCompanyId = stepHistory.createdByCompanyId;
    this.createdByCompanyName = stepHistory.createdByCompanyName;
    this.stepId = stepHistory.stepId;
    this.displayName = stepHistory.displayName;
    this.description = stepHistory.description;
  }

  public sequence?: number;
  public updateAt?: string;
  public editedByCompanyId?: string;
  public editedByCompanyName?: string;
  public createdByCompanyId?: string;
  public createdByCompanyName?: string;
  public stepId?: number;
  public displayName?: string;
  public description?: string;
}

export class StepHistoryBlockchainModel {
  public constructor(stepHistory: StepHistory) {
    this.sequence = stepHistory.sequence;
    this.updatedAt = stepHistory.updateAt;
    this.editedByCompanyID = stepHistory.editedByCompanyId;
    this.editedByCompanyName = stepHistory.editedByCompanyName;
    this.createdByCompanyID = stepHistory.createdByCompanyId;
    this.createdByCompanyName = stepHistory.createdByCompanyName;
    this.stepID = stepHistory.stepId;
    this.displayName = stepHistory.displayName;
    this.description = stepHistory.description;
  }

  public sequence?: number;
  public updatedAt?: string;
  public editedByCompanyID?: string;
  public editedByCompanyName?: string;
  public createdByCompanyID?: string;
  public createdByCompanyName?: string;
  public stepID?: number;
  public displayName?: string;
  public description?: string;
}
