import { BaseClient } from './baseClient';
import { SupplyChainApiAuthenticator } from '../authenticators/supplyChainApiAuthenticator';
import { ApplicationConfig } from '../config';
import { OrderModel } from '@/models/orderModel';
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { OrderWithEvidenceSteps } from '@/models/orderWithEvidenceStepsModel';
import { OrdersWithStepsModal } from '@/models/ordersWithStepsModel';
import { ConfirmOrderModel } from "@/models/confirmOrderModel";
import { OrderDelegatePartnerReqModel } from '@/models/delegateCompanyModel';

export class OrderClient extends BaseClient {
  private readonly endPoint: string = 'api/order';

  public constructor() {
    super(
      ApplicationConfig.endpoints.dashboardApi,
      new SupplyChainApiAuthenticator()
    );
  }

  public async getOrderLineStepsAsync(
    orderId: string,
    orderLineId: string
  ): Promise<OrderLineStepModel[]> {
    return await this.get<OrderLineStepModel[]>(
      `${this.endPoint}/${orderId}/line/${orderLineId}/steps`
    );
  }

  // public async getPendingOrdersAsync(
  //   bookmark: string
  // ): Promise<OrdersWithStepsModal> {
  //   return await this.get<OrdersWithStepsModal>(
  //     `${this.endPoint}/pendingOrders?bookmark=${bookmark}&pagesize=${
  //       process.env.VUE_APP_pageSize !== undefined
  //         ? process.env.VUE_APP_pageSize
  //         : 500
  //     }`
  //   );
  // }

  public async createSupplyChainStepsAsync(
    steps: OrderLineStepModel[]
  ): Promise<Response> {
    return await this.post(`${this.endPoint}/orderlinesteps`, steps);
  }

  public async editSupplyChainStepsAsync(
    steps: OrderLineStepModel[]
  ): Promise<Response> {
    return await this.put(`${this.endPoint}/editorderlinesteps`, steps);
  }

  public getOrderLineEvidenceStepsByStatusAsync(
    statusList: number[]
  ): Promise<OrderWithEvidenceSteps> {
    const statusQuery = statusList.join(',');
    return this.get<OrderWithEvidenceSteps>(
      `${this.endPoint}/orderlinesteps?status=${statusQuery}`
    );
  }

  public async updateLastStep(data: object): Promise<Response> {
    return await this.post(`${this.endPoint}/updatelaststep`, data);
  }

  public async confirmOrderAsync(orders: ConfirmOrderModel[]): Promise<OrdersWithStepsModal> {
    return await this.putWithResponse<OrdersWithStepsModal>(`${this.endPoint}/confirm/bulkOrder`, orders);
  }

  // TTD-2422 api to trigger mail for order which are assigned to delegated partner 
  public async triggerMailDelegatePartner(delegateMailData: OrderDelegatePartnerReqModel): Promise<Response> {
    return await this.post(`${this.endPoint}/sendEmailToDelegatedPartner`, delegateMailData);
  }
}
