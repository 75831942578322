import { ProductGroupClient } from '@/clients/productGroupClient';
import { ProductGroupModel } from '@/models/productGroupModel';
import { SupplyChainStepModel } from '@/models/supplyChainStepModel';

export class ProductGroupService {
  private readonly client: ProductGroupClient = new ProductGroupClient();

  public async getProductGroupsAsync(): Promise<ProductGroupModel[]> {
    const result = await this.client.getProductGroupsAsync();
    return result;
  }

  public async getSupplyChainStepsForProductGroupAsync(
    productGroupId: number
  ): Promise<SupplyChainStepModel[]> {
    const result = await this.client.getSupplyChainStepsForProductGroupAsync(
      productGroupId
    );
    return result;
  }
}
