import { SupplierBlockchain } from '@/clients/supplierBlockchain';
import { DeleteDocRequestModel, FootprintDataResponseModel, FootprintDeadlineResponseModel, SaveFootprintDeadlineModel, ViewFootprintResponseModel } from '@/models/footprintModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import store from '@/store';
import { YarnFootprintRequestModel, YarnFootprintResponseModel, WetProcessFootprintResponseModel, YarnModel } from '@/models/yarnFootprintModel';
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';
import { FabricFootprintRequestModel } from '@/models/fabricFootprintModel';
import { WetProcessFootprintRequestModel } from '@/models/wetProcessFootprintModel';
import { GarmentFootprintRequestModel } from '@/models/garmentFootprintModel';
import { FootprintPartnerModel, FootprintPartnerResponseModel} from '@/models/orderLineStepDisplayModel';
import { StepState } from '@/models/stepState';
import { UserModel } from '@/models/userModel';

export class ClientRequestFootPrintData {
    private readonly supplier: SupplierBlockchain = new SupplierBlockchain();

    public async saveYarnFootprintData(orderId: string, data: YarnFootprintRequestModel): Promise<YarnFootprintResponseModel> {
        const result = await this.supplier.saveYarnFootprintDataAsync(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_yarn_saved').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_yarn_failed').toString());
        }
        return result;
    }

    public async saveWetProcessData(orderId: string, data: WetProcessFootprintRequestModel): Promise<WetProcessFootprintResponseModel> {
        const result = await this.supplier.saveWetProcessDataAsync(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_wet_saved').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_wet_failed').toString());
        }
        return result;
    }

    public async uploadSupportingDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.uploadSupportingDocsAsync(orderId, uploadData);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.supporting_uploadDoc_saved').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.supporting_doc_upload_failed').toString());
        }
        return result;
    }

    public async uploadWetMsdsDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.uploadWetMsdsDocsAsync(orderId, uploadData);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.msds_footprint_uploadDoc_saved').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.msds_doc_upload_failed').toString());
        }
        return result;
    }

    public async getFootprintOnOrderAsync(order: OrderLineDisplayModel): Promise<boolean>{
        const result = await this.supplier.getFootprintOnOrderAsync(order.orderId);
        if(result.success){
            const company = store.getters.user as UserModel;
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === order.orderId);
            const filterOrder = orders[orderIndex];
            const updatedOrder = result.orders;
            updatedOrder.productPictures = filterOrder.productPictures;
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, updatedOrder);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_fetch_failed').toString());
        }
        return result.success;
    }

    public async saveYarnTransportAsync(orderId: string, data: any): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.saveYarnTransportAsync(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.yarn_transport_saved').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.yarn_transport_failed').toString());
        }
        return result;
    }

    public async deleteSupportDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel>{
        let success = true;
        const result = await this.supplier.deleteSupportDocs(orderId, data);
        if(result){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_doc_delete_success').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_doc_delete_failed').toString());
        }
        return result;
    }

    public async deleteMsdsDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel>{
        let success = true;
        const result = await this.supplier.deleteMsdsDocs(orderId, data);
        if(result){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_doc_delete_success').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_doc_delete_failed').toString());
        }
        return result;
    }

    public async saveFabricFootprintDataAsync(orderId: string, data: FabricFootprintRequestModel): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.saveFabricFootprintDataAsync(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.fabric_footprint_saved').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.fabric_footprint_failed').toString());
        }
        return result;
    }

    public async saveGarmentFootprintDataAsync(orderId: string, data: GarmentFootprintRequestModel): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.saveGarmentFootprintDataAsync(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_garment_saved').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_garment_failed').toString());
        }
        return result;
    }

    public async getFootprintPartnerAsync(orderId: string, lineId: string): Promise<FootprintPartnerResponseModel>{
        const result = await this.supplier.getFootprintPartnerAsync(orderId, lineId);
        if(result.success){
            const partner = store.getters.footprintPartner as FootprintPartnerModel[];
            partner.push(...result.orderLineSteps.filter(s => (s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER)));
            store.commit('setFootprintPartner', partner);
        }
        return result;
    }

    public async uploadYarnWetMsdsDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.uploadYarnWetMsdsDocsAsync(orderId, uploadData);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.msds_footprint_uploadDoc_saved').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.msds_doc_upload_failed').toString());
        }
        return result;
    }

    public async deleteYarnWetMsdsDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel>{
        // let success = true;
        const result = await this.supplier.deleteYarnWetMsdsDocs(orderId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_doc_delete_success').toString());
            // success = true;
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_doc_delete_failed').toString());
            // success = false;
        }
        return result;
    }

    public async uploadSupportingWetYarnDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.uploadSupportingWetYarnDocsAsync(orderId, uploadData);
        if(result.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.supporting_uploadDoc_saved').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.supporting_doc_upload_failed').toString());
        }
        return result;
    }

    public async deleteWetYarnSupportDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel>{
        const result = await this.supplier.deleteWetYarnSupportDocs(orderId, data);
        if(result){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_doc_delete_success').toString());
            const orders = store.getters.footprintOrders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            orders.splice(orderIndex, 1);
            orders.splice(orderIndex, 0, result.order);
            store.commit("setFootprintOrders", orders);
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_doc_delete_failed').toString());
        }
        return result;
    }
}