import { ApplicationConfig } from '@/config';
import {
  ResendInviteBRNRequestModel,
  ResendInviteRequestModel,
  ResendInviteResponseModel,
  SigninSupplierModel,
} from '@/models/signinSupplierModel';
import { CreateSupplierModel } from '@/models/createSupplierModel';
import {
  CompanyAdminRequestModel,
  CompanyUserRequestModel,
  CompanyUserResponseModel,
} from '@/models/createCompanyUsersModel';
import { BaseClient } from './baseClient';
import { SupplyChainApiAuthenticator } from '@/authenticators/supplyChainApiAuthenticator';

// models
import { ApprovePendingUserModel, RejectPendingUserModel, SecondUser } from '@/models/secondUser';
import { SecondUserRequest } from '@/models/secondUser';
import { UserPermissionRequestModel } from '@/models/permissionModel';
import { UserModelResponse } from '@/models/userModel';

export class MiddlewareClient extends BaseClient {
  private readonly endPoint: string = 'api';

  public constructor() {
    super(
      ApplicationConfig.endpoints.dashboardApi,
      new SupplyChainApiAuthenticator()
    );
  }

  public async sendVerificationCode(
    email: string,
    verificationcode: string
  ): Promise<any> {
    return await this.postWithResponse(
      `${this.endPoint}/user/sendVerificationCode`,
      { email: email, verificationcode: verificationcode }
    );
  }

  public async resetsendVerificationCode(
    email: string,
    verificationcode: string
  ): Promise<any> {
    return await this.postWithResponse(
      `${this.endPoint}/user/resetsendVerificationCode`,
      { email: email, verificationcode: verificationcode }
    );
  }

  public async updatePassword(
    email: string,
    newpassword: string
  ): Promise<any> {
    return await this.patch(`${this.endPoint}/user/updatePassword`, {
      email: email,
      newpassword: newpassword,
    });
  }

  public async createSupplier(supplier: CreateSupplierModel): Promise<any> {
    return await this.postWithResponse(
      `${this.endPoint}/account/createSupplier`,
      supplier
    );
  }

  public async signinSupplier(client: SigninSupplierModel): Promise<any> {
    return await this.postWithResponse(
      `${this.endPoint}/account/signIn`,
      client
    );
  }

  public async createCompanyAdmin(
    user: CompanyAdminRequestModel
  ): Promise<any> {
    return await this.postWithResponse(`${this.endPoint}/user/addUser`, user);
  }

  public async getUserByEmailForInviteLink(email: string): Promise<any> {
    return await this.postWithResponse(
      `${this.endPoint}/user/getUserByEmailForInviteLink`,
      {
        email: email,
      }
    );
  }

  public async createSecondUser(
    data: SecondUser,
    companyId: string
  ): Promise<any> {
    return await this.postWithResponse(
      `${this.endPoint}/account/inviteSecondUserAndCreateAccount/${companyId}`,
      data
    );
  }

  public async blockUser(userId: string): Promise<any> {
    return await this.postWithResponse(
      `${this.endPoint}/account/BlockUser/${userId}`,
      []
    );
  }

  public async unBlockUser(userId: string): Promise<any> {
    return await this.postWithResponse(
      `${this.endPoint}/account/UnblockUser/${userId}`,
      []
    );
  }

  public async editSecondUser(
    data: SecondUserRequest,
    companyId: string
  ): Promise<any> {
    return await this.putWithResponse(
      `${this.endPoint}/account/EditSecondUser/${companyId}`,
      data
    );
  }

  public async resendinvite(data: SecondUserRequest): Promise<any> {
    return await this.postWithResponse(
      `${this.endPoint}/account/resendinvite`,
      data
    );
  }

  public async setNewUserPassword(
    data: CompanyUserRequestModel
  ): Promise<CompanyUserResponseModel> {
    return await this.postWithResponse<CompanyUserResponseModel>(
      `${this.endPoint}/account/secondUserSaveAccount`,
      data
    );
  }

  public async updateUserPermission(
    data: UserPermissionRequestModel
  ): Promise<UserModelResponse> {
    return await this.putWithResponse(
      `${this.endPoint}/account/UpdateUserGroupPermission`,
      data
    );
  }

  public async resendInviteLink(
    data: ResendInviteRequestModel
  ): Promise<ResendInviteResponseModel> {
    return await this.postWithResponse(
      `${this.endPoint}/supplier/CheckEmailAndResendInviteLink`,
      data
    );
  }

  public async resendInviteLinkWithBRN(
    data: ResendInviteBRNRequestModel
  ): Promise<ResendInviteResponseModel> {
    return await this.postWithResponse(
      `${this.endPoint}/supplier/CheckBRNwithEmailAndResendInviteLink`,
      data
    );
  }

  public async approvePendingUserAsync(companyId: string ,data: ApprovePendingUserModel): Promise<UserModelResponse> {
    return await this.putWithResponse(`${this.endPoint}/account/approveSecondUser/${companyId}`, data);
  }

  public async rejectPendingUserAsync(data: RejectPendingUserModel): Promise<UserModelResponse> {
    return await this.postWithResponse(`${this.endPoint}/account/rejectSecondUser`, data);
  }
}
