import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export class AppInsightsHelper {
  private static isActive: boolean = false;
  private static appInsights: ApplicationInsights | undefined = undefined;

  public static initialize(): void {
    AppInsightsHelper.isActive =
      process.env.VUE_APP_appInsightsInstrumentationKey!.length > 0;

    if (AppInsightsHelper.isActive) {
      AppInsightsHelper.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: process.env.VUE_APP_appInsightsInstrumentationKey,
          enableAutoRouteTracking: true,
        },
      });
      AppInsightsHelper.appInsights.loadAppInsights();
      AppInsightsHelper.appInsights.trackPageView();
    }
  }

  public static trackCritical(exception: Error): void {
    if (!AppInsightsHelper.isActive) {
      return;
    }

    AppInsightsHelper.appInsights?.trackException({
      exception: exception,
      severityLevel: 4,
    });
  }

  public static trackError(exception: Error): void {
    if (!AppInsightsHelper.isActive) {
      return;
    }

    AppInsightsHelper.appInsights?.trackException({
      exception: exception,
      severityLevel: 3,
    });
  }

  public static trackWarning(
    message: string,
    properties?: { [key: string]: any }
  ): void {
    if (!AppInsightsHelper.isActive) {
      return;
    }

    AppInsightsHelper.appInsights?.trackTrace(
      {
        message: message,
        severityLevel: 2,
      },
      properties
    );
  }

  public static trackInformation(
    message: string,
    properties?: { [key: string]: any }
  ): void {
    if (!AppInsightsHelper.isActive) {
      return;
    }

    AppInsightsHelper.appInsights?.trackTrace(
      {
        message: message,
        severityLevel: 1,
      },
      properties
    );
  }

  public static trackTrace(
    message: string,
    properties?: { [key: string]: any }
  ): void {
    if (!AppInsightsHelper.isActive) {
      return;
    }

    AppInsightsHelper.appInsights?.trackTrace(
      {
        message: message,
        severityLevel: 0,
      },
      properties
    );
  }
}
