import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import { mapGetters } from 'vuex';
import { cloneDeep, uniq } from 'lodash';

// Service
import { EvidenceService } from '@/services/evidenceService';

// Helper
import { GroupStepEvidenceHelper } from '@/helpers/groupStepEvidenceHelper';

// Components
import EvidenceDetailsPopup from '@/views/desktopEvidence/components/evidenceDetailsPopup.vue';
import viewDocument from '@/views/desktopEvidence/components/viewDocument.vue';

// Models
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { UserPermissionModel } from '@/models/permissionModel';
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { GroupStepEvidenceModel } from '@/models/groupStepEvidenceModel';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import { OrderLineStepEvidenceModel } from '@/models/orderLineStepEvidenceModel';
import { EvidenceFile } from '@/models/orderStepEvidenceModel';
import { EvidencePhotoListModel, SelectPictureModel } from '@/models/selectPictureModel';
import moment from 'moment';
import _ from 'underscore';

@Component({
  components: { EvidenceDetailsPopup, viewDocument},
  computed: mapGetters(['userRole', 'userPermissions', 'pendingOrders', 'pendingOrderLineSteps', 'orderLineEvidenceApproveSteps']),
})
export default class ApproveRejectEvidence extends Vue {

  @Prop()
  private searchFilter!: string;

  private evidenceService: EvidenceService;

  private userRole!: string;

  private orderLineEvidenceApproveSteps!: OrderLineEvidenceStep[];

  private userPermissions!: UserPermissionModel | null;

  private groupedAppRejEviSteps: GroupStepEvidenceModel[] = [];

  private clonedGroupedAppRejEviSteps: GroupStepEvidenceModel[] = [];

  private isShowUploadPopup: boolean = false;

  private showUploadDocTypeModal: boolean = false;

  private isLoading: boolean = false;

  private showPreviewDocument: boolean = false;

  private uploadDocType: string = '';

  private selectedGroupSteps: GroupStepEvidenceModel = new GroupStepEvidenceModel();

  private appRejUploadedDocs: OrderLineStepEvidenceModel[] = [];

  private pdfEvidenceFile: EvidenceFile = new EvidenceFile();

  private imageEvidenceFiles: SelectPictureModel[] = [];

  private selectedIndex: number = -1;

  public constructor() {
    super();
    this.evidenceService = new EvidenceService();
  }

  private async created(): Promise<void> {
    if(this.orderLineEvidenceApproveSteps.length > 0){
      this.getGroupedAppRejEviSteps();
    }
  }

  private get waitingApproval(): number {
    return OrderLineStepStatus.WaitingForApproval;
  }

  private get updateOrderLinePermission(): number {
    return this.userPermissions !== null
      ? this.userPermissions.updateOrderLine
      : 0;
  }

  private combineRefNo(step: GroupStepEvidenceModel): string {
    let refNo: string[] = [];
    step.orderStyle.forEach((stepOrder, index) => {
      const stepIndex = this.orderLineEvidenceApproveSteps.findIndex(evi => evi.ID === stepOrder.ID);
      if(stepIndex !== -1){
        if(this.orderLineEvidenceApproveSteps[stepIndex].footprintRefNo !== '' && this.orderLineEvidenceApproveSteps[stepIndex].footprintRefNo !== undefined && this.orderLineEvidenceApproveSteps[stepIndex].footprintRefNo !== null){
          refNo.push(this.orderLineEvidenceApproveSteps[stepIndex].footprintRefNo);
        }
      }
    })
    return refNo.length > 0 ? uniq(refNo).join(', ') : '-';
  }

  private combineSteps(step:GroupStepEvidenceModel): string {
    // const nextStep = step.nextStepName!=='' ? ', ' + step.nextStepName : '';
    const tempSteps = cloneDeep(step.steps);
    let assignedSteps = tempSteps[0];
    tempSteps.splice(0, 1);
    for(const st of tempSteps){
      assignedSteps =  assignedSteps + ', ' + st;
    }
    return assignedSteps;
  }

  private async getGroupedAppRejEviSteps(): Promise<void>{
    const evidenceSteps = this.orderLineEvidenceApproveSteps.filter(
      (s) => s.status === OrderLineStepStatus.WaitingForApproval
    );
    this.groupedAppRejEviSteps = await GroupStepEvidenceHelper.groupStepEvidence(
      evidenceSteps
    );
    this.clonedGroupedAppRejEviSteps = cloneDeep(this.groupedAppRejEviSteps);
    this.search();
  }

  private async showPreview(selectedGroup: GroupStepEvidenceModel, index: number): Promise<void> {
    this.selectedIndex = index;
    this.isLoading = true;
    this.imageEvidenceFiles = [];
    this.appRejUploadedDocs = [];
    this.pdfEvidenceFile = new EvidenceFile();
    const result = await this.evidenceService.getEvidencePhotos(
      selectedGroup.orderStyle[0].orderId,
      selectedGroup.orderStyle[0].orderLineId,
      selectedGroup.orderStyle[0].ID
    );
    this.appRejUploadedDocs.push(...result.files);
    let uploadDocType = '';
    if(this.appRejUploadedDocs.length > 1){
      uploadDocType = 'IMAGE';
      this.appRejUploadedDocs.forEach(file => {
        const picture: SelectPictureModel = {
          status: this.waitingApproval,
          latitude: 0,
          longitude: 0,
          createdAt: moment(),
          createdBy: '',
          fileName: file.fileName,
          documentType: file.fileType,
          content: file.docUrl,
          fileUrl: '',
          size: 0,
          progress: 0,
          uploadedPicture: new EvidencePhotoListModel()
        }
        this.imageEvidenceFiles.push(picture);
      })
    } else {
      const file = this.appRejUploadedDocs[0].fileType.split('/');
      const type = file[file.length - 1];
      if(type === 'pdf'){
        uploadDocType = 'PDF'
        const evidence: EvidenceFile = {
          ID: '',
          fileHash: '',
          fileName: this.appRejUploadedDocs[0].fileName,
          fileType: this.appRejUploadedDocs[0].fileType,
          docUrl: '',
          createdAt: '',
          createdBy: '',
          url: this.appRejUploadedDocs[0].docUrl,
          geoLat: 0,
          geoLong: 0,
          status: this.waitingApproval
        }
        this.pdfEvidenceFile = evidence;
      } else {
        uploadDocType = 'IMAGE'
        const picture: SelectPictureModel = {
          status: this.waitingApproval,
          latitude: 0,
          longitude: 0,
          createdAt: moment(),
          createdBy: '',
          fileName: '',
          documentType: '',
          content: this.appRejUploadedDocs[0].docUrl,
          fileUrl: '',
          size: 0,
          progress: 0,
          uploadedPicture: new EvidencePhotoListModel()
        }
        this.imageEvidenceFiles.push(picture);
      }
    }
    this.uploadDocType = uploadDocType;
    this.isLoading = false;
    this.selectedGroupSteps = cloneDeep(selectedGroup);
    this.showPreviewDocument = true;
  }

  private close(success: boolean): void {
    this.showPreviewDocument = false;
    if(success === true){
      this.reload();
    }
  }

  private get approveRejectCount(): number{
    const approvedSteps = this.orderLineEvidenceApproveSteps.filter(
      (s) => s.status === OrderLineStepStatus.WaitingForApproval
    );
    return _.uniq(approvedSteps, 'orderID').length;
  }

  @Watch('searchFilter')
  private search(): void{
    if(this.searchFilter!==''){
      const searchLetter = this.searchFilter.toLowerCase();
      this.groupedAppRejEviSteps = this.clonedGroupedAppRejEviSteps.filter(g => g.reference.toLowerCase().includes(searchLetter) ||
      g.client.toLowerCase().includes(searchLetter) || g.currentStepCompany.toLowerCase().includes(searchLetter) ||
      g.steps[0].toLowerCase().includes(searchLetter) || g.nextStepName.toLowerCase().includes(searchLetter) ||
      g.nextStepCompany.toLowerCase().includes(searchLetter));
    }else{
      this.groupedAppRejEviSteps = this.clonedGroupedAppRejEviSteps;
    }
  }

  @Emit()
  private reload(): void{}

}
