//TTD-2736 sprint-19 development
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { mapState } from "vuex";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import moment from "moment";

@Component({
    components: {  },
    computed: mapState(['userRole', 'userPermissions', 'isOrderLoading', 'orders', 'orderLineStepsLoading', 'orderLineSteps', 'user', 'company', 'suppliersLoading', 'companyType', 'evidenceCountLoading'])
})
export default class InProgressData extends Vue {

    @Prop()
    private filteredOrders!: OrderLineDisplayModel[];
    //Sprint-22 TTD-3942
    @Prop()
    private tabIndex!:number;

    private orders!: OrderLineDisplayModel[];

    private orderLoading!: boolean;

    private evidenceCountLoading!: boolean;

    private formattedDate(date: string): string {
        if(date !== undefined && date !== ""){
            return moment(date).format("DD MMM YYYY");
        }
        else{
            return "-";
        }
    }

    private provideData(order: OrderLineDisplayModel): void {
        this.provideFootprintData(order);
    }

    @Emit()
    private provideFootprintData(order: OrderLineDisplayModel): void{}
}