import { CertificateModel } from '@/models/certificateModel';
import moment from 'moment';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  computed: {
    ...mapGetters([
      'isOrderLoading',
      'styleCount',
      'complianceCertificateLoading',
      'complianceCertificates',
    ]),
  },
})
export default class TotalStylesTile extends Vue {
  private isOrderLoading!: boolean;

  private styleCount!: number;

  private complianceCertificateLoading!: boolean;

  private complianceCertificates!: CertificateModel[];

  private get expiringComplainceCertificates(): number {
    let expCertificates: CertificateModel[] = [];
    expCertificates = this.complianceCertificates.filter(
      (c) => moment(c.validtoDate).diff(moment(),'days') >= 0 && moment(c.validtoDate).diff(moment(),'days') <= 30
    );
    return expCertificates.length;
  }
}
