import { UserClient } from '@/clients/userClient';
import MsalFactory from '@/factories/msalFactory';
import { ActionTypes } from '@/store/actions/actions';
import i18n from '@/i18n';

import {
  UserModel,
  UserModelRequest,
  UserModelResponse,
} from '@/models/userModel';
import { BlockchainAccessModal } from '@/models/blockchainAccessModal';

import store from '@/store';
import router from '@/router';
import { NotificationHelper } from '@/helpers/notificationHelper';

export class UserService {
  private readonly client: UserClient = new UserClient();
  private static storageKey: string = 'user';

  public async loadUserInfoAsync(
    forceReload: boolean = false
  ): Promise<string> {
    let lInfoExists: string = 'FALSE';
    let isBlocked: boolean = false;
    try {
      let user = new UserModel();

      const userStr = localStorage.getItem(UserService.storageKey);
      if (userStr == null || forceReload) {
        const result = await this.client.getUserInfoAsync();
        if (result.status === 'INACTIVE') {
          if (result.isBlocked == 'true') {
            isBlocked = true;
            lInfoExists = 'BLOCKED';
            NotificationHelper.createErrorNotification(
              i18n.t('global.notifications.user_blocked').toString()
            );
          } else {
            if (!forceReload) {
              router.push({ name: 'Signin' });
            } else {
              router.push({
                name: 'SignupLastStep',
                query: { newUser: 'true' },
              });
            }
          }
        }else if(result.status === 'REQUESTED'){
          lInfoExists = 'REQUESTED';
          return lInfoExists;
        } else if (result.companyType === 1 || result.companyType === 5) {
          lInfoExists = 'NOUSER';
        } else if (result.isBlocked == 'true') {
          isBlocked = true;
          lInfoExists = 'BLOCKED';
          NotificationHelper.createErrorNotification(
            i18n.t('global.notifications.user_blocked').toString()
          );
        } else {
          user = result;
          localStorage.setItem(UserService.storageKey, JSON.stringify(user));
          lInfoExists = 'TRUE';
        }
      } else {
        user = JSON.parse(userStr);
      }
      store.commit('setUser', user);
      if (
        (localStorage.getItem('invitationId') === null ||
          localStorage.getItem('invitationId') === undefined ||
          localStorage.getItem('invitationId') === '') &&
        !isBlocked
      ) {
        await store.dispatch(ActionTypes.SET_USER_ROLE_PERMISSIONS);
      }
    } catch (e:any) {
      if (e.StatusCode == 401) {
        alert('You are not allowed to use this dashboard');
        MsalFactory.logoutAllAccounts('https://tex-tracer.com');
      } else {
        MsalFactory.logoutAllAccounts(null);
      }

      throw Error('Something wrong with the user');
    }
    return lInfoExists;
  }

  public getUserInfo(): UserModel | null {
    const userStr = localStorage.getItem(UserService.storageKey);
    if (userStr) {
      return JSON.parse(userStr);
    }

    return null;
  }

  public async getUserInfoByIdAsync(userId: string): Promise<UserModel> {
    const user = await this.client.getUserInfoByIdAsync(userId);
    return user;
  }

  public async getBlockchainToken(): Promise<BlockchainAccessModal> {
    const accesstoken = await this.client.getBlockchainToken();
    return accesstoken;
  }

  public async updateUser(
    userDetails: UserModelRequest
  ): Promise<UserModelResponse> {
    const response = await this.client.updateUser(userDetails);
    if (response.result === 'success') {
      NotificationHelper.createSucceededNotification(response.message);
    } else {
      NotificationHelper.createErrorNotification(response.message);
    }
    return response;
  }

  public async getAllCompanyUsers(companyId: string): Promise<UserModel[]> {
    const companyUsers = await this.client.getAllCompanyUsers(companyId);
    return companyUsers.user;
  }
}
