import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import i18n from '@/i18n';

// Services
import { ComplianceService } from '@/services/complianceService';
import { NonRomanCharCheckHelper }from '@/helpers/nonRomanCharCheckHelper';

// Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';

// Models
import { UserModel } from '@/models/userModel';
import { ComplianceDocToSignCommentModel } from '@/models/complianceDocToSignCommentModel';

@Component({})
export default class CommentModel extends Vue {
  @Prop()
  private documentId!: string;

  @Prop()
  private comment!: any;

  @Prop()
  private commentType!: string;

  private complianceService: ComplianceService;

  private commenttext: string = '';

  private isLoading: boolean = false;

  private user: UserModel = new UserModel();

  private isNonRomanChar: boolean = false;

  public constructor() {
    super();
    this.complianceService = new ComplianceService();
  }

  private async postComment(): Promise<void> {
    if (this.commenttext.length < 1) {
      return;
    }
    if (this.isLoading) {
      return;
    }

    // switch to choose if editing or creating
    switch (this.comment.ID) {
      case '':
        try {
          this.isLoading = true;

          const commentData: any = {
            ID: this.documentId,
            text: this.commenttext,
          };
          const user = this.$store.getters.user as UserModel;
          const companyId = user.companyId;

          await this.complianceService.postCommentAsync(companyId, commentData);
        } catch {
          NotificationHelper.createErrorNotification(
            i18n.t('global.notifications.post_comment_error').toString()
          );
        } finally {
          NotificationHelper.createSucceededNotification(
            i18n.t('global.notifications.post_comment_succeeded').toString()
          );
          this.comment.ID = '';
          this.comment.text = '';
          this.isLoading = false;
          this.close();
        }
        break;

      default:
        try {
          this.isLoading = true;

          const commentData: any = {
            ID: this.documentId,
            scpCommentID: this.comment.ID,
            text: this.commenttext,
          };
          const user = this.$store.getters.user as UserModel;
          const companyId = user.companyId;

          await this.complianceService.editCommentAsync(companyId, commentData);
        } catch {
          NotificationHelper.createErrorNotification(
            i18n.t('global.notifications.edit_comment_error').toString()
          );
        } finally {
          NotificationHelper.createSucceededNotification(
            i18n.t('global.notifications.edit_comment_succeeded').toString()
          );
          this.comment.ID = '';
          this.comment.text = '';
          this.isLoading = false;
          this.close();
        }
        break;
    }
  }

  private async created(): Promise<void> {
    this.user = this.$store.getters.user as UserModel;
    if (this.comment.ID) {
      this.commenttext = this.comment.text;
    }
  }

  private get commentBtnText(): string {
    return this.commentType === 'edit'
      ? this.$t('pages.compliance.update_comment').toString()
      : this.$t('pages.compliance.send_comment').toString();
  }

  private async checkNonRomanChar(val:string){
    this.isNonRomanChar = await NonRomanCharCheckHelper.checkNonRomanChar(val)
  }

  @Emit()
  private close(): void {}

  @Emit()
  private closeParent(): void {}
}
