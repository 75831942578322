import { OrderStepEvidenceModel } from './orderStepEvidenceModel';

export class GroupStepEvidenceModel {
  public steps!: string[];
  public nextStepName!: string;
  public nextStepCompany!: string;
  public currentStepCompany!: string;
  public client!: string;
  public reference!: string;
  public orderStyle!: OrderStepEvidenceModel[];

  public constructor() {
    this.steps = [];
    this.nextStepName = '';
    this.nextStepCompany = '';
    this.currentStepCompany = '';
    this.client = '';
    this.reference = '';
    this.orderStyle = [];
  }
}
