import { render, staticRenderFns } from "./provideData.vue?vue&type=template&id=6dec99d4"
import script from "./provideData.ts?vue&type=script&lang=js&external"
export * from "./provideData.ts?vue&type=script&lang=js&external"
import style0 from "./provideData.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports