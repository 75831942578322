import { InvitationClient } from '@/clients/invitationClient';
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';
import { CompanyModel, companyResponseModel } from '@/models/companyModel';
import { InvitationModel } from '@/models/invitationModel';
import { SupplierBlockchain } from '@/clients/supplierBlockchain';
import { InvitedCompaniesModel } from '@/models/invitedCompaniesModel';
import { AddSecondUserResponeModel, InvitedSupplierModel, InvitedSupplierResponseModel } from '@/models/invitedSupplierModel';

export class InvitationService {
  private readonly client: InvitationClient = new InvitationClient();
  private readonly supplier: SupplierBlockchain = new SupplierBlockchain();

  public async getSupplierByInvitationIdAsync(
    invitationId: string
  ): Promise<CompanyModel> {
    const result = await this.client.getSupplierByInvitationIdAsync(
      invitationId
    );
    return result;
  }

  // Sprint 23 - TTD-3647, updated
  public async getCompanyFromNameAsync(str: string): Promise<InvitedCompaniesModel> {
    const result = await this.supplier.getCompanyFromNameAsync(str);
    return result;
}

  // Sprint 23 - TTD-3647, updated
  public async getCompanyFromRegAsync(str: string): Promise<InvitedCompaniesModel> {
      const result = await this.supplier.getCompanyFromRegAsync(str);
      return result;
  }

  public async updateSupplierAsync(
    invitationId: string,
    supplier: CompanyModel
  ): Promise<companyResponseModel> {
    const response = await this.client.updateSupplierAsync(
      invitationId,
      supplier
    );
    if (
      response.result === 'success' &&
      response.responseObject.companyName !== ''
    ) {
      // Only NoContent delivers no result
      localStorage.removeItem('invitationId');
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.supplier_update_succeeded').toString()
      );
    }
    return response;
  }

  public async sendInvitationAsync(
    invitationModel: InvitationModel
  ): Promise<InvitedSupplierResponseModel> {
    if (
      invitationModel.latitude == undefined ||
      invitationModel.latitude == null
    ) {
      invitationModel.latitude = 0;
    }
    if (
      invitationModel.longitude == undefined ||
      invitationModel.longitude == null
    ) {
      invitationModel.longitude = 0;
    }
    const result = await this.client.sendInvitation(invitationModel);
    return result;
  }

  public async addSecondUserAsync(invitedSupplierModel: InvitationModel, userId: string): Promise<AddSecondUserResponeModel> {
    return await this.client.addSecondUserAsync(invitedSupplierModel, userId);
  }
}
