import { UserService } from '@/services/userService';

export default class CompanyProvider {
  private userService: UserService;

  public constructor(userService: UserService) {
    this.userService = userService;
  }

  public static get(): CompanyProvider {
    return new CompanyProvider(new UserService());
  }

  public async getCompanyIdAsync(): Promise<string> {
    let user = this.userService.getUserInfo();
    if (!user || !user.companyId) {
      await this.userService.loadUserInfoAsync(true);
      user = this.userService.getUserInfo();
    }

    if (!user || !user.companyId) {
      throw Error('No company info found');
    }

    return user.companyId;
  }
}
