import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';
import { mapGetters } from 'vuex';

// Components
import ViewDocumentsToSign from '@/views/compliance/components/viewDocumentsToSign.vue';
import ViewDocumentsSigned from '@/views/compliance/components/viewDocumentsSigned.vue';

// Models
import { ComplianceDocResponseModel } from '@/models/complianceDocResponseModel';
import { ComplianceClientDocModel } from '@/models/complianceClientDocModel';
import { UserPermissionModel } from '@/models/permissionModel';
import { CompanyNameModel } from '@/models/companyModel';

@Component({
  components: { ViewDocumentsToSign, ViewDocumentsSigned },
  computed: mapGetters(['userRole', 'userPermissions', 'companyNameList']),
})
export default class documentsToSign extends Vue {
  @Prop()
  private documents!: ComplianceDocResponseModel[];

  @Prop()
  private selectedTab!: number;

  @Prop()
  private loadingDoc!: boolean;

  @Prop()
  private searchFilter!: string;

  @Prop()
  private selectedClient!: string;

  private showPDFModal: boolean = false;

  private showSignedPDFModal: boolean = false;

  private documentDetail: ComplianceDocResponseModel =
    new ComplianceDocResponseModel();

  private userRole!: string;

  private userPermissions!: UserPermissionModel | null;

  private companyNameList!: CompanyNameModel[];

  public constructor() {
    super();
  }

  private get notSignedDocuments(): ComplianceDocResponseModel[] {
    let clientDocs: ComplianceDocResponseModel[] = [];
    clientDocs = this.documents.filter((d) => d.scpSignedStatus === false && d.scpReadStatus === "false");
    if (this.searchFilter!=='') {
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      clientDocs = clientDocs.filter((s)=>s.documentName.toLowerCase().includes(lowerCaseFilter) || s.fromCompanyName.toLowerCase().includes(lowerCaseFilter));
    }
    return clientDocs;
  }

  private get signedDocuments(): ComplianceDocResponseModel[] {
    let clientDocs: ComplianceDocResponseModel[] = [];
    clientDocs = this.documents.filter((d) => d.scpSignedStatus === true || d.scpReadStatus === "true");
    if (this.searchFilter!=='') {
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      clientDocs = clientDocs.filter((s)=>s.documentName.toLowerCase().includes(lowerCaseFilter) || s.fromCompanyName.toLowerCase().includes(lowerCaseFilter));
    }
    return clientDocs;
  }

  private formattedDate(date: string): string {
    if (date==="9999-01-01T00:00:00Z") {
      return "No deadline";
    }
    return moment(date).format('DD MMM YYYY');
  }

  private get documentCount(): Number {
    return this.documents.length;
  }

  private openPDFModal(doc: ComplianceDocResponseModel): void {
    if (this.signCompliancePermission === 1) {
      const container = document.getElementById('right-container');
      container!.style.height = '97vh';
      this.documentDetail = doc;
      this.showPDFModal = true;
    }
  }

  private openSignedPDFModal(doc: ComplianceDocResponseModel): void {
    const container = document.getElementById('right-container');
    container!.style.height = '97vh';
      this.documentDetail = doc;
      this.showSignedPDFModal = true;
  }

  private closeSignedPDFModal(success: boolean): void {
    const container = document.getElementById('right-container');
    container!.style.height = '100vh';
    this.showSignedPDFModal = false;
  }

  private closePDFModal(success: boolean): void {
    const container = document.getElementById('right-container');
    container!.style.height = '100vh';
    if (success) {
      this.reloadDocuments();
    }
    this.showPDFModal = false;
  }

  private get signCompliancePermission(): number {
    return this.userPermissions !== null
      ? this.userPermissions.signCompliance
      : 0;
  }

  private partnerName(partnerId: string, currentName: string): string {
    return this.companyNameList.filter((c) => c.ID === partnerId).length > 0 ? this.companyNameList.filter((c) => c.ID === partnerId)[0].name : currentName;
  }

  @Emit()
  private reloadDocuments(): void {}
}
