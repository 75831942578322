import lodash from 'lodash';
import store from '@/store';
import _ from 'underscore';
import { GroupStepEvidenceModel } from '@/models/groupStepEvidenceModel';
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { OrderEvidence } from '@/models/orderEvidenceModel';
import { OrderStepEvidenceModel } from '@/models/orderStepEvidenceModel';
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { ConfirmStepBlockchainModel } from '@/models/confirmOrderLineStepModal';
import { Guid } from '@/models/guid';
import { StepState } from '@/models/stepState';

export class GroupStepEvidenceHelper {
  public static groupStepEvidence(
    steps: OrderLineEvidenceStep[]
  ): GroupStepEvidenceModel[] {
    const groupSteps: GroupStepEvidenceModel[] = [];
    const orders: OrderEvidence[] = store.getters
      .orderEvidence as OrderEvidence[];
    const neighbourSteps: OrderLineEvidenceStep[] = store.getters
      .orderLineEvidenceNeighbourSteps as OrderLineEvidenceStep[];
    let fromCompanyIds: string[] = orders.map((o) => o.fromCompanyID);
    let toCompanyIds: string[] = orders.map((o) => o.toCompanyID);
    fromCompanyIds = lodash.uniq(fromCompanyIds);
    toCompanyIds = lodash.uniq(toCompanyIds);
    fromCompanyIds.forEach((fromCompany) => {
      toCompanyIds.forEach((toCompany) => {
        const orderByCompany = orders.filter(
          (o) => o.fromCompanyID === fromCompany && o.toCompanyID === toCompany
        );
        const stepsByCompany: OrderLineEvidenceStep[] = [];
        orderByCompany.forEach((order) => {
          stepsByCompany.push(...steps.filter((s) => s.orderID === order.ID));
        });
        const uniqReferenceNo: string[] = lodash.uniq(
          stepsByCompany
            .filter(
              (s) =>
                s.referenceNo !== null &&
                s.referenceNo !== '' &&
                s.referenceNo !== undefined
            )
            .map((s) => s.referenceNo)
        );
        uniqReferenceNo.forEach((refNo) => {
          let referenceSteps = stepsByCompany.filter(
            (s) => s.referenceNo === refNo
          );
          const groupStep: GroupStepEvidenceModel =
            new GroupStepEvidenceModel();
          referenceSteps = referenceSteps.sort((step1, step2) => {
            return step1.sequence - step2.sequence;
          });
          let lastStepSequence = 0;
          referenceSteps.forEach((step) => {
            if (
              groupStep.steps.findIndex((s) => s === step.displayName) === -1
            ) {
              groupStep.steps.push(step.displayName);
              lastStepSequence = step.sequence;
              groupStep.currentStepCompany = step.toCompanyName;
            }
            const orderStyle: OrderStepEvidenceModel =
              new OrderStepEvidenceModel();
            const order = orderByCompany.filter(
              (o) => o.ID === step.orderID
            )[0];
            orderStyle.ID = step.ID;
            orderStyle.orderId = order.ID;
            orderStyle.orderLineId = order.orderLines[0].lineID;
            orderStyle.orderNo = order.orderNumber;
            orderStyle.styleNo = order.orderLines[0].styleNumber;
            groupStep.orderStyle.push(orderStyle);

            let neighbour = neighbourSteps.filter(
              (n) => n.orderID === order.ID
            );
            neighbour = neighbour.sort((step1, step2) => {
              return step1.sequence - step2.sequence;
            });
            if (neighbour.length > 0) {
              if (
                neighbour.filter((n) => n.sequence === lastStepSequence + 1)
                  .length > 0
              ) {
                groupStep.nextStepName = neighbour.filter(
                  (n) => n.sequence === lastStepSequence + 1
                )[0].displayName;
                groupStep.nextStepCompany = neighbour.filter(
                  (n) => n.sequence === lastStepSequence + 1
                )[0].toCompanyName;
              } else {
                groupStep.nextStepName = '';
                groupStep.nextStepCompany = '';
              }
            }
            groupStep.reference = refNo;
            if (step.fromCompanyID === order.fromCompanyID) {
              groupStep.client = order.fromCompanyUser.name;
            } else if (step.fromCompanyID === order.toCompanyID) {
              groupStep.client = order.toCompany.name;
            } else {
              groupStep.client = order.fromCompanyUser.name;
            }
          });
          groupSteps.push(groupStep);
        });
        const stepsWithoutRef = stepsByCompany.filter(
          (s) =>
            s.referenceNo === null ||
            s.referenceNo === '' ||
            s.referenceNo === undefined
        );
        stepsWithoutRef.sort((step1, step2) => {
          return step1.sequence - step2.sequence;
        });
        while (stepsWithoutRef.length !== 0) {
          let stepName = '';
          let compareStepName = '';
          let stepSequence = '';
          let compareStepSequence = '';
          let nextStep = '';
          let compareNextStep = '';
          let nextStepCompany = '';
          let compareNextStepCompany = '';
          const stepIds: string[] = [];
          const groupStep: GroupStepEvidenceModel =
            new GroupStepEvidenceModel();
          stepsWithoutRef.forEach((step, index) => {
            if (
              stepIds.length > 0 &&
              stepIds.findIndex((s) => s === step.ID) !== -1
            ) {
              return;
            }
            if (
              (stepName === '' && stepSequence === '') ||
              stepName.includes(step.displayName)
            ) {
              const stepData = stepsWithoutRef.filter(
                (s) => s.orderID === step.orderID
              );
              const stepDataList: OrderLineEvidenceStep[] = [];
              if (stepData.length > 0) {
                stepData.sort((step1, step2) => {
                  return step1.sequence - step2.sequence;
                });
                let seq = stepData[0].sequence;
                stepDataList.push(stepData[0]);
                for (let i = 1; i < stepData.length; i++) {
                  if (stepData[i].sequence === seq + 1) {
                    stepDataList.push(stepData[i]);
                    seq++;
                  } else {
                    break;
                  }
                }
              }
              if (stepName === '') {
                stepName = _.pluck(stepDataList, 'displayName').join(',');
                stepSequence = _.pluck(stepDataList, 'sequence').join(',');
                compareStepName = _.pluck(stepDataList, 'displayName').join(
                  ','
                );
                compareStepSequence = _.pluck(stepDataList, 'sequence').join(
                  ','
                );
              } else {
                compareStepName = _.pluck(stepDataList, 'displayName').join(
                  ','
                );
                compareStepSequence = _.pluck(stepDataList, 'sequence').join(
                  ','
                );
              }
              if (
                stepName === compareStepName &&
                stepSequence === compareStepSequence
              ) {
                const lastStepSequence =
                  stepDataList[stepDataList.length - 1].sequence;
                let neighbour = neighbourSteps.filter(
                  (n) => n.orderID === step.orderID
                );
                neighbour = neighbour.sort((step1, step2) => {
                  return step1.sequence - step2.sequence;
                });
                if (neighbour.length > 0) {
                  if (
                    neighbour.filter((n) => n.sequence === lastStepSequence + 1)
                      .length > 0
                  ) {
                    if (nextStep === '' && nextStepCompany === '') {
                      nextStep = neighbour.filter(
                        (n) => n.sequence === lastStepSequence + 1
                      )[0].displayName;
                      nextStepCompany = neighbour.filter(
                        (n) => n.sequence === lastStepSequence + 1
                      )[0].toCompanyName;
                    } else {
                      compareNextStep = neighbour.filter(
                        (n) => n.sequence === lastStepSequence + 1
                      )[0].displayName;
                      compareNextStepCompany = neighbour.filter(
                        (n) => n.sequence === lastStepSequence + 1
                      )[0].toCompanyName;
                    }
                  } else {
                    nextStep = '';
                    nextStepCompany = '';
                    compareNextStep = '';
                    compareNextStepCompany = '';
                  }
                }
              }
              const orderStyle: OrderStepEvidenceModel =
                new OrderStepEvidenceModel();
              const order = orderByCompany.filter(
                (o) => o.ID === step.orderID
              )[0];
              if (index === 0) {
                groupStep.steps = stepName.split(',');
                groupStep.currentStepCompany = step.toCompanyName;
                orderStyle.ID = step.ID;
                orderStyle.orderId = order.ID;
                orderStyle.orderLineId = order.orderLines[0].lineID;
                orderStyle.orderNo = order.orderNumber;
                orderStyle.styleNo = order.orderLines[0].styleNumber;
                groupStep.orderStyle.push(orderStyle);
                groupStep.nextStepName = nextStep;
                groupStep.nextStepCompany = nextStepCompany;
                groupStep.reference = '';
                if (step.fromCompanyID === order.fromCompanyID) {
                  groupStep.client = order.fromCompanyUser.name;
                } else if (step.fromCompanyID === order.toCompanyID) {
                  groupStep.client = order.toCompany.name;
                } else {
                  groupStep.client = order.fromCompanyUser.name;
                }
                stepIds.push(step.ID);
              } else {
                if (
                  stepName === compareStepName &&
                  stepSequence === compareStepSequence &&
                  nextStep === compareNextStep &&
                  nextStepCompany === compareNextStepCompany
                ) {
                  orderStyle.ID = step.ID;
                  orderStyle.orderId = order.ID;
                  orderStyle.orderLineId = order.orderLines[0].lineID;
                  orderStyle.orderNo = order.orderNumber;
                  orderStyle.styleNo = order.orderLines[0].styleNumber;
                  groupStep.orderStyle.push(orderStyle);
                  stepIds.push(step.ID);
                }
              }
            }
          });
          stepIds.forEach((ID) => {
            const removeSteps = stepsWithoutRef.filter((s) => s.ID === ID);
            removeSteps.forEach((removeStep) => {
              const index = stepsWithoutRef.findIndex(
                (s) => s.ID === removeStep.ID
              );
              stepsWithoutRef.splice(index, 1);
            });
          });
          groupSteps.push(groupStep);
        }
      });
    });
    return groupSteps;
  }

  public static updateApproveStepEvidece(orderLineSteps: ConfirmStepBlockchainModel[], type: string, isDelegatedPartnerRemoved:boolean): ConfirmStepBlockchainModel[]{
    const filteredOrderLineSteps = orderLineSteps.filter(step => step.stepState !== StepState.DELETED_BY_CLIENT && step.stepState !== StepState.DELETED_BY_AGENT && step.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && step.stepState !== StepState.DELETED_BY_SUPPLIER); // TTD-4331
    filteredOrderLineSteps.sort((step1, step2) => {
      if (step1.orderID === step2.orderID) {
        // Sort sequences in descending order
        return step2.sequence - step1.sequence;
      } else {
          // If orderIDs are different, sort by orderID
          return step1.orderID.localeCompare(step2.orderID);
      }
    });
    let orderID = filteredOrderLineSteps[0].orderID;
    let orders: OrderLineDisplayModel[] = [];
    if(type === 'CREATE'){
      orders = store.getters.pendingOrders as OrderLineDisplayModel[];
    }
    else{
      orders = store.getters.orders as OrderLineDisplayModel[];
    }
    let fromCompanyIDToWrite = orders.filter(o => o.orderId === orderID)[0].fromCompanyUser.ID;
    let toCompanyIDToWrite = orders.filter(o => o.orderId === orderID)[0].toCompanyId;
    let toCompanyID = filteredOrderLineSteps[0].toCompanyID;
    let brandName = orders.filter(o => o.orderId === orderID)[0].brandName;
    let topSequence = true;
    let agent = '';
    let delegate = '';

    for (var i = 0; i < filteredOrderLineSteps.length; i++) {
      if (orderID == filteredOrderLineSteps[i].orderID) {
          if (toCompanyID != filteredOrderLineSteps[i].toCompanyID) {
              if (filteredOrderLineSteps[i].toCompanyID === Guid.Empty || toCompanyID === Guid.Empty) {
                  filteredOrderLineSteps[i].approvalRequestedTo = "";
              }else{
                  filteredOrderLineSteps[i].approvalRequestedTo = toCompanyID;
              }
              toCompanyID = filteredOrderLineSteps[i].toCompanyID;
              filteredOrderLineSteps[i].clientID = fromCompanyIDToWrite;
              filteredOrderLineSteps[i].cpSupplierID = toCompanyIDToWrite;
              agent = orders.filter(o => o.orderId === orderID)[0].agentCompanyID;
              delegate = orders.filter(o => o.orderId === orderID)[0].delegateCompanyID;
              filteredOrderLineSteps[i].agentID = (agent !== null && agent !== undefined && agent !== '') ? agent : '';
              filteredOrderLineSteps[i].brandName = brandName;
              // TTD-2630 Sprint 18
              //seting Guid.Empty for filteredOrderLineSteps if delegatedpartner removed from the orders
              if(isDelegatedPartnerRemoved){
                filteredOrderLineSteps[i].delegatedCompanyID = Guid.Empty;
              }else{
                filteredOrderLineSteps[i].delegatedCompanyID === "" || filteredOrderLineSteps[i].delegatedCompanyID === undefined || filteredOrderLineSteps[i].delegatedCompanyID === Guid.Empty ? filteredOrderLineSteps[i].delegatedCompanyID = (delegate !== null && delegate !== undefined && delegate !== '' && delegate !== Guid.Empty) ? delegate : Guid.Empty : Guid.Empty;
              }
              if(topSequence){
                filteredOrderLineSteps[i].isLastStep = "true";
              } else{
                filteredOrderLineSteps[i].isLastStep = "false";
              }
              topSequence = false;
          } else {
              //logger.info("i am here -1")
              // check if prev toCompanyID is same as current company
              if (filteredOrderLineSteps[i].toCompanyID !== Guid.Empty) {
                  if (topSequence == false){
                      filteredOrderLineSteps[i].approvalRequestedTo = filteredOrderLineSteps[i - 1].approvalRequestedTo;
                  }else{
                      filteredOrderLineSteps[i].approvalRequestedTo = "";
                  }
              } else if (filteredOrderLineSteps[i].toCompanyID === Guid.Empty) {
                  filteredOrderLineSteps[i].approvalRequestedTo = "";
              }
              filteredOrderLineSteps[i].clientID = fromCompanyIDToWrite;
              filteredOrderLineSteps[i].cpSupplierID = toCompanyIDToWrite;
              agent = orders.filter(o => o.orderId === orderID)[0].agentCompanyID;
              delegate = orders.filter(o => o.orderId === orderID)[0].delegateCompanyID;
              filteredOrderLineSteps[i].agentID = (agent !== null && agent !== undefined && agent !== '') ? agent : '';
              filteredOrderLineSteps[i].brandName = brandName;
              // TTD-2630 Sprint 18
              //seting Guid.Empty for filteredOrderLineSteps if delegatedpartner removed from the orders
              if(isDelegatedPartnerRemoved){
                filteredOrderLineSteps[i].delegatedCompanyID = Guid.Empty;
              }else{
                filteredOrderLineSteps[i].delegatedCompanyID === "" || filteredOrderLineSteps[i].delegatedCompanyID === undefined || filteredOrderLineSteps[i].delegatedCompanyID === Guid.Empty ? filteredOrderLineSteps[i].delegatedCompanyID = (delegate !== null && delegate !== undefined && delegate !== '' && delegate !== Guid.Empty) ? delegate : Guid.Empty : Guid.Empty;
              }
              if(topSequence){
                filteredOrderLineSteps[i].isLastStep = "true";
              } else{
                filteredOrderLineSteps[i].isLastStep = "false";
              }
              topSequence = false;
          }
      } else {
          orderID = filteredOrderLineSteps[i].orderID;
          toCompanyID = filteredOrderLineSteps[i].toCompanyID;
          fromCompanyIDToWrite = orders.filter(o => o.orderId === orderID)[0].fromCompanyUser.ID;
          toCompanyIDToWrite = orders.filter(o => o.orderId === orderID)[0].toCompanyId;
          filteredOrderLineSteps[i].approvalRequestedTo = "";
          filteredOrderLineSteps[i].clientID = fromCompanyIDToWrite;
          filteredOrderLineSteps[i].cpSupplierID = toCompanyIDToWrite;
          filteredOrderLineSteps[i].isLastStep = "true";
          topSequence = false;
          agent = orders.filter(o => o.orderId === orderID)[0].agentCompanyID;
          delegate = orders.filter(o => o.orderId === orderID)[0].delegateCompanyID;
          filteredOrderLineSteps[i].agentID = (agent !== null && agent !== undefined && agent !== '') ? agent : '';
          filteredOrderLineSteps[i].brandName = brandName;
          // TTD-2630 Sprint 18
          //seting Guid.Empty for filteredOrderLineSteps if delegatedpartner removed from the orders
          if(isDelegatedPartnerRemoved){
            filteredOrderLineSteps[i].delegatedCompanyID = Guid.Empty;
          }else{
            filteredOrderLineSteps[i].delegatedCompanyID === "" || filteredOrderLineSteps[i].delegatedCompanyID === undefined || filteredOrderLineSteps[i].delegatedCompanyID === Guid.Empty ? filteredOrderLineSteps[i].delegatedCompanyID = (delegate !== null && delegate !== undefined && delegate !== '' && delegate !== Guid.Empty) ? delegate : Guid.Empty : Guid.Empty;
          }
      }
    }
    // TTD-4331
    const deletedSteps = orderLineSteps.filter(step => step.stepState === StepState.DELETED_BY_CLIENT || step.stepState === StepState.DELETED_BY_AGENT || step.stepState === StepState.DELETED_BY_DELEGATEPARTNER || step.stepState === StepState.DELETED_BY_SUPPLIER);
    filteredOrderLineSteps.push(...deletedSteps);
    return filteredOrderLineSteps;
  }
}
