import { Vue, Component, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import { mapGetters } from 'vuex';
import { ActionTypes } from '@/store/actions/actions';

// Services
import { ComplianceService } from '@/services/complianceService';
import { ComplianceCertificatesService } from '@/services/complianceCertificatesService';
import { NonRomanCharCheckHelper } from '@/helpers/nonRomanCharCheckHelper';

// Components
import ComplianceDocuments from '@/views/compliance/components/complianceDocuments.vue';
import UploadCertificates from '@/views/compliance/components/uploadCertificates.vue';
import CertificatesUploaded from '@/views/compliance/components/certificatesUploaded.vue';
import TransactionCertificates from "@/views/compliance/components/transactionCertificates.vue"

// Modals
import { ComplianceDocResponseModel } from '@/models/complianceDocResponseModel';
import { CertificateModel, CertificateTypes } from '@/models/certificateModel';
import { DropdownModel } from '@/models/dropdownModel';
import { UserModel } from '@/models/userModel';
import { UserPermissionModel } from '@/models/permissionModel';

//helpers
import { CertificateType } from "@/helpers/certficatesTypeHelper";
import { orderLineStepIdModel } from '@/models/approveRejectModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';

@Component({
  components: { ComplianceDocuments, UploadCertificates, CertificatesUploaded, TransactionCertificates },
  computed: mapGetters(['userRole', 'userPermissions', 'socialCertificate', 'sustainableCertificate', 'orders', 'isOrderLoading', 'pendingOrders']),
})
export default class Compliance extends Vue {
  private complianceService: ComplianceService;
  private certificateService: ComplianceCertificatesService;

  private documents: ComplianceDocResponseModel[] = [];

  private certificates: { [key: string]: CertificateModel[] } = {
    social: [],
    sustainable: [],
  };

  private certificatesSecondary: { [key: string]: CertificateModel[] } = {
    social: [],
    sustainable: [],
  };

  private uniqueClientName: string[] = [];

  private loadingDoc: boolean = false;

  private loadingCertificate: boolean = false;

  private tabIndex: number = 0;

  private selectedClient: string = '';

  private showUploadModal: boolean = false;

  private userRole!: string;

  private orders!: OrderLineDisplayModel[];

  private pendingOrders!: OrderLineDisplayModel[];

  private userPermissions!: UserPermissionModel | null;

  private searchFilter: string = '';

  private isNonRomanChar: boolean = false;

  private socialCertificate!: CertificateModel[];

  private sustainableCertificate!: CertificateModel[];
  // Sprnint-24 TTD-116
  private selectedBrand: string = '';

  private selectedType: string = '';

  private showClearFilter: boolean = false;

  private isOrderLoading!: boolean;

  private isCertificateUploaded!: boolean;

  public constructor() {
    super();
    this.complianceService = new ComplianceService();
    this.certificateService = new ComplianceCertificatesService();
  }

  private async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.getCertificates();
    this.getDocuments();
    this.tabIndex = 0;
    // Sprint-24 TTD-3798
    if (this.$route.params.selectTab=='certifcates') {
			this.selectedTab(0);
		}else if(this.$route.params.selectTab=='compliance'){
      this.selectedTab(1);
    }else if(this.$route.params.selectTab=='tc'){
      this.selectedTab(3);
    }
  }
  //Sprint-24 TTD-116
  private get brands(): DropdownModel[] {
    const options: DropdownModel[] = [
      { value: '', text: this.$t('pages.order_lines.all_brands').toString() },
    ];
    this.orders.forEach((line) => {
      if (
        options.findIndex((option) => option.value === line.brandName) === -1
      ) {
        options.push(new DropdownModel(line.brandName, line.brandName));
      }
    });
    this.pendingOrders.forEach((line) => {
      if (
        options.findIndex((option) => option.value === line.brandName) === -1
      ) {
        options.push(new DropdownModel(line.brandName, line.brandName));
      }
    });

    return options;
  }

  private get clientFilterList(): DropdownModel[] {
    const options: DropdownModel[] = [
      { value: '', text: this.$t('pages.compliance.all_client').toString() },
    ];
    this.uniqueClientName = [
      ...new Set(this.documents.map((d) => d.fromCompanyName)),
    ];
    this.uniqueClientName.forEach((client) => {
      options.push({ value: client, text: client });
    });
    return options;
  }
  //Sprint-24 TTD-116
	private get types(): DropdownModel[] {
		let options: DropdownModel[] = [];
		options = CertificateType.getCertificatesType();
		options.splice(0, 0, { value: "", text: this.$t(`pages.compliance.all_types`).toString()});
		return options;
	}

  private async getCertificates(): Promise<void> {
    try {
      this.closeModal();
      this.loadingCertificate = true;
      const user = this.$store.getters.user as UserModel;
      if(this.socialCertificate.length === 0 || this.isCertificateUploaded){
        this.certificates.social =
        await this.certificateService.getSocialCertificatesAsync(
          user.companyId
        );
        this.$store.commit(ActionTypes.SET_SOCIAL_CERTIFICATES, this.certificates.social);
      }
      else{
        this.certificates.social = this.socialCertificate;
      }
      if(this.sustainableCertificate.length === 0 || this.isCertificateUploaded){
        this.isCertificateUploaded = false;
        this.certificates.sustainable =
        await this.certificateService.getSustainableCertificatesAsync(
          user.companyId
        );
        this.$store.commit(ActionTypes.SET_SUSTAINABLE_CERTIFICATES, this.certificates.sustainable);
      }
      else{
        this.certificates.sustainable = this.sustainableCertificate;
      }
      if(this.certificates.social.length > 0){
        this.isCertificateUploaded = false;
        this.certificates.social.forEach(s=> {
          s.isShowArchive = false;
          s.relatableType = CertificateTypes.SOCIAL;
          s.isArchiving = false;
          s.expiryInfo = '';
          s.expiryStatus = '';
        });
      }
      if(this.certificates.sustainable.length > 0){
        this.certificates.sustainable.forEach(s =>{
          s.isShowArchive = false;
          s.relatableType = CertificateTypes.SUSTAINABILITY;
          s.isArchiving = false;
          s.expiryInfo = '';
          s.expiryStatus = '';
        });
      }
      this.certificatesSecondary.sustainable = this.certificates.sustainable;
      this.certificatesSecondary.social = this.certificates.social;
    } finally {
      this.loadingCertificate = false;
    }
  }

  private async getDocuments(): Promise<void> {
    try {
      this.loadingDoc = true;
      this.documents = await this.complianceService.getDocumentsAsync();
      this.documents.sort(function (
        a: ComplianceDocResponseModel,
        b: ComplianceDocResponseModel
      ) {
        return a.createdAt > b.createdAt
          ? -1
          : a.createdAt < b.createdAt
          ? 1
          : 0;
      });
    } finally {
      this.loadingDoc = false;
    }
  }

  private get title(): string[] {
    return [
      i18n.t('pages.compliance.compliance_tab_certificates').toString(),
      i18n.t('pages.compliance.compliance_tab_document_to_signed').toString(),
      i18n.t('pages.compliance.compliance_tab_document_archived').toString(),
    ];
  }

  private get uploadCertificatePermission(): number {
    return this.userPermissions !== null
      ? this.userPermissions.uploadCertificates
      : 0;
  }

  private selectedTab(index: number): void {
    this.tabIndex = index;
    if (index == 0) {
      this.getCertificates();
    }
  }

  private openModal(): void {
    this.showUploadModal = true;
  }

  private closeModal(): void {
    this.showUploadModal = false;
  }

  private async checkNonRomanChar(val:string): Promise<void>{
    this.isNonRomanChar = await NonRomanCharCheckHelper.checkNonRomanChar(val)
  }

  //Sprint-24 TTD-116
	private clearFilterShow(): void{
		this.showClearFilter = true;
	}
	//Sprint-24 TTD-116
	private clearAllFilters(): void {
		this.selectedBrand = '';
		this.selectedType = '';
		this.searchFilter = '';
		this.showClearFilter = false;
    this.selectedClient = '';
	}

  private getUploadedCertificates(){
    this.isCertificateUploaded = true;
    this.getCertificates();
  }

  @Watch('searchFilter')
  private updateDefaultValue(): void {
    if (this.searchFilter && this.searchFilter !== '') {
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      this.certificates.social = this.certificatesSecondary.social.filter((s)=>s.fileName.toLowerCase().includes(lowerCaseFilter) || s.issuer.toLowerCase().includes(lowerCaseFilter) || s.licenseID.toLowerCase().includes(lowerCaseFilter) || s.referenceIssuer.toLowerCase().includes(lowerCaseFilter));
      this.certificates.sustainable = this.certificatesSecondary.sustainable.filter((s)=>s.fileName.toLowerCase().includes(lowerCaseFilter) || s.issuer.toLowerCase().includes(lowerCaseFilter) || s.licenseID.toLowerCase().includes(lowerCaseFilter) || s.referenceIssuer.toLowerCase().includes(lowerCaseFilter));
    } else {
      this.certificates.social = this.certificatesSecondary.social;
      this.certificates.sustainable = this.certificatesSecondary.sustainable;
    }
  }
}
