export class InvitedSupplierResponseModel {
  public result!: string;
  public message!: string;
  public invitedSupplierResponse!: InvitedSupplierModel;
}

export class InvitedSupplierModel {
  public id!: string;
  public invitedCompanyId: string;
  public mainContact: string;
  public companyName: string;
  public country: string;
  public isTier1: boolean;
  public isExistingSupplier!: boolean;
  public invitedPersonEmailID!: string;
  public isCompliant!: boolean;
  public onBoarded!: boolean;
  public isAgent!: boolean;
  public companyEmail!: string;
  public firstName!: string;
  public lastName!: string;
  public isDelegatePartner!: boolean;

  public constructor() {
    this.id = '';
    this.isExistingSupplier = false;
    this.invitedCompanyId = '';
    this.mainContact = '';
    this.companyName = '';
    this.country = '';
    this.isTier1 = false;
    this.invitedPersonEmailID = '';
  }
}

export class LinkSupplierResponeModel {
  public result!: string;
}

export class AddSecondUserResponeModel {
  public result!: string;
  public message!: string;
  public Exceptionmessage!: string;
}
