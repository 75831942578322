import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import VuePdfApp from 'vue-pdf-app';
import lodash from "lodash";

// Model
import { ComplianceDocResponseModel } from '@/models/complianceDocResponseModel';
import { mapState } from 'vuex';
import moment from 'moment';
import { CompanyNameModel } from '@/models/companyModel';

@Component({
  components: { VuePdfApp },
  computed: mapState(['companyNameList']),
})
export default class ViewOriginalDocuments extends Vue {
  @Prop()
  private document!: ComplianceDocResponseModel;

  private pdfDocument!: any;
  private isPdfReady: boolean = false;
  private isDownloading: boolean = false;
  private config: any = {
    toolbar: false,
  };
  private companyNameList!: CompanyNameModel[];

  public constructor() {
    super();
  }

  private get brands(): string {
    if (this.document.brands && this.document.brands.length > 0) {
      const uniqBrands = lodash.uniq(this.document.brands);
      return uniqBrands.join(', ');
    }
    return '';
  }

  private formatDate(dateToBeFormatted: string): string {
    if (dateToBeFormatted==="9999-01-01T00:00:00Z") {
      return "No deadline";
    }
    return moment(dateToBeFormatted).format('D MMMM YYYY');
  }

  private async downloadPDF(): Promise<void> {
    if (this.isPdfReady) {
      this.isDownloading = true;
      this.pdfDocument.contentDispositionFilename = this.document.documentName;
      this.pdfDocument.download();
      this.isDownloading = false;
    }
  }

  private async pdfReady(pdfApp: any): Promise<void> {
    this.isPdfReady = true;
    this.pdfDocument = pdfApp;
  }

  private partnerName(partnerId: string, currentName: string): string {
    return this.companyNameList.filter((c) => c.ID === partnerId).length > 0 ? this.companyNameList.filter((c) => c.ID === partnerId)[0].name : currentName;
  }

  @Emit()
  private closePdf(success: boolean): void {}
}
