// Sprint-26 TTD-3068
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import VuePdfApp from 'vue-pdf-app';
import { mapState } from 'vuex';
import { cloneDeep, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import dateTime from 'date-time';

// Components
import EsgFacilityModal from '@/views/esg/components/esgFacilityModal.vue';
import ViewCertificatesUploaded from '@/views/compliance/components/viewCertificatesUploaded.vue';
import EsgUploadDocModal from '@/views/esg/components/esgUploadDocModal.vue';
import ViewDocuments from '@/views/esg/components/viewDocuments.vue';
import uploadCertificates from "@/views/esg/components/uploadCertificates.vue";

// Services
import { SupplierService } from '@/services/supplierService';
import { ComplianceCertificatesService } from '@/services/complianceCertificatesService';

// Modals
import { EsgAddressStatus, EsgAuditCertificate, EsgDocsModel, EsgFacilityAddressInfo, EsgIdTextAnsModel, EsgSocialAuditCert, EsgSocialAuditCertModel, EsgSocialDocUrlReq, EsgSocialModel, EsgStatus, EsgUploadDocModel } from '@/models/esgModel';
import { UserModel } from '@/models/userModel';
import { AddressModel } from '@/models/addressModel';
import moment from 'moment';
import { CertificateModel, CertificateOnTypeModel, CertificateTypes } from '@/models/certificateModel';
import { CompanyModel } from '@/models/companyModel';
import store from '@/store';

@Component({
  components: {
    VuePdfApp, EsgFacilityModal, ViewCertificatesUploaded, EsgUploadDocModal, ViewDocuments, uploadCertificates
  },
  computed: mapState(['userRole', 'company', 'companyType', 'user', 'socialCertificate', 'sustainableCertificate', 'esgSocial']),
})
export default class EsgDataSocial extends Vue {

  @Prop()
  private activeAddress!: AddressModel;

  @Prop()
  private activeAddEsgSocial!: EsgSocialModel;

  @Prop()
  private showConfirmSave!: boolean;

  @Prop()
  private auditTypes!: CertificateOnTypeModel[];

  private supplierService: SupplierService;
  private certificateService: ComplianceCertificatesService;

  private user!: UserModel;
  private userRole!: string;
  private company!: CompanyModel;
  private socialCertificate!: CertificateModel[];
  private sustainableCertificate!: CertificateModel[];
  private esgSocial!: EsgSocialModel[];

  private socialData: EsgSocialModel = new EsgSocialModel();
  private socialDataClone: EsgSocialModel = new EsgSocialModel();
  private changedkeys: string[] = [];
  private counter: number = 0;
  private isSaving: boolean = false;
  private saCorrActionTaken: EsgIdTextAnsModel = new EsgIdTextAnsModel();
  private saCorrActionTakenClone: EsgIdTextAnsModel = new EsgIdTextAnsModel();
  private auditCertAllSelected: boolean = false;
  private auditCerts: CertificateModel[] = [];
  private companyAddresses: AddressModel[] = [];
  private isAuditCertModalVisible: boolean = false;
  private isFacilityModalVisible: boolean = false;
  private documentDetail: CertificateModel = new CertificateModel();
  private showPDFModal: boolean = false;
  private uploadType: string = '';
  private isUploadDocModalVisible: boolean = false;
  private uploadDocKey: string = '';
  private uploadDocSelectedFacility: EsgAddressStatus[] = [];
  private viewDocFileName: string = '';
  private showViewDocument: boolean = false;
  private deleteDocIndex: number = -1;
  private deleteDocKey: string = '';
  private showDeleteDocModal: boolean = false;
  private showConfirmCancel: boolean = false;
  private esgDocUrl: string = '';
  private showUploadNewAuditCertModal: boolean = false;
  private auditDocs: EsgAuditCertificate[] = [];
  private isUploading: boolean = false;
  private totalEmployeeUpdated: boolean = false;
  private femaleEmployeeUpdated: boolean = false;
  private maleEmployeeUpdated: boolean = false;
  private showEmpOverrideModal: boolean = false;
  private showFemaleEmpOverrideModal: boolean = false;
  private showMaleEmpOverrideModal: boolean = false;

  public constructor() {
    super();
    this.supplierService = new SupplierService();
    this.certificateService = new ComplianceCertificatesService();
  }

  private async created(): Promise<void> {
    if(this.company !== null || this.company !== undefined){
      this.companyAddresses.push(...this.company.officeAddress.filter(add => add.addressStatus !== 'DISABLED'));
      if (this.company.factoryAddressDiffers) {
          this.companyAddresses.push(...this.company.factoryAddresses.filter(add => add.addressStatus !== 'DISABLED'));
      }
    }
    this.socialData = cloneDeep(this.activeAddEsgSocial);
    this.setFollowUpQues();
    this.parseNegativeValueToString();
    this.socialDataClone = cloneDeep(this.socialData);
    this.saCorrActionTakenClone = cloneDeep(this.saCorrActionTaken);
    this.toggleFirstIncompleteBeam();
    if(this.socialCertificate.length > 0 || this.sustainableCertificate.length > 0){
      this.auditCerts = [...this.socialCertificate.filter(s => this.auditTypes.findIndex(au => au.ID === s.type.toString())!==-1)];
      this.auditCerts = this.auditCerts.filter(ac => ac.status !== CertificateTypes.ARCHIVED);
    }
    this.counter = 0;
    this.changedkeys = [];
  }

  private get notStartedStatus(): string {
    return '';
  }

  private get inProgressStatus(): string {
    return EsgStatus.INPROGRESS;
  }

  private get completedStatus(): string {
    return EsgStatus.COMPLETED;
  }

  private get yes(): string {
    return EsgStatus.YES;
  }

  private get no(): string {
    return EsgStatus.NO;
  }

  private get dataUpdatedAt(): string {
    return this.socialData.sdLastUpdatedAt !== '' ? moment(this.socialData.sdLastUpdatedAt).format('DD MMM YYYY') : '';
  }

  private get defaultDate(): string {
    return '0001-01-01T00:00:00Z';
  }

  private get isReadOnly(): boolean {
    return this.userRole === 'Reader';
  }

  private get getSocialAudit(): CertificateModel[] {
    let socialAuditCerts: CertificateModel[] = [];
    this.socialData.socialCert.forEach(cert => {
      socialAuditCerts.push(...this.socialCertificate.filter(sc => sc.ID === cert.ID));
    })
    socialAuditCerts = uniqBy(socialAuditCerts, 'ID');
    socialAuditCerts.forEach(ac => {
      const daysDifference = this.getDateDifference(ac.validtoDate);
            if (daysDifference <= 30) {
              // if(daysDifference > 0){
              //   ac.expiryInfo = `${this.$t(
              //         'pages.compliance.uploaded_certs_expiring'
              //       )} ${daysDifference} ${this.$t(
              //         'pages.compliance.uploaded_certs_expiring_days'
              //       )}`
              //   if(daysDifference === 1){
              //     ac.expiryInfo = `Expiring in ${daysDifference} day`
              //   }
              // }
              // else
               if (daysDifference >= 0 ){
                ac.expiryInfo = 'Expiring'
              }
              else{
                ac.expiryInfo = 'Expired';
              }
              ac.expiryStatus =
                daysDifference >= 0 ? `expiring` : `expired`;
              }
    });
    return socialAuditCerts;
  }

  private formattedDate(date: string, dateFormat: string): string {
    return moment(date).format(dateFormat);
  }

  private getCertName(type: number): string{
    return this.auditTypes.find(a => a.ID === type.toString())!.Name;
  }

  private async openDoc(doc: EsgDocsModel, key: string): Promise<void> {
    await this.getDocUrl(doc, key);
    this.viewDocFileName = doc.fileName;
    this.showViewDocument = true;
  }

  private closeViewDocument(status: boolean): void {
    this.showViewDocument = status;
  }

  private async downloadDoc(doc: EsgDocsModel, key: string): Promise<void> {
    await this.getDocUrl(doc, key);
    const docElem = document.getElementById('esgDoc');
    if(docElem){
      docElem.click();
    }
  }

  private async getDocUrl(doc: EsgDocsModel, key: string): Promise<void> {
    const index = this.esgSocial.findIndex(s => s.addressID === this.activeAddress.id);
    if(index !== -1){
      const data: EsgSocialDocUrlReq = {
        ID: this.esgSocial[index].ID,
        companyID: this.user.companyId,
        socialDataDocType: key,
        socialDataDocTypeID: doc.ID
      }
      const response = await this.supplierService.getDocUrlAsync(data);
      if(response.success){
        this.esgDocUrl = response.socialDoc[0].docUrl;
      }
    }
  }

  private showAuditCertModal(status: boolean): void {
    if(this.isReadOnly){
      return;
    } else {
      if(status){
        if(this.auditCerts.length > 0){
          this.auditCerts.forEach(ac => {
            ac.isSelected = false;
            const daysDifference = this.getDateDifference(ac.validtoDate);
            if (daysDifference <= 30) {
              if(daysDifference > 0){
                ac.expiryInfo = `${this.$t(
                      'pages.compliance.uploaded_certs_expiring'
                    )} ${daysDifference} ${this.$t(
                      'pages.compliance.uploaded_certs_expiring_days'
                    )}`
                if(daysDifference === 1){
                  ac.expiryInfo = `Expiring in ${daysDifference} day`
                }
              }
              else if (daysDifference == 0 ){
                ac.expiryInfo = 'Expiring'
              }
              else{
                ac.expiryInfo = 'Expired';
              }
              ac.expiryStatus =
                daysDifference >= 0 ? `expiring` : `expired`;
              }
          });
          this.auditCerts = this.auditCerts.filter(ac => ac.expiryInfo !== 'Expired');
          if(this.auditCerts.length === 0){
            this.uploadAuditCert();
          }else{
            this.auditCertAllSelected = false;
            this.isAuditCertModalVisible = true;
          }
        } else {
          this.uploadAuditCert();
        }
      } else {
        this.isAuditCertModalVisible = false;
        this.isFacilityModalVisible = false;
      }
    }
  }

  private selectUnselectAuditCert(index: number, value: boolean): void {
    if(this.isReadOnly || this.isUploading){
      return;
    }
    if(this.socialData.socialCert.findIndex(sc => sc.ID === this.auditCerts[index].ID) === -1){
      this.auditCerts[index].isSelected = value;
      this.auditCerts.splice(index, 1, this.auditCerts[index]);
      this.auditCertAllSelected = this.auditCerts.every(a => a.isSelected) ? true : false;
    }
  }

  private isAuditCertlinked(docID: string): boolean {
    return this.socialData.socialCert.findIndex(sc => sc.ID === docID) !== -1 ? true : false;
  }

  private uploadAuditCert(): void {
    if(this.isReadOnly || this.isUploading){
      return;
    }
    this.uploadType = "NEW-AUDIT";
    this.isAuditCertModalVisible = false;
    this.isFacilityModalVisible = false;
    this.showUploadNewAuditCertModal = true;
  }

  private closeUploadNewModal(): void {
    this.showUploadNewAuditCertModal = false;
  }

  private showFacilityModal(status: boolean, type: string): void {
    if(type === 'AUDIT'){
      this.auditCerts.filter(cert => cert.isSelected === true).forEach(cert => {
        const doc: EsgAuditCertificate = new EsgAuditCertificate();
        doc.complianceDocID = cert.ID;
        doc.type = cert.type.toString();
        this.auditDocs.push(doc);
      });
      if(this.companyAddresses.length > 1){
        this.isFacilityModalVisible = status;
      } else {
        this.saveAuditCert();
      }
      if(!status){
        this.isAuditCertModalVisible = true;
      } else {
        this.isAuditCertModalVisible = false;
      }
      this.uploadType = type;
    } else {
      this.isFacilityModalVisible = status;
      this.uploadType = type;
    }
  }

  private get isSelectFacilityDisabled(): boolean {
    const count = this.auditCerts.filter(a => a.isSelected).length;
    return count === 0 ? true : false;
  }

  private selectAllAuditCert(value: boolean): void {
    if(this.isReadOnly || this.isUploading){
      return;
    }
    if(value){
      const certs: CertificateModel[] = [];
      this.auditCerts.forEach(a => {
        if(this.socialData.socialCert.findIndex(sc => sc.ID === a.ID) === -1){
          a.isSelected = true
          certs.push(a);
        } else {
          a.isSelected = false;
          certs.push(a);
        }
      });
      this.auditCerts = [];
      this.auditCerts.push(...certs);
      this.auditCertAllSelected = true;
    } else {
      const certs: CertificateModel[] = [];
      this.auditCerts.forEach(a => {
        a.isSelected = false
        certs.push(a);
      });
      this.auditCerts = [];
      this.auditCerts.push(...certs);
      this.auditCertAllSelected = false;
    }
  }

  private async socialAuditUploaded(auditCerts: EsgSocialAuditCert): Promise<void> {
    if(this.socialCertificate.findIndex(sc => sc.ID === auditCerts.certificates[0].complianceDocID) === -1){
      const socialCertificates = await this.certificateService.getSocialCertificatesAsync(this.user.companyId);
      store.commit('setSocialCertificates', socialCertificates);
    }
    auditCerts.certificates.forEach(cert => {
      const uploadedCerts: EsgSocialAuditCertModel = new EsgSocialAuditCertModel();
      uploadedCerts.ID = cert.complianceDocID;
      uploadedCerts.type = cert.type;
      uploadedCerts.updatedAt = auditCerts.createdAt;
      uploadedCerts.updatedBy = auditCerts.createdBy;
      this.socialData.socialCert.push(uploadedCerts);
    });
    this.isAuditCertModalVisible = false;
    this.isFacilityModalVisible = false;
    this.counter++;
    this.checkAllSectionStatus();
  }

  private toggleFirstIncompleteBeam(): void {
    if(this.socialData.socialAuditStatus !== this.completedStatus){
      this.socialData.socialAuditExpanded = true;
      this.socialData.healthAndSafetyExpanded = false;
      this.socialData.diversityAndEqualOptyExpanded = false;
      this.socialData.livingWageWorkingHoursExpanded = false;
      this.socialData.childForcedLabourExpanded = false;
      this.socialData.discriminationExpanded = false;
      this.socialData.workingConditionsExpanded = false;
      this.socialData.sexualHarasmentExpanded = false;
      this.socialData.grievanceMechanismExpanded = false;
    } else if(this.socialData.healthAndSafetyStatus !== this.completedStatus){
      this.socialData.socialAuditExpanded = false;
      this.socialData.healthAndSafetyExpanded = true;
      this.socialData.diversityAndEqualOptyExpanded = false;
      this.socialData.livingWageWorkingHoursExpanded = false;
      this.socialData.childForcedLabourExpanded = false;
      this.socialData.discriminationExpanded = false;
      this.socialData.workingConditionsExpanded = false;
      this.socialData.sexualHarasmentExpanded = false;
      this.socialData.grievanceMechanismExpanded = false;
    } else if(this.socialData.diversityAndEqualOptyStatus !== this.completedStatus){
      this.socialData.socialAuditExpanded = false;
      this.socialData.healthAndSafetyExpanded = false;
      this.socialData.diversityAndEqualOptyExpanded = true;
      this.socialData.livingWageWorkingHoursExpanded = false;
      this.socialData.childForcedLabourExpanded = false;
      this.socialData.discriminationExpanded = false;
      this.socialData.workingConditionsExpanded = false;
      this.socialData.sexualHarasmentExpanded = false;
      this.socialData.grievanceMechanismExpanded = false;
    } else if(this.socialData.livingWageWorkingHoursStatus !== this.completedStatus){
      this.socialData.socialAuditExpanded = false;
      this.socialData.healthAndSafetyExpanded = false;
      this.socialData.diversityAndEqualOptyExpanded = false;
      this.socialData.livingWageWorkingHoursExpanded = true;
      this.socialData.childForcedLabourExpanded = false;
      this.socialData.discriminationExpanded = false;
      this.socialData.workingConditionsExpanded = false;
      this.socialData.sexualHarasmentExpanded = false;
      this.socialData.grievanceMechanismExpanded = false;
    } else if(this.socialData.childForcedLabourStatus !== this.completedStatus){
      this.socialData.socialAuditExpanded = false;
      this.socialData.healthAndSafetyExpanded = false;
      this.socialData.diversityAndEqualOptyExpanded = false;
      this.socialData.livingWageWorkingHoursExpanded = false;
      this.socialData.childForcedLabourExpanded = true;
      this.socialData.discriminationExpanded = false;
      this.socialData.workingConditionsExpanded = false;
      this.socialData.sexualHarasmentExpanded = false;
      this.socialData.grievanceMechanismExpanded = false;
    } else if(this.socialData.discriminationStatus !== this.completedStatus){
      this.socialData.socialAuditExpanded = false;
      this.socialData.healthAndSafetyExpanded = false;
      this.socialData.diversityAndEqualOptyExpanded = false;
      this.socialData.livingWageWorkingHoursExpanded = false;
      this.socialData.childForcedLabourExpanded = false;
      this.socialData.discriminationExpanded = true;
      this.socialData.workingConditionsExpanded = false;
      this.socialData.sexualHarasmentExpanded = false;
      this.socialData.grievanceMechanismExpanded = false;
    } else if(this.socialData.workingConditionsStatus !== this.completedStatus){
      this.socialData.socialAuditExpanded = false;
      this.socialData.healthAndSafetyExpanded = false;
      this.socialData.diversityAndEqualOptyExpanded = false;
      this.socialData.livingWageWorkingHoursExpanded = false;
      this.socialData.childForcedLabourExpanded = false;
      this.socialData.discriminationExpanded = false;
      this.socialData.workingConditionsExpanded = true;
      this.socialData.sexualHarasmentExpanded = false;
      this.socialData.grievanceMechanismExpanded = false;
    } else if(this.socialData.sexualHarasmentStatus !== this.completedStatus){
      this.socialData.socialAuditExpanded = false;
      this.socialData.healthAndSafetyExpanded = false;
      this.socialData.diversityAndEqualOptyExpanded = false;
      this.socialData.livingWageWorkingHoursExpanded = false;
      this.socialData.childForcedLabourExpanded = false;
      this.socialData.discriminationExpanded = false;
      this.socialData.workingConditionsExpanded = false;
      this.socialData.sexualHarasmentExpanded = true;
      this.socialData.grievanceMechanismExpanded = false;
    } else if(this.socialData.grievanceMechanismStatus !== this.completedStatus){
      this.socialData.socialAuditExpanded = false;
      this.socialData.healthAndSafetyExpanded = false;
      this.socialData.diversityAndEqualOptyExpanded = false;
      this.socialData.livingWageWorkingHoursExpanded = false;
      this.socialData.childForcedLabourExpanded = false;
      this.socialData.discriminationExpanded = false;
      this.socialData.workingConditionsExpanded = false;
      this.socialData.sexualHarasmentExpanded = false;
      this.socialData.grievanceMechanismExpanded = true;
    }
  }

  private parseNegativeValueToString(): void {
    this.socialData.sddeoQ04.totalEmployees.value = (this.socialData.sddeoQ04.totalEmployees.value !== -1 && this.socialData.sddeoQ04.totalEmployees.value.toString() !== '') ? this.socialData.sddeoQ04.totalEmployees.value : (this.activeAddress.employees !== -1 ? this.activeAddress.employees : '');
    this.socialData.sddeoQ04.femaleEmployees.value = (this.socialData.sddeoQ04.femaleEmployees.value !== -1 && this.socialData.sddeoQ04.femaleEmployees.value.toString() !== '')? this.socialData.sddeoQ04.femaleEmployees.value : (this.activeAddress.femaleEmployees !== -1 ? this.activeAddress.femaleEmployees : '');
    this.socialData.sddeoQ04.maleEmployees.value = (this.socialData.sddeoQ04.maleEmployees.value !== -1 && this.socialData.sddeoQ04.maleEmployees.value.toString() !== '')? this.socialData.sddeoQ04.maleEmployees.value : (this.activeAddress.maleEmployees !== -1 ? this.activeAddress.maleEmployees : '');
    this.socialData.sddeoQ05.value = this.socialData.sddeoQ05.value !== -1 ? this.socialData.sddeoQ05.value : '';
    this.socialData.sddeoQ06.value = this.socialData.sddeoQ06.value !== -1 ? this.socialData.sddeoQ06.value : '';
    this.socialData.sdwccQ05.value = this.socialData.sdwccQ05.value !== -1 ? this.socialData.sdwccQ05.value : '';
    this.socialData.sdwccQ06.value = this.socialData.sdwccQ06.value !== -1 ? this.socialData.sdwccQ06.value : '';
  }

  private setFollowUpQues(): void {
    this.setSAFollowUpQues();
    this.setHsFollowUpQues();
    this.setDeoFollowUpQues();
    this.setLwwFollowUpQues();
    this.setCflFollowUpQues();
    this.setWccFollowUpQues();
    this.setDisFollowUpQues();
    this.setShmFollowUpQues();
    this.$forceUpdate();
    this.checkAllSectionStatus();
  }

  private setSAFollowUpQues(): void {
    if(this.socialData.sdsaQ03Docs.length > 0){
      this.socialData.sdsaQ03.value = this.yes;
      this.socialData.sdsaQ03.updatedAt = new Date().toISOString();
      this.socialData.sdsaQ03.updatedBy = this.user.userId;
      this.socialData.sdsaQ01.value = this.yes;
      this.socialData.sdsaQ01.updatedAt = new Date().toISOString();
      this.socialData.sdsaQ01.updatedBy = this.user.userId;
    } else if (this.socialData.socialCert.length > 0){
      this.socialData.sdsaQ01.value = this.yes;
      this.socialData.sdsaQ01.updatedAt = new Date().toISOString();
      this.socialData.sdsaQ01.updatedBy = this.user.userId;
    }
  }

  private setHsFollowUpQues(): void {
    if(this.socialData.sdhsQ04.length > 0){
      this.socialData.sdhsQ03.value = this.yes;
      this.socialData.sdhsQ03.updatedAt = new Date().toISOString();
      this.socialData.sdhsQ03.updatedBy = this.user.userId;
      this.socialData.sdhsQ01.value = this.yes;
      this.socialData.sdhsQ01.updatedAt = new Date().toISOString();
      this.socialData.sdhsQ01.updatedBy = this.user.userId;
    } else if (this.socialData.sdhsQ02.length > 0){
      this.socialData.sdhsQ01.value = this.yes;
      this.socialData.sdhsQ01.updatedAt = new Date().toISOString();
      this.socialData.sdhsQ01.updatedBy = this.user.userId;
    }
  }

  private setDeoFollowUpQues(): void {
    if(this.socialData.sddeoQ03.length > 0){
      this.socialData.sddeoQ02.value = this.yes;
      this.socialData.sddeoQ02.updatedAt = new Date().toISOString();
      this.socialData.sddeoQ02.updatedBy = this.user.userId;
      this.socialData.sddeoQ01.value = this.yes;
      this.socialData.sddeoQ01.updatedAt = new Date().toISOString();
      this.socialData.sddeoQ01.updatedBy = this.user.userId;
    } else if(this.socialData.sddeoQ01Docs.length > 0){
      this.socialData.sddeoQ01.value = this.yes;
      this.socialData.sddeoQ01.updatedAt = new Date().toISOString();
      this.socialData.sddeoQ01.updatedBy = this.user.userId;
    }
  }

  private setLwwFollowUpQues(): void {
    if(this.socialData.sdwccQ03.length > 0){
      this.socialData.sdwccQ02.value = this.yes;
      this.socialData.sdwccQ02.updatedAt = new Date().toISOString();
      this.socialData.sdwccQ02.updatedBy = this.user.userId;
      this.socialData.sdwccQ01.value = this.yes;
      this.socialData.sdwccQ01.updatedAt = new Date().toISOString();
      this.socialData.sdwccQ01.updatedBy = this.user.userId;
    } else if(this.socialData.sdwccQ01Docs.length > 0){
      this.socialData.sdwccQ01.value = this.yes;
      this.socialData.sdwccQ01.updatedAt = new Date().toISOString();
      this.socialData.sdwccQ01.updatedBy = this.user.userId;
    }
  }

  private setCflFollowUpQues(): void {
    if(this.socialData.sdcflQ03.length > 0){
      this.socialData.sdcflQ02.value = this.yes;
      this.socialData.sdcflQ02.updatedAt = new Date().toISOString();
      this.socialData.sdcflQ02.updatedBy = this.user.userId;
      this.socialData.sdcflQ01.value = this.yes;
      this.socialData.sdcflQ01.updatedAt = new Date().toISOString();
      this.socialData.sdcflQ01.updatedBy = this.user.userId;
    } else if(this.socialData.sdcflQ01Docs.length > 0){
      this.socialData.sdcflQ01.value = this.yes;
      this.socialData.sdcflQ01.updatedAt = new Date().toISOString();
      this.socialData.sdcflQ01.updatedBy = this.user.userId;
    }
  }

  private setWccFollowUpQues(): void {
    if(this.socialData.sdwccQ03.length > 0){
      this.socialData.sdwccQ02.value = this.yes;
      this.socialData.sdwccQ02.updatedAt = new Date().toISOString();
      this.socialData.sdwccQ02.updatedBy = this.user.userId;
      this.socialData.sdwccQ01.value = this.yes;
      this.socialData.sdwccQ01.updatedAt = new Date().toISOString();
      this.socialData.sdwccQ01.updatedBy = this.user.userId;
    } else if(this.socialData.sdwccQ01Docs.length > 0){
      this.socialData.sdwccQ01.value = this.yes;
      this.socialData.sdwccQ01.updatedAt = new Date().toISOString();
      this.socialData.sdwccQ01.updatedBy = this.user.userId;
    }
  }

  private setDisFollowUpQues(): void {
    if(this.socialData.sddisQ03.length > 0){
      this.socialData.sddisQ02.value = this.yes;
      this.socialData.sddisQ02.updatedAt = new Date().toISOString();
      this.socialData.sddisQ02.updatedBy = this.user.userId;
      this.socialData.sddisQ01.value = this.yes;
      this.socialData.sddisQ01.updatedAt = new Date().toISOString();
      this.socialData.sddisQ01.updatedBy = this.user.userId;
    } else if(this.socialData.sddisQ01Docs.length > 0){
      this.socialData.sddisQ01.value = this.yes;
      this.socialData.sddisQ01.updatedAt = new Date().toISOString();
      this.socialData.sddisQ01.updatedBy = this.user.userId;
    }
  }

  private setShmFollowUpQues(): void {
    if(this.socialData.sdshmQ03.length > 0){
      this.socialData.sdshmQ02.value = this.yes;
      this.socialData.sdshmQ02.updatedAt = new Date().toISOString();
      this.socialData.sdshmQ02.updatedBy = this.user.userId;
      this.socialData.sdshmQ01.value = this.yes;
      this.socialData.sdshmQ01.updatedAt = new Date().toISOString();
      this.socialData.sdshmQ01.updatedBy = this.user.userId;
    } else if(this.socialData.sdshmQ01Docs.length > 0){
      this.socialData.sdshmQ01.value = this.yes;
      this.socialData.sdshmQ01.updatedAt = new Date().toISOString();
      this.socialData.sdshmQ01.updatedBy = this.user.userId;
    }
  }

  private updateValue(key: string, value: string): void {
    switch (key) {
      case 'sdsaQ01':
        this.socialData.sdsaQ01.value = value;
        this.socialData.sdsaQ01.updatedAt = new Date().toISOString();
        this.socialData.sdsaQ01.updatedBy = this.user.userId;
        if(this.socialData.sdsaQ01.value !== this.yes){
          this.socialData.sdsaQ02.value = '';
          this.socialData.sdsaQ02.updatedAt = new Date().toISOString();
          this.socialData.sdsaQ02.updatedBy = this.user.userId;
          this.socialData.sdsaQ03.value = '';
          this.socialData.sdsaQ03.updatedAt = new Date().toISOString();
          this.socialData.sdsaQ03.updatedBy = this.user.userId;
          // this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ02'), 1);
          // this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ03'), 1);
          // this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ04'), 1);
          // this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ05'), 1);
        } else if(this.socialData.sdsaQ01.value !== this.no) {
          this.socialData.sdsaQ06.value = '';
          this.socialData.sdsaQ06.updatedAt = new Date().toISOString();
          this.socialData.sdsaQ06.updatedBy = this.user.userId;
          // this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ06'), 1);
        }
        if (
          this.socialData.sdsaQ01.value !==
            this.socialDataClone.sdsaQ01.value &&
          !this.changedkeys.includes('sdsaQ01')
        ) {
          this.changedkeys.push('sdsaQ01');
          this.counter++;
        } else if (
          this.socialData.sdsaQ01.value ===
            this.socialDataClone.sdsaQ01.value &&
          this.changedkeys.includes('sdsaQ01')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ01'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkSAStatus();
        break;

      case 'sdsaQ02':
        this.socialData.sdsaQ02.value = value;
        this.socialData.sdsaQ02.updatedAt = new Date().toISOString();
        this.socialData.sdsaQ02.updatedBy = this.user.userId;
        if (
          this.socialData.sdsaQ02.value !==
            this.socialDataClone.sdsaQ02.value &&
          !this.changedkeys.includes('sdsaQ02')
        ) {
          this.changedkeys.push('sdsaQ02');
          this.counter++;
        } else if (
          this.socialData.sdsaQ02.value ===
            this.socialDataClone.sdsaQ02.value &&
          this.changedkeys.includes('sdsaQ02')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ02'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkSAStatus();
        break;

      case 'sdsaQ03':
        this.socialData.sdsaQ03.value = value;
        this.socialData.sdsaQ03.updatedAt = new Date().toISOString();
        this.socialData.sdsaQ03.updatedBy = this.user.userId;
        if (
          this.socialData.sdsaQ03.value !==
            this.socialDataClone.sdsaQ03.value &&
          !this.changedkeys.includes('sdsaQ03')
        ) {
          this.changedkeys.push('sdsaQ03');
          this.counter++;
        } else if (
          this.socialData.sdsaQ03.value ===
            this.socialDataClone.sdsaQ03.value &&
          this.changedkeys.includes('sdsaQ03')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ03'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkSAStatus();
        break;

      case 'sdsaQ04':
        this.socialData.sdsaQ04.updatedAt = new Date().toISOString();
        this.socialData.sdsaQ04.updatedBy = this.user.userId;
        if (
          this.socialData.sdsaQ04.value !==
            this.socialDataClone.sdsaQ04.value &&
          !this.changedkeys.includes('sdsaQ04')
        ) {
          this.changedkeys.push('sdsaQ04');
          this.counter++;
        } else if (
          this.socialData.sdsaQ04.value ===
            this.socialDataClone.sdsaQ04.value &&
          this.changedkeys.includes('sdsaQ04')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ04'), 1);
          this.counter--;
        } else {
          // no action
        }
        break;

      case 'sdsaQ05':
        this.saCorrActionTaken.updatedAt = dateTime();
        this.saCorrActionTaken.updatedBy = this.user.userId;
        if (
          this.saCorrActionTaken.text !==
            this.saCorrActionTakenClone.text &&
          !this.changedkeys.includes('sdsaQ05')
        ) {
          this.changedkeys.push('sdsaQ05');
          this.counter++;
        } else if (
          this.saCorrActionTaken.text ===
            this.saCorrActionTakenClone.text &&
          this.changedkeys.includes('sdsaQ05')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ05'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkSAStatus();
        break;

      case 'sdsaQ06':
        this.socialData.sdsaQ06.value = value;
        this.socialData.sdsaQ06.updatedAt = new Date().toISOString();
        this.socialData.sdsaQ06.updatedBy = this.user.userId;
        if (
          this.socialData.sdsaQ06.value !==
            this.socialDataClone.sdsaQ06.value &&
          !this.changedkeys.includes('sdsaQ06')
        ) {
          this.changedkeys.push('sdsaQ06');
          this.counter++;
        } else if (
          this.socialData.sdsaQ06.value ===
            this.socialDataClone.sdsaQ06.value &&
          this.changedkeys.includes('sdsaQ06')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ06'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkSAStatus();
        break;

      case 'sdhsQ01':
        this.socialData.sdhsQ01.value = value;
        this.socialData.sdhsQ01.updatedAt = new Date().toISOString();
        this.socialData.sdhsQ01.updatedBy = this.user.userId;
        if(this.socialData.sdhsQ01.value !== this.yes){
          this.socialData.sdhsQ03.value = '';
          this.socialData.sdhsQ03.updatedAt = new Date().toISOString();
          this.socialData.sdhsQ03.updatedBy = this.user.userId;
        }
        if (
          this.socialData.sdhsQ01.value !==
            this.socialDataClone.sdhsQ01.value &&
          !this.changedkeys.includes('sdhsQ01')
        ) {
          this.changedkeys.push('sdhsQ01');
          this.counter++;
        } else if (
          this.socialData.sdhsQ01.value ===
            this.socialDataClone.sdhsQ01.value &&
          this.changedkeys.includes('sdhsQ01')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdhsQ01'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkHsStatus();
        break;

      case 'sdhsQ03':
        this.socialData.sdhsQ03.value = value;
        this.socialData.sdhsQ03.updatedAt = new Date().toISOString();
        this.socialData.sdhsQ03.updatedBy = this.user.userId;
        if (
          this.socialData.sdhsQ03.value !==
            this.socialDataClone.sdhsQ03.value &&
          !this.changedkeys.includes('sdhsQ03')
        ) {
          this.changedkeys.push('sdhsQ03');
          this.counter++;
        } else if (
          this.socialData.sdhsQ03.value ===
            this.socialDataClone.sdhsQ03.value &&
          this.changedkeys.includes('sdhsQ03')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdhsQ03'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkHsStatus();
        break;

      case 'sdhsQ05':
        this.socialData.sdhsQ05.value = value;
        this.socialData.sdhsQ05.updatedAt = new Date().toISOString();
        this.socialData.sdhsQ05.updatedBy = this.user.userId;
        if (
          this.socialData.sdhsQ05.value !==
            this.socialDataClone.sdhsQ05.value &&
          !this.changedkeys.includes('sdhsQ05')
        ) {
          this.changedkeys.push('sdhsQ05');
          this.counter++;
        } else if (
          this.socialData.sdhsQ05.value ===
            this.socialDataClone.sdhsQ05.value &&
          this.changedkeys.includes('sdhsQ05')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdhsQ05'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkHsStatus();
        break;

      case 'sdhsQ06':
        this.socialData.sdhsQ06.value = value;
        this.socialData.sdhsQ06.updatedAt = new Date().toISOString();
        this.socialData.sdhsQ06.updatedBy = this.user.userId;
        if (
          this.socialData.sdhsQ06.value !==
            this.socialDataClone.sdhsQ06.value &&
          !this.changedkeys.includes('sdhsQ06')
        ) {
          this.changedkeys.push('sdhsQ06');
          this.counter++;
        } else if (
          this.socialData.sdhsQ06.value ===
            this.socialDataClone.sdhsQ06.value &&
          this.changedkeys.includes('sdhsQ06')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdhsQ06'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkHsStatus();
        break;

      case 'sddeoQ01':
        this.socialData.sddeoQ01.value = value;
        this.socialData.sddeoQ01.updatedAt = new Date().toISOString();
        this.socialData.sddeoQ01.updatedBy = this.user.userId;
        if(this.socialData.sddeoQ01.value !== this.yes){
          this.socialData.sddeoQ02.value = '';
          this.socialData.sddeoQ02.updatedAt = new Date().toISOString();
          this.socialData.sddeoQ02.updatedBy = this.user.userId;
        }
        if (
          this.socialData.sddeoQ01.value !==
            this.socialDataClone.sddeoQ01.value &&
          !this.changedkeys.includes('sddeoQ01')
        ) {
          this.changedkeys.push('sddeoQ01');
          this.counter++;
        } else if (
          this.socialData.sddeoQ01.value ===
            this.socialDataClone.sddeoQ01.value &&
          this.changedkeys.includes('sddeoQ01')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sddeoQ01'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkDeoStatus();
        break;

      case 'sddeoQ02':
        this.socialData.sddeoQ02.value = value;
        this.socialData.sddeoQ02.updatedAt = new Date().toISOString();
        this.socialData.sddeoQ02.updatedBy = this.user.userId;
        if (
          this.socialData.sddeoQ02.value !==
            this.socialDataClone.sddeoQ02.value &&
          !this.changedkeys.includes('sddeoQ02')
        ) {
          this.changedkeys.push('sddeoQ02');
          this.counter++;
        } else if (
          this.socialData.sddeoQ02.value ===
            this.socialDataClone.sddeoQ02.value &&
          this.changedkeys.includes('sddeoQ02')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sddeoQ02'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkDeoStatus();
        break;

      case 'sddeoQ04-te':
        this.socialData.sddeoQ04.totalEmployees.value = this.allowOnlyInteger(
          this.socialData.sddeoQ04.totalEmployees.value.toString()
        );
        if (this.socialData.sddeoQ04.totalEmployees.value !== '') {
          this.socialData.sddeoQ04.totalEmployees.value = Number(
            this.socialData.sddeoQ04.totalEmployees.value
          );
        }
        this.socialData.sddeoQ04.totalEmployees.updatedAt =
          new Date().toISOString();
        this.socialData.sddeoQ04.totalEmployees.updatedBy = this.user.userId;
        if (
          this.socialData.sddeoQ04.totalEmployees.value !==
            this.socialDataClone.sddeoQ04.totalEmployees.value &&
          !this.changedkeys.includes('sddeoQ04-te')
        ) {
          this.changedkeys.push('sddeoQ04-te');
          this.counter++;
        } else if (
          this.socialData.sddeoQ04.totalEmployees.value ===
            this.socialDataClone.sddeoQ04.totalEmployees.value &&
          this.changedkeys.includes('sddeoQ04-te')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sddeoQ04-te'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkDeoStatus();
        break;

      case 'sddeoQ04-fe':
        this.socialData.sddeoQ04.femaleEmployees.value = this.allowOnlyInteger(
          this.socialData.sddeoQ04.femaleEmployees.value.toString()
        );
        if (this.socialData.sddeoQ04.femaleEmployees.value !== '') {
          this.socialData.sddeoQ04.femaleEmployees.value = Number(
            this.socialData.sddeoQ04.femaleEmployees.value
          );
        }
        this.socialData.sddeoQ04.femaleEmployees.updatedAt =
          new Date().toISOString();
        this.socialData.sddeoQ04.femaleEmployees.updatedBy = this.user.userId;
        if (
          this.socialData.sddeoQ04.femaleEmployees.value !==
            this.socialDataClone.sddeoQ04.femaleEmployees.value &&
          !this.changedkeys.includes('sddeoQ04-fe')
        ) {
          this.changedkeys.push('sddeoQ04-fe');
          this.counter++;
        } else if (
          this.socialData.sddeoQ04.femaleEmployees.value ===
            this.socialDataClone.sddeoQ04.femaleEmployees.value &&
          this.changedkeys.includes('sddeoQ04-fe')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sddeoQ04-fe'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkDeoStatus();
        break;

      case 'sddeoQ04-me':
        this.socialData.sddeoQ04.maleEmployees.value = this.allowOnlyInteger(
          this.socialData.sddeoQ04.maleEmployees.value.toString()
        );
        if (this.socialData.sddeoQ04.maleEmployees.value !== '') {
          this.socialData.sddeoQ04.maleEmployees.value = Number(
            this.socialData.sddeoQ04.maleEmployees.value
          );
        }
        this.socialData.sddeoQ04.maleEmployees.updatedAt =
          new Date().toISOString();
        this.socialData.sddeoQ04.maleEmployees.updatedBy = this.user.userId;
        if (
          this.socialData.sddeoQ04.maleEmployees.value !==
            this.socialDataClone.sddeoQ04.maleEmployees.value &&
          !this.changedkeys.includes('sddeoQ04-me')
        ) {
          this.changedkeys.push('sddeoQ04-me');
          this.counter++;
        } else if (
          this.socialData.sddeoQ04.maleEmployees.value ===
            this.socialDataClone.sddeoQ04.maleEmployees.value &&
          this.changedkeys.includes('sddeoQ04-me')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sddeoQ04-me'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkDeoStatus();
        break;

      case 'sddeoQ05':
        this.socialData.sddeoQ05.value = this.allowOnlyInteger(
          this.socialData.sddeoQ05.value.toString()
        );
        if (this.socialData.sddeoQ05.value !== '') {
          this.socialData.sddeoQ05.value = Number(
            this.socialData.sddeoQ05.value
          );
        }
        this.socialData.sddeoQ05.updatedAt = new Date().toISOString();
        this.socialData.sddeoQ05.updatedBy = this.user.userId;
        if (
          this.socialData.sddeoQ05.value !==
            this.socialDataClone.sddeoQ05.value &&
          !this.changedkeys.includes('sddeoQ05')
        ) {
          this.changedkeys.push('sddeoQ05');
          this.counter++;
        } else if (
          this.socialData.sddeoQ05.value ===
            this.socialDataClone.sddeoQ05.value &&
          this.changedkeys.includes('sddeoQ05')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sddeoQ05'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkDeoStatus();
        break;

      case 'sddeoQ06':
        this.socialData.sddeoQ06.value = this.allowOnlyInteger(
          this.socialData.sddeoQ06.value.toString()
        );
        if (this.socialData.sddeoQ06.value !== '') {
          this.socialData.sddeoQ06.value = Number(
            this.socialData.sddeoQ06.value
          );
        }
        this.socialData.sddeoQ06.updatedAt = new Date().toISOString();
        this.socialData.sddeoQ06.updatedBy = this.user.userId;
        if (
          this.socialData.sddeoQ06.value !==
            this.socialDataClone.sddeoQ06.value &&
          !this.changedkeys.includes('sddeoQ06')
        ) {
          this.changedkeys.push('sddeoQ06');
          this.counter++;
        } else if (
          this.socialData.sddeoQ06.value ===
            this.socialDataClone.sddeoQ06.value &&
          this.changedkeys.includes('sddeoQ06')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sddeoQ06'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkDeoStatus();
        break;

      case 'sddeoQ07':
        this.socialData.sddeoQ07.value = value;
        this.socialData.sddeoQ07.updatedAt = new Date().toISOString();
        this.socialData.sddeoQ07.updatedBy = this.user.userId;
        if (
          this.socialData.sddeoQ07.value !==
            this.socialDataClone.sddeoQ07.value &&
          !this.changedkeys.includes('sddeoQ07')
        ) {
          this.changedkeys.push('sddeoQ07');
          this.counter++;
        } else if (
          this.socialData.sddeoQ07.value ===
            this.socialDataClone.sddeoQ07.value &&
          this.changedkeys.includes('sddeoQ07')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sddeoQ07'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkDeoStatus();
        break;

      case 'sdlwwQ01':
        this.socialData.sdlwwQ01.value = value;
        this.socialData.sdlwwQ01.updatedAt = new Date().toISOString();
        this.socialData.sdlwwQ01.updatedBy = this.user.userId;
        if(this.socialData.sdlwwQ01.value !== this.yes){
          this.socialData.sdlwwQ02.value = '';
          this.socialData.sdlwwQ02.updatedAt = new Date().toISOString();
          this.socialData.sdlwwQ02.updatedBy = this.user.userId;
        }
        if (
          this.socialData.sdlwwQ01.value !==
            this.socialDataClone.sdlwwQ01.value &&
          !this.changedkeys.includes('sdlwwQ01')
        ) {
          this.changedkeys.push('sdlwwQ01');
          this.counter++;
        } else if (
          this.socialData.sdlwwQ01.value ===
            this.socialDataClone.sdlwwQ01.value &&
          this.changedkeys.includes('sdlwwQ01')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdlwwQ01'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkLwwStatus();
        break;

      case 'sdlwwQ02':
        this.socialData.sdlwwQ02.value = value;
        this.socialData.sdlwwQ02.updatedAt = new Date().toISOString();
        this.socialData.sdlwwQ02.updatedBy = this.user.userId;
        if (
          this.socialData.sdlwwQ02.value !==
            this.socialDataClone.sdlwwQ02.value &&
          !this.changedkeys.includes('sdlwwQ02')
        ) {
          this.changedkeys.push('sdlwwQ02');
          this.counter++;
        } else if (
          this.socialData.sdlwwQ02.value ===
            this.socialDataClone.sdlwwQ02.value &&
          this.changedkeys.includes('sdlwwQ02')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdlwwQ02'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkLwwStatus();
        break;

      case 'sdlwwQ04':
        this.socialData.sdlwwQ04.value = value;
        this.socialData.sdlwwQ04.updatedAt = new Date().toISOString();
        this.socialData.sdlwwQ04.updatedBy = this.user.userId;
        if (
          this.socialData.sdlwwQ04.value !==
            this.socialDataClone.sdlwwQ04.value &&
          !this.changedkeys.includes('sdlwwQ04')
        ) {
          this.changedkeys.push('sdlwwQ04');
          this.counter++;
        } else if (
          this.socialData.sdlwwQ04.value ===
            this.socialDataClone.sdlwwQ04.value &&
          this.changedkeys.includes('sdlwwQ04')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdlwwQ04'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkLwwStatus();
        break;

      case 'sdlwwQ05':
        this.socialData.sdlwwQ05.value = value;
        this.socialData.sdlwwQ05.updatedAt = new Date().toISOString();
        this.socialData.sdlwwQ05.updatedBy = this.user.userId;
        if (
          this.socialData.sdlwwQ05.value !==
            this.socialDataClone.sdlwwQ05.value &&
          !this.changedkeys.includes('sdlwwQ05')
        ) {
          this.changedkeys.push('sdlwwQ05');
          this.counter++;
        } else if (
          this.socialData.sdlwwQ05.value ===
            this.socialDataClone.sdlwwQ05.value &&
          this.changedkeys.includes('sdlwwQ05')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdlwwQ05'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkLwwStatus();
        break;

      case 'sdlwwQ06':
        this.socialData.sdlwwQ06.value = this.allowOnlyCharacter(this.socialData.sdlwwQ06.value);
        this.socialData.sdlwwQ06.updatedAt = new Date().toISOString();
        this.socialData.sdlwwQ06.updatedBy = this.user.userId;
        if (
          this.socialData.sdlwwQ06.value !==
            this.socialDataClone.sdlwwQ06.value &&
          !this.changedkeys.includes('sdlwwQ06')
        ) {
          this.changedkeys.push('sdlwwQ06');
          this.counter++;
        } else if (
          this.socialData.sdlwwQ06.value ===
            this.socialDataClone.sdlwwQ06.value &&
          this.changedkeys.includes('sdlwwQ06')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdlwwQ06'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkLwwStatus();
        break;

      case 'sdlwwQ07':
        this.socialData.sdlwwQ07.value = value;
        this.socialData.sdlwwQ07.updatedAt = new Date().toISOString();
        this.socialData.sdlwwQ07.updatedBy = this.user.userId;
        if (
          this.socialData.sdlwwQ07.value !==
            this.socialDataClone.sdlwwQ07.value &&
          !this.changedkeys.includes('sdlwwQ07')
        ) {
          this.changedkeys.push('sdlwwQ07');
          this.counter++;
        } else if (
          this.socialData.sdlwwQ07.value ===
            this.socialDataClone.sdlwwQ07.value &&
          this.changedkeys.includes('sdlwwQ07')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdlwwQ07'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkLwwStatus();
        break;

      case 'sdlwwQ08':
        this.socialData.sdlwwQ08.value = value;
        this.socialData.sdlwwQ08.updatedAt = new Date().toISOString();
        this.socialData.sdlwwQ08.updatedBy = this.user.userId;
        if (
          this.socialData.sdlwwQ08.value !==
            this.socialDataClone.sdlwwQ08.value &&
          !this.changedkeys.includes('sdlwwQ08')
        ) {
          this.changedkeys.push('sdlwwQ08');
          this.counter++;
        } else if (
          this.socialData.sdlwwQ08.value ===
            this.socialDataClone.sdlwwQ08.value &&
          this.changedkeys.includes('sdlwwQ08')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdlwwQ08'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkLwwStatus();
        break;

      case 'sdcflQ01':
        this.socialData.sdcflQ01.value = value;
        this.socialData.sdcflQ01.updatedAt = new Date().toISOString();
        this.socialData.sdcflQ01.updatedBy = this.user.userId;
        if(this.socialData.sdcflQ01.value !== this.yes){
          this.socialData.sdcflQ02.value = '';
          this.socialData.sdcflQ02.updatedAt = new Date().toISOString();
          this.socialData.sdcflQ02.updatedBy = this.user.userId;
        }
        if (
          this.socialData.sdcflQ01.value !==
            this.socialDataClone.sdcflQ01.value &&
          !this.changedkeys.includes('sdcflQ01')
        ) {
          this.changedkeys.push('sdcflQ01');
          this.counter++;
        } else if (
          this.socialData.sdcflQ01.value ===
            this.socialDataClone.sdcflQ01.value &&
          this.changedkeys.includes('sdcflQ01')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdcflQ01'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkCflStatus();
        break;

      case 'sdcflQ02':
        this.socialData.sdcflQ02.value = value;
        this.socialData.sdcflQ02.updatedAt = new Date().toISOString();
        this.socialData.sdcflQ02.updatedBy = this.user.userId;
        if (
          this.socialData.sdcflQ02.value !==
            this.socialDataClone.sdcflQ02.value &&
          !this.changedkeys.includes('sdcflQ02')
        ) {
          this.changedkeys.push('sdcflQ02');
          this.counter++;
        } else if (
          this.socialData.sdcflQ02.value ===
            this.socialDataClone.sdcflQ02.value &&
          this.changedkeys.includes('sdcflQ02')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdcflQ02'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkCflStatus();
        break;

      case 'sdcflQ04':
        this.socialData.sdcflQ04.value = value;
        this.socialData.sdcflQ04.updatedAt = new Date().toISOString();
        this.socialData.sdcflQ04.updatedBy = this.user.userId;
        if (
          this.socialData.sdcflQ04.value !==
            this.socialDataClone.sdcflQ04.value &&
          !this.changedkeys.includes('sdcflQ04')
        ) {
          this.changedkeys.push('sdcflQ04');
          this.counter++;
        } else if (
          this.socialData.sdcflQ04.value ===
            this.socialDataClone.sdcflQ04.value &&
          this.changedkeys.includes('sdcflQ04')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdcflQ04'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkCflStatus();
        break;

      case 'sdcflQ05':
        this.socialData.sdcflQ05.value = value;
        this.socialData.sdcflQ05.updatedAt = new Date().toISOString();
        this.socialData.sdcflQ05.updatedBy = this.user.userId;
        if (
          this.socialData.sdcflQ05.value !==
            this.socialDataClone.sdcflQ05.value &&
          !this.changedkeys.includes('sdcflQ05')
        ) {
          this.changedkeys.push('sdcflQ05');
          this.counter++;
        } else if (
          this.socialData.sdcflQ05.value ===
            this.socialDataClone.sdcflQ05.value &&
          this.changedkeys.includes('sdcflQ05')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdcflQ05'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkCflStatus();
        break;

      case 'sdcflQ06':
        this.socialData.sdcflQ06.value = value;
        this.socialData.sdcflQ06.updatedAt = new Date().toISOString();
        this.socialData.sdcflQ06.updatedBy = this.user.userId;
        if (
          this.socialData.sdcflQ06.value !==
            this.socialDataClone.sdcflQ06.value &&
          !this.changedkeys.includes('sdcflQ06')
        ) {
          this.changedkeys.push('sdcflQ06');
          this.counter++;
        } else if (
          this.socialData.sdcflQ06.value ===
            this.socialDataClone.sdcflQ06.value &&
          this.changedkeys.includes('sdcflQ06')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdcflQ06'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkCflStatus();
        break;

      case 'sdwccQ01':
        this.socialData.sdwccQ01.value = value;
        this.socialData.sdwccQ01.updatedAt = new Date().toISOString();
        this.socialData.sdwccQ01.updatedBy = this.user.userId;
        if(this.socialData.sdwccQ01.value !== this.yes){
          this.socialData.sdwccQ02.value = '';
          this.socialData.sdwccQ02.updatedAt = new Date().toISOString();
          this.socialData.sdwccQ02.updatedBy = this.user.userId;
        }
        if (
          this.socialData.sdwccQ01.value !==
            this.socialDataClone.sdwccQ01.value &&
          !this.changedkeys.includes('sdwccQ01')
        ) {
          this.changedkeys.push('sdwccQ01');
          this.counter++;
        } else if (
          this.socialData.sdwccQ01.value ===
            this.socialDataClone.sdwccQ01.value &&
          this.changedkeys.includes('sdwccQ01')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdwccQ01'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkWccStatus();
        break;

      case 'sdwccQ02':
        this.socialData.sdwccQ02.value = value;
        this.socialData.sdwccQ02.updatedAt = new Date().toISOString();
        this.socialData.sdwccQ02.updatedBy = this.user.userId;
        if (
          this.socialData.sdwccQ02.value !==
            this.socialDataClone.sdwccQ02.value &&
          !this.changedkeys.includes('sdwccQ02')
        ) {
          this.changedkeys.push('sdwccQ02');
          this.counter++;
        } else if (
          this.socialData.sdwccQ02.value ===
            this.socialDataClone.sdwccQ02.value &&
          this.changedkeys.includes('sdwccQ02')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdwccQ02'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkWccStatus();
        break;

      case 'sdwccQ04':
        this.socialData.sdwccQ04.value = value;
        this.socialData.sdwccQ04.updatedAt = new Date().toISOString();
        this.socialData.sdwccQ04.updatedBy = this.user.userId;
        if (
          this.socialData.sdwccQ04.value !==
            this.socialDataClone.sdwccQ04.value &&
          !this.changedkeys.includes('sdwccQ04')
        ) {
          this.changedkeys.push('sdwccQ04');
          this.counter++;
        } else if (
          this.socialData.sdwccQ04.value ===
            this.socialDataClone.sdwccQ04.value &&
          this.changedkeys.includes('sdwccQ04')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdwccQ04'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkWccStatus();
        break;

      case 'sdwccQ05':
        this.socialData.sdwccQ05.value = this.allowOnlyInteger(
          this.socialData.sdwccQ05.value.toString()
        );
        if (this.socialData.sdwccQ05.value !== '') {
          this.socialData.sdwccQ05.value = Number(
            this.socialData.sdwccQ05.value
          );
        }
        this.socialData.sdwccQ05.updatedAt = new Date().toISOString();
        this.socialData.sdwccQ05.updatedBy = this.user.userId;
        if (
          this.socialData.sdwccQ05.value !==
            this.socialDataClone.sdwccQ05.value &&
          !this.changedkeys.includes('sdwccQ05')
        ) {
          this.changedkeys.push('sdwccQ05');
          this.counter++;
        } else if (
          this.socialData.sdwccQ05.value ===
            this.socialDataClone.sdwccQ05.value &&
          this.changedkeys.includes('sdwccQ05')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdwccQ05'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkWccStatus();
        break;

      case 'sdwccQ06':
        this.socialData.sdwccQ06.value = this.allowOnlyInteger(
          this.socialData.sdwccQ06.value.toString()
        );
        if (this.socialData.sdwccQ06.value !== '') {
          this.socialData.sdwccQ06.value = Number(
            this.socialData.sdwccQ06.value
          );
        }
        this.socialData.sdwccQ06.updatedAt = new Date().toISOString();
        this.socialData.sdwccQ06.updatedBy = this.user.userId;
        if (
          this.socialData.sdwccQ06.value !==
            this.socialDataClone.sdwccQ06.value &&
          !this.changedkeys.includes('sdwccQ06')
        ) {
          this.changedkeys.push('sdwccQ06');
          this.counter++;
        } else if (
          this.socialData.sdwccQ06.value ===
            this.socialDataClone.sdwccQ06.value &&
          this.changedkeys.includes('sdwccQ06')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdwccQ06'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkWccStatus();
        break;

      case 'sdwccQ07':
        this.socialData.sdwccQ07.value = value;
        this.socialData.sdwccQ07.updatedAt = new Date().toISOString();
        this.socialData.sdwccQ07.updatedBy = this.user.userId;
        if (
          this.socialData.sdwccQ07.value !==
            this.socialDataClone.sdwccQ07.value &&
          !this.changedkeys.includes('sdwccQ07')
        ) {
          this.changedkeys.push('sdwccQ07');
          this.counter++;
        } else if (
          this.socialData.sdwccQ07.value ===
            this.socialDataClone.sdwccQ07.value &&
          this.changedkeys.includes('sdwccQ07')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdwccQ07'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkWccStatus();
        break;

      case 'sdwccQ08':
        this.socialData.sdwccQ08.value = value;
        this.socialData.sdwccQ08.updatedAt = new Date().toISOString();
        this.socialData.sdwccQ08.updatedBy = this.user.userId;
        if (
          this.socialData.sdwccQ08.value !==
            this.socialDataClone.sdwccQ08.value &&
          !this.changedkeys.includes('sdwccQ08')
        ) {
          this.changedkeys.push('sdwccQ08');
          this.counter++;
        } else if (
          this.socialData.sdwccQ08.value ===
            this.socialDataClone.sdwccQ08.value &&
          this.changedkeys.includes('sdwccQ08')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdwccQ08'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkWccStatus();
        break;

      case 'sddisQ01':
        this.socialData.sddisQ01.value = value;
        this.socialData.sddisQ01.updatedAt = new Date().toISOString();
        this.socialData.sddisQ01.updatedBy = this.user.userId;
        if(this.socialData.sddisQ01.value !== this.yes){
          this.socialData.sddisQ02.value = '';
          this.socialData.sddisQ02.updatedAt = new Date().toISOString();
          this.socialData.sddisQ02.updatedBy = this.user.userId;
        }
        if (
          this.socialData.sddisQ01.value !==
            this.socialDataClone.sddisQ01.value &&
          !this.changedkeys.includes('sddisQ01')
        ) {
          this.changedkeys.push('sddisQ01');
          this.counter++;
        } else if (
          this.socialData.sddisQ01.value ===
            this.socialDataClone.sddisQ01.value &&
          this.changedkeys.includes('sddisQ01')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sddisQ01'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkDisStatus();
        break;

      case 'sddisQ02':
        this.socialData.sddisQ02.value = value;
        this.socialData.sddisQ02.updatedAt = new Date().toISOString();
        this.socialData.sddisQ02.updatedBy = this.user.userId;
        if (
          this.socialData.sddisQ02.value !==
            this.socialDataClone.sddisQ02.value &&
          !this.changedkeys.includes('sddisQ02')
        ) {
          this.changedkeys.push('sddisQ02');
          this.counter++;
        } else if (
          this.socialData.sddisQ02.value ===
            this.socialDataClone.sddisQ02.value &&
          this.changedkeys.includes('sddisQ02')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sddisQ02'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkDisStatus();
        break;

      case 'sdshmQ01':
        this.socialData.sdshmQ01.value = value;
        this.socialData.sdshmQ01.updatedAt = new Date().toISOString();
        this.socialData.sdshmQ01.updatedBy = this.user.userId;
        if(this.socialData.sdshmQ01.value !== this.yes){
          this.socialData.sdshmQ02.value = '';
          this.socialData.sdshmQ02.updatedAt = new Date().toISOString();
          this.socialData.sdshmQ02.updatedBy = this.user.userId;
        }
        if (
          this.socialData.sdshmQ01.value !==
            this.socialDataClone.sdshmQ01.value &&
          !this.changedkeys.includes('sdshmQ01')
        ) {
          this.changedkeys.push('sdshmQ01');
          this.counter++;
        } else if (
          this.socialData.sdshmQ01.value ===
            this.socialDataClone.sdshmQ01.value &&
          this.changedkeys.includes('sdshmQ01')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdshmQ01'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkShmStatus();
        break;

      case 'sdshmQ02':
        this.socialData.sdshmQ02.value = value;
        this.socialData.sdshmQ02.updatedAt = new Date().toISOString();
        this.socialData.sdshmQ02.updatedBy = this.user.userId;
        if (
          this.socialData.sdshmQ02.value !==
            this.socialDataClone.sdshmQ02.value &&
          !this.changedkeys.includes('sdshmQ02')
        ) {
          this.changedkeys.push('sdshmQ02');
          this.counter++;
        } else if (
          this.socialData.sdshmQ02.value ===
            this.socialDataClone.sdshmQ02.value &&
          this.changedkeys.includes('sdshmQ02')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdshmQ02'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkShmStatus();
        break;

      case 'sdgrmQ01':
        this.socialData.sdgrmQ01.value = value;
        this.socialData.sdgrmQ01.updatedAt = new Date().toISOString();
        this.socialData.sdgrmQ01.updatedBy = this.user.userId;
        if(this.socialData.sdgrmQ01.value !== this.no){
          this.socialData.sdgrmQ04.value = '';
          this.socialData.sdgrmQ04.updatedAt = new Date().toISOString();
          this.socialData.sdgrmQ04.updatedBy = this.user.userId;
          this.socialData.sdgrmQ05.value = '';
          this.socialData.sdgrmQ05.updatedAt = new Date().toISOString();
          this.socialData.sdgrmQ05.updatedBy = this.user.userId;
        } else if(this.socialData.sdgrmQ01.value !== this.yes) {
          this.socialData.sdgrmQ02.value = '';
          this.socialData.sdgrmQ02.updatedAt = new Date().toISOString();
          this.socialData.sdgrmQ02.updatedBy = this.user.userId;
          this.socialData.sdgrmQ03.value = '';
          this.socialData.sdgrmQ03.updatedAt = new Date().toISOString();
          this.socialData.sdgrmQ03.updatedBy = this.user.userId;
        }
        if (
          this.socialData.sdgrmQ01.value !==
            this.socialDataClone.sdgrmQ01.value &&
          !this.changedkeys.includes('sdgrmQ01')
        ) {
          this.changedkeys.push('sdgrmQ01');
          this.counter++;
        } else if (
          this.socialData.sdgrmQ01.value ===
            this.socialDataClone.sdgrmQ01.value &&
          this.changedkeys.includes('sdgrmQ01')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdgrmQ01'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkGrmStatus();
        break;

      case 'sdgrmQ02':
        this.socialData.sdgrmQ02.value = value;
        this.socialData.sdgrmQ02.updatedAt = new Date().toISOString();
        this.socialData.sdgrmQ02.updatedBy = this.user.userId;
        if(this.socialData.sdgrmQ02.value !== this.yes){
          this.socialData.sdgrmQ03.value = '';
          this.socialData.sdgrmQ03.updatedAt = new Date().toISOString();
          this.socialData.sdgrmQ03.updatedBy = this.user.userId;
        }
        if (
          this.socialData.sdgrmQ02.value !==
            this.socialDataClone.sdgrmQ02.value &&
          !this.changedkeys.includes('sdgrmQ02')
        ) {
          this.changedkeys.push('sdgrmQ02');
          this.counter++;
        } else if (
          this.socialData.sdgrmQ02.value ===
            this.socialDataClone.sdgrmQ02.value &&
          this.changedkeys.includes('sdgrmQ02')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdgrmQ02'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkGrmStatus();
        break;

      case 'sdgrmQ03':
        this.socialData.sdgrmQ03.updatedAt = new Date().toISOString();
        this.socialData.sdgrmQ03.updatedBy = this.user.userId;
        if (
          this.socialData.sdgrmQ03.value !==
            this.socialDataClone.sdgrmQ03.value &&
          !this.changedkeys.includes('sdgrmQ03')
        ) {
          this.changedkeys.push('sdgrmQ03');
          this.counter++;
        } else if (
          this.socialData.sdgrmQ03.value ===
            this.socialDataClone.sdgrmQ03.value &&
          this.changedkeys.includes('sdgrmQ03')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdgrmQ03'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkGrmStatus();
        break;

      case 'sdgrmQ04':
        this.socialData.sdgrmQ04.value = value;
        this.socialData.sdgrmQ04.updatedAt = new Date().toISOString();
        this.socialData.sdgrmQ04.updatedBy = this.user.userId;
        if(this.socialData.sdgrmQ04.value !== this.yes){
          this.socialData.sdgrmQ05.value = '';
          this.socialData.sdgrmQ05.updatedAt = new Date().toISOString();
          this.socialData.sdgrmQ05.updatedBy = this.user.userId;
        }
        if (
          this.socialData.sdgrmQ04.value !==
            this.socialDataClone.sdgrmQ04.value &&
          !this.changedkeys.includes('sdgrmQ04')
        ) {
          this.changedkeys.push('sdgrmQ04');
          this.counter++;
        } else if (
          this.socialData.sdgrmQ04.value ===
            this.socialDataClone.sdgrmQ04.value &&
          this.changedkeys.includes('sdgrmQ04')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdgrmQ04'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkGrmStatus();
        break;

      case 'sdgrmQ05':
        this.socialData.sdgrmQ05.updatedAt = new Date().toISOString();
        this.socialData.sdgrmQ05.updatedBy = this.user.userId;
        if (
          this.socialData.sdgrmQ05.value !==
            this.socialDataClone.sdgrmQ05.value &&
          !this.changedkeys.includes('sdgrmQ05')
        ) {
          this.changedkeys.push('sdgrmQ05');
          this.counter++;
        } else if (
          this.socialData.sdgrmQ05.value ===
            this.socialDataClone.sdgrmQ05.value &&
          this.changedkeys.includes('sdgrmQ05')
        ) {
          this.changedkeys.splice(this.changedkeys.indexOf('sdgrmQ05'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkGrmStatus();
        break;
    }
    if(this.counter > 0){
      this.$store.commit('setEsgDataChanged', true);
    }else{
      this.$store.commit('setEsgDataChanged', false);
    }
  }

  private overwriteFacilityEmployeeData(key: string, value: string): void {
    if(key === 'sddeoQ04-te'){
      if((this.socialDataClone.sddeoQ04.totalEmployees.value === this.activeAddress.employees) && (this.socialDataClone.sddeoQ04.totalEmployees.value !== this.socialData.sddeoQ04.totalEmployees.value) && !this.totalEmployeeUpdated){
        this.showEmpOverrideModal = true;
      } else {
        this.updateValue(key, '');
      }
    } else if (key === 'sddeoQ04-fe'){
      if((this.socialDataClone.sddeoQ04.femaleEmployees.value === this.activeAddress.femaleEmployees) && (this.socialDataClone.sddeoQ04.femaleEmployees.value !== this.socialData.sddeoQ04.femaleEmployees.value) && !this.femaleEmployeeUpdated){
        this.showFemaleEmpOverrideModal = true;
      } else {
        this.updateValue(key, '');
      }
    } else {
      if((this.socialDataClone.sddeoQ04.maleEmployees.value === this.activeAddress.maleEmployees) && (this.socialDataClone.sddeoQ04.maleEmployees.value !== this.socialData.sddeoQ04.maleEmployees.value) && !this.maleEmployeeUpdated){
        this.showMaleEmpOverrideModal = true;
      } else {
        this.updateValue(key, '');
      }
    }
  }

  private cancelEmployeeDataUpdate(): void {
    if(this.showEmpOverrideModal){
      this.socialData.sddeoQ04.totalEmployees.value = this.activeAddress.employees;
    } else if(this.showFemaleEmpOverrideModal){
      this.socialData.sddeoQ04.femaleEmployees.value = this.activeAddress.femaleEmployees;
    } else {
      this.socialData.sddeoQ04.maleEmployees.value = this.activeAddress.maleEmployees;
    }
    this.showEmpOverrideModal = false;
    this.showFemaleEmpOverrideModal = false;
    this.showMaleEmpOverrideModal = false;
  }

  private confirmEmployeeDataUpdate(): void {
    if(this.showEmpOverrideModal){
      this.totalEmployeeUpdated = true;
      this.updateValue('sddeoQ04-te', '');
    } else if(this.showFemaleEmpOverrideModal){
      this.femaleEmployeeUpdated = true;
      this.updateValue('sddeoQ04-fe', '');
    } else {
      this.maleEmployeeUpdated = true;
      this.updateValue('sddeoQ04-me', '');
    }
    this.showEmpOverrideModal = false;
    this.showFemaleEmpOverrideModal = false;
    this.showMaleEmpOverrideModal = false;
  }

  private saveActionTaken(index: number, value: string): void {
    this.socialData.sdsaQ05[index].text = value;
    this.socialData.sdsaQ05[index].updatedAt = dateTime();
    this.socialData.sdsaQ05[index].updatedBy = this.user.userId;
    if (
      this.socialData.sdsaQ05[index].text !==
        this.socialDataClone.sdsaQ05[index].text &&
      !this.changedkeys.includes(`sdsaQ05-${index}`)
    ) {
      this.changedkeys.push(`sdsaQ05-${index}`);
      this.counter++;
    } else if (
      this.socialData.sdsaQ05[index].text ===
        this.socialDataClone.sdsaQ05[index].text &&
      this.changedkeys.includes(`sdsaQ05-${index}`)
    ) {
      this.changedkeys.splice(this.changedkeys.indexOf(`sdsaQ05-${index}`), 1);
      this.counter--;
    } else {
      // no action
    }
    this.socialData.sdsaQ05[index].isEdited = false;
    if(this.socialData.sdsaQ05[index].text.trim() === ''){
      this.socialData.sdsaQ05.splice(index, 1);
      this.changedkeys.splice(this.changedkeys.indexOf(`sdsaQ05-${index}`), 1);
    }
  }

  private allowOnlyInteger(value: string): string {
    const regex = /^\d*$/;
    let returnValue = '';
    if (!regex.test(value)) {
      returnValue = value.replace(/[^\d]/g, '');
    } else {
      returnValue = value;
    }
    return returnValue;
  }

  private allowOnlyCharacter(value: string): string {
    const regex = /[^a-zA-Z ]+/;
    let returnValue = '';
    if (regex.test(value)) {
      returnValue = value.replace(/[^a-zA-Z ]+/g, '');
    } else {
      returnValue = value;
    }
    return returnValue;
  }

  private checkAllSectionStatus(): void {
    this.checkLwwStatus();
    this.checkCflStatus();
    this.checkWccStatus();
    this.checkDisStatus();
    this.checkShmStatus();
    this.checkGrmStatus();
    this.checkSAStatus();
    this.checkHsStatus();
    this.checkDeoStatus();
    this.checkSocialStatus();
  }

  private checkSAStatus(): void {
    if(this.socialData.sdsaQ01.value === this.no &&
      ((this.socialData.sdsaQ06.value === this.no && this.socialData.sdsaQ06Docs.length === 0) || (this.socialData.sdsaQ06.value === this.yes && this.socialData.sdsaQ06Docs.length > 0))
    ){
      this.socialData.socialAuditStatus = this.completedStatus;
    } else if (this.socialData.sdsaQ01.value === this.yes
      && this.socialData.socialCert.length > 0
      && (this.socialData.sdsaQ02.value === this.yes || this.socialData.sdsaQ02.value === this.no)
      && ((this.socialData.sdsaQ03.value === this.yes && this.socialData.sdsaQ03Docs.length > 0 && this.socialData.sdsaQ04.value !== this.notStartedStatus && (this.socialData.sdsaQ05.length > 0 || this.saCorrActionTaken.text !== this.notStartedStatus))
      || (this.socialData.sdsaQ03.value === this.no && this.socialData.sdsaQ03Docs.length === 0 && this.socialData.sdsaQ04.value === this.notStartedStatus && this.socialData.sdsaQ05.length === 0 && this.saCorrActionTaken.text === this.notStartedStatus))
      ) {
      this.socialData.socialAuditStatus = this.completedStatus;
    } else if (this.socialData.sdsaQ01.value === this.notStartedStatus
      && this.socialData.socialCert.length === 0
      && this.socialData.sdsaQ02.value === this.notStartedStatus
      && this.socialData.sdsaQ03.value === this.notStartedStatus
      && this.socialData.sdsaQ03Docs.length === 0 && this.socialData.sdsaQ04.value === this.notStartedStatus
      && this.socialData.sdsaQ05.length === 0 && this.saCorrActionTaken.text === this.notStartedStatus
      && this.socialData.sdsaQ06.value === this.notStartedStatus) {
      this.socialData.socialAuditStatus = this.notStartedStatus;
    } else {
      this.socialData.socialAuditStatus = this.inProgressStatus;
    }
    this.checkSocialStatus();
  }

  private checkHsStatus(): void {
    if(this.socialData.sdhsQ01.value === this.no
      && this.socialData.sdhsQ05.value !== this.notStartedStatus && this.socialData.sdhsQ06.value !== this.notStartedStatus
    ){
      this.socialData.healthAndSafetyStatus = this.completedStatus;
    }
    else if (this.socialData.sdhsQ01.value === this.yes
      && this.socialData.sdhsQ02.length > 0
      && ((this.socialData.sdhsQ03.value === this.yes && this.socialData.sdhsQ04.length > 0) || (this.socialData.sdhsQ03.value === this.no))
      && this.socialData.sdhsQ05.value !== this.notStartedStatus && this.socialData.sdhsQ06.value !== this.notStartedStatus
    ){
      this.socialData.healthAndSafetyStatus = this.completedStatus;
    } else if (this.socialData.sdhsQ01.value === this.notStartedStatus
      && this.socialData.sdhsQ02.length === 0
      && this.socialData.sdhsQ03.value === this.notStartedStatus
      && this.socialData.sdhsQ04.length === 0
      && this.socialData.sdhsQ05.value === this.notStartedStatus
      && this.socialData.sdhsQ06.value === this.notStartedStatus
    ){
      this.socialData.healthAndSafetyStatus = this.notStartedStatus;
    } else {
      this.socialData.healthAndSafetyStatus = this.inProgressStatus;
    }
    this.checkSocialStatus();
  }

  private checkDeoStatus(): void {
    if(this.socialData.sddeoQ01.value === this.no
      && this.socialData.sddeoQ04.totalEmployees.value !== this.notStartedStatus
      && this.socialData.sddeoQ04.femaleEmployees.value !== this.notStartedStatus
      && this.socialData.sddeoQ04.maleEmployees.value !== this.notStartedStatus
      && this.socialData.sddeoQ05.value !== this.notStartedStatus
      && this.socialData.sddeoQ06.value !== this.notStartedStatus
      && this.socialData.sddeoQ07.value !== this.notStartedStatus
    ){
      this.socialData.diversityAndEqualOptyStatus = this.completedStatus;
    } else if (this.socialData.sddeoQ01.value === this.yes
      && this.socialData.sddeoQ01Docs.length > 0
      && ((this.socialData.sddeoQ02.value === this.yes
      && this.socialData.sddeoQ03.length > 0) || this.socialData.sddeoQ02.value === this.no)
      && this.socialData.sddeoQ04.totalEmployees.value !== this.notStartedStatus
      && this.socialData.sddeoQ04.femaleEmployees.value !== this.notStartedStatus
      && this.socialData.sddeoQ04.maleEmployees.value !== this.notStartedStatus
      && this.socialData.sddeoQ05.value !== this.notStartedStatus
      && this.socialData.sddeoQ06.value !== this.notStartedStatus
      && this.socialData.sddeoQ07.value !== this.notStartedStatus){
      this.socialData.diversityAndEqualOptyStatus = this.completedStatus;
    } else if (this.socialData.sddeoQ01.value === this.notStartedStatus
      && this.socialData.sddeoQ01Docs.length === 0
      && this.socialData.sddeoQ02.value === this.notStartedStatus
      && this.socialData.sddeoQ03.length === 0
      && this.socialData.sddeoQ04.totalEmployees.value === this.notStartedStatus
      && this.socialData.sddeoQ04.femaleEmployees.value === this.notStartedStatus
      && this.socialData.sddeoQ04.maleEmployees.value === this.notStartedStatus
      && this.socialData.sddeoQ05.value === this.notStartedStatus
      && this.socialData.sddeoQ06.value === this.notStartedStatus
      && this.socialData.sddeoQ07.value === this.notStartedStatus
    ){
      this.socialData.diversityAndEqualOptyStatus = this.notStartedStatus;
    } else {
      this.socialData.diversityAndEqualOptyStatus = this.inProgressStatus;
    }
    this.checkSocialStatus();
  }

  private checkLwwStatus(): void {
    if(this.socialData.sdlwwQ01.value === this.no
      && this.socialData.sdlwwQ04.value !== this.notStartedStatus
      && ((this.socialData.sdlwwQ05.value === this.yes && this.socialData.sdlwwQ06.value !== this.notStartedStatus) || (this.socialData.sdlwwQ05.value === this.no && this.socialData.sdlwwQ06.value === this.notStartedStatus))
      && this.socialData.sdlwwQ07.value !== this.notStartedStatus
      && this.socialData.sdlwwQ08.value !== this.notStartedStatus
    ){
      this.socialData.livingWageWorkingHoursStatus = this.completedStatus;
    } else if (this.socialData.sdlwwQ01.value === this.yes
      && this.socialData.sdlwwQ01Docs.length > 0
      && ((this.socialData.sdlwwQ02.value === this.yes
      && this.socialData.sdlwwQ03.length > 0) || this.socialData.sdlwwQ02.value === this.no)
      && this.socialData.sdlwwQ04.value !== this.notStartedStatus
      && ((this.socialData.sdlwwQ05.value === this.yes && this.socialData.sdlwwQ06.value !== this.notStartedStatus) || (this.socialData.sdlwwQ05.value === this.no && this.socialData.sdlwwQ06.value === this.notStartedStatus))
      && this.socialData.sdlwwQ07.value !== this.notStartedStatus
      && this.socialData.sdlwwQ08.value !== this.notStartedStatus
    ){
      this.socialData.livingWageWorkingHoursStatus = this.completedStatus;
    } else if (this.socialData.sdlwwQ01.value === this.notStartedStatus
      && this.socialData.sdlwwQ01Docs.length === 0
      && this.socialData.sdlwwQ02.value === this.notStartedStatus
      && this.socialData.sdlwwQ03.length === 0
      && this.socialData.sdlwwQ04.value === this.notStartedStatus
      && this.socialData.sdlwwQ05.value === this.notStartedStatus
      && this.socialData.sdlwwQ06.value === this.notStartedStatus
      && this.socialData.sdlwwQ07.value === this.notStartedStatus
      && this.socialData.sdlwwQ08.value === this.notStartedStatus
    ){
      this.socialData.livingWageWorkingHoursStatus = this.notStartedStatus;
    } else {
      this.socialData.livingWageWorkingHoursStatus = this.inProgressStatus;
    }
    this.checkSocialStatus();
  }

  private checkCflStatus(): void {
    if(this.socialData.sdcflQ01.value === this.no
      && this.socialData.sdcflQ04.value !== this.notStartedStatus
      && this.socialData.sdcflQ05.value !== this.notStartedStatus
      && this.socialData.sdcflQ06.value !== this.notStartedStatus
    ){
      this.socialData.childForcedLabourStatus = this.completedStatus;
    } else if (this.socialData.sdcflQ01.value === this.yes
      && this.socialData.sdcflQ01Docs.length > 0
      && ((this.socialData.sdcflQ02.value === this.yes && this.socialData.sdcflQ03.length > 0) || (this.socialData.sdcflQ02.value === this.no && this.socialData.sdcflQ03.length === 0))
      && this.socialData.sdcflQ04.value !== this.notStartedStatus
      && this.socialData.sdcflQ05.value !== this.notStartedStatus
      && this.socialData.sdcflQ06.value !== this.notStartedStatus
    ){
      this.socialData.childForcedLabourStatus = this.completedStatus;
    } else if (this.socialData.sdcflQ01.value === this.notStartedStatus
      && this.socialData.sdcflQ01Docs.length === 0
      && this.socialData.sdcflQ02.value === this.notStartedStatus
      && this.socialData.sdcflQ03.length === 0
      && this.socialData.sdcflQ04.value === this.notStartedStatus
      && this.socialData.sdcflQ05.value === this.notStartedStatus
      && this.socialData.sdcflQ06.value === this.notStartedStatus
    ){
      this.socialData.childForcedLabourStatus = this.notStartedStatus;
    } else {
      this.socialData.childForcedLabourStatus = this.inProgressStatus;
    }
    this.checkSocialStatus();
  }

  private checkDisStatus(): void {
    if(
      this.socialData.sddisQ01.value === this.no
    ){
      this.socialData.discriminationStatus = this.completedStatus;
    } else if (this.socialData.sddisQ01.value === this.yes
      && this.socialData.sddisQ01Docs.length > 0
      && ((this.socialData.sddisQ02.value === this.yes && this.socialData.sddisQ03.length > 0) || (this.socialData.sddisQ02.value === this.no && this.socialData.sddisQ03.length === 0))
    ){
      this.socialData.discriminationStatus = this.completedStatus;
    } else if (this.socialData.sddisQ01.value === this.notStartedStatus
      && this.socialData.sddisQ01Docs.length === 0
      && this.socialData.sddisQ02.value === this.notStartedStatus && this.socialData.sddisQ03.length === 0
    ){
      this.socialData.discriminationStatus = this.notStartedStatus;
    } else {
      this.socialData.discriminationStatus = this.inProgressStatus;
    }
    this.checkSocialStatus();
  }

  private checkWccStatus(): void {
    if(this.socialData.sdwccQ01.value === this.no
      && ((this.socialData.sdwccQ04.value === this.yes && this.socialData.sdwccQ05.value !== this.notStartedStatus && this.socialData.sdwccQ06.value !== this.notStartedStatus)
      || (this.socialData.sdwccQ04.value === this.no && this.socialData.sdwccQ05.value === this.notStartedStatus && this.socialData.sdwccQ06.value === this.notStartedStatus)  )
      && this.socialData.sdwccQ07.value !== this.notStartedStatus
      && this.socialData.sdwccQ08.value !== this.notStartedStatus
    ){
      this.socialData.workingConditionsStatus = this.completedStatus;
    } else if (this.socialData.sdwccQ01.value === this.yes
      && this.socialData.sdwccQ01Docs.length > 0
      && ((this.socialData.sdwccQ02.value === this.yes && this.socialData.sdwccQ03.length > 0) || (this.socialData.sdwccQ02.value === this.no && this.socialData.sdwccQ03.length === 0))
      && ((this.socialData.sdwccQ04.value === this.no && this.socialData.sdwccQ05.value === this.notStartedStatus && this.socialData.sdwccQ06.value === this.notStartedStatus) || (this.socialData.sdwccQ04.value === this.yes && this.socialData.sdwccQ05.value !== this.notStartedStatus && this.socialData.sdwccQ06.value !== this.notStartedStatus))
      && this.socialData.sdwccQ07.value !== this.notStartedStatus
      && this.socialData.sdwccQ08.value !== this.notStartedStatus
    ){
      this.socialData.workingConditionsStatus = this.completedStatus;
    } else if (this.socialData.sdwccQ01.value === this.notStartedStatus
      && this.socialData.sdwccQ01Docs.length === 0
      && this.socialData.sdwccQ02.value === this.notStartedStatus
      && this.socialData.sdwccQ03.length === 0
      && this.socialData.sdwccQ04.value === this.notStartedStatus
      && this.socialData.sdwccQ05.value === this.notStartedStatus
      && this.socialData.sdwccQ06.value === this.notStartedStatus
      && this.socialData.sdwccQ07.value === this.notStartedStatus
      && this.socialData.sdwccQ08.value === this.notStartedStatus
    ){
      this.socialData.workingConditionsStatus = this.notStartedStatus;
    } else {
      this.socialData.workingConditionsStatus = this.inProgressStatus;
    }
    this.checkSocialStatus();
  }

  private checkShmStatus(): void {
    if(this.socialData.sdshmQ01.value === this.no){
      this.socialData.sexualHarasmentStatus = this.completedStatus;
    } else if (this.socialData.sdshmQ01.value === this.yes
      && this.socialData.sdshmQ01Docs.length > 0
      && ((this.socialData.sdshmQ02.value === this.yes && this.socialData.sdshmQ03.length > 0) || (this.socialData.sdshmQ02.value === this.no && this.socialData.sdshmQ03.length === 0))
    ){
      this.socialData.sexualHarasmentStatus = this.completedStatus;
    } else if (this.socialData.sdshmQ01.value === this.notStartedStatus
      && this.socialData.sdshmQ01Docs.length === 0
      && this.socialData.sdshmQ02.value === this.notStartedStatus
      && this.socialData.sdshmQ03.length === 0
    ){
      this.socialData.sexualHarasmentStatus = this.notStartedStatus;
    } else {
      this.socialData.sexualHarasmentStatus = this.inProgressStatus;
    }
    this.checkSocialStatus();
  }

  private checkGrmStatus(): void {
    if(this.socialData.sdgrmQ01.value === this.no
      && ((this.socialData.sdgrmQ04.value === this.yes && this.socialData.sdgrmQ05.value !== this.notStartedStatus) || (this.socialData.sdgrmQ04.value === this.no && this.socialData.sdgrmQ05.value === this.notStartedStatus))
    ){
      this.socialData.grievanceMechanismStatus = this.completedStatus;
    } else if (this.socialData.sdgrmQ01.value === this.yes
      && ((this.socialData.sdgrmQ02.value === this.yes && this.socialData.sdgrmQ03.value !== this.notStartedStatus) || (this.socialData.sdgrmQ02.value === this.no && this.socialData.sdgrmQ03.value === this.notStartedStatus))
    ){
      this.socialData.grievanceMechanismStatus = this.completedStatus;
    } else if (this.socialData.sdgrmQ01.value === this.notStartedStatus
      && this.socialData.sdgrmQ02.value === this.notStartedStatus
      && this.socialData.sdgrmQ03.value === this.notStartedStatus
      && this.socialData.sdgrmQ04.value === this.notStartedStatus
      && this.socialData.sdgrmQ05.value === this.notStartedStatus
    ){
      this.socialData.grievanceMechanismStatus = this.notStartedStatus;
    } else {
      this.socialData.grievanceMechanismStatus = this.inProgressStatus;
    }
    this.checkSocialStatus();
  }

  private checkSocialStatus(): void {
    if(this.socialData.socialAuditStatus === this.completedStatus
      && this.socialData.healthAndSafetyStatus === this.completedStatus
      && this.socialData.diversityAndEqualOptyStatus === this.completedStatus
      && this.socialData.childForcedLabourStatus === this.completedStatus
      && this.socialData.livingWageWorkingHoursStatus === this.completedStatus
      && this.socialData.discriminationStatus === this.completedStatus
      && this.socialData.workingConditionsStatus === this.completedStatus
      && this.socialData.sexualHarasmentStatus === this.completedStatus
      && this.socialData.grievanceMechanismStatus === this.completedStatus){
      this.socialData.status = this.completedStatus;
    } else if (this.socialData.socialAuditStatus === this.notStartedStatus
      && this.socialData.healthAndSafetyStatus === this.notStartedStatus
      && this.socialData.diversityAndEqualOptyStatus === this.notStartedStatus
      && this.socialData.childForcedLabourStatus === this.notStartedStatus
      && this.socialData.livingWageWorkingHoursStatus === this.notStartedStatus
      && this.socialData.discriminationStatus === this.notStartedStatus
      && this.socialData.workingConditionsStatus === this.notStartedStatus
      && this.socialData.sexualHarasmentStatus === this.notStartedStatus
      && this.socialData.grievanceMechanismStatus === this.notStartedStatus){
      this.socialData.status = this.notStartedStatus;
    } else {
      this.socialData.status = this.inProgressStatus;
    }
    this.facilityStatus(this.activeAddress.id, this.socialData.status);
  }

  private async certUploaded(ID: string, type: string): Promise<void> {
    this.auditDocs = [];
    const doc: EsgAuditCertificate = new EsgAuditCertificate();
    doc.complianceDocID = ID;
    doc.type = type;
    this.auditDocs.push(doc);
    await this.saveAuditCert();
  }

  private async saveAuditCert(): Promise<void> {
    this.isUploading = true;
    const socialCerts: EsgSocialAuditCert = new EsgSocialAuditCert();
    const facilityWithID: EsgFacilityAddressInfo[] = [];
    this.companyAddresses.forEach(add => {
        const facility: EsgFacilityAddressInfo = new EsgFacilityAddressInfo();
        facility.addressID = add.id;
        const socialIdIndex = this.esgSocial.findIndex(social => social.addressID === add.id);
        if(socialIdIndex !== -1) {
            facility.ID = this.esgSocial[socialIdIndex].ID;
        } else {
            facility.ID = uuidv4();
        }
        facilityWithID.push(facility);
    });
    socialCerts.certificates = this.auditDocs;
    socialCerts.createdAt = new Date().toISOString();
    socialCerts.createdBy = this.user.userId;
    socialCerts.addressInfoForFacility = facilityWithID;
    const result = await this.supplierService.uploadEsgSocialAuditCertAsync(socialCerts);
    this.isUploading = false;
    if (result.success) {
        this.showUploadNewAuditCertModal = false;
        this.socialAuditUploaded(socialCerts);
    }
}

  private openPDFModal(doc: any, certName:string): void {
    this.documentDetail = doc;
    this.documentDetail.typeName = certName;
    this.documentDetail.amforiID = doc.licenseID;
    this.showPDFModal = true;
  }

  private closePDFModal(success: boolean): void {
    this.showPDFModal = false;
  }

  private cancelChanges(): void {
    this.socialData = cloneDeep(this.socialDataClone);
    this.saCorrActionTaken = cloneDeep(this.saCorrActionTakenClone);
    this.facilityStatus(this.activeAddress.id, this.socialData.status);
    this.confirmSave();
    this.counter = 0;
    this.changedkeys = [];
    this.showConfirmCancel = false;
    this.$store.commit('setEsgDataChanged', false);
  }

  private isActionEdited(index: number, status: boolean): void {
    this.socialData.sdsaQ05[index].isEdited = status;
    this.socialData.sdsaQ05.splice(index, 1, this.socialData.sdsaQ05[index]);
  }

  private getDateDifference(date: string): number {
    const start = moment(Date.now());
    const end = moment(date);
    const duration = moment.duration(end.diff(start));
    return Math.ceil(duration.asDays());
  }

  private openUploadEsgDocsModel(type: string, key: string): void {
    if(this.isReadOnly){
      return
    } else {
      this.uploadType = type;
      this.uploadDocKey = key;
      if(this.companyAddresses.length > 1){
        this.isFacilityModalVisible = true;
      } else {
        this.companyAddresses.forEach(address => {
          const facility: EsgAddressStatus = {
            addressID: address.id,
            status: '',
            isSelected: true
          };
          this.uploadDocSelectedFacility.push(facility);
        });
        this.isUploadDocModalVisible = true;
      }
    }
  }

  private showEsgUploadDoc(status: boolean, selectedFacilities: EsgAddressStatus[]): void {
    this.uploadDocSelectedFacility = [];
    this.uploadDocSelectedFacility.push(...selectedFacilities);
    this.isUploadDocModalVisible = status;
    this.isFacilityModalVisible = false;
  }

  private closeEsgUploadDoc(status: boolean, uploadedDoc: EsgUploadDocModel): void {
      if(uploadedDoc !== undefined && uploadedDoc !== null){
        (this.socialData as any)[this.uploadDocKey].push(uploadedDoc);
        this.isUploadDocModalVisible = !status;
        this.isFacilityModalVisible = false;
        this.counter++;
        this.checkAllSectionStatus();
      } else {
        this.isUploadDocModalVisible = !status;
        this.isFacilityModalVisible = this.companyAddresses.length > 1 ? true : false;
      }
  }

  private showDeleteDoc(deleteDocIndex: number, key: string): void {
    this.deleteDocIndex = deleteDocIndex;
    this.deleteDocKey = key;
    this.showDeleteDocModal = true;
  }

  private deleteDoc(): void {
    (this.socialData as any)[this.deleteDocKey].splice(this.deleteDocIndex, 1);
    this.counter++;
    this.showDeleteDocModal = false;
  }

  private validateQuestions(data: EsgSocialModel): EsgSocialModel {
    if(data.sdsaQ01.value !== this.yes){
      data.socialCert = [];
      data.sdsaQ02.value = '';
      data.sdsaQ02.updatedAt = new Date().toISOString();
      data.sdsaQ02.updatedBy = this.user.userId;
      data.sdsaQ03.value = '';
      data.sdsaQ03.updatedAt = new Date().toISOString();
      data.sdsaQ03.updatedBy = this.user.userId;
      data.sdsaQ03Docs = [];
      data.sdsaQ04.value = '';
      data.sdsaQ04.updatedAt = new Date().toISOString();
      data.sdsaQ04.updatedBy = this.user.userId;
      data.sdsaQ05 = [];
      this.saCorrActionTaken.text = '';
      this.saCorrActionTaken.updatedAt = '';
      this.saCorrActionTaken.updatedBy = '';
      // this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ02'), 1);
      // this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ03'), 1);
      // this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ04'), 1);
      // this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ05'), 1);
    }
    if(data.sdsaQ01.value !== this.no) {
      data.sdsaQ06.value = '';
      data.sdsaQ06.updatedAt = new Date().toISOString();
      data.sdsaQ06.updatedBy = this.user.userId;
      data.sdsaQ06Docs = [];
      // this.changedkeys.splice(this.changedkeys.indexOf('sdsaQ06'), 1);
    }
    if(data.sdsaQ03.value !== this.yes){
      data.sdsaQ03Docs = [];
      data.sdsaQ04.value = '';
      data.sdsaQ04.updatedAt = new Date().toISOString();
      data.sdsaQ04.updatedBy = this.user.userId;
      data.sdsaQ05 = [];
      this.saCorrActionTaken.text = '';
      this.saCorrActionTaken.updatedAt = '';
      this.saCorrActionTaken.updatedBy = '';
    }
    if(data.sdsaQ06.value !== this.yes){
      data.sdsaQ06Docs = [];
    }
    if(data.sdhsQ01.value !== this.yes){
      data.sdhsQ02 = [];
      data.sdhsQ03.value = '';
      data.sdhsQ03.updatedAt = new Date().toISOString();
      data.sdhsQ03.updatedBy = this.user.userId;
      data.sdhsQ04 = [];
    }
    if(data.sdhsQ03.value !== this.yes){
      data.sdhsQ04 = [];
    }
    if(data.sddeoQ01.value !== this.yes){
      data.sddeoQ01Docs = [];
      data.sddeoQ02.value = '';
      data.sddeoQ02.updatedAt = new Date().toISOString();
      data.sddeoQ02.updatedBy = this.user.userId;
      data.sddeoQ03 = [];
    }
    if(data.sddeoQ02.value !== this.yes){
      data.sddeoQ03 = [];
    }
    if(data.sdlwwQ01.value !== this.yes){
      data.sdlwwQ01Docs = [];
      data.sdlwwQ02.value = '';
      data.sdlwwQ02.updatedAt = new Date().toISOString();
      data.sdlwwQ02.updatedBy = this.user.userId;
      data.sdlwwQ03 = [];
    }
    if(data.sdlwwQ02.value !== this.yes){
      data.sdlwwQ03 = [];
    }
    if(data.sdlwwQ05.value !== this.yes){
      data.sdlwwQ06.value = '';
      data.sdlwwQ06.updatedAt = new Date().toISOString();
      data.sdlwwQ06.updatedBy = this.user.userId;
    }
    if(data.sdcflQ01.value !== this.yes){
      data.sdcflQ01Docs = [];
      data.sdcflQ02.value = '';
      data.sdcflQ02.updatedAt = new Date().toISOString();
      data.sdcflQ02.updatedBy = this.user.userId;
      data.sdcflQ03 = [];
    }
    if(data.sdcflQ02.value !== this.yes){
      data.sdcflQ03 = [];
    }
    if(data.sdwccQ01.value !== this.yes){
      data.sdwccQ01Docs = [];
      data.sdwccQ02.value = '';
      data.sdwccQ02.updatedAt = new Date().toISOString();
      data.sdwccQ02.updatedBy = this.user.userId;
      data.sdwccQ03 = [];
    }
    if(data.sdwccQ02.value !== this.yes){
      data.sdwccQ03 = [];
    }
    if(data.sdwccQ04.value !== this.yes){
      data.sdwccQ05.value = '';
      data.sdwccQ05.updatedAt = new Date().toISOString();
      data.sdwccQ05.updatedBy = this.user.userId;
      data.sdwccQ06.value = '';
      data.sdwccQ06.updatedAt = new Date().toISOString();
      data.sdwccQ06.updatedBy = this.user.userId;
    }
    if(data.sddisQ01.value !== this.yes){
      data.sddisQ01Docs = [];
      data.sddisQ02.value = '';
      data.sddisQ02.updatedAt = new Date().toISOString();
      data.sddisQ02.updatedBy = this.user.userId;
      data.sddisQ03 = [];
    }
    if(data.sddisQ02.value !== this.yes){
      data.sddisQ03 = [];
    }
    if(data.sdshmQ01.value !== this.yes){
      data.sdshmQ01Docs = [];
      data.sdshmQ02.value = '';
      data.sdshmQ02.updatedAt = new Date().toISOString();
      data.sdshmQ02.updatedBy = this.user.userId;
      data.sdshmQ03 = [];
    }
    if(data.sdshmQ02.value !== this.yes){
      data.sdshmQ03 = [];
    }
    if(data.sdgrmQ01.value !== this.no){
      data.sdgrmQ04.value = '';
      data.sdgrmQ04.updatedAt = new Date().toISOString();
      data.sdgrmQ04.updatedBy = this.user.userId;
      data.sdgrmQ05.value = '';
      data.sdgrmQ05.updatedAt = new Date().toISOString();
      data.sdgrmQ05.updatedBy = this.user.userId;
    }
    if(data.sdgrmQ01.value !== this.yes) {
      data.sdgrmQ02.value = '';
      data.sdgrmQ02.updatedAt = new Date().toISOString();
      data.sdgrmQ02.updatedBy = this.user.userId;
      data.sdgrmQ03.value = '';
      data.sdgrmQ03.updatedAt = new Date().toISOString();
      data.sdgrmQ03.updatedBy = this.user.userId;
    }
    if(data.sdgrmQ02.value !== this.yes){
      data.sdgrmQ03.value = '';
      data.sdgrmQ03.updatedAt = new Date().toISOString();
      data.sdgrmQ03.updatedBy = this.user.userId;
    }
    if(data.sdgrmQ04.value !== this.yes){
      data.sdgrmQ05.value = '';
      data.sdgrmQ05.updatedAt = new Date().toISOString();
      data.sdgrmQ05.updatedBy = this.user.userId;
    }
    return data;
  }

  private async save(): Promise<void>{
    try{
      this.isSaving = true;
      const socialIndex = this.esgSocial.findIndex(s => s.addressID === this.activeAddress.id);
      this.socialData.ID = socialIndex !== -1 ? this.esgSocial[socialIndex].ID : uuidv4();
      this.socialData.addressID = this.activeAddress.id;
      this.socialData.addressStatus = this.activeAddress.addressStatus;
      this.socialData.companyID = this.user.companyId;
      this.socialData.reminderSentDate = this.socialData.reminderSentDate !== "" && this.socialData.reminderSentDate !== this.defaultDate ? this.socialData.reminderSentDate : this.defaultDate;
      this.socialData.createdAt = new Date().toISOString();
      this.socialData.updatedAt = new Date().toISOString();
      this.socialData.sdLastUpdatedAt = dateTime();
      let social = cloneDeep(this.socialData);
      social = this.validateQuestions(social);
      if(this.saCorrActionTaken.text !== ""){
        social.sdsaQ05.push(this.saCorrActionTaken);
      }
      social.sddeoQ04.totalEmployees.value = this.socialData.sddeoQ04.totalEmployees.value !== this.notStartedStatus ? this.socialData.sddeoQ04.totalEmployees.value : -1;
      social.sddeoQ04.femaleEmployees.value = this.socialData.sddeoQ04.femaleEmployees.value !== this.notStartedStatus ? this.socialData.sddeoQ04.femaleEmployees.value : -1;
      social.sddeoQ04.maleEmployees.value = this.socialData.sddeoQ04.maleEmployees.value !== this.notStartedStatus ? this.socialData.sddeoQ04.maleEmployees.value : -1;
      social.sddeoQ05.value = this.socialData.sddeoQ05.value !== this.notStartedStatus ? this.socialData.sddeoQ05.value : -1;
      social.sddeoQ06.value = this.socialData.sddeoQ06.value !== this.notStartedStatus ? this.socialData.sddeoQ06.value : -1;
      social.sdwccQ05.value = this.socialData.sdwccQ05.value !== this.notStartedStatus ? this.socialData.sdwccQ05.value : -1;
      social.sdwccQ06.value = this.socialData.sdwccQ06.value !== this.notStartedStatus ? this.socialData.sdwccQ06.value : -1;
      const response = await this.supplierService.saveSocialEsgDataAsync(social);
      if(response.success){
        if(this.totalEmployeeUpdated || this.femaleEmployeeUpdated || this.maleEmployeeUpdated){
          const company = cloneDeep(this.company);
          let activeFacilityIndex = company.officeAddress.findIndex(add => add.id === this.activeAddress.id);
          if(activeFacilityIndex !== -1){
            if(this.totalEmployeeUpdated){
              company.officeAddress[activeFacilityIndex].employees = this.socialData.sddeoQ04.totalEmployees.value !== this.notStartedStatus ? Number(this.socialData.sddeoQ04.totalEmployees.value) : -1;
            }
            if(this.femaleEmployeeUpdated){
              company.officeAddress[activeFacilityIndex].femaleEmployees = this.socialData.sddeoQ04.femaleEmployees.value !== this.notStartedStatus ? Number(this.socialData.sddeoQ04.femaleEmployees.value) : -1;
            }
            if(this.maleEmployeeUpdated){
              company.officeAddress[activeFacilityIndex].maleEmployees = this.socialData.sddeoQ04.maleEmployees.value !== this.notStartedStatus ? Number(this.socialData.sddeoQ04.maleEmployees.value) : -1;
            }
            this.supplierService.updateSupplierAsync(company).then((response) => {
              if(response.result){
                this.$store.commit('setMyAccount', response.responseObject);
              }
            });
          } else {
            activeFacilityIndex = company.factoryAddresses.findIndex(add => add.id === this.activeAddress.id);
            if(activeFacilityIndex !== -1){
              if(this.totalEmployeeUpdated){
                company.factoryAddresses[activeFacilityIndex].employees = this.socialData.sddeoQ04.totalEmployees.value !== this.notStartedStatus ? Number(this.socialData.sddeoQ04.totalEmployees.value) : -1;
              }
              if(this.femaleEmployeeUpdated){
                company.factoryAddresses[activeFacilityIndex].femaleEmployees = this.socialData.sddeoQ04.femaleEmployees.value !== this.notStartedStatus ? Number(this.socialData.sddeoQ04.femaleEmployees.value) : -1;
              }
              if(this.maleEmployeeUpdated){
                company.factoryAddresses[activeFacilityIndex].maleEmployees = this.socialData.sddeoQ04.maleEmployees.value !== this.notStartedStatus ? Number(this.socialData.sddeoQ04.maleEmployees.value) : -1;
              }
            }
            this.supplierService.updateSupplierAsync(company).then((response) => {
              if(response.result){
                this.$store.commit('setMyAccount', response.responseObject);
              }
            });
          }
        }
        this.socialData = response.socialData.filter(sd => sd.addressID === this.activeAddress.id)[0];
        this.socialData.socialAuditExpanded = false;
        this.socialData.healthAndSafetyExpanded = false;
        this.socialData.diversityAndEqualOptyExpanded = false;
        this.socialData.livingWageWorkingHoursExpanded = false;
        this.socialData.workingConditionsExpanded = false;
        this.socialData.discriminationExpanded = false;
        this.socialData.childForcedLabourExpanded = false;
        this.socialData.sexualHarasmentExpanded = false;
        this.socialData.grievanceMechanismExpanded = false;
        this.parseNegativeValueToString();
        this.socialDataClone = cloneDeep(this.socialData);
        this.saCorrActionTaken = new EsgIdTextAnsModel();
        this.saCorrActionTakenClone = cloneDeep(this.saCorrActionTaken);
        this.confirmSave();
        this.counter = 0;
        this.changedkeys = [];
        this.$store.commit('setEsgDataChanged', false);
      }
    }
    finally{
      this.isSaving = false;
    }
  }

  @Watch('activeAddEsgSocial', { deep: true })
  private onSelectedAddChanged(): void {
    this.socialData = cloneDeep(this.activeAddEsgSocial);
    this.parseNegativeValueToString();
    this.socialDataClone = cloneDeep(this.socialData);
    this.toggleFirstIncompleteBeam();
    this.counter = 0;
    this.changedkeys = [];
  }

  @Emit()
  private facilityStatus(addId: string, status: string): void {}

  @Emit()
  private confirmSave(): void {}
}
