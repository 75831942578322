import { Vue, Component } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

// Store
import { ActionTypes } from '@/store/actions/actions';

// Services
import { UserClient } from '@/clients/userClient';

// Components
import PendingActionsTile from '@/views/home/components/pendingActionsTile.vue';
//removing pendingOrderLinesTile component not required any more sprint-18
import SustainabilityEffortsTile from '@/views/home/components/sustainabilityEffortsTile.vue';
import TotalStylesTile from '@/views/home/components/totalStyleTile.vue';

// Models
import { UserModel } from '@/models/userModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { CertificateModel } from '@/models/certificateModel';
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';

@Component({
  components: {
    PendingActionsTile,
    SustainabilityEffortsTile,
    TotalStylesTile,
  },
  computed: {
    ...mapGetters(['orders', 'complianceCertificates', 'orderLineEvidenceSteps', 'orderLineEvidenceStepsLoading', 'readOrSignDocumentsCount']),
  },
})
export default class Home extends Vue {
  private client: UserClient = new UserClient();

  private orders!: OrderLineDisplayModel[];

  private complianceCertificates!: CertificateModel[];
  //TTD-3575 computed propertied defined
  private orderLineEvidenceSteps!: OrderLineEvidenceStep[];

  private orderLineEvidenceStepsLoading!: boolean;

  private isMobile: boolean = false;
  private readOrSignDocumentsCount!:number;

  public constructor() {
    super();
  }

  private async created(): Promise<void> {
    if (screen.width <= 636) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const user = this.$store.getters.user as UserModel;

    if (user.companyId === null || user.companyId === undefined) {
      const result = await this.client.getUserInfoAsync();
      this.$store.commit('setUser', result);
    }
    if (this.complianceCertificates.length === 0) {
      this.$store.dispatch(ActionTypes.SET_COMPLIANCE_CERTIFICATES);
    }
    // Sprint-24 TTD-3798
    if(this.readOrSignDocumentsCount === 0){
      this.$store.dispatch(ActionTypes.SET_READ_OR_SIGN_DOCS);
    }
    //calling dashboard api to show the dashboard details TTD-3575
    if(this.isT1Scp || user.isDelegatePartner){
      await this.getOrders();
    }
  }

  private get userName(): string {
    const user = this.$store.getters.user as UserModel;
    if (user == null || user.name == null) {
      console.error('No user info found');
      return '-';
    }
    return user.firstName;
  }

  private get isT1Scp(): boolean {
    const user = this.$store.getters.user as UserModel;
    return user.isT1Scp;
  }

 //TTD-3575 fetch the details of dashboard
 private async getOrders(): Promise<void> {
  if(this.orders.length === 0){
      await this.$store.dispatch(ActionTypes.SET_ORDERLINE);
      //added check orderLinesSteps api will call only in mobile resoultion
      if(this.orderLineEvidenceSteps.length === 0 && !this.orderLineEvidenceStepsLoading && this.isMobile){
        this.$store.dispatch(ActionTypes.SET_ORDER_LINE_EVIDENCE_STEPS);
      }
    }
  }
}
