import moment from 'moment';

export class SelectPictureModel {
  public status: number = 0;
  public latitude: number = 0;
  public longitude: number = 0;
  public createdAt: moment.Moment = moment();
  public createdBy!: string;
  public fileName!: string;
  public documentType!: string;
  public content!: string;
  public fileUrl!: any;
  public size!: number;
  public progress!: number;
  public uploadedPicture!: EvidencePhotoListModel;
  public constructor() {}
}

export class UploadedPictureResponseModel {
  public docURL!: string;
  public message!: string;
  public path!: string;
  public evidencePhotoList!: EvidencePhotoListModel;
}

export class EvidencePhotoListModel {
  public ID!: string;
  public createdAt!: string;
  public createdBy!: string;
  public docURL!: string;
  public fileHash!: string;
  public fileName!: string;
  public fileType!: string;
  public geoLat!: number;
  public geoLong!: number;
  public status!: string | number;
  public isMainPicture!: boolean;
  public addressID!: string;
  public sequence!: number;
}
