import i18n from '@/i18n';
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { ActionTypes } from '@/store/actions/actions';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { NotificationHelper } from '@/helpers/notificationHelper';
import { mapGetters } from 'vuex';
import { debounce } from "ts-debounce";

// Components
import Stencil from '@/views/profile/components/stencil.vue';
import ProfileZoom from '@/views/profile/components/profileZoom.vue';

// Helpers
import { NonRomanCharCheckHelper }from '@/helpers/nonRomanCharCheckHelper'

// Models
import { UserModel, UserModelRequest } from '@/models/userModel';
import { ContactModel } from '@/models/contactModel';
import { CompanyModel } from '@/models/companyModel';

// Services
import { InvitationService } from '@/services/invitationService';
import { SupplierService } from '@/services/supplierService';
import { UserService } from '@/services/userService';
import store from '@/store';
import lodash from 'lodash';

@Component({
  components: { Cropper, Stencil, ProfileZoom },
  computed: {
    ...mapGetters(['userRole']),
  },
})
export default class MyDetails extends Vue {
  private changedSettingsCounter: number = 0;

  private isSaving: boolean = false;

  private isLoading: boolean = false;

  private user: ContactModel = new ContactModel();

  private userClone: ContactModel = new ContactModel();

  private company: CompanyModel = new CompanyModel();

  private supplierService: SupplierService;

  private invitationService: InvitationService;

  private userService: UserService;

  private isValid: boolean = true;

  private invitationId: string | null = '';

  private fileSrc: string = '';

  private croppedimage: string = '';

  private showProfileModal: boolean = false;

  private longitude: number = 0;

  private latitude: number = 0;

  private isProfileSaving: boolean = false;

  private showDeleteModal: boolean = false;

  private isProfileDeleting: boolean = false;

  private userProfile: string = '';

  private zoom: number = 0;

  private minW: number = 300;

  private minH: number = 300;

  private isMobile: boolean = false;

  private showLoader: boolean = false;

  private profilePictureName: string = '';

  private userRole!: string;

  private isMyphoneNonRoman: boolean = false;
  private checkFieldsDebounced: any;
  private firstNameError: boolean = false;
  private fNameSpecCharErr: boolean = false;

  public constructor() {
    super();
    this.supplierService = new SupplierService();
    this.invitationService = new InvitationService();
    this.userService = new UserService();
  }

  private mounted(): void {
    if (screen.width < 636) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    window.onresize = () => {
      if (window.innerWidth < 636) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    };
  }

  private async created(): Promise<void> {
    try {
      this.isLoading = true;
      this.checkFieldsDebounced = debounce(this.validateField, 250, { maxWait: 250, isImmediate: true });
      this.invitationId = localStorage.getItem('invitationId');
      if (this.invitationId) {
        if (store.getters.company != null) {
          this.company = store.getters.company;
          this.setUserInformation();
        } else {
          this.company =
            await this.invitationService.getSupplierByInvitationIdAsync(
              this.invitationId
            );
          this.setUserInformation();
        }
      } else {
        if (store.getters.company != null) {
          this.company = store.getters.company;
          this.setUserInformation();
        } else {
          store.commit('setCertificatesCount', 0);
          await store.dispatch(ActionTypes.SET_MYACCOUNT);
          this.company = store.getters.company;
          this.setUserInformation();
        }
      }
      store.commit('setMyAccount', this.company);
      store.commit('setSavedOfficeAddresses', this.company.officeAddress);
      store.commit('setSavedFactoryAddresses', this.company.factoryAddresses);
      this.userClone = lodash.cloneDeep(this.user);
    } catch (e) {
      //
    } finally {
      this.isLoading = false;
      this.isFormValid(true);
    }
  }

  // TTD-4477, for adding validations on firstname
  private async validateField(event: any, fieldname: string): Promise<void> {
    var specials=/[@()[\];:<>, ]/;
    switch (fieldname) {
      case "firstName":
        if (event.length < 2) {
            this.firstNameError = true;
        } else {
            this.firstNameError = false;
        }
        if (specials.test(event) && !(event.length < 2)) {
            this.fNameSpecCharErr = true;
        } else {
            this.fNameSpecCharErr = false;
        }
      break;
      
      default:
        break;
    }
  }

  // TTD-4477, for adding validations on firstname
  private get relatedValidations(): boolean {
    return !this.isValid || this.firstNameError || this.fNameSpecCharErr;
  }

  private setUserInformation(): void {
    const user = this.$store.getters.user as UserModel;
    this.user.firstName = user.firstName;
    this.user.lastName = user.lastName;
    this.user.email = user.email;
    user.phone =
      user.phone === undefined || user.phone === null ? '' : user.phone;
    this.user.phone = this.invitationId
      ? this.company.admin.phone
      : user.phone === ''
      ? this.company.admin.phone
      : user.phone;
    if (this.user.phone == '' || this.user.phone === undefined) {
      this.isValid = false;
    }
    this.company.admin = this.user;
  }

  private takePicture(): void {
    if (this.invitationId) {
      return;
    }
    const inputFile = <HTMLDivElement>document.querySelector('.camera-file');
    inputFile.click();
  }

  private uploadProfileFromGallery(event: any): void {
    const app = this;
    const maxHeight: number = 480;
    this.showLoader = true;
    const imgObject = new Image();
    for (let index = 0; index < event.target.files.length; index++) {
      this.profilePictureName = event.target.files[index].name;
      const selectedFile = event.target.files[index];
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (function (oldFile: any, app: any) {
        return (): void => {
          app.fileSrc = reader.result as string;
          app.showProfileModal = true;
        };
      })(selectedFile, app);
      reader.onerror = (): void => {
        NotificationHelper.createErrorNotification(
          i18n.t('errors.image_invalid').toString()
        );
      };
    }
  }

  private getCropImage(coordinates: any, canvas: any): void {
    this.croppedimage = coordinates.canvas.toDataURL();
  }

  private async saveProfile(): Promise<void> {
    this.isProfileSaving = true;
    const file = this.dataURItoFile(this.profilePictureName, this.croppedimage);
    const formData = new FormData();
    formData.append('geoLat', '0');
    formData.append('geoLong', '0');
    formData.append('upload', file);
    const user: any = this.$store.getters.user;
    const response = false;
    if (this.userProfile) {
      formData.append(
        'createdAt',
        user.userProfilePics[user.userProfilePics.length - 1].createdAt
      );
      formData.append(
        'createdBy',
        user.userProfilePics[user.userProfilePics.length - 1].createdBy
      );
      const response = await this.supplierService.updateProfilePictureAsync(
        user.userId,
        formData
      );
      if (response) {
        const info = await this.userService.getUserInfoByIdAsync(user.userId);
        const localStorageUser = JSON.parse(
          localStorage.getItem('user') || '{}'
        );
        localStorageUser.userProfilePics = info.userProfilePics;
        localStorage.setItem('user', JSON.stringify(localStorageUser));
        this.$store.commit('setUser', info);
        this.profilePictureName = '';
        this.showProfileModal = false;
      }
    } else {
      const response = await this.supplierService.uploadProfilePictureAsync(
        user.userId,
        formData
      );
      if (response) {
        const info = await this.userService.getUserInfoByIdAsync(user.userId);
        const localStorageUser = JSON.parse(
          localStorage.getItem('user') || '{}'
        );
        localStorageUser.userProfilePics = info.userProfilePics;
        localStorage.setItem('user', JSON.stringify(localStorageUser));
        this.$store.commit('setUser', info);
        this.profilePictureName = '';
        this.showProfileModal = false;
      }
    }
    this.isProfileSaving = false;
  }

  private showDelete(): void {
    this.showDeleteModal = true;
  }

  private closeDeleteModal(): void {
    this.showDeleteModal = false;
  }

  private async deletePicture(): Promise<void> {
    this.isProfileDeleting = true;
    const user: any = this.$store.getters.user;
    const profileId = user.userProfilePics[user.userProfilePics.length - 1].ID;
    const response = await this.supplierService.deleteProfilePictureAsync(
      user.userId,
      profileId
    );
    const info = await this.userService.getUserInfoByIdAsync(user.userId);
    const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}');
    localStorageUser.userProfilePics = info.userProfilePics;
    localStorage.setItem('user', JSON.stringify(localStorageUser));
    this.$store.commit('setUser', info);
    this.userProfile = '';
    this.isProfileDeleting = false;
    this.showDeleteModal = false;
  }

  private get profileUploaded(): boolean {
    const user: any = this.$store.getters.user;
    if (user.userProfilePics && user.userProfilePics.length > 0) {
      this.userProfile =
        user.userProfilePics[user.userProfilePics.length - 1].docURL;
      return user.userProfilePics[user.userProfilePics.length - 1];
    } else {
      return false;
    }
  }

  private imageReady(): void {
    this.showLoader = false;
  }

  private dataURItoFile(filename: string, dataURI: string): File {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const fileBlob = new Blob([ab], { type: mimeString });
    return new File([fileBlob], filename, { type: mimeString });
  }

  private closeProfileModal(): void {
    this.showProfileModal = false;
    this.croppedimage = '';
  }

  private cancelChanges(): void {
    this.user = this.userClone;
    this.updateStore(true);
    this.isMyphoneNonRoman = false;
  }

  private updateStore(valid: boolean): void {
    if (this.user.phone !== '' && this.user.phone.length >= 10) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
    this.company.admin = this.user;
    store.commit('setMyAccount', this.company);
    this.compareUserClone();
    this.isFormValid(valid);
  }

  private compareUserClone(): void {
    if (
      this.invitationId === '' ||
      this.invitationId === null ||
      this.invitationId === undefined
    ) {
      let changes: number = 0;
      const user: [string, any][] = Object.entries(this.user);
      const userClone: [string, any][] = Object.entries(this.userClone);
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const getObjectDiff = (obj1: any, obj2: any) => {
        const obj1Props = Object.keys(obj1);
        const obj2Props = Object.keys(obj2);

        const keysWithDiffValue = obj1Props.reduce(
          (keysWithDiffValueAccumulator, key) => {
            // eslint-disable-next-line no-prototype-builtins
            const propExistsOnObj2 = obj2.hasOwnProperty(key);
            const hasNestedValue =
              obj1[key] instanceof Object && obj2[key] instanceof Object;
            const keyValuePairBetweenBothObjectsIsEqual =
              obj1[key] === obj2[key];

            if (!propExistsOnObj2) {
              keysWithDiffValueAccumulator.push(key);
            } else if (hasNestedValue) {
              const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
              if (keyIndex >= 0) {
                keysWithDiffValueAccumulator.splice(keyIndex, 1);
              }
              const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
              for (const diff of nestedDiffs) {
                keysWithDiffValueAccumulator.push(`${key}.${diff}`);
              }
            } else if (keyValuePairBetweenBothObjectsIsEqual) {
              const equalValueKeyIndex =
                keysWithDiffValueAccumulator.indexOf(key);
              keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
            }
            return keysWithDiffValueAccumulator;
          },
          obj2Props
        );

        return keysWithDiffValue;
      };
      changes += getObjectDiff(user, userClone).length;
      this.changedSettingsCounter = changes;
      this.changedSettings(changes);
    }
  }

  private onZoom(value: number, asc: boolean) {
    this.minW = 130;
    this.minH = 130;
    const cropper: any = this.$refs.cropper;
    if (cropper) {
      if (!asc) {
        if (value >= 1.9) {
          cropper.zoom(0.9);
        } else if (value >= 1.5 && value < 1.9) {
          cropper.zoom(0.9);
        } else if (value >= 1.4 && value < 1.5) {
          cropper.zoom(0.8);
        } else if (value >= 1.3 && value < 1.4) {
          cropper.zoom(0.8);
        } else if (value >= 1.2 && value < 1.3) {
          cropper.zoom(0.8);
        } else if (value >= 1.1 && value < 1.2) {
          cropper.zoom(0.8);
        } else if (value >= 1 && value < 1.1) {
          cropper.zoom(0);
        }
      } else {
        cropper.zoom(value);
      }
    }
  }

  private vueClickedOutside(): void {
    this.showDeleteModal = false;
  }

  private async save(): Promise<void> {
    try {
      this.isSaving = true;
      const userDetails: UserModelRequest = new UserModelRequest();
      const user = this.$store.getters.user as UserModel;
      userDetails.userId = user.userId;
      userDetails.firstName = this.user.firstName.trim();
      userDetails.lastName = this.user.lastName.trim();
      userDetails.email = this.user.email.trim();
      userDetails.phone = this.user.phone.trim();
      this.user.phone = this.user.phone.trim();
      if (this.user.phone.length < 10) {
        return;
      }
      const response = await this.userService.updateUser(userDetails);
      if (response.result === 'success') {
        this.user.firstName = this.user.firstName.trim();
        this.user.lastName = this.user.lastName.trim();
        this.user.email = this.user.email.trim();
        this.user.phone = this.user.phone.trim();
        user.firstName = this.user.firstName;
        user.lastName = this.user.lastName;
        user.phone = this.user.phone;
        user.name = this.user.firstName + ' ' + this.user.lastName;
        store.commit('setUser', user);
        this.userClone = lodash.cloneDeep(this.user);
        this.changedSettingsCounter = 0;
        this.changedSettings(this.changedSettingsCounter);
      }
    } finally {
      this.isSaving = false;
    }
  }

  private async isFormValid(valid: boolean): Promise<void> {
    let formValid = true;
    formValid = valid;
    if (!this.isValid) {
      formValid = false;
    }
    this.formValid(formValid);
  }

  private async checkNonRomanChar(variableName:string,val:string){
    (this as any)[variableName] = await NonRomanCharCheckHelper.checkNonRomanChar(val);
  }

  @Emit()
  private nextTab(): void {}

  @Emit()
  private changedSettings(value: number): void {}

  @Emit()
  private formValid(valid: boolean): void {}

  @Watch('user')
  private async onContactChanged(user: ContactModel): Promise<void> {
    this.userClone = lodash.cloneDeep(user);
    this.compareUserClone();
  }
}
