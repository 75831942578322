import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { Guid } from "@/models/guid";
import { mapState } from "vuex";
import lodash from "lodash";
import {countBy, where, map} from 'underscore';
import { UserModel } from "@/models/userModel";

// Helpers
import { StepsHelper } from "@/helpers/stepsHelper";
import { StepReferenceModel } from "@/models/orderLineStepModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderLineStepParallelModel } from "@/models/orderLineStepParallelModel";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    computed: mapState(['isFootprintChanged', 'changedFootprintName', 'companyNameList']),
  })
export default class OrderlineSteps extends Vue {

    @Prop()
    private steps!: OrderLineStepDisplayModel[];

    @Prop()
    private orderDetail!: OrderLineDisplayModel;

    @Prop()
    private showInfo!: boolean;

    @Prop()
    private totalParallelChains!: number;

    private selectedStep: string = Guid.Empty;

    private parentStepId: string = '';

    private selectedParallelStep: OrderLineStepDisplayModel = new OrderLineStepDisplayModel();

    private parallelChainIndex: number = -1;

    private footprintStepsWithRef: StepReferenceModel[] = [];

    private user: UserModel = new UserModel();

    private isFootprintChanged!: string;

    private companyNameList!: CompanyNameModel[];

    //Sprint-20 TTD-3931 development
    private created(): void{
        this.user = this.$store.getters.user as UserModel;
    }

    private mounted(): void {
        if (this.showInfo) {
            this.calculateDistance(true);
        }
    }

    private get getSteps(): OrderLineStepDisplayModel[] {
        let allSteps: OrderLineStepDisplayModel[] = [];
        allSteps = lodash.cloneDeep(this.steps);
        this.footprintStepsWithRef = [];
        allSteps.sort((step1, step2) =>{
            return step1.sequence - step2.sequence;
        });
        allSteps.forEach(step => {
            if(this.isFootprintMCStep(step.stepId, allSteps)){
                const stepRef: StepReferenceModel = {
                    toCompanyId: step.toCompanyId,
                    toCompanyName: this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName,
                    mcStepUniqueId: step.id,
                    pcStepUniqueId: Guid.Empty,
                    refNo: step.footprintRefNo,
                    mcSequence: step.sequence,
                    pcOfMC: -1,
                    pcSequence: -1,
                    stepId: step.stepId,
                    footprintRefNoPrefix: step.footprintRefNo
                }
                this.footprintStepsWithRef.push(stepRef);
                step.footprintRefNoPrefix = step.footprintRefNo;
            }
            if (step.parallelSupplyChain0 && step.parallelSupplyChain0.length > 0) {
                step.parallelSupplyChain0.sort((step1, step2) =>{
                    return step1.sequence - step2.sequence;
                });
                step.parallelSupplyChain0.forEach(ps => {
                    if(this.isFootprintPCStep(ps.stepId, step.parallelSupplyChain0)){
                        const pcStepRef: StepReferenceModel = {
                            toCompanyId: ps.toCompanyId,
                            toCompanyName: this.companyNameList.filter(c => c.ID === ps.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === ps.toCompanyId)[0].name : ps.toCompanyName,
                            mcStepUniqueId: step.id,
                            pcStepUniqueId: ps.id,
                            refNo: ps.footprintRefNo,
                            mcSequence: step.sequence,
                            pcOfMC: 0,
                            pcSequence: ps.sequence,
                            stepId: ps.stepId,
                            footprintRefNoPrefix: ps.footprintRefNo
                        }
                        this.footprintStepsWithRef.push(pcStepRef);
                        ps.footprintRefNoPrefix = ps.footprintRefNo;
                    }
                })
            }
            if (step.parallelSupplyChain1 && step.parallelSupplyChain1.length > 0) {
                step.parallelSupplyChain1.sort((step1, step2) =>{
                    return step1.sequence - step2.sequence;
                });
                step.parallelSupplyChain1.forEach(ps => {
                    if(this.isFootprintPCStep(ps.stepId, step.parallelSupplyChain1)){
                        const pcStepRef: StepReferenceModel = {
                            toCompanyId: ps.toCompanyId,
                            toCompanyName: this.companyNameList.filter(c => c.ID === ps.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === ps.toCompanyId)[0].name : ps.toCompanyName,
                            mcStepUniqueId: step.id,
                            pcStepUniqueId: ps.id,
                            refNo: ps.footprintRefNo,
                            mcSequence: step.sequence,
                            pcOfMC: 1,
                            pcSequence: ps.sequence,
                            stepId: ps.stepId,
                            footprintRefNoPrefix: ps.footprintRefNo
                        }
                        this.footprintStepsWithRef.push(pcStepRef);
                        ps.footprintRefNoPrefix = ps.footprintRefNo;
                    }
                })
            }
            if (step.parallelSupplyChain2 && step.parallelSupplyChain2.length > 0) {
                step.parallelSupplyChain2.sort((step1, step2) =>{
                    return step1.sequence - step2.sequence;
                });
                step.parallelSupplyChain2.forEach(ps => {
                    if(this.isFootprintPCStep(ps.stepId, step.parallelSupplyChain2)){
                        const pcStepRef: StepReferenceModel = {
                            toCompanyId: ps.toCompanyId,
                            toCompanyName: this.companyNameList.filter(c => c.ID === ps.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === ps.toCompanyId)[0].name : ps.toCompanyName,
                            mcStepUniqueId: step.id,
                            pcStepUniqueId: ps.id,
                            refNo: ps.footprintRefNo,
                            mcSequence: step.sequence,
                            pcOfMC: 2,
                            pcSequence: ps.sequence,
                            stepId: ps.stepId,
                            footprintRefNoPrefix: ps.footprintRefNo
                        }
                        this.footprintStepsWithRef.push(pcStepRef);
                        ps.footprintRefNoPrefix = ps.footprintRefNo;
                    }
                })
            }
            if (step.parallelSupplyChain3 && step.parallelSupplyChain3.length > 0) {
                step.parallelSupplyChain3.sort((step1, step2) =>{
                    return step1.sequence - step2.sequence;
                });
                step.parallelSupplyChain3.forEach(ps => {
                    if(this.isFootprintPCStep(ps.stepId, step.parallelSupplyChain3)){
                        const pcStepRef: StepReferenceModel = {
                            toCompanyId: ps.toCompanyId,
                            toCompanyName: this.companyNameList.filter(c => c.ID === ps.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === ps.toCompanyId)[0].name : ps.toCompanyName,
                            mcStepUniqueId: step.id,
                            pcStepUniqueId: ps.id,
                            refNo: ps.footprintRefNo,
                            mcSequence: step.sequence,
                            pcOfMC: 3,
                            pcSequence: ps.sequence,
                            stepId: ps.stepId,
                            footprintRefNoPrefix: ps.footprintRefNo
                        }
                        this.footprintStepsWithRef.push(pcStepRef);
                        ps.footprintRefNoPrefix = ps.footprintRefNo;
                    }
                })
            }
            if (step.parallelSupplyChain4 && step.parallelSupplyChain4.length > 0) {
                step.parallelSupplyChain4.sort((step1, step2) =>{
                    return step1.sequence - step2.sequence;
                });
                step.parallelSupplyChain4.forEach(ps => {
                    if(this.isFootprintPCStep(ps.stepId, step.parallelSupplyChain4)){
                        const pcStepRef: StepReferenceModel = {
                            toCompanyId: ps.toCompanyId,
                            toCompanyName: this.companyNameList.filter(c => c.ID === ps.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === ps.toCompanyId)[0].name : ps.toCompanyName,
                            mcStepUniqueId: step.id,
                            pcStepUniqueId: ps.id,
                            refNo: ps.footprintRefNo,
                            mcSequence: step.sequence,
                            pcOfMC: 4,
                            pcSequence: ps.sequence,
                            stepId: ps.stepId,
                            footprintRefNoPrefix: ps.footprintRefNo
                        }
                        this.footprintStepsWithRef.push(pcStepRef);
                        ps.footprintRefNoPrefix = ps.footprintRefNo;
                    }
                })
            }
        })
        allSteps = this.footprintRefPrefixStep(allSteps);
        return allSteps;
    }

    private calculateDistance(onRender: boolean): void {
        if (this.showInfo) {
            setTimeout(async (app: any) => {
                const leftPoint = document.getElementById('scrollContent-'+this.orderDetail.id)!.getBoundingClientRect();
                if (document.getElementById('leftest-title-'+this.orderDetail.id) && document.getElementById('leftest-title-'+this.orderDetail.id)!.getBoundingClientRect().x < leftPoint.x) {
                    let padding = 0;
                    if (onRender) {
                        const required = leftPoint.x - document.getElementById('leftest-title-'+this.orderDetail.id)!.getBoundingClientRect().x;
                        padding = required;
                        const idname = 'hideborder-'+this.orderDetail.id;
                        const line = document.getElementById(idname) as HTMLElement;
                        line.style.left = '-'+padding+'px';
                        line.style.width = padding+24+'px';
                    } else {
                        padding = 200;
                    }
                    const outerElement = document.getElementById('scrollContent-'+this.orderDetail.id);
                    outerElement!.style.paddingLeft = padding+'px';
                }
            }, 250, this);
        } else {
            const outerElement = document.getElementById('scrollContent-'+this.orderDetail.id);
            outerElement!.style.paddingLeft = '0px';
        }

    }

    private selectStep(id: string, stepId: number): void {
        if (!this.isFPStepClickable(id, stepId)) {
            return;
        }
        if (this.isFootprintChanged !== '') {
            this.stepSelected(id, stepId);
        } else {
            this.selectedStep = id;
            this.stepSelected(this.selectedStep, stepId);
        }
    }

    private removeLineOnScroll(event: any) {
        const id = 'scrollContent-'+this.orderDetail.id;
        const scrollDiv = document.getElementById(id) as HTMLInputElement;
        if (event.currentTarget.scrollTop > 0) {
            scrollDiv.style.backgroundPositionY = 40-event.currentTarget.scrollTop+'px';
        } else {
            scrollDiv.style.backgroundPositionY = 40+event.currentTarget.scrollTop+'px';
        }
    }

    private stepActive(id: string): boolean {
        return this.selectedStep != Guid.Empty && id !== Guid.Empty ? (this.selectedStep === id ? true : false) : false;
    }

    private displayName(stepId: number): string{
        return this.$t(`enums.steps.${stepId}`).toString();
    }

    private displayPartnerName(step: OrderLineStepDisplayModel, pcSteps: OrderLineStepParallelModel[]): string{
        if(this.orderDetail.toCompanyId === this.user.companyId || this.orderDetail.delegateCompanyID === this.user.companyId){
            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
        } else {
            if(step.toCompanyId === this.user.companyId){
                return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
            } else {
                const allFootprintStepIds = StepsHelper.getFootprintSteps().map(s => s.id);
                if(pcSteps !== undefined){
                    const currStepIndex = pcSteps.findIndex(s => s.id === step.id);
                    const prevStep = pcSteps[currStepIndex - 1];
                    const nextStep = pcSteps[currStepIndex + 1];
                    if(prevStep && nextStep){
                        const steps = this.footprintStepsWithRef.filter(f => f.pcStepUniqueId === step.id);
                        if(steps.length > 0){
                            const supplierMCSteps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === steps[0].mcStepUniqueId && f.toCompanyId === this.user.companyId);
                            if(supplierMCSteps.length > 0){
                                return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                            }
                        } else if(prevStep.toCompanyId === this.user.companyId || nextStep.toCompanyId === this.user.companyId){
                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                        } else if (allFootprintStepIds.includes(step.stepId)){
                            if(step.stepId === 3 || step.stepId === 22){
                                if(step.stepId === 3){
                                    const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(pcSteps.findIndex(s => s.stepId === 3) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                            if(step.stepId === 4 || step.stepId === 5 || step.stepId === 23){
                                if(step.stepId === 4 || step.stepId === 5){
                                    const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(pcSteps.findIndex(s => s.stepId === 4 || s.stepId === 5) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                        }
                        return '';
                    } else if(nextStep){
                        const steps = this.footprintStepsWithRef.filter(f => f.pcStepUniqueId === step.id);
                        if(steps.length > 0){
                            const supplierMCSteps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === steps[0].mcStepUniqueId && f.toCompanyId === this.user.companyId);
                            if(supplierMCSteps.length > 0){
                                return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                            }
                        } else if(nextStep.toCompanyId === this.user.companyId){
                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                        } else if (allFootprintStepIds.includes(step.stepId)){
                            if(step.stepId === 3 || step.stepId === 22){
                                if(step.stepId === 3){
                                    const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(pcSteps.findIndex(s => s.stepId === 3) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                            if(step.stepId === 4 || step.stepId === 5 || step.stepId === 23){
                                if(step.stepId === 4 || step.stepId === 5){
                                    const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(pcSteps.findIndex(s => s.stepId === 4 || s.stepId === 5) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                        }
                        return '';
                    } else if(prevStep){
                        const steps = this.footprintStepsWithRef.filter(f => f.pcStepUniqueId === step.id);
                        if(steps.length > 0){
                            const supplierMCSteps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === steps[0].mcStepUniqueId && f.toCompanyId === this.user.companyId);
                            if(supplierMCSteps.length > 0){
                                return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                            }
                        } else if(prevStep.toCompanyId === this.user.companyId){
                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                        } else if (allFootprintStepIds.includes(step.stepId)){
                            if(step.stepId === 3 || step.stepId === 22){
                                if(step.stepId === 3){
                                    const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(pcSteps.findIndex(s => s.stepId === 3) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                            if(step.stepId === 4 || step.stepId === 5 || step.stepId === 23){
                                if(step.stepId === 4 || step.stepId === 5){
                                    const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(pcSteps.findIndex(s => s.stepId === 4 || s.stepId === 5) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = pcSteps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                        }
                        return '';
                    } else if (prevStep === undefined && nextStep === undefined){
                        const steps = this.footprintStepsWithRef.filter(f => f.pcStepUniqueId === step.id);
                        if(steps.length > 0){
                            const supplierMCSteps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === steps[0].mcStepUniqueId && f.toCompanyId === this.user.companyId);
                            if(supplierMCSteps.length > 0){
                                return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                            }
                        }
                    }
                    return '';
                } else {
                    const currStepIndex = this.steps.findIndex(s => s.id === step.id);
                    const prevStep = this.steps[currStepIndex - 1];
                    const nextStep = this.steps[currStepIndex + 1];
                    if(prevStep && nextStep){
                        if(prevStep.toCompanyId === this.user.companyId || nextStep.toCompanyId === this.user.companyId){
                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                        } else if (allFootprintStepIds.includes(step.stepId)){
                            if(step.stepId === 3 || step.stepId === 22){
                                if(step.stepId === 3){
                                    const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(this.steps.findIndex(s => s.stepId === 3) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                            if(step.stepId === 4 || step.stepId === 5 || step.stepId === 23){
                                if(step.stepId === 4 || step.stepId === 5){
                                    const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(this.steps.findIndex(s => s.stepId === 4 || s.stepId === 5) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                        } else if (this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty).length > 0){
                            if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 0).length > 0){
                                const pc0Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 0);
                                pc0Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc0Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 1).length > 0){
                                const pc1Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 1);
                                pc1Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc1Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 2).length > 0){
                                const pc2Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 2);
                                pc2Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc2Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 3).length > 0){
                                const pc3Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 3);
                                pc3Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc3Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 4).length > 0){
                                const pc4Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 4);
                                pc4Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc4Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            }
                        }
                        return '';
                    } else if(nextStep){
                        if(nextStep.toCompanyId === this.user.companyId){
                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                        } else if (allFootprintStepIds.includes(step.stepId)){
                            if(step.stepId === 3 || step.stepId === 22){
                                if(step.stepId === 3){
                                    const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(this.steps.findIndex(s => s.stepId === 3) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                            if(step.stepId === 4 || step.stepId === 5 || step.stepId === 23){
                                if(step.stepId === 4 || step.stepId === 5){
                                    const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(this.steps.findIndex(s => s.stepId === 4 || s.stepId === 5) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                        } else if (this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty).length > 0){
                            if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 0).length > 0){
                                const pc0Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 0);
                                pc0Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc0Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 1).length > 0){
                                const pc1Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 1);
                                pc1Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc1Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 2).length > 0){
                                const pc2Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 2);
                                pc2Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc2Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 3).length > 0){
                                const pc3Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 3);
                                pc3Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc3Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 4).length > 0){
                                const pc4Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 4);
                                pc4Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc4Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            }
                        }
                        return '';
                    } else if(prevStep){
                        if(prevStep.toCompanyId === this.user.companyId){
                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                        } else if (allFootprintStepIds.includes(step.stepId)){
                            if(step.stepId === 3 || step.stepId === 22){
                                if(step.stepId === 3){
                                    const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(this.steps.findIndex(s => s.stepId === 3) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23 || s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                            if(step.stepId === 4 || step.stepId === 5 || step.stepId === 23){
                                if(step.stepId === 4 || step.stepId === 5){
                                    const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                    if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                        return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                    }
                                } else {
                                    if(this.steps.findIndex(s => s.stepId === 4 || s.stepId === 5) === -1){
                                        return '';
                                    } else {
                                        const supplierSteps = this.steps.filter(s => s.toCompanyId === this.user.companyId);
                                        if(supplierSteps.findIndex(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31 || s.stepId === 10 || s.stepId === 25 || s.stepId === 32)) !== -1){
                                            return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                        }
                                    }
                                }
                            }
                        } else if (this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty).length > 0){
                            if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 0).length > 0){
                                const pc0Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 0);
                                pc0Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc0Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 1).length > 0){
                                const pc1Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 1);
                                pc1Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc1Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 2).length > 0){
                                const pc2Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 2);
                                pc2Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc2Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 3).length > 0){
                                const pc3Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 3);
                                pc3Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc3Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 4).length > 0){
                                const pc4Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 4);
                                pc4Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc4Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            }
                        }
                        return '';
                    } else if(prevStep === undefined && nextStep === undefined){
                        if (this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty).length > 0){
                            if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 0).length > 0){
                                const pc0Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 0);
                                pc0Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc0Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 1).length > 0){
                                const pc1Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 1);
                                pc1Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc1Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 2).length > 0){
                                const pc2Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 2);
                                pc2Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc2Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 3).length > 0){
                                const pc3Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 3);
                                pc3Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc3Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            } else if(this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 4).length > 0){
                                const pc4Steps = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === step.id && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === 4);
                                pc4Steps.sort((a, b)=> a.pcSequence < b.pcSequence ? -1 : 1);
                                if(pc4Steps[0].toCompanyId === this.user.companyId){
                                    return step.toCompanyName !== '' && step.toCompanyName !== null ? this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName : '-';
                                }
                            }
                        }
                    }
                    return '';
                }
            }
        }
    }

    private displayReference(step: OrderLineStepDisplayModel): string{
        return step.footprintRefNo !== '' && step.footprintRefNo !== null && step.footprintRefNo !== Guid.Empty ? step.footprintRefNoPrefix !== undefined ? step.footprintRefNoPrefix : '' : '';
    }

    private isFootprintMCStep(stepId: number, steps: OrderLineStepDisplayModel[]): boolean {
        if(stepId === 22){
            if(steps.length > 0 && steps.findIndex(s => s.stepId === 3) === -1){
                return true;
            } else{
                return false;
            }
        } else if(stepId === 23){
            if(steps.length > 0 && steps.findIndex(s => (s.stepId === 4 || s.stepId === 5)) === -1){
                return true;
            } else{
                return false;
            }
        } else if(stepId === 10 || stepId === 25 || stepId === 32){
            if(this.orderDetail.toCompanyId === this.user.companyId || this.orderDetail.delegateCompanyID === this.user.companyId){
                const garmentSteps = steps.filter(s => s.toCompanyId !== Guid.Empty && (s.stepId === 10 || s.stepId === 25 || s.stepId === 32));
                garmentSteps.sort((a, b)=> a.sequence > b.sequence ? -1 : 1);
                if(garmentSteps[0] && garmentSteps[0].stepId === stepId){
                    return true;
                } else {
                    return false;
                }
            } else {
                const garmentSteps = steps.filter(s => s.toCompanyId === this.user.companyId && (s.stepId === 10 || s.stepId === 25 || s.stepId === 32));
                garmentSteps.sort((a, b)=> a.sequence > b.sequence ? -1 : 1);
                if(garmentSteps[0] && garmentSteps[0].stepId === stepId){
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            const allFootprintStepIds = StepsHelper.getFootprintSteps().map(s => s.id);
            if(allFootprintStepIds.includes(stepId)){
                return true;
            }
            return false;
        }
    }

    private isFootprintPCStep(stepId: number, steps: OrderLineStepParallelModel[]): boolean {
        if(stepId === 22){
            if(steps.findIndex(s => s.stepId === 3) === -1){
                return true;
            } else{
                return false;
            }
        } else if(stepId === 23){
            if(steps.findIndex(s => (s.stepId === 4 || s.stepId === 5)) === -1){
                return true;
            } else{
                return false;
            }
        } else if(stepId === 10 || stepId === 25 || stepId === 32){
            return false;
        } else {
            const allFootprintStepIds = StepsHelper.getFootprintSteps().map(s => s.id);
            if(allFootprintStepIds.includes(stepId)){
                return true;
            }
            return false;
        }
    }

    // TTD-3934: function to show step not clickable
    private isFPStepClickable(stepUniqueId: string, stepId: number): boolean {
        if(this.orderDetail.toCompanyId === this.user.companyId || this.orderDetail.delegateCompanyID === this.user.companyId){
            if((this.footprintStepsWithRef.findIndex(f => f.mcStepUniqueId === stepUniqueId || f.pcStepUniqueId === stepUniqueId)!==-1) && StepsHelper.getFootprintSteps().map(s => s.id).includes(stepId)){
                return true;
            } else {
                return false;
            }
        } else {
            if((this.footprintStepsWithRef.findIndex(f => f.mcStepUniqueId === stepUniqueId || f.pcStepUniqueId === stepUniqueId)!==-1) && StepsHelper.getFootprintSteps().map(s => s.id).includes(stepId)){
                const mcStep = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === stepUniqueId && f.pcStepUniqueId === Guid.Empty);
                if(mcStep.length > 0){
                    if(mcStep[0].toCompanyId === this.user.companyId){
                        return true;
                    } else {
                        const supplierSteps = this.footprintStepsWithRef.filter(f => f.toCompanyId === this.user.companyId && f.pcStepUniqueId === Guid.Empty);
                        supplierSteps.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1);
                        if(supplierSteps.length > 0 && supplierSteps.filter(s => s.mcSequence > mcStep[0].mcSequence).length > 0){
                            if((stepId === 4 || stepId === 5) && supplierSteps.findIndex(s => s.stepId === 4 || s.stepId === 5) !== -1){
                                return false;
                            }
                            if((stepId === 6 || stepId === 7 || stepId === 8 || stepId === 31) && supplierSteps.findIndex(s => stepId === 6 || stepId === 7 || stepId === 8 || stepId === 31) !== -1){
                                return false;
                            }
                            return true;
                        }
                    }
                    return false;
                }
                const pcStep = this.footprintStepsWithRef.filter(f => f.pcStepUniqueId === stepUniqueId && f.pcStepUniqueId !== Guid.Empty);
                if(pcStep.length > 0){
                    if(pcStep[0].toCompanyId === this.user.companyId){
                        return true;
                    } else {
                        let supplierSteps: StepReferenceModel[] = [];
                        const pcOfMcStep = this.footprintStepsWithRef.filter(f => f.mcStepUniqueId === pcStep[0].mcStepUniqueId && f.pcStepUniqueId === Guid.Empty && f.toCompanyId === this.user.companyId);
                        if(pcOfMcStep.length > 0){
                            if(stepId === 3 || stepId === 22){
                                return true;
                            }
                            if((stepId === 4 || stepId === 5 || stepId === 23)){
                                return true;
                            }
                            // TTD-4255 (git branch: TTD-4202)
                            if((stepId === 6 || stepId === 7 || stepId === 8 || stepId === 31) && pcOfMcStep.findIndex(s => s.stepId === 10 || s.stepId === 25 || s.stepId === 32) === -1){
                                return true;
                            }
                            return false;
                        } else {
                            supplierSteps = this.footprintStepsWithRef.filter(f => f.toCompanyId === this.user.companyId && f.pcStepUniqueId !== Guid.Empty && f.pcOfMC === pcStep[0].pcOfMC && f.mcStepUniqueId === pcStep[0].mcStepUniqueId);
                            supplierSteps.sort((a, b)=> a.pcSequence > b.pcSequence ? -1 : 1);
                            if(supplierSteps.length > 0 && supplierSteps.filter(s => s.pcSequence < pcStep[0].pcSequence).length > 0){
                                if((stepId === 4 || stepId === 5) && supplierSteps.findIndex(s => s.stepId === 4 || s.stepId === 5) !== -1){
                                    return false;
                                }
                                if((stepId === 6 || stepId === 7 || stepId === 8 || stepId === 31) && supplierSteps.findIndex(s => stepId === 6 || stepId === 7 || stepId === 8 || stepId === 31) !== -1){
                                    return false;
                                }
                                return true;
                            }
                        }
                    }
                    return false;
                }
                return false;
            } else {
                return false;
            }
        }
    }

    // TTD-3940
    private footprintRefPrefixStep(steps: OrderLineStepDisplayModel[]): OrderLineStepDisplayModel[] {
        const footprintSteps = StepsHelper.getFootprintSteps();
        footprintSteps.forEach(fs => {
            const fsSteps = this.footprintStepsWithRef.filter(step => step.stepId === fs.id && step.refNo !== '');
            if(fsSteps.length > 0){
                const groupedByCount = countBy(fsSteps, function (item) {
                    return item.refNo;
                });
                for (var ref in groupedByCount) {
                    if (groupedByCount[ref] > 1) {
                        const selectedRef = fsSteps.filter(s => s.refNo === ref);
                        const uniqueCompanyIDs = lodash.uniqBy(selectedRef, 'companyID').map(c => c.toCompanyId);
                        uniqueCompanyIDs.forEach(companyID => {
                            const selectedRefwithSupplier = selectedRef.filter(s => s.toCompanyId === companyID);
                            if(selectedRefwithSupplier.length > 1){
                                let startPrefixNo = 1;
                                const mcSteps = selectedRefwithSupplier.filter(s => s.pcStepUniqueId === Guid.Empty);
                                if(mcSteps.length > 0){
                                    mcSteps.sort((s1, s2) => {
                                        return s1.mcSequence - s2.mcSequence;
                                    })
                                    mcSteps.forEach(mc => {
                                        const index = steps.findIndex(s => s.id === mc.mcStepUniqueId);
                                        steps[index].footprintRefNoPrefix = startPrefixNo++ + ": " + steps[index].footprintRefNoPrefix;
                                        const fsIndex = this.footprintStepsWithRef.findIndex(s => s.mcStepUniqueId === mc.mcStepUniqueId);
                                        this.footprintStepsWithRef[fsIndex].footprintRefNoPrefix = steps[index].footprintRefNoPrefix;
                                    })
                                }
                                const pcSteps = selectedRefwithSupplier.filter(s => s.pcStepUniqueId !== Guid.Empty);
                                if(pcSteps.length > 0){
                                    pcSteps.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1).sort((a, b)=> a.pcOfMC < b.pcOfMC ? -1 : 1).sort((a, b)=> a.pcSequence > b.pcSequence ? -1 : 1);
                                    pcSteps.forEach(pc => {
                                        const index = steps.findIndex(s => s.id === pc.mcStepUniqueId);
                                        if(pc.pcOfMC === 0){
                                        const pcIndex = steps[index].parallelSupplyChain0.findIndex(s => s.id === pc.pcStepUniqueId);
                                        steps[index].parallelSupplyChain0[pcIndex].footprintRefNoPrefix = startPrefixNo++ + ": " + steps[index].parallelSupplyChain0[pcIndex].footprintRefNoPrefix;
                                        const fsIndex = this.footprintStepsWithRef.findIndex(s => s.pcStepUniqueId === pc.pcStepUniqueId);
                                        this.footprintStepsWithRef[fsIndex].footprintRefNoPrefix = steps[index].parallelSupplyChain0[pcIndex].footprintRefNoPrefix;
                                        }
                                        else if(pc.pcOfMC === 1){
                                            const pcIndex = steps[index].parallelSupplyChain1.findIndex(s => s.id === pc.pcStepUniqueId);
                                            steps[index].parallelSupplyChain1[pcIndex].footprintRefNoPrefix = startPrefixNo++ + ": " + steps[index].parallelSupplyChain1[pcIndex].footprintRefNoPrefix;
                                            const fsIndex = this.footprintStepsWithRef.findIndex(s => s.pcStepUniqueId === pc.pcStepUniqueId);
                                            this.footprintStepsWithRef[fsIndex].footprintRefNoPrefix = steps[index].parallelSupplyChain1[pcIndex].footprintRefNoPrefix;
                                        }
                                        else if(pc.pcOfMC === 2){
                                            const pcIndex = steps[index].parallelSupplyChain2.findIndex(s => s.id === pc.pcStepUniqueId);
                                            steps[index].parallelSupplyChain2[pcIndex].footprintRefNoPrefix = startPrefixNo++ + ": " + steps[index].parallelSupplyChain2[pcIndex].footprintRefNoPrefix;
                                            const fsIndex = this.footprintStepsWithRef.findIndex(s => s.pcStepUniqueId === pc.pcStepUniqueId);
                                            this.footprintStepsWithRef[fsIndex].footprintRefNoPrefix = steps[index].parallelSupplyChain2[pcIndex].footprintRefNoPrefix;
                                        }
                                        else if(pc.pcOfMC === 3){
                                            const pcIndex = steps[index].parallelSupplyChain3.findIndex(s => s.id === pc.pcStepUniqueId);
                                            steps[index].parallelSupplyChain3[pcIndex].footprintRefNoPrefix = startPrefixNo++ + ": " + steps[index].parallelSupplyChain3[pcIndex].footprintRefNoPrefix;
                                            const fsIndex = this.footprintStepsWithRef.findIndex(s => s.pcStepUniqueId === pc.pcStepUniqueId);
                                            this.footprintStepsWithRef[fsIndex].footprintRefNoPrefix = steps[index].parallelSupplyChain3[pcIndex].footprintRefNoPrefix;
                                        }
                                        else if(pc.pcOfMC === 4){
                                            const pcIndex = steps[index].parallelSupplyChain4.findIndex(s => s.id === pc.pcStepUniqueId);
                                            steps[index].parallelSupplyChain4[pcIndex].footprintRefNoPrefix = startPrefixNo++ + ": " + steps[index].parallelSupplyChain4[pcIndex].footprintRefNoPrefix;
                                            const fsIndex = this.footprintStepsWithRef.findIndex(s => s.pcStepUniqueId === pc.pcStepUniqueId);
                                            this.footprintStepsWithRef[fsIndex].footprintRefNoPrefix = steps[index].parallelSupplyChain4[pcIndex].footprintRefNoPrefix;
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            }
        })
        this.refNumberWithPrefix(this.footprintStepsWithRef);
        return steps;
    }

    @Emit()
    private stepSelected(id: string, stepId: number): void { }

    @Emit()
    private refNumberWithPrefix(footprintSteps: StepReferenceModel[]): void { }

    @Watch('showInfo')
    private async onShowInfoChanged(showInfo: boolean): Promise<void> {
        this.calculateDistance(true);
    }

}
