import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import { mapGetters } from 'vuex';
// import lodash, { cloneDeep } from 'lodash';

// Components
import CreateSupplyChain from '@/views/home/components/createSupplyChain.vue';
import CreateBulkOrder from '@/views/home/components/bulkOrder.vue';

// Models
import { OrderLineModel } from '@/models/orderLineModel';
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { Guid } from '@/models/guid';
import { StepState } from '@/models/stepState';
import { StepsHelper } from '@/helpers/stepsHelper';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import { UserPermissionModel } from '@/models/permissionModel';
import { OrderLineStepParallelModel } from '@/models/orderLineStepParallelModel';
import { ClientStepModel } from '@/models/clientStepModel';
import { DelegateCompanyModel } from '@/models/delegateCompanyModel';

@Component({
  components: { CreateSupplyChain, CreateBulkOrder },
  computed: mapGetters(['userRole', 'userPermissions', 'pendingOrders', 'pendingOrderLineSteps']),
})
export default class PendingOrderLine extends Vue {
  @Prop()
  private combineOrderLine!: CombinedOrderLineModel;

  // TTD-3617 development
  @Prop()
  private type!: string;
  //commenting these changes not required now sprint-18
  // @Prop()
  // private isPendingStepsUpdated!: boolean;

  // @Prop()
  // private isOrdersUpdated!: boolean;

  private showAddStepModal: boolean = false;

  private pendingOrders!: OrderLineDisplayModel[];

  private pendingOrderLineSteps!: OrderLineStepModel[];

  private showBulkOrderModal: boolean = false;

  // private orderLine: OrderLineDisplayModel[] = [];

  private orderLineStep: OrderLineStepModel[] = [];

  private userRole!: string;

  private userPermissions!: UserPermissionModel | null;

  private isDelegatedToCp: boolean = false;
  //commenting these changes for now which causing pageUnresponsive issue
  // private defaultPendingOrderLineSteps: OrderLineStepModel[] = [];

  public constructor() {
    super();
  }

  private async created(): Promise<void> {
    //Commented this logic for removing lag on pending orderline page
    // this.getOrderLines();
    // this.defaultPendingOrderLineSteps = lodash.cloneDeep(this.pendingOrderLineSteps);
  }

  //Commented this logic for removing lag on pending orderline page

  // private getOrderLines(): void {
  //   const orders: OrderLineDisplayModel[] = this.$store.getters.pendingOrders;
  //   const orderLineSteps: OrderLineStepModel[] =
  //     this.$store.getters.pendingOrderLineSteps;
  //   this.combineOrderLine.orderIds.forEach((orderId) => {
  //     const order = orders.filter((o) => o.orderId === orderId);
  //     const steps =
  //       orderLineSteps === null
  //         ? []
  //         : orderLineSteps.filter((s) => s.orderId === orderId);
  //     this.orderLine.push(...order);
  //     if (steps.length === 0) {
  //       const allSteps = StepsHelper.getStepsByProductGroup(
  //         order[0].productGroup
  //       ).filter((s) => s.showDefault === true);
  //       allSteps.forEach((s) => {
  //         const step: OrderLineStepModel = {
  //           id: Guid.Empty,
  //           orderId: order[0].orderId,
  //           orderNumber: order[0].orderNumber,
  //           orderLineId: order[0].orderLines[0].lineID,
  //           stepId: s.stepId,
  //           productGroup: order[0].productGroup,
  //           displayName: s.displayName,
  //           sequence: s.sequence,
  //           toCompanyId: Guid.Empty,
  //           toCompanyName: '',
  //           fromCompanyId: Guid.Empty,
  //           showDetails: false,
  //           showNewsLetter: false,
  //           showFactoryAddress: false,
  //           showProfile: false,
  //           showDetailsCount: 0,
  //           rejectionReason: 'null',
  //           stepState: StepState.CREATED_BY_CLIENT,
  //           stepHistory: [],
  //           supplierStep: false,
  //           validSocialCertificates: [],
  //           validSustainabilityCertificates: [],
  //           evidencePhotos: [],
  //           compliantAtVerificationDate: false,
  //           status: OrderLineStepStatus.Pending,
  //           currentlyValidCertificates: null,
  //           clientID: order[0].fromCompanyUser.ID,
  //           cpSupplierID: order[0].toCompanyId,
  //           approvalRequestedTo: '',
  //           isLastStep: s.sequence === allSteps.length ? 'true' : 'false',
  //           parallelSupplyChain0: [],
  //           parallelSupplyChain1: [],
  //           parallelSupplyChain2: [],
  //           parallelSupplyChain3: [],
  //           parallelSupplyChain4: [],
  //           parallelChainCount: 0,
  //         };
  //         if(s.parallelChain && s.parallelChain.length > 0){
  //           if (s.showDefaultParallelChain) {
  //             s.parallelChain!.forEach((defaultchild, defaultchildIndex) => {
  //                 const defaultStep = {
  //                     displayName: i18n.t(`enums.steps.${defaultchild}`).toString(),
  //                     stepId: defaultchild,
  //                     sequence: 1,
  //                     productGroup: s.productGroup,
  //                     showDefault: true
  //                 };
  //                 const newStep: OrderLineStepParallelModel = this.mapOrderLineParallelStep(order[0], defaultStep, s.stepId);
  //                 if (defaultchildIndex == 0) {
  //                   step.parallelSupplyChain0 = [newStep];
  //                 }
  //                 if (defaultchildIndex == 1) {
  //                   step.parallelSupplyChain1 = [newStep];
  //                 }
  //             })
  //         }
  //         }
  //         steps.push(step);
  //       });
  //     }
  //     this.orderLineStep.push(...steps);
  //   });
  // }


  //Added this logic for removing lag on pending orderline page
  private get orderLine(): OrderLineDisplayModel[] {
    const pendingOrders: OrderLineDisplayModel[] = [];
    this.combineOrderLine.orderIds.forEach((orderId) => {
      const order = this.pendingOrders.filter((o) => o.orderId === orderId);
      pendingOrders.push(...order);
    });
    return pendingOrders;
  }

  //Added this logic for removing lag on pending orderline page
  private get getOrderLineSteps(): OrderLineStepModel[] {
    const allOrderlineSteps: OrderLineStepModel[] = [];
    this.combineOrderLine.orderIds.forEach((orderId) => {
      const order = this.pendingOrders.filter((o) => o.orderId === orderId);
      const steps =
        this.pendingOrderLineSteps  === null
          ? []
          : this.pendingOrderLineSteps .filter((s) => s.orderId === orderId);
      if (steps.length === 0) {
        const allSteps = StepsHelper.getStepsByProductGroup(
          order[0].productGroup
        ).filter((s) => s.showDefault === true);
        allSteps.forEach((s) => {
          const step: OrderLineStepModel = {
            id: Guid.Empty,
            orderId: order[0].orderId,
            orderNumber: order[0].orderNumber,
            orderLineId: order[0].orderLines[0].lineID,
            stepId: s.stepId,
            productGroup: order[0].productGroup,
            displayName: s.displayName,
            sequence: s.sequence,
            toCompanyId: Guid.Empty,
            toCompanyName: '',
            fromCompanyId: Guid.Empty,
            showDetails: false,
            showNewsLetter: false,
            showFactoryAddress: false,
            showProfile: false,
            showDetailsCount: 0,
            rejectionReason: 'null',
            stepState: StepState.CREATED_BY_CLIENT,
            stepHistory: [],
            supplierStep: false,
            validSocialCertificates: [],
            validSustainabilityCertificates: [],
            evidencePhotos: [],
            compliantAtVerificationDate: false,
            status: OrderLineStepStatus.Pending,
            currentlyValidCertificates: null,
            clientID: order[0].fromCompanyUser.ID,
            cpSupplierID: order[0].toCompanyId,
            approvalRequestedTo: '',
            isLastStep: s.sequence === allSteps.length ? 'true' : 'false',
            parallelSupplyChain0: [],
            parallelSupplyChain1: [],
            parallelSupplyChain2: [],
            parallelSupplyChain3: [],
            parallelSupplyChain4: [],
            parallelChainCount: 0,
            // TTD-3619
            footprintRefNo: '',
            footprintRefID: Guid.Empty,
            footprintRefNoUpdatedBy: Guid.Empty
          };
          if(s.parallelChain && s.parallelChain.length > 0){
            if (s.showDefaultParallelChain) {
              s.parallelChain!.forEach((defaultchild, defaultchildIndex) => {
                  const defaultStep = {
                      displayName: i18n.t(`enums.steps.${defaultchild}`).toString(),
                      stepId: defaultchild,
                      sequence: 1,
                      productGroup: s.productGroup,
                      showDefault: true
                  };
                  const newStep: OrderLineStepParallelModel = this.mapOrderLineParallelStep(order[0], defaultStep, s.stepId);
                  if (defaultchildIndex == 0) {
                    step.parallelSupplyChain0 = [newStep];
                  }
                  if (defaultchildIndex == 1) {
                    step.parallelSupplyChain1 = [newStep];
                  }
              })
          }
          }
          steps.push(step);
        });
      }
      allOrderlineSteps.push(...steps);
    });
    return allOrderlineSteps;
  }

  private openAddStepModal(): void {
    if (this.combineOrderLine.orderCount > 1) {
      this.showBulkOrderModal = true;
    } else {
      this.showAddStepModal = true;
    }
  }

  private closeAddStepModal(
    created: boolean,
    orderLineSteps: OrderLineStepModel[]
  ): void {
    this.showAddStepModal = false;
    this.showBulkOrderModal = false;
    //commenting these changes for now which causing pageUnresponsive issue
    // if(!created){
    //   this.defaultPendingOrderLineSteps = lodash.cloneDeep(this.pendingOrderLineSteps);
    // }
    this.createOrderLines(created, orderLineSteps);
  }

  private getProductName(productGroup: number): string {
    return i18n.t('enums.product_group.' + productGroup).toString();
  }

  private get updateOrderLinePermission(): number {
    return this.userPermissions !== null
      ? this.userPermissions.updateOrderLine
      : 0;
  }

  private mapOrderLineParallelStep(order: OrderLineDisplayModel, defaultStep: ClientStepModel, stepId: number): OrderLineStepParallelModel {
    return {
      id: Guid.Empty,
      parentStepId: stepId.toString(),
      orderId: order.orderId,
      orderNumber: order.orderNumber,
      orderLineId: order.orderLines[0].lineID,
      stepId: defaultStep.stepId,
      productGroup: order.productGroup,
      displayName: defaultStep.displayName,
      sequence: 1,
      fromCompanyId: Guid.Empty,
      toCompanyId: Guid.Empty,
      toCompanyName: '',
      showDetails: false,
      showNewsLetter: false,
      showFactoryAddress: false,
      showProfile: false,
      showDetailsCount: 0,
      rejectionReason: 'null',
      stepState: StepState.NONE,
      stepHistory: [],
      status: OrderLineStepStatus.Pending,
      evidencePhotos: [],
      validSocialCertificates: [],
      validSustainabilityCertificates: [],
      compliantAtVerificationDate: false,
      currentlyValidCertificates: null,
      isLastStep: 'false',
      supplierStep: false,
      // TTD-3619
      footprintRefNo: '',
      footprintRefID: Guid.Empty,
      footprintRefNoUpdatedBy: Guid.Empty
    }
  }

  @Emit()
  private createOrderLines(
    created: boolean,
    orderLineSteps: OrderLineStepModel[]
  ): void {}

  @Emit()
  private confirmOrderLine(
    orderLine: OrderLineDisplayModel[],
    orderLineSteps: OrderLineStepModel[],
    isDelegated: boolean,
    selectedDelegatePartner: DelegateCompanyModel,
    isDelegatedPartnerRemoved: boolean
  ):  void {
    this.isDelegatedToCp = isDelegated;
  }
  //TTD-2422 reset the props after close the modals
  @Emit()
  private resetUpdateVar(): void {}
}
