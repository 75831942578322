import i18n from "@/i18n";
import { DropdownModel } from "@/models/dropdownModel";
//Sprint-24 TTD-116
export class CertificateBodyList {
    public static getCertificatesBodyList(): DropdownModel[] {
        const certificates = i18n.t(`enums.certifcation_bodies`) as any;
        const result: DropdownModel[] = [];
        for (const key of Object.keys(certificates)) {
            const certifcateName = certificates[key];
            result.push({ value: key, text: certifcateName });
        }
        result.sort((a: DropdownModel, b: DropdownModel) => a.text.localeCompare(b.text));
        return result;
    }
}