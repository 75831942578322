export class BulkOrderModel {
  public orderId!: string;
  public orderNumber!: string;
  public styleNumber!: string;
  public styleName!: string;
  public colourway?: string;
  public createdAt!: string;
  public deliveryDate!: string;
  public checked!: boolean;
}
