import { Component, Vue } from 'vue-property-decorator';

// Components
import Sidebar from '@/components/navigation/sidebar/sidebar.vue';
import SidebarMobile from '@/components/navigation/sidebarMobile/sidebar.vue';
import SiteHeader from '@/components/navigation/siteHeader.vue';

@Component({
  components: { Sidebar, SiteHeader, SidebarMobile },
})
export default class Dashboard extends Vue {
  private collapsedSidebar: boolean = false;

  private isMobile: boolean = false;

  private collapsesIcon: NodeRequire = require('../assets/icons/navigation/path.svg');

  private async created(): Promise<void> {
    if (screen.width <= 636) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  private async toggleSidebar(): Promise<void> {
    if (this.collapsedSidebar) {
      this.collapsedSidebar = false;
      this.collapsesIcon = await require('../assets/icons/navigation/path.svg');
      localStorage.setItem('collapsedSidebar', 'false');
    } else {
      this.collapsedSidebar = true;
      this.collapsesIcon =
        await require('../assets/icons/navigation/path-reverse.svg');
      localStorage.setItem('collapsedSidebar', 'true');
    }
  }
}
