import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { Vue, Component } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { ActionTypes } from '@/store/actions/actions';
import _ from 'underscore';
import lodash from "lodash";

// Components
import SmallScreenPopUpMsg from '@/components/general/smallScreenPopUpMsg/smallScreenPopUpMsg.vue';

// Models
import { UserModel } from '@/models/userModel';
import { CompanyModel } from '@/models/companyModel';
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import { Guid } from '@/models/guid';
import { TCStatus, TCType } from "@/models/tcTableModel";

@Component({
  components: { SmallScreenPopUpMsg },
  computed: {
    ...mapGetters([
      'isPendingOrdersLoading',
      'pendingOrders',
      'company',
      'orderLineEvidenceStepsLoading',
      'orderLineEvidenceSteps',
      'orderLineEvidenceNeighbourSteps',
      'orderLineEvidenceApproveSteps',
      'user',
      'userRole',
      'evidenceCountLoading',
      'uploadEvidenceCount',
      'approvedEvidenceCount',
      'footprintCount',
      'isOrderLoading',
      'delegatedOrdersCount',
      'orders',
      'readOrSignDocumentsCount',
      'expiredCertificateCount'
    ]),
  },
})
export default class PendingActionsTile extends Vue {
  private isPendingOrdersLoading!: boolean;

  private pendingOrders!: OrderLineDisplayModel[];

  private pendingInvites: number = 0;

  private showSmallScreenMsg: boolean = false;

  private showQrCodeModal: boolean = false;

  private isUploadQrModal: boolean = false;

  private isApproveQrModal: boolean = false;

  private isCmpleteQrModal: boolean = false;

  private smallScreenMsgText: string = '';

  private company!: CompanyModel;

  private companyLoading: boolean = false;

  private notDesktop: boolean = false;

  private showCompliance: boolean = false;

  private orderLineEvidenceStepsLoading!: boolean;

  private orderLineEvidenceSteps!: OrderLineEvidenceStep[];

  private orderLineEvidenceNeighbourSteps!: OrderLineEvidenceStep[];

  private orderLineEvidenceApproveSteps!: OrderLineEvidenceStep[];

  private user!: UserModel;

  private orders!: OrderLineDisplayModel[];

  private userRole!: string;

  private delegatedOrdersCount!: number;

  private evidenceCountLoading!: boolean;

  private uploadEvidenceCount!: number;

  private approvedEvidenceCount!: number;

  private footprintCount!: number;

  private isOrderLoading!: boolean;

  private readOrSignDocumentsCount!:number;

  private expiredCertificateCount!:number;

  public constructor() {
    super();
  }

  private mounted(): void {
    if (window.innerWidth < 1140) {
      this.notDesktop = true;
    } else {
      this.notDesktop = false;
    }
    window.onresize = () => {
      if (window.innerWidth < 1140) {
        this.notDesktop = true;
      } else {
        this.notDesktop = false;
      }
    };
  }

  private async created(): Promise<void> {
    if (this.company === null) {
      this.companyLoading = true;
      await this.$store.dispatch(ActionTypes.SET_MYACCOUNT);
      this.isCompanyPituresUploaded();
      this.companyLoading = false;
    }
    const companyState = this.$store.getters.company as CompanyModel;
    let sustainableCount = 0;
    let socialCount = 0;
    for (let index = 0; index < companyState.sustainability.length; index++) {
      if (
        companyState.sustainability[index].fileType !== 'image/jpeg' &&
        companyState.sustainability[index].fileType !== null
      ) {
        sustainableCount++;
      }
    }
    for (let index = 0; index < companyState.social.length; index++) {
      if (
        companyState.social[index].fileType !== 'image/jpeg' &&
        companyState.social[index].fileType !== null
      ) {
        socialCount++;
      }
    }
    if (socialCount == 0 && sustainableCount == 0) {
      this.showCompliance = true;
    } else {
      this.showCompliance = false;
    }
    if (this.uploadEvidenceCount === 0 && this.approvedEvidenceCount === 0 && this.footprintCount === 0) {
      await this.$store.dispatch(ActionTypes.SET_ORDER_LINE_EVIDENCE_COUNT);
    }
  }

  private isCompanyPituresUploaded(): boolean {
    if (this.company) {
      return this.company.profilePictures.length >= 3 ? true : false;
    } else {
      return false;
    }
  }

  private get allOrders(): number {
    return this.pendingOrdersCount + this.delegatedOrdersCount;
  }

  private get pendingOrdersCount(): number {
    return this.pendingOrders.length - this.pendingOrders.filter(p => p.delegateCompanyID !== Guid.Empty).length;
  }

  // private get uploadEvidenceCount(): number {
  //   const pendingSteps = this.orderLineEvidenceSteps.filter(
  //     (s) => s.status === OrderLineStepStatus.Pending
  //   );
  //   return _.uniq(pendingSteps, 'orderID').length;
  // }

  // private get approveEvidenceCount(): number {
  //   const waitingApprovalSteps = this.orderLineEvidenceApproveSteps.filter(
  //     (s) => s.status === OrderLineStepStatus.WaitingForApproval
  //   );
  //   return _.uniq(waitingApprovalSteps, 'orderID').length;
  // }

  private get pendingOrderLinesCount(): number {
    return this.pendingOrders.length;
  }

  private get pendingInvitesCount(): number | null {
    return null;
  }

  private get isAdmin(): boolean {
    let result = true;
    if (this.userRole === 'Editor' || this.userRole === 'Reader') {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  private navComplaince(): void {
    if (screen.width < 1280) {
      this.smallScreenMsgText = this.$t(
        'pages.home.small_screen_msg'
      ).toString();
      this.showSmallScreenMsg = true;
    } else {
      this.$router.push({ name: 'Compliance' });
    }
  }

  private navPendingOrdelines(): void {
    if (screen.width < 1280) {
      this.smallScreenMsgText = this.$t(
        'pages.home.small_screen_msg'
      ).toString();
      this.showSmallScreenMsg = true;
    } else {
      this.$router.push({ name: 'OrderLines' });
    }
  }

  private hideSmallScreenMsg(): void {
    this.showSmallScreenMsg = false;
  }

  private get isT1Scp(): boolean {
    const user = this.$store.getters.user as UserModel;
    return user.isT1Scp;
  }

  private showQrModal(type: string): void {
    if (screen.width > 636) {
      this.showQrCodeModal = true;
      if (type == 'upload') {
        this.isUploadQrModal = true;
      } else if (type == 'approve') {
        this.isApproveQrModal = true;
      } else {
        this.isCmpleteQrModal = true;
      }
    } else {
      if (type == 'upload') {
        this.openUploadEvidence();
      } else if (type == 'approve') {
        this.openApproveEvidence();
      } else if (type == 'complete') {
        this.$router.push({
          name: 'Profile',
          params: { selectTab: 'companydetails' },
        });
      }
    }
  }

  private closeQrCodeModal(): void {
    this.showQrCodeModal = false;
    this.isUploadQrModal = false;
    this.isApproveQrModal = false;
    this.isCmpleteQrModal = false;
  }

  private openUploadEvidence(): void {
    this.$router.push({
      name: 'OrderEvidence',
      params: { openUploadEvidence: '1' },
    });
  }

  private openApproveEvidence(): void {
    this.$router.push({
      name: 'OrderEvidence',
      params: { openApproveEvidence: '1' },
    });
  }
  // Sprint-24 TTD-3798 from
  private get pendingTCRequests(): number{
    const pendingFilterOrders = this.pendingOrders.filter(order => (order.tc1Status === TCStatus.PENDING || order.tc2Status === TCStatus.PENDING) && order.toCompanyId!==Guid.Empty);
    const orderTCOrders = this.orders.filter(order => (order.tc1Status === TCStatus.PENDING || order.tc2Status === TCStatus.PENDING) && order.toCompanyId!==Guid.Empty);
    const pendingTCCount = pendingFilterOrders.filter(order => order.tranCertificate1!=='' && order.levelTC1!=='' && order.tc1Status === TCStatus.PENDING).length + pendingFilterOrders.filter(order => order.tranCertificate2!=='' && order.levelTC2!=='' && order.tc2Status === TCStatus.PENDING).length; 
    const orderTCCount = orderTCOrders.filter(order => order.tranCertificate1!=='' && order.levelTC1!=='' && order.tc1Status === TCStatus.PENDING).length + orderTCOrders.filter(order => order.tranCertificate2!=='' && order.levelTC2!=='' && order.tc2Status === TCStatus.PENDING).length;
    return pendingTCCount + orderTCCount;
  }

  private get rejectedTC(): number{
    const filterTC1Orders = this.pendingOrders.filter(o => o.tc1Status === TCStatus.REJECTED && o.tranCertificate1RefId !== "" && o.tranCertificate1RefId !== Guid.Empty);
    const filterTC2Orders = this.pendingOrders.filter(o => o.tc2Status === TCStatus.REJECTED && o.tranCertificate2RefId !== "" && o.tranCertificate2RefId !== Guid.Empty);
    const filterTC3Orders = this.orders.filter(o => o.tc1Status === TCStatus.REJECTED && o.tranCertificate1RefId !== "" && o.tranCertificate1RefId !== Guid.Empty);
    const filterTC4Orders = this.orders.filter(o => o.tc2Status === TCStatus.REJECTED && o.tranCertificate2RefId !== "" && o.tranCertificate2RefId !== Guid.Empty);
    const tc1RefId = lodash.uniqBy(filterTC1Orders, "tranCertificate1RefId").map(o => o.tranCertificate1RefId);
    const tc2RefId = lodash.uniqBy(filterTC2Orders, "tranCertificate2RefId").map(o => o.tranCertificate2RefId);
    const tc3RefId = lodash.uniqBy(filterTC3Orders, "tranCertificate1RefId").map(o => o.tranCertificate1RefId);
    const tc4RefId = lodash.uniqBy(filterTC4Orders, "tranCertificate2RefId").map(o => o.tranCertificate2RefId);
    const uniqTcRefId: string[] = lodash.uniq([...tc1RefId, ...tc2RefId, ...tc3RefId, ...tc4RefId]);
    let tcCount = uniqTcRefId.length;
    return tcCount;
  }

  private get approvedTC(): number{
    const filterTC1Orders = this.pendingOrders.filter(o => o.tc1Status === TCStatus.APPROVED && o.tranCertificate1RefId !== "" && o.tc1Type === TCType.DRAFT && o.tranCertificate1RefId !== Guid.Empty);
    const filterTC2Orders = this.pendingOrders.filter(o => o.tc2Status === TCStatus.APPROVED && o.tranCertificate2RefId !== "" && o.tc2Type === TCType.DRAFT && o.tranCertificate2RefId !== Guid.Empty);
    const filterTC3Orders = this.orders.filter(o => o.tc1Status === TCStatus.APPROVED && o.tranCertificate1RefId !== "" && o.tc1Type === TCType.DRAFT && o.tranCertificate1RefId !== Guid.Empty);
    const filterTC4Orders = this.orders.filter(o => o.tc2Status === TCStatus.APPROVED && o.tranCertificate2RefId !== "" && o.tc2Type === TCType.DRAFT && o.tranCertificate2RefId !== Guid.Empty);
    const tc1RefId = lodash.uniqBy(filterTC1Orders, "tranCertificate1RefId").map(o => o.tranCertificate1RefId);
    const tc2RefId = lodash.uniqBy(filterTC2Orders, "tranCertificate2RefId").map(o => o.tranCertificate2RefId);
    const tc3RefId = lodash.uniqBy(filterTC3Orders, "tranCertificate1RefId").map(o => o.tranCertificate1RefId);
    const tc4RefId = lodash.uniqBy(filterTC4Orders, "tranCertificate2RefId").map(o => o.tranCertificate2RefId);
    const uniqTcRefId: string[] = lodash.uniq([...tc1RefId, ...tc2RefId, ...tc3RefId, ...tc4RefId]);
    let tcCount = uniqTcRefId.length;
    return tcCount;
  }

  private get readOrSignDoc(): number{
    return this.readOrSignDocumentsCount;
  }

  private get isCertificatesExist(): boolean{
    if(this.readOrSignDoc >0 || this.approvedTC > 0 || this.rejectedTC > 0 ||
       this.pendingTCRequests > 0 || this.expiredCertificateCount > 0){
        this.showCompliance = true;
      return true;
    }
    return false;
  }

  private get getComplianceCount(): number{
    return this.readOrSignDoc + this.approvedTC + this.rejectedTC + this.pendingTCRequests + this.expiredCertificateCount;
  }
  // Sprint-24 TTD-3798 to
  //TTD-2736 sprint-19 navigation to footprint
  private navFootPrint(): void {
    if (screen.width < 1140) {
      this.smallScreenMsgText = this.$t(
        'pages.home.small_screen_msg'
      ).toString();
      this.showSmallScreenMsg = true;
    } else {
      this.$router.push({ name: 'Footprint' });
    }
  }
}
