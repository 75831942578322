// Sprint-26 TTD-3068
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import { mapState } from "vuex";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";

// Components
import EsgFacilityModal from '@/views/esg/components/esgFacilityModal.vue';
import EsgUploadDocModal from '@/views/esg/components/esgUploadDocModal.vue';
import ViewDocuments from '@/views/esg/components/viewDocuments.vue';

// Services
import { SupplierService } from '@/services/supplierService';

//Helpers
import { EsgDropdownHelper } from "@/helpers/esgDropDownHelper";
import { FootprintDropdownHelper } from "@/helpers/footprintDropdownHelper"

// Modals
import { EsgEnvModel, EsgStatus, EsgEnergySourceModel, EsgWaterTrtmntTypeModel, EsgTextAnsModel, EsgAddressStatus, EsgUploadDocModel, EsgDocsModel, EsgEnvDocUrlReq } from "@/models/esgModel";
import { UserModel } from "@/models/userModel";
import { DropdownModel } from "@/models/dropdownModel";
import { AddressModel } from "@/models/addressModel";
import { CompanyModel } from "@/models/companyModel";

@Component({
  components: {
    VuePdfApp, EsgFacilityModal, EsgUploadDocModal, ViewDocuments
  },
  computed: mapState(["userRole", "userPermissions", "company", "user", 'esgEnv']),
})
export default class EsgDataEnv extends Vue {

  @Prop()
  private activeAddress!: AddressModel;

  @Prop()
  private activeAddEsgEnv!: EsgEnvModel;

  @Prop()
  private showConfirmSave!: boolean;

  private supplierService: SupplierService;

  private user!: UserModel;
  private userRole!: string;
  private company!: CompanyModel;
  private esgEnv!: EsgEnvModel[];

  private isSaving: boolean = false;
  private envData: EsgEnvModel = new EsgEnvModel();
  private envDataClone: EsgEnvModel = new EsgEnvModel();
  private status: string = 'red';
  private changedkeys: string[] = [];
  private counter: number = 0;
  private energyYearList: DropdownModel[] = [];
  private sourcesList: DropdownModel[] = [];
  private esgDocUrl: string = '';

  private companyAddresses: AddressModel[] = [];
  private isFacilityModalVisible: boolean = false;
  private isUploadDocModalVisible: boolean = false;
  private uploadDocKey: string = '';
  private uploadDocSelectedFacility: EsgAddressStatus[] = [];
  private viewDocFileName: string = '';
  private showViewDocument: boolean = false;
  private deleteDocIndex: number = -1;
  private deleteDocKey: string = '';
  private showDeleteDocModal: boolean = false;
  private showConfirmCancel: boolean = false;
  private isUploading: boolean = false;
  private edwtuQ04Other: string = '';
  private edwtuQ08Other: string = '';
  private edwtuQ04OtherClone: string = '';
  private edwtuQ08OtherClone: string = '';
  private uploadType: string = '';

  private waterTrtmntPlantType: EsgWaterTrtmntTypeModel[] = [{title:'Aerobic', isSelected:false},{title:'Anaerobic',isSelected:false}, {title:'Coagulation',isSelected:false},
    {title:'Flocculation', isSelected:false},{title:'Microfiltration (MF) membrane', isSelected:false}, {title:'Ultrafiltration (UF) membrane', isSelected: false}, {title:'Nanofiltration (NF) membrane',isSelected:false},
     {title:'Reverse Osmosis (RO) membrane', isSelected: false}, {title:'Other', isSelected: false}
  ]

  private recycleWasteWater:  EsgWaterTrtmntTypeModel[] = [{title:'Process water', isSelected:false},{title:'Cleaning', isSelected:false},
    {title:'Irrigation', isSelected:false}, {title:'Other', isSelected: false}
  ]

  public constructor() {
    super();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    if(this.company !== null || this.company !== undefined){
      this.companyAddresses.push(...this.company.officeAddress.filter(add => add.addressStatus !== 'DISABLED'));
      if (this.company.factoryAddressDiffers) {
          this.companyAddresses.push(...this.company.factoryAddresses.filter(add => add.addressStatus !== 'DISABLED'));
      }
    }
    this.envData = cloneDeep(this.activeAddEsgEnv);
    if(this.envData.edeguQ05.length === 0){
      this.envData.edeguQ05.push(new EsgEnergySourceModel);
    }
    this.setFollowUpQues();
    this.parseNegativeValueToString();
    this.envDataClone = cloneDeep(this.envData);
    this.energyYearList = EsgDropdownHelper.getProvideEnergyYearList();
    this.sourcesList = FootprintDropdownHelper.getSourcesList();
    this.toggleFirstIncompleteBeam();
    this.setTreatmentValues();
    this.counter = 0;
    this.changedkeys = [];
  }

  private get notStartedStatus(): string {
    return '';
  }

  private get inProgressStatus(): string {
    return EsgStatus.INPROGRESS;
  }

  private get completedStatus(): string {
    return EsgStatus.COMPLETED;
  }

  private get yes(): string {
    return EsgStatus.YES;
  }

  private get no(): string {
    return EsgStatus.NO;
  }

  private get na(): string {
    return EsgStatus.NA;
  }

  private get dataUpdatedAt(): string {
    return this.envData.edLastUpdatedAt !== '' ? moment(this.envData.edLastUpdatedAt).format('DD MMM YYYY') : '';
  }

  private get defaultDate(): string {
    return '0001-01-01T00:00:00Z';
  }

  private get isReadOnly(): boolean {
    return this.userRole === 'Reader';
  }

  private formattedDate(date: string, dateFormat: string): string {
    return moment(date).format(dateFormat);
  }

  private allowOnlyInteger(value: string): string {
    const regex = /^\d*$/;
    let returnValue = '';
    if (!regex.test(value)) {
      returnValue = value.replace(/[^\d]/g, '');
    } else {
      returnValue = value;
    }
    return returnValue;
  }

  private allowTillHundred(value: string): string {
    const regex = /^[1-9][0-9]?$|^100$/;
    let returnValue = '';
    if (!regex.test(value)) {
      returnValue = value.replace(/[^\d]/g, '');
    } else {
      returnValue = value;
    }
    return returnValue;
  }

  private allowOnlyCharacter(value: string): string {
    const regex = /[^a-zA-Z ]+/;
    let returnValue = '';
    if (regex.test(value)) {
      returnValue = value.replace(/[^a-zA-Z ]+/g, '');
    } else {
      returnValue = value;
    }
    return returnValue;
  }

  private toggleFirstIncompleteBeam(): void {
    if(this.envData.energyUseStatus !== EsgStatus.COMPLETED){
      this.envData.energyUseExpanded = true;
      this.envData.waterUseExpanded = false;
      this.envData.wastageExpanded = false;
      this.envData.circularEconomyExpanded = false;
      this.envData.carbonEmissionExpanded = false;
    } else if(this.envData.waterUseStatus !== EsgStatus.COMPLETED){
      this.envData.energyUseExpanded = false;
      this.envData.waterUseExpanded = true;
      this.envData.wastageExpanded = false;
      this.envData.circularEconomyExpanded = false;
      this.envData.carbonEmissionExpanded = false;
    } else if(this.envData.wastageStatus !== EsgStatus.COMPLETED){
      this.envData.energyUseExpanded = false;
      this.envData.waterUseExpanded = false;
      this.envData.wastageExpanded = true;
      this.envData.circularEconomyExpanded = false;
      this.envData.carbonEmissionExpanded = false;
    } else if(this.envData.circularEconomyStatus !== EsgStatus.COMPLETED){
      this.envData.energyUseExpanded = false;
      this.envData.waterUseExpanded = false;
      this.envData.wastageExpanded = false;
      this.envData.circularEconomyExpanded = true;
      this.envData.carbonEmissionExpanded = false;
    } else if(this.envData.carbonEmissionStatus !== EsgStatus.COMPLETED){
      this.envData.energyUseExpanded = false;
      this.envData.waterUseExpanded = false;
      this.envData.wastageExpanded = false;
      this.envData.circularEconomyExpanded = false;
      this.envData.carbonEmissionExpanded = true;
    }
  }

  private updateValue(key: string, value: string): void {
    let index = -1;
    switch (key) {
      case 'edeguQ01':
        this.envData.edeguQ01.value = Number(this.envData.edeguQ01.value);
        this.envData.edeguQ01.updatedAt = new Date().toISOString();
        this.envData.edeguQ01.updatedBy = this.user.userId;
        if(this.envData.edeguQ01.value !== this.envDataClone.edeguQ01.value && !this.changedkeys.includes('edeguQ01')) {
          this.changedkeys.push('edeguQ01');
          this.counter++;
        } else if(this.envData.edeguQ01.value === this.envDataClone.edeguQ01.value && this.changedkeys.includes('edeguQ01')) {
          this.changedkeys.splice(this.changedkeys.indexOf('edeguQ01'), 1);
          this.counter--;
        } else {
          // no action
        }
        this.checkEnergyUseStatus();
        break;

      case 'edeguQ02':
          this.envData.edeguQ02.value = this.allowOnlyInteger(this.envData.edeguQ02.value.toString());
          if(this.envData.edeguQ02.value !== ''){
            this.envData.edeguQ02.value = Number(this.envData.edeguQ02.value);
          }
          this.envData.edeguQ02.updatedAt = new Date().toISOString();
          this.envData.edeguQ02.updatedBy = this.user.userId;
          if(this.envData.edeguQ02.value !== this.envDataClone.edeguQ02.value && !this.changedkeys.includes('edeguQ02')) {
            this.changedkeys.push('edeguQ02');
            this.counter++;
          } else if(this.envData.edeguQ02.value === this.envDataClone.edeguQ02.value && this.changedkeys.includes('edeguQ02')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edeguQ02'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkEnergyUseStatus();
          break;

      case 'edeguQ03':
        this.envData.edeguQ03.value = this.allowTillHundred(this.envData.edeguQ03.value.toString());
        if(this.envData.edeguQ03.value !== ''){
          this.envData.edeguQ03.value = Number(this.envData.edeguQ03.value);
          if(this.envData.edeguQ03.value > 100){
            this.envData.edeguQ03.value = 100;
          } else if(this.envData.edeguQ03.value < 0){
            this.envData.edeguQ03.value = 0;
          }
        }
          this.envData.edeguQ03.updatedAt = new Date().toISOString();
          this.envData.edeguQ03.updatedBy = this.user.userId;
          if(this.envData.edeguQ03.value !== this.envDataClone.edeguQ03.value && !this.changedkeys.includes('edeguQ03')) {
            this.changedkeys.push('edeguQ03');
            this.counter++;
          } else if(this.envData.edeguQ03.value === this.envDataClone.edeguQ03.value && this.changedkeys.includes('edeguQ03')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edeguQ03'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkEnergyUseStatus();
          break;

      case 'edeguQ04':
        this.envData.edeguQ04.value = this.allowTillHundred(this.envData.edeguQ04.value.toString());
        if(this.envData.edeguQ04.value !== ''){
          this.envData.edeguQ04.value = Number(this.envData.edeguQ04.value);
          if(this.envData.edeguQ04.value > 100){
            this.envData.edeguQ04.value = 100;
          } else if(this.envData.edeguQ04.value < 0){
            this.envData.edeguQ04.value = 0;
          }
        }
          this.envData.edeguQ04.updatedAt = new Date().toISOString();
          this.envData.edeguQ04.updatedBy = this.user.userId;
          if(this.envData.edeguQ04.value !== this.envDataClone.edeguQ04.value && !this.changedkeys.includes('edeguQ04')) {
            this.changedkeys.push('edeguQ04');
            this.counter++;
          } else if(this.envData.edeguQ04.value === this.envDataClone.edeguQ04.value && this.changedkeys.includes('edeguQ04')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edeguQ04'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkEnergyUseStatus();
          break;

          case 'edeguQ05':
          // this.envData.edeguQ04.value = value;
          index = Number(value);
          this.envData.edeguQ05[index].value = this.allowTillHundred(this.envData.edeguQ05[index].value.toString());
          if(this.envData.edeguQ05[index].value !== ''){
            this.envData.edeguQ05[index].value = Number(this.envData.edeguQ05[index].value);
            if(Number(this.envData.edeguQ05[index].value) > 100){
              this.envData.edeguQ05[index].value = 100;
            } else if(Number(this.envData.edeguQ05[index].value) < 0){
              this.envData.edeguQ05[index].value = 0;
            }
          }
          this.envData.edeguQ05[index].updatedAt = new Date().toISOString();
          this.envData.edeguQ05[index].updatedBy = this.user.userId;
          if(this.envData.edeguQ05[index].value !== this.envDataClone.edeguQ05[index].value && !this.changedkeys.includes(`edeguQ05-${index}`)) {
            this.changedkeys.push(`edeguQ05-${index}`);
            this.counter++;
          } else if(this.envData.edeguQ05[index].value === this.envDataClone.edeguQ05[index].value && this.changedkeys.includes(`edeguQ05-${index}`)) {
            this.changedkeys.splice(this.changedkeys.indexOf(`edeguQ05-${index}`), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkEnergyUseStatus();
          break;

          case 'edeguQ05-source':
          // this.envData.edeguQ04.value = value;
          index = Number(value);
          this.envData.edeguQ05[index].updatedAt = new Date().toISOString();
          this.envData.edeguQ05[index].updatedBy = this.user.userId;
          if(this.envData.edeguQ05[index].source !== this.envDataClone.edeguQ05[index].source && !this.changedkeys.includes(`edeguQ05-source-${index}`)) {
            this.changedkeys.push(`edeguQ05-source-${index}`);
            this.counter++;
          } else if(this.envData.edeguQ05[index].source === this.envDataClone.edeguQ05[index].source && this.changedkeys.includes(`edeguQ05-source-${index}`)) {
            this.changedkeys.splice(this.changedkeys.indexOf(`edeguQ05-source-${index}`), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkEnergyUseStatus();
          break;

          case 'edwtuQ01':
          this.envData.edwtuQ01.value = this.allowOnlyInteger(this.envData.edwtuQ01.value.toString());
          if(this.envData.edwtuQ01.value !== ''){
            this.envData.edwtuQ01.value = Number(this.envData.edwtuQ01.value);
          }
          this.envData.edwtuQ01.updatedAt = new Date().toISOString();
          this.envData.edwtuQ01.updatedBy = this.user.userId;
          if(this.envData.edwtuQ01.value !== this.envDataClone.edwtuQ01.value && !this.changedkeys.includes('edwtuQ01')) {
            this.changedkeys.push('edwtuQ01');
            this.counter++;
          } else if(this.envData.edwtuQ01.value === this.envDataClone.edwtuQ01.value && this.changedkeys.includes('edwtuQ01')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edwtuQ01'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkWaterUseStatus();
          break;

          case 'edwtuQ02':
          this.envData.edwtuQ02.value = Number(this.envData.edwtuQ02.value);
          this.envData.edwtuQ02.updatedAt = new Date().toISOString();
          this.envData.edwtuQ02.updatedBy = this.user.userId;
          if(this.envData.edwtuQ02.value !== this.envDataClone.edwtuQ02.value && !this.changedkeys.includes('edwtuQ02')) {
            this.changedkeys.push('edwtuQ02');
            this.counter++;
          } else if(this.envData.edwtuQ02.value === this.envDataClone.edwtuQ02.value && this.changedkeys.includes('edwtuQ02')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edwtuQ02'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkWaterUseStatus();
          break;

        case 'edwtuQ03':
          this.envData.edwtuQ03.value = value;
          this.envData.edwtuQ03.updatedAt = new Date().toISOString();
          this.envData.edwtuQ03.updatedBy = this.user.userId;
          if(this.envData.edwtuQ03.value !== this.envDataClone.edwtuQ03.value && !this.changedkeys.includes('edwtuQ03')) {
            this.changedkeys.push('edwtuQ03');
            this.counter++;
          } else if(this.envData.edwtuQ03.value === this.envDataClone.edwtuQ03.value && this.changedkeys.includes('edwtuQ03')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edwtuQ03'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkWaterUseStatus();
          break;

          case 'edwtuQ06':
          this.envData.edwtuQ06.value = value;
          this.envData.edwtuQ06.updatedAt = new Date().toISOString();
          this.envData.edwtuQ06.updatedBy = this.user.userId;
          if(this.envData.edwtuQ06.value !== this.envDataClone.edwtuQ06.value && !this.changedkeys.includes('edwtuQ06')) {
            this.changedkeys.push('edwtuQ06');
            this.counter++;
          } else if(this.envData.edwtuQ06.value === this.envDataClone.edwtuQ06.value && this.changedkeys.includes('edwtuQ06')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edwtuQ06'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkWaterUseStatus();
          break;

          case 'edwtuQ07':
            this.envData.edwtuQ07.value = this.allowOnlyInteger(this.envData.edwtuQ07.value.toString());
            if(this.envData.edwtuQ07.value !== ''){
              this.envData.edwtuQ07.value = Number(this.envData.edwtuQ07.value);
            }
          this.envData.edwtuQ07.updatedAt = new Date().toISOString();
          this.envData.edwtuQ07.updatedBy = this.user.userId;
          if(this.envData.edwtuQ07.value !== this.envDataClone.edwtuQ07.value && !this.changedkeys.includes('edwtuQ07')) {
            this.changedkeys.push('edwtuQ07');
            this.counter++;
          } else if(this.envData.edwtuQ07.value === this.envDataClone.edwtuQ07.value && this.changedkeys.includes('edwtuQ07')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edwtuQ07'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkWaterUseStatus();
          break;

          case 'edwasQ01':
          this.envData.edwasQ01.value = value;
          this.envData.edwasQ01.updatedAt = new Date().toISOString();
          this.envData.edwasQ01.updatedBy = this.user.userId;
          if(this.envData.edwasQ01.value !== this.envDataClone.edwasQ01.value && !this.changedkeys.includes('edwasQ01')) {
            this.changedkeys.push('edwasQ01');
            this.counter++;
          } else if(this.envData.edwasQ01.value === this.envDataClone.edwasQ01.value && this.changedkeys.includes('edwasQ01')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edwasQ01'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkWastageStatus();
          break;

          case 'edwasQ02':
            this.envData.edwasQ02.value = this.allowTillHundred(this.envData.edwasQ02.value.toString());
            if(this.envData.edwasQ02.value !== ''){
              this.envData.edwasQ02.value = Number(this.envData.edwasQ02.value);
              if(this.envData.edwasQ02.value > 100){
                this.envData.edwasQ02.value = 100;
              } else if(this.envData.edwasQ02.value < 0){
                this.envData.edwasQ02.value = 0;
              }
            }
          this.envData.edwasQ02.updatedAt = new Date().toISOString();
          this.envData.edwasQ02.updatedBy = this.user.userId;
          if(this.envData.edwasQ02.value !== this.envDataClone.edwasQ02.value && !this.changedkeys.includes('edwasQ02')) {
            this.changedkeys.push('edwasQ02');
            this.counter++;
          } else if(this.envData.edwasQ02.value === this.envDataClone.edwasQ02.value && this.changedkeys.includes('edwasQ02')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edwasQ02'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkWastageStatus();
          break;

          case 'edwasQ03':
          this.envData.edwasQ03.value = value;
          this.envData.edwasQ03.updatedAt = new Date().toISOString();
          this.envData.edwasQ03.updatedBy = this.user.userId;
          if(this.envData.edwasQ03.value !== this.envDataClone.edwasQ03.value && !this.changedkeys.includes('edwasQ03')) {
            this.changedkeys.push('edwasQ03');
            this.counter++;
          } else if(this.envData.edwasQ03.value === this.envDataClone.edwasQ03.value && this.changedkeys.includes('edwasQ03')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edwasQ03'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkWastageStatus();
          break;

          case 'edcleQ01':
          this.envData.edcleQ01.value = value;
          this.envData.edcleQ01.updatedAt = new Date().toISOString();
          this.envData.edcleQ01.updatedBy = this.user.userId;
          if(this.envData.edcleQ01.value !== this.envDataClone.edcleQ01.value && !this.changedkeys.includes('edcleQ01')) {
            this.changedkeys.push('edcleQ01');
            this.counter++;
          } else if(this.envData.edcleQ01.value === this.envDataClone.edcleQ01.value && this.changedkeys.includes('edcleQ01')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcleQ01'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCircularEconomyStatus();
          break;

          case 'edcleQ02':
          this.envData.edcleQ02.value = value;
          this.envData.edcleQ02.updatedAt = new Date().toISOString();
          this.envData.edcleQ02.updatedBy = this.user.userId;
          if(this.envData.edcleQ02.value !== this.envDataClone.edcleQ02.value && !this.changedkeys.includes('edcleQ02')) {
            this.changedkeys.push('edcleQ02');
            this.counter++;
          } else if(this.envData.edcleQ02.value === this.envDataClone.edcleQ02.value && this.changedkeys.includes('edcleQ02')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcleQ02'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCircularEconomyStatus();
          break;

          case 'edcleQ03':
          this.envData.edcleQ03.value = value;
          this.envData.edcleQ03.updatedAt = new Date().toISOString();
          this.envData.edcleQ03.updatedBy = this.user.userId;
          if(this.envData.edcleQ03.value !== this.envDataClone.edcleQ03.value && !this.changedkeys.includes('edcleQ03')) {
            this.changedkeys.push('edcleQ03');
            this.counter++;
          } else if(this.envData.edcleQ03.value === this.envDataClone.edcleQ03.value && this.changedkeys.includes('edcleQ03')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcleQ03'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCircularEconomyStatus();
          break;

          case 'edcleQ04':
            this.envData.edcleQ04.value = this.allowOnlyInteger(this.envData.edcleQ04.value.toString());
            if(this.envData.edcleQ04.value !== ''){
              this.envData.edcleQ04.value = Number(this.envData.edcleQ04.value);
            }
          this.envData.edcleQ04.updatedAt = new Date().toISOString();
          this.envData.edcleQ04.updatedBy = this.user.userId;
          if(this.envData.edcleQ04.value !== this.envDataClone.edcleQ04.value && !this.changedkeys.includes('edcleQ04')) {
            this.changedkeys.push('edcleQ04');
            this.counter++;
          } else if(this.envData.edcleQ04.value === this.envDataClone.edcleQ04.value && this.changedkeys.includes('edcleQ04')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcleQ04'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCircularEconomyStatus();
          break;

          case 'edcleQ05':
            this.envData.edcleQ05.value = this.allowTillHundred(this.envData.edcleQ05.value.toString());
            if(this.envData.edcleQ05.value !== ''){
              this.envData.edcleQ05.value = Number(this.envData.edcleQ05.value);
              if(this.envData.edcleQ05.value > 100){
                this.envData.edcleQ05.value = 100;
              } else if(this.envData.edcleQ05.value < 0){
                this.envData.edcleQ05.value = 0;
              }
            }
          this.envData.edcleQ05.updatedAt = new Date().toISOString();
          this.envData.edcleQ05.updatedBy = this.user.userId;
          if(this.envData.edcleQ05.value !== this.envDataClone.edcleQ05.value && !this.changedkeys.includes('edcleQ05')) {
            this.changedkeys.push('edcleQ05');
            this.counter++;
          } else if(this.envData.edcleQ05.value === this.envDataClone.edcleQ05.value && this.changedkeys.includes('edcleQ05')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcleQ05'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCircularEconomyStatus();
          break;

          case 'edcleQ06':
            this.envData.edcleQ06.value = this.allowTillHundred(this.envData.edcleQ06.value.toString());
            if(this.envData.edcleQ06.value !== ''){
              this.envData.edcleQ06.value = Number(this.envData.edcleQ06.value);
              if(this.envData.edcleQ06.value > 100){
                this.envData.edcleQ06.value = 100;
              } else if(this.envData.edcleQ06.value < 0){
                this.envData.edcleQ06.value = 0;
              }
            }
          this.envData.edcleQ06.updatedAt = new Date().toISOString();
          this.envData.edcleQ06.updatedBy = this.user.userId;
          if(this.envData.edcleQ06.value !== this.envDataClone.edcleQ06.value && !this.changedkeys.includes('edcleQ06')) {
            this.changedkeys.push('edcleQ06');
            this.counter++;
          } else if(this.envData.edcleQ06.value === this.envDataClone.edcleQ06.value && this.changedkeys.includes('edcleQ06')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcleQ06'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCircularEconomyStatus();
          break;

          case 'edcleQ07':
            this.envData.edcleQ07.value = this.allowTillHundred(this.envData.edcleQ07.value.toString());
            if(this.envData.edcleQ07.value !== ''){
              this.envData.edcleQ07.value = Number(this.envData.edcleQ07.value);
              if(this.envData.edcleQ07.value > 100){
                this.envData.edcleQ07.value = 100;
              } else if(this.envData.edcleQ07.value < 0){
                this.envData.edcleQ07.value = 0;
              }
            }
          this.envData.edcleQ07.updatedAt = new Date().toISOString();
          this.envData.edcleQ07.updatedBy = this.user.userId;
          if(this.envData.edcleQ07.value !== this.envDataClone.edcleQ07.value && !this.changedkeys.includes('edcleQ07')) {
            this.changedkeys.push('edcleQ07');
            this.counter++;
          } else if(this.envData.edcleQ07.value === this.envDataClone.edcleQ07.value && this.changedkeys.includes('edcleQ07')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcleQ07'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCircularEconomyStatus();
          break;

          case 'edcbeQ01':
          this.envData.edcbeQ01.value = value;
          this.envData.edcbeQ01.updatedAt = new Date().toISOString();
          this.envData.edcbeQ01.updatedBy = this.user.userId;
          if(this.envData.edcbeQ01.value!== this.yes){
            this.envData.edcbeQ03.value = '';
            this.envData.edcbeQ03.updatedAt = new Date().toISOString();
            this.envData.edcbeQ03.updatedBy = this.user.userId;
            this.envData.edcbeQ05.value = '';
            this.envData.edcbeQ05.updatedAt = new Date().toISOString();
            this.envData.edcbeQ05.updatedBy = this.user.userId;
          }
          if(this.envData.edcbeQ01.value !== this.envDataClone.edcbeQ01.value && !this.changedkeys.includes('edcbeQ01')) {
            this.changedkeys.push('edcbeQ01');
            this.counter++;
          } else if(this.envData.edcbeQ01.value === this.envDataClone.edcbeQ01.value && this.changedkeys.includes('edcbeQ01')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcbeQ01'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCarbonEmissionStatus();
          break;

          case 'edcbeQ02':
            this.envData.edcbeQ02.value = this.allowOnlyInteger(this.envData.edcbeQ02.value.toString());
            if(this.envData.edcbeQ02.value !== ''){
              this.envData.edcbeQ02.value = Number(this.envData.edcbeQ02.value);
            }
          this.envData.edcbeQ02.updatedAt = new Date().toISOString();
          this.envData.edcbeQ02.updatedBy = this.user.userId;
          if(this.envData.edcbeQ02.value !== this.envDataClone.edcbeQ02.value && !this.changedkeys.includes('edcbeQ02')) {
            this.changedkeys.push('edcbeQ02');
            this.counter++;
          } else if(this.envData.edcbeQ02.value === this.envDataClone.edcbeQ02.value && this.changedkeys.includes('edcbeQ02')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcbeQ02'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCarbonEmissionStatus();
          break;

          case 'edcbeQ03':
          this.envData.edcbeQ03.value = value;
          this.envData.edcbeQ03.updatedAt = new Date().toISOString();
          this.envData.edcbeQ03.updatedBy = this.user.userId;
          if(this.envData.edcbeQ03.value!== this.yes){
            this.envData.edcbeQ05.value = '';
            this.envData.edcbeQ05.updatedAt = new Date().toISOString();
            this.envData.edcbeQ05.updatedBy = this.user.userId;
          }
          if(this.envData.edcbeQ03.value !== this.envDataClone.edcbeQ03.value && !this.changedkeys.includes('edcbeQ03')) {
            this.changedkeys.push('edcbeQ03');
            this.counter++;
          } else if(this.envData.edcbeQ03.value === this.envDataClone.edcbeQ03.value && this.changedkeys.includes('edcbeQ03')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcbeQ03'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCarbonEmissionStatus();
          break;

          case 'edcbeQ04':
            this.envData.edcbeQ04.value = this.allowOnlyInteger(this.envData.edcbeQ04.value.toString());
            if(this.envData.edcbeQ04.value !== ''){
              this.envData.edcbeQ04.value = Number(this.envData.edcbeQ04.value);
            }
          this.envData.edcbeQ04.updatedAt = new Date().toISOString();
          this.envData.edcbeQ04.updatedBy = this.user.userId;
          if(this.envData.edcbeQ04.value !== this.envDataClone.edcbeQ04.value && !this.changedkeys.includes('edcbeQ04')) {
            this.changedkeys.push('edcbeQ04');
            this.counter++;
          } else if(this.envData.edcbeQ04.value === this.envDataClone.edcbeQ04.value && this.changedkeys.includes('edcbeQ04')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcbeQ04'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCarbonEmissionStatus();
          break;

          case 'edcbeQ05':
          this.envData.edcbeQ05.value = value;
          this.envData.edcbeQ05.updatedAt = new Date().toISOString();
          this.envData.edcbeQ05.updatedBy = this.user.userId;
          if(this.envData.edcbeQ05.value !== this.envDataClone.edcbeQ05.value && !this.changedkeys.includes('edcbeQ05')) {
            this.changedkeys.push('edcbeQ05');
            this.counter++;
          } else if(this.envData.edcbeQ05.value === this.envDataClone.edcbeQ05.value && this.changedkeys.includes('edcbeQ05')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcbeQ05'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCarbonEmissionStatus();
          break;

          case 'edcbeQ06':
            this.envData.edcbeQ06.value = this.allowOnlyInteger(this.envData.edcbeQ06.value.toString());
            if(this.envData.edcbeQ06.value !== ''){
              this.envData.edcbeQ06.value = Number(this.envData.edcbeQ06.value);
            }
          this.envData.edcbeQ06.updatedAt = new Date().toISOString();
          this.envData.edcbeQ06.updatedBy = this.user.userId;
          if(this.envData.edcbeQ06.value !== this.envDataClone.edcbeQ06.value && !this.changedkeys.includes('edcbeQ06')) {
            this.changedkeys.push('edcbeQ06');
            this.counter++;
          } else if(this.envData.edcbeQ06.value === this.envDataClone.edcbeQ06.value && this.changedkeys.includes('edcbeQ06')) {
            this.changedkeys.splice(this.changedkeys.indexOf('edcbeQ06'), 1);
            this.counter--;
          } else {
            // no action
          }
          this.checkCarbonEmissionStatus();
          break;
        }
        if(this.counter > 0){
          this.$store.commit('setEsgDataChanged', true);
        }else{
          this.$store.commit('setEsgDataChanged', false);
        }
  }

  private async setEdwtuQ04Other(event: any, key:string): Promise<void> {
    if(this.edwtuQ04Other !== '' && this.waterTrtmntPlantType[8].isSelected) {
      this.changedkeys.push('edwtuQ048');
      const otherIndex = this.envData.edwtuQ04.findIndex(f => f.value === '' || f.value === this.edwtuQ04OtherClone);
      if(otherIndex!==-1){
        this.envData.edwtuQ04[otherIndex].value = this.edwtuQ04Other;
        this.counter++;
      }
    }
    this.checkWaterUseStatus();
  }

  private setEdwtuQ04(selected:string, index:number): void{
    if(selected === this.yes){
      this.waterTrtmntPlantType[index].isSelected = true;
      const wtplantType: EsgTextAnsModel = {
        value: index !==8 ? this.waterTrtmntPlantType[index].title : '',
        updatedAt: new Date().toISOString(),
        updatedBy: this.user.userId
      };
      this.envData.edwtuQ04.push(wtplantType);
    }else{
      this.waterTrtmntPlantType[index].isSelected = false;
      if(index === 8){
        const otherIndex = this.envData.edwtuQ04.findIndex(e => e.value === this.edwtuQ04OtherClone || e.value === this.edwtuQ04Other);
        if(otherIndex!==-1){
          this.envData.edwtuQ04.splice(otherIndex, 1);
          this.edwtuQ04Other = '';
        }
      }else{
        this.envData.edwtuQ04.splice(index, 1);
      }
    }
    if(this.envData.edwtuQ04 !== this.envDataClone.edwtuQ04 && !this.changedkeys.includes('edwtuQ04'+index)) {
      this.changedkeys.push('edwtuQ04'+index);
      this.counter++;
    } else if(this.envData.edwtuQ04 === this.envDataClone.edwtuQ04 && this.changedkeys.includes('edwtuQ04'+index)) {
      this.changedkeys.splice(this.changedkeys.indexOf('edwtuQ04'+index), 1);
      this.counter--;
    } else {
      // no action
    }
    this.checkWaterUseStatus();
  }

  private async setEdwtuQ08Other(event: any, key:string): Promise<void> {
    if(this.edwtuQ08Other !== '' && this.recycleWasteWater[3].isSelected) {
      this.changedkeys.push('edwtuQ083');
      const otherIndex = this.envData.edwtuQ08.findIndex(f => f.value === '' || f.value === this.edwtuQ08OtherClone);
      if(otherIndex!==-1){
        this.envData.edwtuQ08[otherIndex].value = this.edwtuQ08Other;
        this.counter++;
      }
    }
    this.checkWaterUseStatus();
  }

  private setEdwtuQ08(selected:string, index:number): void{
    if(selected === this.yes){
      this.recycleWasteWater[index].isSelected = true;
      const rwasteWater: EsgTextAnsModel = {
        value: index!==3 ? this.recycleWasteWater[index].title : '',
        updatedAt: new Date().toISOString(),
        updatedBy: this.user.userId
      };
      this.envData.edwtuQ08.push(rwasteWater);
    }else{
      this.recycleWasteWater[index].isSelected = false;
      if(index === 3){
        const otherIndex = this.envData.edwtuQ08.findIndex(e => e.value === this.edwtuQ08OtherClone);
        if(otherIndex!==-1){
          this.envData.edwtuQ08.splice(otherIndex, 1);
          this.edwtuQ08Other = '';
        }
      }else{
        this.envData.edwtuQ08.splice(index, 1);
      }
    }
    if(this.envData.edwtuQ08 !== this.envDataClone.edwtuQ08 && !this.changedkeys.includes('edwtuQ08'+index)) {
      this.changedkeys.push('edwtuQ08'+index);
      this.counter++;
    } else if(this.envData.edwtuQ08 === this.envDataClone.edwtuQ08 && this.changedkeys.includes('edwtuQ08'+index)) {
      this.changedkeys.splice(this.changedkeys.indexOf('edwtuQ08'+index), 1);
      this.counter--;
    } else {
      // no action
    }
    this.checkWaterUseStatus();
  }

  private addSource(): void{
    this.envData.edeguQ05.push(new EsgEnergySourceModel());
    this.envDataClone.edeguQ05.push(new EsgEnergySourceModel());
  }

  private removeSource(index:number): void{
    this.envData.edeguQ05.splice(index, 1);
  }

  private openUploadEsgDocsModel(type: string, key: string): void {
    if(this.isReadOnly){
      return
    } else {
      this.uploadType = type;
      this.uploadDocKey = key;
      if(this.companyAddresses.length > 1){
        this.isFacilityModalVisible = true;
      } else {
        this.companyAddresses.forEach(address => {
          const facility: EsgAddressStatus = {
            addressID: address.id,
            status: '',
            isSelected: true
          };
          this.uploadDocSelectedFacility.push(facility);
        });
        this.isUploadDocModalVisible = true;
      }
    }
  }

  private async getDocUrl(doc: EsgDocsModel, key: string): Promise<void> {
    const index = this.esgEnv.findIndex(s => s.addressID === this.activeAddress.id);
    if(index !== -1){
      const data: EsgEnvDocUrlReq = {
        ID: this.esgEnv[index].ID,
        companyID: this.user.companyId,
        envDataDocType: key,
        envDataDocTypeID: doc.ID
      }
      const response = await this.supplierService.getEnvDocUrlAsync(data);
      if(response.success){
        this.esgDocUrl = response.envDoc[0].docUrl;
      }
    }
  }

  private showFacilityModal(status: boolean, type: string): void {
      this.isFacilityModalVisible = status;
      this.uploadType = type;
  }

  private showEsgUploadDoc(status: boolean, selectedFacilities: EsgAddressStatus[]): void {
    this.uploadDocSelectedFacility = [];
    this.uploadDocSelectedFacility.push(...selectedFacilities);
    this.isUploadDocModalVisible = status;
    this.isFacilityModalVisible = false;
  }

  private async openDoc(doc: EsgDocsModel, key: string): Promise<void> {
    await this.getDocUrl(doc, key);
    this.viewDocFileName = doc.fileName;
    this.showViewDocument = true;
  }

  private async downloadDoc(doc: EsgDocsModel, key: string): Promise<void> {
    await this.getDocUrl(doc, key);
    const docElem = document.getElementById('esgDoc');
    if(docElem){
      docElem.click();
    }
  }

  private showDeleteDoc(deleteDocIndex: number, key: string): void {
    this.deleteDocIndex = deleteDocIndex;
    this.deleteDocKey = key;
    this.showDeleteDocModal = true;
  }

  private closeEsgUploadDoc(status: boolean, uploadedDoc: EsgUploadDocModel): void {
    if(uploadedDoc !== undefined && uploadedDoc !== null){
      (this.envData as any)[this.uploadDocKey].push(uploadedDoc);
      this.isUploadDocModalVisible = !status;
      this.isFacilityModalVisible = false;
      this.counter++;
      this.checkAllSectionStatus();
    } else {
      this.isUploadDocModalVisible = !status;
      this.isFacilityModalVisible = this.companyAddresses.length > 1 ? true : false;
    }
}

  private checkAllSectionStatus(): void {
    this.checkEnergyUseStatus();
    this.checkWaterUseStatus();
    this.checkWastageStatus();
    this.checkCircularEconomyStatus();
    this.checkCircularEconomyStatus();
    this.checkEnvStatus();
  }

  private checkEnergyUseStatus(): void {
    if(this.envData.edeguQ01.value !== '' && this.envData.edeguQ02.value !== '' && this.envData.edeguQ03.value !== '' && this.envData.edeguQ04.value !== '' && this.envData.edeguQ05.every(source => source.value !== '' && source.source !== '')) {
      this.envData.energyUseStatus = EsgStatus.COMPLETED;
    } else if (this.envData.edeguQ01.value === '' && this.envData.edeguQ02.value === '' && this.envData.edeguQ03.value === '' && this.envData.edeguQ04.value === '' && this.envData.edeguQ05.every(source => source.value === '') && this.envData.edeguQ06.length === 0) {
      this.envData.energyUseStatus = this.notStartedStatus;
    } else {
      this.envData.energyUseStatus = this.inProgressStatus;
    }
    this.checkEnvStatus();
  }

  private checkWaterUseStatus(): void {
    if(this.envData.edwtuQ01.value !== '' && this.envData.edwtuQ02.value !== '' && this.envData.edwtuQ03.value === this.no && ((this.envData.edwtuQ06.value === this.no) || (this.envData.edwtuQ06.value === this.yes && this.envData.edwtuQ07.value !== '' && this.envData.edwtuQ08.length > 0))) {
      this.envData.waterUseStatus = EsgStatus.COMPLETED;
    } else if (this.envData.edwtuQ01.value !== '' && this.envData.edwtuQ02.value !== '' && this.envData.edwtuQ03.value === this.yes && this.envData.edwtuQ04.length > 0 && ((this.envData.edwtuQ06.value === this.no) || (this.envData.edwtuQ06.value === this.yes && this.envData.edwtuQ07.value !== '' && this.envData.edwtuQ08.length > 0))) {
      this.envData.waterUseStatus = EsgStatus.COMPLETED;
    } else if (this.envData.edwtuQ01.value === '' && this.envData.edwtuQ02.value === '' && this.envData.edwtuQ03.value === '' && this.envData.edwtuQ04.length === 0 && this.envData.edwtuQ05.length === 0 && this.envData.edwtuQ06.value === '' && this.envData.edwtuQ07.value === '' && this.envData.edwtuQ08.length === 0) {
      this.envData.waterUseStatus = this.notStartedStatus;
    } else {
      this.envData.waterUseStatus = this.inProgressStatus;
    }
    this.checkEnvStatus();
  }

  private checkWastageStatus(): void {
    if(this.envData.edwasQ01.value === this.no && this.envData.edwasQ03.value !== '') {
      this.envData.wastageStatus = EsgStatus.COMPLETED;
    } else if (this.envData.edwasQ01.value === this.yes && this.envData.edwasQ02.value !== '' && this.envData.edwasQ03.value !== '') {
      this.envData.wastageStatus = EsgStatus.COMPLETED;
    } else if (this.envData.edwasQ01.value === '' && this.envData.edwasQ02.value === '' && this.envData.edwasQ03.value === '' && this.envData.edwasQ03Docs.length === 0) {
      this.envData.wastageStatus = this.notStartedStatus;
    } else {
      this.envData.wastageStatus = this.inProgressStatus;
    }
    this.checkEnvStatus();
  }

  private checkCircularEconomyStatus(): void {
    if(this.envData.edcleQ01.value !== '' && this.envData.edcleQ02.value !== '' && (this.envData.edcleQ03.value !== '' &&  this.envData.edcleQ03.value !== this.yes)) {
      this.envData.circularEconomyStatus = EsgStatus.COMPLETED;
    } else if (this.envData.edcleQ01.value !== '' && this.envData.edcleQ02.value !== '' && this.envData.edcleQ03.value === this.yes && this.envData.edcleQ04.value !== '' && this.envData.edcleQ05.value !== '' && this.envData.edcleQ06.value !== '' && this.envData.edcleQ07.value !== '') {
      this.envData.circularEconomyStatus = EsgStatus.COMPLETED;
    } else if (this.envData.edcleQ01.value === '' && this.envData.edcleQ02.value === '' && this.envData.edcleQ03.value === '' && this.envData.edcleQ04.value === '' && this.envData.edcleQ05.value === '' && this.envData.edcleQ06.value === '' && this.envData.edcleQ07.value === '' && this.envData.edcleQ08.length === 0) {
      this.envData.circularEconomyStatus = this.notStartedStatus;
    } else {
      this.envData.circularEconomyStatus = this.inProgressStatus;
    }
    this.checkEnvStatus();
  }

  private checkCarbonEmissionStatus(): void {
    if(this.envData.edcbeQ01.value === this.no && this.envData.edcbeQ02.value === '' && this.envData.edcbeQ03.value === '' && this.envData.edcbeQ04.value === '' && this.envData.edcbeQ05.value === '' && this.envData.edcbeQ06.value === '') {
      this.envData.carbonEmissionStatus = EsgStatus.COMPLETED;
    } else if (this.envData.edcbeQ01.value === this.yes && this.envData.edcbeQ02.value !== '' && ((this.envData.edcbeQ03.value === this.no) || (this.envData.edcbeQ03.value === this.yes && this.envData.edcbeQ04.value !== '' && (this.envData.edcbeQ05.value === this.no || (this.envData.edcbeQ05.value === this.yes && this.envData.edcbeQ06.value !== ''))))) {
      this.envData.carbonEmissionStatus = EsgStatus.COMPLETED
    } else if (this.envData.edcbeQ01.value === '' && this.envData.edcbeQ03.value === '' && this.envData.edcbeQ05.value === '' && this.envData.edcbeQ07.length === 0) {
      this.envData.carbonEmissionStatus = this.notStartedStatus;
    } else {
      this.envData.carbonEmissionStatus = this.inProgressStatus;
    }
    this.checkEnvStatus();
  }

  private checkEnvStatus(): void {
    if(this.envData.energyUseStatus === EsgStatus.COMPLETED && this.envData.waterUseStatus === EsgStatus.COMPLETED && this.envData.wastageStatus === EsgStatus.COMPLETED && this.envData.carbonEmissionStatus === EsgStatus.COMPLETED && this.envData.circularEconomyStatus === EsgStatus.COMPLETED) {
      this.envData.status = EsgStatus.COMPLETED;
    } else if(this.envData.energyUseStatus === this.notStartedStatus && this.envData.waterUseStatus === this.notStartedStatus && this.envData.wastageStatus === this.notStartedStatus && this.envData.carbonEmissionStatus === this.notStartedStatus && this.envData.circularEconomyStatus === this.notStartedStatus) {
      this.envData.status = this.notStartedStatus;
    } else {
      this.envData.status = EsgStatus.INPROGRESS;
    }
    this.facilityStatus(this.activeAddress.id, this.envData.status);
  }

  private cancelChanges(): void {
    this.envData = cloneDeep(this.envDataClone);
    this.facilityStatus(this.activeAddress.id, this.envData.status);
    this.confirmSave();
    this.setTreatmentValues();
    this.counter = 0;
    this.changedkeys = [];
    this.showConfirmCancel = false;
    this.$store.commit('setEsgDataChanged', false);
  }

  private deleteDoc(): void {
    (this.envData as any)[this.deleteDocKey].splice(this.deleteDocIndex, 1);
    this.counter++;
    this.showDeleteDocModal = false;
  }

  private closeViewDocument(status: boolean): void {
    this.showViewDocument = status;
  }

  private setFollowUpQues(): void {
    this.setWtuFollowUpQues();
    this.setWasFollowUpQues();
    this.setCleFollowUpQues();
    this.setCbeFollowUpQues();
    this.$forceUpdate();
    this.checkAllSectionStatus();
  }

  private setWtuFollowUpQues(): void {
    if(this.envData.edwtuQ05.length > 0){
      this.envData.edwtuQ03.value = this.yes;
      this.envData.edwtuQ03.updatedAt = new Date().toISOString();
      this.envData.edwtuQ03.updatedBy = this.user.userId;
    }
  }

  private setWasFollowUpQues(): void {
    if(this.envData.edwasQ03Docs.length > 0){
      this.envData.edwasQ03.value = this.yes;
      this.envData.edwasQ03.updatedAt = new Date().toISOString();
      this.envData.edwasQ03.updatedBy = this.user.userId;
    }
  }

  private setCleFollowUpQues(): void {
    if(this.envData.edcleQ08.length > 0){
      this.envData.edcleQ03.value = this.yes;
      this.envData.edcleQ03.updatedAt = new Date().toISOString();
      this.envData.edcleQ03.updatedBy = this.user.userId;
    }
  }

  private setCbeFollowUpQues(): void {
    if(this.envData.edcbeQ07.length > 0){
      this.envData.edcbeQ01.value = this.yes;
      this.envData.edcbeQ01.updatedAt = new Date().toISOString();
      this.envData.edcbeQ01.updatedBy = this.user.userId;
    }
  }

  private validateQuestions(data: EsgEnvModel): EsgEnvModel {
    if(data.edwtuQ03.value !== this.yes){
      data.edwtuQ04 = [];
      this.waterTrtmntPlantType = this.waterTrtmntPlantType.map(item => ({
        ...item,
        isSelected: false
      }));
      this.edwtuQ04Other = '';
      this.edwtuQ04OtherClone = '';
      data.edwtuQ05 = [];
    }
    if( data.edwtuQ06.value!== this.yes){
      data.edwtuQ07.value = '';
      data.edwtuQ07.updatedAt = new Date().toISOString();
      data.edwtuQ07.updatedBy = this.user.userId;
      data.edwtuQ08 = [];
      this.recycleWasteWater = this.recycleWasteWater.map(item => ({
        ...item,
        isSelected: false
      }));
      this.edwtuQ08Other = '';
      this.edwtuQ08OtherClone = '';
    }
    if(data.edwasQ01.value!== this.yes){
      data.edwasQ02.value = '';
      data.edwasQ02.updatedAt = new Date().toISOString();
      data.edwasQ02.updatedBy = this.user.userId;
    }
    if(data.edwasQ03.value!== this.yes){
      data.edwasQ03Docs = [];
    }
    if(data.edcleQ03.value!== this.yes){
      data.edcleQ04.value = '';
      data.edcleQ04.updatedAt = new Date().toISOString();
      data.edcleQ04.updatedBy = this.user.userId;
      data.edcleQ05.value = '';
      data.edcleQ05.updatedAt = new Date().toISOString();
      data.edcleQ05.updatedBy = this.user.userId;
      data.edcleQ06.value = '';
      data.edcleQ06.updatedAt = new Date().toISOString();
      data.edcleQ06.updatedBy = this.user.userId;
      data.edcleQ07.value = '';
      data.edcleQ07.updatedAt = new Date().toISOString();
      data.edcleQ07.updatedBy = this.user.userId;
      data.edcleQ08 = [];
    }
    if(data.edcbeQ01.value!== this.yes){
      data.edcbeQ02.value = '';
      data.edcbeQ02.updatedAt = new Date().toISOString();
      data.edcbeQ02.updatedBy = this.user.userId;
      data.edcbeQ03.value = '';
      data.edcbeQ03.updatedAt = new Date().toISOString();
      data.edcbeQ03.updatedBy = this.user.userId;
      data.edcbeQ04.value = '';
      data.edcbeQ04.updatedAt = new Date().toISOString();
      data.edcbeQ04.updatedBy = this.user.userId;
      data.edcbeQ05.value = '';
      data.edcbeQ05.updatedAt = new Date().toISOString();
      data.edcbeQ05.updatedBy = this.user.userId;
      data.edcbeQ06.value = '';
      data.edcbeQ06.updatedAt = new Date().toISOString();
      data.edcbeQ06.updatedBy = this.user.userId;
      data.edcbeQ07 = [];
    }
    if(data.edcbeQ03.value!== this.yes){
      data.edcbeQ04.value = '';
      data.edcbeQ04.updatedAt = new Date().toISOString();
      data.edcbeQ04.updatedBy = this.user.userId;
      data.edcbeQ05.value = '';
      data.edcbeQ05.updatedAt = new Date().toISOString();
      data.edcbeQ05.updatedBy = this.user.userId;
      data.edcbeQ06.value = '';
      data.edcbeQ06.updatedAt = new Date().toISOString();
      data.edcbeQ06.updatedBy = this.user.userId;
    }
    if(data.edcbeQ05.value!== this.yes){
      data.edcbeQ06.value = '';
      data.edcbeQ06.updatedAt = new Date().toISOString();
      data.edcbeQ06.updatedBy = this.user.userId;
    }
    return data;
  }

  private parseNegativeValueToString(): void {
    this.envData.edeguQ01.value = this.envData.edeguQ01.value !== -1 ? this.envData.edeguQ01.value : '';
    this.envData.edeguQ02.value = this.envData.edeguQ02.value !== -1 ? this.envData.edeguQ02.value : '';
    this.envData.edeguQ03.value = this.envData.edeguQ03.value !== -1 ? this.envData.edeguQ03.value : '';
    this.envData.edeguQ04.value = this.envData.edeguQ04.value !== -1 ? this.envData.edeguQ04.value : '';
    this.envData.edwtuQ01.value = this.envData.edwtuQ01.value !== -1 ? this.envData.edwtuQ01.value : '';
    this.envData.edwtuQ02.value = this.envData.edwtuQ02.value !== -1 ? this.envData.edwtuQ02.value : '';
    this.envData.edwtuQ07.value = this.envData.edwtuQ07.value !== -1 ? this.envData.edwtuQ07.value : '';
    this.envData.edwasQ02.value = this.envData.edwasQ02.value !== -1 ? this.envData.edwasQ02.value : '';
    this.envData.edcleQ04.value = this.envData.edcleQ04.value !== -1 ? this.envData.edcleQ04.value : '';
    this.envData.edcleQ05.value = this.envData.edcleQ05.value !== -1 ? this.envData.edcleQ05.value : '';
    this.envData.edcleQ06.value = this.envData.edcleQ06.value !== -1 ? this.envData.edcleQ06.value : '';
    this.envData.edcleQ07.value = this.envData.edcleQ07.value !== -1 ? this.envData.edcleQ07.value : '';
    this.envData.edcbeQ02.value = this.envData.edcbeQ02.value !== -1 ? this.envData.edcbeQ02.value : '';
    this.envData.edcbeQ04.value = this.envData.edcbeQ04.value !== -1 ? this.envData.edcbeQ04.value : '';
    this.envData.edcbeQ06.value = this.envData.edcbeQ06.value !== -1 ? this.envData.edcbeQ06.value : '';
    this.envData.edeguQ05 = this.envData.edeguQ05.map(f => ({
      ...f,
      value: f.value !== -1 ? f.value : ''
    }));
  }

  private async save(): Promise<void>{
    try{
      this.isSaving = true;
      const envIndex = this.esgEnv.findIndex(s => s.addressID === this.activeAddress.id);
      this.envData.ID = envIndex !== -1 ? this.esgEnv[envIndex].ID : uuidv4();
      this.envData.addressID = this.activeAddress.id;
      this.envData.addressStatus = this.activeAddress.addressStatus;
      this.envData.companyID = this.user.companyId;
      this.envData.reminderSentDate = this.envData.reminderSentDate !== "" && this.envData.reminderSentDate !== this.defaultDate ? this.envData.reminderSentDate : this.defaultDate;
      this.envData.createdAt = new Date().toISOString();
      this.envData.updatedAt = new Date().toISOString();
      this.envData.edLastUpdatedAt = new Date().toISOString();
      if(this.envData.edwtuQ04.length > 0){
        const index = this.envData.edwtuQ04.findIndex(f => f.value === '');
        if(index!==-1){
          this.envData.edwtuQ04.splice(index, 1);
        }
      }
      if(this.envData.edwtuQ08.length > 0){
        const index = this.envData.edwtuQ08.findIndex(f => f.value === '');
        if(index!==-1){
          this.envData.edwtuQ08.splice(index, 1);
        }
      }
      if(this.envData.edeguQ02.value !== this.notStartedStatus || this.envData.edeguQ03.value! == this.notStartedStatus || this.envData.edeguQ04.value !== this.notStartedStatus){
        if(this.envData.edeguQ02.value === this.notStartedStatus){
          this.envData.edeguQ02.value = 0;
        }
        if(this.envData.edeguQ03.value === this.notStartedStatus){
          this.envData.edeguQ03.value = 0;
        }
        if(this.envData.edeguQ04.value === this.notStartedStatus){
          this.envData.edeguQ04.value = 0;
        }
      }
      let env = cloneDeep(this.envData);
      env = this.validateQuestions(env);
      env.edeguQ01.value = env.edeguQ01.value !== this.notStartedStatus ? env.edeguQ01.value : -1;
      env.edeguQ02.value = env.edeguQ02.value !== this.notStartedStatus ? env.edeguQ02.value : -1;
      env.edeguQ03.value = env.edeguQ03.value !== this.notStartedStatus ? env.edeguQ03.value : -1;
      env.edeguQ04.value = env.edeguQ04.value !== this.notStartedStatus ? env.edeguQ04.value : -1;
      env.edwtuQ01.value = env.edwtuQ01.value !== this.notStartedStatus ? env.edwtuQ01.value : -1;
      env.edwtuQ02.value = env.edwtuQ02.value !== this.notStartedStatus ? env.edwtuQ02.value : -1;
      env.edwtuQ07.value = env.edwtuQ07.value !== this.notStartedStatus ? env.edwtuQ07.value : -1;
      env.edwasQ02.value = env.edwasQ02.value !== this.notStartedStatus ? env.edwasQ02.value : -1;
      env.edcleQ04.value = env.edcleQ04.value !== this.notStartedStatus ? env.edcleQ04.value : -1;
      env.edcleQ05.value = env.edcleQ05.value !== this.notStartedStatus ? env.edcleQ05.value : -1;
      env.edcleQ06.value = env.edcleQ06.value !== this.notStartedStatus ? env.edcleQ06.value : -1;
      env.edcleQ07.value = env.edcleQ07.value !== this.notStartedStatus ? env.edcleQ07.value : -1;
      env.edcbeQ02.value = env.edcbeQ02.value !== this.notStartedStatus ? env.edcbeQ02.value : -1;
      env.edcbeQ04.value = env.edcbeQ04.value !== this.notStartedStatus ? env.edcbeQ04.value : -1;
      env.edcbeQ06.value = env.edcbeQ06.value !== this.notStartedStatus ? env.edcbeQ06.value : -1;
      env.edeguQ05 = this.envData.edeguQ05.map(f => ({
        ...f,
        value: f.value !== this.notStartedStatus ? f.value : -1
      }));
      const response = await this.supplierService.saveEnvEsgDataAsync(env);
      if(response.success){
        this.envData = response.envData.filter(sd => sd.addressID === this.activeAddress.id)[0];
        this.envData.energyUseExpanded = false;
        this.envData.waterUseExpanded = false;
        this.envData.wastageExpanded = false;
        this.envData.circularEconomyExpanded = false;
        this.envData.carbonEmissionExpanded = false;
        this.parseNegativeValueToString();
        this.envDataClone = cloneDeep(this.envData);
        this.confirmSave();
        this.counter = 0;
        this.changedkeys = [];
        this.$store.commit('setEsgDataChanged', false);
      }
    }
    finally{
      this.isSaving = false;
    }
  }

  private setTreatmentValues(): void{
    if(this.envDataClone.edwtuQ04.length > 0){
      this.envDataClone.edwtuQ04.forEach(ed => {
        const index = this.waterTrtmntPlantType.findIndex(w => w.title === ed.value);
        if(index!==-1){
          this.waterTrtmntPlantType[index].isSelected = true;
        }
      })
      const otherIndex = this.envDataClone.edwtuQ04.findIndex(e => !this.waterTrtmntPlantType.some(f => f.title === e.value))
      if(otherIndex!==-1){
        this.waterTrtmntPlantType[8].isSelected = true;
        this.edwtuQ04Other = this.envDataClone.edwtuQ04[otherIndex].value;
        this.edwtuQ04OtherClone = this.envDataClone.edwtuQ04[otherIndex].value;
      }else{
        this.waterTrtmntPlantType[8].isSelected = false;
        this.edwtuQ04Other = '';
        this.edwtuQ04OtherClone = '';
      }
    }
    if(this.envDataClone.edwtuQ08.length > 0){
      this.envDataClone.edwtuQ08.forEach(ed => {
        const index = this.recycleWasteWater.findIndex(w => w.title === ed.value);
        if(index!==-1){
          this.recycleWasteWater[index].isSelected = true;
        }
      })
      const otherRecycleIndex = this.envDataClone.edwtuQ08.findIndex(f => !this.recycleWasteWater.some(w => w.title===f.value));
      if(otherRecycleIndex!==-1){
        this.recycleWasteWater[3].isSelected = true;
        this.edwtuQ08Other = this.envDataClone.edwtuQ08[otherRecycleIndex].value;
        this.edwtuQ08OtherClone = this.envDataClone.edwtuQ08[otherRecycleIndex].value;
      }else{
        this.recycleWasteWater[3].isSelected = false;
        this.edwtuQ08Other = '';
        this.edwtuQ08OtherClone = '';
      }
    }
  }


  @Emit()
  private facilityStatus(addId: string, status: string): void {}

  @Emit()
  private confirmSave(): void {}
}
