import { debounce } from 'ts-debounce';
import { Vue, Component } from 'vue-property-decorator';

// Components
import Authpage from '@/components/general/authpage.vue';
import SmallScreenPopUpMsg from '@/components/general/smallScreenPopUpMsg/smallScreenPopUpMsg.vue';

// Models
import { SigninSupplierModel } from '@/models/signinSupplierModel';

// Services
import { UserService } from '@/services/userService';
import { MiddlewareService } from '@/services/middlewareService';
import { NonRomanCharCheckHelper }from '@/helpers/nonRomanCharCheckHelper';

@Component({
  components: { Authpage, SmallScreenPopUpMsg },
})
export default class Signin extends Vue {
  private userService: UserService;
  private middlewareService: MiddlewareService;
  public constructor() {
    super();
    this.userService = new UserService();
    this.middlewareService = new MiddlewareService();
  }

  private rememberMe: boolean = false;
  private isLoading: boolean = false;
  private inviteError: boolean = false;
  private emailError: boolean = false;
  private pwdError: boolean = false;
  private loginError: boolean = false;
  private loginErrorMesssage: string = '';
  private emailAddress: string = '';
  private password: string = '';
  private checkEmailIsValidDebounced: any;
  private showMobileMsg: boolean = false;
  private mobileMsgText: string = '';
  private errorType: number = 0;
  private noCompany: string = '';
  private isEmailNonRoman: boolean = false;
  private isPassWordNonRoman: boolean = false;
  private secondUserNotconfirmed: boolean = false;

  private async created(): Promise<void> {
    localStorage.removeItem('user');
    sessionStorage.removeItem('access_token');
    localStorage.removeItem('invitationId');
    this.checkEmailIsValidDebounced = debounce(this.checkEmailIsValid, 350);
    if (this.$route.params.accountCreated) {
      this.errorType = 1;
    }
    if (this.$route.params.linkExpired) {
      this.errorType = 2;
    }
    setTimeout(
      (app: any) => {
        app.setBodyOverflow();
      },
      500,
      this
    );
  }

  private async checkNonRomanChar(variableName:string,val:string): Promise<void>{
    (this as any)[variableName] = await NonRomanCharCheckHelper.checkNonRomanChar(val)
  }

  private async checkEnterKey(event: any): Promise<void> {
    if (event.keyCode == 13) {
      this.signinSupplier();
    }
  }

  private async checkEmailIsValid(event: any): Promise<void> {
    const emailAddress = event.target.value;

    if (emailAddress.length < 1) {
      return;
    }

    const matches = emailAddress
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    this.emailError = matches == null ? true : false;
    return;
  }

  private async signinSupplier(): Promise<void> {
    if (this.emailAddress.trim() == '' || this.emailError || this.inviteError) {
      return;
    }
    if (this.password.trim() == '') {
      this.loginErrorMesssage = 'Please enter your password';
      this.loginError = true;
      return;
    }
    this.noCompany = '';
    this.isLoading = true;

    const supplier = new SigninSupplierModel();
    supplier.Email = this.emailAddress.trim();
    supplier.Password = this.password.trim();

    const response = await this.middlewareService.signinSupplier(supplier);

    switch (response.response_type) {
      case 'success':
        this.loginError = false;
        this.pwdError = false;
        sessionStorage.setItem('access_token', response.access_token);
        try {
          const lInfoExists = await this.userService.loadUserInfoAsync(true);
          if (lInfoExists === 'TRUE') {
            if (this.$route.params.settings) {
              setTimeout( (app: any) => {
                app.$router.push({ name: "Profile", params: { 'admin': '1'}});
              },500, this );
            } else {
              setTimeout( (app: any) => {
                app.$router.push({ name: "Home" });
              },500, this );
            }
          } else if (lInfoExists === 'NOUSER') {
            const base_url = window.location.origin;
            let appUrl = '';
            if (base_url.includes('test')) {
              appUrl = 'my-test.tex-tracer.com';
            } else if (base_url.includes('acc')) {
              appUrl = 'my-acc.tex-tracer.com';
            } else if (base_url.includes('demo')) {
              appUrl = 'my-demo.tex-tracer.com';
            } else {
              appUrl = 'my.tex-tracer.com';
            }
            this.noCompany = this.$t('pages.auth.company_not_match', [
              appUrl,
            ]).toString();
            this.isLoading = false;
            this.password = '';
          } else if (lInfoExists === 'BLOCKED') {
            this.isLoading = false;
            this.password = '';
          } else if(lInfoExists === 'REQUESTED'){
            this.secondUserNotconfirmed = true;
          } else {
            this.isLoading = false;
            this.inviteError = true;
            this.password = '';
          }

          if (this.rememberMe) {
            this.saveUserDetailsToLocalStorage();
          } else {
            localStorage.removeItem('rememberUser');
          }
        } catch (e) {
          this.inviteError = true;
          this.isLoading = false;
          this.password = '';
        }
        break;
      default:
        if (response.response_message == 'Password Incorrect') {
          this.pwdError = true;
          this.loginErrorMesssage = response.response_message;
          break;
        }
        this.loginError = true;
        this.loginErrorMesssage = response.response_message;
        break;
    }
    this.isLoading = false;

    return;
  }

  private async toggleRememberMe(): Promise<void> {
    this.rememberMe = !this.rememberMe;
    localStorage.setItem(
      'rememberUser',
      JSON.stringify({ save: this.rememberMe })
    );
  }

  // save user details to local storage when remember me is checked
  private async saveUserDetailsToLocalStorage(): Promise<void> {
    localStorage.setItem(
      'rememberUser',
      JSON.stringify({
        email: this.emailAddress,
        save: this.rememberMe,
      })
    );
  }

  private async mounted(): Promise<void> {
    // check if user has checked remember me checkbox
    const rememberUser = JSON.parse(
      localStorage.getItem('rememberUser') || '{}'
    );

    if (rememberUser.save && rememberUser.email) {
      this.emailAddress = rememberUser.email;
      this.rememberMe = rememberUser.save;
    }
    setTimeout(
      (app: any) => {
        app.setBodyOverflow();
      },
      500,
      this
    );
  }

  private async navForgotPassword(): Promise<void> {
    if (screen.width <= 636) {
      this.mobileMsgText = this.$t(
        'pages.auth.forgot_pwd_mobile_msg'
      ).toString();
      this.showMobileMsg = true;
    } else {
      this.$router.push({ name: 'ForgotPassword' });
    }
  }

  private async navSignUp(): Promise<void> {
    if (screen.width > 991) {
      this.$router.push({ name: 'Signup' });
    }
  }

  private async hideMobileMsg(): Promise<void> {
    this.showMobileMsg = false;
  }

  private get signUptext(): string {
    return screen.width <= 991
      ? this.$t('pages.auth.sign_up_desktop').toString()
      : this.$t('pages.auth.sign_up_here').toString();
  }

  private updated(): void {
    this.$nextTick(() => {
      this.setBodyOverflow();
    });
  }

  private async beforeDestroy(): Promise<void> {
    const bodyContainer = document.body;
    bodyContainer.style.overflow = 'scroll';
  }

  private setBodyOverflow(): void {
    const bodyContainer = document.body;
    if (bodyContainer.clientWidth > 636) {
      bodyContainer.style.overflow = 'scroll';
    } else {
      bodyContainer.style.overflow = 'hidden';
    }
  }
}
