import { DelegateCompanyUserModel, DelegateUserDetailModel } from "./delegateCompanyUserModel";
import { OrderLineDisplayModel } from "./orderLineDisplayModel";

export class DelegateCompanyModel {
    public id!: string;
    public companyName!: string;
    public country!: string;
    public isCompliant!: boolean;
    public isTier1!: boolean;
    public onBoarded!: boolean;
    public invitedPersonEmailID!: string;
    public isAgent!: boolean;
    public companyEmail!: string;
    public firstName!: string;
    public lastName!: string;

    public constructor(id: string, companyName: string, country: string, isCompliant: boolean, onBoarded: boolean) {
        this.id = id;
        this.companyName = companyName;
        this.country = country;
        this.isCompliant = false;
        this.isTier1 = false;
        this.onBoarded = false;
        this.isAgent = false;
        this.invitedPersonEmailID = "";
        this.companyEmail = "";
        this.firstName = "";
        this.lastName =  ""
    }
}

export class OrderDelegateCompanyModel {
    public orderIdList!: string[];
    public delegateUserDetails!: DelegateUserDetailModel;
}

export class OrderCompanyRespModel {
    public success!: boolean;
    public orders!: OrderLineDisplayModel[];
}

export class OrderDelegatePartnerReqModel {
    public delegatedPartnerEmail!: string;
    public delegatedPartnerName!: string;
    public CPName!: string;
}