import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import { debounce } from 'ts-debounce';

//helper
import { NonRomanCharCheckHelper }from '@/helpers/nonRomanCharCheckHelper'

// Models
import { DropdownModel } from '@/models/dropdownModel';

@Component({
  directives: { ClickOutside },
})
export default class DropDownInput extends Vue {
  @Prop()
  private isReadonly!: boolean;

  @Prop()
  private disabled!: boolean;

  @Prop()
  private required!: boolean;

  @Prop({
    default() {
      return [];
    },
  })
  private dropdownList!: DropdownModel[];

  @Prop()
  private placeholder!: string;

  @Prop()
  private tabIndex!: string;

  @Prop({ default: '' })
  private defaultValue!: string;

  @Prop({ default: '' })
  private id!: string;

  @Prop()
  private isCerificationBody!: boolean;

  @Prop()
  private resetField!: boolean;

  public constructor() {
    super();
  }

  private fieldKey: string = '';
  private fieldValue: string = '';

  private inputError: boolean = false;
  private dropdownShow: boolean = false;
  private isInputClicked: boolean = false;

  private isNonRomanChar: boolean = false;

  private searchDebounced: any;

  private async created(): Promise<void> {
    this.searchDebounced = debounce(this.searchDropdown, 250, {
      isImmediate: true,
    });
  }

  private async mounted(): Promise<void> {
    if (this.defaultValue.length > 0) {
      this.fieldValue = this.defaultValue;
    }
  }

  public async clearValues(): Promise<void> {
    this.fieldKey = '';
    this.fieldValue = '';
  }

  private async checkValid(): Promise<boolean> {
    this.isNonRomanChar = await NonRomanCharCheckHelper.checkNonRomanChar(this.fieldValue);
    this.inputError = false;
    if (this.isReadonly && this.fieldKey.length == 0) {
      this.inputError = true;
    }

    if (!this.inputError) {
      if (this.fieldKey.length > 0) {
        this.fieldKey = this.fieldKey.trim();
      }
      if (this.fieldValue.length > 0) {
        this.fieldValue = this.fieldValue.trim();
      }
      this.emitValueSelected(this.fieldKey, this.fieldValue);
    }
    return this.inputError;
  }

  private vueClickedOutside(): void {
    this.dropdownShow = false;
  }

  private async hideDropdownShow(
    fieldKey: string,
    fieldValue: string
  ): Promise<void> {
    this.fieldKey = fieldKey;
    this.fieldValue = fieldValue;
    if (this.dropdownShow) {
      const lError = await this.checkValid();
    }
    this.dropdownShow = false;
  }

  private get filteredList(): DropdownModel[] {
    if (
      this.dropdownList == null ||
      this.dropdownList.length == undefined ||
      this.dropdownList.length === 0
    ) {
      return [];
    }

    if (this.isInputClicked) {
      return this.dropdownList.sort((a: DropdownModel, b: DropdownModel) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    }

    return this.dropdownList
      .filter((item) =>
        item.text.toLowerCase().includes(this.fieldValue.toLowerCase())
      )
      .sort((a: DropdownModel, b: DropdownModel) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
  }

  private scrollFocus: any = null;
  private async searchDropdown(event: any): Promise<void> {
    this.isNonRomanChar = await NonRomanCharCheckHelper.checkNonRomanChar(this.fieldValue.trim())
    if(this.isNonRomanChar){
      this.dropdownShow = false;
      return;
    }
    if (this.isInputClicked) {
      this.isInputClicked = false;
      const fieldValue = this.fieldValue.trim();
      this.emitValueSelected('', '');
      if (fieldValue.length <= 3) {
        this.fieldValue = fieldValue;
      }
    }
    const filteredList = this.filteredList;
    if (!this.dropdownList || this.dropdownList.length === 0) {
      return;
    }

    if (!this.dropdownShow && (event.keyCode == 38 || event.keyCode == 40)) {
      this.dropdownShow = true;
    }

    if (
      this.dropdownShow &&
      (event.keyCode == 38 || event.keyCode == 40 || event.keyCode == 13)
    ) {
      switch (event.keyCode) {
        case 38:
          if (this.scrollFocus === null) {
            this.scrollFocus = 0;
          } else if (this.scrollFocus > 0) {
            this.scrollFocus--;
          }
          break;
        case 40:
          if (this.scrollFocus === null) {
            this.scrollFocus = 0;
          } else if (this.scrollFocus < filteredList.length - 1) {
            this.scrollFocus++;
          }
          break;
        case 13:
          if (this.scrollFocus !== null) {
            this.fieldKey = filteredList[this.scrollFocus].value.toString();
            this.fieldValue = filteredList[this.scrollFocus].text.toString();
            this.dropdownShow = false;
          }
          this.checkValid();
          break;
      }
      if (this.scrollFocus !== null) {
        setTimeout(
          (app: any) => {
            const listItem = app.$refs['scrollFocus' + app.scrollFocus] as Vue;
            Object.entries(listItem).forEach((item) => {
              item[1].scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
          },
          150,
          this
        );
      }
      return;
    }
    if (filteredList.length > 0 && !this.isNonRomanChar) {
      this.dropdownShow = true;
      this.fieldKey = '';
    }
  }

  private emitValueSelected(fieldKey: string, fieldValue: string): void {
    this.fieldKey = fieldKey;
    this.fieldValue = fieldValue.trim();
    this.valueSelected({ fieldKey: fieldKey, fieldValue: fieldValue });
  }

  private async checkNonRoman (){
    this.isNonRomanChar = await NonRomanCharCheckHelper.checkNonRomanChar(this.fieldValue.trim())
    if(this.isNonRomanChar){
      this.dropdownShow = false;
    }
  }

  @Watch('defaultValue', { deep: true })
  private updateDefaultValue(): void {
    if (this.defaultValue && this.defaultValue.length > 0) {
      this.fieldValue = this.defaultValue;
    }
  }

  @Watch('resetField')
  private resetFieldValue(): void{
    if(this.resetField){
      this.fieldValue = '';
      this.inputError = false;
    }
  }

  @Emit()
  private valueSelected(fieldObject: {}): void {}
}
