export class Company {
  public constructor(
    public ID: string,
    public name: string,
    public country: string,
    public isTier1: boolean,
    public onBoarded: boolean
  ) {}
}

export enum CompanyType {
  CP = 'CP',
  DP = 'DP',
  CPDP = 'CDP',
  SCP = 'SCP'
}