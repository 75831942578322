import { SupplyChainStepClient } from '@/clients/supplyChainStepClient';
import { SupplyChainStepModel } from '@/models/supplyChainStepModel';

export class SupplyChainStepService {
  private readonly client: SupplyChainStepClient = new SupplyChainStepClient();

  public async getSupplyChainStepsAsync(): Promise<SupplyChainStepModel[]> {
    const result = await this.client.getSupplyChainStepsAsync();
    return result;
  }
}
