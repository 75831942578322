var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page order-evidence"},[(
      !_vm.orderLineEvidenceStepsLoading &&
      _vm.uploadEvidenceCount == 0 &&
      _vm.approveEvidenceCount == 0 &&
      _vm.reSubmitEvidenceCount == 0
    )?_c('div',{staticClass:"no-evidence"},[_vm._m(0)]):_vm._e(),(
      _vm.orderLineEvidenceStepsLoading ||
      _vm.uploadEvidenceCount > 0 ||
      _vm.approveEvidenceCount > 0 ||
      _vm.reSubmitEvidenceCount > 0
    )?_c('div',[_c('span',{staticClass:"evidence-main"},[_vm._v(_vm._s(_vm.$t('pages.order_evidence.title')))]),_c('div',{staticClass:"row mt-24"},[_c('div',{staticClass:"col-lg-8 col-md-12 col-sm-12 tile medium-large"},[(
            _vm.orderLineEvidenceStepsLoading ||
            _vm.uploadEvidenceCount > 0 ||
            _vm.approveEvidenceCount > 0 ||
            _vm.reSubmitEvidenceCount > 0
          )?_c('div',{staticClass:"content px-3 pb-0 pt-0"},[(_vm.orderLineEvidenceStepsLoading)?_c('div',[_c('b-skeleton',{staticClass:"my-4 pt-3",attrs:{"width":"80%"}}),_c('b-skeleton',{staticClass:"my-4 pt-3",attrs:{"width":"80%"}}),_c('b-skeleton',{staticClass:"my-4 pt-3",attrs:{"width":"80%"}})],1):_c('div',[(_vm.uploadEvidenceCount > 0)?_c('div',{staticClass:"d-flex align-items-center spacing",attrs:{"role":"button"},on:{"click":function($event){return _vm.navUploadEvidence()}}},[_vm._m(1),_c('div',{staticClass:"heading"},[(_vm.uploadEvidenceCount > 0)?_c('span',{staticClass:"count supplyChain px-2"},[_vm._v(_vm._s(_vm.uploadEvidenceCount))]):_vm._e()]),_c('h3',{staticClass:"ml-3 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('pages.order_evidence.upload_evidence'))+" ")]),(_vm.isLoadingUpload)?_c('b-spinner',{staticClass:"white",attrs:{"small":""}}):_vm._e()],1):_vm._e(),(_vm.approveEvidenceCount > 0)?_c('div',{class:[
                'd-flex align-items-center spacing',
                { 'border-top': _vm.uploadEvidenceCount > 0 },
              ],attrs:{"role":"button"},on:{"click":function($event){return _vm.navApproveRejectEvidence()}}},[_vm._m(2),_c('div',{staticClass:"heading"},[(_vm.approveEvidenceCount > 0)?_c('span',{staticClass:"count supplyChain px-2"},[_vm._v(_vm._s(_vm.approveEvidenceCount))]):_vm._e()]),_c('h3',{staticClass:"ml-3 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('pages.order_evidence.approve_reject_evidence'))+" ")]),(_vm.isLoadingApproveReject)?_c('b-spinner',{staticClass:"white",attrs:{"small":""}}):_vm._e()],1):_vm._e(),(_vm.reSubmitEvidenceCount > 0)?_c('div',{class:[
                'd-flex align-items-center spacing',
                {
                  'border-top':
                    _vm.approveEvidenceCount > 0 || _vm.uploadEvidenceCount > 0,
                },
              ],attrs:{"role":"button"},on:{"click":function($event){return _vm.navReSubmitEvidence()}}},[_vm._m(3),_c('div',{staticClass:"heading"},[(_vm.reSubmitEvidenceCount > 0)?_c('span',{staticClass:"count supplyChain px-2"},[_vm._v(_vm._s(_vm.reSubmitEvidenceCount))]):_vm._e()]),_c('h3',{staticClass:"ml-3 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('pages.order_evidence.re_submit_evidence'))+" ")]),(_vm.isLoadingResubmit)?_c('b-spinner',{staticClass:"white",attrs:{"small":""}}):_vm._e()],1):_vm._e()])]):_vm._e()])]),(_vm.showUploadEvidence)?_c('upload-evidence',{attrs:{"evidence-steps":_vm.steps},on:{"close-upload":_vm.closeUploadEvidence,"reload-evidence":_vm.reloadEvidence}}):_vm._e(),(_vm.showApproveRejectEvidence)?_c('approve-reject-evidence',{attrs:{"evidence-steps":_vm.steps},on:{"close-upload":_vm.closeApproveRejectEvidence,"reload-evidence":_vm.reloadEvidence}}):_vm._e(),(_vm.showResubmitEvidence)?_c('upload-evidence',{attrs:{"is-resubmit":"true","evidence-steps":_vm.steps},on:{"close-upload":_vm.closeResubmitEvidence,"reload-evidence":_vm.reloadEvidence}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content px-3 py-2"},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/icons/evidence/all-done.svg"),"alt":""}})]),_c('div',{staticClass:"well-done text-center pb-3"},[_vm._v("All done for today")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('img',{attrs:{"src":require("@/assets/icons/dashboard/upload-order-evidence.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('img',{attrs:{"src":require("@/assets/icons/dashboard/approve-order-evidence.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('img',{attrs:{"src":require("@/assets/icons/dashboard/re-submit-order-evidence.svg"),"alt":""}})])
}]

export { render, staticRenderFns }