import Vue from 'vue';

export class VueFilters {
  public static register(): void {
    Vue.filter('nullCheck', (value?: any) => {
      if (value == null || value === '') {
        return '-';
      }
      return value;
    });
  }
}
