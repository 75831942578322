import { OrderLineStepProof } from './orderLineStepProof';
import { OrderLineStepStatus } from './orderLineStepStatus';
import { OrderLineModel } from './orderLineModel';
import { ProductGroup } from './productGroup';
import { SupplyChainStepModel } from './supplyChainStepModel';
import { Guid } from './guid';
import { StepHistory } from './stepHistoryModal';
import { StepState } from './stepState';
import { OrderLineDisplayModel } from './orderLineDisplayModel';
import { v4 as uuidv4 } from 'uuid';

export class OrderLineStepParallelModel {
  public id!: string;
  public orderId!: string;
  public orderLineId!: string;
  public orderNumber!: string;
  public stepId!: number;
  public displayName!: string;
  public productGroup!: ProductGroup;
  public sequence!: number;
  public status!: OrderLineStepStatus;
  public fromCompanyId!: string;
  public toCompanyId!: string;
  public toCompanyName!: string;
  public evidencePhotos: OrderLineStepProof[] = [];
  public validSocialCertificates: number[] = [];
  public validSustainabilityCertificates: number[] = [];
  public showDetailsCount!: number;
  public showNewsLetter!: boolean;
  public showFactoryAddress!: boolean;
  public showDetails!: boolean;
  public showProfile!: boolean;
  public rejectionReason!: string;
  public compliantAtVerificationDate!: boolean;
  public currentlyValidCertificates!: null;
  public stepState!: StepState;
  public stepHistory!: StepHistory[];
  public isLastStep!: string;
  public parentStepId!: string;
  public isSelected?: boolean;
  public supplierStep!: boolean;
  // TTD-3619
  public footprintRefNo!: string;
  public footprintRefID!: string;
  public footprintRefNoUpdatedBy!: string;
  // Sprint 20, TTD-3940
  public footprintRefNoPrefix?: string;

  public constructor(
    orderLine: OrderLineModel,
    supplyChainStep: SupplyChainStepModel,
    parentStepId: string
  ) {
    this.id = Guid.Empty;
    this.parentStepId = parentStepId!;
    this.orderId = orderLine.orderId!;
    this.orderNumber = orderLine.orderNumber!;
    this.orderLineId = orderLine.lineID!;
    this.stepId = supplyChainStep.stepId;
    this.productGroup = supplyChainStep.productGroup;
    this.displayName = supplyChainStep.displayName;
    this.sequence = supplyChainStep.sequence;
    this.fromCompanyId = Guid.Empty;
    this.toCompanyId = Guid.Empty;
    this.toCompanyName = '';
    this.showDetails = false;
    this.showNewsLetter = false;
    this.showFactoryAddress = false;
    this.showProfile = false;
    this.showDetailsCount = 0;
    this.rejectionReason = 'null';
    this.stepState = StepState.NONE;
    this.stepHistory = [];
    // TTD-3619
    this.footprintRefNo = "";
    this.footprintRefID = Guid.Empty;
    this.footprintRefNoUpdatedBy = Guid.Empty;
  }
}

export class ParallelStepBlockchainModel {
  public ID!: string;
  public orderID!: string;
  public orderLineID!: string;
  public orderNumber!: string;
  public stepID!: number;
  public displayName!: string;
  public productGroup!: ProductGroup;
  public sequence!: number;
  public status!: OrderLineStepStatus;
  public fromCompanyID!: string;
  public toCompanyID!: string;
  public toCompanyName!: string;
  public evidencePhotos: OrderLineStepProof[] = [];
  public validSocialCertificates: number[] = [];
  public validSustainabilityCertificates: number[] = [];
  public showDetailsCount!: number;
  public showNewsLetter!: boolean;
  public showFactoryAddress!: boolean;
  public showDetails!: boolean;
  public showProfile!: boolean;
  public rejectionReason!: string;
  public compliantAtVerificationDate!: boolean;
  public currentlyValidCertificates!: null;
  public stepState!: StepState;
  public stepHistory!: StepHistory[];
  public isLastStep!: string;
  public parentStepId!: string;
  public isSelected?: boolean;
  public supplierStep!: boolean;
  public createdAt!: string;
  public createdBy!: string;
  public updatedAt!: string;
  public updatedBy!: string;
  // TTD-3619
  public footprintRefNo!: string;
  public footprintRefID!: string;
  public footprintRefNoUpdatedBy!: string;

  public constructor(
    step: OrderLineStepParallelModel,
    parentStepId: string,
    order: OrderLineDisplayModel
  ) {
    this.ID = step.id === Guid.Empty || step.id === "" ? uuidv4() : step.id;
    this.parentStepId = parentStepId;
    this.orderID = order.orderId;
    this.orderNumber = order.orderNumber;
    this.orderLineID = order.id;
    this.stepID = step.stepId;
    this.productGroup = step.productGroup;
    this.displayName = step.displayName;
    this.sequence = step.sequence;
    this.fromCompanyID = step.fromCompanyId;
    this.toCompanyID = step.toCompanyId;
    this.toCompanyName = step.toCompanyName;
    this.showDetails = step.showDetails;
    this.showNewsLetter = step.showNewsLetter;
    this.showFactoryAddress = step.showFactoryAddress;
    this.showProfile = step.showProfile;
    this.showDetailsCount = step.showDetailsCount;
    this.rejectionReason = step.rejectionReason;
    this.stepState = step.stepState;
    this.stepHistory = step.stepHistory;
    this.createdAt = '0001-01-01T00:00:00Z';
    this.createdBy = '';
    this.updatedAt = '0001-01-01T00:00:00Z';
    this.updatedBy = '';
    // TTD-3619
    this.footprintRefNo = step.footprintRefNo;
    this.footprintRefID = step.footprintRefID;
    this.footprintRefNoUpdatedBy = step.footprintRefNoUpdatedBy;
  }
}
