import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

// Components
import DocumentsToSign from '@/views/compliance/components/documentsToSign.vue';
import DocumentsSigned from '@/views/compliance/components/documentsSigned.vue';

// Models
import { ComplianceDocResponseModel } from '@/models/complianceDocResponseModel';

@Component({
  components: { DocumentsToSign, DocumentsSigned },
})
export default class ComplianceDocuments extends Vue {
  @Prop()
  private documents!: ComplianceDocResponseModel[];

  @Prop()
  private selectedTab!: number;
  
  @Prop()
  private searchFilter!: string;

  @Prop()
  private loadingDoc!: boolean;

  @Prop()
  private selectedClient!: string;

  public constructor() {
    super();
  }

  private get filteredDocuments(): ComplianceDocResponseModel[] {
    let documents: ComplianceDocResponseModel[] = [];
    if (this.selectedClient !== '') {
      documents = this.documents.filter(
        (d) => d.fromCompanyName === this.selectedClient
      );
    } else {
      documents = this.documents;
    }
    if (this.selectedTab === 1) {
      return documents.filter(
        (d) =>
          d.documentStatus !== 'DOCUMENT_ARCHIEVED'
      );
    }
    
    if (this.selectedTab === 2) {
      return documents.filter((d) => d.documentStatus === 'DOCUMENT_ARCHIEVED');
    }
    return [];
  }

  @Emit()
  private reloadDocuments(): void {}
}
