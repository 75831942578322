import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
// import * as html2pdf from "html2pdf.js";
import printHtmlToPDF from 'print-html-to-pdf';
import VuePdfApp from 'vue-pdf-app';
import lodash from "lodash";

// Components
import Watermark from '@/views/compliance/components/watermark.vue';

// Model
import { ComplianceDocResponseModel } from '@/models/complianceDocResponseModel';

// Services
import { SupplierService } from '@/services/supplierService';

import moment from 'moment';
import { componentsPlugin } from 'bootstrap-vue';
import { mapState } from 'vuex';
import { CompanyNameModel } from '@/models/companyModel';

@Component({
  components: { VuePdfApp, Watermark },
  computed: mapState(['companyNameList']),
})
export default class ViewDocumentsSigned extends Vue {
  @Prop()
  private documentDetail!: ComplianceDocResponseModel;

  private pdfDocument!: any;
  private isPdfReady: boolean = false;
  private config: any = {
    toolbar: false,
  };

  private pdfZoom: string = 'page-width';
  private maxPages: number = 0;
  private pdfWidth: number = 0;
  private pdfHeight: number = 0;
  private lDownloadPDF: boolean = false;
  private numPages: number = 0;
  private supplierService: SupplierService;
  private pdfBase64: string = '';
  private signedImgBase64: string | ArrayBuffer | null = '';
  private isDownloading: boolean = false;
  private companyNameList!: CompanyNameModel[];

  public constructor() {
    super();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {}

  private get brands(): string {
    if (this.documentDetail.brands && this.documentDetail.brands.length > 0) {
      const uniqBrands = lodash.uniq(this.documentDetail.brands);
      return uniqBrands.join(', ');
    }
    return '';
  }

  private get formattedDate(): string {
    return moment(this.documentDetail.scpSignedDate).format('D MMM YYYY');
  }

  private formatteddate(): string {
    return moment(this.documentDetail.scpSignedDate).format('D MMMM YYYY');
  }

  private formatDate(dateToBeFormatted: string): string {
    if (dateToBeFormatted==="9999-01-01T00:00:00Z") {
      return "No deadline";
    }
    return moment(dateToBeFormatted).format('D MMMM YYYY');
  }

  private get fullname(): string {
    return this.documentDetail.toCompanyContactName
      ? this.documentDetail.toCompanyContactName
      : '';
  }

  private fullName(): string {
    return this.documentDetail.toCompanyContactName
      ? this.documentDetail.toCompanyContactName
      : '';
  }

  private async downloadPDF(): Promise<void> {
    // this.isPdfReady = false;
    this.lDownloadPDF = true;

    if (this.pdfDocument.pdfViewer.currentScaleValue !== 'page-width') {
      this.pdfDocument.pdfViewer.currentScaleValue = 'page-width';
    } else {
      await this.downloadWatermarkedPDF();
    }
  }

  private async downloadWatermarkedPDF(): Promise<void> {
    this.isDownloading = true;
    const app = this;
    // app.isPdfReady = false;
    let downloadName = `${app.documentDetail.documentName
      .charAt(0)
      .toUpperCase()}${app.documentDetail.documentName.slice(1).toLowerCase()}`;
    downloadName = downloadName.replace('.pdf', '');
    downloadName += ` signed by ${app.documentDetail.toCompanyName} ${app.formattedDate}.pdf`;

    await fetch(app.documentDetail.docURL)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((data) => {
        this.pdfBase64 = this._arrayBufferToBase64(data);
      });
    // For converting svg to base64
    const svg = document.querySelector('svg');
    if (svg) {
      const svgStr = new XMLSerializer().serializeToString(svg);
      const encodedData = window.btoa(unescape(encodeURIComponent(svgStr)));
      this.signedImgBase64 = encodedData;
    }
    const data = {
      SignedBy: this.fullName(),
      SignedOn: this.formatteddate(),
      DocName: downloadName,
      Base64PDFDoc: this.pdfBase64,
      StampPNG: this.signedImgBase64,
    };
    const result = await this.supplierService.downloadSignedPdf(data);
    const linkSource = `data:application/pdf;base64,${result.signedPdfResponse}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = downloadName;
    downloadLink.click();
    this.isDownloading = false;
    app.isPdfReady = true;
  }

  private _arrayBufferToBase64(buffer: ArrayBuffer): string {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private async updateWatermark(): Promise<void> {
    if(this.documentDetail.signatureRequested !== "false"){
      const app: any = this;
      app.isPdfReady = false;

      setTimeout(
        (app: any) => {
          app.pdfWidth = parseFloat(
            app.$refs.vuepdfapp.$el.querySelector(
              '[class="page"][data-page-number="' + app.maxPages + '"] '
            ).style.width
          );
          app.pdfHeight = parseFloat(
            app.$refs.vuepdfapp.$el.querySelector(
              '[class="page"][data-page-number="' + app.maxPages + '"] '
            ).style.height
          );

          app.$refs.vuepdfapp.$el.querySelector(
            '[class="page"][data-page-number="' +
              app.maxPages +
              '"] > [class="textLayer"] '
          ).style.opacity = 1;
          app.$refs.vuepdfapp.$el
            .querySelector(
              '[class="page"][data-page-number="' +
                app.maxPages +
                '"] > [class="textLayer"] > [class="endOfContent"]'
            )
            .insertAdjacentHTML(
              'afterend',
              `<div id="watermark-${app.maxPages}"></div>`
            );

          new Watermark({
            propsData: {
              width: app.pdfWidth * 0.3,
              height: app.pdfHeight * 0.305,
              signdate: app.formattedDate,
              signedontext: 'Signed on',
              fullname: app.fullname,
            },
          }).$mount(`#watermark-${app.maxPages}`);

          const lenWatermarkSignon: number =
            app.$refs.vuepdfapp.$el.querySelector('[id="watermark-signon"]')
              .innerHTML.length;
          const lenWatermarkSigndate: number =
            app.$refs.vuepdfapp.$el.querySelector('[id="watermark-signdate"]')
              .innerHTML.length;
          const padWatermarkSignon: number =
            (lenWatermarkSigndate - lenWatermarkSignon) / 2;

          const posWatermarkSignon = parseFloat(
            app.$refs.vuepdfapp.$el
              .querySelector('[id="watermark-signon"]')
              .getAttribute('x')
          );
          app.$refs.vuepdfapp.$el
            .querySelector('[id="watermark-signon"]')
            .setAttribute('x', posWatermarkSignon + 35);

          const posWatermarkSigndate = parseFloat(
            app.$refs.vuepdfapp.$el
              .querySelector('[id="watermark-signdate"]')
              .getAttribute('x')
          );
          app.$refs.vuepdfapp.$el
            .querySelector('[id="watermark-signdate"]')
            .setAttribute(
              'x',
              posWatermarkSigndate + (35 - padWatermarkSignon * 5)
            );

          const lenWatermarkFirstname: number =
            app.$refs.vuepdfapp.$el.querySelector('[id="watermark-firstname"]')
              .innerHTML.length;
          const posWatermarkFirstname = parseFloat(
            app.$refs.vuepdfapp.$el
              .querySelector('[id="watermark-firstname"]')
              .getAttribute('x')
          );
          if (lenWatermarkFirstname <= 20) {
            app.$refs.vuepdfapp.$el
              .querySelector('[id="watermark-firstname"]')
              .setAttribute(
                'x',
                posWatermarkFirstname + (35 - lenWatermarkFirstname)
              );
          }

          const lenWatermarkLastname: number =
            app.$refs.vuepdfapp.$el.querySelector('[id="watermark-lastname"]')
              .innerHTML.length;
          const posWatermarkLastname = parseFloat(
            app.$refs.vuepdfapp.$el
              .querySelector('[id="watermark-lastname"]')
              .getAttribute('x')
          );
          if (lenWatermarkLastname <= 20) {
            app.$refs.vuepdfapp.$el
              .querySelector('[id="watermark-lastname"]')
              .setAttribute(
                'x',
                posWatermarkLastname + (35 - lenWatermarkLastname)
              );
          }

          if (lenWatermarkLastname == 0) {
            app.$refs.vuepdfapp.$el
              .querySelector('[id="watermark-firstname"]')
              .setAttribute(
                'x',
                posWatermarkFirstname + (35 - lenWatermarkFirstname)
              );
          }

          if (app.lDownloadPDF) {
            setTimeout(
              (appInner: any) => {
                appInner.downloadWatermarkedPDF();
              },
              100,
              app
            );
          }
          app.isPdfReady = true;
        },
        100 * app.maxPages,
        app
      );
    }
  }

  private async pdfPagesRendered(pdf: any): Promise<void> {
    setTimeout(() => (pdf.pdfViewer.currentScaleValue = 'page-width'));
    setTimeout(
      (appInner: any) => {
        appInner.updateWatermark();
      },
      200 * pdf.pdfDocument.numPages,
      this
    );
  }

  private async pdfReady(pdfApp: any): Promise<void> {
    const app = this;
    await pdfApp.pdfViewer.pagesPromise;
    app.pdfDocument = pdfApp;
    app.pdfDocument.pdfViewer.currentScaleValue = app.pdfZoom;
    app.maxPages = await app.pdfDocument.pdfDocument.numPages;

    app.pdfDocument.pdfViewer.eventBus.on(
      'pagerendered',
      function (event: any) {
        if (app.pdfZoom !== app.pdfDocument.pdfViewer.currentScaleValue) {
          app.pdfZoom = app.pdfDocument.pdfViewer.currentScaleValue;
          app.updateWatermark();
        }
      }
    );

    for (let i = 0; i < app.maxPages; i++) {
      setTimeout(
        (appInner: any) => {
          app.pdfDocument.page = i + 1;

          if (i === app.maxPages - 1) {
            appInner.$refs.vuepdfappContainer.focus();
          }
        },
        250 * (i + 1),
        app
      );
    }
  }

  private partnerName(partnerId: string, currentName: string): string {
    return this.companyNameList.filter((c) => c.ID === partnerId).length > 0 ? this.companyNameList.filter((c) => c.ID === partnerId)[0].name : currentName;
  }

  @Emit()
  private closePdf(success: boolean): void {}
}
