import { Vue, Component, Watch } from 'vue-property-decorator';
import store from '@/store';
import moment from 'moment';
import i18n from '@/i18n';
import { ActionTypes } from '@/store/actions/actions';
import { mapGetters } from 'vuex';
import lodash from 'lodash';

// Services
import { OrderService } from '@/services/orderService';

// Components
import OrderLine from '@/views/orderLines/components/orderLine.vue';
import OrderLineLoader from '@/views/orderLines/components/orderLineLoader.vue';
import { NonRomanCharCheckHelper } from '@/helpers/nonRomanCharCheckHelper';
// TTD-3617 development
import PendingOrderLinesGroup from '@/views/orderLines/components/pendingOrderLinesGroup.vue';

// Models
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { ProductGroup } from '@/models/productGroup';
import { DropdownModel } from '@/models/dropdownModel';
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { OrderCompanyModel } from "@/models/orderCompanyModel";

@Component({
  components: { OrderLine, OrderLineLoader, PendingOrderLinesGroup },
  computed: {
    ...mapGetters(['isOrderLoading', 'orders', 'orderLineSteps', 'pendingOrders']),
  },
})
export default class OrderLines extends Vue {
  private orderService: OrderService;

  private orders!: OrderLineDisplayModel[];

  private orderLineSteps!: OrderLineStepDisplayModel[];

  private isOrderLoading!: boolean;

  private searchFilter: string = '';

  private loadingAll: boolean = false;

  private selectedBrand: string = '';

  private selectedProductGroup: ProductGroup = ProductGroup.None;

  private dateFrom: string | Date = '';

  private dateTo: string | Date = '';

  private today: Date = new Date();
  
  private isNonRomanChar: boolean = false;

  //variabels added for TTD-3575 user story
  private tabIndex: number = 0;

  private tabCountPending: number = 0;

  private tabCountInprogress: number = 0;

  private tabCountCompleted: number = 0;

  private selectedTabName: string = "pending";

  private selectedSortOption: string = '';

  private isSortAtoZ: boolean = false;

  private isSortZtoA: boolean = false;

  private sortType: string = 'asc';

  private isOpen: boolean = false;

  private orderLines: OrderLineDisplayModel[] = [];

  private totalPending: number = 0;

  private latestOrders: OrderLineDisplayModel[] = [];

  private pendingOrders!: OrderLineDisplayModel[];

  private showClearFilter: boolean = false;

  private isShowLoadMore: boolean = true;

  private pageSize:number = 50;

  private currentPage: number = 0;
  //variables added for TTD-3575 user story

  public constructor() {
    super();
    this.orderService = new OrderService();
  }

  private async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // TTD-3617 development,
    this.selectATab(0);
    if (this.orders.length === 0 && this.pendingOrders.length === 0 && this.isOrderLoading === false) {
      await this.getOrders();
    }
    //TTD-3575 display orderlines in respective tabs
    this.orderLinesData('');
  }

  //TTD-3575 navigation tabs logic added
  private selectATab(index: number): void {
    this.tabIndex = index;
  switch(index){
    case 1:
      this.selectedTabName = "inprogress";
      break;
    case 2:
      this.selectedTabName = "completed";
      break;
    default:
      this.selectedTabName = "pending";
      break;
  }
  this.orderLinesData('');
}

  private async getOrders(): Promise<void> {
    this.$store.dispatch(ActionTypes.SET_ORDERLINE);
    //TTD-3575 showing count of respective tabs
    this.tabCountPending = this.orders.filter(o => (o.orderLinePhase === 0 && o.orderLineStatus === 0)).length;
    this.totalPending = this.tabCountPending;

    this.tabCountInprogress = this.orders.filter(o => (o.orderLinePhase === 1 && (o.orderLineStatus >= 1 && o.orderLineStatus <= 6))).length;
    this.tabCountCompleted = this.orders.filter(o => (o.orderLinePhase === 2 && o.orderLineStatus !== 7)).length;
  }

  private get openDate(): Date {
    return this.today;
  }

  private get showNoFilterResults(): boolean {
    return (
      !this.isOrderLoading &&
      this.orders.length > 0 &&
      this.orders.every((o) => !o.show)
    );
  }

  private get showNoResults(): boolean {
    return !this.isOrderLoading && this.orders.length === 0;
  }

  private get locale(): string {
    if (i18n.availableLocales.some((l) => l === i18n.locale)) {
      return i18n.locale;
    }
    return i18n.fallbackLocale as string;
  }

  private get dateFromFilter(): moment.Moment {
    return moment(this.dateFrom).startOf('day');
  }

  private get dateToFilter(): moment.Moment {
    return moment(this.dateTo).endOf('day');
  }

  private get brands(): DropdownModel[] {
    const options: DropdownModel[] = [
      { value: '', text: this.$t('pages.order_lines.all_brands').toString() },
    ];
    this.orders.forEach((line) => {
      if (
        options.findIndex((option) => option.value === line.brandName) === -1
      ) {
        options.push(new DropdownModel(line.brandName, line.brandName));
      }
    });

    return options;
  }

  private get productGroups(): DropdownModel[] {
    const options: DropdownModel[] = [
      {
        value: 0,
        text: this.$t('pages.order_lines.all_productgroups').toString(),
      },
    ];
    this.orders.forEach((line) => {
      if (
        options.findIndex((option) => option.value === line.productGroup) === -1
      ) {
        options.push(
          new DropdownModel(
            line.productGroup,
            this.$t(`enums.product_group.${line.productGroup}`)
              .toString()
              .split(/(?=[A-Z])/)
              .join(' ')
          )
        );
      }
    });

    return options;
  }

  private orderLinesData(val:string): void {
    //TTD-3575 get orderlines method change to orderLinesData
    this.showClearFilter = false;
    let orders: OrderLineDisplayModel[] = [];
    this.tabCountInprogress = this.orders.filter(o => (o.orderLinePhase === 1 && (o.orderLineStatus >= 1 && o.orderLineStatus <= 6))).length
    this.tabCountCompleted = this.orders.filter(o => (o.orderLinePhase === 2 && o.orderLineStatus !== 7)).length;
    if (this.selectedTabName !== "pending") {
      // this.orders.forEach((o) => (o.show = true));
      switch (this.selectedTabName){
        case 'inprogress':
          orders = this.orders.filter(o => (o.orderLinePhase === 1 && (o.orderLineStatus >= 1 && o.orderLineStatus <= 6)));
          break;
        case 'completed':
          orders = this.orders.filter(o => (o.orderLinePhase === 2 && o.orderLineStatus !== 7));
          break;
      }
    } else {
      orders = lodash.cloneDeep(this.pendingOrders);
    }
    if (this.searchFilter !== '' && !this.isNonRomanChar) {
      this.showClearFilter = true;
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      orders = orders.filter(
        (o) =>
          o.orderNumber.toLowerCase().includes(lowerCaseFilter) ||
          (o.styleName != null &&
            o.styleName.toLowerCase().includes(lowerCaseFilter)) ||
          (o.styleNumber != null &&
            o.styleNumber.toLowerCase().includes(lowerCaseFilter) || 
            o.brandName != null && o.brandName.toLowerCase().includes(lowerCaseFilter))
      );
    }
    if (this.selectedBrand !== '') {
      this.showClearFilter = true;
      orders = orders.filter((o) => o.brandName === this.selectedBrand);
    }
    if (this.selectedProductGroup !== ProductGroup.None) {
      this.showClearFilter = true;
      orders = orders.filter(
        (o) => o.productGroup === this.selectedProductGroup
      );
    }
    if (this.dateFrom !== '') {
      this.showClearFilter = true;
      orders = orders.filter((o) =>
        o.expectedTimeOfDelivery.isSameOrAfter(this.dateFromFilter)
      );
    }
    if (this.dateTo !== '') {
      this.showClearFilter = true;
      orders = orders.filter((o) =>
        o.expectedTimeOfDelivery.isSameOrBefore(this.dateToFilter)
      );
    }

    // const hideOrders = this.orders.filter((order) => orders.indexOf(order) < 0);
    // hideOrders.forEach((order) => {
    //   const orderToHide = this.orders.filter((o) => o.id === order.id)[0];
    //   orderToHide.show = false;
    // });

    let finalOrders: OrderLineDisplayModel[] = [];
    // TTD-3617 development, added condition
    if (this.selectedTabName === "pending") {
      finalOrders = orders;
    } else {
      finalOrders = orders.filter(o => (o.orderLines[0].orderLinePhase >= 1 && o.orderLines[0].orderLineStatus > 0));
      // orders.forEach((o) => {
      //   if (o.orderLinePhase === 1 && o.orderLineStatus === 0) {
      //     return;
      //   } else {
      //     finalOrders.push(o);
      //   }
      // });
    }

    this.latestOrders = [];
    this.latestOrders = finalOrders.filter(o => o.orderLineStatus !== 7);
    switch (this.selectedTabName){
      case 'inprogress':
        this.tabCountInprogress = orders.length;
        break;
      case 'completed':
        this.tabCountCompleted = orders.length;
        break;
      default://pending
        this.tabCountPending = orders.length;
        break;
    }
    //TTD-3575 showing orders default and show more logic
    const sortOrders = [...this.latestOrders]
      this.latestOrders = sortOrders.sort((a,b) => this.compareCreatedDateStrings(a.createdAt, b.createdAt));
    if(this.selectedTabName ==='pending'){
      this.orderLines = this.latestOrders;
    }else if(val!== 'loadMore' && this.selectedTabName !=='pending'){
      this.orderLines =  this.latestOrders.slice(0, this.pageSize);
    }else{
      const nextPageStart = (this.currentPage + 1) * this.pageSize;
      const nextPageEnd = nextPageStart + this.pageSize;
      this.orderLines = this.latestOrders.slice(0, nextPageEnd);
      this.currentPage++;
    }
    if(this.tabCountCompleted === this.orderLines.length || this.tabCountInprogress === this.orderLines.length ||
      this.orderLines.length < this.pageSize || (this.showClearFilter && this.orderLines.length === this.latestOrders.length)){
      this.isShowLoadMore = false;
    }else{
      this.isShowLoadMore = true;
    }
    if(this.showClearFilter){
      this.isOpen = false;
      this.selectedSortOption = '';
      this.isSortAtoZ = false;
      this.isSortZtoA = false;
    }
  }

  private async checkNonRomanChar(val:string): Promise<void> {
    this.isNonRomanChar = await NonRomanCharCheckHelper.checkNonRomanChar(val)
  }
  //TTD-3575 logic of toggling orders
  private toggleDropdown() {
    this.isOpen = !this.isOpen;
  }
//TTD-3575 sorting functionality
  private sortedList(sort:string, val: string) {
    if(val!==''){
        this.selectedSortOption = val;
        this.sortType = sort;
        if(this.selectedSortOption === 'date'){
          this.sortByDate();
        }else if(this.selectedSortOption === 'colourWay'){
          if(sort === 'asc'){
            this.isSortAtoZ = true;
            this.isSortZtoA = false;
            const sortedOrderLines = [...this.latestOrders];
            this.latestOrders = sortedOrderLines.slice().sort(this.sortByColour);
          }else{
            this.isSortZtoA = true;
            this.isSortAtoZ = false;
            const sortedOrderLines = [...this.latestOrders]
            this.latestOrders = sortedOrderLines.slice().sort((a, b) => this.sortByColour(b, a));
          }
      }else if(this.selectedSortOption === 'orderNumber' || this.selectedSortOption === 'fromCompanyName'){
        const sortedOrderLines = [...this.latestOrders];
        this.latestOrders = sortedOrderLines.slice().sort((a, b) => {
          if (this.selectedSortOption) {
              if (sort == 'asc') {
                  this.isSortAtoZ = true;
                  this.isSortZtoA = false;
                  
                  const optionA = (a as any)[this.selectedSortOption];
                  const optionB = (b as any)[this.selectedSortOption];
                  
                  if (optionA == null || optionA.trim() === undefined)
                      return 1;
                  if (optionB == null || optionB.trim() === undefined)
                      return -1;
                  
                  if (!isNaN(+optionA.trim()) && !isNaN(+optionB.trim())) {
                      return +optionA.trim() - +optionB.trim();
                  }
                  
                  return optionA.trim().localeCompare(optionB.trim());
              } else {
                  this.isSortZtoA = true;
                  this.isSortAtoZ = false;
                  
                  const optionA = (a as any)[this.selectedSortOption];
                  const optionB = (b as any)[this.selectedSortOption];
                  
                  if (optionA == null || optionA.trim() === undefined)
                      return -1;
                  if (optionB == null || optionB.trim() === undefined)
                      return 1;
                  
                  if (!isNaN(+optionA.trim()) && !isNaN(+optionB.trim())) {
                      return +optionB.trim() - +optionA.trim();
                  }
                  
                  return optionB.trim().localeCompare(optionA.trim());
              }
          }
          return 0;
      });
      }else{
        const sortedOrderLines = [...this.latestOrders];
        this.latestOrders = sortedOrderLines.slice().sort((a, b) => {
          if (this.selectedSortOption) {
              if (sort == 'asc') {
                  this.isSortAtoZ = true;
                  this.isSortZtoA = false;
                  
                  const optionA = (a.orderLines[0] as any)[this.selectedSortOption];
                  const optionB = (b.orderLines[0] as any)[this.selectedSortOption];
                  
                  if (optionA == null || optionA.trim() === undefined)
                      return 1;
                  if (optionB == null || optionB.trim() === undefined)
                      return -1;
                  
                  if (!isNaN(+optionA.trim()) && !isNaN(+optionB.trim())) {
                      return +optionA.trim() - +optionB.trim();
                  }
                  
                  return optionA.trim().localeCompare(optionB.trim());
              } else {
                  this.isSortZtoA = true;
                  this.isSortAtoZ = false;
                  
                  const optionA = (a.orderLines[0] as any)[this.selectedSortOption];
                  const optionB = (b.orderLines[0] as any)[this.selectedSortOption];
                  
                  if (optionA == null || optionA.trim() === undefined)
                      return -1;
                  if (optionB == null || optionB.trim() === undefined)
                      return 1;
                  
                  if (!isNaN(+optionA.trim()) && !isNaN(+optionB.trim())) {
                      return +optionB.trim() - +optionA.trim();
                  }
                  
                  return optionB.trim().localeCompare(optionA.trim());
              }
          }
          return 0;
      });
      }
      // this.orderLines =  this.latestOrders.slice(0, this.orderLines.length);
      this.orderLines = [];
      this.orderLines = this.latestOrders;
    }
  }
//TTD-3575 sorting functionality with colour
  private sortByColour(a: any, b: any) {
    const colorA = a.orderLines[0]?.colourway?.toString() || "";
    const colorB = b.orderLines[0]?.colourway?.toString() || "";

    if (colorA === undefined) return 1;
    if (colorB === undefined) return -1;

    if (typeof colorA === "number" && typeof colorB === "number") {
      return colorA - colorB;
    }

    return colorA.localeCompare(colorB);
  }
//TTD-3575 sorting functionality with dates
  private sortByDate() {
    if(this.sortType === 'asc'){
        this.isSortAtoZ = true;
        this.isSortZtoA = false;
        const sortedOrderLines = [...this.latestOrders];
        this.latestOrders = sortedOrderLines.sort((a: OrderLineDisplayModel, b: OrderLineDisplayModel) => {
        if (moment(a.expectedTimeOfDelivery).isBefore(b.expectedTimeOfDelivery)) {
          return -1;
        } else if (moment(a.expectedTimeOfDelivery).isAfter(b.expectedTimeOfDelivery)) {
          return 1;
        }
        return 0;
      });
    }else{
        this.isSortZtoA = true;
        this.isSortAtoZ = false;
        this.latestOrders = this.latestOrders.sort((a: OrderLineDisplayModel, b: OrderLineDisplayModel) => {
        if (moment(b.expectedTimeOfDelivery).isBefore(a.expectedTimeOfDelivery)) {
          return -1;
        } else if (moment(b.expectedTimeOfDelivery).isAfter(a.expectedTimeOfDelivery)) {
          return 1;
        }
        return 0;
      });
    }
  }
//TTD-3575 sorting functionality clearfilter funtonality
  private async clearAllFilters(): Promise<void> {
    this.searchFilter = "";
    this.selectedBrand = "";
    this.selectedProductGroup = ProductGroup.None;
    this.dateFrom = "";
    this.dateTo = "";
    this.showClearFilter = false;
    this.orderLinesData('');
  }
//TTD-3575 sorting functionality show more orders logic
  private loadMore(value:string) {
    this.orderLinesData(value);
    this.sortedList(this.sortType,this.selectedSortOption);
  }
//TTD-3575 sorting default orders with dates
  private compareCreatedDateStrings(dateString1:moment.Moment, dateString2: moment.Moment): number {
    const date1 = moment(dateString1);
    const date2 = moment(dateString2);
    return date1.isAfter(date2) ? -1 : 1;
  }

  private reloadOrders(): void {
    store.commit('setConfirmOrderLine', true);
    this.getOrders();
    this.orderLinesData('');
  }
  //TTD-3575 call the orderLinesData when ordersupdated
  @Watch('orders')
    private callOrderLines(){
        this.orderLinesData('');
    }
  //TTD-3617 call the pending orderLinesData when ordersupdated
  @Watch('pendingOrders')
    private callPendingOrderLines(){
        this.orderLinesData('');
    }
  //TTD-3575 assing empty value for sorting while clear the filters
  @Watch('showClearFilter')
    private defaultSortOrders(){
      if(this.showClearFilter){
        this.selectedSortOption = '';
      }
    }
}
