import { Guid } from './guid';
import { OrderLineModel } from './orderLineModel';
import { OrderLineStepProof } from './orderLineStepProof';
import { OrderLineStepStatus } from './orderLineStepStatus';
import { StepHistory } from './stepHistoryModal';
import { StepState } from './stepState';
import { SupplyChainStepModel } from './supplyChainStepModel';
import { OrderLineStepParallelModel } from './orderLineStepParallelModel';

export class OrderLineStepModel {
  public constructor(
    orderLine: OrderLineModel,
    supplyChainStep: SupplyChainStepModel
  ) {
    this.id = Guid.Empty;
    this.orderId = orderLine.orderId!;
    this.orderNumber = orderLine.orderNumber!;
    this.orderLineId = orderLine.lineID;
    this.stepId = supplyChainStep.stepId;
    this.productGroup = supplyChainStep.productGroup;
    this.displayName = supplyChainStep.displayName;
    this.sequence = supplyChainStep.sequence;
    this.toCompanyId = Guid.Empty;
    this.toCompanyName = '';
    this.fromCompanyId = orderLine.CompanyId;
    this.showDetails = false;
    this.showNewsLetter = false;
    this.showFactoryAddress = false;
    this.showProfile = false;
    this.showDetailsCount = 0;
    this.rejectionReason = 'null';
    this.stepState = StepState.NONE;
    this.stepHistory = [];
    this.supplierStep = false;
    this.isLastStep = '';
    this.status = OrderLineStepStatus.Pending;
    this.clientID = orderLine.CompanyId;
    this.cpSupplierID = orderLine.toCompanyId;
    this.approvalRequestedTo = '';
    this.agentID = "";
    this.delegatedCompanyID = "";
    this.parallelSupplyChain0 = [];
    this.parallelSupplyChain1 = [];
    this.parallelSupplyChain2 = [];
    this.parallelSupplyChain3 = [];
    this.parallelSupplyChain4 = [];
    // TTD-3619
    this.footprintRefNo = "";
    this.footprintRefID = Guid.Empty;
    this.footprintRefNoUpdatedBy = Guid.Empty;
  }

  public id!: string;
  public orderId!: string;
  public orderLineId!: string;
  public orderNumber!: string;
  public productGroup!: number;
  public stepId!: number;
  public displayName!: string;
  public sequence!: number;
  public toCompanyId!: string;
  public status!: OrderLineStepStatus;
  public fromCompanyId!: string;
  public toCompanyName!: string;
  public evidencePhotos: OrderLineStepProof[] = [];
  public validSocialCertificates: number[] = [];
  public validSustainabilityCertificates: number[] = [];
  public showDetailsCount!: number;
  public showNewsLetter!: boolean;
  public showFactoryAddress!: boolean;
  public showDetails!: boolean;
  public showProfile!: boolean;
  public rejectionReason!: string;
  public compliantAtVerificationDate!: boolean;
  public currentlyValidCertificates!: null;
  public stepState!: StepState;
  public stepHistory!: StepHistory[];
  public supplierStep!: boolean;
  public isLastStep!: string;
  public parallelChainCount!: number;
  public isSelected?: boolean;
  public clientID!: string;
  public cpSupplierID!: string;
  public approvalRequestedTo!: string;
  //TTD-3591, new keys added
  public agentID?: string;
  public delegatedCompanyID?: string;
  //TTD-3591, new keys added
  public parallelSupplyChain0!: OrderLineStepParallelModel[];
  public parallelSupplyChain1!: OrderLineStepParallelModel[];
  public parallelSupplyChain2!: OrderLineStepParallelModel[];
  public parallelSupplyChain3!: OrderLineStepParallelModel[];
  public parallelSupplyChain4!: OrderLineStepParallelModel[];
  // TTD-3619
  public footprintRefNo!: string;
  public footprintRefID!: string;
  public footprintRefNoUpdatedBy!: string;
}

export class StepReferenceModel {
  public toCompanyId!: string;
  public toCompanyName!: string;
  public mcStepUniqueId!: string;
  public pcStepUniqueId!: string;
  public refNo!: string;
  public mcSequence!: number;
  public pcOfMC!: number;
  public pcSequence!: number;
  public stepId!: number;
  public footprintRefNoPrefix?: string;
}

export class FootprintStepsModel {
  public id!: string;
  public stepId!: number;
  public sequence!: number;
  public toCompanyId!: string;
  public toCompanyName!: string;
  public footprintRefNo!: string;
  public footprintRefNoPrefix?: string;
  public mc!: boolean;
  public pc!: boolean;

  public constructor(id:string, toCompanyName:string, toCompanyId:string, stepId:number, refNum:string, sequence:number, prefixRef:string, mc:boolean, pc:boolean) {
      this.id = id;
      this.stepId = stepId;
      this.sequence = sequence;
      this.toCompanyId = toCompanyId;
      this.toCompanyName = toCompanyName;
      this.footprintRefNo = refNum;
      this.footprintRefNoPrefix = prefixRef;
      this.mc = mc;
      this.pc = pc
  }
}