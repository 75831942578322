import { ParallelStepBlockchainModel } from './orderLineStepParallelModel';
import { Guid } from './guid';
import { OrderLineStepProof } from './orderLineStepProof';
import { OrderLineStepStatus } from './orderLineStepStatus';
import { StepHistoryBlockchainModel } from './stepHistoryModal';
import { StepState } from './stepState';

export class ConfirmStepBlockchainModel {
  public constructor() {
    this.ID = Guid.Empty;
    this.orderID = Guid.Empty;
    this.orderNumber = '';
    this.orderLineID = Guid.Empty;
    this.stepID = 0;
    this.productGroup = 0;
    this.displayName = '';
    this.sequence = 0;
    this.toCompanyID = Guid.Empty;
    this.toCompanyName = '';
    this.fromCompanyID = Guid.Empty;
    this.showDetails = false;
    this.showNewsLetter = false;
    this.showFactoryAddress = false;
    this.showProfile = false;
    this.showDetailsCount = 0;
    this.rejectionReason = 'null';
    this.stepState = 0;
    this.stepHistory = [];
    this.isLastStep = '';
    this.brandName = "";
    this.parallelSupplyChain0 = [];
    this.parallelSupplyChain1 = [];
    this.parallelSupplyChain2 = [];
    this.parallelSupplyChain3 = [];
    this.parallelSupplyChain4 = [];
    //TTD-3619
    this.footprintRefNo = "";
    this.footprintRefID = Guid.Empty;
    this.footprintRefNoUpdatedBy = Guid.Empty;
  }

  public ID!: string;
  public orderID!: string;
  public orderLineID!: string;
  public orderNumber!: string;
  public productGroup!: number;
  public stepID!: number;
  public displayName!: string;
  public sequence!: number;
  public toCompanyID!: string;
  public status!: OrderLineStepStatus;
  public fromCompanyID!: string;
  public toCompanyName!: string;
  public evidencePhotos: OrderLineStepProof[] = [];
  public validSocialCertificates: number[] = [];
  public validSustainabilityCertificates: number[] = [];
  public showDetailsCount!: number;
  public showNewsLetter!: boolean;
  public showFactoryAddress!: boolean;
  public showDetails!: boolean;
  public showProfile!: boolean;
  public rejectionReason!: string;
  public compliantAtVerificationDate!: boolean;
  public currentlyValidCertificates!: null;
  public stepState!: StepState;
  public stepHistory!: StepHistoryBlockchainModel[];
  public supplierStep!: boolean;
  public isLastStep!: string;
  public parallelChainCount!: number;
  public isSelected?: boolean;
  public clientID!: string;
  public cpSupplierID!: string;
  public approvalRequestedTo!: string;
  //TTD-3591, new keys added
  public agentID?: string;
  public delegatedCompanyID?: string;
  //TTD-3591, new keys added
  public createdAt!: string;
  public createdBy!: string;
  public referenceNo!: string;
  //TTD-3794, orderRefId & brandName field added
  public brandName?: string;
  //TTD-3794, orderRefId & brandName field added
  public parallelSupplyChain0!: ParallelStepBlockchainModel[];
  public parallelSupplyChain1!: ParallelStepBlockchainModel[];
  public parallelSupplyChain2!: ParallelStepBlockchainModel[];
  public parallelSupplyChain3!: ParallelStepBlockchainModel[];
  public parallelSupplyChain4!: ParallelStepBlockchainModel[];
  // TTD-3619
  public footprintRefNo!: string;
  public footprintRefID!: string;
  public footprintRefNoUpdatedBy!: string;
}

export class ConfirmStepBlockchainRespModel {
  public success!: boolean;
  public orderLineSteps!: ConfirmStepBlockchainModel[];
}
