import { ApplicationConfig } from '../config';
import { CertificateUploadModel } from '@/models/certificateUploadModel';
import { BaseClientBlockchain } from './baseClientBlockchain';
import { CertificateResponseModel } from '@/models/certificateModel';
import { OrdersWithCountModel } from '@/models/ordersWithStepsModel';
import { EvidenceStepCountModel } from '@/models/orderLineEvidenceStep';
import { OrderIdListModel } from '@/models/orderLineDisplayModel';
import { GenericResponse } from '@/models/genericResponse';
import { OrderCompanyRespModel } from '@/models/delegateCompanyModel';
import { StepsRespModel } from '@/models/stepsResponseModel';
import { UploadTCResponsepModel } from "@/models/transcationCertificateModal"
import { ArchiveResponse } from "@/models/certificateModel";

export class ComplianceCertificatesClient extends BaseClientBlockchain {
  private readonly endPoint: string = 'v1/api/supplier';
  private readonly delegatedEndPoint: string = 'v1/api/delegatePartner';
  private readonly endPointCompany: string = 'v1/api/company';
  private readonly tcEndPoint: string = 'v1/api/transaction-certificate'

  public constructor() {
    super(ApplicationConfig.endpoints.blockchainApi);
  }

  public getSocialCertificatesAsync(
    companyId: string
  ): Promise<CertificateResponseModel> {
    return this.get(`${this.endPoint}/${companyId}/pdf/getSocial`);
  }

  public getSustainableCertificatesAsync(
    companyId: string
  ): Promise<CertificateResponseModel> {
    return this.get(`${this.endPoint}/${companyId}/pdf/getSustainable`);
  }

  public uploadSocialCertificatesAsync(
    companyId: string,
    uploadData: FormData
  ): Promise<Response> {
    return this.postWithResponse(
      `${this.endPoint}/${companyId}/pdf/social`,
      uploadData,
      true
    );
  }

  public uploadSustainableCertificatesAsync(
    companyId: string,
    uploadData: FormData
  ): Promise<Response> {
    return this.postWithResponse(
      `${this.endPoint}/${companyId}/pdf/sustainable`,
      uploadData,
      true
    );
  }

  public async getOrdersAsync(
    bookmark: string,
    pageSize: number,
    companyId: string
  ): Promise<OrdersWithCountModel> {
    return await this.get(
      `${this.endPoint}/${companyId}/dashboard?pageSize=${pageSize}&bookmark=${bookmark}`
    );
  }
// TTD-2630 Sprint 18
  public async getOrdersForDelegatedAsync(
    bookmark: string,
    pageSize: number,
    companyId: string
  ): Promise<OrdersWithCountModel> {
    return await this.get(
      `${this.delegatedEndPoint}/${companyId}/dashboard?pageSize=${pageSize}&bookmark=${bookmark}`
    );
  }
  // TTD-2630 Sprint 18
  public async updatedIsDelegated(companyId: string, invitedSupplierID: string): Promise<GenericResponse> {
    return await this.patchWithResponse<GenericResponse>(
      `${this.endPointCompany}/${companyId}/invitedSupplier/${invitedSupplierID}/updateDelegatePartner`,
      '', false
    );
  }

  public async getEvidenceStepsCountAsync(companyId: string, partnerType: string): Promise<EvidenceStepCountModel> {
    return await this.get(
      `${this.endPoint}/${companyId}/dashboard/metrics?partnertype=${partnerType}`
    );
  }

  public async updateOrderPhaseStatusAsync(orderIdList: OrderIdListModel): Promise<OrderCompanyRespModel>{
    return await this.postWithResponse<OrderCompanyRespModel>(`${this.endPoint}/order/updatePhaseAndStatus`, orderIdList, false);
  }

  //Sprint-20 TTD-3931 get steps for regular scp user
  public async getScpOrderLineSteps(supplierId:string,orderId: string,lineId:string): Promise<StepsRespModel> {
    return await this.get(
      `${this.endPoint}/${supplierId}/order/${orderId}/line/${lineId}/orderLineStepsFootprint`
    );
  }

  public uploadTCAsync(
    uploadData: FormData
  ): Promise<UploadTCResponsepModel> {
    return this.postWithResponse(
      `${this.tcEndPoint}/upload`,
      uploadData,
      true
    );
  }

  public archiveCertificate(
    companyId: string,
    updateData: any
  ): Promise<ArchiveResponse> {
    return this.postWithResponse(
      `${this.endPoint}/${companyId}/certificate/archive`,
      updateData,
      false
    );
  }

}
