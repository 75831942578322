import { ComplianceClient } from '@/clients/complianceClient';
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';
import { ComplianceDocResponseModel } from '@/models/complianceDocResponseModel';
import { ComplianceDocToSignCommentModel } from '@/models/complianceDocToSignCommentModel';
import { ComplianceDocToSignEditCommentModel } from '@/models/complianceDocToSignEditCommentModel';
import { ComplianceDocToSignDeleteCommentModel } from '@/models/complianceDocToSignDeleteCommentModel';
import { GenericResponse } from '@/models/genericResponse';
import { TCDocDataModel, TCDocumentModel } from '@/models/transcationCertificateModal';

export class ComplianceService {
  private readonly complianceClient: ComplianceClient = new ComplianceClient();

  public async getDocumentsAsync(): Promise<ComplianceDocResponseModel[]> {
    const result = await this.complianceClient.getDocumentsAsync();
    if (result.success) {
      // Only NoContent delivers no result
      return result.complianceDocScp;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('global.notifications.fetch_document_failed').toString()
      );
      return [];
    }
  }

  public async getDocumentDetailsAsync(
    companyId: string,
    documentId: string
  ): Promise<ComplianceDocResponseModel> {
    const result = await this.complianceClient.getDocumentDetailsAsync(
      companyId,
      documentId
    );
    if (result.success) {
      // Only NoContent delivers no result
      return result.complianceDocScp;
    } else {
      NotificationHelper.createErrorNotification(
        i18n.t('global.notifications.fetch_document_failed').toString()
      );
      return new ComplianceDocResponseModel();
    }
  }

  public async signDocumentAsync(id: string): Promise<boolean> {
    const result = await this.complianceClient.signDocumentAsync(id);
    if (result.success) {
      // Only NoContent delivers no result
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.sign_document_succeeded').toString()
      );
      return true;
    } else {
      NotificationHelper.createErrorNotification('Failed to sign document, please try again.');
      return false;
    }
  }

  public async readDocumentAsync(id: string): Promise<boolean> {
    const result = await this.complianceClient.readDocumentAsync(id);
    if (result.success) {
      // Only NoContent delivers no result
      NotificationHelper.createSucceededNotification('Document read successfully.');
      return true;
    } else {
      NotificationHelper.createErrorNotification('Failed to read document, please try again.');
      return false;
    }
  }

  public async postCommentAsync(
    companyId: string,
    data: ComplianceDocToSignCommentModel
  ): Promise<boolean> {
    const result = await this.complianceClient.postCommentAsync(
      companyId,
      data
    );
    if (!result) {
      // Only NoContent delivers no result
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.post_comment_succeeded').toString()
      );
      return true;
    }
    return false;
  }

  public async editCommentAsync(
    companyId: string,
    data: ComplianceDocToSignEditCommentModel
  ): Promise<boolean> {
    const result = await this.complianceClient.editCommentAsync(
      companyId,
      data
    );
    if (!result) {
      // Only NoContent delivers no result
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.edit_comment_succeeded').toString()
      );
      return true;
    }
    return false;
  }

  public async deleteCommentAsync(
    companyId: string,
    data: ComplianceDocToSignDeleteCommentModel
  ): Promise<boolean> {
    const result = await this.complianceClient.deleteCommentAsync(
      companyId,
      data
    );
    if (!result) {
      // Only NoContent delivers no result
      NotificationHelper.createSucceededNotification(
        i18n.t('global.notifications.delete_comment_succeeded').toString()
      );
      return true;
    }
    return false;
  }

    //Sprint-24 TTD-4485
    public async getViewTCDocument(tcRefID: string): Promise<TCDocumentModel>{
      const result = await this.complianceClient.getViewTCDocument(tcRefID);
      if (result.success) {
          return result;
      } else {
          NotificationHelper.createErrorNotification("Failed to fetch document details.");
          return result;
      }
  }
}
