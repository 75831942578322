import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import VuePdfApp from 'vue-pdf-app';
import lodash from "lodash";

// Services
import { ComplianceService } from '@/services/complianceService';

// Components
import ViewDocumentsToSignComment from '@/views/compliance/components/viewDocumentsToSignComment.vue';

// Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';

// Models
import { UserModel } from '@/models/userModel';
import { ComplianceDocResponseModel } from '@/models/complianceDocResponseModel';
import { mapState } from 'vuex';
import { CompanyNameModel } from '@/models/companyModel';

import moment from 'moment';

@Component({
  components: { VuePdfApp, ViewDocumentsToSignComment },
  computed: mapState(['companyNameList']),
})
export default class ViewDocumentsToSign extends Vue {
  @Prop()
  private documentDetail!: ComplianceDocResponseModel;

  private complianceService: ComplianceService;

  private pdfDocument!: any;

  private isPdfReady: boolean = false;

  private isLoading: boolean = false;

  private showConfirmationPopup: boolean = false;

  private isConfirmed: boolean = false;

  private showCommentModal: boolean = false;

  private curComment: any = { ID: '', scpCommentID: '', text: '' };
  private documentDetailscpComment: any = [];

  private user: UserModel = new UserModel();

  private commentType: string = '';

  private companyNameList!: CompanyNameModel[];

  public constructor() {
    super();
    this.complianceService = new ComplianceService();
  }

  private async created(): Promise<void> {
    this.user = this.$store.getters.user as UserModel;
    this.getDocumentComments();
  }

  private config: any = {
    toolbar: false,
  };

  private get brands(): string {
    if (this.documentDetail.brands && this.documentDetail.brands.length > 0) {
      const uniqBrands = lodash.uniq(this.documentDetail.brands);
      return uniqBrands.join(', ');
    }
    return '';
  }

  private get formattedDate(): string {
    if (this.documentDetail.validtoDate==="9999-01-01T00:00:00Z") {
      return "No deadline";
    }
    return moment(this.documentDetail.validtoDate).format('D MMM YYYY');
  }

  private formatDate(dateToBeFormatted: string): string {
    if (dateToBeFormatted==="9999-01-01T00:00:00Z") {
      return "No deadline";
    }
    return moment(dateToBeFormatted).format('D MMM YYYY');
  }

  private get additionalInfo(): string {
    return this.documentDetail.additionalInfo[0].text;
  }

  private toggleConfirmed(): void {
    this.isConfirmed = !this.isConfirmed;
  }

  private openConfirmationPopup(): void {
    if (this.isConfirmed) {
      this.showConfirmationPopup = true;
    }
  }

  private editComment(comment: any): void {
    if (comment.ID) {
      this.curComment = comment;
      this.openCommentModal('edit');
    }
  }

  private async getDocumentComments(): Promise<void> {
    try {
      const user = this.$store.getters.user as UserModel;
      const companyId = user.companyId;
      const result = await this.complianceService.getDocumentDetailsAsync(
        companyId,
        this.documentDetail.ID
      );
      this.documentDetailscpComment = result.scpComment;
    } catch {
      this.documentDetailscpComment = this.documentDetail.scpComment;
    }
    if (this.documentDetailscpComment) {
      this.documentDetailscpComment = this.documentDetailscpComment.filter(
        (s: { state: number }) => s.state < 2
      );
    }
  }

  private async deleteComment(comment: any): Promise<void> {
    if (comment.ID) {
      try {
        const commentData: any = {
          ID: this.documentDetail.ID,
          scpCommentID: comment.ID,
        };
        const user = this.$store.getters.user as UserModel;
        const companyId = user.companyId;
        await this.complianceService.deleteCommentAsync(companyId, commentData);
      } catch {
        NotificationHelper.createErrorNotification(
          i18n.t('global.notifications.delete_comment_error').toString()
        );
      } finally {
        NotificationHelper.createSucceededNotification(
          i18n.t('global.notifications.delete_comment_succeeded').toString()
        );
        await this.getDocumentComments();
      }
    }
  }

  private openCommentModal(type: string): void {
    this.commentType = type;
    this.showCommentModal = true;
  }

  private closeCommentModal(): void {
    this.showCommentModal = false;
    this.getDocumentComments();
  }

  private async signDocument(): Promise<void> {
    try {
      this.isLoading = true;
      let result:boolean;
      if (this.documentDetail.signatureRequested === 'false') {
        result = await this.complianceService.readDocumentAsync(
          this.documentDetail.ID
        );
      } else {
        result = await this.complianceService.signDocumentAsync(
          this.documentDetail.ID
        );
      }
      if (result) {
        this.close(true);
      }
    } finally {
      this.isLoading = false;
    }
  }

  private async downloadPDF(): Promise<void> {
    if (this.isPdfReady) {
      this.pdfDocument.contentDispositionFilename =
        this.documentDetail.documentName;
      this.pdfDocument.download();
    }
  }
  private async pdfReady(pdfApp: any): Promise<void> {
    this.isPdfReady = true;
    this.pdfDocument = pdfApp;
  }

  private partnerName(partnerId: string, currentName: string): string {
    return this.companyNameList.filter((c) => c.ID === partnerId).length > 0 ? this.companyNameList.filter((c) => c.ID === partnerId)[0].name : currentName;
  }

  @Emit()
  private close(success: boolean): void {}
}
