import { IAuthenticator } from '@/authenticators/interfaces/IAuthenticator';
import { NotificationHelper } from '@/helpers/notificationHelper';
import axios from 'axios';
import i18n from '@/i18n';
import { UserClient } from './userClient';
import { HttpError } from '@/models/httpError';
import { BlockchainAccessModal } from '@/models/blockchainAccessModal';
import store from '@/store';

type CRUDOperation = 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE';

export abstract class BaseClientBlockchain {
  private readonly client: UserClient = new UserClient();
  private contentTypeFormData: boolean = false;
  public constructor(
    private resource: string,
    private authenticator?: IAuthenticator
  ) {}

  public get<T>(query: string): Promise<T> {
    return this.execute<T>(query, 'GET');
  }

  public getText(query: string): Promise<string> {
    return this.execute(query, 'GET', null, true);
  }

  public post(query: string, data: any): Promise<Response> {
    return this.execute(query, 'POST', data);
  }

  public postWithResponse<T>(
    query: string,
    data: any,
    contentTypeFormData: boolean
  ): Promise<T> {
    this.contentTypeFormData = contentTypeFormData;
    return this.execute(query, 'POST', data);
  }

  public put(query: string, data: any): Promise<Response> {
    return this.execute(query, 'PUT', data);
  }

  public putWithResponse<T>(
    query: string,
    data: any,
    contentTypeFormData: boolean
  ): Promise<T> {
    this.contentTypeFormData = contentTypeFormData;
    return this.execute(query, 'PUT', data);
  }

  public patch(query: string, data: any): Promise<Response> {
    return this.execute(query, 'PATCH', data);
  }

  public patchWithResponse<T>(query: string, data: any, contentTypeFormData: boolean): Promise<T> {
    this.contentTypeFormData = contentTypeFormData;
    return this.execute(query, 'PATCH', data, false);
  }

  protected delete(query: string): Promise<Response> {
    return this.execute(query, 'DELETE');
  }

  protected deleteWithData<T>(query: string, data: any): Promise<T> {
    return this.execute(query, "DELETE", data);
}

  private async execute<T>(
    query: string,
    method: CRUDOperation,
    data?: any,
    resultAsText: boolean = false
  ): Promise<T> {
    const url = `${this.resource}/${query}`;
    let accesstoken: BlockchainAccessModal = new BlockchainAccessModal();
    const token = store.getters.accessToken as BlockchainAccessModal;
    if (token !== null) {
      accesstoken = token;
    } else {
      accesstoken = await this.client.getBlockchainToken();
      store.commit('setAccessToken', accesstoken);
    }
    let request: RequestInit = {};

    if (accesstoken) {
      if (this.contentTypeFormData) {
        request = {
          headers: {
            Authorization: 'Bearer ' + accesstoken.token,
          },
          method,
        };

        if (data) {
          request.body = data;
        }
      } else {
        request = {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: 'Bearer ' + accesstoken.token,
          },
          method,
        };

        if (data) {
          request.body = JSON.stringify(data);
        }
      }
    }

    const response = await fetch(url, request);
    switch (response.status) {
      case 403:
        NotificationHelper.createErrorNotification(
          i18n.t('errors.unauthorized').toString()
        );
        return undefined as unknown as T;
      case 204:
        return undefined as unknown as T;
      case 500:
        return response as unknown as T;
      default:
        return await this.getResultFromResponse(resultAsText, response);
    }
  }

  private async getResultFromResponse(
    resultAsText: boolean,
    response: Response
  ): Promise<any> {
    let result = {
      code: '',
      message: i18n.t('errors.general').toString(),
      parameters: [],
    };

    try {
      if (resultAsText) {
        return await response.text();
      }

      result = await response.json();
    } catch {
      throw new HttpError(response.status, result.message);
    }

    if (!response.ok) {
      // check whether this is a localized error response or a custom error response
      if ('code' in result) {
        NotificationHelper.createErrorNotification(
          i18n.t(result.code, result.parameters).toString()
        );
        throw new HttpError(response.status, result.message);
      }
    }
    return result;
  }
}
