import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import samplePdf from '@/views/compliance/components/empty-sample-pdf.pdf'

import VuePdfApp from 'vue-pdf-app';

import moment from 'moment';
import { componentsPlugin } from 'bootstrap-vue';

// Helper
import { NotificationHelper } from '@/helpers/notificationHelper';
import { DropdownModel } from '@/models/dropdownModel';
import { CertificateBodyList } from "@/helpers/certificationBodyHelper";

// Services
import { ComplianceCertificatesService } from '@/services/complianceCertificatesService';
import { NonRomanCharCheckHelper }from '@/helpers/nonRomanCharCheckHelper';

// Models
import { CertificateUploadModel } from '@/models/certificateUploadModel';
import { UserModel } from '@/models/userModel';
import { ThrottlingUtils } from '@azure/msal-common';
import { CertificateTypes, CertificateOnTypeModel } from '@/models/certificateModel'

import DropDownInput from '@/components/general/drop-down-input/dropDownInput.vue';
import { isObject } from 'lodash';

@Component({
  components: { VuePdfApp, DropDownInput },
})
export default class UploadCertificates extends Vue {
  private tabIndex: number = 0;
  private otherCertName: string = '';
  private currentView: string = '';
  private isUploading: boolean = false;
  private certificateType: string = '';
  private certificatesCount: number = 1;
  private isNonRomanChar: boolean = false;
  private isCompanyNonRoman: boolean = false;
  private isReferenceNonRoman: boolean = false;
  private isRspNonRoman: boolean = false;
  private isAuditNonRoman: boolean = false;
  private isAmforNonRoman: boolean = false;
  private isAmforSiteIdNonRoman: boolean = false;
  private isOekoNonRoman: boolean = false;
  private searchText: string = '';

  private sustainableCertificates: CertificateOnTypeModel[] = [];
  private socialCertificates: CertificateOnTypeModel[] = [];
  private auditCertificates: CertificateOnTypeModel[] = [];
  private membershipCertificates: CertificateOnTypeModel[] = [];

  // private socialTypenames: string[] = Object.values(
  //   i18n.t('enums.social_certificates')
  // ).sort((a,b) => { return a.localeCompare(b)});

  // private sustainableCertificates: string[] = Object.values(
  //   i18n.t('enums.sustainability_certificates')
  // ).sort((a,b) => { return a.localeCompare(b)});

  // private auditTypenames: string[] = Object.values(
  //   i18n.t('enums.audit_certificates')
  // ).sort((a,b) => { return a.localeCompare(b)});

  // private membershipTypenames: string[] = Object.values(
  //   i18n.t('enums.memberShip_certificates')
  // ).sort((a,b) => { return a.localeCompare(b)});

  private filteredData: CertificateOnTypeModel[] = [];

  private pdfDocument: any = {};

  private resetField: boolean = false;

  private certificateTypename: string = '';

  private cerificateType: string = '';

  private ledgerType: string = ''

  private uploadCertificate: any = {
    files: [],
    description: '',
    geoLat: 0,
    geoLong: 0,
    issuer: '',
    referenceissuer: '',
    validtoDate: '',
    validfromDate: '',
    type: 0,
    rspholder: '',
    auditresult: '',
    amforiid: '',
    amforisiteid: '',
    oekoclass: '',
    certificationBody: '',
    membershipID: '',
    certificationNumber: '',
    licenseID: '',
    femScore: '',
    fslmScore: '',
    updatedOn: '',
    issuanceDate: ''
  };
  private isUploadError: any = {
    files: -1,
    description: -1,
    geoLat: -1,
    geoLong: -1,
    issuer: -1,
    referenceissuer: -1,
    validtoDate: -1,
    validfromDate: -1,
    rspholder: -1,
    auditresult: -1,
    amforiid: -1,
    amforisiteid: -1,
    oekoclass: -1,
    certificationBody: -1,
    certificationNumber: -1,
    licenseID: -1,
    femScore: -1,
    fslmScore: -1,
    membershipID: -1,
    updatedOn: -1,
    issuanceDate: -1
  };

  private certificateUploadModel: CertificateUploadModel =
    new CertificateUploadModel();

  private certificateService: ComplianceCertificatesService;

  

  public constructor() {
    super();
    // this.currentView = '';
    this.certificateService = new ComplianceCertificatesService();
  }

  private created(): void{
    const sustainable = i18n.t(`enums.sustainability_certificates.sustainability`) as any;
    for (const key of Object.keys(sustainable)) {
        const certifcateName = sustainable[key];
        this.sustainableCertificates.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.SUSTAINABILITY, CertificateTypes.SUSTAINABILITY))
    }
    const susMembership = i18n.t(`enums.sustainability_certificates.membership`) as any;
    for (const key of Object.keys(susMembership)) {
        const certifcateName = susMembership[key];
        this.membershipCertificates.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SUSTAINABILITY));
    }
    const susAudit = i18n.t(`enums.sustainability_certificates.audit`) as any;
    for (const key of Object.keys(susAudit)) {
        const certifcateName = susAudit[key];
        this.auditCertificates.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
    }
    const social = i18n.t(`enums.social_certificates.social`) as any;
    for (const key of Object.keys(social)) {
        const certifcateName = social[key];
        this.socialCertificates.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.SOCIAL, CertificateTypes.SOCIAL));
    }
    const socialAudit = i18n.t(`enums.social_certificates.audit`) as any;
    for (const key of Object.keys(socialAudit)) {
        const certifcateName = socialAudit[key];
        this.auditCertificates.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SOCIAL));
    }
    const socialMembership = i18n.t(`enums.social_certificates.membership`) as any;
    for (const key of Object.keys(socialMembership)) {
        const certifcateName = socialMembership[key];
        this.membershipCertificates.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SOCIAL));
    }
    this.sustainableCertificates = this.sustainableCertificates.sort((a: any, b: any) => {
      return a.Name.localeCompare(b.Name)
    });
    this.socialCertificates = this.socialCertificates.sort((a: any, b: any) => {
      return a.Name.localeCompare(b.Name);
    });
    this.auditCertificates = this.auditCertificates.sort((a: any, b: any) => {
      return a.Name.localeCompare(b.Name);
    });
    this.membershipCertificates = this.membershipCertificates.sort((a: any, b: any) => {
      return a.Name.localeCompare(b.Name);
    });
  }

  private async switchTab(tabIndex: number): Promise<void> {
    this.tabIndex = tabIndex;
    this.setIssuer(0, '', false, false, '', '', '');
    this.otherCertName = '';
    this.isNonRomanChar = false;
  }

  private get disableUpload(): Boolean {
    let disable: boolean = false;
    disable = this.isUploadError.files !== 0 ? true : disable;
    disable = this.isUploadError.description !== 0 ? true : disable;
    disable = this.isUploadError.geoLat !== 0 ? true : disable;
    disable = this.isUploadError.geoLong !== 0 ? true : disable;
    if(this.tabIndex!==3){
      disable = this.isUploadError.certificationBody !== 0 ? true : disable;
      disable = this.isUploadError.certificationNumber !== 0 ? true : disable;
      disable = this.isUploadError.validtoDate !== 0 ? true : disable;
      disable = this.isUploadError.validfromDate !== 0 ? true : disable;
    }
    if(this.tabIndex === 2 && this.uploadCertificate.type === 2){
      disable = this.isUploadError.rspholder !== 0 ? true : disable;
      disable = this.isUploadError.auditresult !== 0 ? true : disable;
      disable = this.isUploadError.amforisiteid !== 0 ? true : disable;
    }
    if(this.tabIndex === 2 && this.uploadCertificate.type !== 2){
      disable = this.isUploadError.amforiid !== 0 ? true : disable;
    }
    
    disable = this.isUploadError.oekoclass !== 0 ? true : disable;
    if(this.tabIndex !== 3){
      disable = this.isUploadError.licenseID !== 0 ? true : disable;
    }
    if(this.tabIndex === 2 && this.uploadCertificate.type === 31){
      disable = this.isUploadError.femScore !== 0 ? true : disable;
    }
    if(this.tabIndex === 2 && this.uploadCertificate.type === 12){
      disable = this.isUploadError.fslmScore !== 0 ? true : disable;
    }
    if(this.tabIndex === 3 && this.uploadCertificate.type !== 14){
      disable = this.isUploadError.membershipID !== 0 ? true : disable;
      disable = this.isUploadError.updatedOn !== 0 ? true : disable;
    }
    if(this.tabIndex === 3 && this.uploadCertificate.type === 14){
      disable = this.isUploadError.membershipID !== 0 ? true : disable;
      disable = this.isUploadError.issuanceDate !== 0 ? true : disable;
    }
    return disable;
  }

  private async checkAllFieldsForErrors(): Promise<void> {
    if(this.tabIndex!==3){
      await this.checkForUploadErrors('files');
      this.checkForUploadErrors('validfromDate');
      this.checkForUploadErrors('validtoDate');
    }
    // this.checkForUploadErrors('issuer');
    // this.checkForUploadErrors('referenceissuer');
    if(this.tabIndex === 0 || this.tabIndex === 1 || this.tabIndex === 2){
      this.checkForUploadErrors('certificationbody');
      this.checkForUploadErrors('certificationNumber');
    }
    if(this.tabIndex === 1 || this.tabIndex === 2){
      this.checkForUploadErrors('licenseID');
      // this.checkForUploadErrors('oekoclass');
    }
    if(this.tabIndex === 2){
      this.checkForUploadErrors('femScore');
      this.checkForUploadErrors('fslmScore');
      this.checkForUploadErrors('rspholder');
      this.checkForUploadErrors('auditresult');
      this.checkForUploadErrors('amforiid');
      this.checkForUploadErrors('amforisiteid');
      this.checkForUploadErrors('audit');
    }
    if(this.tabIndex === 3){
      this.checkForUploadErrors('membershipID');
      this.checkForUploadErrors('updatedOn');
      this.checkForUploadErrors('issuanceDate');
    }
  }

  private gotoTop(): void {
    var element = document.getElementById('scrolldiv') as HTMLElement;
    element.scrollTop = 0;
  }

  private gotoBottom(): void {
    var element = document.getElementById('scrolldiv') as HTMLElement;
    element.scrollTop = element.scrollHeight * 0.9;
  }

  private async checkForUploadErrors(field: string): Promise<void> {
    const maxSize = 1024 * 1024 * 5;
    if (this.currentView == '') {
      return;
    }
    switch (field) {
      case 'files':
        if(this.tabIndex!==3){
          if(this.currentView === 'upload'){
            this.isUploadError.files = -1;
          }else{
            this.isUploadError.files = 1;
          }
  
          if (maxSize < this.uploadCertificate.files.size) {
            NotificationHelper.createErrorNotification(
              i18n.t('errors.certificate_upload_failed').toString()
            );
            return;
          }
          
          if (this.uploadCertificate.files.type.includes('pdf')) {
            this.pdfDocument = this.uploadCertificate.files.file;
            this.isUploadError.files = 0;
            if (navigator.geolocation) {
              await navigator.geolocation.getCurrentPosition(async (position) => {
                this.uploadCertificate.geoLat = position.coords.latitude;
                this.uploadCertificate.geoLong = position.coords.longitude;
                this.isUploadError.geoLat = 0;
                this.isUploadError.geoLong = 0;
              });
              if (this.currentView == 'viewpdf') {
                this.pdfDocument =
                  await this.uploadCertificate.files.arrayBuffer();
              }
            }
          }
        }else{
          this.isUploadError.files = 0;
        }
   
        break;
      case 'description':
        this.isUploadError.description = 1;
        if (this.uploadCertificate.description.length.trim() !== '') {
          this.isUploadError.description = 0;
        }
        break;
      case 'geoLat':
        this.isUploadError.geoLat = 1;
        if (this.uploadCertificate.geoLat !== 0) {
          this.isUploadError.geoLat = 0;
        }
        break;
      case 'geoLong':
        this.isUploadError.geoLong = 1;
        if (this.uploadCertificate.geoLong !== 0) {
          this.isUploadError.geoLong = 0;
        }
        break;
      // case 'issuer':
      //   this.isUploadError.issuer = 1;
      //   if (this.uploadCertificate.issuer.trim() !== '') {
      //     this.isUploadError.issuer = 0;
      //   }
      //   break;
      case 'referenceissuer':
        this.isUploadError.referenceissuer = 1;
        if (this.uploadCertificate.referenceissuer.trim() !== '') {
          this.isUploadError.certificationNumber = 0;
        }
        break;
      case 'rspholder':
        if (this.certificateType.toLowerCase().includes('amfori')) {
          this.isUploadError.rspholder = 1;
          if (this.uploadCertificate.rspholder.trim() !== '') {
            this.isUploadError.rspholder = 0;
          }
        }
        break;
      case 'auditresult':
        if (this.certificateType.toLowerCase().includes('amfori') || this.uploadCertificate.type === 13) {
          this.isUploadError.auditresult = 1;
          if (this.uploadCertificate.auditresult.trim() !== '') {
            this.isUploadError.auditresult = 0;
          }
        }
        break;
      case 'amforiid':
        if (this.certificateType.toLowerCase().includes('amfori')) {
          this.isUploadError.amforiid = 1;
          if (this.uploadCertificate.amforiid.trim() !== '') {
            this.isUploadError.amforiid = 0;
          }
        }
        break;
      case 'amforisiteid':
        if (this.certificateType.toLowerCase().includes('amfori')) {
          this.isUploadError.amforisiteid = 1;
          if (this.uploadCertificate.amforisiteid.trim() !== '') {
            this.isUploadError.amforisiteid = 0;
          }
        }
        break;
      case 'oekoclass':
        if (this.certificateType.toLowerCase().includes('amfori')) {
          this.isUploadError.oekoclass = 1;
          if (this.uploadCertificate.oekoclass.trim() !== '') {
            this.isUploadError.oekoclass = 0;
          }
        }
        break;
      case 'certificationBody':
        this.isUploadError.certificationBody = 1;
        if (this.uploadCertificate.certificationBody.trim() !== '') {
          this.isUploadError.certificationBody = 0;
        }
        break;
      case 'certificationNumber':
      this.isUploadError.certificationNumber = 1;
      if (this.uploadCertificate.certificationNumber.trim() !== '') {
        this.isUploadError.certificationNumber = 0;
      }
      break;
      case 'licenseID':
        this.isUploadError.licenseID = 1;
        if (this.uploadCertificate.licenseID.trim() !== '') {
           this.isUploadError.licenseID = 0;
        }
        break;
      case 'femScore':
      this.isUploadError.femScore = 1;
      if (this.uploadCertificate.femScore.trim() !== '') {
         this.isUploadError.femScore = 0;
        }
        break;
      case 'fslmScore':
      this.isUploadError.fslmScore = 1;
      if (this.uploadCertificate.fslmScore.trim() !== '') {
          this.isUploadError.fslmScore = 0;
        }
        break;
      case 'audit':
      this.isUploadError.auditresult = 1;
      if (this.uploadCertificate.auditresult.trim() !== '') {
          this.isUploadError.auditresult = 0;
        }
        break;
      case 'membershipID':
        this.isUploadError.membershipID = 1;
        if (this.uploadCertificate.membershipID.trim() !== '') {
            this.isUploadError.membershipID = 0;
          }
          break;
      case 'validfromDate':
        this.isUploadError.validfromDate = 1;
        if (
          this.uploadCertificate.validfromDate !== null &&
          this.uploadCertificate.validfromDate !== ''
        ) {
          this.uploadCertificate.validfromDate = new Date(
            this.uploadCertificate.validfromDate
          ).toJSON();
          this.isUploadError.validfromDate = 0;
          if (this.uploadCertificate.validtoDate !== null) {
            const validTo = new Date(
              this.uploadCertificate.validtoDate
            ).getTime();
            const validFrom = new Date(
              this.uploadCertificate.validfromDate
            ).getTime();
            if (validFrom > validTo) {
              this.isUploadError.validfromDate = 1;
            }
            if(validFrom <= validTo){
              this.isUploadError.validtoDate = 0;
            }
          }
        }
        break;
      case 'validtoDate':
        this.isUploadError.validtoDate = 1;
        if (
          this.uploadCertificate.validtoDate !== null &&
          this.uploadCertificate.validtoDate !== ''
        ) {
          this.uploadCertificate.validtoDate = new Date(
            this.uploadCertificate.validtoDate
          ).toJSON();
          this.isUploadError.validtoDate = 0;
          if (this.uploadCertificate.validfromDate !== null) {
            const validTo = new Date(
              this.uploadCertificate.validtoDate
            ).getTime();
            const validFrom = new Date(
              this.uploadCertificate.validfromDate
            ).getTime();
            if (validFrom > validTo) {
              this.isUploadError.validtoDate = 1;
            }
          }
        }
        break;
      case 'updatedOn':
        this.isUploadError.updatedOn = 1;
        if (
          this.uploadCertificate.updatedOn !== null &&
          this.uploadCertificate.updatedOn !== ''
        ) {
          this.uploadCertificate.updatedOn = new Date(
            this.uploadCertificate.updatedOn
          ).toJSON();
          this.isUploadError.updatedOn = 0;
        }
        break;
        case 'issuanceDate':
          this.isUploadError.issuanceDate = 1;
          if (
            this.uploadCertificate.issuanceDate !== null &&
            this.uploadCertificate.issuanceDate !== ''
          ) {
            this.uploadCertificate.issuanceDate = new Date(
              this.uploadCertificate.issuanceDate
            ).toJSON();
            this.isUploadError.issuanceDate = 0;
          }
          break;
    }
  }

  private async setIssuer(
    index: number,
    issuer: string,
    lCheck: boolean = true,
    isClick: boolean,
    certificateName: string,
    type: string,
    relatedType: string
  ): Promise<void> {
    this.resetField = false;
    this.resetUploadFields();
    index = certificateName == '' ? 0 : index;
    if (lCheck && this.uploadCertificate.type == index) {
      this.uploadCertificate.type = 0;
      this.certificateType = '';
    } else {
      this.uploadCertificate.type = index;
      this.certificateType = certificateName;
    }
    if (issuer !== this.otherCertName) {
      this.otherCertName = '';
    }
    if(this.otherCertName === '' && isClick){
      this.uploadCertificate.type = parseInt(issuer);
      this.certificateTypename = certificateName;
      this.cerificateType = type;
      this.ledgerType = relatedType;
      this.switchView('upload')
    }
    this.searchText = '';
    this.filteredData = [];
  }

  private resetUploadFields(): void {
    this.uploadCertificate = {
      files: [],
      description: '',
      geoLat: 0,
      geoLong: 0,
      issuer: '',
      referenceissuer: '',
      validtoDate: '',
      validfromDate: '',
      type: 0,
      rspholder: '',
      auditresult: '',
      amforiid: '',
      amforisiteid: '',
      oekoclass: '',
      femScore: '',
      fslmScore: '',
      certificationBody: '',
      certificationNumber: '',
      licenseID: '',
      audit: '',
      membershipID: '',
      updatedOn: '',
      issuanceDate: ''
    };
    this.resetIsUploadError();
  }

  private resetIsUploadError(): void {
    this.isUploadError = {
      files: -1,
      description: -1,
      geoLat: -1,
      geoLong: -1,
      issuer: -1,
      referenceissuer: -1,
      validtoDate: -1,
      validfromDate: -1,
      rspholder: -1,
      auditresult: -1,
      amforiid: -1,
      amforisiteid: -1,
      oekoclass: -1,
      certificationBody: -1,
      certificationNumber: -1,
      licenseID: -1,
      femScore: -1,
      fslmScore: -1,
      membershipID: -1,
      updatedOn: -1,
      issuanceDate: -1
    };
    this.isUploadError.geoLat = 0;
    this.isUploadError.geoLong = 0;
    this.isUploadError.description = 0;
    this.isUploadError.oekoclass = this.certificateType
      .toLowerCase()
      .includes('oeko')
      ? -1
      : 0;
    this.isUploadError.amforiid = this.certificateType
      .toLowerCase()
      .includes('amfori')
      ? -1
      : 0;
    this.isUploadError.rspholder = this.certificateType
      .toLowerCase()
      .includes('amfori')
      ? -1
      : 0;
    this.isUploadError.auditresult = this.certificateType
      .toLowerCase()
      .includes('amfori')
      ? -1
      : 0;
    this.isUploadError.amforisiteid = this.certificateType
      .toLowerCase()
      .includes('amfori')
      ? -1
      : 0;
  }

  private get auditOptions(): DropdownModel[] {
    const certificates = i18n.t(`enums.audit_result`) as any;
    const result: DropdownModel[] = [];
    for (const key of Object.keys(certificates)) {
        const certifcateName = certificates[key];
        result.push({ value: key, text: certifcateName });
    }
    return result;
  }

  private async goBack(): Promise<void> {
    switch (this.currentView) {
      case 'viewpdf':
        this.switchView('upload');
        this.checkAllFieldsForErrors();
        break;

      case 'upload':
        this.switchView('');
        break;

      default:
        this.closeModal();
        break;
    }
  }

  private async switchView(view: string): Promise<void> {
      this.isNonRomanChar = false;
      this.isCompanyNonRoman = false;
      this.isReferenceNonRoman = false;
      this.isRspNonRoman = false;
      this.isAuditNonRoman = false;
      this.isAmforNonRoman = false;
      this.isAmforSiteIdNonRoman = false;
      this.isOekoNonRoman = false;
    if(view === ''){
      this.resetUploadFields();
    }
    if (view == 'viewpdf' && this.disableUpload) {
      return;
    }

    if (view == 'viewpdf' && this.uploadCertificate.files) {
      this.gotoTop();
      await this.checkAllFieldsForErrors();
      if (this.disableUpload) {
        return;
      }
      setTimeout(
        async (app: any) => {
          app.pdfDocument = await app.uploadCertificate.files.arrayBuffer();
        },
        250,
        this
      );
    }
    if (view == 'upload' && !this.disableUpload) {
      //
    }
    this.currentView = view;
  }

  private async pdfReady(pdfApp: any): Promise<void> {
    const pdfZoom = 'page-fit';
    await pdfApp.pdfViewer.pagesPromise;
    pdfApp.pdfViewer.currentScaleValue = pdfZoom;
  }

  private async uploadCertificateService(): Promise<void> {
    await this.checkAllFieldsForErrors();
    if (this.disableUpload) {
      return;
    }

    this.isUploading = true;

    const user = this.$store.getters.user as UserModel;

    const certificatFormData = new FormData();
    if(this.tabIndex === 3){
      const pdfFile = await this.fetchPdfFile();
      this.uploadCertificate.files = pdfFile;
      this.uploadCertificate.validfromDate = new Date().toJSON();
      this.uploadCertificate.validtoDate = this.getFutureDate(28).toJSON();
      certificatFormData.append('upload', this.uploadCertificate.files);
      }else{
        certificatFormData.append('upload', this.uploadCertificate.files);
      }

    certificatFormData.append(
      'description',
      this.uploadCertificate.description
    );
    certificatFormData.append(
      'geoLat',
      this.uploadCertificate.geoLat.toString()
    );
    certificatFormData.append(
      'geoLong',
      this.uploadCertificate.geoLong.toString()
    );
    certificatFormData.append('issuer', this.uploadCertificate.certificationBody);
    certificatFormData.append(
      'referenceIssuer',
      this.uploadCertificate.certificationNumber
    );

    if (this.certificateType.toLowerCase().includes('amfori') || this.uploadCertificate.type === 13) {
      certificatFormData.append('rspHolder', this.uploadCertificate.rspholder);
      certificatFormData.append(
        'auditResult',
        this.uploadCertificate.auditresult
      );
     
      certificatFormData.append('amforiID', this.uploadCertificate.amforiid);
      certificatFormData.append(
        'siteAmforiID',
        this.uploadCertificate.amforisiteid
      );
    }

    if (this.certificateType.toLowerCase().includes('oeko')) {
      certificatFormData.append('oekoClass', this.uploadCertificate.oekoclass);
    }

    certificatFormData.append(
      'validFromDate',
      this.uploadCertificate.validfromDate
    );
    certificatFormData.append(
      'validToDate',
      this.uploadCertificate.validtoDate
    );
    certificatFormData.append('type', this.uploadCertificate.type.toString());

    certificatFormData.append('licenseID', this.uploadCertificate.licenseID.toString());
    certificatFormData.append('femScore', this.uploadCertificate.femScore.toString());
    certificatFormData.append('membershipID', this.uploadCertificate.membershipID.toString());
    if(this.tabIndex === 3 && this.uploadCertificate.type=== 14){
      certificatFormData.append('issuanceDate', this.uploadCertificate.issuanceDate.toString());
    }else{
      certificatFormData.append('issuanceDate', this.uploadCertificate.updatedOn.toString());
    }
    
    if(this.tabIndex == 0 && this.cerificateType === CertificateTypes.SUSTAINABILITY){
      certificatFormData.append('certificateType', CertificateTypes.CERTIFICATE);
    }else if(this.tabIndex == 1 && this.cerificateType === CertificateTypes.SOCIAL){
      certificatFormData.append('certificateType', CertificateTypes.CERTIFICATE);
    }else if(this.tabIndex == 2 && this.cerificateType === CertificateTypes.AUDIT){
      certificatFormData.append('certificateType', CertificateTypes.AUDIT);
    }else{
      certificatFormData.append('certificateType', CertificateTypes.MEMBERSHIP);
    }
    certificatFormData.append('fslmScore', this.uploadCertificate.fslmScore.toString());
    certificatFormData.append('status', CertificateTypes.UPLOADED);
    certificatFormData.append('otherCertName', this.otherCertName);
    try {
      let response: any;
      if (this.tabIndex == 0 || (this.tabIndex == 2 && this.ledgerType === CertificateTypes.SUSTAINABILITY) || 
          (this.tabIndex == 3 && this.ledgerType === CertificateTypes.SUSTAINABILITY)) {
        response =
          await this.certificateService.uploadSustainableCertificatesAsync(
            user.companyId,
            certificatFormData
          );
      } else {
        response = await this.certificateService.uploadSocialCertificatesAsync(
          user.companyId,
          certificatFormData
        );
      }

      if (response.status == 500) {
        if(this.tabIndex!==3){
          NotificationHelper.createErrorNotification(
            i18n.t('errors.certificate_upload_failed').toString()
          );
        }else{
          NotificationHelper.createErrorNotification(
            'Updating membership failed.'
          );
        }
      }

      if (response.type && response.ID) {
        const certTotal = this.$store.getters.certificatesCount;
        if (certTotal > 0) {
          this.$store.commit('setCertificatesCount', certTotal + 1);
        } else {
          this.$store.commit('setCertificatesCount', 1);
        }
        if(this.tabIndex!==3){
          NotificationHelper.createSucceededNotification(
            i18n.t('pages.compliance.certificate_upload_success').toString()
          );
        }else{
          NotificationHelper.createSucceededNotification(
            'Membership successfully updated'
          );
        }
       
        this.reloadCertificates();
      }
    } finally {
      this.isUploading = false;
    }
  }

  private async checkNonRomanChar(val:string) {
    this.isNonRomanChar = await NonRomanCharCheckHelper.checkNonRomanChar(val);
  }

  private async checkNonRomanCharForUpload(variableName:string,val:string){
    (this as any)[variableName] = await NonRomanCharCheckHelper.checkNonRomanChar(val)
  }

  private get isNonRomnError():boolean{
    if(this.isCompanyNonRoman||this.isReferenceNonRoman||this.isRspNonRoman||this.isAuditNonRoman||
      this.isAmforNonRoman||this.isAmforSiteIdNonRoman||this.isOekoNonRoman){
        return true;
      }
      return false
  }

  private get certifcationBodyList(): DropdownModel[] {
		let options: DropdownModel[] = [];
		options = CertificateBodyList.getCertificatesBodyList();
		return options;
	}

  private setCertificateBody(val:any): void{
    this.uploadCertificate.certificationBody = val.fieldValue;
    this.checkForUploadErrors('certificationBody');
  }

  private setAuditOption(val:any): void{
    this.uploadCertificate.auditresult = val.fieldValue;
    this.checkForUploadErrors('audit');
  }

  private searchBy(): void{
    if(this.searchText.trim()!==''){
      if(this.tabIndex === 0){
        this.filteredData = this.sustainableCertificates.filter(su => su.Name.toLowerCase().includes(this.searchText.toLowerCase()) && su.Name!=='None' && su.Name!=='Other');
      }else if(this.tabIndex === 1){
        this.filteredData = this.socialCertificates.filter(so => so.Name.toLowerCase().includes(this.searchText.toLowerCase()) && so.Name!=='None' && so.Name!=='Other');
      }else if(this.tabIndex === 2){
        this.filteredData = this.auditCertificates.filter(su => su.Name.toLowerCase().includes(this.searchText.toLowerCase()) && su.Name!=='Other');
      }else{
        this.filteredData = this.membershipCertificates.filter(me => me.Name.toLowerCase().includes(this.searchText.toLowerCase()) && me.Name!=='Other')
      }
    }else{
      this.filteredData = [];
    }
  }

  private setOtherCertificate(): void{
    this.resetField = false;
    if(this.tabIndex === 0){
      this.uploadCertificate.type = 12;
      this.cerificateType = CertificateTypes.SUSTAINABILITY;
      this.ledgerType = CertificateTypes.SUSTAINABILITY;
    }else if(this.tabIndex === 1){
      this.uploadCertificate.type = 10;
      this.cerificateType = CertificateTypes.SOCIAL;
      this.ledgerType = CertificateTypes.SOCIAL;
    }else if(this.tabIndex === 2){
      this.uploadCertificate.type = 15;
      this.cerificateType = CertificateTypes.AUDIT;
      this.ledgerType = CertificateTypes.SOCIAL;
    }else {
      this.uploadCertificate.type = 16;
      this.cerificateType = CertificateTypes.MEMBERSHIP;
      this.ledgerType = CertificateTypes.SOCIAL;
      this.isUploadError.files = 0;
      this.isUploadError.licenseID = 0;
    }
    this.certificateTypename = this.otherCertName;
    this.switchView('upload')
  }

  private async fetchPdfFile(): Promise<File> {
    const response = await fetch(samplePdf);
    const blob = await response.blob();
    return new File([blob], 'sample.pdf', { type: 'application/pdf' });
  }

  private getFutureDate(days: number): Date {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + days);
    return currentDate;
  }

  @Watch('uploadCertificate.files')
  private checkValidFiles(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('files');
    }
  }

  @Watch('uploadCertificate.validfromDate')
  private checkValidFromDate(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('validfromDate');
    }
  }

  @Watch('uploadCertificate.validtoDate')
  private checkValidToDate(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('validtoDate');
    }
  }

  @Watch('uploadCertificate.updatedOn')
  private checkUpdatedOn(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('updatedOn');
    }
  }

  @Watch('uploadCertificate.issuanceDate')
  private checkissuanceDate(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && newValue !== null) {
      this.checkForUploadErrors('issuanceDate');
    }
  }
  

  @Emit()
  private reloadCertificates(): void {
  }

  @Emit()
  private closeModal(): void {
  }
}
