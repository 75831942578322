import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';

// Components
import CreateSupplyChain from '@/views/home/components/createSupplyChain.vue';

// Modals
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { BulkOrderModel } from '@/models/bulkOrderModel';
import { DelegateCompanyModel } from '@/models/delegateCompanyModel';
import { Guid } from '@/models/guid';
import { UserModel } from '@/models/userModel';

// Helper
import { DateTimeHelper } from '@/helpers/dateTimeHelper';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { OrderLineStepModel } from '@/models/orderLineStepModel';

@Component({
  components: { CreateSupplyChain },
})
export default class CreateBulkOrder extends Vue {
  @Prop()
  private orderLine!: OrderLineDisplayModel[];

  @Prop()
  private orderLineStep!: OrderLineStepModel[];

  private selectedOrderLine: OrderLineDisplayModel[] = [];

  private selectedOrderLineStep: OrderLineStepModel[] = [];

  private combinedOrders: BulkOrderModel[] = [];

  private selected: boolean = false;

  private selectAll: boolean = false;

  private isLoading: boolean = false;

  private showAddStepModal: boolean = false;

  private btnCustomize: string;

  private orderIds: string[] = [];

  private customiseOrders: CombinedOrderLineModel;

  private isOrderNotSelected: boolean = true;

  private productGroup: any;

  private stepsDrafted: OrderLineStepModel[] = [];

  public constructor() {
    super();
    this.customiseOrders = new CombinedOrderLineModel();
    this.btnCustomize = this.$t('pages.home.create').toString();
    
  }

  private async created(): Promise<void> {
    try {
      this.isLoading = true;
      this.btnCustomize = this.$t('pages.home.create').toString();
      this.productGroup = i18n.t(
        'enums.product_group.' + this.orderLine[0].productGroup
      );
      this.selectAll = false;
      this.showAddStepModal = false;
      // TTD-3617 Sprint 18, updated to false
      this.isOrderNotSelected = false;
      this.stepsDrafted = [];
      this.combinedOrders = [];
      await this.getOrderList();
      // TTD-3617 Sprint 18
      this.select();
    } finally {
      this.isLoading = false;
    }
  }

  // TTD-2630 Sprint 18
  private checkbyWhom(): boolean {
    const user = this.$store.getters.user as UserModel;
    if (this.orderLine[0].delegateCompanyID !== user.companyId) {
      return true;
    }
    return false;
  }

  private getOrderList(): void {
    this.orderLine.forEach((item) => {
      const order = new BulkOrderModel();
      order.orderId = item.orderId;
      order.orderNumber = item.orderNumber;
      order.styleNumber = item.styleNumber;
      order.styleName = item.styleName;
      order.colourway =
        item.orderLines[0].colourway === undefined ||
        item.orderLines[0].colourway.trim() === ''
          ? '-'
          : item.orderLines[0].colourway.trim();
      order.createdAt = DateTimeHelper.convertDateDesiredFormat(
        item.createdAt,
        'D MMM YYYY'
      );
      order.deliveryDate = DateTimeHelper.convertDateDesiredFormat(
        item.expectedTimeOfDelivery,
        'D MMM YYYY'
      );
      order.checked = false;
      this.combinedOrders.push(order);
    });
  }

  private openAddStepModal(): void {
    this.selectOrderLine();
    this.showAddStepModal = true;
  }

  private closeAddStepModal(orderLineSteps: OrderLineStepModel[]): void {
    this.showAddStepModal = false;
    this.stepsDrafted = orderLineSteps;
  }

  private selectOrderLine(): void {
    this.selectedOrderLine = [];
    this.selectedOrderLineStep = [];
    if (this.orderLine.length === this.orderIds.length) {
      this.selectedOrderLine = this.orderLine;
      this.selectedOrderLineStep = this.orderLineStep;
    } else {
      this.orderIds.forEach((id) => {
        const orderLine = this.orderLine.filter((line) => line.orderId === id);
        const orderLinStep = this.orderLineStep.filter(
          (step) => step.orderId === id
        );
        this.selectedOrderLine.push(...orderLine);
        this.selectedOrderLineStep.push(...orderLinStep);
      });
    }
  }

  private select(): void {
    if (!this.selectAll) {
      this.selectAll = true;
      this.combinedOrders.forEach((order) => {
        order.checked = true;
        if (this.orderIds.indexOf(order.orderId) === -1) {
          this.orderIds.push(order.orderId);
        }
      });
      this.isOrderNotSelected = false;
      return;
    }
    this.combinedOrders.forEach((order) => {
      order.checked = false;
      if (this.orderIds.indexOf(order.orderId) !== -1) {
        const orderIndex = this.orderIds.indexOf(order.orderId);
        this.orderIds.splice(orderIndex, 1);
      }
    });
    this.selectAll = false;
    this.isOrderNotSelected = true;
  }

  private orderValue(orderId: string, value: boolean): void {
    if (value) {
      const index = this.combinedOrders.findIndex((o) => o.orderId === orderId);
      this.combinedOrders[index].checked = value;
      this.orderIds.push(orderId);
      this.isOrderNotSelected = false;
      if (this.orderIds.length === this.combinedOrders.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
      const allOrders = this.combinedOrders;
      this.combinedOrders = [];
      this.combinedOrders = allOrders;
    } else {
      const index = this.combinedOrders.findIndex((o) => o.orderId === orderId);
      this.combinedOrders[index].checked = value;
      const orderIndex = this.orderIds.indexOf(orderId);
      this.orderIds.splice(orderIndex, 1);
      this.selectAll = false;
      if (this.orderIds.length === 0) {
        this.isOrderNotSelected = true;
      }
      const allOrders = this.combinedOrders;
      this.combinedOrders = [];
      this.combinedOrders = allOrders;
    }
  }

  @Emit()
  private close(created: boolean): void {}

  @Emit()
  private confirmOrderLine(
    orderLine: OrderLineDisplayModel[],
    orderLineSteps: OrderLineStepModel[],
    isDelegated: boolean,
    selectedDelegatePartner: DelegateCompanyModel,
    isDelegatedPartnerRemoved: boolean
  ): void {}
  //commenting these changes not required now sprint-18
  // @Emit()
  // private resetOrderLineSteps(rawSteps:OrderLineStepModel[]): void{}
}
