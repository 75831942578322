import { Vue, Component, Prop } from 'vue-property-decorator';
import store from '@/store';
import { mapGetters } from 'vuex';
import moment from 'moment';

// Components
import { OrderService } from '@/services/orderService';
import { SupplierService } from '@/services/supplierService';
import { UserService } from '@/services/userService';

// component
import ViewQualityDocument from '@/views/orderLines/components/viewQualityDocument.vue';
import Steps from '@/views/orderLines/components/steps.vue';
import StepsLoader from '@/views/orderLines/components/stepsLoader.vue';
import EditOrderLine from '@/views/orderLines/components/editOrderLine.vue';
import CompanyProvider from '@/providers/companyProvider';

// models
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { StepState } from '@/models/stepState';
import { ProductQualityReportModel } from '@/models/productQualityReportModel';
import { Guid } from '@/models/guid';
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import { UserPermissionModel } from '@/models/permissionModel';
import { GroupStepEvidenceHelper } from '@/helpers/groupStepEvidenceHelper';
import { ConfirmStepBlockchainModel } from '@/models/confirmOrderLineStepModal';
import { StepHistoryBlockchainModel } from '@/models/stepHistoryModal';
import { ParallelStepBlockchainModel } from '@/models/orderLineStepParallelModel';
import { ActionTypes } from '@/store/actions/actions';
import { UserModel } from '@/models/userModel';
import { CompanyNameModel } from '@/models/companyModel';

@Component({
  components: { Steps, StepsLoader, EditOrderLine, ViewQualityDocument },
  computed: mapGetters(['userRole', 'userPermissions', 'companyNameList']),
})
export default class OrderLine extends Vue {
  @Prop()
  private orderLine!: OrderLineDisplayModel;

  private orderLineData: OrderLineDisplayModel = new OrderLineDisplayModel();

  private orderService: OrderService;

  private supplierService: SupplierService;

  private userService: UserService;

  private stepsLoading: boolean = false;

  private orderLineSteps: OrderLineStepModel[] = [];

  private showInfo: boolean = false;

  private showParallelChain: boolean = false;

  private selectedStep: OrderLineStepDisplayModel =
    new OrderLineStepDisplayModel();

  private companyId: string = '';

  private pictureIndex: number = 0;

  private showAddStepModal: boolean = false;

  private stepEdited: boolean = false;

  private currentReportDocument!: ProductQualityReportModel;

  private showReportPDFModal: boolean = false;

  private userRole!: string;

  private userPermissions!: UserPermissionModel | null;

  private isParallelSupplyChainAdded: boolean = false;

  private userData!: UserModel;

  private showNotAllowEdit: boolean = false;

  private companyNameList!: CompanyNameModel[];

  public constructor() {
    super();
    this.orderService = new OrderService();
    this.userService = new UserService();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    this.userData = this.$store.getters.user as UserModel;
    this.orderLineData = this.orderLine;
    await this.getOrderLineSteps();
    this.companyId = await CompanyProvider.get().getCompanyIdAsync();
  }

  private toggleInfo(): void {
    this.showInfo = !this.showInfo;
    if (this.showInfo === true && this.isParallelSupplyChainAdded === true) {
      this.showParallelChain = true;
    } else {
      this.showParallelChain = false;
    }
  }

  private openReportPdfModal(
    selectedReportDocument: ProductQualityReportModel
  ): void {
    this.currentReportDocument = selectedReportDocument;
    this.showReportPDFModal = true;
  }

  private async getOrderLineSteps(): Promise<void> {
    try {
      this.stepsLoading = true;
      let count = 0;
      this.isParallelSupplyChainAdded = false;
      if (!this.stepEdited) {
        const steps: OrderLineStepModel[] = this.$store.getters.orderLineSteps;
        this.orderLineSteps = steps.filter(
          (s) => s.orderId === this.orderLine.orderId
        );
        this.orderLineSteps.sort((step1, step2) => {
          return step1.sequence - step2.sequence;
        });
        this.orderLineSteps.forEach((o) => {
          o.parallelChainCount = count;
          if (o.parallelSupplyChain0.length > 0) {
            this.isParallelSupplyChainAdded = true;
            o.parallelSupplyChain0.forEach((s) => {
              s.parentStepId = o.stepId.toString();
            });
            count++;
          }
          if (o.parallelSupplyChain1.length > 0) {
            this.isParallelSupplyChainAdded = true;
            o.parallelSupplyChain1.forEach((s) => {
              s.parentStepId = o.stepId.toString();
            });
            count++;
          }
          if (o.parallelSupplyChain2.length > 0) {
            this.isParallelSupplyChainAdded = true;
            o.parallelSupplyChain2.forEach((s) => {
              s.parentStepId = o.stepId.toString();
            });
            count++;
          }
          if (o.parallelSupplyChain3.length > 0) {
            this.isParallelSupplyChainAdded = true;
            o.parallelSupplyChain3.forEach((s) => {
              s.parentStepId = o.stepId.toString();
            });
            count++;
          }
          if (o.parallelSupplyChain4.length > 0) {
            this.isParallelSupplyChainAdded = true;
            o.parallelSupplyChain4.forEach((s) => {
              s.parentStepId = o.stepId.toString();
            });
            count++;
          }
        });
      } else {
        this.orderLineSteps = await this.orderService.getOrderLineStepsAsync(
          this.orderLine.orderId,
          this.orderLine.id
        );
        const steps: OrderLineStepDisplayModel[] =
          this.$store.getters.orderLineSteps;
        this.orderLineSteps.forEach((line) => {
          const index = steps.findIndex((s) => s.id === line.id);
          if (index !== -1) {
            steps[index] = line;
          } else {
            steps.push(line);
          }
        });
        store.commit('setOrderLineSteps', steps);
        this.orderLineSteps.sort((step1, step2) => {
          return step1.sequence - step2.sequence;
        });
        this.orderLineSteps.forEach((o) => {
          o.parallelChainCount = count;
          if (o.parallelSupplyChain0.length > 0) {
            this.isParallelSupplyChainAdded = true;
            o.parallelSupplyChain0.forEach((s) => {
              s.parentStepId = o.stepId.toString();
            });
            count++;
          }
          if (o.parallelSupplyChain1.length > 0) {
            this.isParallelSupplyChainAdded = true;
            o.parallelSupplyChain1.forEach((s) => {
              s.parentStepId = o.stepId.toString();
            });
            count++;
          }
          if (o.parallelSupplyChain2.length > 0) {
            this.isParallelSupplyChainAdded = true;
            o.parallelSupplyChain2.forEach((s) => {
              s.parentStepId = o.stepId.toString();
            });
            count++;
          }
          if (o.parallelSupplyChain3.length > 0) {
            this.isParallelSupplyChainAdded = true;
            o.parallelSupplyChain3.forEach((s) => {
              s.parentStepId = o.stepId.toString();
            });
            count++;
          }
          if (o.parallelSupplyChain4.length > 0) {
            this.isParallelSupplyChainAdded = true;
            o.parallelSupplyChain4.forEach((s) => {
              s.parentStepId = o.stepId.toString();
            });
            count++;
          }
        });
        if(this.showInfo === true && this.isParallelSupplyChainAdded){
          this.showParallelChain = true;
        }
        else{
            this.showParallelChain = false;
        }
        steps.sort((step1, step2) =>{
            return step1.sequence - step2.sequence;
        });
      }
    } finally {
      this.stepsLoading = false;
    }
  }

  private get orderLineInfo(): OrderLineDisplayModel {
    // TTD-3864 filtering pictures which are deleted
    if(this.orderLineData.productPictures !== undefined && this.orderLineData.productPictures.length > 0){
      this.orderLineData.productPictures = this.orderLineData.productPictures.filter(p => p.shown === true);
    }
    return this.orderLineData;
  }

  private get steps(): OrderLineStepDisplayModel[] {
    this.orderLineSteps = this.orderLineSteps.sort((step1, step2) => {
      return step1.sequence - step2.sequence;
    });
    const orderlineSteps = this.orderLineSteps.filter(
      (s) =>
        s.stepState !== StepState.DELETED_BY_CLIENT &&
        s.stepState !== StepState.DELETED_BY_SUPPLIER &&
        s.stepState !== StepState.DELETED_BY_AGENT &&
        s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER &&
        s.stepState !== StepState.NONE
    );
    orderlineSteps.forEach((s) => {
      if (s.parallelSupplyChain0.length > 0) {
        s.parallelSupplyChain0 = s.parallelSupplyChain0.filter(
          (s) =>
            s.stepState !== StepState.DELETED_BY_CLIENT &&
            s.stepState !== StepState.DELETED_BY_SUPPLIER &&
            s.stepState !== StepState.DELETED_BY_AGENT &&
            s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER &&
            s.stepState !== StepState.NONE
        );
      }
      if (s.parallelSupplyChain1.length > 0) {
        s.parallelSupplyChain1 = s.parallelSupplyChain1.filter(
          (s) =>
            s.stepState !== StepState.DELETED_BY_CLIENT &&
            s.stepState !== StepState.DELETED_BY_SUPPLIER &&
            s.stepState !== StepState.DELETED_BY_AGENT &&
            s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER &&
            s.stepState !== StepState.NONE
        );
      }
      if (s.parallelSupplyChain2.length > 0) {
        s.parallelSupplyChain2 = s.parallelSupplyChain2.filter(
          (s) =>
            s.stepState !== StepState.DELETED_BY_CLIENT &&
            s.stepState !== StepState.DELETED_BY_SUPPLIER &&
            s.stepState !== StepState.DELETED_BY_AGENT &&
            s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER &&
            s.stepState !== StepState.NONE
        );
      }
      if (s.parallelSupplyChain3.length > 0) {
        s.parallelSupplyChain3 = s.parallelSupplyChain3.filter(
          (s) =>
            s.stepState !== StepState.DELETED_BY_CLIENT &&
            s.stepState !== StepState.DELETED_BY_SUPPLIER &&
            s.stepState !== StepState.DELETED_BY_AGENT &&
            s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER &&
            s.stepState !== StepState.NONE
        );
      }
      if (s.parallelSupplyChain4.length > 0) {
        s.parallelSupplyChain4 = s.parallelSupplyChain4.filter(
          (s) =>
            s.stepState !== StepState.DELETED_BY_CLIENT &&
            s.stepState !== StepState.DELETED_BY_SUPPLIER &&
            s.stepState !== StepState.DELETED_BY_AGENT &&
            s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER &&
            s.stepState !== StepState.NONE
        );
      }
    });
    return orderlineSteps;
  }

  private get productGroup(): string {
    if (this.orderLineInfo.productGroup == null) {
      return '-';
    }
    return this.$t(`enums.product_group.${this.orderLineInfo.productGroup}`)
      .toString()
      .split(/(?=[A-Z])/)
      .join(' ');
  }

  private get deliveryDate(): string {
    if (this.orderLineInfo.expectedTimeOfDelivery == null) {
      return '-';
    }
    return moment(this.orderLineInfo.expectedTimeOfDelivery).format(
      'D MMMM YYYY'
    );
  }

  private get proofText(): string {
    if (
      this.orderLineInfo.evidenceCreatedBy !== '' &&
      this.orderLine.stepStatus != OrderLineStepStatus.None
    ) {
      const date = moment(this.orderLineInfo.evidenceCreatedAt).format(
        'D MMM YYYY'
      );
      const name = this.orderLineInfo.evidenceCreatedBy;
      return `${this.$t('pages.order_lines.uploaded_by', [date, name])}`;
    }
    return '-';
  }

  private get compliantText(): string {
    if (
      this.selectedStep.evidencePhotos.length > 0 &&
      this.orderLineInfo.stepStatus != OrderLineStepStatus.None &&
      (this.checkTier1StatusForOrder() ||
        this.selectedStep.toCompanyId === this.companyId)
    ) {
      if (this.orderLineInfo.certificate != null) {
        return this.$t('pages.order_lines.scp_compliant').toString();
      } else {
        return this.$t('pages.order_lines.not_scp_compliant').toString();
      }
    }
    return '-';
  }

  private get stepVerifiedText(): string {
    if (
      this.selectedStep.evidencePhotos.length > 0 &&
      this.orderLineInfo.stepStatus != OrderLineStepStatus.None &&
      (this.checkTier1StatusForOrder() ||
        this.selectedStep.toCompanyId === this.companyId)
    ) {
      if (this.orderLineInfo.stepStatus === OrderLineStepStatus.Accepted) {
        return this.$t('pages.order_lines.verified_next_step').toString();
      } else {
        return this.$t('pages.order_lines.step_not_verified').toString();
      }
    }
    return '-';
  }

  private get geolocationVerifiedText(): string {
    return '-';
  }

  private get updateOrderLinePermission(): number {
    return this.userPermissions !== null
      ? this.userPermissions.updateOrderLine
      : 0;
  }

  private async stepSelected(step: OrderLineStepDisplayModel): Promise<void> {
    this.showInfo = true;
    if (this.showInfo === true && this.isParallelSupplyChainAdded === true) {
      this.showParallelChain = true;
    } else {
      this.showParallelChain = false;
    }
    this.selectedStep = step;
    this.orderLineData.stepStatus = step.status;
    this.orderLineData.scpCompanyName = this.companyNameList.filter(c => c.ID === step.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === step.toCompanyId)[0].name : step.toCompanyName;
    this.orderLineData.evidenceCreatedBy = '';

    if (step.evidencePhotos.length > 0) {
      this.orderLineData.evidenceCreatedAt = step.evidencePhotos[0].createdAt;
    }

    if (
      this.checkTier1StatusForOrder() ||
      step.toCompanyId === this.companyId
    ) {
      await Promise.all([this.getUserFromEvidenceAsync()]);
    }
  }

  private showNextPicture(): void {
    const maxIndex = this.orderLineInfo.productPictures!.length - 1;
    if (this.pictureIndex === maxIndex) {
      this.pictureIndex = 0;
    } else {
      this.pictureIndex++;
    }
  }

  private showPreviousPicture(): void {
    const maxIndex = this.orderLineInfo.productPictures!.length - 1;
    if (this.pictureIndex === 0) {
      this.pictureIndex = maxIndex;
    } else {
      this.pictureIndex--;
    }
  }

  private checkTier1StatusForOrder(): boolean {
    return this.orderLine.toCompanyId === this.companyId;
  }

  private async getUserFromEvidenceAsync(): Promise<void> {
    if (
      this.selectedStep?.evidencePhotos.length > 0 &&
      (this.orderLineInfo.evidenceCreatedBy == null ||
        this.orderLineInfo.evidenceCreatedBy === '')
    ) {
      const user = await this.userService.getUserInfoByIdAsync(
        this.selectedStep.evidencePhotos[0].createdBy!
      );
      this.orderLineData.evidenceCreatedBy = `${user.firstName} ${user.lastName}`;
    }
    return Promise.resolve();
  }

  private async getSupplierNameAsync(): Promise<void> {
    if (
      this.orderLineData.scpCompanyName == null ||
      (this.orderLineData.scpCompanyName === '' &&
        this.selectedStep.toCompanyId != Guid.Empty)
    ) {
      const supplier = await this.supplierService.getSupplierNameByIdAsync(
        this.selectedStep.toCompanyId
      );
      this.orderLineData.scpCompanyName = supplier;
    }
    return Promise.resolve();
  }

  private async openAddStepModal(order: OrderLineDisplayModel): Promise<void> {
    if(order.footprintFlag === 'TRUE'){
        this.showNotAllowEdit = true;
    }else{
        this.showAddStepModal = true;
    }
  }

  private async closeAddStepModal(
    edited: boolean,
    orderLineSteps: OrderLineStepModel[]
  ): Promise<void> {
    this.showAddStepModal = false;
    if (edited) {
      this.stepEdited = true;
      this.stepsLoading = true;
      const steps: ConfirmStepBlockchainModel[] = [];
      orderLineSteps.forEach(step => {
        const line: ConfirmStepBlockchainModel = new ConfirmStepBlockchainModel();
        line.ID = step.id;
        line.orderID = step.orderId;
        line.orderNumber = step.orderNumber;
        line.orderLineID = step.orderLineId;
        line.stepID = step.stepId;
        line.productGroup = step.productGroup;
        line.displayName = step.displayName;
        line.sequence = step.sequence;
        line.toCompanyID = step.toCompanyId;
        line.toCompanyName = step.toCompanyName;
        line.fromCompanyID = step.fromCompanyId;
        line.status = step.status;
        line.showDetails = step.showDetails;
        line.showNewsLetter = step.showNewsLetter;
        line.showFactoryAddress = step.showFactoryAddress;
        line.showProfile = step.showProfile;
        line.showDetailsCount = step.showDetailsCount;
        line.rejectionReason = step.rejectionReason;
        line.stepState = step.stepState;
        line.evidencePhotos = step.evidencePhotos;
        line.validSocialCertificates = step.validSocialCertificates;
        line.validSustainabilityCertificates = step.validSustainabilityCertificates;
        line.compliantAtVerificationDate = step.compliantAtVerificationDate;
        line.currentlyValidCertificates = step.currentlyValidCertificates;
        line.supplierStep = step.supplierStep;
        line.clientID = step.clientID;
        line.cpSupplierID = step.cpSupplierID;
        line.approvalRequestedTo = step.approvalRequestedTo;
        line.stepHistory = step.stepHistory.map(hist => new StepHistoryBlockchainModel(hist));
        line.isLastStep = step.isLastStep;
        // TTD-3619 ref fields are added
        line.footprintRefNo = step.footprintRefNo;
        line.footprintRefID = step.footprintRefID;
        line.footprintRefNoUpdatedBy = step.footprintRefNoUpdatedBy;
        line.parallelChainCount = step.parallelChainCount;
        line.parallelSupplyChain0 = step.parallelSupplyChain0.map(chain0 => new ParallelStepBlockchainModel(chain0, step.stepId.toString(), this.orderLineData));
        line.parallelSupplyChain1 = step.parallelSupplyChain1.map(chain1 => new ParallelStepBlockchainModel(chain1, step.stepId.toString(), this.orderLineData));
        line.parallelSupplyChain2 = step.parallelSupplyChain2.map(chain2 => new ParallelStepBlockchainModel(chain2, step.stepId.toString(), this.orderLineData));
        line.parallelSupplyChain3 = step.parallelSupplyChain3.map(chain3 => new ParallelStepBlockchainModel(chain3, step.stepId.toString(), this.orderLineData));
        line.parallelSupplyChain4 = step.parallelSupplyChain4.map(chain4 => new ParallelStepBlockchainModel(chain4, step.stepId.toString(), this.orderLineData));
        steps.push(line);
      })
      const updatedSteps = await GroupStepEvidenceHelper.updateApproveStepEvidece(steps, 'EDIT', false);
      await this.orderService.updateOrderStepsBlockchainAsync(updatedSteps, 'EDIT');
      this.$store.dispatch(ActionTypes.SET_ORDER_LINE_EVIDENCE_COUNT);
      this.getOrderLineSteps();
    }
  }
}
