import { OrderLineStepParallelModel } from './orderLineStepParallelModel';
import { OrderLineStepProof } from './orderLineStepProof';
import { OrderLineStepStatus } from './orderLineStepStatus';
import { ProductGroup } from './productGroup';
import { StepState } from './stepState';

export class OrderLineStepDisplayModel {
  public id!: string;
  public orderId!: string;
  public orderLineId!: string;
  public orderNumber!: string;
  public stepId!: number;
  public displayName!: string;
  public productGroup!: ProductGroup;
  public sequence!: number;
  public status!: OrderLineStepStatus;
  public toCompanyId!: string;
  public toCompanyName!: string;
  public fromCompanyId!: string;
  public clientID!: string;
  public cpSupplierID!: string;
  public approvalRequestedTo!: string;
  public evidencePhotos: OrderLineStepProof[] = [];
  public parallelSupplyChain0!: OrderLineStepParallelModel[];
  public parallelSupplyChain1!: OrderLineStepParallelModel[];
  public parallelSupplyChain2!: OrderLineStepParallelModel[];
  public parallelSupplyChain3!: OrderLineStepParallelModel[];
  public parallelSupplyChain4!: OrderLineStepParallelModel[];
  // Sprint 20, TTD-3932
  public parallelChainCount!: number;
  public footprintRefNo!: string;
  public footprintRefID!: string;
  public footprintRefNoUpdatedBy!: string;
  public stepState!: StepState;
  // Sprint 20, TTD-3940
  public footprintRefNoPrefix?: string;
}

export class FootprintPartnerModel {
  public id!: string;
  public orderId!: string;
  public orderLineId!: string;
  public orderNumber!: string;
  public stepId!: number;
  public displayName!: string;
  public status!: OrderLineStepStatus;
  public toCompanyId!: string;
  public toCompanyName!: string;
  public fromCompanyID!: string;
  public stepState!: StepState;
  public agentID!: string;
  public delegatedCompanyID!: string;
  public sequence!: number;
}

// TTD-3934
export class FootprintPartnerResponseModel {
  public success!: boolean;
  public orderLineSteps!: FootprintPartnerModel[];
}