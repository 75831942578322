export class EsgSocialModel {
    public ID!: string;
    public reminderSentDate!: string;
    public addressStatus!: string;
    public companyID!: string;
    public addressID!: string;
    public status!: string;
    public createdAt!: string;
    public updatedAt!: string;
    public socialAuditStatus!: string;
    public socialAuditExpanded!: boolean;
    public sdsaQ01!: EsgTextAnsModel; // need to check in BE what key is set
    public socialCert!: EsgSocialAuditCertModel[];
    public sdsaQ02!: EsgTextAnsModel;
    public sdsaQ03!: EsgTextAnsModel;
    public sdsaQ03Docs!: EsgDocsModel[];
    public sdsaQ04!: EsgTextAnsModel;
    public sdsaQ05!: EsgIdTextAnsModel[];
    public sdsaQ06!: EsgTextAnsModel;
    public sdsaQ06Docs!: EsgDocsModel[];
    public healthAndSafetyStatus!: string;
    public healthAndSafetyExpanded!: boolean;
    public sdhsQ01!: EsgTextAnsModel;
    public sdhsQ02!: EsgDocsModel[];
    public sdhsQ03!: EsgTextAnsModel;
    public sdhsQ04!: EsgDocsModel[];
    public sdhsQ05!: EsgTextAnsModel;
    public sdhsQ06!: EsgTextAnsModel;
    public diversityAndEqualOptyStatus!: string;
    public diversityAndEqualOptyExpanded!: boolean;
    public sddeoQ01!: EsgTextAnsModel;
    public sddeoQ01Docs!: EsgDocsModel[];
    public sddeoQ02!: EsgTextAnsModel;
    public sddeoQ03!: EsgDocsModel[];
    public sddeoQ04!: EsgEmployeeModel;
    public sddeoQ05!: EsgNumberAnsModel;
    public sddeoQ06!: EsgNumberAnsModel;
    public sddeoQ07!: EsgTextAnsModel;
    public livingWageWorkingHoursStatus!: string;
    public livingWageWorkingHoursExpanded!: boolean;
    public sdlwwQ01!: EsgTextAnsModel;
    public sdlwwQ01Docs!: EsgDocsModel[];
    public sdlwwQ02!: EsgTextAnsModel;
    public sdlwwQ03!: EsgDocsModel[];
    public sdlwwQ04!: EsgTextAnsModel;
    public sdlwwQ05!: EsgTextAnsModel;
    public sdlwwQ06!: EsgTextAnsModel;
    public sdlwwQ07!: EsgTextAnsModel;
    public sdlwwQ08!: EsgTextAnsModel;
    public childForcedLabourStatus!: string;
    public childForcedLabourExpanded!: boolean;
    public sdcflQ01!: EsgTextAnsModel;
    public sdcflQ01Docs!: EsgDocsModel[];
    public sdcflQ02!: EsgTextAnsModel;
    public sdcflQ03!: EsgDocsModel[];
    public sdcflQ04!: EsgTextAnsModel;
    public sdcflQ05!: EsgTextAnsModel;
    public sdcflQ06!: EsgTextAnsModel;
    public workingConditionsStatus!: string;
    public workingConditionsExpanded!: boolean;
    public sdwccQ01!: EsgTextAnsModel;
    public sdwccQ01Docs!: EsgDocsModel[];
    public sdwccQ02!: EsgTextAnsModel;
    public sdwccQ03!: EsgDocsModel[];
    public sdwccQ04!: EsgTextAnsModel;
    public sdwccQ05!: EsgNumberAnsModel;
    public sdwccQ06!: EsgNumberAnsModel;
    public sdwccQ07!: EsgTextAnsModel;
    public sdwccQ08!: EsgTextAnsModel;
    public discriminationStatus!: string;
    public discriminationExpanded!: boolean;
    public sddisQ01!: EsgTextAnsModel;
    public sddisQ01Docs!: EsgDocsModel[];
    public sddisQ02!: EsgTextAnsModel;
    public sddisQ03!: EsgDocsModel[];
    public sexualHarasmentStatus!: string;
    public sexualHarasmentExpanded!: boolean;
    public sdshmQ01!: EsgTextAnsModel;
    public sdshmQ01Docs!: EsgDocsModel[];
    public sdshmQ02!: EsgTextAnsModel;
    public sdshmQ03!: EsgDocsModel[];
    public grievanceMechanismStatus!: string;
    public grievanceMechanismExpanded!: boolean;
    public sdgrmQ01!: EsgTextAnsModel;
    public sdgrmQ02!: EsgTextAnsModel; // need to check in BE what key is set
    public sdgrmQ03!: EsgTextAnsModel; // need to check in BE what key is set
    public sdgrmQ04!: EsgTextAnsModel;
    public sdgrmQ05!: EsgTextAnsModel;
    public sdLastUpdatedAt!: string;

    public constructor(){
        this.ID = '';
        this.reminderSentDate = '';
        this.addressStatus = '';
        this.companyID = '';
        this.addressID = '';
        this.status = '';
        this.createdAt = '0001-01-01T00:00:00Z';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.socialAuditStatus = '';
        this.socialAuditExpanded = true;
        this.sdsaQ01 = new EsgTextAnsModel();
        this.socialCert = [];
        this.sdsaQ02 = new EsgTextAnsModel();
        this.sdsaQ03 = new EsgTextAnsModel();
        this.sdsaQ03Docs = [];
        this.sdsaQ04 = new EsgTextAnsModel();
        this.sdsaQ05 = [];
        this.sdsaQ06 = new EsgTextAnsModel();
        this.sdsaQ06Docs = [];
        this.healthAndSafetyStatus = '';
        this.healthAndSafetyExpanded = false;
        this.sdhsQ01 = new EsgTextAnsModel();
        this.sdhsQ02 = [];
        this.sdhsQ03 = new EsgTextAnsModel();
        this.sdhsQ04 = [];
        this.sdhsQ05 = new EsgTextAnsModel();
        this.sdhsQ06 = new EsgTextAnsModel();
        this.diversityAndEqualOptyStatus = '';
        this.diversityAndEqualOptyExpanded = false;
        this.sddeoQ01 = new EsgTextAnsModel();
        this.sddeoQ01Docs = [];
        this.sddeoQ02 = new EsgTextAnsModel();
        this.sddeoQ03 = [];
        this.sddeoQ04 = new EsgEmployeeModel();
        this.sddeoQ05 = new EsgNumberAnsModel();
        this.sddeoQ06 = new EsgNumberAnsModel();
        this.sddeoQ07 = new EsgTextAnsModel();
        this.livingWageWorkingHoursStatus = '';
        this.livingWageWorkingHoursExpanded = false;
        this.sdlwwQ01 = new EsgTextAnsModel();
        this.sdlwwQ01Docs = [];
        this.sdlwwQ02 = new EsgTextAnsModel();
        this.sdlwwQ03 = [];
        this.sdlwwQ04 = new EsgTextAnsModel();
        this.sdlwwQ05 = new EsgTextAnsModel();
        this.sdlwwQ06 = new EsgTextAnsModel();
        this.sdlwwQ07 = new EsgTextAnsModel();
        this.sdlwwQ08 = new EsgTextAnsModel();
        this.childForcedLabourStatus = '';
        this.childForcedLabourExpanded = false;
        this.sdcflQ01 = new EsgTextAnsModel();
        this.sdcflQ01Docs = [];
        this.sdcflQ02 = new EsgTextAnsModel();
        this.sdcflQ03 = [];
        this.sdcflQ04 = new EsgTextAnsModel();
        this.sdcflQ05 = new EsgTextAnsModel();
        this.sdcflQ06 = new EsgTextAnsModel();
        this.workingConditionsStatus = '';
        this.workingConditionsExpanded = false;
        this.sdwccQ01 = new EsgTextAnsModel();
        this.sdwccQ01Docs = [];
        this.sdwccQ02 = new EsgTextAnsModel();
        this.sdwccQ03 = [];
        this.sdwccQ04 = new EsgTextAnsModel();
        this.sdwccQ05 = new EsgNumberAnsModel();
        this.sdwccQ06 = new EsgNumberAnsModel();
        this.sdwccQ07 = new EsgTextAnsModel();
        this.sdwccQ08 = new EsgTextAnsModel();
        this.discriminationStatus = '';
        this.discriminationExpanded = false;
        this.sddisQ01 = new EsgTextAnsModel();
        this.sddisQ01Docs = [];
        this.sddisQ02 = new EsgTextAnsModel();
        this.sddisQ03 = [];
        this.sexualHarasmentStatus = '';
        this.sexualHarasmentExpanded = false;
        this.sdshmQ01 = new EsgTextAnsModel();
        this.sdshmQ01Docs = [];
        this.sdshmQ02 = new EsgTextAnsModel();
        this.sdshmQ03 = [];
        this.grievanceMechanismStatus = '';
        this.grievanceMechanismExpanded = false;
        this.sdgrmQ01 = new EsgTextAnsModel();
        this.sdgrmQ02 = new EsgTextAnsModel();
        this.sdgrmQ03 = new EsgTextAnsModel();
        this.sdgrmQ04 = new EsgTextAnsModel();
        this.sdgrmQ05 = new EsgTextAnsModel();
        this.sdLastUpdatedAt = '';
    }
}

export class EsgEnvModel {
    public ID!: string;
    public reminderSentDate!: string;
    public addressStatus!: string;
    public companyID!: string;
    public addressID!: string;
    public status!: string;
    public createdAt!: string;
    public updatedAt!: string;
    public energyUseStatus!: string;
    public energyUseExpanded!: boolean;
    public edeguQ01!: EsgNumberAnsModel; // need to check in BE what key is set
    public edeguQ02!: EsgNumberAnsModel;
    public edeguQ03!: EsgNumberAnsModel;
    public edeguQ04!: EsgNumberAnsModel;
    public edeguQ05!: EsgEnergySourceModel[];
    public edeguQ06!: EsgDocsModel[];
    public waterUseStatus!: string;
    public waterUseExpanded!: boolean;
    public edwtuQ01!: EsgNumberAnsModel;
    public edwtuQ02!: EsgNumberAnsModel;
    public edwtuQ03!: EsgTextAnsModel;
    public edwtuQ04!: EsgTextAnsModel[];
    public edwtuQ05!: EsgDocsModel[];
    public edwtuQ06!: EsgTextAnsModel;
    public edwtuQ07!: EsgNumberAnsModel;
    public edwtuQ08!: EsgTextAnsModel[];
    public wastageStatus!: string;
    public wastageExpanded!: boolean;
    public edwasQ01!: EsgTextAnsModel;
    public edwasQ02!: EsgNumberAnsModel;
    public edwasQ03!: EsgTextAnsModel;
    public edwasQ03Docs!: EsgDocsModel[];
    public circularEconomyStatus!: string;
    public circularEconomyExpanded!: boolean;
    public edcleQ01!: EsgTextAnsModel;
    public edcleQ02!: EsgTextAnsModel;
    public edcleQ03!: EsgTextAnsModel;
    public edcleQ04!: EsgNumberAnsModel;
    public edcleQ05!: EsgNumberAnsModel;
    public edcleQ06!: EsgNumberAnsModel;
    public edcleQ07!: EsgNumberAnsModel;
    public edcleQ08!: EsgDocsModel[];
    public carbonEmissionStatus!: string;
    public carbonEmissionExpanded!: boolean;
    public edcbeQ01!: EsgTextAnsModel;
    public edcbeQ02!: EsgNumberAnsModel;
    public edcbeQ03!: EsgTextAnsModel;
    public edcbeQ04!: EsgNumberAnsModel;
    public edcbeQ05!: EsgTextAnsModel;
    public edcbeQ06!: EsgNumberAnsModel;
    public edcbeQ07!: EsgDocsModel[];
    public edLastUpdatedAt!: string;

    public constructor(){
        this.ID = '';
        this.reminderSentDate = '';
        this.addressStatus = '';
        this.companyID = '';
        this.addressID = '';
        this.status = '';
        this.createdAt = '0001-01-01T00:00:00Z';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.energyUseStatus = '';
        this.energyUseExpanded = false;
        this.edeguQ01 = new EsgNumberAnsModel();
        this.edeguQ02 = new EsgNumberAnsModel();
        this.edeguQ03 = new EsgNumberAnsModel();
        this.edeguQ04 = new EsgNumberAnsModel();
        this.edeguQ05 = [new EsgEnergySourceModel];
        this.edeguQ06 = [];
        this.waterUseStatus = '';
        this.waterUseExpanded = false;
        this.edwtuQ01 = new EsgNumberAnsModel();
        this.edwtuQ02 = new EsgNumberAnsModel();
        this.edwtuQ03 = new EsgTextAnsModel();
        this.edwtuQ04 = [];
        this.edwtuQ05 = [];
        this.edwtuQ06 = new EsgTextAnsModel();
        this.edwtuQ07 = new EsgNumberAnsModel();
        this.edwtuQ08 = [];
        this.wastageStatus = '';
        this.wastageExpanded = false;
        this.edwasQ01 = new EsgTextAnsModel();
        this.edwasQ02 = new EsgNumberAnsModel();
        this.edwasQ03 = new EsgTextAnsModel();
        this.edwasQ03Docs = [];
        this.circularEconomyStatus = '';
        this.circularEconomyExpanded = false;
        this.edcleQ01 = new EsgTextAnsModel();
        this.edcleQ02 = new EsgTextAnsModel();
        this.edcleQ03 = new EsgTextAnsModel();
        this.edcleQ04 = new EsgNumberAnsModel();
        this.edcleQ05 = new EsgNumberAnsModel();
        this.edcleQ06 = new EsgNumberAnsModel();
        this.edcleQ07 = new EsgNumberAnsModel();
        this.edcleQ08 = [];
        this.carbonEmissionStatus = '';
        this.carbonEmissionExpanded = false;
        this.edcbeQ01 = new EsgTextAnsModel();
        this.edcbeQ02 = new EsgNumberAnsModel();
        this.edcbeQ03 = new EsgTextAnsModel();
        this.edcbeQ04 = new EsgNumberAnsModel();
        this.edcbeQ05 = new EsgTextAnsModel();
        this.edcbeQ06 = new EsgNumberAnsModel();
        this.edcbeQ07 = [];
    }
}

export class EsgSocialRespModel {
    public success!: boolean;
    public socialData!: EsgSocialModel[];
}

export class EsgNumberAnsModel {
    public value!: number | string;
    public updatedAt!: string;
    public updatedBy!: string;
    public constructor(){
        this.value = '';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.updatedBy = '';
    }
}

export class EsgTextAnsModel {
    public value!: string;
    public updatedAt!: string;
    public updatedBy!: string;
    public constructor(){
        this.value = '';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.updatedBy = '';
    }
}

export class EsgIdTextAnsModel {
    public ID!: string;
    public text!: string;
    public updatedAt!: string;
    public updatedBy!: string;
    public index!: number;
    public isEdited!: boolean;
    public constructor(){
        this.ID = '';
        this.text = '';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.updatedBy = '';
        this.index = -1;
        this.isEdited = false;
    }
}

export class EsgSocialAuditCertModel {
    public ID!: string;
    public type!: string;
    public updatedAt!: string;
    public updatedBy!: string;
    public constructor(){
        this.ID = '';
        this.type = '';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.updatedBy = '';
    }
}

export class EsgDocsModel {
    public ID!: string;
    public fileType!: string;
    public fileHash!: string;
    public fileName!: string;
    public docUrl!: string;
    public geoLat!: number;
    public geoLong!: number;
    public createdAt!: string;
    public uploadedBy!: string;
    public constructor(){
        this.ID = '';
        this.fileType = '';
        this.fileHash = '';
        this.fileName = '';
        this.docUrl = '';
        this.geoLat = 0;
        this.geoLong = 0;
        this.createdAt = '0001-01-01T00:00:00Z';
        this.uploadedBy = '';
    }
}

export class EsgEmployeeModel {
    public totalEmployees!: EsgNumberAnsModel;
    public femaleEmployees!: EsgNumberAnsModel;
    public maleEmployees!: EsgNumberAnsModel;
    public constructor(){
        this.totalEmployees = new EsgNumberAnsModel();
        this.femaleEmployees = new EsgNumberAnsModel();
        this.maleEmployees = new EsgNumberAnsModel();
    }
}

export class EsgAddressStatus {
    public addressID!: string;
    public status!: string;
    public isSelected!: boolean;
    public constructor(addID: string, status: string){
        this.addressID = addID;
        this.status = status;
        this.isSelected = false;
    }
}

export class EsgSocialAuditCert {
    public certificates!: EsgAuditCertificate[];
    public createdAt!: string;
    public createdBy!: string;
    public addressInfoForFacility!: EsgFacilityAddressInfo[];
}

export class EsgAuditCertificate {
    public complianceDocID!: string;
    public type!: string;
}

export class EsgFacilityAddressInfo {
    public ID!: string;
    public addressID!: string;
}

export class EsgSocialAuditCertResp {
    public success!: boolean;
}

export class EsgSocialDocUrl {
    public companyID!: string;
    public socialDataDocTypeID!: string;
    public fileType!: string;
    public fileName!: string;
    public fileHash!: string;
    public originalDocURL!: string;
    public docUrl!: string;
    public createdAt!: string;
    public uploadedBy!: string;
    public actualOwnerID!: string;
}

export class EsgSocialDocUrlReq {
    public ID!: string;
    public companyID!: string;
    public socialDataDocType!: string;
    public socialDataDocTypeID!: string;
}

export class EsgSocialDocUrlResp {
    public success!: boolean;
    public socialDoc!: EsgSocialDocUrl[];
}

export class EsgUploadDocModel {
    public socialDataDocType!: string;
    public ID!: string;
    public fileType!: string;
    public fileHash!: string;
    public fileName!: string;
    public docUrl!: string;
    public geoLat!: number;
    public geoLong!: number;
    public createdAt!: string;
    public uploadedBy!: string;
    public addressInfoForFacility!: EsgFacilityAddressInfo[];
}

export class EsgUploadDocResponse {
    public uploadedBy!: string;
    public docUrl!: string;
    public fileName!: string;
    public fileHash!: string;
    public originalDocURL!: string;
    public type!: string;
    public ID!: string;
    public fileType!: string;
    public geoLong!: number;
    public geoLat!: number;
    public createdAt!: string;
}

export class EsgUploadDocRespModel {
    public success!: boolean;
    public docs!: EsgUploadDocResponse[];
}

export class EsgWaterTrtmntTypeModel {
    public title!: string;
    public isSelected!: boolean;
    public constructor(){
        this.title = '';
        this.isSelected = false;
    }
}

export class EsgWaterTreatmentModel {
    public value!: string;
    public title!: string;
    public updatedAt!: string;
    public updatedBy!: string;
    public constructor(){
        this.value = '';
        this.title = '';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.updatedBy = '';
    }
}

export class EsgEnvRespModel {
    public success!: boolean;
    public envData!: EsgEnvModel[];
}

export class EsgEnergySourceModel {
    public value!: number | string;
    public source!: string;
    public updatedAt!: string;
    public updatedBy!: string;
    public constructor(){
        this.value = '';
        this.source = '';
        this.updatedAt = '0001-01-01T00:00:00Z';
        this.updatedBy = '';
    }
}

export class EsgEnvDocUrlReq {
    public ID!: string;
    public companyID!: string;
    public envDataDocType!: string;
    public envDataDocTypeID!: string;
}

export class EsgEnvDocUrlResp {
    public success!: boolean;
    public envDoc!: EsgEnvDocUrl[];
}

export class EsgEnvDocUrl {
    public companyID!: string;
    public envDataDocTypeID!: string;
    public fileType!: string;
    public fileName!: string;
    public fileHash!: string;
    public originalDocURL!: string;
    public docUrl!: string;
    public createdAt!: string;
    public uploadedBy!: string;
    public actualOwnerID!: string;
}

export enum EsgStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    INPROGRESS = 'INPROGRESS',
    COMPLETED = 'COMPLETED',
    YES = 'YES',
    NO = 'NO',
    NA = 'NA',
}
