export class CreateSupplierModel {
  public CompanyName!: string;
  public CompanyType!: number;
  public CompanyRegistrationNumber!: string;
  public MainContactEmailAddress!: string;
  public City!: string;
  public Country!: string;
  public CompanyWebsite!: string;
  public MainContactName!: string;
  public MainContactPhone!: string;
}
