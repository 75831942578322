import { Vue, Component, Watch } from 'vue-property-decorator';
import { ActionTypes } from '@/store/actions/actions';
import { mapGetters } from 'vuex';

// Services
import { OrderService } from '@/services/orderService';

// Components
import UploadResubmitEvidence from '@/views/desktopEvidence/components/uploadResubmitEvidence.vue';
import ApproveRejectEvidence from '@/views/desktopEvidence/components/approveRejectEvidence.vue';
import ArchiveEvidence from '@/views/desktopEvidence/components/archiveEvidence.vue';

// models
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';

@Component({
  components: { UploadResubmitEvidence, ApproveRejectEvidence, ArchiveEvidence },
  computed: {
    ...mapGetters(['orderLineEvidenceStepsLoading',
      'orderLineEvidenceSteps', 'orderLineEvidenceApproveSteps']),
  },
})
export default class OrderLines extends Vue {

  private orderLineEvidenceStepsLoading!: boolean;

  private orderLineEvidenceSteps!: OrderLineEvidenceStep[];

  private orderLineEvidenceApproveSteps!: OrderLineEvidenceStep[];

  private orderService: OrderService;

  private tabIndex: number = 0;

  private steps: OrderLineEvidenceStep[] = [];

  private searchFilter: string = '';

  private reloadEvidence: boolean = false;

  public constructor() {
    super();
    this.orderService = new OrderService();
  }

  private async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    await this.initEvidence();
    this.selectATab(0);
  }

  private async initEvidence(): Promise<void> {
    // if ((this.orderLineEvidenceSteps.length === 0 && !this.orderLineEvidenceStepsLoading) || this.reloadEvidence) {
      await this.$store.dispatch(ActionTypes.SET_ORDER_LINE_EVIDENCE_STEPS);
      this.reloadEvidence = false;
    // }
  }

  private get pending(): number {
    return OrderLineStepStatus.Pending;
  }

  private get waitingApproval(): number {
    return OrderLineStepStatus.WaitingForApproval;
  }

  private get rejected(): number {
      return OrderLineStepStatus.Rejected;
  }

  private get pendingEvidenceAction(): boolean {
    return this.orderLineEvidenceSteps.filter(s => s.status === this.pending ||  s.status === this.rejected).length > 0 ? true : false;
  }

  private get approveEvidenceAction(): boolean {
    return this.orderLineEvidenceApproveSteps.filter(s => s.status === this.waitingApproval).length > 0 ? true : false;
  }

  private selectATab(index: number): void {
    this.tabIndex = index;
  }

  private async reload(): Promise<void> {
    this.reloadEvidence = true;
    await this.initEvidence();
  }

  private get approveRejectSteps(): number{
    return this.orderLineEvidenceApproveSteps.filter(
      (s) => s.status === OrderLineStepStatus.WaitingForApproval
    ).length;
  }

  private get uploadOrResubmitSteps(): number{
    return  this.orderLineEvidenceSteps.filter(
      (s) => s.status === OrderLineStepStatus.Pending
    ).length || this.orderLineEvidenceSteps.filter(
      (s) => s.status === OrderLineStepStatus.Rejected
    ).length;
  }

  private get archivedSteps(): number {
    return this.orderLineEvidenceSteps.filter((s) => s.status === OrderLineStepStatus.WaitingForApproval || s.status === OrderLineStepStatus.Accepted).length;
  }
}
