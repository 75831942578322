import moment from 'moment';
import { CompanyUserModel } from './companyUserModel';
import { OrderLineModel } from './orderLineModel';
import { OrderLineStepStatus } from './orderLineStepStatus';
import { ProductGroup } from './productGroup';
import { ProductPictureDisplayModel } from './productPictureDisplayModel';
import { ProductQualityReportModel } from './productQualityReportModel';
import { AgentCompanyUserModel } from "./agentCompanyUserModel";
import { DelegateCompanyUserModel } from './delegateCompanyUserModel';
import { YarnDataModel } from "./yarnFootprintModel";
import { WetProcessDataModel } from "./wetProcessFootprintModel";
import { FabricDataModel } from "./fabricFootprintModel";
import { GarmentDataModel } from "./garmentFootprintModel";
import {ActionOnFootprintModel } from './actionOnFootprintModel'

export class OrderLineDisplayModel {
  public id!: string;
  public orderId!: string;
  public orderNumber!: string;
  public toCompanyName!: string;
  public toCompanyId!: string;
  public fromCompanyId!: string;
  public fromCompanyName!: string;
  public productGroup!: ProductGroup;
  public styleNumber!: string;
  public styleName!: string;
  public brandName!: string;
  public expectedTimeOfDelivery!: moment.Moment;
  public scpCompanyName: string = '';
  public productPictures?: ProductPictureDisplayModel[];
  public productQualityReport!: ProductQualityReportModel[];
  public agentCompanyUser!: AgentCompanyUserModel;
  public certificate?: string;
  public evidenceCreatedBy?: string = '';
  public evidenceCreatedAt?: moment.Moment;
  public stepStatus: OrderLineStepStatus = OrderLineStepStatus.None;
  public show: boolean = true;
  public orderLineStatus!: number;
  public orderLinePhase!: number;
  public fromCompanyUser!: CompanyUserModel;
  public tier1NotifiedDate!: string;
  public orderRefId!: string;
  public createdAt!: moment.Moment;
  public orderLines!: OrderLineModel[];
  public agentCompanyID!: string;
  public delegateCompanyUser!: DelegateCompanyUserModel;
  public delegateCompanyID!: string;
  public delegatedAt!: moment.Moment;
  //TTD-2736 sprint-19 development
  public footprintFlag!: string;
  public yarn!: YarnDataModel;
  public wetprocess!: WetProcessDataModel;
  public fabric!: FabricDataModel;
  public garment!: GarmentDataModel;
  public finalFootprintStatus!: string;
  // Sprint 20, TTD-3932
  public footprintDeadline!: string;
  public footprintFilledByCompanyID!: string;
  //Sprint-21 Sprint-21 TTD-3739
  public actionOnFootprint!: ActionOnFootprintModel;
   // Sprint-24 TTD-116
   public tranCertificate1!: string;
   public tranCertificate2!: string;
   public levelTC1!: string;
   public levelTC2!: string;
   public license!: string;
   public deliveryTerm!: string;
   public tc1Status!: string;
   public tc2Status!: string;
   public tranCertificate1RefId!: string;
   public tranCertificate2RefId!: string; 
   public customer!: string;
   public tc1Type!: string;
   public tc2Type!: string;
   public tcReferenceNumber!: string;
   public tcReferenceNumber1!: string;
   public rejectionReason!: string;
}

export class OrderIdListModel {
  public orderIdList!: string[];
}
