import moment from 'moment';
import { FileModel } from './fileModel';
import { UploadedPictureResponseModel } from './selectPictureModel';

export class CompanyPictureModel extends FileModel {
  public addressID!: string;
  public sequence!: number;
  public contentType!: Blob;
  public size!: number;
  public progress!: number;
  public uploadedPicture!: UploadedPictureResponseModel;
  public createdBy!: string;

  public constructor() {
    super('', '', '', '', moment(), 0, 0, false);
  }

  public setFileData(file: FileModel): void {
    this.id = file.id;
    this.content = file.content;
    this.fileName = file.fileName;
    this.geoLat = file.geoLat;
    this.geoLong = file.geoLong;
    this.documentType = file.documentType;
  }
}

export class PictureModel {
  public addressID!: string;
  public fileHash!: string;
  public docURL!: string;
  public createdAt!: string;
  public createdBy!: string;
  public fileType!: string;
  public fileName!: string;
  public geoLat!: number;
  public geoLong!: number;
  public id!: string;
  public isMainPicture!: boolean
  public sequence!: number;
  public owner!: string;

  public constructor() {}
}

export class CompanyPictureReqModel {
  public profilePics!: PictureModel[];
}
