import { AddressModel } from '@/models/addressModel';
import { GeolocationModel } from '@/models/geolocationModel';
import { GeolocationClient } from '@/clients/geolocationClient';
import { MapboxTokenModel } from '@/models/mapboxTokenModel';

export class GeolocationService {
  private readonly client: GeolocationClient = new GeolocationClient();

  public async getCoordinatesForAddressAsync(
    address: AddressModel
  ): Promise<GeolocationModel[]> {
    const result = await this.client.getCoordinatesForAddressAsync(address);
    return result;
  }

  public async getMapboxToken(): Promise<MapboxTokenModel> {
    const result = await this.client.getMapboxToken();
    return result;
  }

  public async getCoordinates(token: string, query: string): Promise<any> {
    const result = await this.client.getCoordinates(token, query);
    return result;
  }
}
