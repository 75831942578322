import { ApplicationConfig } from '@/config';
import {
  ComplianceResponseModel,
  ComplianceResponseSingleDocModel,
} from '@/models/complianceResponseModel';
import { GenericResponse } from '@/models/genericResponse';
import { BaseClientBlockchain } from './baseClientBlockchain';
import { TCDocumentModel } from '@/models/transcationCertificateModal';

export class ComplianceClient extends BaseClientBlockchain {
  private readonly endPoint: string = 'v1/api';

  public constructor() {
    super(ApplicationConfig.endpoints.blockchainApi);
  }

  public async getDocumentsAsync(): Promise<ComplianceResponseModel> {
    return await this.get<ComplianceResponseModel>(
      `${this.endPoint}/complianceDoc/suppliers`
    );
  }

  public async getDocumentDetailsAsync(
    companyId: string,
    documentId: string
  ): Promise<ComplianceResponseSingleDocModel> {
    return await this.get<ComplianceResponseSingleDocModel>(
      `${this.endPoint}/supplier/${companyId}/complianceDoc/${documentId}/get`
    );
  }

  public async signDocumentAsync(id: string): Promise<GenericResponse> {
    return await this.patchWithResponse<GenericResponse>(
      `${this.endPoint}/supplier/complianceDoc/${id}/sign`,
      '', false
    );
  }

  public async readDocumentAsync(id: string): Promise<GenericResponse> {
    return await this.patchWithResponse<GenericResponse>(
      `${this.endPoint}/supplier/complianceDoc/${id}/read`,
      '', false
    );
  }

  public async postCommentAsync(
    companyId: string,
    data: object
  ): Promise<Response> {
    return await this.patch(
      `${this.endPoint}/supplier/${companyId}/complianceDoc/comment`,
      data
    );
  }

  public async editCommentAsync(
    companyId: string,
    data: object
  ): Promise<Response> {
    return await this.patch(
      `${this.endPoint}/supplier/${companyId}/complianceDoc/editComment`,
      data
    );
  }

  public async deleteCommentAsync(
    companyId: string,
    data: object
  ): Promise<Response> {
    return await this.patch(
      `${this.endPoint}/supplier/${companyId}/complianceDoc/deleteComment`,
      data
    );
  }

 // Sprint-24 TTD-4485
  public async getViewTCDocument(
    tcRefID: string
  ): Promise<TCDocumentModel> {
    return await this.get(
      `${this.endPoint}/transaction-certificate/${tcRefID}/getTranCertificate`
    );
  }
}
