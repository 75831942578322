export class CertificateUploadModel {
  public upload!: any;
  public description!: string;
  public geoLat!: number;
  public geoLong!: number;
  public issuer!: string;
  public referenceIssuer!: string;
  public rspHolder!: string;
  public amforiId!: string;
  public amforiSiteId!: string;
  public oekoclass!: string;
  public auditResult!: string;
  public validtoDate!: string;
  public validfromDate!: string;
  public type!: number;
}
