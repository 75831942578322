export class CountryStateCityClient {
  private readonly apiKey: string =
    'cFd2YUdlS0lmVkhRSzRtMUMxaFdtRWRBaUN5VGlrZm84U3dnVmxNcQ==';
  private readonly apiEndPoint: string = 'https://api.countrystatecity.in/v1';

  public async getAllCountries(): Promise<any> {
    const url = `${this.apiEndPoint}/countries`;
    const request: RequestInit = {
      headers: {
        'X-CSCAPI-KEY': this.apiKey,
        'Content-type': 'application/json; charset=UTF-8',
      },
      method: 'GET',
    };

    const timeout = 4000;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(url, {
      ...request,
      signal: controller.signal,
    });
    clearTimeout(id);

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        return [];
    }
  }

  public async getStatesOfCountry(ciso: string): Promise<any> {
    const url = `${this.apiEndPoint}/countries/${ciso}/states`;
    const request: RequestInit = {
      headers: {
        'X-CSCAPI-KEY': this.apiKey,
        'Content-type': 'application/json; charset=UTF-8',
      },
      method: 'GET',
    };

    const timeout = 4000;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(url, {
      ...request,
      signal: controller.signal,
    });
    clearTimeout(id);

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        return [];
    }
  }

  public async getCitiesOfState(ciso: string, siso: string): Promise<any> {
    const url = `${this.apiEndPoint}/countries/${ciso}/states/${siso}/cities`;
    const request: RequestInit = {
      headers: {
        'X-CSCAPI-KEY': this.apiKey,
        'Content-type': 'application/json; charset=UTF-8',
      },
      method: 'GET',
    };

    const timeout = 4000;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(url, {
      ...request,
      signal: controller.signal,
    });
    clearTimeout(id);

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        return [];
    }
  }
}
