import { Guid } from './guid';
import { OrderLineStepStatus } from './orderLineStepStatus';

export class stepListModel {
  public ID!: string;
  public constructor() {
    this.ID = Guid.Empty;
  }
}

export class orderLineStepIdModel {
  public stepsList!: stepListModel[];
  public constructor() {
    this.stepsList = [new stepListModel()];
  }
}

export class ApproveRejectModel {
  public orderLineStepID!: orderLineStepIdModel;
  public rejectionReason!: string;
  public status!: OrderLineStepStatus;
  public constructor() {
    this.orderLineStepID = new orderLineStepIdModel();
    this.rejectionReason = '';
    this.status = -1;
  }
}
