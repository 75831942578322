import { DropdownModel } from "@/models/dropdownModel";

export class EsgDropdownHelper {
    public static getProvideEnergyYearList(): DropdownModel[] {
        const yearList: DropdownModel[] = []
        let startyear = 2022;
        while (startyear <= new Date().getFullYear()) {
            yearList.push({ value: startyear, text: startyear.toString() });
            startyear++;
        }
        return yearList;
    }
}
