import { Vue, Component, Emit } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import { debounce } from 'ts-debounce';
import i18n from '@/i18n';

// Components
import Terms from '@/views/auth/signup/terms.vue';
import Policy from '@/views/auth/signup/policy.vue';
import Authpage from '@/components/general/authpage.vue';

// Models
import { CreateSupplierModel } from '@/models/createSupplierModel';

// Services
import { MiddlewareService } from '@/services/middlewareService';
import { NonRomanCharCheckHelper }from '@/helpers/nonRomanCharCheckHelper';

// Helpers
import { DropdownModel } from '@/models/dropdownModel';
import { CountryHelper } from '@/helpers/countryHelper';

@Component({
  components: { Authpage, 'terms-popup': Terms, 'policy-popup': Policy },
  directives: { ClickOutside },
})
export default class SignupForm extends Vue {
  private middlewareService: MiddlewareService;
  public constructor() {
    super();
    this.middlewareService = new MiddlewareService();
  }

  private isLoading: boolean = false;

  private companyTypeList: any = i18n.t('dropdown.supplier_company_type');

  private mainContactName: string = '';
  private mainContactNameError: boolean = false;

  private mainContactPhone: string = '';
  private mainContactPhoneError: boolean = false;

  private companyName: string = '';
  private companyNameError: boolean = false;

  private companyRegistrationNumber: string = '';
  private companyRegistrationNumberError: boolean = false;

  private companyType: string = '';
  private companyTypeID: number = 0;
  private companyTypeError: boolean = false;

  private companyWebsite: string = '';
  private companyWebsiteError: boolean = false;

  private city: string = '';
  private cityError: boolean = false;

  private country: string = '';
  private countryCode: string = '';
  private countryError: boolean = false;

  private formError: boolean = false;
  private allowSignup: boolean = false;
  private acceptedTerms: boolean = false;
  private acceptedPolicy: boolean = false;

  private companyDropdownShow: boolean = false;

  private countryList: DropdownModel[] = [];
  private countryDropdownShow: boolean = false;

  private showModalTerms: boolean = false;
  private showModalPolicy: boolean = false;

  private searchCountryDebounced: any;
  private searchCompanyTypeDebounced: any;

  private mainContactPhoneErrorMsg: string = '';
  private mainContactNameErrorMsg: string = '';
  private mainCompanyNameErrorMsg: string = '';
  private companyRegErrorMsg: string = '';
  private companyWebsiteErrorMsg: string = '';
  private companyTypeErrorMsg: string = '';
  private cityErrorMsg: string = '';
  private countryErrorMsg: string = '';

  private async created(): Promise<void> {
    this.countryList = CountryHelper.getCountryList();
    this.acceptedTerms = false;
    this.acceptedPolicy = false;
    this.searchCountryDebounced = debounce(this.searchCountry, 250, {
      isImmediate: true,
    });
    this.searchCompanyTypeDebounced = debounce(this.searchCompanyType, 250, {
      isImmediate: true,
    });
  }

  private scrollCompanyTypeFocus: any = null;
  private async searchCompanyType(event: any): Promise<void> {
    const filteredCompanyTypeList = this.companyTypeList.filter(
      (companytype: { value: string }) =>
        companytype.value.toLowerCase().includes(this.companyType.toLowerCase())
    );

    if (
      !this.companyDropdownShow &&
      (event.keyCode == 38 || event.keyCode == 40)
    ) {
      this.companyDropdownShow = true;
    }

    if (
      this.companyDropdownShow &&
      (event.keyCode == 38 || event.keyCode == 40 || event.keyCode == 13)
    ) {
      switch (event.keyCode) {
        case 38:
          if (this.scrollCompanyTypeFocus === null) {
            this.scrollCompanyTypeFocus = 0;
          } else if (this.scrollCompanyTypeFocus > 0) {
            this.scrollCompanyTypeFocus--;
          }
          break;
        case 40:
          if (this.scrollCompanyTypeFocus === null) {
            this.scrollCompanyTypeFocus = 0;
          } else if (
            this.scrollCompanyTypeFocus <
            filteredCompanyTypeList.length - 1
          ) {
            this.scrollCompanyTypeFocus++;
          }
          break;
        case 13:
          if (this.scrollCompanyTypeFocus !== null) {
            this.companyType =
              filteredCompanyTypeList[
                this.scrollCompanyTypeFocus
              ].value.toString();
            this.companyTypeID = parseInt(
              filteredCompanyTypeList[
                this.scrollCompanyTypeFocus
              ].key.toString()
            );
            this.companyDropdownShow = false;
          }
          this.checkFields('companyType');
          break;
      }
      if (this.scrollCompanyTypeFocus !== null) {
        setTimeout(
          (app: any) => {
            const listItem = app.$refs[
              'scrollCompanyTypeFocus' + app.scrollCompanyTypeFocus
            ] as Vue;
            Object.entries(listItem).forEach((item) => {
              item[1].scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
          },
          150,
          this
        );
      }
      return;
    }

    if (this.companyType.length > 0) {
      this.companyDropdownShow = true;
    } else {
      this.companyDropdownShow = false;
    }
  }

  private async hideCompanyDropdown(): Promise<void> {
    if (this.companyDropdownShow) {
      this.checkFields('companyType');
    }
    this.companyDropdownShow = false;
  }

  private scrollCountryFocus: any = null;
  private async searchCountry(event: any): Promise<void> {
    const filteredCountryList = this.countryList.filter((item) =>
      item.text.toLowerCase().includes(this.country.toLowerCase())
    );

    if (
      !this.countryDropdownShow &&
      (event.keyCode == 38 || event.keyCode == 40)
    ) {
      this.countryDropdownShow = true;
    }

    if (
      this.countryDropdownShow &&
      (event.keyCode == 38 || event.keyCode == 40 || event.keyCode == 13)
    ) {
      switch (event.keyCode) {
        case 38:
          if (this.scrollCountryFocus === null) {
            this.scrollCountryFocus = 0;
          } else if (this.scrollCountryFocus > 0) {
            this.scrollCountryFocus--;
          }
          break;
        case 40:
          if (this.scrollCountryFocus === null) {
            this.scrollCountryFocus = 0;
          } else if (this.scrollCountryFocus < filteredCountryList.length - 1) {
            this.scrollCountryFocus++;
          }
          break;
        case 13:
          if (this.scrollCountryFocus !== null) {
            this.country =
              filteredCountryList[this.scrollCountryFocus].text.toString();
            this.countryCode =
              filteredCountryList[this.scrollCountryFocus].value.toString();
            this.countryDropdownShow = false;
          }
          this.checkFields('country');
          break;
      }
      if (this.scrollCountryFocus !== null) {
        setTimeout(
          (app: any) => {
            const listItem = app.$refs[
              'scrollCountryFocus' + app.scrollCountryFocus
            ] as Vue;
            Object.entries(listItem).forEach((item) => {
              item[1].scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
          },
          150,
          this
        );
      }
      return;
    }
    if (this.country.length > 0 && filteredCountryList.length > 0) {
      this.countryDropdownShow = true;
      this.countryCode = '';
    } else {
      this.countryDropdownShow = false;
    }
  }

  private async hideCountryDropdown(): Promise<void> {
    if (this.countryDropdownShow) {
      const lError = await this.checkFields('country');
      if (lError) {
        return;
      }
    }
    this.countryDropdownShow = false;
  }

  @Emit()
  private closeModalTerms(): void {
    this.showModalTerms = false;
  }

  private updateTerms(acceptedTerms: boolean): void {
    this.acceptedTerms = acceptedTerms;
    this.showModalTerms = false;
    this.checkFields('terms');
  }

  private updatePolicy(acceptedPolicy: boolean): void {
    this.acceptedPolicy = acceptedPolicy;
    this.showModalPolicy = false;
    this.checkFields('policy');
  }

  private async checkFields(fieldname: string): Promise<boolean> {
    this.checkSignupAllowed();
    let val =  (this as any)[fieldname]

    if (fieldname === 'mainContactName') {
      this.mainContactNameError =
        this.mainContactName.length < 3 ? true : false;
      if (this.mainContactNameError) {
        this.mainContactNameErrorMsg = this.$t("errors.invalid_name").toString();
        return true;
      }
       this.checkNonRomanChar('mainContactNameError',val,'mainContactNameErrorMsg')
    }

    if (fieldname === 'mainContactPhone') {
      const matches = this.mainContactPhone.match(/^[+]?\d+$/g);
      if (
        matches !== null &&
        this.mainContactPhone.length >= 10 &&
        this.mainContactPhone.length <= 20
      ) {
        this.mainContactPhoneError = false;
        return false;
      } else {
        if (this.mainContactPhone.length > 20) {
          this.mainContactPhoneErrorMsg =
            'This field may not contain more than 20 characters';
        } else {
          this.mainContactPhoneErrorMsg =
            'This field contains no valid phone number';
        }
        this.mainContactPhoneError = true;
        return true;
      }
    }

    if (fieldname === 'companyName') {
      this.companyNameError = this.companyName.length < 3 ? true : false;
      if (this.companyNameError) {
        this.mainCompanyNameErrorMsg = this.$t("errors.invalid_company").toString();
        return true;
      }
      this.checkNonRomanChar('companyNameError',val,'mainCompanyNameErrorMsg')
    }

    if (fieldname === 'companyRegistrationNumber') {
      this.companyRegistrationNumberError =
        this.companyRegistrationNumber.length < 3 ? true : false;
      if (this.companyRegistrationNumberError) {
        this.companyRegErrorMsg = this.$t("errors.invalid_registration").toString();
        return true;
      }
      this.checkNonRomanChar('companyRegistrationNumberError',val,'companyRegErrorMsg')
    }

    if (fieldname === 'companyType') {
      this.companyTypeError = this.companyTypeID == 0 ? true : false;
      if (this.companyTypeError) {
        return true;
      }
    }

    if (fieldname === 'companyWebsite') {
      this.companyWebsiteError = this.companyWebsite.length < 6 ? true : false;
      if (this.companyWebsiteError) {
        this.companyWebsiteErrorMsg = this.$t("errors.invalid_website").toString();
        return true;
      }
      this.checkNonRomanChar('companyWebsiteError',val,'companyWebsiteErrorMsg')
    }

    if (fieldname === 'city') {
      this.cityError = this.city.length < 3 ? true : false;
      if (this.cityError) {
        this.cityErrorMsg = this.$t("errors.invalid_city").toString();
        return true;
      }
      this.checkNonRomanChar('cityError',val,'cityErrorMsg')
    }

    if (fieldname === 'country') {
      this.countryError = this.countryCode.length < 1 ? true : false;
      if (this.countryError) {
        this.countryErrorMsg = this.$t("errors.invalid_country").toString();
        return true;
      }
      this.checkNonRomanChar('countryError',val,'countryErrorMsg')
    }

    return false;
  }
  private checkSignupAllowed(): boolean {
    this.allowSignup = true;
    const matchesMainContactPhone = this.mainContactPhone
      .toLowerCase()
      .match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g);
    if (
      this.mainContactName.length < 3 ||
      matchesMainContactPhone == null ||
      this.companyName.length < 3 ||
      this.companyRegistrationNumber.length < 3 ||
      this.companyTypeID == 0 ||
      this.companyWebsite.length < 6 ||
      this.city.length < 3 ||
      this.countryCode.length < 1
    ) {
      this.allowSignup = false;
    }
    return this.allowSignup;
  }

  private async createSupplier(): Promise<void> {
    this.isLoading = true;
    const supplier = new CreateSupplierModel();
    supplier.CompanyName = this.companyName.trim();
    supplier.CompanyType = this.companyTypeID;
    supplier.CompanyWebsite = this.companyWebsite.trim();
    supplier.CompanyRegistrationNumber = this.companyRegistrationNumber.trim();

    const maincontactemail = localStorage.getItem('maincontactemail');
    supplier.MainContactEmailAddress =
      maincontactemail !== null && maincontactemail !== undefined
        ? maincontactemail
        : '';
    supplier.City = this.city.trim();
    supplier.Country = this.countryCode;
    supplier.MainContactName = this.mainContactName.trim();
    supplier.MainContactPhone = this.mainContactPhone.trim();

    if (maincontactemail == null || maincontactemail == undefined) {
      this.isLoading = false;
      return;
    }

    try {
      const result = await this.middlewareService.createSupplier(supplier);
      if (result.result == 'success') {
        this.$router.push({ name: 'SignupSuccess' });
      } else {
        this.$router.push({ name: 'SignupRegistrationError' });
      }
    } catch (error) {
      this.isLoading = false;
    }
  }

  private async checkNonRomanChar(variableName:string,val:string,erroMsgVariable:string){
    (this as any)[variableName] = await NonRomanCharCheckHelper.checkNonRomanChar(val);
    (this as any)[erroMsgVariable] = this.$t("validation.roman_characters").toString(); 
  }

  private get isNonRomanChar():boolean{
    if(this.mainContactNameError||this.mainContactPhoneError||this.companyNameError||this.companyRegistrationNumberError
      ||this.companyTypeError||this.companyWebsiteError||this.cityError||this.countryError){
        return true
    }else{
      return false
    }
  }
}
