import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

// Services
import { EvidenceService } from '@/services/evidenceService';

// Components
import SelectOrder from '@/components/general/evidence/selectOrder/selectOrder.vue';
import SelectPictures from '@/views/orderEvidence/components/selectPictures.vue';

// Helper
import { GroupStepEvidenceHelper } from '@/helpers/groupStepEvidenceHelper';

// Models
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { GroupStepEvidenceModel } from '@/models/groupStepEvidenceModel';
import { OrderStepEvidenceModel } from '@/models/orderStepEvidenceModel';
import { UserPermissionModel } from '@/models/permissionModel';

@Component({
  components: { SelectOrder, SelectPictures },
  computed: mapGetters(['userRole', 'userPermissions']),
})
export default class UploadEvidence extends Vue {
  @Prop()
  private evidenceSteps!: OrderLineEvidenceStep[];

  @Prop({ default: false })
  private isResubmit!: boolean;

  private evidenceService: EvidenceService;

  private groupedSteps: GroupStepEvidenceModel[] = [];
  private curGroupedSteps: GroupStepEvidenceModel =
    new GroupStepEvidenceModel();

  private selectedOrders: OrderStepEvidenceModel[] = [];

  private groupedStepOrders: GroupStepEvidenceModel =
    new GroupStepEvidenceModel();

  private type: string = 'groupSteps';

  private files: File[] = [];

  private uploading: boolean = false;

  private isDisabled: boolean = false;

  private last: boolean = false;

  private userRole!: string;

  private userPermissions!: UserPermissionModel | null;

  public constructor() {
    super();
    this.evidenceService = new EvidenceService();
  }

  private async created(): Promise<void> {
    this.onCreated();
  }

  private async onCreated(): Promise<void> {
    this.type = 'groupSteps';
    this.groupedSteps = GroupStepEvidenceHelper.groupStepEvidence(
      this.evidenceSteps
    );
    const auth = <HTMLDivElement>document.querySelector('.left-container');
    const sidebarMobile = <HTMLDivElement>(
      document.querySelector('.sidebar-mobile')
    );
    auth.style.setProperty('position', 'initial', 'important');
    sidebarMobile.style.setProperty('z-index', '99999', 'important');
  }

  private async beforeDestroy(): Promise<void> {
    const auth = <HTMLDivElement>document.querySelector('.left-container');
    const sidebarMobile = <HTMLDivElement>(
      document.querySelector('.sidebar-mobile')
    );
    auth.style.setProperty('position', 'fixed');
    sidebarMobile.style.setProperty('z-index', 'unset', 'important');
  }

  private navSelectOrders(index: number): void {
    this.groupedStepOrders = this.groupedSteps[index];
    this.groupedStepOrders.orderStyle.forEach((s) => {
      s.isSelected = true;
    });
    this.selectedOrders = this.groupedStepOrders.orderStyle;
    this.type = 'selectOrder';
  }

  private navCancelSelectPictures(): void {
    this.groupedStepOrders = this.curGroupedSteps;
    this.type = 'selectOrder';
  }

  private navSelectPictures(curGroupedSteps: GroupStepEvidenceModel): void {
    curGroupedSteps.orderStyle.forEach((o) => {
      if (this.selectedOrders.findIndex((s) => s.ID === o.ID) !== -1) {
        o.isSelected = true;
      } else {
        o.isSelected = false;
      }
    });
    this.curGroupedSteps = curGroupedSteps;
    this.type = 'selectPictures';
  }

  private selectedOrderList(selectedOrders: OrderStepEvidenceModel[]): void {
    this.selectedOrders = selectedOrders;
    if (selectedOrders.length == 0) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }

  private get isTransport(): boolean {
    return this.groupedStepOrders.steps.length === 1 &&
      this.groupedStepOrders.steps[0] === 'Transport'
      ? true
      : false;
  }

  private get uploadOrderEvidencePermission(): number {
    return this.userPermissions !== null
      ? this.userPermissions.uploadOrderEvidence
      : 0;
  }

  private closeOrder(): void {
    this.type = 'groupSteps';
  }

  private closePictures(): void {
    this.groupedStepOrders = this.curGroupedSteps;
    this.type = 'selectOrder';
  }

  private lastPage(): void {
    this.last = true;
  }

  private nextStepCompany(companyName: string): string {
    return companyName !== '' ? companyName : '-';
  }

  @Emit()
  private closeUpload(): void {}

  @Emit()
  private reloadEvidence(): void {}
}
