import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import {
  DraggableElement,
  DraggableArea,
  StencilPreview,
  ResizeEvent,
} from 'vue-advanced-cropper';

@Component({
  components: {
    StencilPreview,
    DraggableArea,
    DraggableElement,
  },
})
export default class Stencil extends Vue {
  @Prop()
  private image!: any;

  @Prop()
  private coordinates!: any;

  @Prop()
  private transitions!: any;

  @Prop()
  private stencilCoordinates!: any;

  private get style() {
    const { height, width, left, top } = this.stencilCoordinates;
    const style = {
      width: `${width}px`,
      height: `${height}px`,
      transform: `translate(${left}px, ${top}px)`,
      transition: '',
    };
    if (this.transitions && this.transitions.enabled) {
      style.transition = `${this.transitions.time}ms ${this.transitions.timingFunction}`;
    }
    return style;
  }

  private onMove(moveEvent: any) {
    this.$emit('move', moveEvent);
  }

  private onMoveEnd() {
    this.$emit('move-end');
  }
  private onResize(dragEvent: any) {
    const shift = dragEvent.shift();

    const widthResize = shift.left;
    const heightResize = -shift.top;

    this.$emit(
      'resize',
      new ResizeEvent(
        {
          left: widthResize,
          right: widthResize,
          top: heightResize,
          bottom: heightResize,
        },
        {
          compensate: true,
        }
      )
    );
  }

  private onResizeEnd() {
    this.$emit('resize-end');
  }

  private aspectRatios() {
    return {
      minimum: 1,
      maximum: 1,
    };
  }
}
