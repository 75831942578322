import { BaseClient } from './baseClient';
import { SupplyChainApiAuthenticator } from '../authenticators/supplyChainApiAuthenticator';
import { ApplicationConfig } from '../config';
import { SupplyChainStepModel } from '@/models/supplyChainStepModel';
import { ProductGroupModel } from '@/models/productGroupModel';

export class ProductGroupClient extends BaseClient {
  private readonly endPoint: string = 'api/productgroup';

  public constructor() {
    super(
      ApplicationConfig.endpoints.dashboardApi,
      new SupplyChainApiAuthenticator()
    );
  }

  public async getProductGroupsAsync(): Promise<ProductGroupModel[]> {
    return await this.get<ProductGroupModel[]>(`${this.endPoint}`);
  }

  public async getSupplyChainStepsForProductGroupAsync(
    productGroupId: number
  ): Promise<SupplyChainStepModel[]> {
    return await this.get<SupplyChainStepModel[]>(
      `${this.endPoint}/${productGroupId}/supplyChainSteps`
    );
  }
}
