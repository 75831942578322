import i18n from '@/i18n';
import moment from 'moment';
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { NotificationHelper } from '@/helpers/notificationHelper';
import { Drag, Drop, DropList } from 'vue-easy-dnd';
import ClickOutside from 'vue-click-outside';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Compressor from 'compressorjs';

// Components
import SmallScreenPopUpMsg from '@/components/general/smallScreenPopUpMsg/smallScreenPopUpMsg.vue';

// Models
import { CompanyPictureModel, CompanyPictureReqModel, PictureModel } from '@/models/companyPictureModel';

// Services
import { SupplierService } from '@/services/supplierService';
import { BlockchainAccessModal } from '@/models/blockchainAccessModal';
import { UserClient } from '@/clients/userClient';
import { UserModel } from '@/models/userModel';
import { UploadedPictureResponseModel } from '@/models/selectPictureModel';
import { CompanyModel } from '@/models/companyModel';

@Component({
  directives: { ClickOutside },
  components: { Drag, Drop, DropList, SmallScreenPopUpMsg, Cropper },
})
export default class CompanyPictures extends Vue {
  private supplierService: SupplierService;
  private client: UserClient;
  private isCameraOn: boolean = false;
  private isCameraEnabled: boolean = false;
  private isPicturesExists: boolean = false;
  private isDeletePoupVisibile: boolean = false;
  private isReachedMaxPictures: boolean = false;
  private showPicturesIsEditable: boolean = false;
  private askGeolocationPermission: boolean = true;
  private isShowPicturePopup: boolean = false;
  private showPictureSrc: string = '';

  private curImageIndexToDelete: number = 0;
  private maxPhotoCount: number = 5;
  private minPhotoCount: number = 3;
  private maxViewable: number = 3;
  private companyId: string = '';
  private longitude: number = 0;
  private latitude: number = 0;
  private aspectRatio: number = 0;

  private cameraWidth: number = 0;
  private cameraHeight: number = 0;

  private video: any = null;
  private canvas: any = null;
  private videoWrapper: any = null;

  private isSaving: boolean = false;

  private isMobile: boolean = false;

  private fileSrc: string = '';

  private croppedimage: Blob = new Blob();
  private croppedImageUrl: string = '';

  private isSequenceChanged: boolean = false;

  private fileType: string = 'image/jpeg';
  private picturesAdded: CompanyPictureModel[] = [];
  private allPictures: CompanyPictureModel[] = [];
  private newPicture: CompanyPictureModel = new CompanyPictureModel();

  private defaultID: string = '00000000-0000-0000-0000-000000000000';
  @Prop()
  private componentState!: string;

  @Prop({ default: '00000000-0000-0000-0000-000000000000' })
  private addressId!: string;

  public constructor() {
    super();
    this.client = new UserClient();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {}

  private async mounted(): Promise<void> {
    await this.getGeolocationPermission();
    if (screen.width < 636) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    window.onresize = () => {
      if (screen.width < 636) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    };
    await this.getProfilepictures();
    if (this.isMobile && this.picturesAdded.length == 0) {
      this.stateChanged('upload');
    }
  }

  private getGeolocationPermission(): void {
    navigator.geolocation.getCurrentPosition(
      this.getLocationSuccess,
      this.getLocationError,
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }

  private async getCropImage(coordinates: any, canvas: any) {
    if(coordinates !== null && coordinates !== undefined){
      if(coordinates.canvas !== null && coordinates.canvas !== undefined){
         coordinates.canvas.toBlob((blob: any) => {
          const url = URL.createObjectURL(blob);
          this.croppedimage = blob;
          this.croppedImageUrl = url;
        },"image/jpeg",
        0.95,);
      }
    }
  }

  private getProfilepictures(): CompanyPictureModel[] {
    this.picturesAdded = [];
    const company: any = this.$store.getters.profile;
    if (company && company.profilePictures.length > 0) {
      for (let i = 0; i < company.profilePictures.length; i++) {
        if (
          company.profilePictures[i].addressID === '' ||
          company.profilePictures[i].addressID === null
        ) {
          company.profilePictures[i].addressID = company.officeAddress[0].id;
        }
      }
    }
    if (company && company.profilePictures) {
      this.companyId = company.id;
      const picturesFound: CompanyPictureModel[] = [];
      company.profilePictures.forEach((picture: any) => {
        if (picture.addressID == this.addressId) {
          picture.progress = 0;
          picturesFound.push(picture);
        }
      });
      picturesFound.sort((a: any, b: any) => {
        return a.sequence - b.sequence;
      });
      this.picturesAdded = picturesFound;
    }
    return this.picturesAdded;
  }

  private getLocationError(): void {
    //
  }

  private getLocationSuccess(position: any): void {
    this.longitude = position.coords.longitude;
    this.latitude = position.coords.latitude;
  }

  private openPicturePopup(pictureSrc: string): void {
    this.isShowPicturePopup = true;
    this.showPictureSrc = pictureSrc;
    this.hideFooter();
  }

  private closePicturePopup(): void {
    this.showPictureSrc = '';
    this.isShowPicturePopup = false;
    this.showFooter();
  }

  private uploadImageFromGallery(event: any): void {
    this.allPictures = [];
    this.allPictures.push(...this.picturesAdded);
    this.newPicture = new CompanyPictureModel();
    if (this.latitude === 0 && this.longitude === 0) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.enable_geolocation').toString()
      );
      return;
    }
    const app = this;
    const maxHeight: number = 480;
    const imgObject = new Image();

    if (
      this.picturesAdded.length + event.target.files.length >
      this.maxPhotoCount
    ) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.profile_pictures_quantity', [
          this.minPhotoCount,
          this.maxPhotoCount,
        ]).toString()
      );
      return;
    }

    for (let index = 0; index < event.target.files.length; index++) {
      new Compressor(event.target.files[index], {
        quality: 0.6,
        success(result) {
          let selectedFile = result;
          const reader: FileReader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onload = (function (oldFile: any, app: any) {
            return (): void => {
              app.fileSrc = reader.result as string;
            };
          })(selectedFile, app);
          reader.onerror = (): void => {
            NotificationHelper.createErrorNotification(
              i18n.t('errors.image_invalid').toString()
            );
          };
        }
      })
    }
    this.newPicture.id = '';
    this.newPicture.addressID = this.addressId;
    this.newPicture.sequence = this.picturesAdded.length + 1;
    this.newPicture.content = '';
    this.newPicture.fileName = 'photo' + moment.now();
    this.newPicture.geoLat = this.latitude;
    this.newPicture.geoLong = this.longitude;
    this.newPicture.documentType = this.fileType;
    this.stateChanged('showPreview');
  }

  private get currentPicture(): CompanyPictureModel {
    return this.picturesAdded[this.picturesAdded.length - 1];
  }

  private saveCroppedImage(): void {
    if(window.navigator.onLine){
      this.newPicture.contentType = this.croppedimage;
      this.newPicture.content = this.croppedImageUrl;
      this.newPicture.size = this.croppedimage.size;
      this.newPicture.documentType = this.croppedimage.type;
      this.newPicture.progress = 1;
      this.allPictures.push(this.newPicture);
      this.picturesAdded = [];
      this.picturesAdded.push(...this.allPictures);
      this.uploadPictureInChunks(this.picturesAdded.length - 1);
    } else {
      NotificationHelper.createErrorNotification('No internet connection. Please try again when you are connected to the internet.');
    }
  }

  private async uploadPictureInChunks(pictureindex: number): Promise<void> {
    const token = this.$store.getters.accessToken as BlockchainAccessModal;
    const user = this.$store.getters.user as UserModel;
    const chunkSize = 3 * 100 * 1024; // 300kb (adjust based on your requirements)
    const totalChunks = Math.ceil(this.picturesAdded[pictureindex].size / chunkSize);
    const chunkProgress = 100 / totalChunks;
    let accesstoken: BlockchainAccessModal = new BlockchainAccessModal();
    let chunkNumber = 0;
    let start = 0;
    let end = chunkSize;
    if (token !== null) {
      accesstoken = token;
    } else {
      accesstoken = await this.client.getBlockchainToken();
      this.$store.commit('setAccessToken', accesstoken);
    }
    const uploadNextChunk = async () => {
      if (start <= this.picturesAdded[pictureindex].size) {
        const chunk = this.picturesAdded[pictureindex].contentType.slice(start, end);
        const formData = new FormData();
        formData.append("upload", chunk);
        formData.append("chunkNumber", chunkNumber.toString());
        formData.append("totalChunks", totalChunks.toString());
        formData.append("originalname", this.picturesAdded[pictureindex].fileName);
        formData.append("mimetype", this.picturesAdded[pictureindex].documentType);
        formData.append("geoLat", this.latitude.toString());
        formData.append("geoLong", this.longitude.toString());
        formData.append("status", '0');

        fetch(`${process.env.VUE_APP_Blockchain_Api_Endpoint}/v2/api/supplier/${user.companyId}/profilepictures`, {
          headers: {
            Authorization: 'Bearer ' + accesstoken.token,
          },
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data: UploadedPictureResponseModel) => {
            this.picturesAdded[pictureindex].progress = (Number((chunkNumber + 1) * chunkProgress));
            const picture = this.picturesAdded[pictureindex];
            this.picturesAdded.splice(pictureindex, 1, picture);
            chunkNumber++;
            start = end;
            end = start + chunkSize;
            if(chunkNumber === totalChunks) {
              this.picturesAdded[pictureindex].uploadedPicture = data;
              const picture = this.picturesAdded[pictureindex];
              this.picturesAdded.splice(pictureindex, 1, picture);
            }
            uploadNextChunk();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setTimeout(
          (app: any) => {
            this.picturesAdded[pictureindex].progress = 101;
            const picture = this.picturesAdded[pictureindex];
            this.picturesAdded.splice(pictureindex, 1, picture);
            this.fileSrc = '';
            this.croppedimage = new Blob;
            this.croppedImageUrl = '';
            this.stateChanged('upload');
          },
          1000,
          this
        );
      }
    };
    uploadNextChunk();
  }

  private takePicture(): void {
    if (this.latitude === 0 && this.longitude === 0) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.enable_geolocation').toString()
      );
      this.getGeolocationPermission();
    }
    if (this.picturesAdded.length >= this.maxPhotoCount) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.profile_pictures_quantity', [
          this.minPhotoCount,
          this.maxPhotoCount,
        ]).toString()
      );
      return;
    }
    const inputFile = <HTMLDivElement>document.querySelector('.camera-file');
    inputFile.click();
  }

  private get isUploadDisabled(): boolean {
    return this.picturesAdded.some(p => p.progress > 0 && p.progress < 101);
  }

  private addPicture(): void {
    this.saveCompanyPictures();
  }

  private deletePicture(index: number): void {
    this.closeDeletePopup();
    const user = this.$store.getters.user as UserModel;
    const company = this.$store.getters.profile as CompanyModel;
    const pictures: CompanyPictureModel[] = [];
    if(company && company.profilePictures.length > 0){
      pictures.push(...company.profilePictures);
    }
    if(pictures.length > 0 && pictures.findIndex(p => this.picturesAdded[index].id === p.id) !== -1){
      this.supplierService.deleteCompanyPictureAsync(
        user.companyId,
        this.picturesAdded[index].id
      );
    }
    this.picturesAdded.splice(
      this.picturesAdded.indexOf(this.picturesAdded[index]),
      1
    );
    this.isSequenceChanged = true;
  }

  private showDeletePopup(index: number): void {
    this.curImageIndexToDelete = index;
    this.isDeletePoupVisibile = true;
    this.hideFooter();
  }

  private hideFooter(): void {
    const rightContainer = document.getElementById(
      'left-container'
    ) as HTMLElement;
    rightContainer.style.display = 'none';
  }

  private showFooter(): void {
    const rightContainer = document.getElementById(
      'left-container'
    ) as HTMLElement;
    rightContainer.style.display = 'block';
  }

  private closeDeletePopup(): void {
    this.isDeletePoupVisibile = false;
    this.showFooter();
  }

  private dataURItoFile(filename: string, dataURI: string): File {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const fileBlob = new Blob([ab], { type: mimeString });
    return new File([fileBlob], filename, { type: mimeString });
  }

  private async saveCompanyPictures(): Promise<void> {
    if (this.latitude === 0 && this.longitude === 0) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.enable_geolocation').toString()
      );
    }

    if (this.latitude === 0 && this.longitude === 0) {
      return;
    }

    if (
      this.picturesAdded.length < this.minPhotoCount ||
      this.picturesAdded.length > this.maxPhotoCount
    ) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.profile_pictures_quantity', [
          this.minPhotoCount,
          this.maxPhotoCount,
        ]).toString()
      );
      return;
    }

    this.isSaving = true;
    const pictures: PictureModel[] = [];
    const user = this.$store.getters.user as UserModel;
    this.picturesAdded.forEach((picture: CompanyPictureModel, index: number) => {
      const pic: PictureModel = {
        id: picture.id === '' ? picture.uploadedPicture.evidencePhotoList.ID : picture.id,
        addressID: picture.addressID,
        fileHash: picture.id === '' ?  picture.uploadedPicture.evidencePhotoList.fileHash : '',
        docURL: picture.id === '' ? picture.uploadedPicture.evidencePhotoList.docURL : '',
        createdAt: picture.id === '' ? picture.uploadedPicture.evidencePhotoList.createdAt : picture.createdAt.toString(),
        createdBy: picture.id === '' ? picture.uploadedPicture.evidencePhotoList.createdBy : picture.createdBy,
        fileType: picture.id === '' ? picture.uploadedPicture.evidencePhotoList.fileType : picture.documentType,
        fileName: picture.id === '' ? picture.uploadedPicture.evidencePhotoList.fileName : picture.fileName,
        geoLat: picture.id === '' ? picture.uploadedPicture.evidencePhotoList.geoLat : picture.geoLat,
        geoLong: picture.id === '' ? picture.uploadedPicture.evidencePhotoList.geoLong : picture.geoLong,
        isMainPicture: index === 0 ? true : false,
        sequence: index + 1,
        owner: picture.id === '' ? picture.uploadedPicture.evidencePhotoList.createdBy : picture.createdBy,
      }
      pictures.push(pic);
    });
    if (user.companyId === '') {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.application_error').toString()
      );
      return;
    }
    const companyPictures: CompanyPictureReqModel = {
      profilePics: pictures
    };
    await this.supplierService.submitCompanyPicturesAsync(companyPictures, user.companyId);
    await this.supplierService.getSupplierAsync();

    setTimeout(
      (app: any) => {
        app.showPicturesIsEditable = false;
        app.isSequenceChanged = false;
        app.isSaving = false;
        app.stateChanged('');
      },
      250,
      this
    );
  }

  @Emit()
  private closePopup(): void {
    this.stateChanged('');
  }

  @Emit()
  private stateChanged(state: string): void {}

  @Emit()
  private updateIsSaving(isSaving: boolean): void {}

  @Watch('isSaving')
  private async onIsSavingChanged(isSaving: boolean): Promise<void> {
    this.updateIsSaving(isSaving);
  }

  @Watch('addressId')
  private onAddressIdChanged(addressId: string): void {
    this.getProfilepictures();
  }

  @Watch('componentState')
  private async onComponentStateChanged(value: string): Promise<void> {
    this.isSequenceChanged = false;
    this.showPicturesIsEditable = false;
    switch (value) {
      case 'upload':
        break;

      default:
        this.getProfilepictures();
        break;
    }
  }

  @Emit()
  private showQrCodeModal(): void {}
}
