import { ProductGroup } from './productGroup';

export class CombinedOrderLineModel {
  public id!: string;
  public orderCount!: number;
  public orderIds!: string[];
  public clientName!: string;
  public orderRefId!: string;
  public productGroup!: ProductGroup;
  public clientId!: string;
  // TTD-2630 Sprint 18
  public delegatedDate!: string;
  public delegatedByPartnerName!: string;
}
