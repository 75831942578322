import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import lodash, { isNumber } from "lodash";
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

// Components
import VuePdfApp from "vue-pdf-app";

// Services
import { ClientRequestFootPrintData } from "@/services/footPrintRequestService";

// Helpers
import { FootprintDropdownHelper } from "@/helpers/footprintDropdownHelper";
import { FootprintPermissionsHelper } from "@/helpers/footprintPermissionsHelper";
import { CountryHelper } from "@/helpers/countryHelper";
import { StepsHelper } from "@/helpers/stepsHelper";

// Models
import { DropdownModel } from "@/models/dropdownModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { FootprintPartnerModel } from "@/models/orderLineStepDisplayModel";
import { FabricFootprintModel, YarnModel, WeftModel, WarpModel, EnergySupportingDocumentModel, FabricFootprintRequestModel, LoopYarn, FabricStepsDataModel  } from "@/models/fabricFootprintModel";
import { UserModel } from "@/models/userModel";
import { ModeOfTransportModel, TransportFootprintModel, TransportFootprintRequestModel, TransportStepsDataModel } from "@/models/transportFootprintModel";
import { DeleteDocRequestModel, FootPrintChangedConstant, FootPrintChangedTabConstant, FootPrintCompletedConstant, FootPrintConstant, FootprintDataResponseModel } from "@/models/footprintModel";
import { FiberModel } from "@/models/yarnFootprintModel";
import { Guid } from "@/models/guid";
import { FootprintStepsModel, StepReferenceModel } from "@/models/orderLineStepModel";

@Component({
    components: { VuePdfApp },
	computed: mapState(['orderLoading', 'companyType', 'orderLineStepsLoading', 'footprintPartner', 'orders', 'suppliers', 'user', 'userRole', 'isFootprintChanged', 'footprintOrders'])
})
export default class FabricFootprintData extends Vue {

	@Prop()
	private orderDetail!: OrderLineDisplayModel;

	@Prop()
	private deadline!: string;

	@Prop()
	private allFootprintSteps!: StepReferenceModel[];

	@Prop()
	private activeStep!: string;

	@Prop()
	private showWetProcess!: string;

	@Prop()
	private showGarment!: string;
	//Spint-22 TTD-3942
	@Prop()
	private tabIndex!: number;

	private orderLoading!: boolean;

	private companyType!: number;

    private clientRequestFootPrintData: ClientRequestFootPrintData;

    private orders!: OrderLineDisplayModel[];

	private orderLineStepsLoading!: boolean;

    private footprintPartner!: FootprintPartnerModel[];

	private footprintOrders!: OrderLineDisplayModel[];

	private uniqueSuppliersListLoading!: boolean;

    private fabricType: string = "";

    private fabricTypes: DropdownModel[] = [];

    private fabricWeightUnits: DropdownModel[]= [];

    private fabricFootprintClone: FabricFootprintModel[] = [];

    private fabricFootprint: FabricFootprintModel[] = [];

    private fabricFpChangedCounter: number = 0;

	private fabricTpChangedCounter: number = 0;

    private showUploadDocModal: boolean = false;

	private docFile: any = '';

	private isDocUploading: boolean = false;

	private docPreview: EnergySupportingDocumentModel = new EnergySupportingDocumentModel();

	private pdfDocument!: any;

    private isPdfReady: boolean = false;

	private showFabricTransport: boolean = false;

	private fabricTransport: TransportFootprintModel[] = [];

	private fabricTransportClone: TransportFootprintModel[] = [];

	private fabricTransportCloneInitialState: TransportFootprintModel[] = [];

    private showFabricFootprint: boolean = true;

    private user!: UserModel;

    private disableSave: boolean = false;

    private isSaving: boolean = false;

    private fabricIndex: number = -1;

    private showPdfPreviewModal: boolean = false;

	private isDeletingDoc: boolean = false;

    private modeOfTransportList: DropdownModel[] = [];

	private transportUnitList: DropdownModel[] = [];

    private yarnRefList: DropdownModel[] = [];

	private footprintStatus: string = 'red';

	private transportStatus: string = 'red';

	private showDeleteModal: boolean = false;

	private showWarningModal: boolean = false;

	private fabricDeleIndex: number = 0;

	private fabriclDeleDocIndex: number = 0;

	private userRole!: string;

	private deletedDocID: string = "";

	private fabricFootprintCloneInitialState: FabricFootprintModel[] = [];

	private transportToBeDeleted: boolean = false;

	private isTabRemoved: boolean = false;

	private isFabricFieldEmpty: boolean = false;

	private isFootprintChanged!: string;

	private transportFieldEmpty: boolean = false;

	private footprintOrder!: OrderLineDisplayModel;

	private fiberList: DropdownModel[] = [];

	private fiberOriginList: DropdownModel[] = [];

	private FpDeletedCounter: number = 0;

	private TpDeletedCounter: number = 0;

	private userCompanyId!: string;

	private guidEmpty: string = Guid.Empty;

	private fabricWrapperData: FabricStepsDataModel[] = [];

	private fabricWrapperDataClone: FabricStepsDataModel[] = [];

	private transportWrapperData: TransportStepsDataModel[] = [];

	private showNoFabricStepMsg: boolean = false; 

	private isWetProcessPartner: boolean = false;

	private activeFabricStep: string = Guid.Empty;

	private config: any = {
        toolbar: false
    };

    public constructor() {
		super();
        this.clientRequestFootPrintData = new ClientRequestFootPrintData();
	}

    private async created(): Promise<void> {
		this.fabricWeightUnits = FootprintDropdownHelper.getFabricWeightUnits();
		this.fabricTypes = FootprintDropdownHelper.getFabricTypes();
		this.userCompanyId = this.user.companyId !== undefined && this.user.companyId !== '' ? this.user.companyId : Guid.Empty;
		this.modeOfTransportList = FootprintDropdownHelper.getModeOfTransportList();
		this.transportUnitList = FootprintDropdownHelper.getTransportUnit();
		this.fiberList = FootprintDropdownHelper.getFiberTypeList();
		this.fiberOriginList = lodash.orderBy(CountryHelper.getCountryList(),['text'],['asc']);
		let allOrders = lodash.cloneDeep(this.footprintOrders);
        this.footprintOrder = allOrders.filter(o => o.orderId === this.orderDetail.orderId)[0];
		this.checkWetprocessPartners();
        if(this.footprintOrder.yarn.footprint.length > 0){
            this.footprintOrder.yarn.footprint.forEach(yarn => {
                if(yarn.reference.filledByCompanyID!==Guid.Empty){
					this.yarnRefList.push(new DropdownModel(yarn.ID, yarn.reference.referenceNo));
				}
            });
        }else{
			this.yarnRefList.push(new DropdownModel(Guid.Empty, 'No yarn data available'));
		}
		if(this.footprintOrder.fabric.fabricFootprint.length > 0){
            this.fabricFootprint = lodash.cloneDeep(this.footprintOrder.fabric.fabricFootprint);
            this.fabricFootprint.forEach((fabric, index) => {
                fabric.fabricType.isValid = true;
                fabric.toCompanyUser.isValid = true;
                fabric.reference.isValid = true;
                fabric.yarnFabric.forEach(yarn => {
                    yarn.yarnRefNumber.isValid = true;
                    yarn.yarnPercent.isValid = true;
					if(yarn.yarnPercent.count === -1){
						yarn.yarnPercent.count = '';
					}
                });
				if(fabric.fiberFabric === null || fabric.fiberFabric.length === 0){
					fabric.fiberFabric = [new FiberModel()];
					fabric.fiberFabric[0].ID = uuidv4();
					fabric.fiberFabric[0].filledByCompanyID = this.userCompanyId;
					fabric.fiberFabric[0].filledByPrimarySource = this.userCompanyId === fabric.toCompanyUser.toCompanyID ? true : false;
				}
				fabric.fiberFabric.forEach(fiber => {
					fiber.fiberType.isValid = true;
					fiber.fiberValue.isValid = true;
					fiber.originOfFiber.isValid = true;
					if(fiber.fiberValue.value === -1){
						fiber.fiberValue.value = '';
					}
				});
				if(fabric.yarnFabric === null || fabric.yarnFabric.length === 0){
					fabric.yarnFabric = [new YarnModel()];
					fabric.yarnFabric[0].ID = uuidv4();
					fabric.yarnFabric[0].filledByCompanyID = this.userCompanyId;
					fabric.yarnFabric[0].filledByPrimarySource = this.userCompanyId === fabric.toCompanyUser.toCompanyID ? true : false;
				}
                fabric.total.isValid = true;
				if(fabric.total.totalcount === -1){
					fabric.total.totalcount = '';
				}
                fabric.fabricWeight.isValid = true;
				if(fabric.fabricWeight.weight === -1){
					fabric.fabricWeight.weight = '';
				}
                fabric.fabricUnit.isValid = true;
                fabric.fabricWidth.isValid = true;
				if(fabric.fabricWidth.width === -1){
					fabric.fabricWidth.width = '';
				}
                fabric.totalElectricEnergy.isValid =true;
				if(fabric.totalElectricEnergy.value === -1){
					fabric.totalElectricEnergy.value = '';
				}
                fabric.windEnergy.isValid =true;
				if(fabric.windEnergy.value === -1){
					fabric.windEnergy.value = '';
				}
                fabric.solarEnergy.isValid =true;
				if(fabric.solarEnergy.value === -1){
					fabric.solarEnergy.value = '';
				}
				if(index === 0){
					fabric.showFabric = true;
				}
				fabric.yarnRefList = lodash.cloneDeep(this.yarnRefList);
				fabric.yarnRefList.forEach(ref => {
					fabric.yarnFabric.findIndex(y => y.yarnRefNumber.yarnID === ref.value) !== -1 ? ref.disabled = true : ref.disabled = false;
				});
            });
		}
		if(this.footprintOrder.fabric.transport.length > 0){
			this.fabricTransport = this.footprintOrder.fabric.transport;
			this.fabricTransport.forEach((transport, index) => {
				transport.toCompanyUser.isValid = true;
				transport.modeOfTransport.forEach(mode => {
					if(mode.distance.value === -1){
						mode.distance.value = '';
					}
					mode.distance.isValid = true;
					mode.distanceUnit.isValid = true;
					mode.transportType.isValid = true;
				})
			})
		}
		this.createFabricCards();
        this.fabricFootprintClone = lodash.cloneDeep(this.fabricFootprint);
        this.fabricTransportClone = lodash.cloneDeep(this.fabricTransport);
		this.fabricFootprintCloneInitialState = lodash.cloneDeep(this.fabricFootprint);
		this.fabricTransportCloneInitialState = lodash.cloneDeep(this.fabricTransport);
		this.fabricFpStatus();
		this.fabricTpStatus();
		this.checkNonEmptyFields();
	}

	private async mounted(): Promise<void> {
		this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
			if(collapseId === 'fabric-0'){
				const fabric = this.fabricFootprint[0];
				if(fabric!==undefined){
					fabric.showFabric = isJustShown;
					this.fabricFootprint.splice(0, 1);
					this.fabricFootprint.splice(0, 0, fabric);
				}
			}
			if(collapseId === 'transport-0'){
				const transport = this.fabricTransport[0];
				if(transport !== undefined){
					transport.showTransport = isJustShown;
					this.fabricTransport.splice(0, 1);
					this.fabricTransport.splice(0, 0, transport);
				}
			}
		});
	}

	private createFabricCards(): void{
		const StepSrNo = this.allFootprintSteps.filter(step => (step.stepId === 4) || (step.stepId === 5)).length > 0 ? '4-5' : this.allFootprintSteps.filter(step => (step.stepId === 23)).length > 0 ? '23' : '-1';
		if(StepSrNo === '-1'){
			this.showNoFabricStepMsg = true;
		} else {
			let mcStep: StepReferenceModel[] = [];
			if((this.orderDetail.toCompanyId === this.user.companyId) || (this.orderDetail.delegateCompanyID === this.user.companyId)){
				mcStep = this.allFootprintSteps.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId === Guid.Empty).length > 0 ? this.allFootprintSteps.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId === Guid.Empty) : this.allFootprintSteps.filter(a => a.stepId === 23 && a.pcStepUniqueId === Guid.Empty).length > 0 ? this.allFootprintSteps.filter(a => a.stepId === 23 && a.pcStepUniqueId === Guid.Empty) : [];
			} else {
				const steps = this.allFootprintSteps.filter(a => this.isFootprintStep(a.stepId) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId === Guid.Empty);
				steps.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1);
				let pointedSequence = -1;
				if(steps.length > 0){
					pointedSequence = steps[steps.length - 1].mcSequence;
				}
				if (this.allFootprintSteps.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId === Guid.Empty).length > 0) {
					if (this.allFootprintSteps.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId === Guid.Empty).length > 0) {
						mcStep = this.allFootprintSteps.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId === Guid.Empty);
					} else {
						mcStep = this.allFootprintSteps.filter(a => ((a.stepId === 4 || a.stepId === 5) && a.mcSequence <= pointedSequence && pointedSequence!==-1) && a.pcStepUniqueId === Guid.Empty);
					}
				} else {
					if (this.allFootprintSteps.filter(a => a.stepId === 23 && a.pcStepUniqueId === Guid.Empty).length > 0) {
						if (this.allFootprintSteps.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId === Guid.Empty).length > 0) {
							mcStep = this.allFootprintSteps.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId === Guid.Empty);
						} else {
							mcStep = this.allFootprintSteps.filter(a => a.stepId === 23 && a.mcSequence <= pointedSequence && pointedSequence!==-1 && a.pcStepUniqueId === Guid.Empty);
						}
					}
				}
				// mcStep = this.allFootprintSteps.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId === Guid.Empty && a.toCompanyId === this.userCompanyId).length > 0 ? this.allFootprintSteps.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId === Guid.Empty && a.toCompanyId === this.userCompanyId) : this.allFootprintSteps.filter(a => a.stepId === 23 && a.pcStepUniqueId === Guid.Empty && a.toCompanyId === this.userCompanyId).length > 0 ? this.allFootprintSteps.filter(a => a.stepId === 23 && a.pcStepUniqueId === Guid.Empty && a.toCompanyId === this.userCompanyId) : [];
				// if(mcStep.length === 0){
				// 	mcStep = this.allFootprintSteps.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId === Guid.Empty).length > 0 ? this.allFootprintSteps.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId === Guid.Empty) : this.allFootprintSteps.filter(a => a.stepId === 23 && a.pcStepUniqueId === Guid.Empty).length > 0 ? this.allFootprintSteps.filter(a => a.stepId === 23 && a.pcStepUniqueId === Guid.Empty) : [];
				// }
			}
			if(mcStep.length > 0){
				mcStep.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1);
				mcStep.forEach(step => {
					this.defaultFabricWrapper(step);
					this.defaultFabricTpWrapper(step);
				});
			}
			let pcSteps: StepReferenceModel[] = [];
			pcSteps = this.allFootprintSteps.filter(a => (a.stepId === 4 || a.stepId === 5 || a.stepId === 23) && a.pcStepUniqueId !== Guid.Empty);
			if(pcSteps.length > 0){
				const pcStepsUnique = lodash.uniqBy(pcSteps, 'mcStepUniqueId');
				pcStepsUnique.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1);
				pcStepsUnique.forEach(step => {
					const pc0allStep = pcSteps.filter(a => a.mcStepUniqueId === step.mcStepUniqueId && a.pcOfMC === 0);
					if(pc0allStep.length > 0){
						let pc0Step: StepReferenceModel[] = [];
						if((this.orderDetail.toCompanyId === this.user.companyId) || (this.orderDetail.delegateCompanyID === this.user.companyId)){
							pc0Step = pc0allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty).length > 0 ? pc0allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty) : pc0allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty).length > 0 ? pc0allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty) : [];
						} else {
							if (pc0allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty).length > 0) {
								if (pc0allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty).length > 0) {
									pc0Step = pc0allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty);
								} else {
									pc0Step = pc0allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty);
								}
							} else {
								if (pc0allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty).length > 0) {
									if (pc0allStep.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty).length > 0) {
										pc0Step = pc0allStep.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty);
									} else {
										pc0Step = pc0allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty);
									}
								}
							}
						}
						if(pc0Step.length > 0){
							pc0Step.sort((a, b)=> a.pcSequence > b.pcSequence ? -1 : 1);
							pc0Step.forEach((pc0) => {
								this.defaultFabricWrapper(pc0);
								this.defaultFabricTpWrapper(pc0);
							});
						}
						const pc1allStep = pcSteps.filter(a => a.mcStepUniqueId === step.mcStepUniqueId && a.pcOfMC === 1);
						if(pc1allStep.length > 0){
							let pc1Step: StepReferenceModel[] = [];
							if((this.orderDetail.toCompanyId === this.user.companyId) || (this.orderDetail.delegateCompanyID === this.user.companyId)){
								pc1Step = pc1allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty).length > 0 ? pc1allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty) : pc1allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty).length > 0 ? pc1allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty) : [];
							} else {
								if (pc1allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty).length > 0) {
									if (pc1allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty).length > 0) {
										pc1Step = pc1allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty);
									} else {
										pc1Step = pc1allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty);
									}
								} else {
									if (pc1allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty).length > 0) {
										if (pc1allStep.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty).length > 0) {
											pc1Step = pc1allStep.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty);
										} else {
											pc1Step = pc1allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty);
										}
									}
								}
							}
							if(pc1Step.length > 0){
								pc1Step.sort((a, b)=> a.pcSequence > b.pcSequence ? -1 : 1);
								pc1Step.forEach((pc1) => {
									this.defaultFabricWrapper(pc1);
									this.defaultFabricTpWrapper(pc1);
								});
							}
							const pc2allStep = pcSteps.filter(a => a.mcStepUniqueId === step.mcStepUniqueId && a.pcOfMC === 2);
							if(pc2allStep.length > 0){
								let pc2Step: StepReferenceModel[] = [];
								if((this.orderDetail.toCompanyId === this.user.companyId) || (this.orderDetail.delegateCompanyID === this.user.companyId)){
									pc2Step = pc2allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty).length > 0 ? pc2allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty) : pc2allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty).length > 0 ? pc2allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty) : [];
								} else {
									if (pc2allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty).length > 0) {
										if (pc2allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty).length > 0) {
											pc2Step = pc2allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty);
										} else {
											pc2Step = pc2allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty);
										}
									} else {
										if (pc2allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty).length > 0) {
											if (pc2allStep.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty).length > 0) {
												pc2Step = pc2allStep.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty);
											} else {
												pc2Step = pc2allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty);
											}
										}
									}
								}
								if(pc2Step.length > 0){
									pc2Step.sort((a, b)=> a.pcSequence > b.pcSequence ? -1 : 1);
									pc2Step.forEach((pc2) => {
										this.defaultFabricWrapper(pc2);
										this.defaultFabricTpWrapper(pc2);
									});
								}
								const pc3allStep = pcSteps.filter(a => a.mcStepUniqueId === step.mcStepUniqueId && a.pcOfMC === 3);
								if(pc3allStep.length > 0){
									let pc3Step: StepReferenceModel[] = [];
									if((this.orderDetail.toCompanyId === this.user.companyId) || (this.orderDetail.delegateCompanyID === this.user.companyId)){
										pc3Step = pc3allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty).length > 0 ? pc3allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty) : pc3allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty).length > 0 ? pc3allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty) : [];
									} else {
										if (pc3allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty).length > 0) {
											if (pc3allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty).length > 0) {
												pc3Step = pc3allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty);
											} else {
												pc3Step = pc3allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty);
											}
										} else {
											if (pc3allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty).length > 0) {
												if (pc3allStep.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty).length > 0) {
													pc3Step = pc3allStep.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty);
												} else {
													pc3Step = pc3allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty);
												}
											}
										}
									}
									if(pc3Step.length > 0){
										pc3Step.sort((a, b)=> a.pcSequence > b.pcSequence ? -1 : 1);
										pc3Step.forEach((pc3) => {
											this.defaultFabricWrapper(pc3);
											this.defaultFabricTpWrapper(pc3);
										});
									}
									const pc4allStep = pcSteps.filter(a => a.mcStepUniqueId === step.mcStepUniqueId && a.pcOfMC === 4);
									if(pc4allStep.length > 0){
										let pc4Step: StepReferenceModel[] = [];
										if((this.orderDetail.toCompanyId === this.user.companyId) || (this.orderDetail.delegateCompanyID === this.user.companyId)){
											pc4Step = pc4allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty).length > 0 ? pc4allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty) : pc4allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty).length > 0 ? pc4allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty) : [];
										} else {
											if (pc4allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty).length > 0) {
												if (pc4allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty).length > 0) {
													pc4Step = pc4allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty);
												} else {
													pc4Step = pc4allStep.filter(a => (a.stepId === 4 || a.stepId === 5) && a.pcStepUniqueId !== Guid.Empty);
												}
											} else {
												if (pc4allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty).length > 0) {
													if (pc4allStep.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty).length > 0) {
														pc4Step = pc4allStep.filter(a => a.stepId === 23 && a.toCompanyId === this.user.companyId && a.pcStepUniqueId !== Guid.Empty);
													} else {
														pc4Step = pc4allStep.filter(a => a.stepId === 23 && a.pcStepUniqueId !== Guid.Empty);
													}
												}
											}
										}
										if(pc4Step.length > 0){
											pc4Step.sort((a, b)=> a.pcSequence > b.pcSequence ? -1 : 1);
											pc4Step.forEach((pc4) => {
												this.defaultFabricWrapper(pc4);
												this.defaultFabricTpWrapper(pc4);
											});
										}
									}
								}
							}
						}
					}
				});
			}
			this.showFabricFootprint = true;
			if (this.fabricWrapperData.length>0) {
				this.fabricWrapperData[0].showWrapper = true;
				const fabricSteps = this.fabricFootprint.filter(f => f.stepID === this.fabricWrapperData[0].stepID);
				fabricSteps.sort((a, b)=> a.sequence < b.sequence ? -1 : 1);
				const fabricIndex = this.fabricFootprint.findIndex(yf => yf.ID === fabricSteps[0].ID);
				this.fabricFootprint[fabricIndex].showFabric = true;
			}
		}
	}

	private defaultFabricWrapper(step: StepReferenceModel): void {
		this.fabricWrapperData.push(new FabricStepsDataModel());
		const lastIndex = this.fabricWrapperData.length - 1;
		this.fabricWrapperData[lastIndex].stepID = step.pcStepUniqueId !== Guid.Empty ? step.pcStepUniqueId : step.mcStepUniqueId;
		this.fabricWrapperData[lastIndex].referenceNo = step.refNo;
		this.fabricWrapperData[lastIndex].supplierName = step.toCompanyName;
		this.fabricWrapperData[lastIndex].supplierCompanyId = step.toCompanyId;
		this.fabricWrapperData[lastIndex].showWrapper = false;
		this.fabricWrapperData[lastIndex].referenceNoPrefix = step.footprintRefNoPrefix;
		if(this.footprintOrder.yarn.footprint.length > 0){
			if(step.pcStepUniqueId === Guid.Empty){
				if(this.allFootprintSteps.filter(s => s.mcStepUniqueId === step.mcStepUniqueId).length > 1){
					const yarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && (s.stepId === 3 || s.stepId === 22));
					if(yarnStep.length > 0){
						yarnStep.forEach(ys => {	
							const mcYarnStep = this.footprintOrder.yarn.footprint.filter(y => y.stepID === ys.mcStepUniqueId);
							if(mcYarnStep.length > 0){
								mcYarnStep.forEach(y => {
									if(y.reference.filledByCompanyID!==Guid.Empty){
										this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
									}
								})
							}
						})
					}
					const pc0YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step.mcStepUniqueId && s.pcOfMC === 0 && (s.stepId === 3 || s.stepId === 22));
					if(pc0YarnStep.length > 0){
						pc0YarnStep.forEach(pc0 => {
							const pc0Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc0.pcStepUniqueId);
							if(pc0Yarn.length > 0){
								pc0Yarn.forEach(y => {
									if(y.reference.filledByCompanyID!==Guid.Empty){
										this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
									}
								})
							}
						})
					}
					const pc1YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step.mcStepUniqueId && s.pcOfMC === 1 && (s.stepId === 3 || s.stepId === 22));
					if(pc1YarnStep.length > 0){
						pc1YarnStep.forEach(pc1 => {
							const pc1Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc1.pcStepUniqueId);
							if(pc1Yarn.length > 0){
								pc1Yarn.forEach(y => {
									if(y.reference.filledByCompanyID!==Guid.Empty){
										this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
									}
								})
							}
						})
					}
					const pc2YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step.mcStepUniqueId && s.pcOfMC === 2 && (s.stepId === 3 || s.stepId === 22));
					if(pc2YarnStep.length > 0){
						pc2YarnStep.forEach(pc2 => {
							const pc2Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc2.pcStepUniqueId);
							if(pc2Yarn.length > 0){
								pc2Yarn.forEach(y => {
									if(y.reference.filledByCompanyID!==Guid.Empty){
										this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
									}
								})
							}
						})
					}
					const pc3YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step.mcStepUniqueId && s.pcOfMC === 3 && (s.stepId === 3 || s.stepId === 22));
					if(pc3YarnStep.length > 0){
						pc3YarnStep.forEach(pc3 => {
							const pc3Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc3.pcStepUniqueId);
							if(pc3Yarn.length > 0){
								pc3Yarn.forEach(y => {
									if(y.reference.filledByCompanyID!==Guid.Empty){
										this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
									}
								})
							}
						})
					}
					const pc4YarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step.mcStepUniqueId && s.pcOfMC === 4 && (s.stepId === 3 || s.stepId === 22));
					if(pc4YarnStep.length > 0){
						pc4YarnStep.forEach(pc4 => {
							const pc4Yarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc4.pcStepUniqueId);
							if(pc4Yarn.length > 0){
								pc4Yarn.forEach(y => {
									if(y.reference.filledByCompanyID!==Guid.Empty){
										this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
									}
								})
							}
						})
					}
					if(this.fabricWrapperData[lastIndex].yarnRefList.length === 0){
						this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(Guid.Empty, 'No yarn data available'));
						this.fabricWrapperData[lastIndex].yarnRefList[0].disabled = true;
					}
				} else if(this.allFootprintSteps.filter(s => s.mcStepUniqueId === step.mcStepUniqueId).length === 1) {
					const yarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && (s.stepId === 3 || s.stepId === 22));
					if(yarnStep.length > 0){
						yarnStep.forEach(ys => {	
							const mcYarnStep = this.footprintOrder.yarn.footprint.filter(y => y.stepID === ys.mcStepUniqueId);
							if(mcYarnStep.length > 0){
								mcYarnStep.forEach(y => {
									if(y.reference.filledByCompanyID!==Guid.Empty){
										this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
									}
								})
							}
						})
					}
					if(this.fabricWrapperData[lastIndex].yarnRefList.length === 0){
						this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(Guid.Empty, 'No yarn data available'));
						this.fabricWrapperData[lastIndex].yarnRefList[0].disabled = true;
					}
				}
			} else {
				const pcYarnStep = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.mcStepUniqueId === step.mcStepUniqueId && s.pcOfMC === step.pcOfMC && (s.stepId === 3 || s.stepId === 22));
				if(pcYarnStep.length > 0){
					pcYarnStep.forEach(pc => {
						const pcYarn = this.footprintOrder.yarn.footprint.filter(y => y.stepID === pc.pcStepUniqueId);
						if(pcYarn.length > 0){
							pcYarn.forEach(y => {
								if(y.reference.filledByCompanyID!==Guid.Empty){
									this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(y.ID, y.reference.referenceNo));
								}
							})
						}
					})
				}
				if(this.fabricWrapperData[lastIndex].yarnRefList.length === 0){
					this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(Guid.Empty, 'No yarn data available'));
					this.fabricWrapperData[lastIndex].yarnRefList[0].disabled = true;
				}
			}
		} else {
			this.fabricWrapperData[lastIndex].yarnRefList.push(new DropdownModel(Guid.Empty, 'No yarn data available'));
			this.fabricWrapperData[lastIndex].yarnRefList[0].disabled = true;
		}
		if((this.fabricFootprint.length === 0) || (this.fabricFootprint.length > 0 && this.fabricFootprint.filter(y => y.stepID === this.fabricWrapperData[lastIndex].stepID).length === 0)){
			this.defaultFabricFootprint(step);
		}
	}

	private defaultFabricTpWrapper(step: StepReferenceModel): void {
		this.transportWrapperData.push(new TransportStepsDataModel());
		const lastIndex = this.transportWrapperData.length - 1;
		this.transportWrapperData[lastIndex].stepID = step.pcStepUniqueId !== Guid.Empty ? step.pcStepUniqueId : step.mcStepUniqueId;
		this.transportWrapperData[lastIndex].referenceNo = step.refNo;
		this.transportWrapperData[lastIndex].supplierName = step.toCompanyName;
		this.transportWrapperData[lastIndex].showWrapper = false;
		this.transportWrapperData[lastIndex].referenceNoPrefix = step.footprintRefNoPrefix;
		this.transportWrapperData[lastIndex].supplierCompanyId = step.toCompanyId;
		if((this.fabricTransport.length === 0) || (this.fabricTransport.length > 0 && this.fabricTransport.filter(y => y.stepID === this.transportWrapperData[lastIndex].stepID).length === 0)){
			this.defaultFabricTransport(step);
		}
	}

	private get agentPermissions(): boolean {
		return FootprintPermissionsHelper.agentFootprintRole(this.companyType);
	}

	// private getAgentPermissions(filledBy: string): boolean {
	// 	return this.companyType === 5 ? FootprintPermissionsHelper.getAgentFootprintRole(this.companyType, filledBy, this.userCompanyId): false;
	// }

	private isFilledByPrimarySource(filledBy: string, toCompanyID: string): boolean {
		//Spint-22 TTD-3942
		if(this.tabIndex === 1){
			return true;
		}
		if (filledBy === Guid.Empty || filledBy === '') {
			return false;
		} else if((filledBy !== Guid.Empty && filledBy !== '') && (filledBy === this.userCompanyId || this.userCompanyId === toCompanyID)) {
			return false;
		} else {
			return true;
		}
		// return filledBy !== Guid.Empty && filledBy !== '' && filledBy === toCompanyID ? filledBy === this.userCompanyId ? false : true : false;
	}

	private canDeleteYarn(fabric: FabricFootprintModel): boolean {
		const wrapper = this.fabricWrapperData.filter(w => w.stepID === fabric.stepID);
		if(wrapper.length > 0){
			if(this.userCompanyId === wrapper[0].supplierCompanyId || fabric.filledByCompanyID === this.userCompanyId){
				return true;
			}
		}
		return false;
	}

	private canDeleteYarnFiber(yarn: YarnModel, fabric: FabricFootprintModel): boolean {
		const wrapper = this.fabricWrapperData.filter(w => w.stepID === fabric.stepID);
		if(wrapper.length > 0){
			if(this.userCompanyId === wrapper[0].supplierCompanyId || yarn.filledByCompanyID === this.userCompanyId){
				return true;
			}
		}
		return false;
	}

	private canDeleteFabricFiber(fiber: FiberModel, fabric: FabricFootprintModel): boolean {
		const wrapper = this.fabricWrapperData.filter(w => w.stepID === fabric.stepID);
		if(wrapper.length > 0){
			if(this.userCompanyId === wrapper[0].supplierCompanyId || fiber.filledByCompanyID === this.userCompanyId){
				return true;
			}
		}
		return false;
	}

	private canDeleteModeTrnsprt(mode: ModeOfTransportModel, wrapperStepID: string): boolean {
		const wrapper = this.fabricWrapperData.filter(yw => yw.stepID === wrapperStepID);
		if(this.userCompanyId === wrapper[0].supplierCompanyId || (mode.distance.filledByCompanyID === this.userCompanyId && mode.distanceUnit.filledByCompanyID === this.userCompanyId && mode.transportType.filledByCompanyID === this.userCompanyId)){
			return true;
		}
		return false;
	}

	private isFiledByClient(filledBy: string): boolean {
		if(this.footprintOrder.fromCompanyUser.ID === filledBy && this.tabIndex !==1){
			return true;
		}
		return false;
	}

    private defaultFabricFootprint(step: StepReferenceModel): void {
		// let fabricFootprintCount = this.allFootprintSteps.filter(step => this.isFabricFootprint(step.stepId) && step.toCompanyId!==Guid.Empty).length
		// for (let i = 0; i < fabricFootprintCount; i++) {
		// 	this.fabricFootprint.push(new FabricFootprintModel());
		// }
		this.fabricFootprint.push(new FabricFootprintModel());
		const lastIndex = this.fabricFootprint.length - 1;
		// this.fabricFootprint.forEach(fabric => {
		this.fabricFootprint[lastIndex].ID = uuidv4();
		this.fabricFootprint[lastIndex].stepID = step.pcStepUniqueId !== Guid.Empty ? step.pcStepUniqueId : step.mcStepUniqueId;
		this.fabricFootprint[lastIndex].toCompanyUser.toCompanyID = step.toCompanyId;
		this.fabricFootprint[lastIndex].toCompanyUser.toCompanyName = step.toCompanyName;
		this.fabricFootprint[lastIndex].toCompanyUser.filledByCompanyID = this.userCompanyId;
		this.fabricFootprint[lastIndex].toCompanyUser.filledByPrimarySource = step.toCompanyId === this.userCompanyId ? true : false;
		this.fabricFootprint[lastIndex].filledByCompanyID = this.userCompanyId;
		this.fabricFootprint[lastIndex].filledByPrimarySource = step.toCompanyId === this.userCompanyId ? true : false;
		this.fabricFootprint[lastIndex].yarnRefList = this.yarnRefList;
		this.fabricFootprint[lastIndex].showFabric = true;
		this.fabricFootprint[lastIndex].status = FootPrintCompletedConstant.INCOMPLETE;
		const fabricFootprint = this.fabricFootprint.filter(y => y.stepID === this.fabricFootprint[lastIndex].stepID);
		if(fabricFootprint.length > 1){
			this.fabricFootprint[lastIndex].sequence = fabricFootprint[fabricFootprint.length - 1].sequence + 1;
		} else {
			this.fabricFootprint[lastIndex].sequence = 1;
		}
		// });
		// this.showFabricFootprint = true;
	}

    private defaultFabricTransport(step: StepReferenceModel): void {
		const fabricTp: TransportFootprintModel = new TransportFootprintModel;
		fabricTp.ID = uuidv4();
		fabricTp.stepID = step.pcStepUniqueId !== Guid.Empty ? step.pcStepUniqueId : step.mcStepUniqueId;
		fabricTp.footprintID = Guid.Empty;
		fabricTp.modeOfTransport.push(new ModeOfTransportModel());
		fabricTp.toCompanyUser.toCompanyID = step.toCompanyId;
		fabricTp.toCompanyUser.toCompanyName = step.toCompanyName;
		fabricTp.toCompanyUser.filledByCompanyID = this.userCompanyId;
		fabricTp.toCompanyUser.filledByPrimarySource = step.toCompanyId === this.userCompanyId ? true : false;
		fabricTp.filledByCompanyID = this.userCompanyId;
		fabricTp.filledByPrimarySource = step.toCompanyId === this.userCompanyId ? true : false;
		fabricTp.status = FootPrintCompletedConstant.INCOMPLETE;
		this.fabricTransport.push(fabricTp);
		this.checkTransportEmptyFields();
	}

    private toggleFabricFootprint(): void {
		// if(this.fabricFpChangedCounter > 0){
		// 	this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
		// }
		// else{
			this.showFabricFootprint = !this.showFabricFootprint;
			if(this.showFabricFootprint){
				this.showFabricTransport = false;
			}
		// }
	}

    private toggleFabric(index: number): void {
		if(this.fabricFootprint[index]){
			this.fabricFootprint[index].showFabric = !this.fabricFootprint[index].showFabric;
		}
	}

	private checkUniqueness(value: string): boolean {
		const count = this.fabricFootprint.filter(f => f.reference.referenceNo === value).length;
		return count > 1 ? false : true;
	}

	private yarnRefOptions(index: number, indexWrapper: number): DropdownModel[] {
		let refList:DropdownModel[] = [];
		if(this.isWetProcessPartner){
			refList = this.yarnRefList;
		}else{
			refList = lodash.cloneDeep(this.fabricWrapperData[indexWrapper].yarnRefList);
		}
		refList.forEach(r => {
			if(this.fabricFootprint[index].yarnFabric.findIndex(y => y.yarnRefNumber.yarnID === r.value) !== -1 || r.value === Guid.Empty){
				r.disabled = true;
			}
			else{
				r.disabled = false;
			}
		});
		return refList;
	}

    private async validateFabricFootprint(event: any, fieldname: string, fabricIndex: number, childIndex: number, wrapperIndex: number): Promise<void> {
		switch (fieldname) {
            case "fabric-type": {
				event !== '' ? this.fabricFootprint[fabricIndex].fabricType.isValid = true : this.fabricFootprint[fabricIndex].fabricType.isValid = false;
				this.fabricFootprint[fabricIndex].fabricType.isValid === false || this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
                this.fabricFootprint[fabricIndex].fabricType.type = event;
				this.fabricFootprint[fabricIndex].fabricType.filledByCompanyID = this.fabricFootprint[fabricIndex].fabricType.type!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricFootprint[fabricIndex].fabricType.filledByPrimarySource = this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID === this.userCompanyId &&  this.fabricFootprint[fabricIndex].fabricType.type!=='' ? true : false;
				let total = 0;
				if(this.fabricFootprint[fabricIndex].fabricType.type !== 'NON-WOVEN-SB' && 
					this.fabricFootprint[fabricIndex].fabricType.type !== 'NON-WOVEN-NP'){
						if(this.fabricFootprint[fabricIndex].yarnFabric.length > 0){
							// this.fabricFootprint[fabricIndex].yarnFabric.push(new YarnModel());
							this.fabricFootprint[fabricIndex].yarnFabric.forEach(y => {
								y.ID = uuidv4();
								y.filledByCompanyID = this.userCompanyId;
								y.filledByPrimarySource = this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
							});
						}
						this.fabricFootprint[fabricIndex].yarnFabric.forEach(y => {
							if(y.yarnPercent.count !== ""){
								total = total + (Number(y.yarnPercent.count) ? Number(y.yarnPercent.count) : 0);
							}
						});
						this.fabricFootprint[fabricIndex].total.totalcount = total;
				}else{
					if(this.fabricFootprint[fabricIndex].fiberFabric.length > 0){
						// this.fabricFootprint[fabricIndex].fiberFabric.push(new FiberModel());
						this.fabricFootprint[fabricIndex].fiberFabric.forEach(w => {
							w.ID = uuidv4();
							w.filledByCompanyID = this.userCompanyId;
							w.filledByPrimarySource = this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
						});
					}
					this.fabricFootprint[fabricIndex].fiberFabric.forEach(fiber => {
						total = total + (Number(fiber.fiberValue.value) ? Number(fiber.fiberValue.value) : 0) ;
					});
					this.fabricFootprint[fabricIndex].total.totalcount = total;
				}
				this.fabricFootprint[fabricIndex].yarnRefList = lodash.cloneDeep(this.yarnRefList);
                break;
			}
			case "reference": {
				/* eslint-disable-next-line */
				!event.target.value.match(/^[a-zA-Z0-9- ]+(?:[\w -]*[a-zA-Z0-9- ]+)*$/) || event.target.value === '' ? this.fabricFootprint[fabricIndex].reference.isValid = false : this.fabricFootprint[fabricIndex].reference.isValid = true;
				if(event.target.value !== ''){
					this.fabricFootprint[fabricIndex].reference.isUnique = this.checkUniqueness(event.target.value);
				} else {
					this.fabricFootprint[fabricIndex].reference.isUnique = true;
				}
				this.fabricFootprint[fabricIndex].reference.isValid === false || this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.fabricFootprint[fabricIndex].reference.referenceNo = event.target.value;
				this.fabricFootprint[fabricIndex].reference.filledByCompanyID = this.fabricFootprint[fabricIndex].reference.referenceNo!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricFootprint[fabricIndex].reference.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].reference.referenceNo!=='' ? true : false;
                break;
			}
			case "yarn-ref": {
				event !== '' ? this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnRefNumber.isValid = true : this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnRefNumber.isValid = false;
				this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnRefNumber.isValid === false || this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
                const selectedYarnRef = this.fabricWrapperData[wrapperIndex].yarnRefList.filter(y => y.value === event)[0];
				this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnRefNumber.referenceNumber = selectedYarnRef.text;
				this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnRefNumber.yarnID = selectedYarnRef.value.toString();
				this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnRefNumber.filledByCompanyID = this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnRefNumber.referenceNumber!=='' ? this.userCompanyId : Guid.Empty;
                this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnRefNumber.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnRefNumber.referenceNumber!=='' ? true : false;
                break;
			}
			case "yarn-percent": {
				this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnPercent.count = event.target.value !== "" ? parseInt(event.target.value) ? parseInt(event.target.value) : "" : "";
				if(Number(this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnPercent.count) <= 100 || this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnPercent.count === ''){
					this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnPercent.isValid = true;
					this.disableSave = false;
				} else {
					this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnPercent.isValid = false;
					this.disableSave = true;
				}
				this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnPercent.filledByCompanyID = this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnPercent.count!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnPercent.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnPercent.count!=='' ? true : false;
                let total = 0;
                this.fabricFootprint[fabricIndex].yarnFabric.forEach(y => {
                    if(y.yarnPercent.count !== ""){
                        total = total + (Number(y.yarnPercent.count) ? Number(y.yarnPercent.count) : 0);
                    }
                });
                this.fabricFootprint[fabricIndex].total.totalcount = total;
                this.fabricFootprint[fabricIndex].total.filledByCompanyID = this.userCompanyId;
				this.fabricFootprint[fabricIndex].total.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID ? true : false;
                this.fabricFootprint[fabricIndex].total.totalcount === 100 ? this.fabricFootprint[fabricIndex].total.isValid = true : this.fabricFootprint[fabricIndex].total.isValid = false;
                !this.fabricFootprint[fabricIndex].yarnFabric[childIndex].yarnPercent.isValid ? this.disableSave=true : this.disableSave=false;
				!this.fabricFootprint[fabricIndex].total.isValid ? this.disableSave=true : this.disableSave=false;
                break;
			}
			case "fabric-weight": {
				/* eslint-disable-next-line */
				(event.target.value.match(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/) && event.target.value.toString().length <= 10) || event.target.value === '' ? this.fabricFootprint[fabricIndex].fabricWeight.isValid = true : this.fabricFootprint[fabricIndex].fabricWeight.isValid = false;
				this.fabricFootprint[fabricIndex].fabricWeight.isValid === false || this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.fabricFootprint[fabricIndex].fabricWeight.weight = event.target.value!=='' && !isNaN(event.target.value) ? parseFloat(event.target.value) : '';
				this.fabricFootprint[fabricIndex].fabricWeight.filledByCompanyID = this.fabricFootprint[fabricIndex].fabricWeight.weight!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricFootprint[fabricIndex].fabricWeight.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].fabricWeight.weight!=='' ? true : false;
                break;
			}
			case "fabric-weight-unit": {
				event !== '' ? this.fabricFootprint[fabricIndex].fabricUnit.isValid = true : this.fabricFootprint[fabricIndex].fabricUnit.isValid = false;
				this.fabricFootprint[fabricIndex].fabricUnit.isValid === false || this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.fabricFootprint[fabricIndex].fabricUnit.unit = event;
				this.fabricFootprint[fabricIndex].fabricUnit.filledByCompanyID = this.fabricFootprint[fabricIndex].fabricUnit.unit!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricFootprint[fabricIndex].fabricUnit.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].fabricUnit.unit!=='' ? true : false;
                break;
			}
            case "fabric-width": {
				/* eslint-disable-next-line */
				(event.target.value.match(/^[0-9]*$/) && event.target.value.toString().length <= 10) || event.target.value === ''? this.fabricFootprint[fabricIndex].fabricWidth.isValid = true : this.fabricFootprint[fabricIndex].fabricWidth.isValid = false;
				this.fabricFootprint[fabricIndex].fabricWidth.isValid === false || this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.fabricFootprint[fabricIndex].fabricWidth.width = event.target.value;
				this.fabricFootprint[fabricIndex].fabricWidth.filledByCompanyID = this.fabricFootprint[fabricIndex].fabricWidth.width!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricFootprint[fabricIndex].fabricWidth.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].fabricWidth.width!=='' ? true : false;
                break;
			}
			case "electrical-energy": {
				/* eslint-disable-next-line */
				this.fabricFootprint[fabricIndex].totalElectricEnergy.value = parseInt(event.target.value) === 0 || parseInt(event.target.value) ? parseInt(event.target.value) : "";
				if(this.fabricFootprint[fabricIndex].totalElectricEnergy.value === '' || isNumber(this.fabricFootprint[fabricIndex].totalElectricEnergy.value)){
					this.fabricFootprint[fabricIndex].totalElectricEnergy.isValid = true;
					this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave = true : this.disableSave = false;
				} else {
					this.fabricFootprint[fabricIndex].totalElectricEnergy.isValid = false;
					this.disableSave = true;
				}
				this.fabricFootprint[fabricIndex].totalElectricEnergy.filledByCompanyID = this.userCompanyId;
				this.fabricFootprint[fabricIndex].totalElectricEnergy.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID ? true : false;
				break;
			}
			case "wind-energy": {
				this.fabricFootprint[fabricIndex].windEnergy.value = parseInt(event.target.value) === 0 || parseInt(event.target.value) ? parseInt(event.target.value) : "";
				if(Number(this.fabricFootprint[fabricIndex].windEnergy.value) <= 100 || this.fabricFootprint[fabricIndex].windEnergy.value === ''){
					this.fabricFootprint[fabricIndex].windEnergy.isValid = true;
					this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave = true : this.disableSave = false;
				} else {
					this.fabricFootprint[fabricIndex].windEnergy.isValid = false;
					this.disableSave = true;
				}
				// if (event.target.value !== '') {
					this.fabricFootprint[fabricIndex].windEnergy.filledByCompanyID = this.fabricFootprint[fabricIndex].windEnergy.value!=='' && this.fabricFootprint[fabricIndex].windEnergy.value!==0 ? this.userCompanyId : Guid.Empty;
					this.fabricFootprint[fabricIndex].windEnergy.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].windEnergy.value!=='' &&
					this.fabricFootprint[fabricIndex].windEnergy.value!==0 ? true : false;
				// }
				break;
			}
			case "solar-energy": {
				this.fabricFootprint[fabricIndex].solarEnergy.value = parseInt(event.target.value) === 0 || parseInt(event.target.value) ? parseInt(event.target.value) : "";
				if(Number(this.fabricFootprint[fabricIndex].solarEnergy.value) <= 100 || this.fabricFootprint[fabricIndex].solarEnergy.value === ''){
					this.fabricFootprint[fabricIndex].solarEnergy.isValid = true;
					this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave = true : this.disableSave = false;
				} else {
					this.fabricFootprint[fabricIndex].solarEnergy.isValid = false;
					this.disableSave = true;
				}
				this.fabricFootprint[fabricIndex].solarEnergy.filledByCompanyID = this.fabricFootprint[fabricIndex].solarEnergy.value!=='' && this.fabricFootprint[fabricIndex].solarEnergy.value!==0 ? this.userCompanyId : Guid.Empty;
				this.fabricFootprint[fabricIndex].solarEnergy.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].solarEnergy.value!=='' &&
				this.fabricFootprint[fabricIndex].solarEnergy.value!==0 ? true : false;
				break;
			}
			case "fiber-type": {
				event !== '' ? this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberType.isValid = true : this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberType.isValid = false;
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberType.isValid === false || this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberType.type = event;
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberType.filledByCompanyID = this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberType.type!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberType.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberType.type!=='' ? true : false;
                break;
			}
			case "fiber-value": {
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberValue.value = event.target.value!=='' ? parseInt(event.target.value) ? parseInt(event.target.value): "" : "";
				if(Number(this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberValue.value) <= 100 || this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberValue.value === ''){
					this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberValue.isValid = true;
					this.disableSave = false;
				}
				else{
					this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberValue.isValid = false;
					this.disableSave = true;
				}
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberValue.filledByCompanyID = this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberValue.value!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberValue.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberValue.value!=='' ? true : false;
				let totalFiber = 0;
				this.fabricFootprint[fabricIndex].fiberFabric.forEach(fiber => {
					totalFiber = totalFiber + (Number(fiber.fiberValue.value) ? Number(fiber.fiberValue.value) : 0) ;
				});
				this.fabricFootprint[fabricIndex].total.totalcount = totalFiber;
				this.fabricFootprint[fabricIndex].total.filledByCompanyID = this.userCompanyId;
				this.fabricFootprint[fabricIndex].total.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID ? true : false;
				this.fabricFootprint[fabricIndex].total.totalcount === 100 ? this.fabricFootprint[fabricIndex].total.isValid = true : this.fabricFootprint[fabricIndex].total.isValid = false;
				!this.fabricFootprint[fabricIndex].fiberFabric[childIndex].fiberValue.isValid ? this.disableSave=true : this.disableSave=false;
				!this.fabricFootprint[fabricIndex].total.isValid ? this.disableSave=true : this.disableSave=false;
                break;
			}
			case "fiber-origin": {
				event !== '' ? this.fabricFootprint[fabricIndex].fiberFabric[childIndex].originOfFiber.isValid = true : this.fabricFootprint[fabricIndex].fiberFabric[childIndex].originOfFiber.isValid = false;
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].originOfFiber.isValid === false || this.fabricFootprint[fabricIndex].total.isValid === false ? this.disableSave=true : this.disableSave=false;
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].originOfFiber.value = event;
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].originOfFiber.filledByCompanyID = this.fabricFootprint[fabricIndex].fiberFabric[childIndex].originOfFiber.value!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricFootprint[fabricIndex].fiberFabric[childIndex].originOfFiber.filledByPrimarySource = this.userCompanyId === this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID && this.fabricFootprint[fabricIndex].fiberFabric[childIndex].originOfFiber.value!=='' ? true : false;
                break;
			}
			default:
                break;
        }
		this.compareFabricFootprintClone();
		this.checkNonEmptyFields();
    }

	private async validateFabricFootprintRound(event: any, fieldname: string, yarnIndex: number): Promise<void> {
		switch (fieldname) {
			case 'electrical-energy': {
				const val = event.target.value;
				let rounded = val;
				if (val.indexOf('.')!==-1 && val.indexOf('.') !== val.length-1) {
					rounded = Number(val).toFixed(2);
				}
				this.fabricFootprint[yarnIndex].totalElectricEnergy.value = rounded !== '' && !isNaN(rounded) ? parseFloat(rounded) : '';
				this.fabricFootprint[yarnIndex].totalElectricEnergy.filledByCompanyID = rounded!=='' && rounded!=='0'  ? this.fabricFootprint[yarnIndex].totalElectricEnergy.filledByCompanyID : Guid.Empty;
				this.fabricFootprint[yarnIndex].totalElectricEnergy.filledByPrimarySource = rounded!=='' && rounded!=='0' ? this.fabricFootprint[yarnIndex].totalElectricEnergy.filledByPrimarySource : false;
				break;
			}
			default:
				break;
		}
	}

	private fabricFpStatus(): void {
		const footprintOrder = this.footprintOrders.filter(o => o.orderId === this.orderDetail.orderId)[0];
		if(footprintOrder.fabric.footprintStatus === FootPrintCompletedConstant.COMPLETED){
			this.footprintStatus = 'green';
		}
		else if(footprintOrder.fabric.footprintStatus === FootPrintCompletedConstant.PARTIAL){
			this.footprintStatus = 'orange';
		}
		else{
			this.footprintStatus = 'red';
		}
	}

	private fabricTpStatus(): void {
		// const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		const footprintOrder = this.footprintOrders.filter(o => o.orderId === this.orderDetail.orderId)[0];
		if(footprintOrder.fabric.transportStatus === FootPrintCompletedConstant.COMPLETED){
			let tpEmptyField = false;
			this.fabricTransport.forEach(tp => {
				tp.modeOfTransport.forEach(mode => {
					if(mode.transportType.value === '' || mode.distance.value === '' || mode.distanceUnit.unit === ''){
						tpEmptyField = true;
					}
				});
			});
			if(tpEmptyField){
				this.transportStatus = 'orange';
			}
			else{
				this.transportStatus = 'green';
			}
		}
		else if(footprintOrder.fabric.transportStatus === FootPrintCompletedConstant.PARTIAL){
			this.transportStatus = 'orange';
		}
		else{
			this.transportStatus = 'red';
		}
	}

    private addFabric(fabricWrapper: FabricStepsDataModel){
		if(this.userRole === "Reader" || this.isSaving || this.isWetProcessPartner){
			return;
		}
        this.fabricFootprint.push(new FabricFootprintModel());
		this.fabricFootprint.forEach((fabric, index) => {
			if(index === (this.fabricFootprint.length - 1)){
				fabric.ID = uuidv4();
				fabric.stepID = fabricWrapper.stepID;
				fabric.filledByCompanyID = this.userCompanyId;
				fabric.filledByPrimarySource = fabricWrapper.supplierCompanyId === this.userCompanyId ? true : false;
				fabric.toCompanyUser.toCompanyID = fabricWrapper.supplierCompanyId;
				fabric.toCompanyUser.toCompanyName = fabricWrapper.supplierName;
				fabric.toCompanyUser.filledByCompanyID = this.userCompanyId;
				fabric.toCompanyUser.filledByPrimarySource = fabricWrapper.supplierCompanyId === this.userCompanyId ? true : false;
				fabric.yarnRefList = lodash.cloneDeep(this.yarnRefList);
				fabric.showFabric = true;
				const fabricFootprint = this.fabricFootprint.filter(f => f.stepID === fabric.stepID);
				if(fabricFootprint.length > 1){
					fabric.sequence = fabricFootprint[fabricFootprint.length - 1].sequence + 1;
				} else {
					fabric.sequence = 1;
				}
			}
			else{
				fabric.showFabric = false;
			}
		});
		this.fabricFootprintClone.push(new FabricFootprintModel());
		this.fabricFootprintClone.forEach((fabric, index) => {
			if(index === (this.fabricFootprint.length - 1)){
				fabric.ID = uuidv4();
				fabric.stepID = fabricWrapper.stepID;
				fabric.filledByCompanyID = this.userCompanyId;
				fabric.filledByPrimarySource = fabricWrapper.supplierCompanyId === this.userCompanyId ? true : false;
				fabric.toCompanyUser.toCompanyID = fabricWrapper.supplierCompanyId;
				fabric.toCompanyUser.toCompanyName = fabricWrapper.supplierName;
				fabric.toCompanyUser.filledByCompanyID = this.userCompanyId;
				fabric.toCompanyUser.filledByPrimarySource = fabricWrapper.supplierCompanyId === this.userCompanyId ? true : false;
				fabric.yarnRefList = lodash.cloneDeep(this.yarnRefList);
				fabric.showFabric = true;
				const fabricFootprint = this.fabricFootprint.filter(f => f.stepID === fabric.stepID);
				if(fabricFootprint.length > 1){
					fabric.sequence = fabricFootprint[fabricFootprint.length - 1].sequence + 1;
				} else {
					fabric.sequence = 1;
				}
			}
			else{
				fabric.showFabric = false;
			}
		});
		this.compareFabricFootprintClone();
		this.checkNonEmptyFields();
    }

    private deleteFabric(index:number){
        this.fabricFootprint.splice(index, 1);
        this.fabricFootprintClone.splice(index, 1);
		this.checkNonEmptyFields();
    }

    private addFabricYarn(id:string): void {
		if(this.userRole === "Reader" || this.isSaving || this.isWetProcessPartner){
			return;
		}
		const yarnIndex = this.fabricFootprint.findIndex(fb => fb.ID === id);
		if(yarnIndex!==-1){
			this.fabricFootprint[yarnIndex].yarnFabric.push(new YarnModel);
			this.fabricFootprint[yarnIndex].yarnFabric[this.fabricFootprint[yarnIndex].yarnFabric.length - 1].ID = uuidv4();
			this.fabricFootprint[yarnIndex].yarnFabric[this.fabricFootprint[yarnIndex].yarnFabric.length - 1].filledByCompanyID = this.userCompanyId;
			this.fabricFootprint[yarnIndex].yarnFabric[this.fabricFootprint[yarnIndex].yarnFabric.length - 1].filledByPrimarySource = this.fabricFootprint[yarnIndex].toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
		}
		const yarnCloneIndex = this.fabricFootprintClone.findIndex(fb => fb.ID === id);
		if(yarnCloneIndex!==-1){
			this.fabricFootprintClone[yarnCloneIndex].yarnFabric.push(new YarnModel);
			this.fabricFootprintClone[yarnCloneIndex].yarnFabric[this.fabricFootprintClone[yarnCloneIndex].yarnFabric.length - 1].ID = uuidv4();
			this.fabricFootprintClone[yarnCloneIndex].yarnFabric[this.fabricFootprintClone[yarnCloneIndex].yarnFabric.length - 1].filledByCompanyID = this.userCompanyId;
			this.fabricFootprintClone[yarnCloneIndex].yarnFabric[this.fabricFootprintClone[yarnCloneIndex].yarnFabric.length - 1].filledByPrimarySource = this.fabricFootprintClone[yarnIndex].toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
		}
		this.compareFabricFootprintClone();
		this.checkNonEmptyFields();
	}

	private addModeTransport(stepID: string): void {
		if(this.isSaving || this.userRole === 'Reader' || this.isWetProcessPartner){
			return;
		}
		const tpIndex = this.fabricTransport.findIndex(tp => tp.stepID === stepID);
		this.fabricTransport[tpIndex].modeOfTransport.push(new ModeOfTransportModel);
		this.fabricTransportClone[tpIndex].modeOfTransport.push(new ModeOfTransportModel);
		this.compareFabricTransportClone();
		this.checkTransportEmptyFields();
	}

	private removeFabricFootprint(index: number): void {
		this.isTabRemoved = true;
		this.fabricFootprint.splice(index, 1);
		this.fabricFootprintClone.splice(index, 1);
		this.FpDeletedCounter++;
		this.compareFabricFootprintClone();
		this.checkNonEmptyFields();
		this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA);
		this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.FABRIC_FP);
	}

	private removeFabricYarn(index: number, yarnIndex: number): void {
		if(this.fabricFootprint[index].yarnFabric[yarnIndex].yarnPercent.count !== ""){
			this.fabricFootprint[index].total.totalcount = Number(this.fabricFootprint[index].total.totalcount) - Number(this.fabricFootprint[index].yarnFabric[yarnIndex].yarnPercent.count);
			if(this.fabricFootprint[index].total.totalcount !== 100){
				this.fabricFootprint[index].total.isValid = false;
				this.disableSave = true;
			}
			else{
				this.fabricFootprint[index].total.isValid = true;
			}
			this.fabricFootprintClone[index].total.totalcount = Number(this.fabricFootprintClone[index].total.totalcount) - Number(this.fabricFootprintClone[index].yarnFabric[yarnIndex].yarnPercent.count);
			if(this.fabricFootprintClone[index].total.totalcount !== 100){
				this.fabricFootprintClone[index].total.isValid = false;
				this.disableSave = true;
			}
		}
		this.fabricFootprint[index].yarnFabric.splice(yarnIndex, 1);
		this.fabricFootprintClone[index].yarnFabric.splice(yarnIndex, 1);
		this.compareFabricFootprintClone();
		this.checkNonEmptyFields();
	}

	private addFabricFiber(Id:string){
		if(this.userRole === "Reader" || this.isSaving){
			return;
		}
		const fabricIndex = this.fabricFootprint.findIndex(fb => fb.ID === Id);
		if(fabricIndex!==-1){
			this.fabricFootprint[fabricIndex].fiberFabric.push(new FiberModel);
			this.fabricFootprint[fabricIndex].fiberFabric[this.fabricFootprint[fabricIndex].fiberFabric.length - 1].ID = uuidv4();
			this.fabricFootprint[fabricIndex].fiberFabric[this.fabricFootprint[fabricIndex].fiberFabric.length - 1].filledByCompanyID = this.userCompanyId;
			this.fabricFootprint[fabricIndex].fiberFabric[this.fabricFootprint[fabricIndex].fiberFabric.length - 1].filledByPrimarySource = this.fabricFootprint[fabricIndex].toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
		}
		const fabricCloneIndex = this.fabricFootprintClone.findIndex(fb => fb.ID === Id);
		if(fabricCloneIndex!==-1){
			this.fabricFootprintClone[fabricCloneIndex].fiberFabric.push(new FiberModel);
			this.fabricFootprintClone[fabricCloneIndex].fiberFabric[this.fabricFootprintClone[fabricCloneIndex].fiberFabric.length - 1].ID = uuidv4();
			this.fabricFootprintClone[fabricCloneIndex].fiberFabric[this.fabricFootprintClone[fabricCloneIndex].fiberFabric.length - 1].filledByCompanyID = this.userCompanyId;
			this.fabricFootprintClone[fabricCloneIndex].fiberFabric[this.fabricFootprintClone[fabricCloneIndex].fiberFabric.length - 1].filledByPrimarySource = this.fabricFootprintClone[fabricCloneIndex].toCompanyUser.toCompanyID === this.userCompanyId ? true : false;
		}
		this.compareFabricFootprintClone();
		this.checkNonEmptyFields();
	}

	private removeFabricFiber(fabricIndex: number, fiberindex: number): void {
		if(this.fabricFootprint[fabricIndex].fiberFabric[fiberindex].fiberValue.value !== ""){
			this.fabricFootprint[fabricIndex].total.totalcount = Number(this.fabricFootprint[fabricIndex].total.totalcount) - Number(this.fabricFootprint[fabricIndex].fiberFabric[fiberindex].fiberValue.value);
			if(this.fabricFootprint[fabricIndex].total.totalcount !== 100){
				this.fabricFootprint[fabricIndex].total.isValid = false;
				this.disableSave = true;
			}
			else{
				this.fabricFootprint[fabricIndex].total.isValid = true;
			}
			this.fabricFootprintClone[fabricIndex].total.totalcount = Number(this.fabricFootprintClone[fabricIndex].total.totalcount) - Number(this.fabricFootprintClone[fabricIndex].fiberFabric[fiberindex].fiberValue.value);
			if(this.fabricFootprintClone[fabricIndex].total.totalcount !== 100){
				this.fabricFootprintClone[fabricIndex].total.isValid = false;
				this.disableSave = true;
			}
		}
		this.fabricFootprint[fabricIndex].fiberFabric.splice(fiberindex, 1);
		this.fabricFootprintClone[fabricIndex].fiberFabric.splice(fiberindex, 1);
		this.compareFabricFootprintClone();
		this.checkNonEmptyFields();
	}

    private  dataChanged(): void {
		this.compareFabricFootprintClone();
	}

    private compareFabricFootprintClone() {
		let changes: number = 0;
		const fabric: [string, any][] = Object.entries(this.fabricFootprint);
		const fabricClone: [string, any][] = Object.entries(this.fabricFootprintClone);

		const getObjectDiff = (obj1: any, obj2: any) => {
		const obj1Props = Object.keys(obj1);
		const obj2Props = Object.keys(obj2);

		const keysWithDiffValue = obj1Props.reduce((keysWithDiffValueAccumulator, key) => {
			// eslint-disable-next-line no-prototype-builtins
			const propExistsOnObj2 = obj2.hasOwnProperty(key);
			const hasNestedValue = obj1[key] instanceof Object && obj2[key] instanceof Object;
			const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];

			if (!propExistsOnObj2) {
			keysWithDiffValueAccumulator.push(key);
			} else if (hasNestedValue) {
			const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
			if (keyIndex >= 0) {
				keysWithDiffValueAccumulator.splice(keyIndex, 1);
			}
			const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
			for (const diff of nestedDiffs) {
				keysWithDiffValueAccumulator.push(`${key}.${diff}`);
			}
			} else if (keyValuePairBetweenBothObjectsIsEqual) {
			const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
			keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
			}
			return keysWithDiffValueAccumulator;
		}, obj2Props);

		return keysWithDiffValue;
		};
		const differences = getObjectDiff(fabric, fabricClone);
		changes += differences.length;
		differences.forEach(element => {
			if (element.includes('status') || element.includes('.ID') || element.includes('isUnique') || element.includes('yarnID') || element.includes('disabled') || element.includes('isValid') || element.includes('filledByCompanyID') || element.includes('filledByPrimarySource') || element.includes('toCompanyName') || element.includes('showFabric') || element.includes('energySupportingDocument')) {
				changes = changes-1;
			}
		});
		this.fabricFpChangedCounter = changes + this.FpDeletedCounter;
		changes > 0? this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA): '';
		changes > 0? this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.FABRIC_FP): '';
	}

	private fabricWrapperFpStatus(stepID: string): string {
		let status = 'red';
		let count = 0;
		let filledCount = 0;
		const relativeFabrics = this.fabricFootprint.filter(y => y.stepID === stepID);
		relativeFabrics.forEach(fabric => {
			let fabricCount = 0;
			let fabricFilledCount = 0;
			if(fabric.reference.referenceNo === ""){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
			if(fabric.fabricType.type === ''){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
			if(fabric.total.totalcount === ""){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
			if(fabric.fabricWidth.width === ""){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
			if(fabric.totalElectricEnergy.value === ""){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
			if(fabric.windEnergy.value === "" ){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
			if(fabric.solarEnergy.value === "" ){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
			if (fabric.fabricType.type !== 'NON-WOVEN-SB' && fabric.fabricType.type !== 'NON-WOVEN-NP') {
				fabric.yarnFabric.forEach(yarn => {
					if(yarn.yarnRefNumber.referenceNumber === '' ){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
					if(yarn.yarnPercent.count === '' ){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
				});
				if(fabric.fabricUnit.unit === '' ){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
				if(fabric.fabricWeight.weight === '' ){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
			}
			if (fabric.fabricType.type === 'NON-WOVEN-SB' || fabric.fabricType.type === 'NON-WOVEN-NP') {
				fabric.fiberFabric.forEach(fiber => {
					if(fiber.fiberType.type === '' ){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
					if(fiber.fiberValue.value === '' ){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
					if(fiber.originOfFiber.value === '' ){count++; fabricCount++;} else {filledCount++;fabricFilledCount++;}
				});
			}
			const stepIndex = this.fabricFootprint.findIndex(y => y.stepID === stepID && y.ID === fabric.ID);
			if(stepIndex !== -1){
				if (fabricCount>0 && fabricFilledCount>0) {
					this.fabricFootprint[stepIndex].status = FootPrintCompletedConstant.PARTIAL;
				} else if(fabricCount === 0) {
					this.fabricFootprint[stepIndex].status = FootPrintCompletedConstant.COMPLETED;
				} else {
					this.fabricFootprint[stepIndex].status = FootPrintCompletedConstant.INCOMPLETE;
				}
			}
		})
		if (count>0 && filledCount>0) {
			status = "orange";
		} else if(count === 0) {
			status = "green";
		}
		return status;
	}

	private fabricWrapperTpStatus(stepID: string): string {
		let status = 'red';
		let count = 0;
		let filledCount = 0;
		const relativeTrans = this.fabricTransport.filter(y => y.stepID === stepID);
		relativeTrans.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					count++;
				}
				if(mode.distance.value !== '' || mode.distanceUnit.unit !== '' || mode.transportType.value !== ''){
					filledCount++;
				}
			});
			const tpIndex = this.fabricTransport.findIndex(y => y.stepID === stepID && y.ID === tp.ID);
			if(tpIndex !== -1){
				if (count>0 && filledCount>0) {
					this.fabricTransport[tpIndex].status = FootPrintCompletedConstant.PARTIAL;
				} else if(count === 0) {
					this.fabricTransport[tpIndex].status = FootPrintCompletedConstant.COMPLETED;
				} else {
					this.fabricTransport[tpIndex].status = FootPrintCompletedConstant.INCOMPLETE;
				}
			}
		})
		if (count>0 && filledCount>0) {
			status = "orange";
		} else if(count === 0) {
			status = "green";
		}
		return status;
	}

	private get showWarningFootprint(): boolean {
		let count = 0;
		const finalFabricFp = lodash.cloneDeep(this.fabricFootprint);
		finalFabricFp.forEach((fabric, index) => {
			if(fabric.fabricType.type === ''){
				count++;
			}
			if(fabric.toCompanyUser.toCompanyName === ''){
				count++;
			}
			if(fabric.reference.referenceNo === ''){
				count++;
			}
			if (fabric.fabricType.type !== 'NON-WOVEN-SB' && fabric.fabricType.type !== 'NON-WOVEN-NP') {
				fabric.yarnFabric.forEach(yarn => {
					if(yarn.yarnRefNumber.referenceNumber === ''){
						count++;
					}
					if(yarn.yarnPercent.count === ''){
						count++;
					}
					if (!yarn.yarnPercent.isValid || !yarn.yarnRefNumber.isValid) {
						this.disableSave = true;
					}
				});	
				if(fabric.fabricUnit.unit === ''){
					count++;
				}
				if(fabric.fabricWeight.weight === ''){
					count++;
				}
			}
			
			if (fabric.fabricType.type === 'NON-WOVEN-SB' || fabric.fabricType.type === 'NON-WOVEN-NP') {
				fabric.fiberFabric.forEach(fiber => {
					if(fiber.fiberType.type === ''){
						count++;
					}
					if(fiber.fiberValue.value === ''){
						count++;
					}
					if(fiber.originOfFiber.value === ''){
						count++;
					}
					if (!fiber.fiberType.isValid || !fiber.fiberValue.isValid || !fiber.originOfFiber.isValid) {
						this.disableSave = true;
					}
				});
			}
			if(fabric.total.totalcount === ''){
				count++;
			}
			if(fabric.fabricWidth.width === ''){
				count++;
			}
			if(fabric.totalElectricEnergy.value === ''){
				count++;
			}
			if(fabric.windEnergy.value === ''){
				count++;
			}
			if(fabric.solarEnergy.value === ''){
				count++;
			}
		});
		if (count > 0) {
			return true;
		}
		return false;
	}

	private get showWarningTransport(): boolean {
		let count = 0;
		this.fabricTransport.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					count++;
				}
			});
		});
		if (count > 0) {
			return true;
		}
		return false;
	}

	private checkNonEmptyFields(): void {
		this.isFabricFieldEmpty = false;
		this.disableSave = false;
		let finalFabricFp = lodash.cloneDeep(this.fabricFootprint);
		finalFabricFp = finalFabricFp.filter(f => this.fabricWrapperData.filter(w => w.stepID === f.stepID).length > 0);
		this.fabricWrapperData.forEach((wrapper) => {
			let filledField = false;
			finalFabricFp.filter(fabric => fabric.stepID === wrapper.stepID).forEach(fabric => {
				fabric.fabricType.type === "" ? this.isFabricFieldEmpty = true : filledField = true;
				// fabric.toCompanyUser.toCompanyName === "" ? this.isFabricFieldEmpty = true : filledField = true;
				fabric.reference.referenceNo === "" ? this.isFabricFieldEmpty = true : filledField = true;
				if (fabric.fabricType.type !== 'NON-WOVEN-SB' && fabric.fabricType.type !== 'NON-WOVEN-NP') {
					fabric.yarnFabric.forEach(yarn => {
						yarn.yarnRefNumber.referenceNumber === "" ? this.isFabricFieldEmpty = true : filledField = true;
						yarn.yarnPercent.count === "" ? this.isFabricFieldEmpty = true : filledField = true;
						// Sptint-22 TTD-4290
						if (!yarn.yarnPercent.isValid || !yarn.yarnRefNumber.isValid || !fabric.windEnergy.isValid || !fabric.solarEnergy.isValid || !fabric.total.isValid) {
							this.disableSave = true;
						}
					});
					fabric.fabricUnit.unit === "" ? this.isFabricFieldEmpty = true : filledField = true;	
					fabric.fabricWeight.weight === "" ? this.isFabricFieldEmpty = true : filledField = true;
				}
				
				if (fabric.fabricType.type === 'NON-WOVEN-SB' || fabric.fabricType.type === 'NON-WOVEN-NP') {
					fabric.fiberFabric.forEach(fiber => {
						fiber.fiberType.type === "" ? this.isFabricFieldEmpty = true : filledField = true;
						fiber.fiberValue.value === "" ? this.isFabricFieldEmpty = true : filledField = true;
						fiber.originOfFiber.value === "" ? this.isFabricFieldEmpty = true : filledField = true;
						// Sptint-22 TTD-4290
						if (!fiber.fiberType.isValid || !fiber.fiberValue.isValid || !fiber.originOfFiber.isValid || !fabric.windEnergy.isValid || !fabric.solarEnergy.isValid || !fabric.total.isValid) {
							this.disableSave = true;
						}
					});
				}
				fabric.total.totalcount === "" ? this.isFabricFieldEmpty = true : filledField = true;
				fabric.fabricWidth.width === "" ? this.isFabricFieldEmpty = true : filledField = true;
				fabric.totalElectricEnergy.value === "" ? this.isFabricFieldEmpty = true : filledField = true;
				fabric.windEnergy.value === "" ? this.isFabricFieldEmpty = true : filledField = true;
				fabric.solarEnergy.value === "" ? this.isFabricFieldEmpty = true : filledField = true;
				if(filledField && fabric.reference.referenceNo === ''){
					if ((!fabric.toCompanyUser.isValid || fabric.toCompanyUser.toCompanyID === '') || 
					(fabric.reference.referenceNo === '' || !fabric.reference.isValid) || 
					(fabric.reference.isUnique !== undefined && !fabric.reference.isUnique) ||
					!fabric.fabricType.isValid ||
					!fabric.fabricWeight.isValid ||
					!fabric.fabricWidth.isValid ||
					!fabric.solarEnergy.isValid ||
					!fabric.totalElectricEnergy.isValid ||
					!fabric.windEnergy.isValid || 
					!fabric.total.isValid
					) {
						this.disableSave = true;
					}
				}
			});
		});
		if(this.disableSave){
			this.$store.commit('setIncorrectFootprintData', true);
		}else{
			this.$store.commit('setIncorrectFootprintData', false);
		}
	}

	private checkTransportEmptyFields(): void {
		this.transportFieldEmpty = false;
		const transport = lodash.cloneDeep(this.fabricTransport);
		transport.forEach(tp => {
			if(tp.toCompanyUser.toCompanyID === Guid.Empty || tp.toCompanyUser.toCompanyID === ''){
				this.transportFieldEmpty = true;
			}
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value === '' || mode.distanceUnit.unit === '' || mode.transportType.value === ''){
					this.transportFieldEmpty = true;
				}
			});
		});
	}

	// private beforeSave(): void {
	// 	let showWarning: boolean = false;
	// 	const showSaveWarning: boolean = false;
	// 	this.fabricFootprint.forEach(fabric => {
	// 		if ((fabric.reference.referenceNo === "" || fabric.reference.referenceNo === null)) {
	// 			this.disableSave = true;
	// 			if (fabric.reference.referenceNo === "") {
	// 				fabric.reference.isValid = false;
	// 			}

	// 		}
	// 		if(fabric.fabricType.type !== "NON-WOVEN-SB" && fabric.fabricType.type!=='NON-WOVEN-NP'){
	// 			fabric.yarnFabric.forEach(y => {
	// 				if(y.yarnPercent.count === ""){
	// 					y.yarnPercent.count = -1;
	// 				}
	// 			});
	// 			if (fabric.fabricWeight.weight !== "") {
	// 				if(fabric.fabricWeight.weight.toString().indexOf(".") !== -1){
	// 					fabric.fabricWeight.weight = parseFloat(fabric.fabricWeight.weight.toString());
	// 				}
	// 				else{
	// 					fabric.fabricWeight.weight = parseInt(fabric.fabricWeight.weight.toString());
	// 				}
	// 			}
	// 			else{
	// 				fabric.fabricWeight.weight = -1;
	// 			}
	// 			fabric.fiberFabric = [];
	// 		}
	// 		else if(fabric.fabricType.type === "NON-WOVEN-SB" || fabric.fabricType.type === 'NON-WOVEN-NP'){
	// 			if(fabric.fiberFabric.length > 0){
	// 				fabric.fiberFabric.forEach(ff => {
	// 					if(ff.fiberValue.value === ""){
	// 						ff.fiberValue.value = -1;
	// 					}
	// 				})
	// 			}else{
	// 				fabric.fiberFabric = [];
	// 			}
	// 			fabric.yarnFabric = [];
	// 			if (fabric.fabricWeight.weight !== "") {
	// 				if(fabric.fabricWeight.weight.toString().indexOf(".") !== -1){
	// 					fabric.fabricWeight.weight = parseFloat(fabric.fabricWeight.weight.toString());
	// 				}
	// 				else{
	// 					fabric.fabricWeight.weight = parseInt(fabric.fabricWeight.weight.toString());
	// 				}
	// 			}
	// 			else{
	// 				fabric.fabricWeight.weight = -1;
	// 			}
	// 		}
	// 		else {
	// 			fabric.yarnFabric = [];
	// 			fabric.fiberFabric = [];
	// 		}
	// 		if (fabric.totalElectricEnergy.value !== "") {
	// 			if(fabric.totalElectricEnergy.value.toString().indexOf(".") !== -1){
	// 				fabric.totalElectricEnergy.value = parseFloat(fabric.totalElectricEnergy.value.toString());
	// 			}
	// 			else{
	// 				fabric.totalElectricEnergy.value = parseInt(fabric.totalElectricEnergy.value.toString());
	// 			}
	// 		}
	// 		else{
	// 			fabric.totalElectricEnergy.value = -1;
	// 		}
	// 		if(fabric.total.totalcount === ""){
	// 			fabric.total.totalcount = -1;
	// 		}
	// 		if(fabric.windEnergy.value === ""){
	// 			fabric.windEnergy.value = -1;
	// 		}
	// 		if(fabric.solarEnergy.value === ""){
	// 			fabric.solarEnergy.value = -1;
	// 		}
	// 	});
	// 	if (this.disableSave) {
	// 		return;
	// 	}
	// 	this.saveFabricFootprintData();

	// }

	private async saveFabricFootprintData(): Promise<void> {
		this.disableSave = false;
		this.isSaving = true;
		const fabricFP = lodash.cloneDeep(this.fabricFootprint);
		fabricFP.forEach(fabric => {
			// if ((fabric.reference.referenceNo === "" || fabric.reference.referenceNo === null)) {
			// 	this.disableSave = true;
			// 	if (fabric.reference.referenceNo === "") {
			// 		fabric.reference.isValid = false;
			// 	}

			// }
			if(fabric.fabricType.type !== "NON-WOVEN-SB" && fabric.fabricType.type!=='NON-WOVEN-NP'){
				fabric.yarnFabric.forEach(y => {
					if(y.yarnPercent.count === ""){
						y.yarnPercent.count = -1;
					}
				});
				if (fabric.fabricWeight.weight !== "") {
					if(fabric.fabricWeight.weight.toString().indexOf(".") !== -1){
						fabric.fabricWeight.weight = parseFloat(fabric.fabricWeight.weight.toString());
					}
					else{
						fabric.fabricWeight.weight = parseInt(fabric.fabricWeight.weight.toString());
					}
				}
				else{
					fabric.fabricWeight.weight = -1;
				}
				fabric.fiberFabric = [];
			}
			else if(fabric.fabricType.type === "NON-WOVEN-SB" || fabric.fabricType.type === 'NON-WOVEN-NP'){
				if(fabric.fiberFabric.length > 0){
					fabric.fiberFabric.forEach(ff => {
						if(ff.fiberValue.value === ""){
							ff.fiberValue.value = -1;
						}
					})
				}else{
					fabric.fiberFabric = [];
				}
				fabric.yarnFabric = [];
				if (fabric.fabricWeight.weight !== "") {
					if(fabric.fabricWeight.weight.toString().indexOf(".") !== -1){
						fabric.fabricWeight.weight = parseFloat(fabric.fabricWeight.weight.toString());
					}
					else{
						fabric.fabricWeight.weight = parseInt(fabric.fabricWeight.weight.toString());
					}
				}
				else{
					fabric.fabricWeight.weight = -1;
				}
			}
			else {
				fabric.yarnFabric = [];
				fabric.fiberFabric = [];
			}
			if (fabric.totalElectricEnergy.value !== "") {
				if(fabric.totalElectricEnergy.value.toString().indexOf(".") !== -1){
					fabric.totalElectricEnergy.value = parseFloat(fabric.totalElectricEnergy.value.toString());
				}
				else{
					fabric.totalElectricEnergy.value = parseInt(fabric.totalElectricEnergy.value.toString());
				}
			}
			else{
				fabric.totalElectricEnergy.value = -1;
			}
			if(fabric.total.totalcount === ""){
				fabric.total.totalcount = -1;
			}
			if(fabric.windEnergy.value === ""){
				fabric.windEnergy.value = -1;
			}
			if(fabric.solarEnergy.value === ""){
				fabric.solarEnergy.value = -1;
			}
		});
		// if (this.disableSave) {
		// 	return;
		// }
		// const order = this.orders.filter(o => o.orderId === this.orderId)[0];
		const fabricData: FabricFootprintRequestModel = {
			footprintDeadline: this.footprintOrder.footprintDeadline !== (this.deadline !== "" ? new Date(this.deadline).toISOString() : "") ? new Date(this.deadline).toISOString(): this.footprintOrder.footprintDeadline,
			fabric: {
				fabricFootprint: fabricFP,
				footprintStatus: this.fabricFootprint.filter(y => y.status === FootPrintCompletedConstant.COMPLETED).length === this.fabricFootprint.length ? FootPrintCompletedConstant.COMPLETED : this.fabricFootprint.filter(y => y.status === FootPrintCompletedConstant.INCOMPLETE).length === this.fabricFootprint.length ? FootPrintCompletedConstant.INCOMPLETE : FootPrintCompletedConstant.PARTIAL,
			}
		};
		const result = await this.clientRequestFootPrintData.saveFabricFootprintDataAsync(this.orderDetail.orderId, fabricData);
		if(result.success){
			this.fabricFootprint = lodash.cloneDeep(result.order.fabric.fabricFootprint);
			this.fabricFootprint.forEach((fabric, index) => {
				fabric.toCompanyUser.isValid = true;
				fabric.reference.isValid = true;
				fabric.fabricType.isValid = true;
				fabric.total.isValid = true;
				if(fabric.total.totalcount === -1){
					fabric.total.totalcount = '';
				}
				fabric.fabricWeight.isValid = true;
				if(fabric.fabricWeight.weight === -1){
					fabric.fabricWeight.weight = '';
				}
				if(fabric.fiberFabric === null || fabric.fiberFabric.length === 0){
					fabric.fiberFabric = [new FiberModel()];
					fabric.fiberFabric[0].ID = uuidv4();
				}
				fabric.yarnRefList = lodash.cloneDeep(this.yarnRefList);
				fabric.yarnFabric.forEach(y => {
					y.yarnPercent.isValid = true;
					y.yarnRefNumber.isValid = true;
					if(y.yarnPercent.count === -1){
						y.yarnPercent.count = '';
					}
				});
				fabric.fiberFabric.forEach(fiber => {
					fiber.fiberType.isValid = true;
					fiber.fiberValue.isValid = true;
					fiber.originOfFiber.isValid = true;
					if(fiber.fiberValue.value === -1){
						fiber.fiberValue.value = '';
					}
				});
				fabric.fabricUnit.isValid = true;
				fabric.fabricWidth.isValid = true;
				if(fabric.fabricWidth.width === -1){
					fabric.fabricWidth.width = '';
				}
				fabric.totalElectricEnergy.isValid = true;
				if(fabric.totalElectricEnergy.value === -1){
					fabric.totalElectricEnergy.value  = '';
				}
				fabric.windEnergy.isValid = true;
				if(fabric.windEnergy.value === -1){
					fabric.windEnergy.value  = '';
				}
				fabric.solarEnergy.isValid = true;
				if(fabric.solarEnergy.value === -1){
					fabric.solarEnergy.value  = '';
				}
				if(index === 0){
					fabric.showFabric = true;
				} else {
					fabric.showFabric = false;
				}
			});
			this.fabricFootprintClone = lodash.cloneDeep(this.fabricFootprint);
			this.fabricFootprintCloneInitialState = lodash.cloneDeep(this.fabricFootprint);
			this.fabricFpStatus();
			this.showFabricFootprint = false;
			this.showFabricTransport = true;
			this.fabricFpChangedCounter = 0;
			this.FpDeletedCounter = 0;
		}
		this.$store.commit("setFootprintChanged", '');
		this.$store.commit("setChangedFootprintName", '');
		this.$store.commit('setIncorrectFootprintData', false);
		this.isSaving = false;
		this.showWarningModal = false;
	}

	// TTD-3061 Sprint19
	private handleFpPrevious(): void {
		this.showFabricFootprint = false;
		this.navigateTab(0);
	}

	// TTD-3061 Sprint19
	private handleTpPrevious(): void {
		this.showFabricFootprint = true;
		this.showFabricTransport = false;
	}

	// TTD-3061 Sprint19
	private handleTpNext(): void {
		this.showFabricTransport = false;
		//TTD-4276 issue1
		if(this.isFootprintChanged === FootPrintChangedConstant.DATA){
			this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
			this.saveChanges();
			this.showFabricFootprint = true;
			return;
		}else{
			this.navigateTab(2);
		}
	}

	// TTD-3061 Sprint19
	private handleFpNext(): void {
		this.showFabricFootprint = false;
		this.showFabricTransport = true;
		this.transportWrapperData.length > 0 ? this.transportWrapperData[0].showWrapper = true : "";
	}

    private openUploadDocModal(fabricIndex: number): void {
		this.showUploadDocModal = true;
		this.fabricIndex = fabricIndex;
		this.docFile = "";
	}

	private get uploadDisabled(): boolean {
		return this.docFile === "";
	}

	private closeUploadDocModal(): void {
		this.showUploadDocModal = false;
		this.docFile = "";
	}

	private openPDFModal(doc: EnergySupportingDocumentModel): void {
		this.docPreview = doc;
		this.showPdfPreviewModal = true;
	}

	private closePdfModal(): void {
		this.showPdfPreviewModal = false;
	}

	private async downloadPDF(): Promise<void> {
        if (this.isPdfReady) {
            this.pdfDocument.contentDispositionFilename = this.docPreview.fileName;
            this.pdfDocument.download();
        }
    }

    private async pdfReady(pdfApp: any): Promise<void> {
        this.isPdfReady = true;
        this.pdfDocument = pdfApp;
    }

    private async uploadSupportingDoc(): Promise<void>{
		this.isDocUploading = true;
		const docFormData = new FormData();
		docFormData.append("upload", this.docFile);
		docFormData.append("geoLong", '0');
		docFormData.append("geoLat", '0');
		docFormData.append("docID", uuidv4());
		docFormData.append("footprintID", this.fabricFootprint[this.fabricIndex].ID);
		docFormData.append("stepID", this.fabricFootprint[this.fabricIndex].stepID);
		docFormData.append("footprintType", FootPrintConstant.FABRIC);
		docFormData.append("filledByCompanyID", this.userCompanyId);
		docFormData.append("filledByPrimarySource", this.userCompanyId === this.fabricFootprint[this.fabricIndex].toCompanyUser.toCompanyID ? "true" : "false");
		const result = await this.clientRequestFootPrintData.uploadSupportingDocsAsync(this.orderDetail.orderId, docFormData);
		if(result.success){
			this.fabricFootprint[this.fabricIndex].energySupportingDocument = result.order.fabric.fabricFootprint[this.fabricIndex].energySupportingDocument;
			if(this.fabricFootprintCloneInitialState[this.fabricIndex]){
				this.fabricFootprintCloneInitialState[this.fabricIndex].energySupportingDocument = result.order.fabric.fabricFootprint[this.fabricIndex].energySupportingDocument;
			}
			this.closeUploadDocModal();
		}
		this.isDocUploading = false;
	}

	private clearFabricFootprintData(): void {
		if(this.fabricFpChangedCounter>0 || this.isTabRemoved){
			this.fabricFootprint = lodash.cloneDeep(this.fabricFootprintCloneInitialState);
			this.fabricFootprintClone = lodash.cloneDeep(this.fabricFootprintCloneInitialState);
			this.fabricFpChangedCounter = 0;
			this.FpDeletedCounter = 0;
			// this.defaultFabricTransport();
		this.$store.commit("setFootprintChanged", '');
		this.$store.commit("setChangedFootprintName", '');
		this.$store.commit('setIncorrectFootprintData', false);
			this.isTabRemoved = false;
		}
	}

	private toggleFabricTransport(): void {
        // const order = this.orders.filter(o => o.orderId === this.orderDetail.orderId)[0];
		// if(this.footprintOrder.fabric.fabricFootprint.length > 0 && this.footprintOrder.fabric.fabricFootprint[0].toCompanyUser.toCompanyName !== '' && this.fabricFpChangedCounter === 0 && this.fabricFootprint.length === this.fabricFootprintCloneInitialState.length){
			this.showFabricTransport = !this.showFabricTransport;
			if(this.showFabricTransport){
				this.showFabricFootprint = false;
			}
		// }else{
		// 	this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
		// }
	}

	private toggleTransport(index: number): void {
		this.fabricTransport[index].showTransport = !this.fabricTransport[index].showTransport;
	}

	private removeTransportFootprint(index: number): void {
		let flag: boolean = false;
		this.fabricTransport.splice(index, 1);
		this.fabricTransportClone.splice(index, 1);
		this.TpDeletedCounter++;
		this.compareFabricTransportClone();
		// this.fabricTransport.findIndex(t => t.showDelete===true) !== -1 ? flag = true: flag = false;
		// this.transportToBeDeleted = flag;
	}

	private fabricTransportMode(stepID: string): ModeOfTransportModel[] {
		const fabricTransport = this.fabricTransport.filter(ft => ft.stepID === stepID)[0];
		return fabricTransport.modeOfTransport;
	}

	private async validateFabricTransport(event: any, fieldname: string, stepID: string, modeTransportIndex: number): Promise<void> {
		const tpindex = this.fabricTransport.findIndex(tp => tp.stepID === stepID);
        switch (fieldname) {
            case "transport-type": {
				event !== '' ? this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.isValid = true : this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.isValid = false;
				!this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.isValid ? this.disableSave=true : this.disableSave=false;
				this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.value = event;
				this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.filledByCompanyID = this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.value!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.filledByPrimarySource = this.userCompanyId === this.fabricTransport[tpindex].toCompanyUser.toCompanyID && this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].transportType.value!=='' ? true : false;
                break;
			}
			case "distance": {
				/* eslint-disable-next-line */
				event.target.value.match(/^([0-9][0-9]{0,4})$/) ? Number(event.target.value) <= 50000 ? this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distance.isValid = true : this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distance.isValid = false : this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distance.isValid = false;
				event.target.value !== "" && event.target.value.match(/^([0-9][0-9]{0,4})$/) === null ? this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distance.isValid = true : '';
				!this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distance.isValid ? this.disableSave=true : this.disableSave=false;
				this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distance.value = event.target.value !== "" && event.target.value.match(/^([0-9][0-9]{0,4})$/) !== null ? Number(event.target.value) : "";
				this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distance.filledByCompanyID = this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distance.value!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distance.filledByPrimarySource = this.userCompanyId === this.fabricTransport[tpindex].toCompanyUser.toCompanyID && this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distance.value!=='' ? true : false;
                break;
			}
			case "distance-unit": {
				event !== '' ? this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.isValid = true : this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.isValid = false;
				!this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.isValid ? this.disableSave=true : this.disableSave=false;
				this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.unit = event;
				this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.filledByCompanyID = this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.unit!=='' ? this.userCompanyId : Guid.Empty;
				this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.filledByPrimarySource = this.userCompanyId === this.fabricTransport[tpindex].toCompanyUser.toCompanyID && this.fabricTransport[tpindex].modeOfTransport[modeTransportIndex].distanceUnit.unit!=='' ? true : false;
                break;
			}
			default:
                break;
        }
		this.compareFabricTransportClone();
    }

	private compareFabricTransportClone() {
		let changes: number = 0;
		const fabricTransport: [string, any][] = Object.entries(this.fabricTransport);
		const fabricTransportClone: [string, any][] = Object.entries(this.fabricTransportClone);

		const getObjectDiff = (obj1: any, obj2: any) => {
		const obj1Props = Object.keys(obj1);
		const obj2Props = Object.keys(obj2);

		const keysWithDiffValue = obj1Props.reduce((keysWithDiffValueAccumulator, key) => {
			// eslint-disable-next-line no-prototype-builtins
			const propExistsOnObj2 = obj2.hasOwnProperty(key);
			const hasNestedValue = obj1[key] instanceof Object && obj2[key] instanceof Object;
			const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];

			if (!propExistsOnObj2) {
			keysWithDiffValueAccumulator.push(key);
			} else if (hasNestedValue) {
			const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
			if (keyIndex >= 0) {
				keysWithDiffValueAccumulator.splice(keyIndex, 1);
			}
			const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
			for (const diff of nestedDiffs) {
				keysWithDiffValueAccumulator.push(`${key}.${diff}`);
			}
			} else if (keyValuePairBetweenBothObjectsIsEqual) {
			const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
			keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
			}
			return keysWithDiffValueAccumulator;
		}, obj2Props);

		return keysWithDiffValue;
		};
		const differences = getObjectDiff(fabricTransport, fabricTransportClone);
		changes += differences.length;
		differences.forEach(element => {
			if (element.includes('status') || element.includes('isValid') || element.includes('.ID') || element.includes('filledByCompanyID') || element.includes('showTransport') || element.includes('filledByPrimarySource')) {
				changes = changes-1;
			}
		});
		this.fabricTpChangedCounter = changes + this.TpDeletedCounter;
		changes > 0?this.$store.commit("setFootprintChanged", FootPrintChangedConstant.DATA): '';
		changes > 0?this.$store.commit("setChangedFootprintName", FootPrintChangedTabConstant.FABRIC_TP): '';
		this.checkTransportEmptyFields();
	}

	private async saveFabricTransportData(): Promise<void> {
		this.isSaving = true;
		const transport = lodash.cloneDeep(this.fabricTransport);
		transport.forEach(tp => {
			tp.modeOfTransport.forEach(mode => {
				if(mode.distance.value !== ""){
					mode.distance.value = Number(mode.distance.value);
				}
				else{
					mode.distance.value = -1;
				}
			});
		});
		const fabricTransport: TransportFootprintRequestModel = {
			footprintDeadline: this.footprintOrder.footprintDeadline !== (this.deadline !== "" ? new Date(this.deadline).toISOString() : "") ? new Date(this.deadline).toISOString(): this.footprintOrder.footprintDeadline,
			data: {
				transportType: FootPrintConstant.FABRIC,
				transportStatus: this.fabricTransport.filter(tp => tp.status === FootPrintCompletedConstant.COMPLETED).length === this.fabricTransport.length ? FootPrintCompletedConstant.COMPLETED : this.fabricTransport.filter(tp => tp.status === FootPrintCompletedConstant.INCOMPLETE).length === this.fabricTransport.length ? FootPrintCompletedConstant.INCOMPLETE : FootPrintCompletedConstant.PARTIAL,
				transport: transport
			}
		};
		const result = await this.clientRequestFootPrintData.saveYarnTransportAsync(this.orderDetail.orderId, fabricTransport);
		if(result.success){
			this.fabricTransport = result.order.fabric.transport;
			this.fabricTransport.forEach((transport, index) => {
				transport.toCompanyUser.isValid = true;
				transport.modeOfTransport.forEach(mode => {
					if(mode.distance.value === -1){
						mode.distance.value = '';
					}
					mode.distance.isValid = true;
					mode.distanceUnit.isValid = true;
					mode.transportType.isValid = true;
				});
			})
			this.fabricTransportClone = lodash.cloneDeep(this.fabricTransport);
			this.fabricTransportCloneInitialState = lodash.cloneDeep(this.fabricTransport);
			this.fabricTpStatus();
			this.fabricTpChangedCounter = 0;
			this.TpDeletedCounter = 0;
		}
		if(this.transportWrapperData.length > 0){
			this.transportWrapperData.forEach((tw, index) => {
				if(index === 0){
					tw.showWrapper = true;
				} else {
					tw.showWrapper = false;
				}
			})
		}
		this.isSaving = false;
		this.$store.commit("setFootprintChanged", '');
		this.$store.commit("setChangedFootprintName", '');
		this.$store.commit('setIncorrectFootprintData', false);
	}

	private clearFabricTransportData() {
		if (this.fabricTpChangedCounter > 0) {
			this.fabricTransport = lodash.cloneDeep(this.fabricTransportCloneInitialState);
			this.fabricTransportClone = lodash.cloneDeep(this.fabricTransportCloneInitialState);
			this.fabricTpChangedCounter = 0;
			this.TpDeletedCounter = 0;
			this.$store.commit("setFootprintChanged", '');
			this.$store.commit("setChangedFootprintName", '');
			this.$store.commit('setIncorrectFootprintData', false);
		}
		this.compareFabricTransportClone();
	}

	private showDeletePopup(fabricIndex: number, fabricDocIndex: number){
		this.showDeleteModal = true;
		this.fabricDeleIndex = fabricIndex;
		this.fabriclDeleDocIndex = fabricDocIndex;
	}

	private async deleteSupDoc(): Promise<void> {
		this.isDeletingDoc = true;
		this.deletedDocID = this.fabricFootprint[this.fabricDeleIndex].energySupportingDocument[this.fabriclDeleDocIndex].ID;
		this.isDeletingDoc = true;
		const doc: DeleteDocRequestModel = {
			footprintID: this.fabricFootprint[this.fabricDeleIndex].ID,
			footprintType: FootPrintConstant.FABRIC,
			docID: this.fabricFootprint[this.fabricDeleIndex].energySupportingDocument[this.fabriclDeleDocIndex].ID
		};
		const result: FootprintDataResponseModel = await this.clientRequestFootPrintData.deleteSupportDocs(this.orderDetail.orderId, doc);
		if(result.success){
			this.fabricFootprint[this.fabricDeleIndex].energySupportingDocument = result.order.fabric.fabricFootprint[this.fabricDeleIndex].energySupportingDocument;
			if(this.fabricFootprintCloneInitialState[this.fabricDeleIndex]){
				this.fabricFootprintCloneInitialState[this.fabricDeleIndex].energySupportingDocument = result.order.fabric.fabricFootprint[this.fabricDeleIndex].energySupportingDocument;
			}
		}
		this.isDeletingDoc = false;
		this.showDeleteModal = false;
	}

	private isFabricFootprint(stepId:number): boolean{
		if ((stepId === 4 || stepId === 5) || (stepId === 23 && !this.isWeavingOrKnitting(4) && !this.isWeavingOrKnitting(5))) {
			return true;
		}
		return false;
	}

	private isWeavingOrKnitting(stepId:number): boolean {
		const allFootprintStepIds = this.allFootprintSteps.map(s => s.stepId);
		if (allFootprintStepIds.includes(stepId)) {
			return true;
		}
		return false;
	}

	private getFabricLength(stepId: string): number {
		return this.fabricFootprint.filter(f => f.stepID === stepId).length;
	}

	private getFabricIndex(stepId: string, id: string): number {
		const fabrics = this.fabricFootprint.filter(f => f.stepID === stepId);
		fabrics.sort((a, b)=> a.sequence < b.sequence ? -1 : 1)
		return fabrics.findIndex(r => r.ID === id)+1;
	}

	private toggleFabricWrapper(stepId: string): void {
		// if(this.fabricFpChangedCounter > 0 || (this.fabricFootprint.length !== this.fabricFootprintCloneInitialState.length)){
		// 	// this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
		// }
		// else{
			this.activeFabricStep = Guid.Empty;
			this.fabricWrapperData.filter(w => w.stepID === stepId)[0].showWrapper = !this.fabricWrapperData.filter(w => w.stepID === stepId)[0].showWrapper;
		// }
	}
	private toggleTransportWrapper(index: number): void {
		this.transportWrapperData[index].showWrapper = !this.transportWrapperData[index].showWrapper;
	}

	private prefixRefNumber(stepId: string): string {
		let refNum = '';
		const fabric = this.fabricWrapperData.filter(f => f.stepID === stepId)[0];
		if(fabric.footprintRefNoPrefix!==''){
			refNum = fabric.footprintRefNoPrefix === undefined ? '' : fabric.footprintRefNoPrefix;
		}
		return refNum;
	}

	private removeMode(stepID: string, modeIndex: number): void {
		const tpIndex = this.fabricTransport.findIndex(tp => tp.stepID === stepID);
		this.fabricTransport[tpIndex].modeOfTransport.splice(modeIndex, 1);
		this.compareFabricTransportClone();
	}

	private getFiberFabricLength(Id: string): number{
		return this.fabricFootprint.filter(fb => fb.ID === Id)[0].fiberFabric.length;
	}

	private get selectedCard(): string {
		const wrapperIndex = this.fabricWrapperData.findIndex(w => w.stepID === this.activeFabricStep);
		const yarnIndex = this.fabricFootprint.findIndex(y => y.stepID === this.activeFabricStep);
		if(wrapperIndex !== -1 && yarnIndex !== -1){
			this.fabricFootprint.every(w => w.showFabric = false);
			this.fabricWrapperData.every(w => w.showWrapper = false);
			this.fabricWrapperData[wrapperIndex].showWrapper = true;
			this.fabricFootprint[yarnIndex].showFabric = true;
		}
		return this.activeFabricStep;
	}

	private checkWetprocessPartners(): void {
		if((this.orderDetail.toCompanyId !== this.user.companyId) && (this.orderDetail.delegateCompanyID !== this.user.companyId)){ 
			const fabricStepIndex = this.allFootprintSteps.findIndex(step => (step.stepId === 4 || step.stepId === 5 || step.stepId === 23) && step.toCompanyId === this.user.companyId);
			if(this.allFootprintSteps.findIndex(step => step.toCompanyId === this.user.companyId && (step.stepId === 6 || step.stepId === 7 || step.stepId === 8 || step.stepId === 31) && fabricStepIndex===-1)!==-1){
				this.isWetProcessPartner = true
			}else{
				this.isWetProcessPartner = false
			}
		}
	}

	private isFootprintStep(stepId:number): boolean {
		const allFootprintStepIds = StepsHelper.getFootprintSteps().map(s => s.id);
		if (allFootprintStepIds.includes(stepId)) {
			return true;
		}
		return false;
	}

	@Emit()
	private navigateTab(index: number): void {}

	//TTD-4276 issue1
	@Emit()
	private saveChanges(): void {}

	@Watch('activeStep')
    private openCardForSelected(){
		this.activeFabricStep = this.activeStep;
    }
}