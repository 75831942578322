import { AddressStatus } from './addressState';
import { Guid } from './guid';

export class AddressModel {
  public id!: string;
  public line1!: string;
  public line2!: string;
  public city!: string;
  public state!: string;
  public country!: string;
  public countryInFull!: string;
  public postal!: string;
  public officeName!: string;
  public disabled!: boolean;
  public forceEnabled!: boolean;
  public lookupAvailable!: boolean;
  public isOfficeAddress!: boolean;
  public countryError!: boolean;
  public stateError!: boolean;
  public cityError!: boolean;
  public isConfirmed!: boolean;
  public confirmError!: boolean;
  public isEdited!: boolean;
  public geoLat!: number;
  public geoLong!: number;
  public sequence!: number;
  public isConfirming!: boolean;
  public addressStatus!: string;
  public companySubtypes!: number[];
  public facilityType!: string;
  public isExpanded!: boolean;
  public employees!: number | string;
  public maleEmployees!: number | string;
  public femaleEmployees!: number | string;
  public monthlyCapacity!: number | string;
  public monthlyCapacityUnit!: string;
  public inValid!: AddressErrorModel;
  public position!: number;

  public constructor() {
    this.id = Guid.Empty;
    this.line1 = '';
    this.line2 = '';
    this.city = '';
    this.state = '';
    this.country = '';
    this.countryInFull = '';
    this.postal = '';
    this.officeName = '';
    this.disabled = false;
    this.forceEnabled = false;
    this.geoLat = 0;
    this.geoLong = 0;
    this.sequence = 0;
    this.isOfficeAddress = false;
    this.countryError = false;
    this.stateError = false;
    this.cityError = false;
    this.confirmError = false;
    this.isEdited = false;
    this.isConfirmed = false;
    this.lookupAvailable = false;
    this.isConfirming = false;
    this.addressStatus = AddressStatus.ENABLE;
    this.companySubtypes = [];
    this.isExpanded = true;
    this.facilityType = '';
    this.employees = '';
    this.maleEmployees = '';
    this.femaleEmployees = '';
    this.monthlyCapacity = '';
    this.monthlyCapacityUnit = '';
    this.inValid = new AddressErrorModel();
    this.position = -1;
  }
}

export class AddressErrorModel {
  public isAddTypeEmpty!: boolean;
  public isAddNameEmpty!: boolean;
  public isAddLine1Empty!: boolean;
  public isAddPostalEmpty!: boolean;
  public isAddCompanySubtypeEmpty!: boolean;
  public isAddCountryEmpty!: boolean;
  public isAddStateEmpty!: boolean;
  public isAddCityEmpty!: boolean;
  public isAddEmployeesEmpty!: boolean;
  public isAddMaleEmployeesEmpty!: boolean;
  public isAddFemaleEmployeesEmpty!: boolean;
  public isAddMonthlyCapacityEmpty!: boolean;
  public isAddMonthlyCapacityUnitEmpty!: boolean;
  public constructor() {
    this.isAddTypeEmpty = false;
    this.isAddNameEmpty = false;
    this.isAddLine1Empty = false;
    this.isAddPostalEmpty = false;
    this.isAddCompanySubtypeEmpty = false;
    this.isAddCountryEmpty = false;
    this.isAddStateEmpty = false;
    this.isAddCityEmpty = false;
    this.isAddEmployeesEmpty = false;
    this.isAddMaleEmployeesEmpty = false;
    this.isAddFemaleEmployeesEmpty = false;
    this.isAddMonthlyCapacityEmpty = false;
    this.isAddMonthlyCapacityUnitEmpty = false;
  }
}
