import { Guid } from "./guid";
import { ToCompanyUserModel } from "./yarnFootprintModel";

export class TransportFootprintRequestModel {
    public footprintDeadline!: string;
    public data!: TransportRequestModel;
}

export class TransportRequestModel {
    public transportType!: string;
    public transportStatus!: string;
    public transport!: TransportFootprintModel[];
}

export class TransportStepsDataModel {
    public constructor(){
        this.stepID = "";
        this.footprintStatus = "";
        this.supplierName = "";
        this.referenceNo = "";
        this.referenceNoPrefix = "";
        this.showWrapper = false;
        this.supplierCompanyId = Guid.Empty;
    }
    public stepID!: string;
    public supplierName!: string;
    public referenceNo!: string;
    public referenceNoPrefix?: string;
    public footprintStatus!: string;
    public showWrapper!: boolean;
    public supplierCompanyId!: string;
}

export class TransportFootprintModel {
    public constructor(){
        this.ID = Guid.Empty;
        this.footprintID = Guid.Empty;
        this.toCompanyUser = {
            toCompanyID: "",
            toCompanyName: "",
            filledByCompanyID: "",
            filledByPrimarySource: false,
            isValid: true
        };
        this.modeOfTransport = [];
        this.showTransport = true;
        this.showDelete = false;
        this.filledByCompanyID = Guid.Empty;
        this.filledByPrimarySource = false;
        this.treatMentType = "";
        this.stepID = "";
        this.referenceNo = "";
        this.showWrapper = false;
        this.status = ""
    }
    public ID!: string;
    public footprintID!: string;
    public toCompanyUser!: ToCompanyUserModel;
    public modeOfTransport!: ModeOfTransportModel[];
    public showTransport!: boolean;
    public showDelete!: boolean;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public treatMentType!: string;
    public stepID!: string;
    public referenceNo!: string;
    public showWrapper!: boolean;
    public status!: string;
}

export class ModeOfTransportModel {
    public constructor(){
        this.transportType = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.distance = {
            value: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
        this.distanceUnit = {
            unit: "",
            filledByCompanyID: Guid.Empty,
            filledByPrimarySource: false,
            isValid: true
        };
    }
    public transportType!: TransportTypeModel;
    public distance!: DistanceModel;
    public distanceUnit!: DistanceUnitModel;
}

export class TransportTypeModel {
    public value!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class DistanceModel {
    public value!: number | string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}

export class DistanceUnitModel {
    public unit!: string;
    public filledByCompanyID!: string;
    public filledByPrimarySource!: boolean;
    public isValid!: boolean;
}
