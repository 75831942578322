import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import { mapGetters } from 'vuex';
import { cloneDeep, uniq } from 'lodash';

// Service
import { EvidenceService } from '@/services/evidenceService';

// Helper
import { GroupStepEvidenceHelper } from '@/helpers/groupStepEvidenceHelper';

// Components
import EvidenceDetailsPopup from '@/views/desktopEvidence/components/evidenceDetailsPopup.vue';
import viewDocument from '@/views/desktopEvidence/components/viewDocument.vue';

// Models
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { UserPermissionModel } from '@/models/permissionModel';
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { GroupStepEvidenceModel } from '@/models/groupStepEvidenceModel';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import { OrderLineStepEvidenceModel } from '@/models/orderLineStepEvidenceModel';
import { EvidencePhotoListModel, SelectPictureModel } from '@/models/selectPictureModel';
import moment from 'moment';
import { EvidenceFile } from '@/models/orderStepEvidenceModel';

@Component({
  components: { EvidenceDetailsPopup, viewDocument },
  computed: mapGetters(['userRole', 'userPermissions', 'pendingOrders', 'pendingOrderLineSteps', 'orderLineEvidenceSteps']),
})
export default class ArchiveEvidence extends Vue {

  @Prop()
  private searchFilter!: string;

  private evidenceService: EvidenceService;

  private orderLineStep: OrderLineStepModel[] = [];

  private userRole!: string;

  private orderLineEvidenceSteps!: OrderLineEvidenceStep[];

  private userPermissions!: UserPermissionModel | null;

  private groupedArchiveEviSteps: GroupStepEvidenceModel[] = [];

  private clonedGroupedArchiveEviSteps: GroupStepEvidenceModel[] = [];

  private archiveUploadedDocs: OrderLineStepEvidenceModel[] = [];

  private isShowUploadPopup: boolean = false;

  private selectedGroup: GroupStepEvidenceModel = new GroupStepEvidenceModel();

  private showUploadDocTypeModal: boolean = false;

  private showPreviewDocument: boolean = false;

  private selectedIndex: number = -1;

  private isLoading: boolean = false;

  private rejectedUploadedDocs: OrderLineStepEvidenceModel[] = [];

  private pdfEvidenceFile: EvidenceFile = new EvidenceFile();

  private imageEvidenceFiles: SelectPictureModel[] = [];

  private uploadDocType: string = '';

  private selectedGroupSteps: GroupStepEvidenceModel = new GroupStepEvidenceModel();

  private combinedRefNo: string = '';

  public constructor() {
    super();
    this.evidenceService = new EvidenceService();
  }

  private async created(): Promise<void> {
    if(this.orderLineEvidenceSteps.length > 0){
      await this.getGroupedUploadEviSteps();
      this.search();
    }
  }

  private get updateOrderLinePermission(): number {
    return this.userPermissions !== null
      ? this.userPermissions.updateOrderLine
      : 0;
  }

  private get rejected(): number {
    return OrderLineStepStatus.Rejected;
  }

  private get waitingApproval(): number {
    return OrderLineStepStatus.WaitingForApproval;
  }

  private get archived(): number {
    return OrderLineStepStatus.Archived;
  }

  private combineRefNo(step: GroupStepEvidenceModel): string {
    let refNo: string[] = [];
    step.orderStyle.forEach((stepOrder, index) => {
      const stepIndex = this.orderLineEvidenceSteps.findIndex(evi => evi.ID === stepOrder.ID);
      if(stepIndex !== -1){
        if(this.orderLineEvidenceSteps[stepIndex].footprintRefNo !== '' && this.orderLineEvidenceSteps[stepIndex].footprintRefNo !== undefined && this.orderLineEvidenceSteps[stepIndex].footprintRefNo !== null){
          refNo.push(this.orderLineEvidenceSteps[stepIndex].footprintRefNo);
        }
      }
    })
    return refNo.length > 0 ? uniq(refNo).join(', ') : '-';
  }

  private combineSteps(step:GroupStepEvidenceModel): string {
    // const nextStep = step.nextStepName!=='' ? ', ' + step.nextStepName : '';
    const tempSteps = cloneDeep(step.steps);
    let assignedSteps = tempSteps[0];
    tempSteps.splice(0, 1);
    for(const st of tempSteps){
      assignedSteps =  assignedSteps + ', ' + st;
    }
    return assignedSteps;
  }

  private async getGroupedUploadEviSteps(): Promise<void>{
    const evidenceSteps = this.orderLineEvidenceSteps.filter(
      (s) => s.status === OrderLineStepStatus.WaitingForApproval || s.status === OrderLineStepStatus.Accepted
    );
    this.groupedArchiveEviSteps = await GroupStepEvidenceHelper.groupStepEvidence(
      evidenceSteps
    );
    this.clonedGroupedArchiveEviSteps = cloneDeep(this.groupedArchiveEviSteps);
  }

  private close(success: boolean): void {
    this.showPreviewDocument = false;
  }

  private async showPreview(selectedGroup: GroupStepEvidenceModel, index: number): Promise<void> {
    this.selectedIndex = index;
    this.isLoading = true;
    this.imageEvidenceFiles = [];
    this.archiveUploadedDocs = [];
    this.pdfEvidenceFile = new EvidenceFile();
    const result = await this.evidenceService.getEvidencePhotos(
      selectedGroup.orderStyle[0].orderId,
      selectedGroup.orderStyle[0].orderLineId,
      selectedGroup.orderStyle[0].ID
    );
    this.archiveUploadedDocs.push(...result.files);
    let uploadDocType = '';
    if(this.archiveUploadedDocs.length > 1){
      uploadDocType = 'IMAGE';
      this.archiveUploadedDocs.forEach(file => {
        const picture: SelectPictureModel = {
          status: this.waitingApproval,
          latitude: 0,
          longitude: 0,
          createdAt: moment(),
          createdBy: '',
          fileName: file.fileName,
          documentType: file.fileType,
          content: file.docUrl,
          fileUrl: '',
          size: 0,
          progress: 0,
          uploadedPicture: new EvidencePhotoListModel()
        }
        this.imageEvidenceFiles.push(picture);
      })
    } else {
      const file = this.archiveUploadedDocs[0].fileType.split('/');
      const type = file[file.length - 1];
      if(type === 'pdf'){
        uploadDocType = 'PDF'
        const evidence: EvidenceFile = {
          ID: '',
          fileHash: '',
          fileName: this.archiveUploadedDocs[0].fileName,
          fileType: this.archiveUploadedDocs[0].fileType,
          docUrl: '',
          createdAt: '',
          createdBy: '',
          url: this.archiveUploadedDocs[0].docUrl,
          geoLat: 0,
          geoLong: 0,
          status: this.waitingApproval
        }
        this.pdfEvidenceFile = evidence;
      } else {
        uploadDocType = 'IMAGE'
        const picture: SelectPictureModel = {
          status: this.waitingApproval,
          latitude: 0,
          longitude: 0,
          createdAt: moment(),
          createdBy: '',
          fileName: '',
          documentType: '',
          content: this.archiveUploadedDocs[0].docUrl,
          fileUrl: '',
          size: 0,
          progress: 0,
          uploadedPicture: new EvidencePhotoListModel()
        }
        this.imageEvidenceFiles.push(picture);
      }
    }
    this.uploadDocType = uploadDocType;
    this.isLoading = false;
    this.combinedRefNo = this.combineRefNo(selectedGroup);
    this.selectedGroupSteps = cloneDeep(selectedGroup);
    this.showPreviewDocument = true;
  }

  @Watch('searchFilter')
  private search(): void{
    if(this.searchFilter!==''){
      const searchLetter = this.searchFilter.toLowerCase();
      this.groupedArchiveEviSteps = this.clonedGroupedArchiveEviSteps.filter(g => g.reference.toLowerCase().includes(searchLetter) ||
      g.client.toLowerCase().includes(searchLetter) || g.currentStepCompany.toLowerCase().includes(searchLetter) ||
      g.steps[0].toLowerCase().includes(searchLetter) || g.nextStepName.toLowerCase().includes(searchLetter) ||
      g.nextStepCompany.toLowerCase().includes(searchLetter));
    }else{
      this.groupedArchiveEviSteps = this.clonedGroupedArchiveEviSteps;
    }
  }

  @Emit()
  private reload(): void{}

}
