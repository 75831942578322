import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import VuePdfApp from 'vue-pdf-app';

// Components

// Model
import { ProductQualityReportModel } from '@/models/productQualityReportModel';

import moment from 'moment';

@Component({
  components: { VuePdfApp },
})
export default class ViewQualityDocument extends Vue {
  @Prop()
  private document!: ProductQualityReportModel;

  private pdfDocument!: any;
  private isPdfReady: boolean = false;
  private config: any = {
    toolbar: false,
  };

  public constructor() {
    super();
  }

  private formatDate(dateToBeFormatted: string): string {
    return moment(dateToBeFormatted).format('D MMMM YYYY');
  }

  private async downloadPDF(): Promise<void> {
    if (this.isPdfReady) {
      this.pdfDocument.contentDispositionFilename = this.document.fileName;
      this.pdfDocument.download();
    }
  }

  private async pdfReady(pdfApp: any): Promise<void> {
    this.isPdfReady = true;
    this.pdfDocument = pdfApp;
  }

  @Emit()
  private close(success: boolean): void {}
}
