import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import { v4 as uuidv4 } from 'uuid';
import store from '@/store';
import Compressor from 'compressorjs';

// Components
import UploadTable from '@/views/desktopEvidence/components/uploadTable.vue';

// Model
import { TCStatus, TCType, TCtableModel } from "@/models/tcTableModel";
import { UserModel } from '@/models/userModel';
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderListModel, TCMailModel, UploadOrderModel, TCDocumentModel } from "@/models/transcationCertificateModal";
import moment from "moment";
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { UserPermissionModel } from '@/models/permissionModel';

// Services
import { ComplianceCertificatesService } from '@/services/complianceCertificatesService';
import { SupplierService } from "@/services/supplierService";
import { UserClient } from '@/clients/userClient';
import { EvidenceService } from '@/services/evidenceService';

//Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';
import { EvidencePhotoListModel, SelectPictureModel, UploadedPictureResponseModel } from "@/models/selectPictureModel";
import i18n from "@/i18n";
import { BlockchainAccessModal } from "@/models/blockchainAccessModal";
import { OrderLineStepStatus } from "@/models/orderLineStepStatus";
import { GroupStepEvidenceModel } from "@/models/groupStepEvidenceModel";
import { cloneDeep, uniq } from "lodash";
import { AddressModel } from "@/models/addressModel";
import { EvidenceFile, orderLineStepID, stepsList, SubmitStepEvidenceModel } from "@/models/orderStepEvidenceModel";
import { DropdownModel } from "@/models/dropdownModel";
import { ApproveRejectModel, orderLineStepIdModel } from "@/models/approveRejectModel";
import { mapGetters } from "vuex";

@Component({
    components: { VuePdfApp, UploadTable },
    computed: mapGetters(['orderLineEvidenceSteps', 'userPermissions'])
})

export default class viewDocument extends Vue {
    @Prop()
    private uploadDocType!: string;

    @Prop()
    private imageEvidenceFiles!: any;

    @Prop()
    private latitude!: number;

    @Prop()
    private longitude!: number;

    @Prop()
    private evidenceType!: number;

    @Prop()
    private selectedGroupSteps!: GroupStepEvidenceModel;

    @Prop()
    private pdfEvidenceFile!: EvidenceFile;

    @Prop()
    private combinedRefNo!: string;

    @Prop()
    private processSteps!: GroupStepEvidenceModel;

    private orderLineEvidenceSteps!: OrderLineEvidenceStep[];

    private userPermissions!: UserPermissionModel | null;

    private client: UserClient;
    private supplierService: SupplierService;
    private evidenceService: EvidenceService;

    private picturesAdded: SelectPictureModel[] = [];

    private user: UserModel = new UserModel();

    private noteNextPartner: string = '';

    private isSaving: boolean = false;

    private maxPhotoCount: number = 20;
    private minPhotoCount: number = 1;
    private maxMeters: Number = 100;

    private showApproveConfirmPopup: boolean = false;
    private showRejectReasonPopup: boolean = false;

    private rejectReason: string = '';

    private isRejectionSelected: boolean = false;

    private isPdfReady: boolean = false;

    private rejectionReason: string | undefined = '';

    public constructor() {
        super();
        this.client = new UserClient();
        this.supplierService = new SupplierService();
        this.evidenceService = new EvidenceService();
    }

    private async created(): Promise<void> {
      this.picturesAdded = [];
        this.user = this.$store.getters.user as UserModel;
        if(this.evidenceType === this.pending){
          if(this.uploadDocType === 'PDF'){
            // pdf function call
          } else {
            this.isPdfReady = true;
            this.uploadImageFromGallery(this.imageEvidenceFiles);
          }
        } else {
          // approve reject init
          if(this.imageEvidenceFiles !== undefined && this.imageEvidenceFiles !== null){
            this.picturesAdded.push(...this.imageEvidenceFiles);
          }
        }
        this.getRejectionReason();
    }

    private get waitingApproval(): number {
        return OrderLineStepStatus.WaitingForApproval;
    }

    private get pending(): number {
        return OrderLineStepStatus.Pending;
    }

    private get rejected(): number {
        return OrderLineStepStatus.Rejected;
    }

    private get archived(): number {
      return OrderLineStepStatus.Archived;
    }

    private get isConfirmDisabled(): boolean {
        if(this.uploadDocType === 'PDF'){
            return false;
        }
        else {
            return this.picturesAdded.some(p => p.progress > 0 && p.progress < 101) || this.isSaving || this.picturesAdded.length === 0;
        }
    }

    private pdfReady(): void{
      this.isPdfReady = true;
    }

    private get combineSteps(): string {
      let groupedstep = cloneDeep(this.selectedGroupSteps.steps);
      if(this.evidenceType === this.pending){
        groupedstep = cloneDeep(this.processSteps.steps);
      }
      // if(this.selectedGroupSteps.nextStepName !== ''){
      //   groupedstep.push(this.selectedGroupSteps.nextStepName);
      // }
      return uniq(groupedstep).join(', ');
    }

    private async uploadImageFromGallery(event: any): Promise<void> {
        const app = this;
        for (let index = 0; index < event.target.files.length; index++) {
          // TTD-4294: Added compression to reduce the image size
          new Compressor(event.target.files[index], {
            quality: 0.6,
            success(result) {
              const selectedFile = result;
              const reader: FileReader = new FileReader();
              reader.readAsDataURL(selectedFile);
              reader.onload = (function (file: any, app: any) {
                return (): void => {
                  // TTD-4294: Removed limit of 5MB
                  const newPicture = new SelectPictureModel();
                  newPicture.latitude = app.latitude;
                  newPicture.longitude = app.longitude;
                  newPicture.createdBy = `${app.user.firstName} ${app.user.lastName}`;
                  newPicture.fileName = file.name;
                  newPicture.documentType = file.type;
                  newPicture.content = reader.result as string;
                  newPicture.size = file.size;
                  newPicture.progress = 1;
                  app.picturesAdded.push(newPicture);
                  app.uploadPictureInChunks(app.picturesAdded.length - 1);
                //   app.closePopup();
                };
              })(selectedFile, app);
              reader.onerror = (): void => {
                NotificationHelper.createErrorNotification(
                  i18n.t('errors.image_invalid').toString()
                );
                // app.closePopup();
              };
            }
          })
        }
        // this.isCameraOn = false;
    }

    private async uploadPictureInChunks(pictureindex: number): Promise<void> {
        const token = store.getters.accessToken as BlockchainAccessModal;
        const chunkSize = 3 * 100 * 1024; // 300kb (adjust based on your requirements)
        const totalChunks = Math.ceil(this.picturesAdded[pictureindex].size / chunkSize);
        const chunkProgress = 100 / totalChunks;
        let accesstoken: BlockchainAccessModal = new BlockchainAccessModal();
        let chunkNumber = 0;
        let start = 0;
        let end = chunkSize;
        if (token !== null) {
          accesstoken = token;
        } else {
          accesstoken = await this.client.getBlockchainToken();
          store.commit('setAccessToken', accesstoken);
        }

        const uploadNextChunk = async () => {
          if (start <= this.picturesAdded[pictureindex].size) {
            const chunk = this.dataURItoFile(this.picturesAdded[pictureindex].fileName, this.picturesAdded[pictureindex].content).slice(start, end);
            const formData = new FormData();
            formData.append("upload", chunk);
            formData.append("chunkNumber", chunkNumber.toString());
            formData.append("totalChunks", totalChunks.toString());
            formData.append("originalname", this.picturesAdded[pictureindex].fileName);
            formData.append("mimetype", this.picturesAdded[pictureindex].documentType);
            formData.append("geoLat", this.picturesAdded[pictureindex].latitude.toString());
            formData.append("geoLong", this.picturesAdded[pictureindex].longitude.toString());
            formData.append("status", OrderLineStepStatus.WaitingForApproval.toString());

            fetch(`${process.env.VUE_APP_Blockchain_Api_Endpoint}/v2/api/orderlineStep/supplier/${this.user.companyId}/uploadEvidence`, {
              headers: {
                Authorization: 'Bearer ' + accesstoken.token,
              },
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((data: UploadedPictureResponseModel) => {
                this.picturesAdded[pictureindex].progress = (Number((chunkNumber + 1) * chunkProgress));
                const picture = this.picturesAdded[pictureindex];
                this.picturesAdded.splice(pictureindex, 1, picture);
                chunkNumber++;
                start = end;
                end = start + chunkSize;
                if(chunkNumber === totalChunks) {
                  this.picturesAdded[pictureindex].uploadedPicture = data.evidencePhotoList;
                  const picture = this.picturesAdded[pictureindex];
                  this.picturesAdded.splice(pictureindex, 1, picture);
                }
                uploadNextChunk();
              })
              .catch((error) => {
                console.error("Error uploading chunk:", error);
              });
          } else {
            setTimeout(
              (app: any) => {
                this.picturesAdded[pictureindex].progress = 101;
                const picture = this.picturesAdded[pictureindex];
                this.picturesAdded.splice(pictureindex, 1, picture);
              },
              1000,
              this
            );
          }
        };
        uploadNextChunk();
    }

    private dataURItoFile(filename: string, dataURI: string): File {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const fileBlob = new Blob([ab], { type: mimeString });
        return new File([fileBlob], filename, { type: mimeString });
    }

    private async saveSelectPictures(): Promise<void> {
      const uploadedPictures: EvidencePhotoListModel[] = [];
      if (this.latitude === 0 && this.longitude === 0) {
        NotificationHelper.createErrorNotification(
          this.$t('global.notifications.enable_geolocation').toString()
        );
        return;
      }

      const lMatches: Boolean = await this.checkLocationMatches();

      if(this.uploadDocType === 'PDF'){
        const uploadedPdf: EvidencePhotoListModel = {
          ID: this.pdfEvidenceFile.ID,
          createdAt: new Date().toISOString(),
          createdBy: this.user.userId,
          docURL: this.pdfEvidenceFile.docUrl,
          fileHash: this.pdfEvidenceFile.fileHash,
          fileName: this.pdfEvidenceFile.fileName,
          fileType: this.pdfEvidenceFile.fileType,
          geoLat: this.pdfEvidenceFile.geoLat,
          geoLong: this.pdfEvidenceFile.geoLong,
          status: this.pdfEvidenceFile.status,
          isMainPicture: false,
          addressID: '',
          sequence: 0
        }
        uploadedPictures.push(uploadedPdf);
      } else {
        if (
          this.picturesAdded.length < this.minPhotoCount ||
          this.picturesAdded.length > this.maxPhotoCount
        ) {
          NotificationHelper.createErrorNotification(
            this.$t('global.notifications.profile_pictures_quantity', [
              this.minPhotoCount,
              this.maxPhotoCount,
            ]).toString()
          );
          return;
        }

        const pictureAddedFiles: File[] = [];
        this.picturesAdded.forEach((picture: SelectPictureModel) => {
          pictureAddedFiles.push(
            this.dataURItoFile(picture.fileName, picture.content)
          );
        });
        this.picturesAdded.forEach((p) => {
          uploadedPictures.push(p.uploadedPicture);
        });
      }

        // This method need to call after images get captured from cameraor uploaded from device
        try {
          this.isSaving = true;
          let stepIds: stepsList[] = []
          this.selectedGroupSteps.orderStyle.forEach((s) => {
            if (s.isSelected) {
              stepIds.push({ ID: s.ID });
            }
          });
          const lineStepID: orderLineStepID = {
            stepsList: stepIds
          }
          const submitStepEvidence: SubmitStepEvidenceModel = {
            orderLineStepID: lineStepID,
            referenceNo: this.noteNextPartner.trim(),
            evidencePhotoList: uploadedPictures
          }
          const uploadResult = await this.evidenceService.submitStepEvidence(
            submitStepEvidence,
            this.user.companyId
          );
          NotificationHelper.createSucceededNotification('Order evidence successfully uploaded');
          this.noteNextPartner = '';
          this.isSaving = false;
        } catch (error) {
          NotificationHelper.createErrorNotification(
            this.$t('global.notifications.error').toString()
          );
          this.isSaving = false;
        } finally {
          const lastStepData: object[] = [];
          this.selectedGroupSteps.orderStyle.forEach((o) => {
            lastStepData.push({
              orderID: o.orderId,
              orderLineID: o.orderLineId,
              orderLineStepID: o.ID,
            });
          });
        }
        this.close(true);
      }

      private async checkLocationMatches(): Promise<Boolean> {
        let lMatches: Boolean = false;
        let company: any = this.$store.getters.profile;
        if (this.$store.getters.profile != null) {
          company = this.$store.getters.profile;
        } else {
          company = await this.supplierService.getSupplierAsync();
        }

        if (company.officeAddress !== null || company.officeAddress !== undefined) {
          await company.officeAddress.forEach((address: AddressModel) => {
            if (address != null && address !== undefined) {
              if (address && address.geoLat != null && address.geoLong != null) {
                this.getDistanceFromLatLonInMeters(
                  { lat: address.geoLat, lon: address.geoLong },
                  { lat: this.latitude, lon: this.longitude }
                ).then((distance) => {
                  lMatches = distance < this.maxMeters ? true : lMatches;
                });
              }
            }
          });
        }

        if (
          company.factoryAddresses !== null ||
          company.factoryAddresses !== undefined
        ) {
          await company.factoryAddresses.forEach((address: AddressModel) => {
            if (address != null && address !== undefined) {
              if (address && address.geoLat != null && address.geoLong != null) {
                this.getDistanceFromLatLonInMeters(
                  { lat: address.geoLat, lon: address.geoLong },
                  { lat: this.latitude, lon: this.longitude }
                ).then((distance) => {
                  lMatches = distance < this.maxMeters ? true : lMatches;
                });
              }
            }
          });
        }

        return lMatches;
      }

      private async getDistanceFromLatLonInMeters(
        coords1: { lat: number; lon: number },
        coords2: { lat: number; lon: number }
      ): Promise<Number> {
        const { lat: lat1, lon: lon1 } = coords1;
        const { lat: lat2, lon: lon2 } = coords2;

        const R = 6371;
        const halfDLat = this.degToRad(lat2 - lat1) / 2;
        const halfDLon = this.degToRad(lon2 - lon1) / 2;
        const a =
          Math.sin(halfDLat) * Math.sin(halfDLat) +
          Math.cos(this.degToRad(lat1)) *
            Math.cos(this.degToRad(lat2)) *
            Math.sin(halfDLon) *
            Math.sin(halfDLon);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return (R * c) / 100;
      }

      private degToRad(deg: number): number {
        return deg * (Math.PI / 180);
      }

      private get rejectionReasons(): DropdownModel[] {
        const options: DropdownModel[] = [
          {
            value: 'Incorrect document',
            text: 'Incorrect document',
          },
          {
            value: 'Document not readable',
            text: 'Document not readable',
          },
        ];
        return options;
      }

      private async approveSubmit(): Promise<void> {
        this.isSaving = true;
        const data: ApproveRejectModel = {
          orderLineStepID: new orderLineStepIdModel(),
          rejectionReason: '',
          status: 0,
        };
        data.rejectionReason = '';
        data.status = OrderLineStepStatus.Accepted;
        let counter = 0;
        this.selectedGroupSteps.orderStyle.forEach((o) => {
          if (counter == 0) {
            data.orderLineStepID.stepsList.pop();
          }
          const stepId = { ID: o.ID };
          data.orderLineStepID.stepsList.push(stepId);
          counter++;
        });
        const user = this.$store.getters.user as UserModel;
        const result = await this.evidenceService.postEvidenceStatus(
          user.companyId,
          data
        );
        if(result.success){
          NotificationHelper.createSucceededNotification('Order evidence successfully approved');
        }
        this.isSaving = false;
        this.showApproveConfirmPopup = false;
        this.close(true);
      }

      private async rejectSubmit(): Promise<void> {
        this.isSaving = true;
        const data: ApproveRejectModel = {
          orderLineStepID: new orderLineStepIdModel(),
          rejectionReason: '',
          status: 0,
        };
        data.rejectionReason = this.rejectReason;
        data.status = OrderLineStepStatus.Rejected;
        let counter = 0;
        this.selectedGroupSteps.orderStyle.forEach((o) => {
          if (counter == 0) {
            data.orderLineStepID.stepsList.pop();
          }
          const stepId = { ID: o.ID };
          data.orderLineStepID.stepsList.push(stepId);
          counter++;
        });
        const user = this.$store.getters.user as UserModel;
        const result = await this.evidenceService.postEvidenceStatus(
          user.companyId,
          data
        );
        if(result.success){
          NotificationHelper.createSucceededNotification('Order evidence successfully rejected');
        }
        this.isSaving = false;
        this.showRejectReasonPopup = false;
        this.close(true);
      }

      private getRejectionReason(): void {
        this.selectedGroupSteps.orderStyle.forEach((stepOrder, index) => {
          const stepIndex = this.orderLineEvidenceSteps.findIndex(evi => evi.ID === stepOrder.ID);
          if(stepIndex !== -1){
            if(this.orderLineEvidenceSteps[stepIndex].evidencePhotos.length > 0 && this.orderLineEvidenceSteps[stepIndex].evidencePhotos[0].rejectionReason){
              this.rejectionReason = this.orderLineEvidenceSteps[stepIndex].evidencePhotos[0].rejectionReason;
            }
          }
        });
      }

      private get updateOrderLinePermission(): number {
        return this.userPermissions !== null
          ? this.userPermissions.updateOrderLine
          : 0;
      }

    @Emit()
    private goToEviDetail(): void{}

    @Emit()
    private close(success: boolean): void{}

}
