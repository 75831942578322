import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import store from '@/store';
import _ from 'underscore';

// Components

// Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';

// Services
import { UserService } from '@/services/userService';
import { MiddlewareService } from '@/services/middlewareService';

// Models
import { UserModel } from '@/models/userModel';
import { ApprovePendingUserModel, RejectPendingUserModel, SecondUser } from '@/models/secondUser';
import { CompanyModel } from '@/models/companyModel';
import { mapGetters } from 'vuex';

@Component({
  computed:mapGetters(['user'])
})
export default class AdminSettings extends Vue {
  @Prop()
  private userListFilter!: string;

  @Prop()
  private reloadList!: boolean;

  @Prop()
  private isNonRomanChar!: boolean;

  private userService: UserService;
  private middlewareService: MiddlewareService;
  private allUsers: UserModel[] = [];
  private backupUsers: UserModel[] = [];
  private requestedUsers: UserModel[] = [];
  private allUsersResponse: UserModel[] = [];
  private user!: UserModel;
  private nameSorting: string = 'desc';
  private statusSorting: string = 'asc';
  private statusSortIcon: NodeRequire = require('../../../assets/icons/myprofile/sort-asc.svg');
  private nameSortIcon: NodeRequire = require('../../../assets/icons/myprofile/sort-desc.svg');
  private isLoading: boolean = false;
  private showBlockModal: boolean = false;
  private showUnBlockModal: boolean = false;
  private currentUserId: string = '';
  private resendUser: SecondUser = new SecondUser();
  private isSaving: boolean = false;
  private isResendLoading: boolean = false;
  private showResendModal: boolean = false;
  private showApproveUserModal: boolean = false;
  private showRejectUserModal: boolean = false;
  private usernameError: boolean = false;
  private emailError: boolean = false;
  private phoneError: boolean = false;
  private roleError: boolean = false;
  private isApproving: boolean = false;
  private isRejecting: boolean = false;
  private isError: boolean = false;
  private rejectUser: UserModel = new UserModel;
  private approvePendingUser: ApprovePendingUserModel = new ApprovePendingUserModel;

  public constructor() {
    super();
    this.userService = new UserService();
    this.middlewareService = new MiddlewareService();
  }

  private async created(): Promise<void> {
    this.isLoading = true;
    const company = store.getters.user as UserModel;
    this.allUsersResponse = await this.userService.getAllCompanyUsers(
      company.companyId
    );
    this.load();
  }

  private async load(): Promise<void> {
    this.allUsersResponse.sort((user1, user2) => {
      return user1.firstName + ' ' + user1.lastName <
        user2.firstName + ' ' + user2.lastName
        ? -1
        : user1.firstName + ' ' + user1.lastName >
          user2.firstName + ' ' + user2.lastName
        ? 1
        : 0;
    });
    this.allUsers = this.allUsersResponse.filter(u => u.status !== 'REQUESTED');
    this.backupUsers = this.allUsers;
    this.requestedUsers = this.allUsersResponse.filter(u => u.status === 'REQUESTED');
    this.isLoading = false;
  }

  private updateList(userListFilter: string): void {
    if (this.userListFilter == '') {
      this.allUsers = this.backupUsers;
    } else {
      this.allUsers = this.backupUsers;
      const updated = this.allUsers.filter(
        (u) =>
          u.firstName.toLowerCase().indexOf(userListFilter.toLowerCase()) >
            -1 ||
          u.lastName.toLowerCase().indexOf(userListFilter.toLowerCase()) > -1
      );
      this.allUsers = updated;
    }
  }

  private sortData(field: string): void {
    if (field == 'name' && this.nameSorting == 'desc') {
      this.nameSortIcon = require('../../../assets/icons/myprofile/sort-asc.svg');
      this.nameSorting = 'asc';
      this.allUsers.sort((user1, user2) => {
        return user1.firstName + ' ' + user1.lastName <
          user2.firstName + ' ' + user2.lastName
          ? -1
          : user1.firstName + ' ' + user1.lastName >
            user2.firstName + ' ' + user2.lastName
          ? 1
          : 0;
      });
    } else if (field == 'name' && this.nameSorting == 'asc') {
      this.nameSortIcon = require('../../../assets/icons/myprofile/sort-desc.svg');
      this.nameSorting = 'desc';
      this.allUsers.sort((user1, user2) => {
        return user2.firstName + ' ' + user2.lastName <
          user1.firstName + ' ' + user1.lastName
          ? -1
          : user2.firstName + ' ' + user2.lastName >
            user1.firstName + ' ' + user1.lastName
          ? 1
          : 0;
      });
    }

    if (field == 'status' && this.statusSorting == 'desc') {
      this.statusSortIcon = require('../../../assets/icons/myprofile/sort-asc.svg');
      this.statusSorting = 'asc';
      this.allUsers.sort((user1, user2) => {
        return user1.status < user2.status
          ? -1
          : user1.status > user2.status
          ? 1
          : 0;
      });
    } else if (field == 'status' && this.statusSorting == 'asc') {
      this.statusSortIcon = require('../../../assets/icons/myprofile/sort-desc.svg');
      this.statusSorting = 'desc';
      this.allUsers.sort((user1, user2) => {
        return user2.status < user1.status
          ? -1
          : user2.status > user1.status
          ? 1
          : 0;
      });
    }
  }

  private blockUser(userId: string): void {
    this.showBlockModal = true;
    this.currentUserId = userId;
  }

  private unBlockUser(userId: string): void {
    this.showUnBlockModal = true;
    this.currentUserId = userId;
  }

  private closeBlockUnBlockModal(): void {
    this.showBlockModal = false;
    this.showUnBlockModal = false;
    this.currentUserId = '';
  }

  private async blockUnblockAction(): Promise<void> {
    this.isSaving = true;
    if (this.showBlockModal) {
      const response = await this.middlewareService.blockUser(
        this.currentUserId
      );
      if (response.result == 'success') {
        NotificationHelper.createSucceededNotification(response.message);
        const index = this.allUsersResponse.findIndex(u => u.userId === this.currentUserId);
        if(index !== -1){
          this.allUsersResponse[index].isBlocked = "true";
        }
        this.load();
      } else {
        NotificationHelper.createErrorNotification(response.message);
      }
      this.showBlockModal = false;
      this.currentUserId = '';
    } else if (this.showUnBlockModal) {
      const response = await this.middlewareService.unBlockUser(
        this.currentUserId
      );
      if (response.result == 'success') {
        NotificationHelper.createSucceededNotification(response.message);
        const index = this.allUsersResponse.findIndex(u => u.userId === this.currentUserId);
        if(index !== -1){
          this.allUsersResponse[index].isBlocked = "false";
        }
        this.load();
      } else {
        NotificationHelper.createErrorNotification(response.message);
      }
      this.showUnBlockModal = false;
      this.currentUserId = '';
    }
    this.isSaving = false;
  }

  private resendModalOpen(user: UserModel, index: number): void {
    this.resendUser.companyName = user.companyName;
    this.resendUser.firstname = user.firstName;
    this.resendUser.lastname = user.lastName;
    this.resendUser.companyType = user.companyType;
    this.resendUser.email = user.email;
    this.resendUser.firstnameOfCompanyAdmin = user.firstName;
    this.resendUser.phone = user.phone;
    this.resendUser.role = user.role;
    this.showResendModal = true;
  }

  private resendModalClose(): void {
    this.showResendModal = false;
  }

  private async resendInvite(): Promise<void> {
    this.isResendLoading = true;
    const user: UserModel = store.getters.user;
    this.resendUser.firstnameOfCompanyAdmin = user.firstName;
    const response = await this.middlewareService.resendinvite(this.resendUser);
    if (response.result == 'success') {
      NotificationHelper.createSucceededNotification(response.message);
    } else {
      NotificationHelper.createErrorNotification(response.message);
    }
    this.isResendLoading = false;
    this.showResendModal = false;
  }

  private async isReload(reload: boolean) {
    if (reload) {
      const company = store.getters.user as UserModel;
      this.allUsersResponse = await this.userService.getAllCompanyUsers(
        company.companyId
      );
      this.load();
      this.reloadSuccess();
    }
  }

  private isAdminUser(user: UserModel): boolean {
    const company = this.$store.getters.company as CompanyModel;
    if (company && user) {
      if (user.isAdmin === 'true') {
        return true;
      } else {
        if (
          user.role === 'Admin' &&
          user.userType == 'COMPANY_ADMIN' &&
          company.contact.email.toLowerCase() === user.email.toLowerCase()
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  private showEditModal(user: UserModel): void {
    this.showEdit(user);
  }

  private async validateField(fieldname:string, checkAll: boolean|null): Promise<void> {
    this.isError = false;
    if (fieldname=='username' || checkAll) {
        this.usernameError = (this.approvePendingUser.username.length < 2) ? true : false;
    }
    if (fieldname=='email' || checkAll) {
        const emailValid = this.approvePendingUser.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        this.emailError = (emailValid == null || this.approvePendingUser.email.length==0) ? true : false;
    }
    if (fieldname=='phone' || checkAll) {
        const pattern = /^[+]?[0-9]{2}?[0-9]{6,11}$/;
        const validPattern: boolean = pattern.test(this.approvePendingUser.phone);
        this.phoneError = !validPattern ? true : false;
    }
    if (fieldname=='role' || checkAll) {
        this.roleError = (this.approvePendingUser.role==='' || this.approvePendingUser.role==='Admin') ? true : false;
    }
  }

  private openApproveUserModal(index: number): void {
    this.approvePendingUser = {
      username: this.requestedUsers[index].firstName+' '+this.requestedUsers[index].lastName,
      firstname: this.requestedUsers[index].firstName,
      lastname: this.requestedUsers[index].lastName,
      email: this.requestedUsers[index].email,
      phone: '',
      role: '',
      companyType: this.requestedUsers[index].companyType,
      companyName: this.requestedUsers[index].companyName,
      firstnameOfCompanyAdmin: this.user.firstName,
      userId: this.requestedUsers[index].userId
    }
    this.showApproveUserModal = true;
  }

  private get approveDisable(): boolean {
    return this.approvePendingUser.username === '' || this.usernameError || this.approvePendingUser.email === '' || this.emailError || this.phoneError || this.approvePendingUser.role === '' || this.roleError ? true : false;
  }

  private async approveUser(): Promise<void> {
    this.isApproving = true;
    const response = await this.middlewareService.approvePendingUserAsync(this.user.companyId, this.approvePendingUser);
    if(response.result === 'success'){
      const index = this.allUsersResponse.findIndex(u => u.userId === response.user.userId);
      if(index !== -1){
        this.allUsersResponse.splice(index, 1, response.user);
      }
      this.showApproveUserModal = false;
      this.load();
    }
    this.isApproving = false;
  }

  private openRejectUserModal(index: number): void {
    this.rejectUser = this.requestedUsers[index];
    this.showRejectUserModal = true;
  }

  private async rejectPendingUser(): Promise<void> {
    this.isRejecting = true;
    const rejectedUser: RejectPendingUserModel = {
      SecondUserName: this.rejectUser.firstName + ' ' + this.rejectUser.lastName,
      SecondUserEmail: this.rejectUser.email,
      SecondUserId: this.rejectUser.userId,
      LoggedInAdminName: this.user.companyName,
      InvitedByCompanyName: this.rejectUser.invitedByCompanyName,
      InvitedByCompanyEmail: this.rejectUser.invitedByCompanyEmailID,
      InvitedByCompanyId: this.rejectUser.invitedByCompanyID
    }
    const response = await this.middlewareService.rejectPendingUserAsync(rejectedUser);
    if(response.result === 'success'){
      const index = this.allUsersResponse.findIndex(u => u.userId === this.rejectUser.userId);
      if(index !== -1){
        this.allUsersResponse.splice(index, 1);
      }
      this.showRejectUserModal = false;
      this.load();
    }
    this.isRejecting = false;
  }

  @Emit()
  private reloadSuccess(): void {}

  @Emit()
  private showEdit(user: UserModel): void {}

  @Watch('userListFilter')
  private async onUserListFilterChanged(userListFilter: string): Promise<void> {
    if(this.isNonRomanChar === false){
      this.updateList(userListFilter);
    }
  }

  @Watch('reloadList')
  private async onReloadListChanged(reloadList: boolean): Promise<void> {
    this.isReload(reloadList);
  }
}
