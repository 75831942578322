import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

// Components
import SmallScreenPopUpMsg from '@/components/general/smallScreenPopUpMsg/smallScreenPopUpMsg.vue';

@Component({
  components: { SmallScreenPopUpMsg },
  computed: {
    ...mapGetters(['userRole']),
  },
})
export default class Sidebar extends Vue {
  private applicationLogo: NodeRequire = require('../../../assets/logo.svg');

  private showSmallScreenMsg: boolean = false;

  private smallScreenMsgText: string = '';

  private userRole!: string;

  public constructor() {
    super();
  }

  private sidebar: boolean = false;

  private navComplaince(): void {
    this.smallScreenMsgText = this.$t('pages.home.small_screen_msg').toString();
    this.showSmallScreenMsg = true;
  }

  private hideSmallScreenMsg(): void {
    this.showSmallScreenMsg = false;
  }

  private get isAdmin(): boolean {
    let result = true;
    if (this.userRole === 'Editor' || this.userRole === 'Reader') {
      result = false;
    } else {
      result = true;
    }
    return result;
  }
}
