import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import {
  MglMap,
  MglMarker,
  MglGeolocateControl,
  MglNavigationControl,
} from 'vue-mapbox';

// Models
import { CoordinatesModel } from '@/models/coordinatesModel';
import { AddressModel } from '@/models/addressModel';

// Services
import { GeolocationService } from '@/services/geolocationService';

@Component({
  components: { MglMap, MglMarker, MglGeolocateControl, MglNavigationControl },
})
export default class MapModal extends Vue {
  @Prop()
  private addresses!: AddressModel[];

  @Prop()
  private mapboxAccessToken!: string;

  private mapStyle: string = 'mapbox://styles/mapbox/light-v10';

  private coordinates: CoordinatesModel = new CoordinatesModel();

  private geolocationService: GeolocationService;

  private isLoading: boolean = false;

  private mapbox: any = null;

  private userLocation: number[] = [];

  private address: AddressModel = new AddressModel();

  private addressIndex: number = 1;

  private showWarningModal: boolean = false;

  public constructor() {
    super();
    this.geolocationService = new GeolocationService();
  }

  private async created(): Promise<void> {
    this.address = this.addresses[0];
    this.getCoordinates();
  }

  private async getCoordinates(): Promise<void> {
    try {
      this.isLoading = true;
      const geolocations =
        await this.geolocationService.getCoordinatesForAddressAsync(
          this.address
        );
      if (geolocations.length > 0) {
        this.coordinates = geolocations[0].center;
      }
    } finally {
      this.isLoading = false;
    }
  }

  private get center(): number[] {
    if (this.coordinates == null) {
      return [0, 0];
    }
    return [this.coordinates.longitude, this.coordinates.latitude];
  }

  private get confirmDisabled(): boolean {
    return this.isLoading;
  }

  private async onMapLoaded(event: any): Promise<void> {
    const asyncActions = event.component.actions;

    await asyncActions.flyTo({
      center: this.center,
      zoom: 13,
      speed: 1,
    });
    this.isLoading = false;
  }

  private setMarkerPosition(event: any): void {
    const layer = event.mapboxEvent.target;
    const latLon = layer._lngLat;
    this.coordinates.latitude = latLon.lat;
    this.coordinates.longitude = latLon.lng;
  }

  private get companySubtype(): string {
    const subtype: string[] = [];
    this.address.companySubtypes.forEach((type) => {
      subtype.push(this.$t(`enums.steps.${type}`).toString());
    });
    return this.address.companySubtypes.length === 0 ? '-' : subtype.join(', ');
  }

  private get addressDetail(): string {
    return (
      this.address.officeName.trim() +
      ', ' +
      (this.address.line1.trim() !== ''
        ? this.address.line1.trim() + ', '
        : '') +
      (this.address.line2.trim() !== ''
        ? this.address.line2.trim() + ', '
        : '') +
      this.address.postal.trim() +
      ', ' +
      this.address.city.trim() +
      ', ' +
      this.address.state.trim() +
      ', ' +
      this.$t(`countries.${this.address.country}`).toString()
    );
  }

  private get addressHeading(): string {
    return this.address.isOfficeAddress === true
      ? this.$t('pages.profile.office_address_detail').toString()
      : this.$t('pages.profile.factory_address_detail').toString();
  }

  private nextAddress(): void {
    const len = this.addresses.length;
    if (this.addressIndex == len) {
      return;
    }
    this.addressIndex = this.addressIndex + 1;
    this.address = this.addresses[this.addressIndex - 1];
    this.getCoordinates();
  }

  private openWarningModel(): void {
    this.showWarningModal = true;
  }

  private closeWarningModal(): void {
    this.showWarningModal = false;
  }

  private confirmAddressLocation(): void {
    this.addresses[this.addressIndex - 1].geoLong = this.coordinates.longitude;
    this.addresses[this.addressIndex - 1].geoLat = this.coordinates.latitude;
    this.addresses[this.addressIndex - 1].isConfirmed = true;
    this.addresses[this.addressIndex - 1].disabled = true;
    this.addresses[this.addressIndex - 1].lookupAvailable = false;
    if (this.addresses.length > 1) {
      if (this.addressIndex === this.addresses.length) {
        this.closeWarningModal();
        this.addressConfirmed(this.addresses);
      } else {
        this.nextAddress();
      }
    } else {
      this.closeWarningModal();
      this.addressConfirmed(this.addresses);
    }
  }

  @Emit()
  private addressConfirmed(addresses: AddressModel[]): void {}

  @Emit()
  private close(): void {}
}
