export class InvitationModel {
  public toContactName!: string;
  public MainContactFirstName!: string;
  public MainContactLastName!: string;
  public toContactEmail!: string;
  public companyRegistrationNumber!: string;
  public companyName!: string;
  public isClient!: boolean;
  public language!: string;
  public country!: string;
  public city!: string;
  public state!: string;
  public latitude!: number;
  public longitude!: number;
  public isDelegatePartner!: boolean;
  public invitedPersonEmailID!: string;

  public constructor() {
    this.toContactName = '';
    this.MainContactFirstName = '';
    this.MainContactLastName = '';
    this.toContactEmail = '';
    this.companyRegistrationNumber = '';
    this.companyName = '';
    this.language = '';
    this.country = '';
    this.state = '';
    this.city = '';
    this.isDelegatePartner = false;
    this.invitedPersonEmailID = "";
  }

  public get isValid(): boolean {
    return !(
      this.toContactName.length < 2 ||
      this.toContactEmail.length < 2 ||
      (this.toContactEmail &&
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.toContactEmail
        )) || //eslint-disable-line
      this.companyRegistrationNumber.length < 2 ||
      this.companyName.length < 2 ||
      this.country.length < 2 ||
      this.state.length < 2 ||
      this.city.length < 2
    );
  }
}
