import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Watermark extends Vue {
  @Prop({ type: String, required: true, default: 'signed on' })
  private signedontext!: string;

  @Prop({ type: String, required: true, default: '' })
  private signdate!: string;

  @Prop({ type: String, required: true, default: '' })
  private fullname!: string;

  @Prop({ type: Number, required: true, default: '' })
  private width!: number;

  @Prop({ type: Number, required: true, default: '' })
  private height!: number;

  public constructor(any: any) {
    super();
  }

  private get firstandlastname(): string[] {
    const trimFullname = this.fullname.trim();

    if (trimFullname.length <= 20) {
      return [trimFullname, ''];
    }

    const half: number = trimFullname.length / 2;
    return [trimFullname.slice(0, half), trimFullname.slice(half)];
  }
}
