var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"steps-parent-wrapper"},[_c('div',{class:[
      'steps',
      'custom-scroll',
      { onestep: _vm.steps.length == 1 },
      { twostep: _vm.steps.length == 2 },
      { threestep: _vm.steps.length == 3 },
      { fourstep: _vm.steps.length == 4 },
      { fivestep: _vm.steps.length == 5 },
      { sixstep: _vm.steps.length == 6 },
      { sevenstep: _vm.steps.length == 7 },
      { eightstep: _vm.steps.length == 8 },
    ],attrs:{"id":`scrollContent-${_vm.lineId}`},on:{"scroll":_vm.removeLineOnScroll}},_vm._l((_vm.getSortedSteps),function(step,index){return _c('div',{key:step.id + index,class:[
        'step',
        'step-' + _vm.lineId,
        { active: _vm.stepActive(step) },
        { first: index === 0 && _vm.steps.length !== 1 },
        { last: index + 1 === _vm.steps.length && _vm.steps.length !== 1 },
      ]},[_c('div',{staticClass:"hideborder",attrs:{"id":`hideborder-${_vm.lineId}`}}),(step.status === 0)?_c('div',{staticClass:"pending",on:{"click":function($event){return _vm.selectStep(step)}}}):(step.status === 2)?_c('div',{staticClass:"completed",on:{"click":function($event){return _vm.selectStep(step)}}}):_c('div',{staticClass:"waiting",on:{"click":function($event){return _vm.selectStep(step)}}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.displayName(step.stepId)))]),_c('div',{staticClass:"customOrderlines-margin-bottom"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInfo),expression:"showInfo"}]},[_vm._l((step.parallelSupplyChain0),function(parallelStep,indexParallel){return _c('div',{key:parallelStep.displayName + parallelStep.id + indexParallel},[(indexParallel == 0)?_c('div',{staticClass:"vert-border one",style:([
              { height: `${83 + 80 * step.parallelChainCount}px` },
            ])}):_vm._e(),_c('div',{class:[
              'righttoleft',
              'one',
              { active: _vm.parallelStepActive(step.stepId, parallelStep, 0) },
            ],style:([
              {
                right: `${35 + 80 * indexParallel}px`,
                top: `${120 + 80 * step.parallelChainCount}px`,
              },
            ])},[(parallelStep.status === 0)?_c('div',{class:['pending'],on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep, 0)}}}):(parallelStep.status === 2)?_c('div',{class:['completed'],on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep, 0)}}}):_c('div',{class:['waiting'],on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep, 0)}}}),(indexParallel + 1 === step.parallelSupplyChain0.length)?_c('div',{staticClass:"title",attrs:{"id":`leftest-title-${_vm.lineId}`}},[_vm._v(" "+_vm._s(_vm.displayName(parallelStep.stepId))+" ")]):_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.displayName(parallelStep.stepId))+" ")])])])}),_vm._l((step.parallelSupplyChain1),function(parallelStep1,indexParallel1){return _c('div',{key:parallelStep1.displayName + parallelStep1.id + indexParallel1},[(indexParallel1 == 0)?_c('div',{staticClass:"vert-border two",style:([
              {
                height: `${80}px`,
                top: `${200 + 80 * (step.parallelChainCount - 1)}px`,
              },
            ])}):_vm._e(),_c('div',{class:[
              'righttoleft',
              'two',
              { active: _vm.parallelStepActive(step.stepId, parallelStep1, 1) },
            ],style:([
              {
                right: `${35 + 80 * indexParallel1}px`,
                top: `${200 + 80 * step.parallelChainCount}px`,
              },
            ])},[(parallelStep1.status === 0)?_c('div',{staticClass:"pending",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep1, 1)}}}):(parallelStep1.status === 2)?_c('div',{staticClass:"completed",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep1, 1)}}}):_c('div',{staticClass:"waiting",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep1, 1)}}}),(indexParallel1 + 1 === step.parallelSupplyChain1.length)?_c('div',{staticClass:"title",attrs:{"id":`leftest-title-${_vm.lineId}`}},[_vm._v(" "+_vm._s(_vm.displayName(parallelStep1.stepId))+" ")]):_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.displayName(parallelStep1.stepId))+" ")])])])}),_vm._l((step.parallelSupplyChain2),function(parallelStep2,indexParallel2){return _c('div',{key:parallelStep2.displayName + parallelStep2.id + indexParallel2},[(indexParallel2 == 0)?_c('div',{staticClass:"vert-border three",style:([
              {
                height: `${80}px`,
                top: `${280 + 80 * (step.parallelChainCount - 1)}px`,
              },
            ])}):_vm._e(),_c('div',{class:[
              'righttoleft',
              'three',
              { active: _vm.parallelStepActive(step.stepId, parallelStep2, 2) },
            ],style:([
              {
                right: `${35 + 80 * indexParallel2}px`,
                top: `${280 + 80 * step.parallelChainCount}px`,
              },
            ])},[(parallelStep2.status === 0)?_c('div',{staticClass:"pending",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep2, 2)}}}):(parallelStep2.status === 2)?_c('div',{staticClass:"completed",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep2, 2)}}}):_c('div',{staticClass:"waiting",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep2, 2)}}}),(indexParallel2 + 1 === step.parallelSupplyChain2.length)?_c('div',{staticClass:"title",attrs:{"id":`leftest-title-${_vm.lineId}`}},[_vm._v(" "+_vm._s(_vm.displayName(parallelStep2.stepId))+" ")]):_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.displayName(parallelStep2.stepId))+" ")])])])}),_vm._l((step.parallelSupplyChain3),function(parallelStep3,indexParallel3){return _c('div',{key:parallelStep3.displayName + parallelStep3.id + indexParallel3},[(indexParallel3 == 0)?_c('div',{staticClass:"vert-border four",style:([
              {
                height: `${80}px`,
                top: `${360 + 80 * (step.parallelChainCount - 1)}px`,
              },
            ])}):_vm._e(),_c('div',{class:[
              'righttoleft',
              'four',
              { active: _vm.parallelStepActive(step.stepId, parallelStep3, 3) },
            ],style:([
              {
                right: `${35 + 80 * indexParallel3}px`,
                top: `${360 + 80 * step.parallelChainCount}px`,
              },
            ])},[(parallelStep3.status === 0)?_c('div',{staticClass:"pending",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep3, 3)}}}):(parallelStep3.status === 2)?_c('div',{staticClass:"completed",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep3, 3)}}}):_c('div',{staticClass:"waiting",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep3, 3)}}}),(indexParallel3 + 1 === step.parallelSupplyChain3.length)?_c('div',{staticClass:"title",attrs:{"id":`leftest-title-${_vm.lineId}`}},[_vm._v(" "+_vm._s(_vm.displayName(parallelStep3.stepId))+" ")]):_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.displayName(parallelStep3.stepId))+" ")])])])}),_vm._l((step.parallelSupplyChain4),function(parallelStep4,indexParallel4){return _c('div',{key:parallelStep4.displayName + parallelStep4.id + indexParallel4},[(indexParallel4 == 0)?_c('div',{staticClass:"vert-border five",style:([
              {
                height: `${80}px`,
                top: `${440 + 80 * (step.parallelChainCount - 1)}px`,
              },
            ])}):_vm._e(),_c('div',{class:[
              'righttoleft',
              'five',
              { active: _vm.parallelStepActive(step.stepId, parallelStep4, 4) },
            ],style:([
              {
                right: `${35 + 80 * indexParallel4}px`,
                top: `${440 + 80 * step.parallelChainCount}px`,
              },
            ])},[(parallelStep4.status === 0)?_c('div',{staticClass:"pending",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep4, 4)}}}):(parallelStep4.status === 2)?_c('div',{staticClass:"completed",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep4, 4)}}}):_c('div',{staticClass:"waiting",on:{"click":function($event){return _vm.selectParallelStep(step.stepId, parallelStep4, 4)}}}),(indexParallel4 + 1 === step.parallelSupplyChain4.length)?_c('div',{staticClass:"title",attrs:{"id":`leftest-title-${_vm.lineId}`}},[_vm._v(" "+_vm._s(_vm.displayName(parallelStep4.stepId))+" ")]):_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.displayName(parallelStep4.stepId))+" ")])])])})],2)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }