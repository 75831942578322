import i18n from '@/i18n';
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';

@Component
export default class SmallScreenPopUpMsg extends Vue {
  @Prop()
  private msgText!: string;

  @Prop({ default: '' })
  private msgContent!: string;

  @Prop({ default: i18n.t('global.myprofile.close').toString() })
  private closeText!: string;

  @Prop({ default: '' })
  private successText!: string;

  @Emit()
  private success(): void {
    // Emit
  }

  @Emit()
  private close(): void {
    // Emit
  }
}
