import i18n from '@/i18n';
import { DropdownModel } from '@/models/dropdownModel';
import { StepsHelper } from '@/helpers/stepsHelper';
import { ProductGroupHelper } from './productGroupHelper';

export class CompanyFormDropdownHelper {
  public static getCompanyTypes(): DropdownModel[] {
    return [
      { value: 2, text: i18n.t('dropdown.company_type.agent').toString() },
      {
        value: 3,
        text: i18n.t('dropdown.company_type.manufacturer').toString(),
      },
      { value: 4, text: i18n.t('dropdown.company_type.logistics').toString() },
    ];
  }

  public static getCompanySubtypes(): DropdownModel[] {
    return StepsHelper.getSteps().map(
      (s) => new DropdownModel(s.id, s.description)
    );
  }

  public static getProductGroups(): DropdownModel[] {
    return ProductGroupHelper.getProductGroups().map(
      (p) =>
        new DropdownModel(
          p.id,
          p.description
            .split(/(?=[A-Z])/)
            .join(' ')
            .charAt(0)
            .toUpperCase() +
            p.description
              .split(/(?=[A-Z])/)
              .join(' ')
              .slice(1)
              .toLowerCase()
        )
    );
  }

  public static getCategories(): DropdownModel[] {
    return [
      { value: 1, text: i18n.t('dropdown.category.men').toString() },
      { value: 2, text: i18n.t('dropdown.category.women').toString() },
      { value: 3, text: i18n.t('dropdown.category.kids').toString() },
      { value: 5, text: i18n.t('dropdown.category.trims').toString() },
      { value: 6, text: i18n.t('dropdown.category.workwear').toString() },
      { value: 7, text: i18n.t('dropdown.category.upholstery').toString() },
      { value: 8, text: i18n.t('dropdown.category.yarn').toString() },
      { value: 9, text: i18n.t('dropdown.category.raw-material').toString() },
      { value: 4, text: i18n.t('dropdown.category.other').toString() },
    ];
  }
}
