//TTD-2736 sprint-19 develoment
import { Vue, Component, Prop, Watch, Ref } from "vue-property-decorator";
import lodash from "lodash";
import { mapState } from "vuex";
import { ActionTypes } from "@/store/actions/actions";
import _ from 'underscore';
import ClickOutside from 'vue-click-outside';
import moment from "moment";


// Components
import ProvideData from "@/views/footprint/components/provideData.vue";
import Steps from '@/views/footprint/components/orderlineSteps.vue';
import StepsLoader from '@/views/orderLines/components/stepsLoader.vue';
import yarnFootprint from "@/views/footprint/components/yarnFootprint.vue"; // TTD-3934
import fabricFootprint from "@/views/footprint/components/fabricFootprint.vue"; // TTD-3934
import wetProcessFootprint from "@/views/footprint/components/wetProcessFootprint.vue"; // TTD-3934
import garmentFootprint from "@/views/footprint/components/garmentFootprint.vue"; // TTD-3934

// Services
import { ClientRequestFootPrintData } from '@/services/footPrintRequestService';
import { SupplierService } from "@/services/supplierService";
import { OrderService } from "@/services/orderService";

// Models
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { ProductGroup } from "@/models/productGroup";
import { DropdownModel } from "@/models/dropdownModel";
import { FootPrintChangedConstant, FootPrintChangedTabConstant, FootPrintCompletedConstant, SaveFootprintDeadlineModel } from "@/models/footprintModel";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { UserModel } from "@/models/userModel";
import { CompanyModel } from "@/models/companyModel";
import { UserPermissionModel } from "@/models/permissionModel";
import { FootprintPartnerModel, OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { StepState } from "@/models/stepState";
import { FootprintStepsModel, StepReferenceModel } from "@/models/orderLineStepModel";

// Helpers
import { StepsHelper } from "@/helpers/stepsHelper";
import { Guid } from "@/models/guid";

@Component({
  components: { ProvideData, Steps, StepsLoader, yarnFootprint, fabricFootprint, wetProcessFootprint, garmentFootprint },
  directives: {
    ClickOutside
  },
  computed: mapState(['isOrderLoading', 'orders', 'footprintOrders', 'footprintCount', 'userRole', 'orderLineSteps', 'orderLineStepsLoading', 'evidenceCountLoading', 'user', 'isFootprintChanged', 'showFootprintConfirmation', 'changedFootprintName', 'incorrectFootprintData'])
})
export default class Footprint extends Vue {

  private supplierService: SupplierService;

  private footprintService: ClientRequestFootPrintData;

  private orderService: OrderService;

  private tabIndex: number = 0;

  private selectedTabName: string = "";

  private orders!: OrderLineDisplayModel[];

  // private footprintPartner!: FootprintPartnerModel[];

  private isOrderLoading!: boolean;

  private evidenceCountLoading!: boolean;

  private companyType!: number;

	private suppliers!: OrderCompanyModel[];

  private join: boolean = false;

  private isFootprintChanged!: string;

  private footprintCount!: number;

  private footprintOrders!: OrderLineDisplayModel[];

  private searchFilter: string = "";

  private selectedBrand: string = "";

  private selectedProductGroup: ProductGroup = ProductGroup.None;

  private selectedUsername: string = "";

  private selectedSeason: string = "";

  private selectedPartnerID: string = "";

  private dateFrom: string | Date = "";

  private dateTo: string | Date = "";

  private showAllUsers: boolean = true;

  private showAllSeasons: boolean = true;

  private showFilters: boolean = false;

  private selectedFilters: number = 0;

  private user!: UserModel;

  private userRole!: string;

  private company!: CompanyModel;

  private showClearFilter: boolean = false;

  private provideCount: number = 0;

  private completedCount: number = 0;

  private showDetails: boolean = false;

  private detailTabIndex: number = -1;

  private footprintOrderDeadline: string = "";

  private footprintFilledByCompanyID: string = "";

  private today: Date = new Date();

  private orderDetail: OrderLineDisplayModel = new OrderLineDisplayModel();

  private showSaveConfirmPopUp: boolean = false;

  private isSaving: boolean = false;

  private userPermissions!: UserPermissionModel | null;

  private showFootprintConfirmation!: string;

  private changedFootprintName!: string;

  private isLoading: boolean = false;

  private isTabLoading: boolean = true;

  private isAllUsersSelected: boolean = false;

  private isAllSeasonsSelected: boolean = false;

  private isPageReload: boolean = false;

	private userCompanyId!: string;

  private orderLineStepsLoading!: boolean;

  private orderLineSteps!: OrderLineStepDisplayModel[];

  private isParallelChainAdded: boolean = false;

  private scpOrderLineSteps!: OrderLineStepDisplayModel[];

  private yarnTabIndex!: number;

  private fabricTabIndex!: number;

  private wetTabIndex!: number;

  private garmentTabIndex!: number;

  // Sprint 20, TTD-3932
  private hideChain: boolean = false;

  private totalParallelChains: number = 0;

  private allFootprintSteps: StepReferenceModel[] = [];

  // TTD-3931
  private steps: OrderLineStepDisplayModel[] = [];
  private isOrderPropShown: boolean = false;

  // TTD-3934
  private showYarn: boolean = true;
  private showFabric: boolean = true;
  private showWetProcess: boolean = true;
  private showGarment: boolean = true;

  private activeStep: string = Guid.Empty;

  @Ref('contentElement') contentElement! : HTMLDivElement;

  public constructor() {
    super();
    this.supplierService = new SupplierService();
    this.footprintService = new ClientRequestFootPrintData();
    this.orderService = new OrderService();
  }

  private async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if (this.isOrderLoading === false && this.orders.length === 0) {
      await this.$store.dispatch(ActionTypes.SET_ORDERLINE);
    }
    if(this.footprintCount === 0){
      await this.$store.dispatch(ActionTypes.SET_ORDER_LINE_EVIDENCE_COUNT);
    }
    this.selectATab(0);
  }


  private get filterOrders(): OrderLineDisplayModel[] {
    let orders: OrderLineDisplayModel[] = [];
    // const provideData = this.footprintOrders.filter(o => o.yarn.footprintStatus !== FootPrintCompletedConstant.COMPLETED
    //   || o.yarn.transportStatus !== FootPrintCompletedConstant.COMPLETED
    //   || o.fabric.footprintStatus !== FootPrintCompletedConstant.COMPLETED
    //   || o.fabric.footprintStatus !== FootPrintCompletedConstant.COMPLETED
    //   || o.wetprocess.footprintStatus !== FootPrintCompletedConstant.COMPLETED
    //   || o.wetprocess.footprintStatus !== FootPrintCompletedConstant.COMPLETED
    //   || o.garment.footprintStatus !== FootPrintCompletedConstant.COMPLETED
    //   || o.garment.footprintStatus !== FootPrintCompletedConstant.COMPLETED);
    const provideData = this.footprintOrders.filter(o => ((o.finalFootprintStatus === FootPrintCompletedConstant.PARTIAL || o.finalFootprintStatus === "" || o.actionOnFootprint.status === FootPrintCompletedConstant.FORCEINPROGRESS)  && (o.footprintFlag === 'TRUE') && o.actionOnFootprint.status !== FootPrintCompletedConstant.FORCECOMPLETED));
    // const completed = this.footprintOrders.filter(o => o.yarn.footprintStatus === FootPrintCompletedConstant.COMPLETED
    //   && o.yarn.transportStatus === FootPrintCompletedConstant.COMPLETED
    //   && o.fabric.footprintStatus === FootPrintCompletedConstant.COMPLETED
    //   && o.fabric.footprintStatus === FootPrintCompletedConstant.COMPLETED
    //   && o.wetprocess.footprintStatus === FootPrintCompletedConstant.COMPLETED
    //   && o.wetprocess.footprintStatus === FootPrintCompletedConstant.COMPLETED
    //   && o.garment.footprintStatus === FootPrintCompletedConstant.COMPLETED
    //   && o.garment.footprintStatus === FootPrintCompletedConstant.COMPLETED);
    const completed = this.footprintOrders.filter(o => (o.finalFootprintStatus === FootPrintCompletedConstant.COMPLETED && o.footprintFlag === 'TRUE' && o.actionOnFootprint.status !== FootPrintCompletedConstant.FORCEINPROGRESS) || o.actionOnFootprint.status === FootPrintCompletedConstant.FORCECOMPLETED);
    this.provideCount = provideData.length;
    this.completedCount = completed.length;
    if(this.tabIndex === 0){
      orders = provideData;
    }
    else{
      orders = completed;
    }
    if (this.searchFilter !== "") {
      this.showClearFilter = true;
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      orders = orders.filter(o => o.orderNumber.toLowerCase().includes(lowerCaseFilter)
        || (o.styleName != null && o.styleName.toLowerCase().includes(lowerCaseFilter))
        || (o.styleNumber != null && o.styleNumber.toLowerCase().includes(lowerCaseFilter))
        || (o.styleNumber != null && o.styleNumber.toLowerCase().includes(lowerCaseFilter))
        || (o.brandName != null && o.brandName.toLowerCase().includes(lowerCaseFilter)));
    }
    if (this.selectedBrand !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => o.brandName === this.selectedBrand);
    }
    if (this.selectedProductGroup !== ProductGroup.None) {
      this.showClearFilter = true;
      orders = orders.filter(o => o.productGroup === this.selectedProductGroup);
    }
    if(this.tabIndex === 0){
      this.provideCount = orders.length;
    }else{
      this.completedCount = orders.length;
    }
    return orders;
  }

  private get brands(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_brands").toString() }];
    const distinctBrands: any[] = [];
    this.footprintOrders.filter((item) => {
      const i = distinctBrands.findIndex(x => (x.orderLines[0].brandName == item.orderLines[0].brandName));
      if (i <= -1 && item.orderLines[0].brandName !== null && item.orderLines[0].brandName !== "") {
        distinctBrands.push(item);
      }
      return null;
    });
    distinctBrands.filter(line => {
      if (line.orderLines[0].season !== null) {
        options.push(new DropdownModel(line.orderLines[0].brandName, line.orderLines[0].brandName));
      }
    });
    return options;
  }
  
  private get productGroups(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: 0, text: this.$t("pages.order_lines.all_productgroups").toString() }];
    this.footprintOrders.filter(line => {
      if (options.findIndex(option => option.value === line.productGroup) === -1) {
        const productGroupName = this.$t(`enums.product_group.${line.productGroup}`).toString().split(/(?=[A-Z])/).join(" ");
        options.push(new DropdownModel(line.productGroup, productGroupName.charAt(0).toUpperCase() + productGroupName.slice(1).toLowerCase()));
      }
    });

    return options;
  }

  private async clearAllFilters(): Promise<void> {
    this.searchFilter = "";
    this.selectedBrand = "";
    this.selectedProductGroup = ProductGroup.None;
    this.showClearFilter = false;
  }

  private selectATab(index: number): void {
    this.tabIndex = index;
  }

  private async provideFootprintDataHandler(order: OrderLineDisplayModel): Promise<void> {
    setTimeout( () => this.$nextTick(() => {
      this.contentElement.scrollIntoView({ behavior: 'smooth', block: 'start'});
    }), 0);
    let steps: OrderLineStepDisplayModel[] = []; 
    this.isLoading = true;
    this.showDetails = true;
    this.detailTabIndex = -1;
    this.isTabLoading = true;
    this.showYarn = true;
    this.showFabric = true;
    this.showWetProcess = true;
    this.showGarment = true;
    this.orderDetail = this.footprintOrders.filter(o => o.orderId === order.orderId)[0];
    this.footprintOrderDeadline = this.orderDetail.footprintDeadline;
    this.footprintFilledByCompanyID = this.orderDetail.footprintFilledByCompanyID;
    if((this.orderDetail.toCompanyId === this.user.companyId) || (this.orderDetail.delegateCompanyID === this.user.companyId)){
      this.isOrderPropShown = true;
      steps = this.orderLineSteps.filter(s => s.orderId === this.orderDetail.orderId).filter(s => (s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER));
    } else {
      let result = await this.orderService.getScpOrderLineSteps(this.user.companyId,order.orderId,order.orderLines[0].lineID);
      if(result.success){
        steps = result.orderLineSteps;
      }
    }
    this.supplychainSteps(steps);
    if (this.orderDetail.yarn.footprint === undefined) {
      const result = await this.footprintService.getFootprintOnOrderAsync(order);
      if (result) {
        this.orderDetail = this.footprintOrders.filter(o => o.orderId === order.orderId)[0];
        this.footprintOrderDeadline = this.orderDetail.footprintDeadline;
      }
    }
    this.isLoading = false;
  }

  private get openDate(): Date {
    return this.today;
  }

  private stepsLoading(): void {
    if(this.orderLineSteps.length > 0){
       const steps =  this.orderLineSteps.filter(s => s.orderId === this.orderDetail.orderId);
       if(steps.length > 0){
        this.isLoading = false;
       }
       else{
        this.isLoading = this.orderLineStepsLoading ? true : false;
       }
    }
    else{
      this.isLoading = this.orderLineStepsLoading;
    }
    if(!this.user.isT1Scp && !this.user.isDelegatePartner){
      if(this.scpOrderLineSteps===undefined || this.scpOrderLineSteps.length === 0){
        this.isLoading = true
      }else{
        this.isLoading = false
      }
    }
  }

  private supplychainSteps(steps: OrderLineStepDisplayModel[]) {
    let count = 0;
    steps.sort((step1, step2) =>{
      return step1.sequence - step2.sequence;
    });
    steps.forEach(o => {
        o.parallelChainCount = count;
        if (o.parallelSupplyChain0!.length>0) {
          count++;
        }
        if (o.parallelSupplyChain1!.length>0) {
          count++;
        }
        if (o.parallelSupplyChain2!.length>0) {
          count++;
        }
        if (o.parallelSupplyChain3!.length>0) {
          count++;
        }
        if (o.parallelSupplyChain4!.length>0) {
          count++;
        }
    });
    this.totalParallelChains = count;
    count > 0 ? this.isParallelChainAdded = true : this.isParallelChainAdded = false;
    // this.allFootprintSteps = this.getAllFootprintSteps(steps);
    this.steps = steps;
}

  private get yarnStatusColor(): string {
    let status = "red";
    const order = this.footprintOrders.filter(o => o.orderId === this.orderDetail.orderId)[0];
    if(order && (order.yarn.footprintStatus === FootPrintCompletedConstant.COMPLETED && order.yarn.transportStatus === FootPrintCompletedConstant.COMPLETED)){
      status = 'green';
    }
    else if(order && (((order.yarn.footprintStatus === "" || order.yarn.footprintStatus === FootPrintCompletedConstant.INCOMPLETE) && order.yarn.transportStatus === "") || (order.yarn.footprintStatus === FootPrintCompletedConstant.INCOMPLETE && (order.yarn.transportStatus === FootPrintCompletedConstant.INCOMPLETE || order.yarn.transportStatus === '')))){
      status = 'red';
    }
    else{
      status = 'orange';
    }
    return status;
  }

  private get fabricStatusColor(): string {
    const order = this.footprintOrders.filter(o => o.orderId === this.orderDetail.orderId)[0];
    let status = "red";
    if(order && (order.fabric.footprintStatus === FootPrintCompletedConstant.COMPLETED && order.fabric.transportStatus === FootPrintCompletedConstant.COMPLETED)){
      status = 'green';
    }
    else if(order && (((order.fabric.footprintStatus === "" || order.fabric.footprintStatus === FootPrintCompletedConstant.INCOMPLETE) && order.fabric.transportStatus === "") || (order.fabric.footprintStatus === FootPrintCompletedConstant.INCOMPLETE && (order.fabric.transportStatus === FootPrintCompletedConstant.INCOMPLETE || order.fabric.transportStatus === '')))){
      status = 'red';
    }
    else{
      status = 'orange';
    }
    return status;
  }

  private get wetStatusColor(): string {
    const order = this.footprintOrders.filter(o => o.orderId === this.orderDetail.orderId)[0];
    let status = "red";
    if(order && (order.wetprocess.footprintStatus === FootPrintCompletedConstant.COMPLETED && order.wetprocess.transportStatus === FootPrintCompletedConstant.COMPLETED)){
      status = 'green';
    }
    else if(order && (((order.wetprocess.footprintStatus === "" || order.wetprocess.footprintStatus === FootPrintCompletedConstant.INCOMPLETE) && order.wetprocess.transportStatus === "") || (order.wetprocess.footprintStatus === FootPrintCompletedConstant.INCOMPLETE && (order.wetprocess.transportStatus === FootPrintCompletedConstant.INCOMPLETE || order.wetprocess.transportStatus === '')))){
      status = 'red';
    }
    else{
      status = 'orange';
    }
    return status;
  }

  private get garmentStatusColor(): string {
    const order = this.footprintOrders.filter(o => o.orderId === this.orderDetail.orderId)[0];
    let status = "red";
    if(order && (order.garment.footprintStatus === FootPrintCompletedConstant.COMPLETED && order.garment.transportStatus === FootPrintCompletedConstant.COMPLETED)){
      status = 'green';
    }
    else if(order && ((order.garment.footprintStatus === "" && order.garment.transportStatus === "") || (order.garment.footprintStatus === FootPrintCompletedConstant.INCOMPLETE && order.garment.transportStatus === FootPrintCompletedConstant.INCOMPLETE))){
      status = 'red';
    }
    else{
      status = 'orange';
    }
    return status;
  }

  // TTD-3932 Sprint 20
  private stepSelected(id: string, stepId: number): void {
    if(this.isFootprintChanged === FootPrintChangedConstant.DATA){
      this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
      this.showSaveConfirmPopUp = true;
    } else {
      if (stepId === 3 || stepId === 22) {
        this.detailTabIndex = 0;
      } else if(stepId === 4 || stepId === 5 || stepId === 23) {
        this.detailTabIndex = 1;
      } else if(stepId === 6 || stepId === 7 || stepId === 8 || stepId === 31) {
        this.detailTabIndex = 2;
      } else if(stepId === 10 || stepId === 25 || stepId === 32) {
        if((this.orderDetail.toCompanyId === this.user.companyId) || (this.orderDetail.delegateCompanyID === this.user.companyId)){
          this.detailTabIndex = 3;
        } else {
          const wetPSteps = this.allFootprintSteps.filter(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31));
          if (wetPSteps.length > 0 && wetPSteps.filter(ws => ws.toCompanyId === this.user.companyId).length>0) {
            this.detailTabIndex = 3;
          } else {
            this.detailTabIndex = 2;
          }
        }
      }
      setTimeout( () => this.$nextTick(() => {
        this.activeStep = id;
      }), 0);
    }
  }

  private setFpDeadline(date: Date | null): void {
    if(this.$store.getters.isFootprintChanged !== FootPrintChangedConstant.DATA){
      this.$store.commit('setFootprintChanged', FootPrintChangedConstant.DEADLINE);
    }
  }

  private closeSaveFootprintModal(): void {
    this.$store.commit('setFootprintChanged', '');
    this.$store.commit('setFootprintConfirmation', '');
    this.$store.commit('setIncorrectFootprintData', false);
    this.showSaveConfirmPopUp = false;
    this.footprintOrderDeadline = this.orderDetail.footprintDeadline;
  }

  private async saveFootprintDeadline(): Promise<void> {
    return;
  }

  private saveFootprintData(): void {
    switch (this.changedFootprintName){
      case FootPrintChangedTabConstant.YARN_FP: {
        const func = this.$refs.yarn as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveYarnFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.YARN_TP: {
        const func = this.$refs.yarn as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveYarnTransportData();
        break;
      }
      case FootPrintChangedTabConstant.FABRIC_FP: {
        const func = this.$refs.fabric as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveFabricFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.FABRIC_TP: {
        const func = this.$refs.fabric as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveFabricTransportData();
        break;
      }
      case FootPrintChangedTabConstant.WET_FP: {
        const func = this.$refs.wet as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.beforeSave();
        break;
      }
      case FootPrintChangedTabConstant.WET_TP: {
        const func = this.$refs.wet as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.saveWetTransportData();
        break;
      }
      case FootPrintChangedTabConstant.GARMENT_FP: {
        const func = this.$refs.garment as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.beforeSave();
        break;
      }
      case FootPrintChangedTabConstant.GARMENT_TP: {
        const func = this.$refs.garment as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.saveGarmentTransportData();
        break;
      }
      default:
        this.$store.commit('setFootprintChanged', '');
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        break;
    }
  }

  private clearFootprintData(): void {
    switch (this.changedFootprintName){
      case FootPrintChangedTabConstant.YARN_FP: {
        const func = this.$refs.yarn as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearYarnFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.YARN_TP: {
        const func = this.$refs.yarn as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearYarnTransportData();
        break;
      }
      case FootPrintChangedTabConstant.FABRIC_FP: {
        const func = this.$refs.fabric as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearFabricFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.FABRIC_TP: {
        const func = this.$refs.fabric as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearFabricTransportData();
        break;
      }
      case FootPrintChangedTabConstant.WET_FP: {
        const func = this.$refs.wet as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearWetFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.WET_TP: {
        const func = this.$refs.wet as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearWetTransportData();
        break;
      }
      case FootPrintChangedTabConstant.GARMENT_FP: {
        const func = this.$refs.garment as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.showSaveConfirmPopUp = false;
        func.clearGarmentFootprintData();
        break;
      }
      case FootPrintChangedTabConstant.GARMENT_TP: {
        const func = this.$refs.garment as any;
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        this.showSaveConfirmPopUp = false;
        func.clearGarmtTransportData();
        break;
      }
      default:
        this.$store.commit('setFootprintChanged', '');
        this.$store.commit('setFootprintConfirmation', '');
        this.$store.commit('setIncorrectFootprintData', false);
        break;
    }
  }

  private showFootprintOrderList(): void {
    if (this.isFootprintChanged !== '') {
      this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
      this.showSaveConfirmPopUp = true;
    }
    else {
      this.$store.commit("setFootprintChanged", '');
      this.$store.commit("setChangedFootprintName", '');
      this.showDetails = false;
      this.selectATab(this.tabIndex);
    }
  }

  private selectDetailTab(newTabIndex: number, prevTabIndex: number, bvEvent: any): void {
    const order: OrderLineDisplayModel = this.footprintOrders.filter(o => o.orderId === this.orderDetail.orderId)[0];
    switch (newTabIndex) {
      case 0:
        if(this.isFootprintChanged === FootPrintChangedConstant.DATA){
          bvEvent.preventDefault();
          this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
          this.showSaveConfirmPopUp = true;
        }
        else{
          this.detailTabIndex = newTabIndex;
        }
        break;

      case 1:
        if(this.isFootprintChanged === FootPrintChangedConstant.DATA){
          bvEvent.preventDefault();
          this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
          this.showSaveConfirmPopUp = true;
        }
        break;

      case 2:
        if(this.isFootprintChanged === FootPrintChangedConstant.DATA){
          bvEvent.preventDefault();
          this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
          this.showSaveConfirmPopUp = true;
        }
        break;

      case 3:
        if(this.isFootprintChanged === FootPrintChangedConstant.DATA){
          bvEvent.preventDefault();
          this.$store.commit('setFootprintConfirmation', this.isFootprintChanged);
          this.showSaveConfirmPopUp = true;
        }
        break;
    }
  }

  private navigateTabHandler(index: number): void {
    this.detailTabIndex = index;
  }

  private formatDate(dateToBeFormatted: string): string {
    if (dateToBeFormatted==="9999-01-01T00:00:00Z" || dateToBeFormatted==="") {
      return "-";
    }
  return moment(dateToBeFormatted).format('D MMMM YYYY');
  }

  // Sprint 20, TTD-3932
  private hideSection(): boolean {
    this.hideChain = !this.hideChain;
    return this.hideChain;
  }

  // Sprint-20 TTD-3931 check is footprint step exist or not
  private isFootprintStep(stepId:number): boolean {
    const allFootprintStepIds = StepsHelper.getFootprintSteps().map(s => s.id);
    if (allFootprintStepIds.includes(stepId)) {
        return true;
    }
    return false;
}
  // Sprint-20 TTD-3931 check is parallel chaing has footprint step
  private isPCHasFPStep(step:OrderLineStepDisplayModel): boolean{
    let isPCHasFPStep : boolean = false;
    if(step.parallelSupplyChain0!.length > 0){
      const index0 = step.parallelSupplyChain0.findIndex(p0 => p0.toCompanyId === this.user.companyId && this.isFootprintStep(p0.stepId));
      if(index0!==-1){
        isPCHasFPStep = true
      }else{
        step.parallelSupplyChain0 = [];
      }
    }
    if(step.parallelSupplyChain1!.length > 0){
      const index1 = step.parallelSupplyChain1.findIndex(p1 => p1.toCompanyId === this.user.companyId && this.isFootprintStep(p1.stepId));
      if(index1!==-1){
        isPCHasFPStep = true
      }
    }
    if(step.parallelSupplyChain2!.length > 0){
      const index2 = step.parallelSupplyChain2.findIndex(p2 => p2.toCompanyId === this.user.companyId && this.isFootprintStep(p2.stepId));
      if(index2!==-1){
        isPCHasFPStep = true
      }
    }
    if(step.parallelSupplyChain3!.length > 0){
      const index3 = step.parallelSupplyChain3.findIndex(p3 => p3.toCompanyId === this.user.companyId && this.isFootprintStep(p3.stepId));
      if(index3!==-1){
        isPCHasFPStep = true
      }
    }
    if(step.parallelSupplyChain4!.length > 0){
      const index4 = step.parallelSupplyChain4.findIndex(p4 => p4.toCompanyId === this.user.companyId && this.isFootprintStep(p4.stepId));
      if(index4!==-1){
        isPCHasFPStep = true
      }
    }
    return isPCHasFPStep;
  }

  // Sprint-20 TTD-3931 checkParallel chain and set the parallel chain at proper index
  private setParallelChain(step:OrderLineStepDisplayModel): void{
    if(step.parallelSupplyChain0!.length > 0){
      const index0 = step.parallelSupplyChain0.findIndex(p0 => p0.toCompanyId === this.user.companyId && this.isFootprintStep(p0.stepId));
      if(index0===-1){
        step.parallelSupplyChain0 = [];
      }
    }
    if(step.parallelSupplyChain1!.length > 0){
      const index1 = step.parallelSupplyChain1.findIndex(p1 => p1.toCompanyId === this.user.companyId && this.isFootprintStep(p1.stepId));
      if(index1!==-1){
        if(step.parallelSupplyChain0.length === 0){
          step.parallelSupplyChain0 = step.parallelSupplyChain1;
          step.parallelSupplyChain1 = [];
        }
      }else{
        step.parallelSupplyChain1 = [];
      }
    }
    if(step.parallelSupplyChain2!.length > 0){
      const index2 = step.parallelSupplyChain2.findIndex(p2 => p2.toCompanyId === this.user.companyId && this.isFootprintStep(p2.stepId));
      if(index2!==-1){
        if(step.parallelSupplyChain0.length === 0 && step.parallelSupplyChain1.length === 0){
          step.parallelSupplyChain0 = step.parallelSupplyChain2;
          step.parallelSupplyChain2 = [];
        }else if(step.parallelSupplyChain0.length > 0 && step.parallelSupplyChain1.length === 0){
          step.parallelSupplyChain1 = step.parallelSupplyChain2;
          step.parallelSupplyChain2 = [];
        }
      }else{
        step.parallelSupplyChain2 = [];
      }
    }
    if(step.parallelSupplyChain3!.length > 0){
      const index3 = step.parallelSupplyChain3.findIndex(p3 => p3.toCompanyId === this.user.companyId && this.isFootprintStep(p3.stepId));
      if(index3!==-1){
        if(step.parallelSupplyChain0.length === 0 && step.parallelSupplyChain1.length === 0 && step.parallelSupplyChain2.length === 0){
          step.parallelSupplyChain0 = step.parallelSupplyChain3;
          step.parallelSupplyChain3 = [];
        }else if(step.parallelSupplyChain0.length > 0 && step.parallelSupplyChain1.length === 0 && step.parallelSupplyChain2.length === 0){
          step.parallelSupplyChain1 = step.parallelSupplyChain3;
          step.parallelSupplyChain3 = [];
        }else if(step.parallelSupplyChain0.length > 0 && step.parallelSupplyChain1.length > 0 && step.parallelSupplyChain2.length === 0){
          step.parallelSupplyChain2 = step.parallelSupplyChain3;
          step.parallelSupplyChain3 = [];
        }
      }else{
        step.parallelSupplyChain3 = [];
      }
    }
    if(step.parallelSupplyChain4!.length > 0){
      const index4 = step.parallelSupplyChain4.findIndex(p4 => p4.toCompanyId === this.user.companyId && this.isFootprintStep(p4.stepId));
      if(index4!==-1){
        if(step.parallelSupplyChain0.length === 0 && step.parallelSupplyChain1.length === 0 && step.parallelSupplyChain2.length === 0 && step.parallelSupplyChain3.length === 0){
          step.parallelSupplyChain0 = step.parallelSupplyChain4;
          step.parallelSupplyChain4 = [];
        }else if(step.parallelSupplyChain0.length > 0 && step.parallelSupplyChain1.length === 0 && step.parallelSupplyChain2.length === 0 && step.parallelSupplyChain3.length === 0){
          step.parallelSupplyChain1 = step.parallelSupplyChain4;
          step.parallelSupplyChain4 = [];
        }else if(step.parallelSupplyChain0.length > 0 && step.parallelSupplyChain1.length > 0 && step.parallelSupplyChain2.length === 0 && step.parallelSupplyChain3.length === 0){
          step.parallelSupplyChain2 = step.parallelSupplyChain4;
          step.parallelSupplyChain4 = [];
        }else if(step.parallelSupplyChain0.length > 0 && step.parallelSupplyChain1.length > 0 && step.parallelSupplyChain2.length > 0 && step.parallelSupplyChain3.length === 0){
          step.parallelSupplyChain3 = step.parallelSupplyChain4;
          step.parallelSupplyChain4 = [];
        }
      }else{
        step.parallelSupplyChain4 = [];
      }
    }
  }

  private getRefNumber(steps: StepReferenceModel[]): void{
    this.allFootprintSteps = steps;
    // TTD-3934 tab shown to supplier if footprint steps present
    if((this.orderDetail.toCompanyId === this.user.companyId) || (this.orderDetail.delegateCompanyID === this.user.companyId)){
      this.yarnTabIndex = 0;
      this.fabricTabIndex = 1;
      this.wetTabIndex = 2;
      this.garmentTabIndex = 3;
      this.detailTabIndex = 0;
    } else {
      if(this.allFootprintSteps.filter(s => (s.stepId === 10 || s.stepId === 25 || s.stepId === 32) && s.toCompanyId === this.user.companyId).length > 0){
        this.yarnTabIndex = 0;
        this.fabricTabIndex = 1;
        if(this.allFootprintSteps.filter(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31) && s.toCompanyId === this.user.companyId).length > 0){
          this.showWetProcess = true;
          this.wetTabIndex = 2;
          this.garmentTabIndex = 3;
        } else {
          this.showWetProcess = false;
          this.garmentTabIndex = 2;
        }
      } else if(this.allFootprintSteps.filter(s => (s.stepId === 6 || s.stepId === 7 || s.stepId === 8 || s.stepId === 31) && s.toCompanyId === this.user.companyId).length > 0){
        this.showGarment = false
        this.yarnTabIndex = 0;
        this.fabricTabIndex = 1;
        this.wetTabIndex = 2;
      } else if(this.allFootprintSteps.filter(s => (s.stepId === 4 || s.stepId === 5 || s.stepId === 23) && s.toCompanyId === this.user.companyId).length > 0){
        this.showWetProcess = false;
        this.showGarment = false;
        this.yarnTabIndex = 0;
        this.fabricTabIndex = 1;
      } else if(this.allFootprintSteps.filter(s => (s.stepId === 3 || s.stepId === 22) && s.toCompanyId === this.user.companyId).length > 0){
        this.showGarment = false;
        this.showWetProcess = false;
        this.showFabric = false;
        this.yarnTabIndex = 0;       
      }
    }
    const mcSteps = this.allFootprintSteps.filter(s => s.pcStepUniqueId === Guid.Empty && s.toCompanyId === this.user.companyId);
    const pcSteps = this.allFootprintSteps.filter(s => s.pcStepUniqueId !== Guid.Empty && s.toCompanyId === this.user.companyId);
    if(mcSteps.length > 0 && pcSteps.length > 0){
      mcSteps.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1);
      pcSteps.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1).sort((a, b)=> a.pcOfMC < b.pcOfMC ? -1 : 1).sort((a, b)=> a.pcSequence > b.pcSequence ? -1 : 1);
      if(mcSteps[0].stepId === 3 || pcSteps[0].stepId === 3 || mcSteps[0].stepId === 22 || pcSteps[0].stepId === 22){
        this.detailTabIndex = 0;
      } else if(mcSteps[0].stepId === 4 || pcSteps[0].stepId === 4 || mcSteps[0].stepId === 5 || pcSteps[0].stepId === 5 || mcSteps[0].stepId === 23 || pcSteps[0].stepId === 23){
        this.detailTabIndex = 1;
      } else if(mcSteps[0].stepId === 6 || pcSteps[0].stepId === 6 || mcSteps[0].stepId === 7 || pcSteps[0].stepId === 7 || mcSteps[0].stepId === 8 || pcSteps[0].stepId === 8 || mcSteps[0].stepId === 31 || pcSteps[0].stepId === 31){
        this.detailTabIndex = 2;
      } else if(mcSteps[0].stepId === 10 || pcSteps[0].stepId === 10 || mcSteps[0].stepId === 25 || pcSteps[0].stepId === 25 || mcSteps[0].stepId === 32 || pcSteps[0].stepId === 32){
        this.detailTabIndex = 2;
      }
    } else if(mcSteps.length > 0){
      mcSteps.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1);
      if(mcSteps[0].stepId === 3 || mcSteps[0].stepId === 22){
        this.detailTabIndex = 0;
      } else if(mcSteps[0].stepId === 4 || mcSteps[0].stepId === 5 || mcSteps[0].stepId === 23){
        this.detailTabIndex = 1;
      } else if(mcSteps[0].stepId === 6 || mcSteps[0].stepId === 7 || mcSteps[0].stepId === 8 || mcSteps[0].stepId === 31){
        this.detailTabIndex = 2;
      } else if(mcSteps[0].stepId === 10 || mcSteps[0].stepId === 25 || mcSteps[0].stepId === 32){
        this.detailTabIndex = 2;
      }
    } else if(pcSteps.length > 0){
      pcSteps.sort((a, b)=> a.mcSequence < b.mcSequence ? -1 : 1).sort((a, b)=> a.pcOfMC < b.pcOfMC ? -1 : 1).sort((a, b)=> a.pcSequence > b.pcSequence ? -1 : 1);
      if(pcSteps[0].stepId === 3 || pcSteps[0].stepId === 22){
        this.detailTabIndex = 0;
      } else if(pcSteps[0].stepId === 4 || pcSteps[0].stepId === 5 || pcSteps[0].stepId === 23){
        this.detailTabIndex = 1;
      } else if(pcSteps[0].stepId === 6 || pcSteps[0].stepId === 7 || pcSteps[0].stepId === 8 || pcSteps[0].stepId === 31){
        this.detailTabIndex = 2;
      } else if(pcSteps[0].stepId === 10 || pcSteps[0].stepId === 25 || pcSteps[0].stepId === 32){
        this.detailTabIndex = 2;
      }
    }
    this.isTabLoading = false;
   }

   //Sptint-22 TTD-4290
   private resetConfirmationText(): void{
    this.showSaveConfirmPopUp  = false;
    this.$store.commit('setFootprintConfirmation', '');
   }
   
   //TTD-4276 issue1
   private showConfirmationPopup(): void{
    this.showSaveConfirmPopUp = true;
   }
}