import { OrderLineDisplayModel } from "./orderLineDisplayModel";

export class ViewFootprintResponseModel {
    public success!: boolean;
    public orders!: OrderLineDisplayModel;
}
export class SaveFootprintDeadlineModel {
    public footprintDeadline!: string;
}

export class FootprintDeadlineResponseModel {
    public success!: boolean;
}

export class FootprintDataResponseModel {
    public success!: boolean;
    public order!: OrderLineDisplayModel;
}

export class DeleteDocRequestModel {
    public footprintID!: string;
    public footprintType!: string;
    public docID!: string;
}

export enum FootPrintConstant {
    YARN = 'YARN',
    FABRIC = 'FABRIC',
    WETPROCESS = 'WET-PROCESS',
    GARMENT = 'GARMENT',
    SUPPORTINGDOCS_WP_DYIENGT = 'WP_DYIENGT',
    SUPPORTINGDOCS_WP_PRINTT = 'WP_PRINTT',
    SUPPORTINGDOCS_WP_PRET = 'WP_PRET',
    SUPPORTINGDOCS_WP_CANDFT = 'WP_CANDF',
    SUPPORTINGDOCS_GARMENT_POST_TREATMENT = 'GARMENT_PT',
    MSDSDOCS_WP_DYIENGT = 'WP_DYIENGT_MSDS',
    MSDSDOCS_WP_PRINTT = 'WP_PRINTT_MSDS',
    MSDSDOCS_WP_CANDFT = 'WP_CANDF_MSDS',
    MSDSDOCS_WP_PRET = 'WP_PRET_MSDS',
    MSDSDOCS_GARMENT_POST_TREATMENT = 'GARMENT_PT_MSDS'
}

export enum FootPrintCompletedConstant {
    COMPLETED = 'COMPLETED',
    PARTIAL = 'PARTIAL-COMPLETED',
    INCOMPLETE = 'INCOMPLETE',
    // Sprint-22 TTD-3942 user story
    FORCECOMPLETED = 'FORCE-COMPLETED',
    FORCEINPROGRESS = 'FORCE-INPROGRESS'
}

export enum FootPrintChangedConstant {
    DEADLINE = 'DEADLINE',
    DATA = 'DATA'
}

export enum FootPrintChangedTabConstant {
    YARN_FP = 'YARN-FP',
    YARN_TP = 'YARN-TP',
    FABRIC_FP = 'FABRIC-FP',
    FABRIC_TP = 'FABRIC-TP',
    WET_FP = 'WET-FP',
    WET_TP = 'WET-TP',
    GARMENT_FP = 'GARMENT-FP',
    GARMENT_TP = 'GARMENT-TP'
}