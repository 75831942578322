import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

// Models
import { OrderLineModel } from '@/models/orderLineModel';
import { DateTimeHelper } from '@/helpers/dateTimeHelper';

@Component
export default class OrderInfo extends Vue {
  @Prop()
  private orderLine!: OrderLineModel;

  @Prop()
  private showCreateButton!: boolean;

  public constructor() {
    super();
  }

  private getETD(date: string): string {
    return DateTimeHelper.convertDateStringToDesiredFormat(
      date,
      'YYYY-MM-DD',
      'D MMM YYYY'
    );
  }

  @Emit()
  private openAddStepModal(): void {}
}
