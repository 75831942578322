import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import store from '@/store';
import enLangData from '@/locales/en.json';
import { Drag, Drop, DropList } from 'vue-easy-dnd';
import lodash from 'lodash';

// Service
import { ProductGroupService } from '@/services/productGroupService';
import { SupplyChainStepService } from '@/services/supplyChainStepService';
import { SupplierService } from '@/services/supplierService';
import { OrderService } from '@/services/orderService';

// Components
import SearchSupplier from '@/components/general/search-supplier/searchSupplier.vue';

// Helper
import { ProductGroupHelper } from '@/helpers/productGroupHelper';
import { DateTimeHelper } from '@/helpers/dateTimeHelper';
import { StepsHelper } from '@/helpers/stepsHelper';

// Models
import { DropdownModel } from '@/models/dropdownModel';
import { OrderLineModel } from '@/models/orderLineModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import { ProductGroupModel } from '@/models/productGroupModel';
import { SupplyChainStepModel } from '@/models/supplyChainStepModel';
import { Guid } from '@/models/guid';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { SupplierStepModel } from '@/models/supplierStepModel';
import { StepState } from '@/models/stepState';
import { StepHistory } from '@/models/stepHistoryModal';
import { UserModel } from '@/models/userModel';
import { OrderLineStepParallelModel } from '@/models/orderLineStepParallelModel';
import { InviteDropdownModel } from '@/models/inviteDropdownModel';
import { mapState } from 'vuex';
import { CompanyNameModel } from '@/models/companyModel';

@Component({
  components: { Drag, Drop, DropList, SearchSupplier },
  computed: mapState(['user', 'suppliers', 'companyNameList'])
})
export default class EditSupplyChain extends Vue {
  @Prop()
  private orderLineData!: OrderLineDisplayModel;

  @Prop()
  private editOrderLineSteps!: OrderLineStepModel[];

  private orderService: OrderService;

  private productGroupService: ProductGroupService;

  private supplierService: SupplierService;

  private supplyChainStepService: SupplyChainStepService;

  private orderLine: OrderLineModel = new OrderLineModel();

  private orderLineSteps: OrderLineStepModel[] = [];

  private selectedProductGroup: number = 0;

  private selectedScp: string = Guid.Empty;

  private selectedStep: number = 0;

  private selectedParallelStep: number = 0;

  private allProductGroups: ProductGroupModel[] = [];

  private deleteDisabled: boolean = true;

  private draggedStep: number = -1;

  private assignNameToOtherNewStep: boolean = false;

  private addingInProgress: boolean = false;

  private allSteps: SupplierStepModel[] = [];

  private stepsForProductGroup: SupplyChainStepModel[] = [];

  private allSupplyChainPartners: OrderCompanyModel[] = [];

  private showSearch: boolean = false;

  private isCreating: boolean = false;

  private isLoading: boolean = false;

  private stepIndex: number = -1;

  private customisedCount: number = 0;

  private companyId: string = Guid.Empty;

  private currentSupplierId: string = Guid.Empty;

  private orderRefId: string = Guid.Empty;

  private supplierChanged: boolean = false;

  // private showInfo: boolean = false;

  private stepNames: string[] = [];

  private companyStepHistory: StepHistory[] = [];

  private company: UserModel = new UserModel();

  private indexOfChain: number = -1;

  private indexOfChainStep: number = -1;

  private hasChild: string[] = [];

  private parentStepId: number = 0;

  private showMaxParallelChainModal: boolean = false;

  private parallelChain0DropdownSteps: DropdownModel[] = [];

  private parallelChain1DropdownSteps: DropdownModel[] = [];

  private parallelChain2DropdownSteps: DropdownModel[] = [];

  private parallelChain3DropdownSteps: DropdownModel[] = [];

  private parallelChain4DropdownSteps: DropdownModel[] = [];

  private isNewStepAdded: boolean = false;

  private showRemoveStepModal: boolean = false;

  private stepIdToRemove: number = -1;

  private showHover: boolean = true;

  private paddingLeft: number = 0;

  private isDragging: boolean = false;

  private suppliersLoading: boolean = false;

  private showSupplierForParallel: boolean = false;

  private showTier1: boolean = false;

  private showSupplier: boolean = false;

  private isParallelSupplyChain: boolean = false;

  private clientId: string = '';

  private defaultOrderLine: OrderLineModel = new OrderLineModel();

  private isParallel: boolean = false;

  private user!: UserModel;

  private suppliers!: OrderCompanyModel[];
  // TTD-3619
  private isShowAddRefModal: boolean = false;

  private toCompanyId: string = '';

  private chainType: string = '';

  private referenceNumber: string = '';

  private companyNameList!: CompanyNameModel[];

  private isEmptyStepName: boolean = false;

  public constructor() {
    super();
    this.orderService = new OrderService();
    this.supplierService = new SupplierService();
    this.supplyChainStepService = new SupplyChainStepService();
    this.productGroupService = new ProductGroupService();
    this.stepNames = Object.values(enLangData.enums.steps);
  }

  private async created(): Promise<void> {
    try {
      this.isLoading = true;
      this.company = this.user;
      await this.editOrderLineStep();
      await this.getSupplyChainPartners();
      await this.getSupplyChainSteps();
      if (
        this.selectedProductGroup != null &&
        this.selectedProductGroup !== 0
      ) {
        await this.getSupplyChainStepsForProductGroup(
          this.selectedProductGroup
        );
      }
      this.setChainCount();
    } finally {
      setTimeout(
        async (app: any) => {
          document
            .querySelector('.step-list')
            ?.setAttribute('id', 'step-list-custom-scroll');
          app.calculateDistance(true);
        },
        500,
        this
      );
      this.isLoading = false;
    }
  }

  private removeParallelChainOrStep(
    stepIdText: string,
    parentStepIdText: string,
    indexOfChain: number
  ) {
    const stepId = Number.parseInt(stepIdText);
    const ParentStepId = Number.parseInt(parentStepIdText);
    const step = this.getParallelChain(indexOfChain, parentStepIdText).filter(
      (c) => c.stepId == stepId
    )[0];
    if (
      (step.stepState < StepState.CREATED_BY_DELEGATEPARTNER) &&
      step.toCompanyId !== Guid.Empty && this.orderLineData.delegateCompanyID === this.user.companyId
    ) {
      return;
    } else if (
      (step.stepState < StepState.CREATED_BY_SUPPLIER || step.stepState > StepState.UPDATED_BY_SUPPLIER) &&
      step.toCompanyId !== Guid.Empty && this.orderLineData.delegateCompanyID !== this.user.companyId
    ) {
      return;
    }
    const indexOfStep = this.getParallelChain(
      indexOfChain,
      parentStepIdText
    ).findIndex((s) => s.stepId === stepId);
    const allStepsInParallel = this.getParallelChain(
      indexOfChain,
      parentStepIdText
    );
    if (allStepsInParallel.length > 1) {
      if (indexOfChain == 0) {
        this.orderLineSteps
          .filter((s) => s.stepId == ParentStepId)[0]
          .parallelSupplyChain0?.splice(indexOfStep, 1);
      } else if (indexOfChain == 1) {
        this.orderLineSteps
          .filter((s) => s.stepId == ParentStepId)[0]
          .parallelSupplyChain1?.splice(indexOfStep, 1);
      } else if (indexOfChain == 2) {
        this.orderLineSteps
          .filter((s) => s.stepId == ParentStepId)[0]
          .parallelSupplyChain2?.splice(indexOfStep, 1);
      } else if (indexOfChain == 3) {
        this.orderLineSteps
          .filter((s) => s.stepId == ParentStepId)[0]
          .parallelSupplyChain3?.splice(indexOfStep, 1);
      } else if (indexOfChain == 4) {
        this.orderLineSteps
          .filter((s) => s.stepId == ParentStepId)[0]
          .parallelSupplyChain4?.splice(indexOfStep, 1);
      }
    } else {
      switch (indexOfChain) {
        case 0:
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain0 = [];
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain0 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain1;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain1 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4 = [];
          this.setParallelChainStepDropdown(0, parentStepIdText);
          break;
        case 1:
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain1 = [];
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain1 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4 = [];
          this.setParallelChainStepDropdown(1, parentStepIdText);
          break;
        case 2:
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2 = [];
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain2 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4 = [];
          this.setParallelChainStepDropdown(2, parentStepIdText);
          break;
        case 3:
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3 = [];
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain3 = this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4;
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4 = [];
          this.setParallelChainStepDropdown(3, parentStepIdText);
          break;
        default:
          this.orderLineSteps.filter(
            (s) => s.stepId == ParentStepId
          )[0].parallelSupplyChain4 = [];
          this.setParallelChainStepDropdown(4, parentStepIdText);
          break;
      }
      this.setChainCount();
    }
  }

  private remove(stepIdText: string): void {
    const stepId = Number.parseInt(stepIdText);
    const parentStep = this.orderLineSteps.filter((o) => o.stepId === stepId);
    if (
      (parentStep[0].stepState < StepState.CREATED_BY_DELEGATEPARTNER) &&
      parentStep[0].toCompanyId !== Guid.Empty && this.orderLineData.delegateCompanyID === this.user.companyId
    ) {
      return;
    } else if (
      (parentStep[0].stepState < StepState.CREATED_BY_SUPPLIER || parentStep[0].stepState > StepState.UPDATED_BY_SUPPLIER) &&
      parentStep[0].toCompanyId !== Guid.Empty && this.orderLineData.delegateCompanyID !== this.user.companyId
    ) {
      return;
    }  else if (
      parentStep[0].parallelSupplyChain0.length > 0 &&
      parentStep[0].parallelSupplyChain0.filter((p) => p.supplierStep === false && p.toCompanyId !== Guid.Empty)
        .length > 0
    ) {
      return;
    } else if (
      parentStep[0].parallelSupplyChain1.length > 0 &&
      parentStep[0].parallelSupplyChain1.filter((p) => p.supplierStep === false && p.toCompanyId !== Guid.Empty)
        .length > 0
    ) {
      return;
    } else if (
      parentStep[0].parallelSupplyChain2.length > 0 &&
      parentStep[0].parallelSupplyChain2.filter((p) => p.supplierStep === false && p.toCompanyId !== Guid.Empty)
        .length > 0
    ) {
      return;
    } else if (
      parentStep[0].parallelSupplyChain3.length > 0 &&
      parentStep[0].parallelSupplyChain3.filter((p) => p.supplierStep === false && p.toCompanyId !== Guid.Empty)
        .length > 0
    ) {
      return;
    } else if (
      parentStep[0].parallelSupplyChain4.length > 0 &&
      parentStep[0].parallelSupplyChain4.filter((p) => p.supplierStep === false && p.toCompanyId !== Guid.Empty)
        .length > 0
    ) {
      return;
    }
    if (
      parentStep[0].parallelSupplyChain0?.length == 0 &&
      parentStep[0].parallelSupplyChain1?.length == 0 &&
      parentStep[0].parallelSupplyChain2?.length == 0 &&
      parentStep[0].parallelSupplyChain3?.length == 0 &&
      parentStep[0].parallelSupplyChain4?.length == 0
    ) {
      this.stepIdToRemove = stepId;
      this.removeChain();
    } else {
      this.stepIdToRemove = stepId;
      this.showRemoveStepModal = true;
    }
  }

  private removeChain(): void {
    const indexOfStep = this.orderLineSteps.findIndex(
      (s) => s.stepId == this.stepIdToRemove
    );
    this.orderLineSteps.splice(indexOfStep, 1);
    this.setChainCount();
    this.stepIdToRemove = -1;
    this.showRemoveStepModal = false;
  }

  private closeModal(stepIdText: string): void {
    this.stepIdToRemove = -1;
    this.showRemoveStepModal = false;
  }

  private showleftRightIcon(parentStepId: string, index: number): boolean {
    let show = false;
    const parentIndex = this.orderLineSteps.findIndex(
      (s) => s.stepId === Number(parentStepId)
    );
    switch (index) {
      case 0: {
        show =
          this.orderLineSteps[parentIndex].parallelSupplyChain0.length > 1
            ? true
            : false;
        break;
      }
      case 1: {
        show =
          this.orderLineSteps[parentIndex].parallelSupplyChain1.length > 1
            ? true
            : false;
        break;
      }
      case 2: {
        show =
          this.orderLineSteps[parentIndex].parallelSupplyChain2.length > 1
            ? true
            : false;
        break;
      }
      case 3: {
        show =
          this.orderLineSteps[parentIndex].parallelSupplyChain3.length > 1
            ? true
            : false;
        break;
      }
      case 4: {
        show =
          this.orderLineSteps[parentIndex].parallelSupplyChain4.length > 1
            ? true
            : false;
        break;
      }
    }
    return show;
  }

  private async getOrderLineSteps(): Promise<void> {
    if (this.orderLineSteps.length > 0) {
      this.clientId = this.orderLineSteps[0].fromCompanyId;
    }
  }

  private getParallelChain(
    index: number,
    parentStepId: string
  ): OrderLineStepParallelModel[] {
    const parentId =
      parentStepId !== null ? Number.parseInt(parentStepId) : null;
    let parallelChain: OrderLineStepParallelModel[] = [];
    if (index == 0) {
      parallelChain = this.orderLineSteps.filter(
        (o) => o.stepId === parentId
      )[0].parallelSupplyChain0!;
    } else if (index == 1) {
      parallelChain = this.orderLineSteps.filter(
        (o) => o.stepId === parentId
      )[0].parallelSupplyChain1!;
    } else if (index == 2) {
      parallelChain = this.orderLineSteps.filter(
        (o) => o.stepId === parentId
      )[0].parallelSupplyChain2!;
    } else if (index == 3) {
      parallelChain = this.orderLineSteps.filter(
        (o) => o.stepId === parentId
      )[0].parallelSupplyChain3!;
    } else {
      parallelChain = this.orderLineSteps.filter(
        (o) => o.stepId === parentId
      )[0].parallelSupplyChain4!;
    }
    return parallelChain;
  }

  private addChain(stepIdText: string): void {
    let count = 0;
    this.orderLineSteps.forEach((step) => {
      if (step.parallelSupplyChain0 && step.parallelSupplyChain0.length > 0) {
        count = count + 1;
      }
      if (step.parallelSupplyChain1 && step.parallelSupplyChain1.length > 0) {
        count = count + 1;
      }
      if (step.parallelSupplyChain2 && step.parallelSupplyChain2.length > 0) {
        count = count + 1;
      }
      if (step.parallelSupplyChain3 && step.parallelSupplyChain3.length > 0) {
        count = count + 1;
      }
      if (step.parallelSupplyChain4 && step.parallelSupplyChain4.length > 0) {
        count = count + 1;
      }
      if (count >= 5) {
        return;
      }
    });
    if (count >= 5) {
      this.showMaxParallelChainModal = true;
      return;
    }
    const stepId = Number.parseInt(stepIdText);
    let cloned = lodash.cloneDeep(this.orderLineSteps);
    cloned = cloned.filter(
      (s) => s.stepId == stepId
    );
    const newStep: OrderLineStepParallelModel = new OrderLineStepParallelModel(
      this.orderLine,
      {
        stepId: 0,
        displayName: '',
        sequence: 1,
        productGroup: this.selectedProductGroup,
      },
      stepIdText
    );
    newStep.supplierStep = true;
    if (cloned[0].parallelSupplyChain0?.length === 0) {
      cloned[0].parallelSupplyChain0 = [newStep];
      this.setParallelChainStepDropdown(0, stepIdText);
    } else if (cloned[0].parallelSupplyChain1?.length === 0) {
      cloned[0].parallelSupplyChain1 = [newStep];
      this.setParallelChainStepDropdown(1, stepIdText);
    } else if (cloned[0].parallelSupplyChain2?.length === 0) {
      cloned[0].parallelSupplyChain2 = [newStep];
      this.setParallelChainStepDropdown(2, stepIdText);
    } else if (cloned[0].parallelSupplyChain3?.length === 0) {
      cloned[0].parallelSupplyChain3 = [newStep];
      this.setParallelChainStepDropdown(3, stepIdText);
    } else if (cloned[0].parallelSupplyChain4?.length === 0) {
      cloned[0].parallelSupplyChain4 = [newStep];
      this.setParallelChainStepDropdown(4, stepIdText);
    } else {
      this.showMaxParallelChainModal = true;
    }
    const index = this.orderLineSteps.findIndex(s => s.stepId === cloned[0].stepId);
    this.orderLineSteps.splice(index, 1);
    this.orderLineSteps.splice(index, 0, cloned[0]);
    this.setChainCount();
    this.calculateDistance(false);
    this.isNewStepAdded = true;
  }

  private calculateDistance(onRender: boolean): void {
    setTimeout(
      async (app: any) => {
        const leftPoint = document
          .getElementById('step-list-custom-scroll')!
          .getBoundingClientRect();
        if (
          document.getElementById('leftest-leftplus') &&
          document.getElementById('leftest-leftplus')!.getBoundingClientRect()
            .x < leftPoint.x
        ) {
          const padding = app.paddingLeft + 200;
          app.paddingLeft = padding;
          const outerElement = document.getElementById(
            'step-list-custom-scroll'
          );
          outerElement!.style.paddingLeft = padding + 'px';
        } else if (
          document.getElementById('leftest-add') &&
          document.getElementById('leftest-add')!.getBoundingClientRect().x <
            leftPoint.x
        ) {
          const padding = app.paddingLeft + 210;
          app.paddingLeft = padding;
          const outerElement = document.getElementById(
            'step-list-custom-scroll'
          );
          outerElement!.style.paddingLeft = padding + 'px';
        }
      },
      250,
      this
    );
  }

  private setParallelChainStepDropdown(
    indexOfChain: number,
    parentStepIdText: string
  ): void {
    let parentStepIndex = 0;
    switch (indexOfChain) {
      case 0:
        this.parallelChain0DropdownSteps = [];
        parentStepIndex = this.orderLineSteps.findIndex(
          (s) => s.stepId === Number.parseInt(parentStepIdText)
        );
        this.allSteps.forEach((step) => {
          const index = this.orderLineSteps[
            parentStepIndex
          ].parallelSupplyChain0?.findIndex((sp) => sp.stepId === step.stepId);
          if (index === -1) {
            this.parallelChain0DropdownSteps.push(
              new DropdownModel(`${step.stepId}`, step.displayName)
            );
          }
        });
        break;
      case 1:
        this.parallelChain1DropdownSteps = [];
        parentStepIndex = this.orderLineSteps.findIndex(
          (s) => s.stepId === Number.parseInt(parentStepIdText)
        );
        this.allSteps.forEach((step) => {
          const index = this.orderLineSteps[
            parentStepIndex
          ].parallelSupplyChain1?.findIndex((sp) => sp.stepId === step.stepId);
          if (index === -1) {
            this.parallelChain1DropdownSteps.push(
              new DropdownModel(`${step.stepId}`, step.displayName)
            );
          }
        });
        break;
      case 2:
        this.parallelChain2DropdownSteps = [];
        parentStepIndex = this.orderLineSteps.findIndex(
          (s) => s.stepId === Number.parseInt(parentStepIdText)
        );
        this.allSteps.forEach((step) => {
          const index = this.orderLineSteps[
            parentStepIndex
          ].parallelSupplyChain2?.findIndex((sp) => sp.stepId === step.stepId);
          if (index === -1) {
            this.parallelChain2DropdownSteps.push(
              new DropdownModel(`${step.stepId}`, step.displayName)
            );
          }
        });
        break;
      case 3:
        this.parallelChain3DropdownSteps = [];
        parentStepIndex = this.orderLineSteps.findIndex(
          (s) => s.stepId === Number.parseInt(parentStepIdText)
        );
        this.allSteps.forEach((step) => {
          const index = this.orderLineSteps[
            parentStepIndex
          ].parallelSupplyChain3?.findIndex((sp) => sp.stepId === step.stepId);
          if (index === -1) {
            this.parallelChain3DropdownSteps.push(
              new DropdownModel(`${step.stepId}`, step.displayName)
            );
          }
        });
        break;
      case 4:
        this.parallelChain4DropdownSteps = [];
        parentStepIndex = this.orderLineSteps.findIndex(
          (s) => s.stepId === Number.parseInt(parentStepIdText)
        );
        this.allSteps.forEach((step) => {
          const index = this.orderLineSteps[
            parentStepIndex
          ].parallelSupplyChain4?.findIndex((sp) => sp.stepId === step.stepId);
          if (index === -1) {
            this.parallelChain4DropdownSteps.push(
              new DropdownModel(`${step.stepId}`, step.displayName)
            );
          }
        });
        break;
    }
  }

  private get createDisabled(): boolean {
    if (this.orderLineSteps.length > 0 && this.selectedStep === 0) {
      this.selectedStep = this.orderLineSteps[0].stepId;
      this.stepChanged();
    }
    this.isEmptyStepName = false; // TTD-4752
    let isDisable = false;
    isDisable =
      this.selectedProductGroup == null ||
      this.selectedProductGroup === 0 ||
      this.orderLineSteps.some((o) => o.toCompanyId === Guid.Empty);
    if(this.orderLineSteps.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
      this.isEmptyStepName = true;
    }
    if (!isDisable) {
      this.orderLineSteps.forEach((o) => {
        if (o.parallelSupplyChain0.length > 0 && !isDisable) {
          isDisable = o.parallelSupplyChain0.some(
            (o) => o.toCompanyId === Guid.Empty
          );
          if(o.parallelSupplyChain0.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
            this.isEmptyStepName = true;
          }
        }
        if (o.parallelSupplyChain1.length > 0 && !isDisable) {
          isDisable = o.parallelSupplyChain1.some(
            (o) => o.toCompanyId === Guid.Empty
          );
          if(o.parallelSupplyChain1.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
            this.isEmptyStepName = true;
          }
        }
        if (o.parallelSupplyChain2.length > 0 && !isDisable) {
          isDisable = o.parallelSupplyChain2.some(
            (o) => o.toCompanyId === Guid.Empty
          );
          if(o.parallelSupplyChain2.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
            this.isEmptyStepName = true;
          }
        }
        if (o.parallelSupplyChain3.length > 0 && !isDisable) {
          isDisable = o.parallelSupplyChain3.some(
            (o) => o.toCompanyId === Guid.Empty
          );
          if(o.parallelSupplyChain3.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
            this.isEmptyStepName = true;
          }
        }
        if (o.parallelSupplyChain4.length > 0 && !isDisable) {
          isDisable = o.parallelSupplyChain4.some(
            (o) => o.toCompanyId === Guid.Empty
          );
          if(o.parallelSupplyChain4.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null)){
            this.isEmptyStepName = true;
          }
        }
      });
    }
    return isDisable;
  }

  private get isDeleteDisabled(): boolean {
    return this.deleteDisabled;
  }

  private get assignDisabled(): boolean {
    return this.selectedProductGroup == null || this.selectedProductGroup === 0;
  }

  private get assignScpDisabled(): boolean {
    return (
      this.selectedProductGroup == null ||
      this.selectedProductGroup === 0 ||
      this.selectedStep <= 0
    );
  }

  private get steps(): DropdownModel[] {
    return this.orderLineSteps
      .filter((s) => s.stepId !== 0)
      .map((s) => ({ value: `${s.stepId}`, text: s.displayName }));
  }

  private get supplierCompanyId(): string {
    return this.currentSupplierId;
  }

  private get dropdownSteps(): DropdownModel[] {
    const result: DropdownModel[] = [];
    this.allSteps.forEach((step) => {
      const index = this.orderLineSteps.findIndex(
        (sp) => sp.stepId === step.stepId
      );
      if (index === -1) {
        result.push(new DropdownModel(`${step.stepId}`, step.displayName));
      }
    });
    return result;
  }

  private get supplyChainPartners(): InviteDropdownModel[] {
    return this.allSupplyChainPartners.map((s) => ({
      value: s.id,
      text: s.isCompliant
        ? s.id === this.user.companyId
          ? `${s.companyName} (me)`
          : `${s.companyName} (${s.country})`
        : `${s.companyName} (${this.$t('pages.home.pending_invite')})`,
        selected: false
    }));
  }

  private get emptyGuid(): string {
    return Guid.Empty;
  }

  // Display step name based on the country language
  private displayName(stepId: number): string {
    return this.$t(`enums.steps.${stepId}`).toString();
  }

  private get selectedScpName(): string {
    if (this.selectedScp == null || this.selectedScp === Guid.Empty) {
      return '';
    }
    return this.supplyChainPartners.filter(
      (s) => s.value === this.selectedScp
    )[0] !== undefined
      ? this.supplyChainPartners.filter((s) => s.value === this.selectedScp)[0]
          .text
      : '';
  }

  private get clientCompanyId(): string {
    return this.clientId;
  }

  private get getGuid(): string {
    return Guid.Empty;
  }

  private setChainCount(): void {
    let count = 0;
    this.isParallelSupplyChain = false;
    this.orderLineSteps.forEach((o) => {
      o.parallelChainCount = count;
      if (o.parallelSupplyChain0!.length > 0) {
        count++;
        this.isParallelSupplyChain = true;
      }
      if (o.parallelSupplyChain1!.length > 0) {
        count++;
        this.isParallelSupplyChain = true;
      }
      if (o.parallelSupplyChain2!.length > 0) {
        count++;
        this.isParallelSupplyChain = true;
      }
      if (o.parallelSupplyChain3!.length > 0) {
        count++;
        this.isParallelSupplyChain = true;
      }
      if (o.parallelSupplyChain4!.length > 0) {
        count++;
        this.isParallelSupplyChain = true;
      }
    });
  }

  private isLastStep(stepId: string): boolean {
    const index = this.orderLineSteps.findIndex(
      (s) => s.stepId === Number.parseInt(stepId)
    );
    if (this.orderLineSteps.length === index + 1) {
      return true;
    }
    return false;
  }

  private async getSupplyChainPartners(): Promise<void> {
    if (
      this.suppliers != null &&
      this.suppliers.length > 0 &&
      !this.supplierChanged
    ) {
      this.allSupplyChainPartners = this.suppliers;
    } else {
      this.suppliersLoading = true;
      this.allSupplyChainPartners =
        await this.supplierService.getSuppliersListAsync(this.user.companyId);
      this.suppliersLoading = false;
    }
  }

  private async getSupplyChainSteps(): Promise<void> {
    this.allSteps = StepsHelper.getStepsByProductGroup(
      this.selectedProductGroup
    );
  }

  // Edit OrderLine Steps
  private async editOrderLineStep(): Promise<void> {
    this.orderLine.orderId = this.orderLineData.orderId;
    this.orderLine.lineID = this.orderLineData.id;
    this.orderLine.orderNumber = this.orderLineData.orderNumber;
    this.orderLine.styleName = this.orderLineData.styleName;
    this.orderLine.colourway =
      this.orderLineData.orderLines[0].colourway == undefined ||
      this.orderLineData.orderLines[0].colourway.trim() == ''
        ? '-'
        : this.orderLineData.orderLines[0].colourway;
    this.orderLine.styleNumber = this.orderLineData.styleNumber;
    this.orderLine.companyName = this.orderLineData.fromCompanyName;
    this.selectedProductGroup = this.editOrderLineSteps[0].productGroup;
    this.companyId = this.orderLineData.toCompanyId;
    this.orderRefId = this.orderLineData.orderRefId;
    this.orderLine.expectedTimeOfDelivery =
      DateTimeHelper.convertDateDesiredFormat(
        this.orderLineData.expectedTimeOfDelivery,
        'D MMM YYYY'
      );
    this.orderLine.delegateCompanyUser = this.orderLineData.delegateCompanyUser;
    this.orderLine.delegateCompanyID = this.orderLineData.delegateCompanyID;
    this.editOrderLineSteps.map(
      (o) =>
        (o.supplierStep =
          o.stepState == 1 || o.stepState == 2 || o.stepState == 3 || o.stepState == 7 || o.stepState == 8
            ? false
            : true)
    );
  }

  // TTD-2630 Sprint 18
  private showDelegateInfoTOCPOnly(order: OrderLineModel): boolean {
    const user = this.$store.getters.user as UserModel;
    if (order.delegateCompanyID !== Guid.Empty && order.delegateCompanyID !== user.companyId) {
      return true;
    }
    return false;
  }

  private async getSupplyChainStepsForProductGroup(
    productGroup: number
  ): Promise<void> {
    this.stepsForProductGroup = this.allSteps;
    this.orderLineSteps = [];
      this.orderLineSteps = this.editOrderLineSteps.filter(
        (s) =>
          s.stepState !== StepState.DELETED_BY_CLIENT &&
          s.stepState !== StepState.DELETED_BY_SUPPLIER &&
          s.stepState !== StepState.DELETED_BY_AGENT &&
          s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER &&
          s.stepState !== StepState.NONE
      );
      this.orderLineSteps.forEach((s) => {
        s.toCompanyName = this.companyNameList.filter(c => c.ID === s.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === s.toCompanyId)[0].name : s.toCompanyName;
        if (s.parallelSupplyChain0.length > 0) {
          s.parallelSupplyChain0.forEach((p) => {
            if (p.toCompanyId != Guid.Empty && p.toCompanyName != '' && (p.stepState === 1 || p.stepState === 2 || p.stepState === 3 || p.stepState === 7 || p.stepState === 8)) {
              p.toCompanyName = this.companyNameList.filter(c => c.ID === p.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === p.toCompanyId)[0].name : p.toCompanyName;
              p.supplierStep = false;
            } else {
              p.supplierStep = true;
            }
            p.parentStepId = s.stepId.toString();
          });
        }
        if (s.parallelSupplyChain1.length > 0) {
          s.parallelSupplyChain1.forEach((p) => {
            if (p.toCompanyId != Guid.Empty && p.toCompanyName != '' && (p.stepState === 1 || p.stepState === 2 || p.stepState === 3 || p.stepState === 7 || p.stepState === 8)) {
              p.toCompanyName = this.companyNameList.filter(c => c.ID === p.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === p.toCompanyId)[0].name : p.toCompanyName;
              p.supplierStep = false;
            } else {
              p.supplierStep = true;
            }
            p.parentStepId = s.stepId.toString();
          });
        }
        if (s.parallelSupplyChain2.length > 0) {
          s.parallelSupplyChain2.forEach((p) => {
            if (p.toCompanyId != Guid.Empty && p.toCompanyName != '' && (p.stepState === 1 || p.stepState === 2 || p.stepState === 3 || p.stepState === 7 || p.stepState === 8)) {
              p.toCompanyName = this.companyNameList.filter(c => c.ID === p.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === p.toCompanyId)[0].name : p.toCompanyName;
              p.supplierStep = false;
            } else {
              p.supplierStep = true;
            }
            p.parentStepId = s.stepId.toString();
          });
        }
        if (s.parallelSupplyChain3.length > 0) {
          s.parallelSupplyChain3.forEach((p) => {
            if (p.toCompanyId != Guid.Empty && p.toCompanyName != '' && (p.stepState === 1 || p.stepState === 2 || p.stepState === 3 || p.stepState === 7 || p.stepState === 8)) {
              p.toCompanyName = this.companyNameList.filter(c => c.ID === p.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === p.toCompanyId)[0].name : p.toCompanyName;
              p.supplierStep = false;
            } else {
              p.supplierStep = true;
            }
            p.parentStepId = s.stepId.toString();
          });
        }
        if (s.parallelSupplyChain4.length > 0) {
          s.parallelSupplyChain4.forEach((p) => {
            if (p.toCompanyId != Guid.Empty && p.toCompanyName != '' && (p.stepState === 1 || p.stepState === 2 || p.stepState === 3 || p.stepState === 7 || p.stepState === 8)) {
              p.toCompanyName = this.companyNameList.filter(c => c.ID === p.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === p.toCompanyId)[0].name : p.toCompanyName;
              p.supplierStep = false;
            } else {
              p.supplierStep = true;
            }
            p.parentStepId = s.stepId.toString();
          });
        }
      });
      this.orderLineSteps.sort((step1, step2) => {
        return step1.sequence - step2.sequence;
      });
  }

  private changeStyleForParallel(
    stepId: number,
    parentStepId: number,
    indexOfChain: number
  ): void {
    this.isParallel = true;
    if (
      this.getParallelChain(indexOfChain, parentStepId.toString()).filter(
        (c) => c.stepId == stepId
      )[0].isSelected === true
    ) {
      this.getParallelChain(indexOfChain, parentStepId.toString()).filter(
        (c) => c.stepId == stepId
      )[0].isSelected = false;
    } else {
      this.getParallelChain(indexOfChain, parentStepId.toString()).filter(
        (c) => c.stepId == stepId
      )[0].isSelected = true;
    }
    const step = this.getParallelChain(
      indexOfChain,
      parentStepId.toString()
    ).filter((c) => c.stepId == stepId)[0];
    const index = this.getParallelChain(
      indexOfChain,
      parentStepId.toString()
    ).findIndex((s) => s.stepId == stepId);
    this.getParallelChain(indexOfChain, parentStepId.toString()).splice(
      index,
      1
    );
    this.getParallelChain(indexOfChain, parentStepId.toString()).splice(
      index,
      0,
      step
    );
  }

  private changeStyle(stepId: string): void {
    this.isParallel = false;
    if (
      this.orderLineSteps.filter((s) => s.stepId === Number.parseInt(stepId))[0]
        .isSelected === true
    ) {
      this.orderLineSteps.filter(
        (s) => s.stepId === Number.parseInt(stepId)
      )[0].isSelected = false;
    } else {
      this.orderLineSteps.filter(
        (s) => s.stepId === Number.parseInt(stepId)
      )[0].isSelected = true;
    }
    const step = this.orderLineSteps.filter(
      (s) => s.stepId === Number.parseInt(stepId)
    )[0];
    const index = this.orderLineSteps.findIndex(
      (s) => s.stepId == Number.parseInt(stepId)
    );
    this.orderLineSteps.splice(index, 1);
    this.orderLineSteps.splice(index, 0, step);
  }

  private getStatusForParallelStep(
    stepId: number,
    parentStepId: string,
    indexOfChain: number
  ): string {
    const parentId =
      parentStepId !== null ? Number.parseInt(parentStepId) : null;
    const supplierAssigned =
      this.getParallelChain(indexOfChain, parentStepId).filter(
        (c) => c.stepId == stepId
      )[0].toCompanyId !== Guid.Empty;
    return supplierAssigned ? 'green' : 'red';
  }

  private async getProductGroups(): Promise<void> {
    this.allProductGroups = ProductGroupHelper.getProductGroups();
  }

  private getStatusForStep(stepId: number): string {
    const supplierAssigned =
      this.orderLineSteps.filter((o) => o.stepId === stepId)[0].toCompanyId !==
      Guid.Empty;
    return supplierAssigned ? 'green' : 'red';
  }

  private setName(stepIdText: string): void {
    const stepId = Number.parseInt(stepIdText);
    const item = this.allSteps.filter((s) => s.stepId === stepId)[0];
    this.orderLineSteps.filter((sp) => sp.stepId === stepId)[0].displayName =
      item.displayName;
    this.assignNameToOtherNewStep = false;
    this.addingInProgress = false;
  }

  private setNameForParallel(
    stepIdText: string,
    indexOfChain: number,
    parentStepId: string
  ): void {
    const stepId = Number.parseInt(stepIdText);
    const parentId = parentStepId !== null ? Number.parseInt(parentStepId) : 0;
    const item = this.allSteps.filter((s) => s.stepId === stepId)[0];
    this.getParallelChain(indexOfChain, parentStepId).filter(
      (c) => c.stepId === stepId
    )[0].displayName = item.displayName;
    this.isNewStepAdded = false;
  }

  private enableDelete(event: any): void {
    this.isDragging = true;
    if(event.data !== null && event.data !== undefined) {
      const step = event.data as SupplyChainStepModel;
      this.draggedStep = step.stepId;
    }
    this.deleteDisabled = false;
    this.showHover = false;
  }

  private disableDelete(): void {
    this.deleteDisabled = true;
    this.draggedStep = -1;
    this.setChainCount();
    this.showHover = true;
    this.calculateDistance(false);
  }

  private add(stepIdText: string): void {
    const stepId = Number.parseInt(stepIdText);
    const index = this.orderLineSteps.findIndex((s) => s.stepId === stepId);
    this.addAtIndex(index + 1);
  }

  private addAtIndex(index: number): void {
    if (this.orderLineSteps.some((a) => a.stepId === 0)) {
      this.assignNameToOtherNewStep = true;
      return;
    } else if (this.dropdownSteps.length <= 1) {
      return;
    }
    this.addingInProgress = true;
    const newStep: OrderLineStepModel = new OrderLineStepModel(this.orderLine, {
      stepId: 0,
      displayName: '',
      sequence: index,
      productGroup: this.selectedProductGroup,
    });
    newStep.supplierStep = true;
    this.orderLineSteps.splice(index, 0, newStep);
  }

  private addInParallel(
    stepIdText: string,
    parentStepIdText: string,
    indexOfChain: number
  ): void {
    if (!this.isNewStepAdded) {
      const stepId = Number.parseInt(stepIdText);
      const ParentStepId = Number.parseInt(parentStepIdText);
      const index = this.getParallelChain(
        indexOfChain,
        parentStepIdText
      ).findIndex((s) => s.stepId === stepId);
      const sequence = this.getParallelChain(indexOfChain, parentStepIdText).filter(i => i.stepId === stepId)[0].sequence;
      if (sequence < this.getParallelChain(indexOfChain, parentStepIdText).length) {
        this.getParallelChain(indexOfChain, parentStepIdText).filter(p => p.sequence > sequence).forEach(c => {
          c.sequence = c.sequence+1;
        })
      }
      if (stepIdText !== null) {
        const stepId = Number.parseInt(stepIdText);
        const item = this.allSteps.filter((s) => s.stepId === stepId)[0];
        const ParentStepId = Number.parseInt(parentStepIdText);
        const newStep: OrderLineStepParallelModel =
          new OrderLineStepParallelModel(
            this.orderLine,
            {
              stepId: 0,
              displayName: '',
              sequence: sequence + 1,
              productGroup: this.selectedProductGroup,
            },
            parentStepIdText
          );
        newStep.supplierStep = true;
        this.getParallelChain(indexOfChain, parentStepIdText).splice(
          index,
          0,
          newStep
        );
        this.setParallelChainStepDropdown(indexOfChain, parentStepIdText);
        this.isNewStepAdded = true;
        this.calculateDistance(false);
      }
    }
  }

  private addInParallelAtIndex(
    index: number,
    stepIdText: string | null,
    parentStepIdText: string,
    indexOfChain: number
  ): void {
    if (!this.isNewStepAdded) {
      if (stepIdText !== null) {
        const stepId = Number.parseInt(stepIdText);
        const item = this.allSteps.filter((s) => s.stepId === stepId)[0];
        const ParentStepId = Number.parseInt(parentStepIdText);
        const newStep: OrderLineStepParallelModel =
          new OrderLineStepParallelModel(
            this.orderLine,
            {
              stepId: 0,
              displayName: '',
              sequence: 1,
              productGroup: this.selectedProductGroup,
            },
            parentStepIdText
          );
        newStep.supplierStep = true;
        this.getParallelChain(indexOfChain, parentStepIdText).forEach(p => {
          p.sequence = p.sequence+1;
        })
        this.getParallelChain(indexOfChain, parentStepIdText).splice(
          index + 1,
          0,
          newStep
        );
        this.setParallelChainStepDropdown(indexOfChain, parentStepIdText);
        this.isNewStepAdded = true;
        this.calculateDistance(false);
      }
    }
  }

  private openSearchSelectHandlerParallel(
    stepId: number,
    parentStepId: number,
    indexOfChain: number,
    toCompanyId: string
  ): void {
    this.toCompanyId = toCompanyId;
    if (stepId !== -1) {
      const index = this.getParallelChain(
        indexOfChain,
        parentStepId.toString()
      ).findIndex((t) => t.stepId === stepId);
      const steps = this.getParallelChain(
        indexOfChain,
        parentStepId.toString()
      );
      if ((steps[index].stepState < StepState.CREATED_BY_SUPPLIER || steps[index]!.stepState > StepState.UPDATED_BY_SUPPLIER) && steps[index].toCompanyId !== Guid.Empty && this.user.companyId !== this.orderLineData.delegateCompanyID) {
        return;
      }
      if ((steps[index].stepState===StepState.CREATED_BY_SUPPLIER || steps[index].stepState===StepState.UPDATED_BY_SUPPLIER || steps[index].stepState===StepState.CREATED_BY_CLIENT || steps[index].stepState===StepState.UPDATED_BY_CLIENT || steps[index].stepState===StepState.CREATED_BY_AGENT || steps[index].stepState===StepState.UPDATED_BY_AGENT || steps[index].stepState===0) && steps[index].toCompanyId !== Guid.Empty && this.user.companyId === this.orderLineData.delegateCompanyID) {
        return;
      }
      this.indexOfChain = indexOfChain;
      this.indexOfChainStep = index;
      this.selectedParallelStep = stepId;
      this.parentStepId = parentStepId;
      this.showSearch = true;
      this.showSupplierForParallel = true;
      if(this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].footprintRefNo !==''){
        this.referenceNumber = this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
          this.indexOfChainStep
        ].footprintRefNo
      }
      this.changeStyleForParallel(stepId, parentStepId, indexOfChain);
      return;
    }
    this.showTier1 = true;
  }

  private setSupplierHandlerParallel(value: string, refNum: string): void {
    if (
      this.orderLineSteps.filter((s) => s.stepId == this.parentStepId)[0]
        .toCompanyName === ''
    ) {
      this.customisedCount++;
    }
    const selectSCP = this.allSupplyChainPartners.filter(
      (s) => s.id === value
    )[0];
    if(this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].id === undefined || this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].id === "" || this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].id === Guid.Empty) {
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].id = uuidv4();
    }
    this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].fromCompanyId = this.company.companyId;
    this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].toCompanyName = selectSCP.companyName;
    this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
      this.indexOfChainStep
    ].toCompanyId = selectSCP.id;
    if (this.orderLineData.delegateCompanyID === this.user.companyId) {
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].stepState = StepState.UPDATED_BY_DELEGATEPARTNER;
    } else {
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].stepState = StepState.UPDATED_BY_SUPPLIER;
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].supplierStep = true;
    }
    //TTD-3619
    const user = store.getters.user as UserModel;
    if(this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].footprintRefNo!==refNum){
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].footprintRefNo = refNum;
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].footprintRefNoUpdatedBy = user.companyId;
      if(this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].footprintRefID === Guid.Empty){
        this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].footprintRefID = uuidv4();
      }
    }
    this.showTier1 = false;
    this.showSearch = false;
    this.showSupplierForParallel = false;
    this.changeStyleForParallel(
      this.selectedParallelStep,
      this.parentStepId,
      this.indexOfChain
    );
  }

  private closeSearchHandlerParallel(): void {
    this.showSupplierForParallel = false;
    this.showSupplier = false;
    this.changeStyleForParallel(
      this.selectedParallelStep,
      this.parentStepId,
      this.indexOfChain
    );
  }

  private setInvalid(): void {
    this.assignNameToOtherNewStep = true;
  }

  private deleteStep(event: any): void {
    if (this.orderLineSteps.length <= 1 || this.deleteDisabled) {
      return;
    }
    const step = event.data as OrderLineStepModel;
    if (
      step.stepState ===
      (StepState.CREATED_BY_CLIENT || StepState.UPDATED_BY_CLIENT)
    ) {
      return;
    }
    this.selectedScp = Guid.Empty;
    this.selectedStep = 0;
    const index = this.orderLineSteps.findIndex(
      (s) => s.stepId === step.stepId
    );
    this.orderLineSteps.splice(index, 1);
  }

  private stepChanged(): void {
    if (this.selectedStep <= 0) {
      return;
    }
    const orderLineStep = this.orderLineSteps.filter(
      (o) => o.stepId === this.selectedStep
    )[0];
    this.selectedScp = orderLineStep.toCompanyId;
  }

  private setSupplierIdForStep(scpId: string): void {
    if (this.selectedStep <= 0 || this.selectedScp === Guid.Empty) {
      return;
    }
    const orderLineStep = this.orderLineSteps.filter(
      (o) => o.stepId === this.selectedStep
    )[0];
    orderLineStep.toCompanyId = scpId;
  }

  private swipeLeft(): void {
    const contentToScroll = this.$refs.scrollContent as Vue;
    contentToScroll.$el.scrollLeft -= 200;
  }

  private swipeRight(): void {
    const contentToScroll = this.$refs.scrollContent as Vue;
    contentToScroll.$el.scrollLeft += 200;
  }

  private closeSearchHandler(): void {
    this.showSearch = false;
    if (!this.isParallel) {
      this.changeStyle(this.selectedStep.toString());
    }
  }

  private showBlockUpdate(step: OrderLineStepModel): boolean {
    if ((step.stepState===StepState.CREATED_BY_SUPPLIER || step.stepState===StepState.UPDATED_BY_SUPPLIER || step.stepState===StepState.CREATED_BY_CLIENT || step.stepState===StepState.UPDATED_BY_CLIENT || step.stepState===StepState.CREATED_BY_AGENT || step.stepState===StepState.UPDATED_BY_AGENT || step.stepState===0) && step.toCompanyId !== Guid.Empty && this.user.companyId === this.orderLineData.delegateCompanyID) {
      return true;
    }
    if ((step.stepState===StepState.CREATED_BY_DELEGATEPARTNER || step.stepState===StepState.UPDATED_BY_DELEGATEPARTNER || step.stepState===StepState.CREATED_BY_CLIENT || step.stepState===StepState.UPDATED_BY_CLIENT || step.stepState===StepState.CREATED_BY_AGENT || step.stepState===StepState.UPDATED_BY_AGENT || step.stepState===0) && step.toCompanyId !== Guid.Empty && this.user.companyId !== this.orderLineData.delegateCompanyID) {
      return true;
    }
    return false;
  }

  private openSearchSelectHandler(stepId: number, toCompanyId:string): void {
    this.toCompanyId = toCompanyId;
    const step = this.orderLineSteps.find((s) => s.stepId === stepId);
    if ((step!.stepState < StepState.CREATED_BY_SUPPLIER || step!.stepState > StepState.UPDATED_BY_SUPPLIER) && step?.toCompanyId !== Guid.Empty && this.user.companyId !== this.orderLineData.delegateCompanyID) {
      return;
    }
    if ((step?.stepState===StepState.CREATED_BY_SUPPLIER || step?.stepState===StepState.UPDATED_BY_SUPPLIER || step?.stepState===StepState.CREATED_BY_CLIENT || step?.stepState===StepState.UPDATED_BY_CLIENT || step?.stepState===StepState.CREATED_BY_AGENT || step?.stepState===StepState.UPDATED_BY_AGENT || step?.stepState===0) && step?.toCompanyId !== Guid.Empty && this.user.companyId === this.orderLineData.delegateCompanyID) {
      return;
    }
    this.selectedStep = stepId;
    this.changeStyle(this.selectedStep.toString());
    const index = this.orderLineSteps.findIndex((s) => s.stepId === stepId);
    this.stepIndex = index;
    if(this.orderLineSteps[index].footprintRefNo!==''){
      this.referenceNumber = this.orderLineSteps[index].footprintRefNo;
    }
    if (this.assignScpDisabled) {
      return;
    }
    this.showSearch = true;
  }

  private async invitationSendHandler(): Promise<void> {
    this.supplierChanged = true;
    await this.getSupplyChainPartners();
  }

  private setSupplierHandler(value: string, refNum: string): void {
    const selectSCP = this.allSupplyChainPartners.filter(
      (s) => s.id === value
    )[0];
    const history: StepHistory = {};
    history.stepId = this.orderLineSteps[this.stepIndex].stepId;
    history.createdByCompanyId =
      this.orderLineSteps[this.stepIndex].fromCompanyId === Guid.Empty
        ? this.company.companyId
        : this.orderLineSteps[this.stepIndex].fromCompanyId;
    history.createdByCompanyName =
      this.orderLineSteps[this.stepIndex].fromCompanyId === Guid.Empty
        ? this.company.companyName
        : '';
    history.editedByCompanyId = this.company.companyId;
    history.editedByCompanyName = this.company.companyName;
    this.companyStepHistory.push(history);
    this.orderLineSteps[this.stepIndex].fromCompanyId = this.company.companyId;
    this.orderLineSteps[this.stepIndex].toCompanyName = selectSCP.companyName;
    this.orderLineSteps[this.stepIndex].toCompanyId = selectSCP.id;
    if (this.orderLineData.delegateCompanyID === this.user.companyId) {
      this.orderLineSteps[this.stepIndex].supplierStep = false;
      this.orderLineSteps[this.stepIndex].stepState = StepState.UPDATED_BY_DELEGATEPARTNER;
    } else {
      this.orderLineSteps[this.stepIndex].supplierStep = true;
      this.orderLineSteps[this.stepIndex].stepState = StepState.UPDATED_BY_SUPPLIER;
    }
    this.showSearch = false;
    const user = store.getters.user as UserModel;
    if(this.orderLineSteps[this.stepIndex].footprintRefNo !== refNum){
      this.orderLineSteps[this.stepIndex].footprintRefNo = refNum;
      this.orderLineSteps[this.stepIndex].footprintRefNoUpdatedBy = user.companyId;
      if(this.orderLineSteps[this.stepIndex].footprintRefID === Guid.Empty){
        this.orderLineSteps[this.stepIndex].footprintRefID = uuidv4();
      }
    }
    this.changeStyle(this.selectedStep.toString());
  }

  private async productGroupChanged(): Promise<void> {
    try {
      this.isLoading = true;
      this.selectedScp = Guid.Empty;
      this.selectedStep = 0;
      await this.getSupplyChainStepsForProductGroup(this.selectedProductGroup);
    } finally {
      this.isLoading = false;
    }
  }

  private createSupplyChainClick(): void {
    const confirmationText = this.$t(
      'pages.order_lines.confirm_update_supply_chain'
    ).toString();
    this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false})
      .then(() => {
        this.createSupplyChainAsync();
      })
      .catch((onrejected) => {});
  }

  private async createSupplyChainAsync(): Promise<void> {
    try {
      this.isCreating = true;
      let steps: OrderLineStepModel[] = this.$store.getters.orderLineSteps;
      steps = steps.filter((s) => s.orderId === this.orderLineData.orderId);
      this.orderLineSteps.forEach((step, index) => {
        if (step.toCompanyId !== Guid.Empty && step.id === Guid.Empty) {
          step.id = uuidv4();
          step.fromCompanyId = this.companyId;
          if (step.supplierStep === true) {
            step.stepState = StepState.UPDATED_BY_SUPPLIER;
          }
        }
        const stepHistory: StepHistory = {
          displayName: step.displayName,
          stepId: step.stepId,
          sequence: step.sequence,
          description: '',
          createdByCompanyId:
            this.companyStepHistory.filter((h) => h.stepId === step.stepId)
              .length > 0
              ? this.companyStepHistory.filter(
                  (h) => h.stepId === step.stepId
                )[0].createdByCompanyId
              : this.company.companyId,
          createdByCompanyName:
            this.companyStepHistory.filter((h) => h.stepId === step.stepId)
              .length > 0
              ? this.companyStepHistory.filter(
                  (h) => h.stepId === step.stepId
                )[0].createdByCompanyName
              : this.company.companyName,
          editedByCompanyId:
            this.companyStepHistory.filter((h) => h.stepId === step.stepId)
              .length > 0
              ? this.companyStepHistory.filter(
                  (h) => h.stepId === step.stepId
                )[0].editedByCompanyId
              : this.company.companyId,
          editedByCompanyName:
            this.companyStepHistory.filter((h) => h.stepId === step.stepId)
              .length > 0
              ? this.companyStepHistory.filter(
                  (h) => h.stepId === step.stepId
                )[0].editedByCompanyName
              : this.company.companyName,
          updateAt: moment().toISOString(),
        };
        step.sequence = index + 1;
        step.displayName = this.stepNames[step.stepId];
        step.isLastStep =
          step.sequence === this.orderLineSteps.length ? 'true' : 'false';
        step.stepHistory.push(stepHistory);
      });
      // this.orderLineSteps = this.setLastStep(this.orderLineSteps);
      steps.forEach((step) => {
        step.displayName = this.stepNames[step.stepId];
        const index = this.orderLineSteps.findIndex((s) => s.id === step.id);
        if (
          index === -1 &&
          (step.stepState === StepState.CREATED_BY_SUPPLIER ||
            step.stepState === StepState.UPDATED_BY_SUPPLIER ||
            step.stepState === StepState.CREATED_BY_DELEGATEPARTNER ||
            step.stepState === StepState.UPDATED_BY_DELEGATEPARTNER)
        ) {
          const stepHistory: StepHistory = {
            displayName: this.stepNames[step.stepId],
            stepId: step.stepId,
            sequence: step.sequence,
            description: '',
            createdByCompanyId: step.fromCompanyId,
            createdByCompanyName: '',
            editedByCompanyId: this.user.companyId,
            editedByCompanyName: this.user.companyName,
            updateAt: moment().toISOString(),
          };
          step.stepHistory.push(stepHistory);
          step.stepState = this.orderLineData.delegateCompanyID === this.user.companyId ? StepState.DELETED_BY_DELEGATEPARTNER : StepState.DELETED_BY_SUPPLIER;
          this.orderLineSteps.push(step);
        }
      });
      this.close(true, this.orderLineSteps);
    } finally {
      this.isCreating = false;
    }
  }

  checkTextLenght(): boolean {
    const colourway = this.orderLine.colourway;
    if (colourway) {
      return colourway.length > 16;
    }
    return false;
  }
  //TTD-3619 show add refmodal for mainChain
  private showAddRefModalForMc(stepId:number){
    this.isShowAddRefModal = true;
    this.chainType = 'mainChain';
    const index = this.orderLineSteps.findIndex((s) => s.stepId === stepId);
    this.stepIndex = index;
    this.referenceNumber = '';
  }
  //TTD-3619 show add ref modal for parallel chain
  private showAddRefModalForPc(stepId: number,parentStepId: number,indexOfChain: number){
    this.isShowAddRefModal = true;
    this.chainType = 'parallelChain';
    if(stepId!==-1){
      const index = this.getParallelChain(
        indexOfChain,
        parentStepId.toString()
      ).findIndex((t) => t.stepId === stepId);
      this.indexOfChain = indexOfChain;
      this.indexOfChainStep = index;
      this.parentStepId = parentStepId;
    }
    this.referenceNumber = ''
  }
  //TTD-3619 updating ref number
  private updateReferenceNumber(){
    const user = store.getters.user as UserModel;
    if(this.chainType === 'mainChain'){
      this.orderLineSteps[this.stepIndex].footprintRefNo = this.referenceNumber;
      if(this.referenceNumber!==''){
        this.orderLineSteps[this.stepIndex].footprintRefID = uuidv4();
        this.orderLineSteps[this.stepIndex].footprintRefNoUpdatedBy = user.companyId;
      }
    }else{
      this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
        this.indexOfChainStep
      ].footprintRefNo = this.referenceNumber;
      if(this.referenceNumber!==''){
        this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
          this.indexOfChainStep
        ].footprintRefID = uuidv4();
        this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
          this.indexOfChainStep
        ].footprintRefNoUpdatedBy = user.companyId;
      }
      const parentId = this.parentStepId !== null ? Number.parseInt(this.parentStepId.toString()) : null;
      const index = this.orderLineSteps.findIndex((o) => o.stepId === parentId);
      const ele = this.orderLineSteps.filter((o) => o.stepId === parentId);
        this.orderLineSteps.splice(index, 1);
        this.orderLineSteps.splice(index, 0, ele[0]);
    }
    this.isShowAddRefModal = false;
  }
  //TTD-3619 added validation for ref input field.
  private filterInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const filteredValue = input.value.replace(/[^a-zA-Z0-9.,+/&-]/g, '');
    input.value = filteredValue;
    this.referenceNumber = filteredValue;
}

  @Emit()
  private close(edited: boolean, orderLineSteps: OrderLineStepModel[]): void {}
}
