import { Vue, Component } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import store from '@/store';
import MsalFactory from '@/factories/msalFactory';

// Services
import { UserService } from '@/services/userService';
import { InvitationService } from '@/services/invitationService';

// Helpers
import { CountryHelper } from '@/helpers/countryHelper';

// Models
import { CompanyModel } from '@/models/companyModel';
import { DropdownModel } from '@/models/dropdownModel';
import { UserModel } from '@/models/userModel';

@Component({
  directives: { ClickOutside },
})
export default class SiteHeader extends Vue {
  private applicationLogo: NodeRequire = require('../../assets/logo.svg');

  private showMenu: boolean = false;
  private showLanguageMenu: boolean = false;
  private showAccountNotification: boolean = false;
  private userService: UserService;

  private invitationService: InvitationService = new InvitationService();
  private companyDetails: CompanyModel = new CompanyModel();
  private showData: Object = {};
  private countries: DropdownModel[] = [];
  private countryData: Object = {};
  private languages: object[] = [];
  private selectedLang: any = '';
  private selectedLangFlag: string = '';
  private selectedFlag: NodeRequire | string = '';
  private profile: string = '';

  public constructor() {
    super();
    this.userService = new UserService();
    this.showData = {};
    this.languages = [];
  }

  private async created(): Promise<void> {
    await this.userService.loadUserInfoAsync();
    await this.loadLangDropdown();
    const invitationId = localStorage.getItem('invitationId');
    if (invitationId) {
      if (store.getters.company && store.getters.company != null) {
        this.companyDetails = store.getters.company;
      } else {
        this.companyDetails =
          await this.invitationService.getSupplierByInvitationIdAsync(
            invitationId
          );
      }
    }

    this.countries = CountryHelper.getCountryList();
    const url = window.navigator.language;
    const getCountrySelect = sessionStorage.getItem('countrySelect');
    if (getCountrySelect != null) {
      this.showData = JSON.parse(getCountrySelect);
    }
  }

  private get isProfileUploaded(): boolean {
    const user = this.$store.getters.user as UserModel;
    if (user.userProfilePics && user.userProfilePics.length > 0) {
      this.profile =
        user.userProfilePics[user.userProfilePics.length - 1].docURL;
      return true;
    } else {
      return false;
    }
  }

  private loadLangDropdown(): void {
    const getCountry = sessionStorage.getItem('country');
    let langs = require('../../locales/langFlag.json');
    langs = JSON.parse(JSON.stringify(langs));
    if (getCountry !== null) {
      this.selectedLang = getCountry;
      const selectedFlagObj = Object.fromEntries(
        Object.entries(langs.flags).filter(([key]) => key.includes(getCountry))
      );
      const flag = Object.values(selectedFlagObj)[0];
      const flagPath = this.countryImage(flag);
      this.selectedFlag = flagPath;
    } else {
      this.selectedLang = process.env.VUE_APP_I18N_LOCALE!.toUpperCase();
      const selectedFlagObj = Object.fromEntries(
        Object.entries(langs.flags).filter(([key]) =>
          key.includes(this.selectedLang)
        )
      );
      const flag = Object.values(selectedFlagObj)[0];
      const flagPath = this.countryImage(flag);
      this.selectedFlag = flagPath;
    }
    for (const [key, value] of Object.entries(langs.language)) {
      const flag = langs.flags[key];
      this.languages.push({ code: key, language: value, flag: flag });
    }
  }

  private countrySelect(item: any): void {
    this.selectedLang = item.code;
    const flagPath = this.countryImage(item.flag);
    this.selectedFlag = flagPath;
    const setCountry = sessionStorage.setItem('country', item.code);
    const getCountry = sessionStorage.getItem('country');
    const countryLanguage = require('../../locales/countryLang.json');
    const country = JSON.parse(JSON.stringify(countryLanguage));
    this.countryData = country;
    let jsonName = null;
    const countries = country;
    Object.entries(country.countries).forEach(function (entry: any) {
      const [key, value] = entry;
      if (key == getCountry) {
        jsonName = value;
      }
    });
    const countryJson = require('../../locales/' + jsonName);
    const countryJsonString = JSON.stringify(countryJson);
    const countrySelect = JSON.parse(countryJsonString);
    const setCountrySelect = sessionStorage.setItem(
      'countrySelect',
      countryJsonString
    );
    location.reload();
  }

  private countryImage(countryCode: any): NodeRequire {
    return require(`../../assets/icons/general/${countryCode}.svg`);
  }

  private get userFirstname(): string {
    const user = this.$store.getters.user as UserModel;
    if (user == null || user.name == null) {
      console.error('No user info found');
      return '-';
    }
    return user.firstName;
  }

  private get userName(): string {
    const user = this.$store.getters.user as UserModel;
    if (user == null || user.name == null) {
      console.error('No user info found');
      return '-';
    }
    return user.name;
  }

  private get userCompany(): string {
    const user = this.$store.getters.user as UserModel;
    if (
      this.companyDetails?.companyName != null &&
      this.companyDetails.companyName != ''
    ) {
      return this.companyDetails.companyName;
    } else if (
      user == null ||
      user.companyName == null ||
      user.companyName == ''
    ) {
      console.error('No user info found');
      return '-';
    }

    return user.companyName;
  }

  private toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  private toggleLanguageMenu(): void {
    this.showLanguageMenu = !this.showLanguageMenu;
  }

  private navAccount(): void {
    this.$router.push({ name: 'Profile' });
  }

  private logout(): void {
    localStorage.removeItem('user');
    localStorage.removeItem('invitationId');
    sessionStorage.removeItem('access_token');
    store.commit('setInitailState');
    this.$router.push({ name: 'Signin' });
  }

  private closeMenu(): void {
    if (screen.width > 636) {
      this.showMenu = false;
    }
  }
}
