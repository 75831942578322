import { Guid } from "./guid";

export class TCtableModel{
    [x: string]: any;
    public tranCertificate1!: string;
    public tranCertificate2!: string;
    public levelTC1!: string;
    public levelTC2!: string;
    public partner!: string;
    public deleveryDate!: moment.Moment;
    public brandNames!: string[];
    public orderNumbers!: string[];
    public styleNumbers!: string[];
    public styleNames!: string[];
    public colours!: string[];
    public tc1Status!: string;
    public tc2Status!: string;
    public linkedOrderIDs!: String[];
    public toCompanyId!: string;
    public isSelectedTC!: boolean;
    public tranCertificate1RefId!: string;
    public tranCertificate2RefId!: string;
    public orderIds!: string[];
    public tcReferenceNumber!: string;
    public tcReferenceNumber1!: string;
    public tc1Type!: string;
    public tc2Type!: string;
    public fromCompanyId!: string;
    public docURL!: string;
    public fileName!: string;
    public fileType!: string;
    public client!: string;
    public attachement!: number;
    public rejectedReason!: string;
    public isDocLoading!: boolean;

    public constructor(orderIds: string[], tc1: string, tc2: string, level1:string, level2:string, partner:string, delieveryDate:moment.Moment, brands:string[], orderNumbers:string[], styleNumbers:string[],
        styleNames:string[], colours: string[], orderIDs:string[], companyId:string, refId1:string, refId2:string, tc1Status:string, tc2Status: string, tcReferenceNumber: string, tcReferenceNumber1: string, tc1Type: string, 
        tc2Type: string, client:string, fromCompanyId:string, reason: string
    ) {
        this.tranCertificate1 = tc1;
        this.tranCertificate2 = tc2;
        this.levelTC1 = level1;
        this.levelTC2 = level2;
        this.partner = partner;
        this.delieveryDate = delieveryDate;
        this.brandNames = brands;
        this.orderNumbers = orderNumbers;
        this.styleNumbers = styleNumbers;
        this.styleNames = styleNames;
        this.colours = colours;
        this.linkedOrderIDs = orderIDs;
        this.toCompanyId = companyId;
        this.tranCertificate1RefId = refId1;
        this.tranCertificate2RefId = refId2;
        this.tc1Status = tc1Status;
        this.tc2Status = tc2Status;
        this.orderIds = orderIds;
        this.tcReferenceNumber = tcReferenceNumber;
        this.tcReferenceNumber1 = tcReferenceNumber1;
        this.tc1Type = tc1Type;
        this.tc2Type = tc2Type;
        this.client = client;
        this.attachement = 0;
        this.fromCompanyId = fromCompanyId;
        this.rejectedReason = reason;
    }
}

export enum TCStatus {
    PENDING = 'PENDING',
    UPLOAD = 'UPLOADED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    ARCHIVED = 'ARCHIVED'
}

export enum TCType {
    DRAFT = 'DRAFT',
    FINALTC = 'FINAL'
}