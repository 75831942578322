import { UserProfileModel } from './userProfileModel';

export class UserModel {
  public name!: string;
  public firstName!: string;
  public lastName!: string;
  public companyName!: string;
  public companyId!: string;
  public email!: string;
  public status!: string;
  public isAdmin!: string;
  public isBlocked!: string;
  public isT1Scp!: boolean;
  public isDelegatePartner!: boolean;
  public userType!: string;
  public userProfilePics!: UserProfileModel[];
  public phone!: string;
  public userId!: string;
  public companyType!: number;
  public role!: string | null;
  public invitedByCompanyEmailID!: string;
  public invitedByCompanyID!: string;
  public invitedByCompanyName!: string;
}

export class UserModelRequest {
  public userId!: string;
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public phone!: string;
}

export class UserModelResponse {
  public Exceptionmessage!: string | null;
  public message!: string;
  public result!: string;
  public user!: UserModel;
}
