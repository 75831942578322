import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import store from '@/store';

// Components
import Multiselect from '@/components/input/multiselect.vue';

// Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';

// Services
import { MiddlewareService } from '@/services/middlewareService';
import { NonRomanCharCheckHelper } from '@/helpers/nonRomanCharCheckHelper';

// Models
import { UserModel } from '@/models/userModel';
import { SecondUser } from '@/models/secondUser';
import { SecondUserRequest } from '@/models/secondUser';
import { DropdownModel } from '@/models/dropdownModel';
import { CompanyModel } from '@/models/companyModel';

@Component({
  components: { Multiselect },
})
export default class AdminSettings extends Vue {
  @Prop()
  private editUser!: UserModel;

  private middlewareService: MiddlewareService;
  private roles: DropdownModel[] = [];
  private secondUser: SecondUser = new SecondUser();
  private firstnameError: boolean = false;
  private emailError: boolean = false;
  private lastnameError: boolean = false;
  private phoneError: boolean = false;
  private roleError: boolean = false;
  private isError: boolean = false;
  private isSaving: boolean = false;
  private isEdit: boolean = false;
  private showSucceededModal: boolean = false;
  private userStatus: string = '';
  private isFirstNameNonRoman: boolean = false;
  private isLastNameNonRoman: boolean = false;
  private isEmailNonRoman: boolean = false;
  private fNameSpecCharErr: boolean = false;
  private lNameSpecCharErr: boolean = false;

  public constructor() {
    super();
    this.middlewareService = new MiddlewareService();
  }

  private created(): void {
    this.roles = [
      { value: 'Editor', text: 'Editor' },
      { value: 'Reader', text: 'Reader' },
    ];

    if (this.editUser && this.editUser.companyName !== undefined) {
      this.loadData(true);
      this.isEdit = true;
      this.userStatus = this.editUser.status;
    } else {
      this.isEdit = false;
      this.loadData(false);
    }
  }

  private loadData(isEdit: boolean): void {
    if (isEdit) {
      const user: UserModel = this.editUser;
      this.secondUser.companyName = user.companyName;
      this.secondUser.firstname = user.firstName;
      this.secondUser.lastname = user.lastName;
      this.secondUser.companyType = user.companyType;
      this.secondUser.email = user.email;
      this.secondUser.firstnameOfCompanyAdmin = user.firstName;
      this.secondUser.phone = user.phone;
      this.secondUser.role = user.role;
      if (user.role == 'Admin') {
        this.isError = true;
      }
    } else {
      const user: UserModel = store.getters.user;
      this.secondUser.companyName = user.companyName;
      this.secondUser.companyType = user.companyType;
      this.secondUser.firstnameOfCompanyAdmin = user.firstName;
    }
  }

  private close(): void {
    if (this.isSaving) {
      return;
    }
    this.closeModal();
    this.secondUser = new SecondUser();
    if (this.isEdit) {
      this.isEdit = false;
    }
  }

  private selectedRole(role: any): void {
    this.secondUser.role = role;
    this.validateField('role', null);
  }

  private async validateField(
    fieldname: string,
    checkAll: boolean | null
  ): Promise<void> {
    var specials=/[@()[\];:<>, ]/;
    this.isError = false;
    if (fieldname == 'firstname' || checkAll) {
      if (this.secondUser.firstname.length < 2) {
          this.firstnameError = true;
      } else {
          this.firstnameError = false;
      }
      // TTD-4477, for adding validations on firstname
      if (specials.test(this.secondUser.firstname) && !(this.secondUser.firstname.length < 2)) {
          this.fNameSpecCharErr = true;
      } else {
          this.fNameSpecCharErr = false;
      }
    }
    if (fieldname == 'lastname' || checkAll) {
      this.lastnameError = this.secondUser.lastname.length < 2 ? true : false;
    }
    if (fieldname == 'email' || checkAll) {
      const emailValid = this.secondUser.email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      this.emailError =
        emailValid == null || this.secondUser.email.length == 0 ? true : false;
    }
    if (fieldname == 'phone' || checkAll) {
      const pattern = /^[+]?[0-9]{2}?[0-9]{6,11}$/;
      const validPattern: boolean = pattern.test(this.secondUser.phone);
      this.phoneError = !validPattern ? true : false;
    }
    if (fieldname == 'role' || checkAll) {
      this.roleError =
        this.secondUser.role === '' || this.secondUser.role === 'Admin'
          ? true
          : false;
    }
  }

  private get isSendDisabled(): boolean {
    if (
      this.firstnameError ||
      this.fNameSpecCharErr ||
      this.lastnameError ||
      this.emailError ||
      this.phoneError ||
      this.roleError
    ) {
      this.isError = true;
      return true;
    }
    this.isError = false;
    return false;
  }

  private async send(): Promise<void> {
    await this.validateField('all', true);
    if(this.isSendDisabled){
        return;
    }
    if (this.secondUser.role == 'Admin') {
      this.isError = true;
      return;
    }
    if (
      (this.isEdit && this.secondUser.email !== this.editUser.email) ||
      !this.isEdit
    ) {
      const confirmationText = this.$t('pages.invitation.invite_confirmation', [
        this.secondUser.email,
      ]).toString();
      this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false})
        .then(() => {
          this.sendInvitation();
        })
        .catch((onrejected) => {
          return;
        });
    } else {
      this.sendInvitation();
    }
  }

  private async sendInvitation(): Promise<void> {
    this.isSaving = true;
    const user: UserModel = store.getters.user;
    const company: CompanyModel = store.getters.company;
    if (this.isEdit) {
      if (this.secondUser.email !== this.editUser.email) {
        this.secondUser.oldemail = this.editUser.email;
        this.secondUser.email = this.secondUser.email.trim();
        this.secondUser.firstname = this.secondUser.firstname.trim();
        this.secondUser.lastname = this.secondUser.lastname.trim();
        this.secondUser.phone = this.secondUser.phone.trim();
        const secondUserRequest: SecondUserRequest = this.secondUser;
        secondUserRequest.userId = this.editUser.userId;
        var response = await this.middlewareService.editSecondUser(
          secondUserRequest,
          user.companyId
        );
      } else {
        this.secondUser.oldemail = '';
        const secondUserRequest: SecondUserRequest = new SecondUserRequest();
        secondUserRequest.userId = this.editUser.userId;
        secondUserRequest.companyName = this.secondUser.companyName;
        secondUserRequest.companyType = this.secondUser.companyType;
        secondUserRequest.firstname = this.secondUser.firstname.trim();
        secondUserRequest.firstnameOfCompanyAdmin =
          this.secondUser.firstnameOfCompanyAdmin;
        secondUserRequest.lastname = this.secondUser.lastname.trim();
        secondUserRequest.phone = this.secondUser.phone.trim();
        secondUserRequest.role = this.secondUser.role;
        var response = await this.middlewareService.editSecondUser(
          secondUserRequest,
          user.companyId
        );
      }
    } else {
      this.secondUser.firstname = this.secondUser.firstname.trim();
      this.secondUser.lastname = this.secondUser.lastname.trim();
      this.secondUser.email = this.secondUser.email.trim();
      this.secondUser.phone = this.secondUser.phone.trim();
      var response = await this.middlewareService.createSecondUser(
        this.secondUser,
        user.companyId
      );
    }

    if (response.message.includes('Email already exists.')) {
      const alertExistingInListText = this.$t(
        'pages.invitation.email_already_exist'
      ).toString();
      this.$alert(alertExistingInListText, '', undefined, {
        confirmButtonText: this.$t('global.modal.ok') as string,
        html: alertExistingInListText,
      });
    } else if (response.result == 'success') {
      NotificationHelper.createSucceededNotification(response.message);
      if (this.isEdit) {
        this.closeModal();
      } else {
        this.openSucceededModal();
      }
      this.reload();
    } else {
      NotificationHelper.createErrorNotification(response.message);
    }
    this.isSaving = false;
  }

  private openSucceededModal(): void {
    this.showSucceededModal = true;
  }

  private closeSucceededModal(): void {
    this.showSucceededModal = false;
    this.closeModal();
  }

  private async checkNonRomanChar(variableName:string,val:string){
    (this as any)[variableName] = await NonRomanCharCheckHelper.checkNonRomanChar(val)
  }

  private get isNonRomanChar():boolean{
    if(this.isFirstNameNonRoman || this.isLastNameNonRoman || this.isEmailNonRoman){
      return true;
    }else{
      return false;
    }
  }

  @Emit()
  private closeModal(): void {}

  @Emit()
  private reload(): void {}
}
