export class UserPermissionModel {
  public constructor(userPermissions: UserPermissionModel) {
    this.inviteCompany =
      userPermissions.inviteCompany !== undefined
        ? userPermissions.inviteCompany
        : 0;
    this.readCompany =
      userPermissions.readCompany !== undefined
        ? userPermissions.readCompany
        : 0;
    this.updateCompany =
      userPermissions.updateCompany !== undefined
        ? userPermissions.updateCompany
        : 0;
    this.confirmGeolocationCompany =
      userPermissions.confirmGeolocationCompany !== undefined
        ? userPermissions.confirmGeolocationCompany
        : 0;
    this.inviteUser =
      userPermissions.inviteUser !== undefined ? userPermissions.inviteUser : 0;
    this.readUser =
      userPermissions.readUser !== undefined ? userPermissions.readUser : 0;
    this.updateUser =
      userPermissions.updateUser !== undefined ? userPermissions.updateUser : 0;
    this.blockUser =
      userPermissions.blockUser !== undefined ? userPermissions.blockUser : 0;
    this.readOrderLine =
      userPermissions.readOrderLine !== undefined
        ? userPermissions.readOrderLine
        : 0;
    this.updateOrderLine =
      userPermissions.updateOrderLine !== undefined
        ? userPermissions.updateOrderLine
        : 0;
    this.readQrCode =
      userPermissions.readQrCode !== undefined ? userPermissions.readQrCode : 0;
    this.createReports =
      userPermissions.createReports !== undefined
        ? userPermissions.createReports
        : 0;
    this.readReports =
      userPermissions.readReports !== undefined
        ? userPermissions.readReports
        : 0;
    this.updateReports =
      userPermissions.updateReports !== undefined
        ? userPermissions.updateReports
        : 0;
    this.deleteReports =
      userPermissions.deleteReports !== undefined
        ? userPermissions.deleteReports
        : 0;
    this.createOrderLineSteps =
      userPermissions.createOrderLineSteps !== undefined
        ? userPermissions.createOrderLineSteps
        : 0;
    this.readOrderLineSteps =
      userPermissions.readOrderLineSteps !== undefined
        ? userPermissions.readOrderLineSteps
        : 0;
    this.updateOrderLineSteps =
      userPermissions.updateOrderLineSteps !== undefined
        ? userPermissions.updateOrderLineSteps
        : 0;
    this.deleteOrderLineSteps =
      userPermissions.deleteOrderLineSteps !== undefined
        ? userPermissions.deleteOrderLineSteps
        : 0;
    this.assignSCPOrderLineSteps =
      userPermissions.assignSCPOrderLineSteps !== undefined
        ? userPermissions.assignSCPOrderLineSteps
        : 0;
    this.createOrderLineStepsTemplate =
      userPermissions.createOrderLineStepsTemplate !== undefined
        ? userPermissions.createOrderLineStepsTemplate
        : 0;
    this.readOrderLineStepsTemplate =
      userPermissions.readOrderLineStepsTemplate !== undefined
        ? userPermissions.readOrderLineStepsTemplate
        : 0;
    this.updateOrderLineStepsTemplate =
      userPermissions.updateOrderLineStepsTemplate !== undefined
        ? userPermissions.updateOrderLineStepsTemplate
        : 0;
    this.deleteOrderLineStepsTemplate =
      userPermissions.deleteOrderLineStepsTemplate !== undefined
        ? userPermissions.deleteOrderLineStepsTemplate
        : 0;
    this.createPhotos =
      userPermissions.createPhotos !== undefined
        ? userPermissions.createPhotos
        : 0;
    this.readPhotos =
      userPermissions.readPhotos !== undefined ? userPermissions.readPhotos : 0;
    this.updatePhotos =
      userPermissions.updatePhotos !== undefined
        ? userPermissions.updatePhotos
        : 0;
    this.deletePhotos =
      userPermissions.deletePhotos !== undefined
        ? userPermissions.deletePhotos
        : 0;
    this.acceptRejectPhotos =
      userPermissions.acceptRejectPhotos !== undefined
        ? userPermissions.acceptRejectPhotos
        : 0;
    this.readSCPOrderLine =
      userPermissions.readSCPOrderLine !== undefined
        ? userPermissions.readSCPOrderLine
        : 0;
    this.downloadSignedCompliance =
      userPermissions.downloadSignedCompliance !== undefined
        ? userPermissions.downloadSignedCompliance
        : 0;
    this.signCompliance =
      userPermissions.signCompliance !== undefined
        ? userPermissions.signCompliance
        : 0;
    this.uploadCertificates =
      userPermissions.uploadCertificates !== undefined
        ? userPermissions.uploadCertificates
        : 0;
    this.readCertificates =
      userPermissions.readCertificates !== undefined
        ? userPermissions.readCertificates
        : 0;
    this.downloadCertificates =
      userPermissions.downloadCertificates !== undefined
        ? userPermissions.downloadCertificates
        : 0;
    this.delegateOrderLine =
      userPermissions.delegateOrderLine !== undefined
        ? userPermissions.delegateOrderLine
        : 0;
    this.createDelegateOrderLineSteps =
      userPermissions.createDelegateOrderLineSteps !== undefined
        ? userPermissions.createDelegateOrderLineSteps
        : 0;
    this.assignSCPDelegateOrderLineSteps =
      userPermissions.assignSCPDelegateOrderLineSteps !== undefined
        ? userPermissions.assignSCPDelegateOrderLineSteps
        : 0;
    this.delegateOrderLineSteps =
      userPermissions.delegateOrderLineSteps !== undefined
        ? userPermissions.delegateOrderLineSteps
        : 0;
    this.readDelegateOrderLineSteps =
      userPermissions.readDelegateOrderLineSteps !== undefined
        ? userPermissions.readDelegateOrderLineSteps
        : 0;
    this.updateDelegateOrderLineSteps =
      userPermissions.updateDelegateOrderLineSteps !== undefined
        ? userPermissions.updateDelegateOrderLineSteps
        : 0;
    this.deleteDelegateOrderLineSteps =
      userPermissions.deleteDelegateOrderLineSteps !== undefined
        ? userPermissions.deleteDelegateOrderLineSteps
        : 0;
    this.uploadOrderEvidence =
      userPermissions.uploadOrderEvidence !== undefined
        ? userPermissions.uploadOrderEvidence
        : 0;
    this.approveOrderEvidence =
      userPermissions.approveOrderEvidence !== undefined
        ? userPermissions.approveOrderEvidence
        : 0;
    this.submitOrderEvidence =
      userPermissions.submitOrderEvidence !== undefined
        ? userPermissions.submitOrderEvidence
        : 0;
    this.deleteOrderEvidence =
      userPermissions.deleteOrderEvidence !== undefined
        ? userPermissions.deleteOrderEvidence
        : 0;
    this.readOrderEvidence =
      userPermissions.readOrderEvidence !== undefined
        ? userPermissions.readOrderEvidence
        : 0;
  }

  public inviteCompany!: number;
  public readCompany!: number;
  public updateCompany!: number;
  public confirmGeolocationCompany!: number;
  public inviteUser!: number;
  public readUser!: number;
  public updateUser!: number;
  public blockUser!: number;
  public readOrderLine!: number;
  public updateOrderLine!: number;
  public readQrCode!: number;
  public createReports!: number;
  public readReports!: number;
  public updateReports!: number;
  public deleteReports!: number;
  public createOrderLineSteps!: number;
  public readOrderLineSteps!: number;
  public updateOrderLineSteps!: number;
  public deleteOrderLineSteps!: number;
  public assignSCPOrderLineSteps!: number;
  public createOrderLineStepsTemplate!: number;
  public readOrderLineStepsTemplate!: number;
  public updateOrderLineStepsTemplate!: number;
  public deleteOrderLineStepsTemplate!: number;
  public createPhotos!: number;
  public readPhotos!: number;
  public updatePhotos!: number;
  public deletePhotos!: number;
  public acceptRejectPhotos!: number;
  public readSCPOrderLine!: number;
  public downloadSignedCompliance!: number;
  public signCompliance!: number;
  public uploadCertificates!: number;
  public readCertificates!: number;
  public downloadCertificates!: number;
  public delegateOrderLine!: number;
  public createDelegateOrderLineSteps!: number;
  public assignSCPDelegateOrderLineSteps!: number;
  public delegateOrderLineSteps!: number;
  public readDelegateOrderLineSteps!: number;
  public updateDelegateOrderLineSteps!: number;
  public deleteDelegateOrderLineSteps!: number;
  public uploadOrderEvidence!: number;
  public submitOrderEvidence!: number;
  public approveOrderEvidence!: number;
  public deleteOrderEvidence!: number;
  public readOrderEvidence!: number;
}

export class UserRolePermissionsModel {
  public companyID!: string;
  public groupID!: string;
  public groupName!: string;
  public permissions!: UserPermissionModel;
}

export class UserPermissionRequestModel {
  public companyID!: string;
  public companyType!: number;
  public groupID!: string;
}
