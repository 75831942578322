import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { cloneDeep, uniq } from 'lodash';

// Components
import enLangData from "@/locales/en.json";
import UploadTable from '@/views/desktopEvidence/components/uploadTable.vue';
import viewDocument from '@/views/desktopEvidence/components/viewDocument.vue';

// Service
import { SupplyChainStepService } from '@/services/supplyChainStepService';
import { SupplierService } from '@/services/supplierService';
import { EvidenceService } from '@/services/evidenceService';
import { OrderService } from '@/services/orderService';

// Helper
import { StepsHelper } from '@/helpers/stepsHelper';
import { NotificationHelper } from "@/helpers/notificationHelper";

// Models
import { GroupStepEvidenceModel } from '@/models/groupStepEvidenceModel';
import { OrderLineEvidenceStep } from '@/models/orderLineEvidenceStep';
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { EvidenceFile, EvidenceFileRespModel, OrderStepEvidenceModel } from '@/models/orderStepEvidenceModel';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import { UserModel } from '@/models/userModel';

@Component({
  components: { UploadTable, viewDocument },
  computed: mapState(['suppliers', 'companyNameList', 'user'])
})
export default class EvidenceDetailsPopup extends Vue {
  @Prop()
  private allSteps!: OrderLineEvidenceStep[];

  @Prop()
  private selectedGroup!: GroupStepEvidenceModel;

  @Prop()
  private combinedRefNo!: string;

  private orderService: OrderService;

  private evidenceService: EvidenceService;

  private user!: UserModel;

  private stepNames: string[] = [];

  private isLoading: boolean = false;

  private displaySteps: OrderLineDisplayModel[] = [];

  private selectedOrders: OrderStepEvidenceModel[] = [];

  private processSteps: GroupStepEvidenceModel = new GroupStepEvidenceModel();

  private longitude: number = 0;

  private latitude: number = 0;

  private isLocationEnabled: boolean = false;

  private showAllowLocationSharing: boolean = false;

  private isSelectDocBtnDisabled: boolean = false;

  private showUploadDocTypeModal: boolean = false;

  private showUploadFileModal: boolean = false;

  private uploadDocType: string = '';

  private isUploading: boolean = false;

  private docFile: any = [];

  private invalidDocSelected: boolean = false;

  private showPreviewDocument: boolean = false;

  private imageEvidenceFiles: any = [];

  private selectedGroupSteps: GroupStepEvidenceModel = new GroupStepEvidenceModel();

  private maxPhotoCount: number = 20;
  private minPhotoCount: number = 1;

  private pdfEvidenceFile: EvidenceFile = new EvidenceFile()

  public constructor() {
    super();
    this.orderService = new OrderService();
    this.evidenceService = new EvidenceService();
    this.stepNames = Object.values(enLangData.enums.steps);
  }

  private async created(): Promise<void> {
    this.processSteps = cloneDeep(this.selectedGroup);
    try {
      this.isLoading = true;
    } finally {
      this.isLoading = false;
    }
  }

  private get pending(): number {
    return OrderLineStepStatus.Pending;
  }

  private get waitingApproval(): number {
    return OrderLineStepStatus.WaitingForApproval;
  }

  private get combineSteps(): string {
    const groupedstep = cloneDeep(this.processSteps.steps);
    return uniq(groupedstep).join(', ');
  }

  private get stepsWithNextStep(): string[] {
    const allSteps = this.selectedGroup.steps;
    if(this.selectedGroup.nextStepName !== ''){
      allSteps.push(this.selectedGroup.nextStepName);
    }
    return allSteps;
  }

  private checkLocationSharing(){
    this.getGeolocationPermission();
  }

  private getGeolocationPermission(): void {
    navigator.geolocation.getCurrentPosition(
      this.getLocationSuccess,
      this.getLocationError,
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }

  private getLocationSuccess(position: any): void {
    this.longitude = position.coords.longitude;
    this.latitude = position.coords.latitude;
    this.showUploadDocTypeModal = true;
  }

  private getLocationError(err: any): void {
    if(err) {
      this.showAllowLocationSharing = true;
    }
  }

  private selectedOrderList(selectedOrders: OrderStepEvidenceModel[]): void {
    this.selectedOrders = [];
    this.selectedOrders = selectedOrders;
    if (selectedOrders.length == 0) {
      this.isSelectDocBtnDisabled = true;
    } else {
      this.isSelectDocBtnDisabled = false;
    }
  }

  private uploadedDocument(event: any): void {
    this.invalidDocSelected = false;
    if (this.latitude === 0 && this.longitude === 0) {
      NotificationHelper.createErrorNotification(
        this.$t('global.notifications.enable_geolocation').toString()
      );
      return;
    }
    if(this.uploadDocType === 'IMAGE'){
      if (
        event.target.files.length < this.minPhotoCount ||
        event.target.files.length > this.maxPhotoCount
      ) {
        NotificationHelper.createErrorNotification(
          this.$t('global.notifications.profile_pictures_quantity', [
            this.minPhotoCount,
            this.maxPhotoCount,
          ]).toString()
        );
        return;
      }
    }
    let count = 0;
    for (let index = 0; index < event.target.files.length; index++) {
      const docTypeSplit = event.target.files[index].type.split("/");
      const docType = docTypeSplit[docTypeSplit.length - 1];
      if(this.uploadDocType === 'PDF'){
        if(docType !== 'pdf'){
          count++;
        }
      } else {
        if(docType !== 'png' && docType !== 'jpg' && docType !== 'jpeg'){
          count++;
        }
      }
    }
    if(count > 0){
      this.invalidDocSelected = true;
      if(this.uploadDocType === 'PDF'){
        NotificationHelper.createErrorNotification(
          this.$t('global.notifications.invalid_pdf').toString()
        );
      }else{
        NotificationHelper.createErrorNotification(
          this.$t('global.notifications.invalid_image').toString()
        );
      }
      return;
    }
    this.imageEvidenceFiles = event;
  }

  private async uploadPdfEvidence(): Promise<EvidenceFileRespModel> {
    this.isUploading = true;
    const data: FormData = new FormData();
    data.append('upload', this.imageEvidenceFiles.target.files[0]);
    data.append('geoLat', this.latitude.toString());
    data.append('geoLong', this.longitude.toString());
    data.append('status', this.waitingApproval.toString());
    const response = await this.evidenceService.uploadPdfEvidenceAsync(this.user.companyId, data);
    this.isUploading = false;
    return response;
  }

  private selectDocTypeBack(): void {
    this.uploadDocType = '';
    this.invalidDocSelected = false;
    this.showUploadFileModal = false;
    this.showUploadDocTypeModal = false;
    this.showAllowLocationSharing = false;
    this.showPreviewDocument = false;
  }

  private uploadDocBack(): void {
    this.invalidDocSelected = false;
    this.showUploadFileModal = false;
    this.showUploadDocTypeModal = true;
  }

  private selectedDoc(type: string): void {
    this.uploadDocType = type;
    this.docFile = [];
    this.invalidDocSelected = true;
    this.showUploadDocTypeModal = false;
    this.showUploadFileModal = true;
  }

  private async uploadDocBtn(): Promise<void> {
    const groupedSteps = cloneDeep(this.selectedGroup);
    if(this.uploadDocType === 'PDF'){
      const resp = await this.uploadPdfEvidence();
      if(resp.success){
        this.pdfEvidenceFile = resp.evidencePhoto;
      } else {
        return;
      }
    }
    groupedSteps.orderStyle = this.selectedOrders;
    this.selectedGroupSteps = groupedSteps;
    this.showPreviewDocument = true;
  }

  @Emit()
  private selectOrders(): void{}

  @Emit()
  private close(success: boolean): void {
    this.selectDocTypeBack();
  }
}
