import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { mapState } from "vuex";
import { v4 as uuidv4 } from 'uuid';

// Components

// Services
import { SupplierService } from "@/services/supplierService";

// Helpers
import { NotificationHelper } from "@/helpers/notificationHelper";

// Models
import { EsgAddressStatus, EsgDocsModel, EsgEnvModel, EsgFacilityAddressInfo, EsgSocialModel } from "@/models/esgModel";
import { UserModel } from "@/models/userModel";
import dateTime from "date-time";

@Component({
    computed: mapState(["company", "user", "esgSocial", "socialCertificate", "sustainableCertificate", "esgEnv"]),
})
export default class EsgUploadDocModal extends Vue {
    @Prop()
    private uploadDocKey!: string;

    @Prop()
    private uploadDocSelectedFacility!: EsgAddressStatus[];

    @Prop()
    private type!: string;

    @Prop()
    private esgType!: string;

    private supplierService: SupplierService;

    private esgSocial!: EsgSocialModel[];

    private esgEnv!: EsgEnvModel[];

    private user!: UserModel;

    private isUploading: boolean = false;

    private esgFacilityWithID: EsgFacilityAddressInfo[] = [];

    private docFile: any = "";

    private longitude: number = 0;

    private latitude: number = 0;

    private isLocationEnabled: boolean = false;

    public constructor() {
        super();
        this.supplierService = new SupplierService();
    }

    private created(): void {
        this.getGeolocationPermission();
        this.uploadDocSelectedFacility.forEach(facility => {
            const facilityWithID = new EsgFacilityAddressInfo();
            if(this.esgType === 'SOCIAL') {
                const index = this.esgSocial.findIndex(social => social.addressID === facility.addressID);
                if(index !== -1) {
                    facilityWithID.addressID = facility.addressID;
                    facilityWithID.ID = this.esgSocial[index].ID;
                    this.esgFacilityWithID.push(facilityWithID);
                } else {
                    facilityWithID.addressID = facility.addressID;
                    facilityWithID.ID = uuidv4();
                    this.esgFacilityWithID.push(facilityWithID);
                }
            } else if (this.esgType === 'ENV') {
                const index = this.esgEnv.findIndex(env => env.addressID === facility.addressID);
                if(index !== -1) {
                    facilityWithID.addressID = facility.addressID;
                    facilityWithID.ID = this.esgEnv[index].ID;
                    this.esgFacilityWithID.push(facilityWithID);
                } else {
                    facilityWithID.addressID = facility.addressID;
                    facilityWithID.ID = uuidv4();
                    this.esgFacilityWithID.push(facilityWithID);
                }
            }
        });
    }

    private getGeolocationPermission(): void {
        navigator.geolocation.getCurrentPosition(
          this.getLocationSuccess,
          this.getLocationError,
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
    }

    private getLocationSuccess(position: any): void {
        this.longitude = position.coords.longitude;
        this.latitude = position.coords.latitude;
        this.isLocationEnabled = true;
    }

    private getLocationError(err: any): void {
        if(err.code === -1){
            NotificationHelper.createErrorNotification(
            this.$t('global.notifications.enable_geolocation').toString()
            );
            NotificationHelper.createErrorNotification(err.message);
        }
    }

    private async uploadDoc(): Promise<void>{
		this.isUploading = true;
		const docFormData = new FormData();
        if(this.type === 'SUPPORTINGDOCS') {
            docFormData.append("upload", this.docFile);
            docFormData.append("envDataDocType", this.uploadDocKey);
            docFormData.append("ID", uuidv4());
            docFormData.append("fileType", this.docFile.type);
            docFormData.append("fileName", this.docFile.name);
            docFormData.append("geoLat", this.latitude.toString());
            docFormData.append("geoLong", this.longitude.toString());
            docFormData.append("createdAt", dateTime());
            docFormData.append("createdBy", this.user.userId);
            docFormData.append("addressInfoForFacility", JSON.stringify(this.esgFacilityWithID));
            const result = await this.supplierService.uploadEnvEsgDocsAsync(docFormData);
            if(result.success){
                const doc: EsgDocsModel = new EsgDocsModel();
                doc.ID = result.docs[0].ID;
                doc.fileName = result.docs[0].fileName;
                doc.docUrl = result.docs[0].originalDocURL;
                doc.fileHash = result.docs[0].fileHash;
                doc.fileType = result.docs[0].fileType;
                doc.geoLat = result.docs[0].geoLat;
                doc.geoLong = result.docs[0].geoLong;
                doc.createdAt = result.docs[0].createdAt;
                doc.uploadedBy = result.docs[0].uploadedBy;
                this.closeEsgUploadDoc(true, doc);
            }
        } else {
            docFormData.append("upload", this.docFile);
            docFormData.append("socialDataDocType", this.uploadDocKey);
            docFormData.append("ID", uuidv4());
            docFormData.append("fileType", this.docFile.type);
            docFormData.append("fileName", this.docFile.name);
            docFormData.append("geoLat", this.latitude.toString());
            docFormData.append("geoLong", this.longitude.toString());
            docFormData.append("createdAt", dateTime());
            docFormData.append("createdBy", this.user.userId);
            docFormData.append("addressInfoForFacility", JSON.stringify(this.esgFacilityWithID));
            const result = await this.supplierService.uploadSocialEsgDocsAsync(this.uploadDocKey, docFormData);
            if(result.success){
                const doc: EsgDocsModel = new EsgDocsModel();
                doc.ID = result.docs[0].ID;
                doc.fileName = result.docs[0].fileName;
                doc.docUrl = result.docs[0].originalDocURL;
                doc.fileHash = result.docs[0].fileHash;
                doc.fileType = result.docs[0].fileType;
                doc.geoLat = result.docs[0].geoLat;
                doc.geoLong = result.docs[0].geoLong;
                doc.createdAt = result.docs[0].createdAt;
                doc.uploadedBy = result.docs[0].uploadedBy;
                this.closeEsgUploadDoc(true, doc);
            }
        }

		this.isUploading = false;
	}

    @Emit()
    private closeEsgUploadDoc(status: boolean, uploadedDoc: EsgDocsModel): void {}
}
