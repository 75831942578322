import { ApplicationConfig } from '@/config';
import { IAuthenticator } from './interfaces/IAuthenticator';
import { MsalAuthenticator } from './msalAuthenticator';

export class SupplyChainApiAuthenticator
  extends MsalAuthenticator
  implements IAuthenticator
{
  public async authenticateRequestAsync(
    request: RequestInit
  ): Promise<RequestInit> {
    const accessToken = sessionStorage.getItem('access_token');
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return request;
  }
}
