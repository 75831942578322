import { ApplicationConfig } from '@/config';
import { BaseClientBlockchain } from './baseClientBlockchain';
import { YarnFootprintRequestModel, YarnFootprintResponseModel, WetProcessFootprintResponseModel, YarnModel } from '@/models/yarnFootprintModel';
import { WetProcessFootprintRequestModel } from '@/models/wetProcessFootprintModel';
import { DeleteDocRequestModel, FootprintDataResponseModel, FootprintDeadlineResponseModel, SaveFootprintDeadlineModel, ViewFootprintResponseModel} from '@/models/footprintModel';
import { FabricFootprintRequestModel } from '@/models/fabricFootprintModel';
import { GarmentFootprintModel, GarmentFootprintRequestModel } from '@/models/garmentFootprintModel';
import { FootprintPartnerResponseModel } from '@/models/orderLineStepDisplayModel';
import { InvitedCompaniesModel } from '@/models/invitedCompaniesModel';
import { CompanyNameResponseModel } from '@/models/companyModel';
import { EsgEnvDocUrlReq, EsgEnvDocUrlResp, EsgEnvModel, EsgEnvRespModel, EsgSocialAuditCert, EsgSocialAuditCertResp, EsgSocialDocUrlReq, EsgSocialDocUrlResp, EsgSocialModel, EsgSocialRespModel, EsgUploadDocRespModel } from '@/models/esgModel';

export class SupplierBlockchain extends BaseClientBlockchain {
    private readonly endPointOrder: string = "v1/api/order";
    private readonly endPoint: string = "v1/api";

    public constructor() {
        super(ApplicationConfig.endpoints.blockchainApi);
    }

    // Sprint 23 - TTD-3647
    public async getCompanyFromNameAsync(company: string): Promise<InvitedCompaniesModel>{
        return await this.get<InvitedCompaniesModel>(`${this.endPoint}/company/${company}/search`);
    }

    // Sprint 23 - TTD-3647
    public async getCompanyFromRegAsync(reg: string): Promise<InvitedCompaniesModel>{
        return await this.get<InvitedCompaniesModel>(`${this.endPoint}/company/regNumber/${reg}/search`);
    }

    // TTD-3934
    public async saveYarnFootprintDataAsync(orderId: string, data: YarnFootprintRequestModel): Promise<YarnFootprintResponseModel>{
        return await this.patchWithResponse<YarnFootprintResponseModel>(`${this.endPointOrder}/${orderId}/setYarnFootprint`, data, false);
    }

    // TTD-3934
    public async saveWetProcessDataAsync(orderId: string, data: WetProcessFootprintRequestModel): Promise<WetProcessFootprintResponseModel>{
        return await this.patchWithResponse<WetProcessFootprintResponseModel>(`${this.endPointOrder}/${orderId}/addWPFootprint`, data, false);
    }

    // TTD-3934
    public async uploadSupportingDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/supportingdocs`, uploadData, true);
    }

    // TTD-3934
    public async uploadWetMsdsDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/msdsdocs`, uploadData, true);
    }

    // TTD-3934
    public async getFootprintOnOrderAsync(orderId: string): Promise<ViewFootprintResponseModel>{
        return await this.get<ViewFootprintResponseModel>(`${this.endPointOrder}/${orderId}/getFootprint`);
    }

    // TTD-3934
    public async saveYarnTransportAsync(orderId: string, data: any): Promise<FootprintDataResponseModel>{
        return await this.patchWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/addTransport`, data, false);
    }

    // TTD-3934
    public async deleteSupportDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteSupportingDocs`, data);
    }

    // TTD-3934
    public async saveFabricFootprintDataAsync(orderId: string, data: FabricFootprintRequestModel): Promise<FootprintDataResponseModel>{
        return await this.patchWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/addFabricFootprint`, data, false);
    }

    // TTD-3934
    public async deleteMsdsDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteMsdsDocs`, data);
    }

    // TTD-3934
    public async saveGarmentFootprintDataAsync(orderId: string, data: GarmentFootprintRequestModel): Promise<FootprintDataResponseModel>{
        return await this.patchWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/addGarmentFootprint`, data, false);
    }

    // TTD-3934
    public async getFootprintPartnerAsync(orderId: string, lineId: string): Promise<FootprintPartnerResponseModel>{
        return await this.get<FootprintPartnerResponseModel>(`${this.endPointOrder}/${orderId}/getSCPAsscociatedWithSteps`);
    }

    // TTD-3934
    public async uploadYarnWetMsdsDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/msdsdocsYarnWp`, uploadData, true);
    }

    // TTD-3934
    public async deleteYarnWetMsdsDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteMsdsdocsYarnWp`, data);
    }

    // TTD-3934
    public async uploadSupportingWetYarnDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/supportingdocsYarnWp`, uploadData, true);
    }

    // TTD-3934
    public async deleteWetYarnSupportDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteSupportingDocsYarnWp`, data);
    }

    // TTD-3691 get company name
    public async getCompanyNameAsync(): Promise<CompanyNameResponseModel> {
        return this.get(`${this.endPoint}/company/redis/getAllCompany`);
    }

    // TTD-4716 save social esg data
    public async saveSocialEsgDataAsync(socialData: EsgSocialModel): Promise<EsgSocialRespModel>{
        return await this.postWithResponse<EsgSocialRespModel>(`${this.endPoint}/esg/social-data/create`, socialData, false);
    }

    // TTD-4716 get social esg data
    public async getSocialEsgDataAsync(companyID: string): Promise<EsgSocialRespModel>{
        return await this.get(`${this.endPoint}/esg/social-data/company/${companyID}/get`);
    }

    // TTD-4716 get social esg data on address
    public async getSocialEsgDataOnFacilityAsync(addressID: string): Promise<EsgSocialRespModel>{
        return await this.get(`${this.endPoint}/esg/social-data/address/${addressID}/get`);
    }

    // TTD-2667 upload esg social audit certificates
    public async uploadEsgSocialAuditCertAsync(esgSocialAuditCertData: EsgSocialAuditCert): Promise<EsgSocialAuditCertResp>{
        return await this.putWithResponse<EsgSocialAuditCertResp>(`${this.endPoint}/esg/social-data/complianceDoc/upload`, esgSocialAuditCertData, false);
    }

    // TTD-4716 upload social proof & policy docs
    public async uploadSocialEsgDocsAsync(uploadData: FormData): Promise<EsgUploadDocRespModel>{
        return await this.putWithResponse<EsgUploadDocRespModel>(`${this.endPoint}/esg/social-data/doc/upload`, uploadData, true);
    }

    // TTD-4716 get doc url
    public async getDocUrlAsync(doc: EsgSocialDocUrlReq): Promise<EsgSocialDocUrlResp>{
        return await this.putWithResponse<EsgSocialDocUrlResp>(`${this.endPoint}/esg/social-data/company/getSocialDocUrl`, doc, false);
    }

    // TTD-4716 get environmental esg data
    public async getEnvEsgDataAsync(companyID: string): Promise<EsgEnvRespModel>{
        return await this.get(`${this.endPoint}/esg/env-data/company/${companyID}/get`);
    }

    public async saveEnvEsgDataAsync(envData: EsgEnvModel): Promise<EsgEnvRespModel>{
        return await this.postWithResponse<EsgEnvRespModel>(`${this.endPoint}/esg/env-data/create`, envData, false);
    }

    // TTD-4716 upload environemnt supporting docs docs
    public async uploadEnvEsgDocsAsync(uploadData: FormData): Promise<EsgUploadDocRespModel>{
        return await this.putWithResponse<EsgUploadDocRespModel>(`${this.endPoint}/esg/env-data/doc/upload`, uploadData, true);
    }

    // TTD-4716 get env doc url
    public async getEnvDocUrlAsync(doc: EsgEnvDocUrlReq): Promise<EsgEnvDocUrlResp>{
        return await this.putWithResponse<EsgEnvDocUrlResp>(`${this.endPoint}/esg/env-data/company/getEnvDocUrl`, doc, false);
    }

    // TTD-4716 get env esg data on address
    public async getEnvEsgDataOnFacilityAsync(addressID: string): Promise<EsgEnvRespModel>{
        return await this.get(`${this.endPoint}/esg/env-data/address/${addressID}/get`);
    }
}
