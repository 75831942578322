import { ComplianceAdditionalInfoModel } from './complianceAdditionalInfoModel';

export class ComplianceDocResponseModel {
  public ID!: string;
  public additionalInfo!: ComplianceAdditionalInfoModel[];
  public brands!: string[];
  public complianceDocID!: string;
  public createdAt!: string;
  public documentName!: string;
  public documentStatus!: string;
  public documentVersion!: number;
  public emailNotificationDate!: string;
  public fromCompanyID!: string;
  public fromCompanyName!: string;
  public notifyDays!: number;
  public originalFileName!: string;
  public originalFileType!: string;
  public docURL!: string;
  public scpComment!: string[];
  public scpSignedStatus!: boolean;
  public scpSignedDate!: string;
  public scpReadStatus!: string;
  public signatureRequested!: string;
  public scpReadDate!: string;
  public signRequestedTo!: string;
  public t1Scp!: boolean;
  public toCompanyContactEmail!: string;
  public toCompanyContactName!: string;
  public toCompanyID!: string;
  public toCompanyEmail!: string;
  public toCompanyName!: string;
  public updatedAt!: string;
  public validtoDate!: string;
}
