// Sprint-26 TTD-4716
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import { uniqBy } from "lodash";

// Service
import { ComplianceCertificatesService } from "@/services/complianceCertificatesService";
import { SupplierService } from "@/services/supplierService";

// Components
import esgDataSocial from "@/views/esg/components/esgDataSocial.vue";
import esgDataEnv from "@/views/esg/components/esgDataEnv.vue";

// Models
import { CompanyModel } from "@/models/companyModel";
import { AddressModel } from "@/models/addressModel";
import { ActionTypes } from "@/store/actions/actions";
import { EsgAddressStatus, EsgEnvModel, EsgSocialModel, EsgStatus } from "@/models/esgModel";
import { CertificateModel, CertificateOnTypeModel, CertificateTypes } from "@/models/certificateModel";
import i18n from "@/i18n";
import { UserModel } from "@/models/userModel";
import store from "@/store";

@Component({
  components: {esgDataSocial, esgDataEnv},
  computed: mapState(["userRole", "userPermissions", "companyType", "company", "esgSocial", "socialCertificate", "sustainableCertificate", "esgEnv"]),
})
export default class EsgData extends Vue {
  @Prop()
  private tabIndex!: number;
  private certificateService: ComplianceCertificatesService;
  private supplierService: SupplierService;
  private activateFacility: number = 0;
  private status: string = 'red';
  private company!: CompanyModel;
  private addresses: AddressModel[] = [];
  private activeAddress: AddressModel = new AddressModel();
  private isLoading: boolean = false;
  private esgSocial!: EsgSocialModel[];
  private activeAddEsgSocial: EsgSocialModel = new EsgSocialModel();
  private esgAddressStatus: EsgAddressStatus[] = [];
  private showConfirmSave: boolean = false;
  private auditTypes: CertificateOnTypeModel[] = [];
  private socialCertificate!: CertificateModel[];
  private sustainableCertificate!: CertificateModel[];
  private esgEnv!: EsgEnvModel[];
  private activeAddEsgEnv: EsgEnvModel = new EsgEnvModel();
  private officeName!: string;

  public constructor() {
    super();
    this.certificateService = new ComplianceCertificatesService();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    await this.esgFacilityData();
  }

  private async esgFacilityData(): Promise<void> {
    this.isLoading = true;
    if(this.company === null || this.company === undefined){
      await this.$store.dispatch(ActionTypes.SET_MYACCOUNT);
    }
    const officeAddress = this.company.officeAddress.filter(add => add.addressStatus !== 'DISABLED');
    this.addresses.push(...officeAddress);
    if(this.company.factoryAddressDiffers){
      const factoryAddress = this.company.factoryAddresses.filter(add => add.addressStatus !== 'DISABLED');
      this.addresses.push(...factoryAddress);
    }
    this.addresses = uniqBy(this.addresses, 'id');
    if(this.company.newFacilityData === 'YES'){
      this.addresses.sort((a, b) => {
        return (a.position < b.position) ? -1 : ((a.position > b.position) ? 1 : 0);
      });
    }
    this.activeAddress = this.addresses[this.activateFacility];
    if(this.tabIndex === 0){
      await this.esgSocialData();
    } else {
      await this.esgEnvData();
    }
    this.officeName = this.addresses[this.activateFacility].officeName;
    this.isLoading = false;
  }

  private async esgSocialData(): Promise<void> {
    if(this.esgSocial.length === 0){
      await this.$store.dispatch(ActionTypes.SET_ESG_SOCIAL_DATA);
      await this.getCertificates();
    }
    this.activeAddSocialData();
    const susAudit = i18n.t(`enums.sustainability_certificates.audit`) as any;
    for (const key of Object.keys(susAudit)) {
        const certifcateName = susAudit[key];
        if(!certifcateName.includes('FEM')){
          this.auditTypes.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
        }
    }
    const socialAudit = i18n.t(`enums.social_certificates.audit`) as any;
    for (const key of Object.keys(socialAudit)) {
        const certifcateName = socialAudit[key];
        this.auditTypes.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SOCIAL));
    }
  }

  private async esgEnvData(): Promise<void> {
    if(this.esgEnv.length === 0){
      await this.$store.dispatch(ActionTypes.SET_ESG_ENV_DATA);
    }
    this.activeAddEnvData();
  }

  private get notStartedStatus(): string {
    return '';
  }

  private get inProgressStatus(): string {
    return EsgStatus.INPROGRESS;
  }

  private get completedStatus(): string {
    return EsgStatus.COMPLETED;
  }

  private async showEsgData(index:number, officeName: string): Promise<void>{
    this.officeName = officeName;
    if(this.tabIndex === 0){
      if(this.$refs.social){
        const socialData = this.$refs.social as any;
        if(socialData.counter > 0){
          this.showConfirmSave = true;
        } else {
          await this.getEsgSocialOnFacility(index);
          this.showConfirmSave = false;
          this.activateFacility = index;
          this.activeAddress = this.addresses[index];
          await this.activeAddSocialData();
          this.isLoading = false;
        }
      } else {
        await this.getEsgSocialOnFacility(index);
        this.showConfirmSave = false;
        this.activateFacility = index;
        this.activeAddress = this.addresses[index];
        await this.activeAddSocialData();
        this.isLoading = false;
      }
    } else {
      if(this.$refs.env){
        const envData = this.$refs.env as any;
        if(envData.counter > 0){
          this.showConfirmSave = true;
        } else {
          await this.getEsgEnvOnFacility(index);
          this.showConfirmSave = false;
          this.activateFacility = index;
          this.activeAddress = this.addresses[index];
          await this.activeAddEnvData();
          this.isLoading = false;
        }
      } else {
        await this.getEsgEnvOnFacility(index);
        this.showConfirmSave = false;
        this.activateFacility = index;
        this.activeAddress = this.addresses[index];
        await this.activeAddEnvData();
        this.isLoading = false;
      }
    }
  }

  private addressStatus(addId: string): string {
    const index = this.esgAddressStatus.findIndex(add => add.addressID === addId);
    return index !== -1 ? this.esgAddressStatus[index].status : this.notStartedStatus;
  }

  private facilityStatus(addId: string, status: string): void {
    const index = this.esgAddressStatus.findIndex(add => add.addressID === addId);
    this.esgAddressStatus[index].status = status;
  }

  private activeAddSocialData(): void {
    if(this.esgSocial.length > 0){
      const socialData = this.esgSocial.find(esg => esg.addressID === this.activeAddress.id);
      if(socialData !== undefined){
        this.activeAddEsgSocial = socialData;
        this.activeAddEsgSocial.socialAuditExpanded = false;
        this.activeAddEsgSocial.healthAndSafetyExpanded = false;
        this.activeAddEsgSocial.diversityAndEqualOptyExpanded = false;
        this.activeAddEsgSocial.livingWageWorkingHoursExpanded = false;
        this.activeAddEsgSocial.workingConditionsExpanded = false;
        this.activeAddEsgSocial.discriminationExpanded = false;
        this.activeAddEsgSocial.childForcedLabourExpanded = false;
        this.activeAddEsgSocial.sexualHarasmentExpanded = false;
        this.activeAddEsgSocial.grievanceMechanismExpanded = false;
      } else {
        this.activeAddEsgSocial = new EsgSocialModel();
      }
    }
    this.esgAddressStatus = [];
    this.addresses.forEach(add => {
      const addSocialStatus = this.esgSocial.find(esg => esg.addressID === add.id);
      if(addSocialStatus !== undefined){
        this.esgAddressStatus.push(new EsgAddressStatus(add.id, addSocialStatus.status));
      } else {
        this.esgAddressStatus.push(new EsgAddressStatus(add.id, this.notStartedStatus));
      }
    });
  }

  private activeAddEnvData(): void {
    if(this.esgEnv.length > 0){
      const envData = this.esgEnv.find(esg => esg.addressID === this.activeAddress.id);
      if(envData !== undefined){
        this.activeAddEsgEnv = envData;
        this.activeAddEsgEnv.energyUseExpanded = false;
        this.activeAddEsgEnv.waterUseExpanded = false;
        this.activeAddEsgEnv.wastageExpanded = false;
        this.activeAddEsgEnv.circularEconomyExpanded = false;
        this.activeAddEsgEnv.carbonEmissionExpanded = false;
      } else {
        this.activeAddEsgEnv = new EsgEnvModel();
      }
    }
    this.esgAddressStatus = [];
    this.addresses.forEach(add => {
      const addEnvStatus = this.esgEnv.find(esg => esg.addressID === add.id);
      if(addEnvStatus !== undefined){
        this.esgAddressStatus.push(new EsgAddressStatus(add.id, addEnvStatus.status));
      } else {
        this.esgAddressStatus.push(new EsgAddressStatus(add.id, this.notStartedStatus));
      }
    });
  }

  private async getCertificates(): Promise<void> {
      const user = this.$store.getters.user as UserModel;
      if(this.socialCertificate.length === 0){
        const social =
        await this.certificateService.getSocialCertificatesAsync(
          user.companyId
        );
        store.commit(ActionTypes.SET_SOCIAL_CERTIFICATES, social);
      }
      if(this.sustainableCertificate.length === 0){
        const sustainable =
        await this.certificateService.getSustainableCertificatesAsync(
          user.companyId
        );
        store.commit(ActionTypes.SET_SUSTAINABLE_CERTIFICATES, sustainable);
      }
  }

  private async getEsgSocialOnFacility(index: number): Promise<void> {
    this.isLoading = true;
    await this.supplierService.getSocialEsgDataOnFacilityAsync(this.addresses[index].id);
  }

  private async getEsgEnvOnFacility(index: number): Promise<void> {
    this.isLoading = true;
    await this.supplierService.getEnvEsgDataOnFacilityAsync(this.addresses[index].id);
  }

  @Watch('tabIndex', { deep: true })
  private async onTabChange(): Promise<void> {
    this.activateFacility = 0;
    await this.esgFacilityData();
  }

  @Emit()
  private officeSelected(addressId: string): void { }

}
