export enum ProductGroup {
  None = 0,
  FlatknitGarment = 1,
  CircleKnitGarment = 2,
  WovenGarment = 3,
  Denim = 4,
  Outerwear = 5,
  Lingerie = 6,
  Bags = 7,
  Shoes = 8,
  Socks = 9,
  Accessories = 10,
  Others = 11,
}
